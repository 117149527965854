import type { Agency } from '@/types/objects'

import type { TApiCallSuccess } from '../actions/api'

export interface IAgencyState {
  data: Agency | null

  printout?: {
    id: string
    logo: string
    coverImage: string
    primaryColor: string
    secondaryColor: string
    name: string
  }
}

export const initialState: IAgencyState = {
  data: null,

  printout: undefined
}

export default (
  state: IAgencyState = initialState,
  action: TApiCallSuccess
): IAgencyState => {
  switch (action?.type) {
    case 'GET_AGENCY_SUCCESS':
      return {
        ...state,
        data: action.payload.data as Agency
      }

    case 'GET_AGENCY_PRINTOUT_SUCCESS':
      return {
        ...state,
        printout: action.payload.data as IAgencyState['printout']
      }

    case 'SELECT_RELATION':
      return initialState

    default:
      return state
  }
}
