import type { Policy } from '@/types/objects'
import { add, parse } from 'date-fns'

const showValueMeter = (policy: Policy): boolean => {
  const { productName, details } = policy
  if (details?.underinsurance_guarantee?.value === false) return false
  if (details?.underinsurance_guarantee_date?.value === '') return false
  const underinsuranceGuaranteDate = parse(details?.underinsurance_guarantee_date?.value as string, 'dd-MM-yyyy', new Date())
  const isOlderThan57Months = new Date() >= add(underinsuranceGuaranteDate, { months: 57 })
  return (productName === 'Inboedel' || productName === 'Woonhuis') && isOlderThan57Months
}

export default showValueMeter
