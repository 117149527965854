import type { SupportQuestion, SupportQuestionCategory } from '@/types/objects'
import type { TApiCallSuccess } from '../actions/api'

interface QuestionRepositoryState {
  categories: SupportQuestionCategory[]
  questions: SupportQuestion[]
}

export const initialState: QuestionRepositoryState = {
  categories: [],
  questions: []
}

export default (state = initialState, action: TApiCallSuccess) => {
  switch (action?.type) {
    case 'GET_QUESTIONS_SUCCESS': {
      const data = action.payload.data as QuestionRepositoryState

      return {
        ...state,
        categories: data?.categories ?? [],
        questions: data?.questions ?? []
      }
    }

    default:
      return state
  }
}
