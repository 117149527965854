import React from 'react'

import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import type { Policy } from '@/types/objects'
import i18n from '@/utils/i18n'

import Conditions from './conditions'

const ConditionsPhone: React.FC<{ policy: Policy }> = ({ policy }) => {
  if (!policy?.conditions?.length) {
    return null
  }

  return (
    <>
      <Text
        style={Styling.marginVertical}
        type='subsection'
      >
        {i18n.t('Polisvoorwaarden')}
      </Text>

      <Conditions policy={policy} />
    </>
  )
}

export default ConditionsPhone
