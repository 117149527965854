import React from 'react'

import type { TTextProps } from '@/components/wrappers/Text'
import Text from '@/components/wrappers/Text'
import type { Relation } from '@/types/objects'
import common from '@/utils/common'

type TAmountProps = TTextProps & {
  currency?: boolean
  paymentPeriod?: Relation['paymentPeriod']
  hidePaymentPeriod?: boolean
}

const options = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
}

function renderChildren (children: any, props: TAmountProps) {
  switch (typeof children) {
    case 'string': {
      // Parse the string / number to a float number and convert it to the locale representation.
      const floatValue = parseFloat(children)

      if (Number.isNaN(floatValue)) {
        return ''
      }
      return (props.currency ? '€ ' : '') + floatValue.toLocaleString('nl-NL', options)
    }
    case 'number':
      return (props.currency ? '€ ' : '') + children.toLocaleString('nl-NL', options)
    default:
      return children
  }
}

export default function Amount ({ children, ...props }: TAmountProps) {
  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <Text {...props}>
      {renderChildren(children, props)}
      {(props.paymentPeriod && !props.hidePaymentPeriod) ? common.determinePaymentPeriod(props.paymentPeriod) : null}
    </Text>
  )
}
