import React from 'react'
import { RefreshControl as RNRefreshControl } from 'react-native-web-refresh-control'

import Colors from '@/constants/Colors'

interface TRefreshControlProps {
  onRefresh: () => void
  refreshing: boolean
  title?: string
}

function RefreshControl (props: TRefreshControlProps) {
  return (
    <RNRefreshControl
      colors={[Colors.primary]}
      tintColor={Colors.primary}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      // @note: Hide the title until it is discussed if it's needed.
      title=''
    />
  )
}

export default RefreshControl
