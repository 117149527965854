import React from 'react'
import { connect } from 'react-redux'
import date from '@/utils/date'

import PDFViewer from '../pdf_viewer'

import i18n from '@/utils/i18n'
import fs from '@/utils/fs'

import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'

interface IProps {
  filename: string
  url: string
}

const PolicyStatement: React.FC<IProps> = ({ url, filename }) => (
  <PDFViewer
    route={{
      params: {
        title: filename,
        fileName: filename,
        key: `policy-statement-${filename}`,
        url: `${url}&date=${date.now()}`,
        errorMessage: i18n.t('Het is niet gelukt om het polisblad te downloaden')
      }
    }}
  />
)

const mapStateToProps = (state: TState) => {
  const relation = selectors.getCurrentRelation(state)
  const filename = fs.determineFilenameStatement(relation)

  return {
    filename,
    url: relation?.policyStatement?.replace(/ /g, '%20')
  }
}

export default connect(mapStateToProps)(PolicyStatement)
