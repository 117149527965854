import prototype from '@/utils/prototype'
import type { TApiCallSuccess } from '../actions/api'

export interface TranslationsState {
  [key: string]: {
    [key: string]: string
  }
}

export const initialState: TranslationsState = {}

export default (state: TranslationsState = initialState, action: TApiCallSuccess): TranslationsState => {
  switch (action?.type) {
    case 'GET_TRANSLATIONS_SUCCESS': {
      if (prototype.isObject(action.payload.data)) {
        return prototype.mergeDictsOfDicts(state, action.payload.data)
      }

      return state
    }

    default:
      return state
  }
}
