import * as CONSTANTS from '@/redux/constants/modals'
import type * as TYPES from '@/redux/types/modals'

export const closeModal = (modal: string): TYPES.CloseModalAction => ({
  modal,
  type: CONSTANTS.CLOSE_MODAL
})

export const closeModalEmergencyNumber = () => closeModal('emergencyNumber')
export const closeModalHelp = () => closeModal('help')
export const closeModalLogs = () => closeModal('logs')
export const closeModalSession = () => closeModal('session')

export const openModal = (modal: string): TYPES.OpenModalAction => ({
  modal,
  type: CONSTANTS.OPEN_MODAL
})

export const openModalEmergencyNumber = () => openModal('emergencyNumber')
export const openModalHelp = () => openModal('help')
export const openModalLogs = () => openModal('logs')
export const openModalSession = () => openModal('session')
