import type { ActionSheetProps } from '@expo/react-native-action-sheet'
import { connectActionSheet } from '@expo/react-native-action-sheet'
import React from 'react'
import { ActivityIndicator, View } from 'react-native'

import Icon from '@/components/Icon'
import ListItem from '@/components/list_items/ListItem'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import type { User } from '@/types/objects'
import i18n from '@/utils/i18n'
import toast from '@/utils/toast'
import { add } from 'date-fns'
type TListItemUserProps = ActionSheetProps & {
  currentUser: User
  onEdit: (...args: any[]) => any
  onRemove: (...args: any[]) => any
  onResendInvite: (...args: any[]) => any
  showActionSheetWithOptions?: (...args: any[]) => any
  user: User
  loading: boolean
}

class ListItemUser extends React.Component<TListItemUserProps> {
  onPress = () => {
    if (this.props.currentUser?.mainUser) {
      if (this.props.user.mainUser) {
        this.props.onEdit(this.props.user)
      } else {
        let options = [i18n.t('Verwijder'), i18n.t('Wijzig'), i18n.t('Annuleer')]
        const expiredInvite = this.userStatus(this.props.user) === 'expired'
        if (expiredInvite) {
          options = [i18n.t('Verwijder'), i18n.t('Wijzig'), i18n.t('Verstuur opnieuw'), i18n.t('Annuleer')]
        }
        const destructiveButtonIndex = 0
        const cancelButtonIndex = options.length - 1

        this.props.showActionSheetWithOptions(
          {
            cancelButtonIndex,
            destructiveButtonIndex,
            options
          },
          (buttonIndex: any) => {
            if (buttonIndex === 0 && this.props.onRemove) {
              this.props.onRemove(this.props.user)
            } else if (buttonIndex === 1 && this.props.onEdit) {
              this.props.onEdit(this.props.user)
            } else if (expiredInvite && buttonIndex === 2 && this.props.onResendInvite) {
              this.props.onResendInvite(this.props.user)
            }
          }
        )
      }
    } else if (this.props.currentUser?.id === this.props.user?.id) {
      this.props.onEdit(this.props.user)
    } else {
      toast(i18n.t('U heeft niet genoeg rechten om deze gebruiker aan te passen'))
    }
  }

  userStatus = (user: User): 'active' | 'pending' | 'expired' => {
    if (user.active) return 'active'
    if (user.passwordResetTokens?.filter(item => {
      const datePlusDay = add(new Date(item.createdAt), { days: 1 })
      const now = new Date()
      return item.valid && datePlusDay >= now
    }).length > 0) return 'pending'
    return 'expired'
  }

  render () {
    const currentUser = this.props.currentUser || {}
    const user = this.props.user || {}
    let icon

    if (currentUser.mainUser) {
      if (user.mainUser) {
        icon = 'pencil'
      } else {
        icon = 'ellipsis-v'
      }
    } else if (currentUser.id === user.id) {
      icon = 'pencil'
    }

    const style = (): { backgroundColor: string } | undefined => {
      if (user.mainUser) return
      if (this.userStatus(user) === 'active') return { backgroundColor: Colors.lightGreen }
      if (this.userStatus(user) === 'pending') return { backgroundColor: Colors.lightWaiting }
      return { backgroundColor: Colors.lightError }
    }

    return (
      <ListItem
        item={user}
        onPress={this.onPress}
        style={style()}
        disabled={this.props.loading}
      >
        <View>
          <Text
            bold
            type='description'
          >
            {user.mainUser ? i18n.t('Hoofdgebruiker') : i18n.t('Subgebruiker')}
          </Text>

          <Text type='description'>{user.email}</Text>
        </View>
        {this.props.loading
          ? <ActivityIndicator
              color={Colors.lightGray}
              size='large'
            />
          : icon
            ? <Icon
                color={Colors.lightGray}
                icon={icon}
              />
            : null}
      </ListItem>
    )
  }
}

export default connectActionSheet(ListItemUser)
