import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import Svg, { Circle, Defs, Ellipse, G, Path } from 'react-native-svg'

interface TSearchProps {
  style?: StyleProp<ViewStyle>
  width?: number
}

const defaultWidth = 228
const defaultHeight = 138
export const ratio = defaultHeight / defaultWidth

export default function SvgComponent (props: TSearchProps) {
  const width = props.width || defaultWidth
  const height = props.width ? width * ratio : defaultHeight
  return (
    <Svg
      height={height}
      style={props.style}
      viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}
      width={width}
    >
      <Defs />
      <G
        fill='none'
        fillRule='evenodd'
      >
        {/* @ts-expect-error ts-migrate(2322) FIXME: Property 'filter' does not exist on type 'Intrinsi... Remove this comment to see the full error message */}
        <Ellipse
          cx={114}
          cy={117}
          fill='#FBFAFA'
          filter='url(#prefix__a)'
          rx={114}
          ry={21}
        />
        <Circle
          cx={146}
          cy={45}
          fill='#E7E4E2'
          r={17}
        />
        <Circle
          cx={59}
          cy={70}
          fill='#E7E4E2'
          opacity={0.377}
          r={18}
        />
        <Circle
          cx={102}
          cy={21}
          fill='#E7E4E2'
          opacity={0.3}
          r={13}
        />
        <Circle
          cx={141}
          cy={8}
          fill='#E7E4E2'
          opacity={0.7}
          r={8}
        />
        <Circle
          cx={66}
          cy={23}
          fill='#E7E4E2'
          opacity={0.628}
          r={4}
        />
        <Circle
          cx={163}
          cy={76}
          fill='#E7E4E2'
          opacity={0.644}
          r={4}
        />
        <Path
          d='M158.715 102.027L140.41 83.723a4.403 4.403 0 00-3.12-1.285h-2.993a38.001 38.001 0 008.078-23.5c0-21.095-17.093-38.188-38.188-38.188C83.092 20.75 66 37.843 66 58.938c0 21.095 17.092 38.187 38.187 38.187 8.868 0 17.02-3.011 23.5-8.078v2.992c0 1.176.46 2.295 1.286 3.122l18.304 18.304c1.725 1.726 4.516 1.726 6.224 0l5.195-5.196a4.427 4.427 0 00.019-6.242zm-54.528-19.59c-12.98 0-23.5-10.501-23.5-23.5 0-12.98 10.502-23.5 23.5-23.5 12.98 0 23.5 10.502 23.5 23.5 0 12.98-10.501 23.5-23.5 23.5z'
          fill='#84726F'
          fillRule='nonzero'
        />
      </G>
    </Svg>
  )
}
