import * as FileSystem from 'expo-file-system'
import path from 'path'

import type { Condition, File, Policy, Purchase, Relation, ServiceGuide } from '@/types/objects'
import logger from '@/utils/logger'

// The module 'expo-file-system' does not work on web!

class Fs {
  cacheDirectory = FileSystem.cacheDirectory

  // Having a directory 'documents' in the file system's document directory is needed for Android.
  documentDirectory: string =
    (FileSystem.documentDirectory && path.join(FileSystem.documentDirectory, 'documents')) || ''

  logError = (error: unknown) => logger.warn('Fs:', String(error), error)

  writeAsString = async (fileUri: string, contents: string, options?: object) => {
    return null
  }

  download = async (uri: string, fileUri: string, options?: FileSystem.DownloadOptions) => {
    return null
  }

  getInfo = async (fileUri: string, options?: object) => {
    return null
  }

  readAsString = async (fileUri: string, options: object = {}): Promise<string> => {
    return ''
  }

  makeDirectory = async (fileUri: string, options: object = {}) => {
    return false
  }

  uriToBase64 = async (uri: string) => {
    let base64: string = ''

    if (uri?.startsWith('data:')) {
      const contentTypeEnding = 'base64,'
      const index = uri.indexOf(contentTypeEnding)
      if (index > -1) {
        base64 = uri.substring(index + contentTypeEnding.length)
      }
    }

    return base64
  }

  fileExists = async (filepath: string) => {
    const result = await this.getInfo(filepath)
    return result?.exists && !result.isDirectory
  }

  downloadDocument = async (uri: string, filename: string, options?: FileSystem.DownloadOptions) => {
    await this.makeDirectory(this.documentDirectory)
    const filepath = path.join(this.documentDirectory || '', filename || '')
    const result = await this.download(uri, filepath, options)
    return result?.uri ? filepath : ''
  }

  fileToFilename = (file: File) => {
    let filename = ''

    if (file) {
      if (file.filename) {
        filename = file.filename
      } else if (file.name) {
        filename = file.name
      }
      let basename = ''
      if (file.uri && !file.uri.startsWith('data:')) {
        basename = path.basename(file.uri)
      }
      if (filename) {
        // Check if the filename has an extension.
        let extension = path.extname(filename)
        // If not try to determine the extension and append it to the filename.
        if (!extension && basename) {
          extension = path.extname(basename)
          if (extension) {
            filename += extension
          }
        }
      } else if (basename) {
        filename = basename
      }
    }

    return filename
  }

  determineFilenameStatement = (relation: Relation) =>
    (relation?.id && `polisblad-${relation.id}.pdf`) || ''

  determineFilenameGreenCard = (policy: Policy) =>
    (policy?.id && `verzekeringsbewijs-polis-${policy.id}.pdf`) || ''

  determineFilenameCondition = (condition: Condition) => {
    let filepath = ''
    if (condition?.id) {
      const extension = (condition.url && path.extname(condition.url)) || '.pdf'
      filepath = `voorwaarden-${condition.id}${extension}`
    }
    return filepath
  }

  determineFilenameInvoice = (purchase: Purchase) => {
    let filepath = ''
    if (purchase?.id) {
      const extension = (purchase.invoiceFilename && path.extname(purchase.invoiceFilename)) || '.pdf'
      filepath = `factuur-${purchase.id}${extension}`
    }
    return filepath
  }

  determineFilenameServiceGuide = (serviceGuide: ServiceGuide) => {
    let filepath = ''
    if (serviceGuide?.id) {
      const extension = (serviceGuide.url && path.extname(serviceGuide.url)) || '.pdf'
      filepath = `dvd-${serviceGuide.id}${extension}`
    }
    return filepath
  }

  downloadFile = async (url: string, fileName: string) => {
    try {
      let filePath: string
      // Some files are saved without an extension.
      // The url contains the extension, so add it to the file name if it is missing.
      if (this.isMissingFileExtension(fileName)) {
        const fileExtension = url.split('.').pop()
        filePath = `${FileSystem.documentDirectory}${fileName}.${fileExtension}`
      } else {
        filePath = `${FileSystem.documentDirectory}${fileName}`
      }

      const result = await this.download(url, filePath)

      return {
        success: result?.status === 200,
        ...result
      }
    } catch (err) {
      this.logError(err)
      return {
        success: false
      }
    }
  }

  isMissingFileExtension = (fileName: string) => {
    return fileName.split('.').length === 1
  }
}

export default new Fs()
