import { StyleSheet, View } from 'react-native'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import { apiCall } from '@/redux/actions/api'
import { openModalEmergencyNumber } from '@/redux/actions/modals'
import { connect } from 'react-redux'
import Styling from '@/constants/Styling'
import { decode } from 'html-entities'
import React from 'react'
import Text from '@/components/touchables/TouchableText'
import type { Agency as IAgency, IAgencyLocation, OpeningHour } from '@/types/objects'
import Icon from '@/components/Icon'
import common from '@/utils/common'
import type { ThemeColors } from '@/redux/reducers/theme'
import Colors from '@/constants/Colors'
import navigation from '@/utils/navigation'
import TouchableOpacity from '@/components/touchables/Touchable'
import OpeningHourDot from '@/components/OpeningHourDot'

interface OfficeInfoProps {
  agency: IAgency
  mainLocation: IAgencyLocation
  colors: ThemeColors
  currentOpeningHour: OpeningHour
  nextOpeningHour: OpeningHour
}

function getOpeningConditionLabel (openingCondition: number, currentOpeningHour: OpeningHour, nextOpeningHour: OpeningHour) {
  switch (openingCondition) {
    case -1:
      return `Nog dicht, opent ${(currentOpeningHour.day || '').toLowerCase()} om ${currentOpeningHour.opening}`
    case 0:
      return `Geopend, sluit om ${currentOpeningHour.closing}`
    case 1: {
      if (nextOpeningHour) {
        return `Gesloten, opent ${(nextOpeningHour.day).toLowerCase()} om ${nextOpeningHour.opening}`
      }

      return 'Gesloten'
    }
    default:
      return 'Gesloten'
  }
}

const OfficeInfo = ({
  agency,
  mainLocation,
  colors,
  currentOpeningHour,
  nextOpeningHour
}: OfficeInfoProps) => {
  const openingCondition = common.determineOpeningCondition(currentOpeningHour)

  const handlePhone = () => {
    common.openPhoneNumber(mainLocation.phoneNumber)
  }

  const handleEmail = () => {
    common.openEmail(mainLocation.email)
  }

  const handleOpenProfile = () => {
    navigation.openScreenAgency()
  }

  return (
    <View style={styles.component}>
      <View style={styles.header}>
        <Text
          style={Styling.marginTopSmall}
          type='descriptionLight'
        >
          {decode(agency.name)}
        </Text>
      </View>
      <View style={styles.info}>
        <View style={styles.infoProperty}>
          <View style={styles.infoPropertyIcon}>
            <OpeningHourDot openingCondition={openingCondition} />
          </View>
          <Text
            type='note'
            style={styles.infoPropertyLabel}
          >
            {getOpeningConditionLabel(openingCondition as number, currentOpeningHour, nextOpeningHour)}
          </Text>
        </View>

        <TouchableOpacity
          style={styles.infoProperty}
          onPress={handlePhone}
        >
          <View style={styles.infoPropertyIcon}>
            <Icon
              icon='phone'
              color={colors?.brand}
            />
          </View>
          <Text
            type='note'
            style={styles.infoPropertyLabel}
            onPress={handlePhone}
          >
            {mainLocation.phoneNumber}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.infoProperty}
          onPress={handleEmail}
        >
          <View style={styles.infoPropertyIcon}>
            <Icon
              icon='envelope'
              color={colors?.brand}
            />
          </View>
          <Text
            type='note'
            onPress={handleEmail}
            style={styles.infoPropertyLabel}
          >
            {mainLocation.email}
          </Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        onPress={handleOpenProfile}
        style={styles.footer}
      >
        <View
          style={styles.profile}
        >
          <View style={styles.profileIcon}>
            <Icon
              small
              color={Colors.gray}
              icon={['fal', 'chevron-right']}
            />
          </View>
          <Text
            onPress={handleOpenProfile}
            type='note'
            style={[styles.profileLabel, {
              color: colors?.brand
            }]}
          >
            Bekijk het volledige profiel
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  component: {},
  header: {
    marginBottom: 16
  },
  info: {},
  infoProperty: {
    paddingTop: 8,
    paddingBottom: 8,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  infoPropertyIcon: {
    marginRight: 8
  },
  infoPropertyLabel: {},
  footer: {
    paddingTop: 16,
    paddingBottom: 16
  },
  profile: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  profileIcon: {
    marginRight: 8
  },
  profileLabel: {}
})

const findNextOpeningHour = (openingHours: OpeningHour[]): OpeningHour => {
  const currentDayNumber = new Date().getDay()

  let openingHour = openingHours
    // Only keep the openingHour days after today
    .filter((openingHour) => openingHour.dayNumber > currentDayNumber)
    // Find the first openingHour which isn't closed
    .find((openingHour) => openingHour.closed === 'false')

  if (!openingHour) {
    // The rest of the week didn't contain any openingHours that aren't closed
    // Let's cycle again from the beginning
    openingHour = openingHours.find((openingHour) => openingHour.closed === 'false')
  }

  return openingHour!
}

const mapStateToProps = (state: TState) => {
  const currentDay = new Date().getDay()
  const openingHours = state.agency?.data?.openingHours ?? []
  const showOpeningHours = state.agency?.data?.showOpeningHours
  const currentOpeningHour = openingHours.find((openingHour) => openingHour.dayNumber === currentDay)
  const nextOpeningHour = findNextOpeningHour(openingHours.sort((a, b) => a.dayNumber - b.dayNumber))

  return {
    agency: selectors.getAgency(state),
    agencyId: selectors.getAgencyId(state),
    agents: selectors.getAgents(state),
    colors: selectors.getThemeColors(state),
    currentOpeningHour,
    mainLocation: selectors.getMainLocation(state),
    nextOpeningHour,
    openingHours,
    showOpeningHours,
    refreshing: selectors.isLoading('AGENCY')(state)
  }
}

const mapDispatchToProps = {
  apiCall,
  openModalEmergencyNumber
}

export default connect(mapStateToProps, mapDispatchToProps)(OfficeInfo)
