import { put, select, takeLatest } from 'redux-saga/effects'

import * as ACTIONS from '@/redux/actions/relationRepository'
import * as api from '@/redux/api/relations'
import { RELATION_UPDATE_EMAIL } from '@/redux/constants/relationRepository'
import type { Agency, Relation, User } from '@/types/objects'
import type { IApiResponse } from '@/utils/api_wrapper'
import apiWrapper from '@/utils/api_wrapper'
import toast from '@/utils/toast'

import { updateToken } from '../actions/user'
import selectors from '../selectors'
import callApi from '../api'
import { apiCall } from '../actions/api'

function * updateEmailAddress (action: ReturnType<typeof ACTIONS.updateEmail>) {
  try {
    apiWrapper.cancelRequests()

    const agencyId: Agency['id'] = yield select(selectors.getAgencyId)
    const relationId: Relation['id'] = yield select(selectors.getCurrentRelationId)

    const result: IApiResponse<User> = yield callApi(api.updateEmailAddress, relationId, {
      ...action?.payload,
      agencyId
    })

    if (result?.message) {
      toast(result?.message)
    }

    if (result?.success) {
      const userId: User['id'] = yield select(selectors.getUserId)

      yield put(ACTIONS.updateEmailSuccess())
      yield put(updateToken(result?.data?.token))
      yield put(apiCall('GET', 'USER', relationId, userId, true))
    } else {
      throw new Error(result?.message ?? 'Er is iets fout gegaan, probeer het later opnieuw')
    }
  } catch (error) {
    yield put(ACTIONS.updateEmailFailed(error))
  }
}

export function * relationRepositorySaga () {
  yield takeLatest(RELATION_UPDATE_EMAIL, updateEmailAddress)
}
