import i18n from '@/utils/i18n'

export const RESET_FORM_CLAIM_DAMAGE = 'RESET_FORM_CLAIM_DAMAGE'
export const SET_FORM_CLAIM_DAMAGE_ID = 'SET_FORM_CLAIM_DAMAGE_ID'
export const UPDATE_FORM_CLAIM_DAMAGE_DYNAMIC = 'UPDATE_FORM_CLAIM_DAMAGE_DYNAMIC'
export const UPDATE_FORM_CLAIM_DAMAGE_STATIC = 'UPDATE_FORM_CLAIM_DAMAGE_STATIC'

export const staticClaimQuestionLabels = {
  dateOfDamage: i18n.t('Wat is de datum waarop de schade is ontstaan?'),
  description: i18n.t('Geef een gedetailleerde omschrijving van de schade'),
  policyId: i18n.t('Op welke polis wilt u schade melden?'),
  phoneNumber: 'Wat is uw telefoonnummer?',
  damageResolved: 'Is de schade al volledig hersteld/gerepareerd?',
  invoiceRecieved: 'Heeft u hier nota(s) voor ontvangen?',
  addInvoices: 'Voeg de nota(s) toe',
  addAttachment: 'Voeg bijlage(n) toe'
}
