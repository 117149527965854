export const RESET_TOKEN = 'RESET_TOKEN'

export const SET_FACIAL_RECOGNITION = 'SET_FACIAL_RECOGNITION'
export const SET_FINGERPRINT = 'SET_FINGERPRINT'
export const SET_PINCODE = 'SET_PINCODE'

export const UPDATE_PUSH_TOKEN = 'UPDATE_PUSH_TOKEN'
export const UPDATE_TOKEN = 'UPDATE_TOKEN'
export const UPDATE_TOKEN_EXPIRES_AT = 'UPDATE_TOKEN_EXPIRES_AT'
export const UPDATE_TOKEN_EXTENDED_AT = 'UPDATE_TOKEN_EXTENDED_AT'
export const UPDATE_USER = 'UPDATE_USER'

export const LOGGED_IN = 'LOGGED_IN'
export const LOGOUT = 'LOGOUT'
export const LOCK = 'LOCK'

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'

export const USER_ID_SENT_TO_ONESIGNAL = 'USER_ID_SENT_TO_ONESIGNAL'
