import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { Platform, StyleSheet, View } from 'react-native'

import ContextSpacing from '@/bootstrap/ContextSpacing'
import type { SpacingProp } from '@/types/props'
import prototype from '@/utils/prototype'

interface TRowProps {
  children?: React.ReactNode
  gutterHorizontal?: number
  gutterVertical?: number
  style?: StyleProp<ViewStyle>
  spacing?: SpacingProp
}

export default function Row (props: TRowProps) {
  let spacing: SpacingProp = null
  let style: StyleProp<ViewStyle> = null
  if (prototype.isNumber(props.spacing)) {
    spacing = Number(props.spacing)
  } else if (prototype.isNumber(props.gutterHorizontal) || prototype.isNumber(props.gutterVertical)) {
    spacing = {}
    style = {}
    if (prototype.isNumber(props.gutterHorizontal)) {
      spacing.horizontal = props.gutterHorizontal as number
      style.marginHorizontal = (props.gutterHorizontal as number) / -2
    }
    if (prototype.isNumber(props.gutterVertical)) {
      spacing.vertical = props.gutterVertical as number
      style.marginVertical = (props.gutterVertical as number) / -2
    }
  }
  const Children = <View style={[styles.view, style, props.style]}>{props.children}</View>
  if (spacing === null) {
    return Children
  }
  return <ContextSpacing.Provider value={spacing}>{Children}</ContextSpacing.Provider>
}

const styles = StyleSheet.create({
  view: {
    // Do not use 'flex: 1'.
    flexDirection: 'row',
    flexWrap: 'wrap',
    // On web set the css property overflow-y to 'auto'
    ...(Platform.OS === 'web' ? { overflowY: 'auto' } : {})
  }
})
