import React from 'react'
import type { GestureResponderEvent, StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

import Action from '@/components/Action'
import Styling from '@/constants/Styling'

interface TActionsProps {
  labelDown?: string
  labelLeft?: string
  labelRight?: string
  labelUp?: string
  onPressDown?: (event: GestureResponderEvent) => void
  onPressLeft?: (event: GestureResponderEvent) => void
  onPressRight?: (event: GestureResponderEvent) => void
  onPressUp?: (event: GestureResponderEvent) => void
  style?: StyleProp<ViewStyle>
}

export default function Actions (props: TActionsProps) {
  return (
    <View style={props.style ? [styles.container, props.style] : styles.container}>
      {props.labelLeft
        ? (
          <Action
            label={props.labelLeft}
            onPress={props.onPressLeft}
            style={props.labelRight || props.labelUp || props.labelDown ? Styling.marginRight : null}
            type='left'
          />
          )
        : null}
      {props.labelRight
        ? (
          <Action
            label={props.labelRight}
            onPress={props.onPressRight}
            style={props.labelUp || props.labelDown ? Styling.marginRight : null}
            type='right'
          />
          )
        : null}
      {props.labelUp
        ? (
          <Action
            label={props.labelUp}
            onPress={props.onPressUp}
            style={props.labelDown ? Styling.marginRight : null}
            type='up'
          />
          )
        : null}
      {props.labelDown
        ? (
          <Action
            label={props.labelDown}
            onPress={props.onPressDown}
            type='down'
          />
          )
        : null}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
})
