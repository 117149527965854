import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import Svg, { Circle, Defs, Ellipse, G, Path } from 'react-native-svg'

interface TAddUserProps {
  style?: StyleProp<ViewStyle>
  width?: number
}

const defaultWidth = 228
const defaultHeight = 135
export const ratio = defaultHeight / defaultWidth

export default function SvgComponent (props: TAddUserProps) {
  const width = props.width || defaultWidth
  const height = props.width ? width * ratio : defaultHeight
  return (
    <Svg
      height={height}
      style={props.style}
      viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}
      width={width}
    >
      <Defs />
      <G
        fill='none'
        fillRule='evenodd'
      >
        {/* @ts-expect-error ts-migrate(2322) FIXME: Property 'filter' does not exist on type 'Intrinsi... Remove this comment to see the full error message */}
        <Ellipse
          cx={114}
          cy={114}
          fill='#FBFAFA'
          filter='url(#prefix__a)'
          rx={114}
          ry={21}
        />
        <Path
          d='M113 57.25c15.742 0 28.5-12.758 28.5-28.5S128.742.25 113 .25s-28.5 12.758-28.5 28.5 12.758 28.5 28.5 28.5zm19.95 7.125c16.52 0 29.925 13.404 29.925 29.925v9.262c0 5.9-4.787 10.688-10.688 10.688H73.812c-5.9 0-10.687-4.787-10.687-10.688V94.3c0-16.521 13.404-29.925 29.925-29.925h3.718c4.966 2.271 10.443 3.563 16.232 3.563a38.801 38.801 0 0016.232-3.563h3.718z'
          fill='#84726F'
          fillRule='nonzero'
        />
        <G transform='translate(131 39)'>
          <Circle
            cx={26.64}
            cy={26.64}
            fill='#FFF'
            r={26.64}
          />
          <Path
            d='M26.64 4C14.31 4 4.32 13.99 4.32 26.32s9.99 22.32 22.32 22.32 22.32-9.99 22.32-22.32S38.97 4 26.64 4zM39.6 28.84c0 .594-.486 1.08-1.08 1.08h-8.28v8.28c0 .594-.486 1.08-1.08 1.08h-5.04c-.594 0-1.08-.486-1.08-1.08v-8.28h-8.28c-.594 0-1.08-.486-1.08-1.08V23.8c0-.594.486-1.08 1.08-1.08h8.28v-8.28c0-.594.486-1.08 1.08-1.08h5.04c.594 0 1.08.486 1.08 1.08v8.28h8.28c.594 0 1.08.486 1.08 1.08v5.04z'
            fill='#E7E4E2'
            fillRule='nonzero'
          />
        </G>
      </G>
    </Svg>
  )
}
