/* eslint-disable import/extensions */

// Import the internationalization libraries in the root of the application.
import 'intl'
// To support all locales the 'intl/locale-data/complete.js' file should be imported instead of separate import statements.
import 'intl/locale-data/jsonp/nl.js'
import 'intl/locale-data/jsonp/en.js'

// Required import for 'uuid' library
import 'react-native-get-random-values'

import './utils/initialize_icons'

import { enableScreens } from 'react-native-screens'
import AppLoading from 'expo-app-loading'
import * as Analytics from '@/utils/firebase/analytics'
import * as Font from 'expo-font'
import * as ScreenOrientation from 'expo-screen-orientation'
import React from 'react'
import { BackHandler, Platform } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { gestureHandlerRootHOC } from 'react-native-gesture-handler'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import Config from 'react-native-config'
import * as Sentry from '@sentry/react'

import * as RemoteConfig from '@/utils/firebase/remote_config'

import * as Bootstrap from '@/bootstrap'
import Settings from '@/constants/Settings'
import ModalEmergencyNumber from '@/modals/ModalEmergencyNumber'
import ModalHelp from '@/modals/ModalHelp'
import ModalLogs from '@/modals/ModalLogs'
import ModalSession from '@/modals/ModalSession'
import Navigator from '@/navigation'
import { store, persistor } from '@/redux/configureStore'
import locationUtil from '@/utils/location'
import logger from '@/utils/logger'
import navigation from '@/utils/navigation'

import UpdateAppModal from './modals/update_app_modal'
import { IS_WEB } from '@/constants/platform'

import git from '../git.json'
import { initializeOneSignal } from './utils/initialize_onesignal'

Analytics.initialize()

enableScreens()

if (IS_WEB) {
  Sentry.init({
    dsn: 'https://733562edad3f4aab820070bcaaf70317@o877352.ingest.sentry.io/5707134',
    release: Config.APP_ENV
  })
}

locationUtil.setApiKey('AIzaSyCNOJXwCHH5g-frntuP4Q1KWkVxYMfE_cI')

interface TAppState {
  loaded: boolean
}

class App extends React.Component<unknown, TAppState> {
  state = {
    loaded: false
  }

  componentDidMount () {
    this.lockScreenOrientation()

    if (Platform.OS === 'android') {
      BackHandler.addEventListener('hardwareBackPress', navigation.handleBackPress)
    }

    if (Platform.OS === 'web') {
      try {
        // This is done to make it easier for Web to see which version is running in production.
        document.getElementsByTagName('HTML')[0].setAttribute('branch', git?.branch)
        document.getElementsByTagName('HTML')[0].setAttribute('sha', git?.sha)
        document.getElementsByTagName('HTML')[0].setAttribute('environment', Settings.environment)
      } catch (error) {
        this.logError(error)
      }
    } else {
      RemoteConfig.onBoot()
    }
  }

  lockScreenOrientation = async () => {
    if (Platform.OS === 'web') {
      return
    }

    try {
      const orientation = await ScreenOrientation.getOrientationAsync()
      const enumConversion: { [key: number]: ScreenOrientation.OrientationLock } = {
        [ScreenOrientation.Orientation.LANDSCAPE_LEFT as number]: ScreenOrientation.OrientationLock.LANDSCAPE_LEFT,
        [ScreenOrientation.Orientation.LANDSCAPE_RIGHT as number]: ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT,
        [ScreenOrientation.Orientation.PORTRAIT_DOWN as number]: ScreenOrientation.OrientationLock.PORTRAIT_DOWN,
        [ScreenOrientation.Orientation.PORTRAIT_UP as number]: ScreenOrientation.OrientationLock.PORTRAIT_UP
      }

      if (orientation in enumConversion) {
        await ScreenOrientation.lockAsync(enumConversion[orientation])
      }
    } catch (error) {
      this.logError(error)
    }
  }

  logError = (error: Error) => logger.warn(String(error), error)

  setLoaded = () => this.setState({ loaded: true })

  onError = (error: Error) => {
    this.logError(error)
    this.setLoaded()
  }

  fetchFonts = async () =>
    await Font.loadAsync({
      'montserrat-medium': require('./assets/fonts/Montserrat/Montserrat-Medium.ttf'), // eslint-disable-line global-require
      'montserrat-regular': require('./assets/fonts/Montserrat/Montserrat-Regular.ttf') // eslint-disable-line global-require
    })

  onBeforeLift = () => {
    initializeOneSignal()
  }

  render () {
    if (!this.state.loaded) {
      return (
        <AppLoading
          onError={this.onError}
          onFinish={this.setLoaded}
          startAsync={this.fetchFonts}
        />
      )
    }

    return (
      <SafeAreaProvider>
        <Bootstrap.Root>
          <Provider store={store}>
            <PersistGate
              persistor={persistor}
              onBeforeLift={this.onBeforeLift}
            >
              <ActionSheetProvider>
                <>
                  <Navigator />
                  <ModalEmergencyNumber />
                  <ModalHelp />
                  <ModalLogs />
                  <ModalSession />
                  {Platform.OS !== 'web' ? <UpdateAppModal /> : null}
                </>
              </ActionSheetProvider>
            </PersistGate>
          </Provider>
        </Bootstrap.Root>
      </SafeAreaProvider>
    )
  }
}

export default gestureHandlerRootHOC(App)
