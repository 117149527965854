import { decode } from 'html-entities'
import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import date from '@/utils/date'
import common from '@/utils/common'
import data from '@/utils/data'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import ButtonCircle from '@/components/buttons/ButtonCircle'
import ImageAutoHeight from '@/components/images/ImageAutoHeight'
import Screen from '@/components/Screen'
import type { Route } from '@/components/Tabs'
import Tabs from '@/components/Tabs'
import RefreshControl from '@/components/wrappers/RefreshControl'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Settings from '@/constants/Settings'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import ItemAgent from '@/screens/agency/components/ItemAgent'
import OpeningHours from '@/screens/agency/components/OpeningHours'

import { apiCall } from '@/redux/actions/api'
import { openModalEmergencyNumber } from '@/redux/actions/modals'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'

import type { Agency as AgencyObj, Agent, IAgencyLocation } from '@/types/objects'
import type { ThemeColors } from '@/redux/reducers/theme'

const intervalDuration = 60000
interface TAgencyProps {
  agency: AgencyObj
  agencyId: AgencyObj['id']
  agents?: AgencyObj['agents']
  colors: ThemeColors
  currentOpeningHour?: any
  nextOpeningHour?: any
  openModalEmergencyNumber: typeof openModalEmergencyNumber
  showOpeningHours: boolean
  openingHours?: any[]
  refreshing: boolean
  mainLocation: IAgencyLocation
  apiCall: typeof apiCall
}

interface TAgencyState {
  openingCondition?: number
}

const routes = [
  {
    key: 'general',
    title: i18n.t('Algemeen')
  },
  {
    key: 'agents',
    title: i18n.t('Adviseurs')
  }
]

class Agency extends React.Component<TAgencyProps, TAgencyState> {
  interval: any

  constructor (props: TAgencyProps) {
    super(props)

    const openingCondition = common.determineOpeningCondition(props.currentOpeningHour)

    this.state = {
      openingCondition
    }
  }

  componentDidMount () {
    this.refresh()
    this.interval = setInterval(() => {
      const openingCondition = common.determineOpeningCondition(this.props.currentOpeningHour)
      this.setState({ openingCondition })
    }, intervalDuration)
  }

  componentWillUnmount () {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  refresh = () => this.props.apiCall('GET', 'AGENCY', this.props.agencyId)

  openWhatsapp = () => common.openWhatsapp(this.props.mainLocation.whatsapp)

  openPhoneNumber = () => common.openPhoneNumber(this.props.mainLocation.phoneNumber)

  openEmail = () => common.openEmail(this.props.mainLocation.email)

  openWebsite = () => common.openWebsite(this.props.agency.website)

  keyExtractor = (agent: Agent) => `list-item-agent-${agent.id}`

  renderItem = ({ item }: { item: Agent }) => <ItemAgent agent={item} />

  renderRefreshControl = () => (
    <RefreshControl
      onRefresh={this.refresh}
      refreshing={this.props.refreshing}
      title={i18n.t('Gegevens van de adviseur aan het ophalen')}
    />
  )

  renderGeneral = () => {
    const agency = this.props.agency || {}

    return (
      <View style={styles.contentContainer}>
        {this.props.agency?.name
          ? (
            <Text
              style={[Styling.marginBottomSmall, { fontSize: 18 }]}
              type='descriptionBold'
            >
              {decode(this.props.agency.name)}
            </Text>
            )
          : null}

        <Text
          style={Styling.marginBottomSmall}
          type='subsection'
        >
          {i18n.t('Adres')}
        </Text>
        <Text
          style={Styling.marginBottomMedium}
          type='description'
        >
          {data.getAgencyAddress(agency)}
        </Text>

        {this.props.showOpeningHours && this.props.openingHours?.length
          ? (
            <View style={Styling.marginBottom}>
              <Text
                style={Styling.marginBottomSmall}
                type='subsection'
              >
                {i18n.t('Openingstijden')}
              </Text>
              <OpeningHours
                currentOpeningHour={this.props.currentOpeningHour}
                openingCondition={this.state.openingCondition}
                openingHours={this.props.openingHours}
              />
            </View>
            )
          : null}

        <Text type='paragraphLight'>
          {i18n.t('Buiten kantooruren kunt u in gevallen van nood bellen met de alarmcentrale van Nh1816 op')}{' '}
          <Text
            onPress={this.props.openModalEmergencyNumber}
            type='paragraphError'
          >
            {Settings.emergencyNumber}
          </Text>
        </Text>

        {agency.serviceGuides?.length
          ? (
            <View style={Styling.marginTopMedium}>
              <Text
                style={Styling.marginBottomSmall}
                type='subsection'
              >
                {i18n.t('Onze diensten')}
              </Text>

              {agency.serviceGuides.map((serviceGuide, index) => (
                <Button
                  color={this.props.colors?.brand}
                  key={`service-guide-${serviceGuide.id}`}
                  onPress={async () => await navigation.openScreenServiceGuide(serviceGuide)}
                  style={index !== 0 && Styling.marginTopSmall}
                  title={serviceGuide.title}
                />
              ))}
            </View>
            )
          : null}

        {agency.website
          ? (
            <View style={Styling.marginTopMedium}>
              <Text
                style={Styling.marginBottomSmall}
                type='subsection'
              >
                {i18n.t('Meer informatie?')}
              </Text>
              <Button
                color={this.props.colors?.brand}
                title='Bekijk de website'
                onPress={this.openWebsite}
              />
            </View>
            )
          : null}
      </View>
    )
  }

  renderScene = ({ route }: { route: Route }) => {
    if (route.key === 'general') {
      return this.renderGeneral()
    }

    if (route.key === 'agents') {
      return (
        <Bootstrap.Collection
          data={this.props.agents}
          gutter={15}
          id='agency-phone-collection'
          keyExtractor={this.keyExtractor}
          margin={30}
          renderItem={this.renderItem}
          span={{ lg: 6, md: 6, sm: 6, xl: 6, xs: 12 }}
        />
      )
    }

    return null
  }

  render () {
    const agency = this.props.agency || {}
    const mainLocation = this.props.mainLocation || {}
    const currentOpeningHour = this.props.currentOpeningHour || {}
    const nextOpeningHour = this.props.nextOpeningHour || {}

    return (
      <Screen
        noPadding
        white
        refreshControl={this.renderRefreshControl()}
      >
        <View style={styles.containerCenter}>
          {Platform.OS !== 'web' && agency.logo
            ? (
              <ImageAutoHeight
                style={Styling.marginBottomMedium}
                uri={`${agency.logo}?${date.now()}`}
                width={150}
              />
              )
            : null}

          {this.state.openingCondition === -1
            ? (
              <Text type='paragraphLight'>
                {i18n.t('Gesloten, opent')} {(currentOpeningHour.day || '').toLowerCase()} om{' '}
                {currentOpeningHour.opening}
              </Text>
              )
            : null}

          {this.state.openingCondition === 0
            ? (
              <Text type='paragraphSuccess'>Geopend, sluit om {currentOpeningHour.closing}</Text>
              )
            : null}

          {this.state.openingCondition === 1
            ? (
              <Text type='paragraphLight'>
                {i18n.t('Gesloten, opent')} {(nextOpeningHour.day || '').toLowerCase()} om {nextOpeningHour.opening}
              </Text>
              )
            : null}

          <View style={styles.buttonContainer}>
            <ButtonCircle
              color={this.props.colors?.brand}
              disabled={!mainLocation.phoneNumber}
              icon='phone'
              onPress={this.openPhoneNumber}
              title={i18n.t('Bellen')}
            />

            <ButtonCircle
              color={this.props.colors?.brand}
              disabled={!mainLocation.email}
              icon='envelope'
              onPress={this.openEmail}
              title={i18n.t('Bericht')}
            />

            {mainLocation.whatsapp
              ? (
                <ButtonCircle
                  color={Colors.whatsapp}
                  icon={['fab', 'whatsapp']}
                  onPress={this.openWhatsapp}
                  title={i18n.t('WhatsApp')}
                />
                )
              : (
                <ButtonCircle
                  color={this.props.colors?.brand}
                  disabled={!agency.website}
                  icon='globe'
                  onPress={this.openWebsite}
                  title={i18n.t('Website')}
                />
                )}

            <ButtonCircle
              color={Colors.darkRed}
              icon='phone-plus'
              onPress={this.props.openModalEmergencyNumber}
              title={i18n.t('Nood')}
            />
          </View>
        </View>

        {this.props.agents?.length
          ? <Tabs
              scrollEnabled
              renderScene={this.renderScene}
              routes={routes}
              tabBarStyle={styles.tabBar}
            />
          : this.renderGeneral()}
      </Screen>
    )
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Sizes.spacingVerticalMedium,
    width: '100%'
  },
  container: {
    backgroundColor: Colors.white,
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingTop: Sizes.screenPaddingVertical
  },
  containerCenter: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingTop: Sizes.screenPaddingVertical
  },
  contentContainer: {
    backgroundColor: Colors.white,
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingVertical: Sizes.screenPaddingVertical
  },
  tabBar: {
    marginTop: Sizes.screenPaddingVertical
  }
})

const mapStateToProps = (state: TState) => {
  const currentDay = new Date().getDay()
  const openingHours = state.agency?.data?.openingHours
  const showOpeningHours = state.agency?.data?.showOpeningHours
  const currentOpeningHour = openingHours?.find((o) => o.dayNumber === currentDay)
  const nextOpeningHour =
    openingHours?.find((o) => o.dayNumber > currentDay) ||
    openingHours?.reduce(
      (lowest, current) => (lowest.dayNumber < current.dayNumber ? lowest : current),
      openingHours[0]
    )

  return {
    agency: selectors.getAgency(state),
    agencyId: selectors.getAgencyId(state, null)!,
    agents: selectors.getAgents(state),
    colors: selectors.getThemeColors(state),
    currentOpeningHour,
    mainLocation: selectors.getMainLocation(state),
    nextOpeningHour,
    showOpeningHours,
    openingHours,
    refreshing: !!selectors.isLoading('AGENCY')(state)
  }
}

const mapDispatchToProps = {
  apiCall,
  openModalEmergencyNumber
}

export default connect(mapStateToProps, mapDispatchToProps)(Agency)
