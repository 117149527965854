import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { View } from 'react-native'

import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'

interface TListUnorderedProps {
  items: string[]
  prefix?: string
  style?: StyleProp<ViewStyle>
  type?: string
}

export default function ListUnordered (props: TListUnorderedProps) {
  return (
    <View style={props.style}>
      {(props.items || []).map((item, index) => (
        <Text
          // eslint-disable-next-line react/no-array-index-key
          key={`list-unordered-item-${item}-${index}`}
          style={index !== 0 && Styling.marginTopSmall}
          type={props.type || 'description'}
        >
          {props.prefix ? `${props.prefix} ` : ''}
          {item}
        </Text>
      ))}
    </View>
  )
}
