import type { State as TState } from '@/redux/reducers'
import { createSelector } from 'reselect'
import type { WtdCategory } from '@/redux/reducers/support'

export const getAboutMembership = (state: TState) => state.support?.aboutMembership

export const getAboutNh = (state: TState) => state.support?.aboutNh

export const getWtd = (state: TState) => state.support.wtd
  .sort((a, b) => a.sortNumber - b.sortNumber)

export const getWtdItemByCategory = (wtdCategory: WtdCategory) => createSelector(getWtd, wtd => wtd.find(w => w.category === wtdCategory))
