import React from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'

export default function ListOrdered (props: any) {
  return (props.items || []).map((item: any, index: any) => (
    // eslint-disable-next-line react/no-array-index-key
    <View
      key={`list-ordered-item-${item}-${index}`}
      style={[Styling.row, index !== 0 && Styling.marginTop]}
    >
      <View style={styles.width}>
        <Text type='description'>{index + 1}.</Text>
      </View>
      <Text type='description'>{item}</Text>
    </View>
  ))
}

const styles = StyleSheet.create({
  width: {
    width: 20
  }
})
