import * as Clipboard from 'expo-clipboard'
import * as WebBrowser from 'expo-web-browser'
import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import type { IMemberBenefit } from '@/types/objects'
import i18n from '@/utils/i18n'
import toast from '@/utils/toast'

interface IProps {
  memberBenefit: IMemberBenefit
}

const VoucherCode: React.FC<IProps> = ({ memberBenefit }) => {
  const copyVoucherCode = (): void => {
    Clipboard.setString(memberBenefit?.voucherCode ?? '')
    toast(i18n.t('De code is gekopieerd naar uw klembord.'))
  }

  const openVoucherPdf = (): void => {
    memberBenefit?.voucherPdf && WebBrowser.openBrowserAsync(memberBenefit?.voucherPdf)
  }

  const handleOnPress = (): void => {
    if (memberBenefit?.isVoucherCode) {
      copyVoucherCode()
    } else if (memberBenefit?.isPdfVoucher) {
      openVoucherPdf()
    }
  }

  return (
    <TouchableOpacity
      onPress={handleOnPress}
      style={styles.container}
    >
      {memberBenefit?.isVoucherCode
        ? (
          <>
            <Text
              style={styles.smallText}
              type='buttonSmall'
            >
              {i18n.t('Uw kortingscode:')}
            </Text>

            <Text
              style={styles.code}
              type='button'
            >
              {memberBenefit?.voucherCode}
            </Text>

            <Text
              style={styles.smallText}
              type='buttonSmall'
            >
              {i18n.t('Klik op de knop om de code te kopiëren')}
            </Text>
          </>
          )
        : null}

      {memberBenefit?.isPdfVoucher ? <Text type='buttonSmall'>{i18n.t('Voucher openen')}</Text> : null}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  code: {
    fontSize: 25,
    marginVertical: 10
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.primary,
    borderRadius: 6,
    justifyContent: 'center',
    padding: 15
  },
  smallText: {
    fontSize: 12
  }
})

export default VoucherCode
