import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import Svg, { Defs, Ellipse, G, Path } from 'react-native-svg'

interface THandshakeProps {
  style?: StyleProp<ViewStyle>
  width?: number
}

const defaultWidth = 228
const defaultHeight = 130
export const ratio = defaultHeight / defaultWidth

export default function SvgComponent (props: THandshakeProps) {
  const width = props.width || defaultWidth
  const height = props.width ? width * ratio : defaultHeight
  return (
    <Svg
      height={height}
      style={props.style}
      viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}
      width={width}
    >
      <Defs />
      <G
        fill='none'
        fillRule='evenodd'
      >
        {/* @ts-expect-error ts-migrate(2322) FIXME: Property 'filter' does not exist on type 'Intrinsi... Remove this comment to see the full error message */}
        <Ellipse
          cx={114}
          cy={109}
          fill='#FBFAFA'
          filter='url(#prefix__a)'
          rx={114}
          ry={21}
        />
        <Path
          d='M188.1 15.6c2.145 0 3.9 1.73 3.9 3.876v46.75c0 2.146-1.755 3.9-3.9 3.9h-23.79c-.658-3.51-2.438-6.824-5.411-9.237l-35.49-28.812 6.361-5.825c1.585-1.463 1.707-3.925.244-5.509a3.918 3.918 0 00-5.509-.244l-19.5 17.843-.097.049c-5.143 4.606-11.334 1.9-13.674-.659-3.462-3.753-3.56-9.823.512-13.65l.073-.097 23.96-21.937A7.797 7.797 0 01121.044 0h20.938c2.072 0 4.047.829 5.51 2.291L160.8 15.6h27.3z'
          fill='#988C83'
          fillRule='nonzero'
        />
        <Path
          d='M153.95 66.958l-36.392-29.542-7.312 6.703c-9.896 9.043-21.157 2.9-24.79-1.073-6.556-7.166-6.044-18.257 1.073-24.79L106.468 0H86.042a7.786 7.786 0 00-5.509 2.291L67.2 15.6H39.9a3.912 3.912 0 00-3.9 3.9v46.776c0 2.145 1.755 3.9 3.9 3.9h31.76l22.06 19.962c6.679 5.436 16.502 4.412 21.937-2.266l.05-.05 4.362 3.779c3.876 3.169 9.604 2.56 12.748-1.316l7.654-9.409 1.316 1.073c3.34 2.705 8.263 2.218 10.969-1.146l2.316-2.852a7.814 7.814 0 00-1.122-10.993z'
          fill='#84726F'
        />
      </G>
    </Svg>
  )
}
