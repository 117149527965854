import { createSelector } from 'reselect'
import type { State } from '../reducers'

export const getNotifications = (state: State) =>
  state.notifications?.data?.sort((a, b) => b?.messageId - a?.messageId)

export const getShouldOpenNotifications = (state: State) =>
  state.notifications?.shouldOpenAfterLogin

export const getNotification = createSelector(
  [getNotifications, (_, id: string) => id],
  (notifications, id) => notifications?.find((notification) =>
    notification?.messageId?.toString() === id
  )
)

export const getUnreadNotifications = createSelector(
  getNotifications,
  (notifications) => notifications?.filter(x => !x.read)
)

export const getAmountUnreadNotifications = createSelector(
  getUnreadNotifications,
  (notifications) => notifications?.length ?? 0
)
