import React from 'react'
import { StyleSheet, View } from 'react-native'

import Button from '@/components/buttons/Button'
import Image from '@/components/images/image'
import ListItem from '@/components/list_items/ListItem'
import Text from '@/components/wrappers/Text'
import type { IMemberBenefit } from '@/types/objects'
import navigation from '@/utils/navigation'

interface IProps {
  memberBenefit: IMemberBenefit
}

const MemberBenefitListItem: React.FC<IProps> = ({ memberBenefit }) => {
  const openBenefit = (): void => {
    navigation.openScreenMemberBenefitsDetail(memberBenefit?.id)
  }

  return (
    <ListItem
      column
      flex
      item={memberBenefit}
      onPress={openBenefit}
    >
      <Image
        style={styles.image}
        uri={memberBenefit?.image}
      />

      <View style={styles.innerContainer}>
        <Text type='descriptionBold'>{memberBenefit?.title}</Text>

        <Text type='descriptionLight'>{memberBenefit?.intro}</Text>
      </View>

      <Button
        onPress={openBenefit}
        style={styles.button}
        title='Bekijken'
      />
    </ListItem>
  )
}

const styles = StyleSheet.create({
  button: {
    marginHorizontal: 25
  },

  image: {
    height: 200,
    width: '100%'
  },

  innerContainer: {
    flex: 1,
    padding: 25,
    paddingTop: 10
  }
})

export default MemberBenefitListItem
