import type {
  GeneratedGreenCard,
  Claim,
  Company,
  Condition,
  IHistoricalClaim,
  IMemberBenefit,
  INotification,
  Policy,
  Purchase,
  ServiceGuide,
  TipCategory,
  User
} from '@/types/objects'
import type { NavigationContainerRef } from '@react-navigation/native'
import { StackActions } from '@react-navigation/native'
import { Dimensions, Platform } from 'react-native'

import logger from '@/utils/logger'
import prototype from '@/utils/prototype'
import type { IAttachmentViewerProps } from '@/screens/pdf_viewer'
import type { WtdCategory } from '@/redux/reducers/support'
import getBreakpoint from '@/bootstrap/getBreakpoint'
import defaults from '@/bootstrap/defaults'
import { IS_WEB } from '@/constants/platform'

export const noHeaderOptions = {
  headerShown: false
}

class Navigation {
  navigator: NavigationContainerRef | null = null

  breakpoint = getBreakpoint(defaults.breakpoints, Dimensions.get('window').width)

  isSmallScreen = () => ['xxs', 'xs'].includes(this.breakpoint)

  log = (...params: any[]) => logger.log('Navigation:', ...params)

  init = (navigator: NavigationContainerRef) => {
    if (navigator) {
      this.navigator = navigator
    }
  }

  processParams = (params?: object): object => {
    if (Platform.OS === 'web') {
      return {
        claimId: undefined,
        policyId: undefined,
        title: undefined,
        ...(prototype.isObject(params) ? params : {})
      }
    }
    return params ?? {}
  }

  push = async (routeName: string, params?: object, navigator: NavigationContainerRef | null = this.navigator) => {
    this.log('push', routeName)
    if (navigator && typeof navigator.dispatch === 'function') {
      await navigator.dispatch(StackActions.push(routeName, this.processParams(params)))
      return true
    }
    return false
  }

  navigate = async (routeName: string, params?: object, navigator: NavigationContainerRef | null = this.navigator) => {
    this.log('navigate', routeName, params)

    if (navigator && typeof navigator.navigate === 'function') {
      await navigator.navigate(routeName, this.processParams(params))
      return true
    }

    return false
  }

  replace = async (routeName: string, params?: object, navigator = this.navigator) => {
    this.log('replace', routeName, params)
    if (navigator && typeof navigator.dispatch === 'function') {
      await navigator.dispatch(StackActions.replace(routeName, this.processParams(params)))
      return true
    }
    return false
  }

  back = async (navigator: NavigationContainerRef | null = this.navigator) => {
    this.log('back')
    if (navigator && typeof navigator.goBack === 'function') {
      await navigator.goBack()
      return true
    }
    return false
  }

  pop = async (count: number = 1, navigator: NavigationContainerRef | null = this.navigator) => {
    this.log('pop')
    if (navigator && typeof navigator.dispatch === 'function') {
      await navigator.dispatch(StackActions.pop(count))
      return true
    }
    return false
  }

  getRootState = (navigator: NavigationContainerRef | null = this.navigator) =>
    (navigator && typeof navigator.getRootState === 'function' && navigator.getRootState()) ?? null

  getHierarchicalRoutes = (navigator: NavigationContainerRef | null = this.navigator): object[] => {
    const hierarchicalRoutes: object[] = []
    let route = navigator?.getRootState()

    if (!route) {
      return hierarchicalRoutes
    }

    route.name = 'RootStack'
    hierarchicalRoutes.push(route)

    route = route?.routes?.[route?.index]

    while (route) {
      hierarchicalRoutes.push(route)
      route = route?.state?.routes?.[route?.state?.index]
    }

    return hierarchicalRoutes
  }

  getCurrentRoute = (navigator: NavigationContainerRef | null = this.navigator) =>
    navigator?.getCurrentRoute?.() ?? null

  getCurrentRouteName = (navigator: NavigationContainerRef | null = this.navigator) => {
    const route = this.getCurrentRoute(navigator)
    return route?.name ?? ''
  }

  handleBackPress = () => {
    const currentRoute = this.getCurrentRoute()
    if (['Case', 'Policies', 'Agency', 'More'].includes(currentRoute?.name)) {
      this.openScreenOverview()
      return true
    }
    return false
  }

  isCurrentAgency = (routeName: string) => routeName === 'Agency'

  isCurrentBenefits = (routeName: string) => routeName === 'Benefits'

  isCurrentCase = (routeName: string) => {
    return ['Case', 'Purchases', 'Purchase', 'EditPurchase', 'CreatePurchase', 'Claims', 'Claim', 'Payments',
      'ValueMeters', 'HouseholdEffectsValueMeter', 'RebuildValueMeter'].includes(routeName)
  }

  isCurrentMemberBenefits = (routeName: string) => routeName === 'MemberBenefits'

  isCurrentMore = (routeName: string) => routeName === 'More'

  isCurrentNotifications = (routeName: string) => routeName === 'Notifications'

  isCurrentOverview = (routeName: string) => routeName === 'Overview'

  isCurrentPolicies = (routeName: string) => ['Policies', 'Policy', 'CombiPolicy', 'PolicyStatement'].includes(routeName)

  isCurrentProfile = (routeName: string) => routeName === 'Profile'

  openScreen = async (screenName: string, params?: object) => await this.navigate(screenName, params)

  openScreenAboutApp = async () => await this.navigate('MoreStack', { screen: 'AboutApp' })

  openScreenAboutNh1816 = async () => await this.navigate('MoreStack', { screen: 'AboutNh1816' })

  openScreenCreatePurchase = async () =>
    await this.navigate('PurchasesStack', {
      screen: 'CreatePurchase'
    })

  openScreenAddUser = async () =>
    await this.navigate('ManageUsersStack', {
      screen: 'AddUser'
    })

  openScreenAgency = async () => await this.navigate('AgencyStack', { screen: 'Agency' })

  openScreenMemberBenefits = async () => {
    if (this.isSmallScreen() && !IS_WEB) {
      await this.navigate('TabStack', { screen: 'MoreStack', params: { screen: 'MemberBenefitsStack', initial: false } })
    } else {
      await this.navigate('MemberBenefitsStack')
    }
  }

  openScreenMemberBenefitsDetail = async (id: IMemberBenefit['id']) =>
    await this.navigate('MemberBenefitsStack', {
      params: { id },
      screen: 'MemberBenefitsDetail'
    })

  openScreenCase = async () => await this.navigate('CaseStack', { screen: 'Case' })

  openScreenClaim = async (claim: Claim) =>
    await this.navigate('CaseStack', {
      params: {
        params: { claimId: claim?.id },
        screen: 'Claim'
      },
      screen: 'ClaimsStack'
    })

  openScreenGenerateGreenCard = async (policy: Policy) => {
    await this.navigate('GreenCardsStack', { screen: 'GenerateGreenCard', params: { policyId: policy?.id } })
  }

  openScreenGeneratedGreenCard = async (generatedGreenCard: GeneratedGreenCard) => {
    await this.navigate('GreenCardsStack', { screen: 'GeneratedGreenCard', params: { generatedGreenCard } })
  }

  openScreenHistoricalClaim = async (claim: IHistoricalClaim) =>
    await this.navigate('CaseStack', {
      params: {
        params: { claimNumber: claim?.claimNumber },
        screen: 'HistoricalClaim'
      },
      screen: 'ClaimsStack'
    })

  openScreenClaimDamage = async () => await this.navigate('ClaimDamageStack', { screen: 'ClaimDamage' })

  openScreenClaimDamageForm = async () => await this.navigate('ClaimDamageStack', { screen: 'ClaimDamageForm' })

  openScreenClaims = async () =>
    await this.navigate('CaseStack', {
      params: { screen: 'Claims' },
      screen: 'ClaimsStack'
    })

  openScreenCombiPolicy = async () => await this.navigate('PoliciesStack', { screen: 'CombiPolicy' })

  openScreenCompany = async (company: Company) => await this.navigate('Company', { companyId: company?.id })

  openScreenDamageRepair = async () => await this.navigate('DamageRepair')

  openScreenDamageRepairVehicle = async () => await this.navigate('DamageRepairVehicle')

  openScreenDamageRepairVehicleWeb = async () => await this.navigate('DamageRepairStack', { screen: 'DamageRepairVehicle' })

  openScreenEditPurchase = async (purchase: Purchase) =>
    await this.navigate('PurchasesStack', {
      screen: 'EditPurchase',
      params: { purchaseId: purchase?.id },
    })

  openScreenEditUser = async (user: User) =>
    await this.navigate('ManageUsersStack', {
      screen: 'EditUser',
      params: { userId: user?.id },
    })

  openScreenPurchase = async (purchase: Purchase) =>
    await this.navigate('CaseStack', {
      params: { params: { purchaseId: purchase?.id }, screen: 'Purchase' },
      screen: 'PurchasesStack'
    })

  openScreenPurchases = async () => await this.navigate('CaseStack', { params: { screen: 'Purchases' }, screen: 'PurchasesStack' })

  openScreenForgotPassword = async () => await this.navigate('ForgotPassword')

  openScreenGreenCard = (policy: Policy) => {
    if (!this.isSmallScreen()) {
      this.push('GreenCardsStack', { screen: 'GreenCard', params: { policyId: policy?.id } })
    } else {
      this.push('TabStack', { screen: 'GreenCardsStack', params: { screen: 'GreenCard', params: { policyId: policy?.id } } })
    }
  }

  openScreenGreenCards = () => {
    if (!this.isSmallScreen()) {
      this.push('GreenCardsStack', { screen: 'GreenCards' })
    } else {
      this.push('TabStack', { screen: 'GreenCardsStack', params: { screen: 'GreenCards' } })
    }
  }

  openScreenHome = async (action = '', params = {}) => await this.navigate('Home', { action, ...params })

  openScreenLogin = async () => await this.navigate('Login')

  openScreenManageFacialRecognition = async () => await this.navigate('ProfileStack', { screen: 'ManageFacialRecognition' })

  openScreenManageFingerprint = async () => await this.navigate('ProfileStack', { screen: 'ManageFingerprint' })

  openScreenManageLogin = async () => await this.navigate('ProfileStack', { screen: 'ManageLogin' })

  openScreenManagePermissions = async () => await this.navigate('ProfileStack', { screen: 'ManagePermissions' })

  openScreenManagePincode = async () => await this.navigate('ProfileStack', { screen: 'ManagePincode' })

  openScreenManageRelation = async () => await this.navigate('ProfileStack', { screen: 'ManageRelation' })

  openScreenManageUsers = async () =>
    await this.navigate('ManageUsersStack', {
      screen: 'ManageUsers'
    })

  openScreenManageVerify = async () => await this.navigate('ProfileStack', { screen: 'ManageVerify' })

  openScreenMore = async () => await this.navigate('MoreStack', { screen: 'More' })

  openScreenNotifications = async () => await this.navigate('NotificationsStack', { screen: 'Notifications' })
  openScreenNotification = async (id: INotification['messageId']) => await this.navigate('NotificationsStack', { screen: 'Notification', params: { id } })

  openScreenOverview = async () => await this.navigate('Overview')

  openScreenPayments = async () => {
    await this.navigate('CaseStack', { screen: 'Payments' })
  }

  openScreenPolicies = async () => await this.navigate('PoliciesStack', { screen: 'Policies' })

  openScreenPolicy = async (policy: Policy) =>
    await this.navigate('PoliciesStack', { params: { policyId: policy?.id }, screen: 'Policy' })

  openScreenAddPolicy = async () =>
    await this.navigate('PoliciesStack', { screen: 'AddPolicy' })

  openScreenAddPolicySuccess = async () =>
    await this.navigate('PoliciesStack', { screen: 'AddPolicySuccess' })

  openScreenPolicyCondition = async (policy: Policy, condition: Condition) =>
    await this.navigate('PoliciesStack', {
      params: { conditionId: condition?.id, policyId: policy?.id },
      screen: 'PolicyCondition'
    })

  openScreenGeneralConditions = async () =>
    await this.navigate('PoliciesStack', {
      screen: 'GeneralConditions'
    })

  openScreenPolicyStatement = async () => await this.navigate('PoliciesStack', { screen: 'PolicyStatement' })

  openScreenProfile = async () => await this.navigate('ProfileStack', { screen: 'Profile' })

  openScreenQuestions = async () => await this.navigate('MoreStack', { screen: 'Questions' })

  openScreenRegister = async () => await this.navigate('Register')

  openScreenSettings = async () => await this.navigate('ProfileStack', { screen: 'Settings' })

  openScreenServiceGuide = async (serviceGuide: ServiceGuide) =>
    await this.navigate('ServiceGuide', { serviceGuideId: serviceGuide?.id })

  openScreenSwitchCombiPolicy = async () => await this.navigate('ProfileStack', { screen: 'SwitchCombiPolicy' })

  openScreenAddRelationToCombi = async () => await this.navigate('ProfileStack', { screen: 'AddRelationToCombi' })

  openScreenTermsOfUse = async () => await this.navigate('MoreStack', { screen: 'TermsOfUse' })

  openScreenTipCategories = async () => await this.navigate('TipsStack')

  openScreenTipCategory = async (category: TipCategory) =>
    await this.navigate('TipsStack', { params: { categoryId: category?.id }, screen: 'TipCategory' })

  openScreenWhatToDo = async (category: WtdCategory) => await this.navigate('WhatToDo', { category })

  openScreenWhatToDos = async () => await this.navigate('WhatToDos')

  openScreenValueMeters = async () => await this.navigate('CaseStack', { screen: 'ValueMetersStack' })

  openScreenValueMeter = async (policy: Policy) => {
    const isHousehold = policy?.productName === 'Inboedel'
    const screen = isHousehold ? 'HouseholdEffectsValueMeter' : 'RebuildValueMeter'

    await this.navigate('CaseStack', {
      screen: 'ValueMetersStack',
      params: {
        screen,
        params: { policyId: policy?.id }
      }
    })
  }

  replaceScreenClaim = (claimId: Claim['claimDamageId']) => {
    if (Platform.OS === 'web') {
      this.replace('CaseStack', {
        params: {
          params: { claimId },
          screen: 'Claim'
        },
        screen: 'ClaimsStack'
      })
    } else {
      // navigation structure is significantly different than on web
      this.replace('TabStack', {
        initial: true,
        params: {
          initial: false,
          params: {
            initial: false,
            params: { claimId },
            screen: 'Claim'
          },
          screen: 'ClaimsStack'
        },
        screen: 'CaseStack'
      })
    }
  }

  replaceScreenClaims = () => {
    if (Platform.OS === 'web') {
      this.replace('CaseStack', {
        params: { screen: 'Claims' },
        screen: 'ClaimsStack'
      })
    } else {
      this.replace('TabStack', {
        initial: true,
        params: {
          initial: false,
          params: {
            initial: false,
            screen: 'Claims'
          },
          screen: 'ClaimsStack'
        },
        screen: 'CaseStack'
      })
    }
  }

  pushPDFViewer = async (params: IAttachmentViewerProps) =>
    await this.navigate('PDFViewer', params)

  pushImageViewer = async (params: IAttachmentViewerProps) =>
    await this.navigate('ImageViewer', params)
}

export default new Navigation()
