import type { Purchase, PurchaseInvoice, PurchasePicture } from '@/types/objects'
import api from '@/utils/api_wrapper'

export const getPurchases = async () =>
  await api.get('purchases')

export const getPurchase = async (id: string) => {
  return await api.get(`purchases/${id}`)
}

export const deletePurchase = async (id: string) =>
  await api.delete(`purchases/${id}`)

export const postPurchaseInvoice = async (purchaseInvoice: PurchaseInvoice) =>
  await api.post<Purchase>('purchases/purchase-invoice', purchaseInvoice)

export const postPurchasePicture = async (purchasePicture: PurchasePicture) =>
  await api.post<Purchase>('purchases/purchase-picture', purchasePicture)
