import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { Platform, StyleSheet, View } from 'react-native'
import { TabBar, TabView } from 'react-native-tab-view'
import { connect } from 'react-redux'

import Pill from '@/components/Pill'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import mapStateToProps from '@/redux/mapStateToProps'
import type { ThemeColors } from '@/redux/reducers/theme'

export interface Route {
  key: string
  title: string

  [key: string]: string
}

interface TTabsProps {
  colors: ThemeColors
  onIndexChange?: (...args: any[]) => any
  renderScene: ({ route }: { route: Route }) => void
  routes: Route[]
  scrollEnabled?: boolean
  swipeEnabled?: boolean
  tabBarStyle?: StyleProp<ViewStyle>
  usePills?: boolean
}

interface TTabsState {
  index: number
}

class Tabs extends React.Component<TTabsProps, TTabsState> {
  state = {
    index: 0
  }

  onIndexChange = (index: number): void => {
    if (typeof this.props.onIndexChange === 'function') {
      this.props.onIndexChange(index)
    }

    this.setState({ index })
  }

  getLabelText = ({ route }: { route: Route }) => route.title

  renderLabel = ({ route }: { route: Route }) => (
    <Text
      style={styles.label}
      type='description'
    >
      {route.title}
    </Text>
  )

  renderPill = () => {
    return (
      <View style={this.props.tabBarStyle ? [Styling.row, this.props.tabBarStyle] : Styling.row}>
        {(this.props.routes || []).map((route, index) => (
          <Pill
            large
            borderColor={index !== this.state.index ? this.props.colors?.brand : Colors.white}
            color={index !== this.state.index ? Colors.white : this.props.colors?.brand}
            key={`tabs-pill-${route.key || index}`}
            onPress={() => this.onIndexChange(index)}
            style={index !== 0 && Styling.marginLeft}
            textColor={index !== this.state.index ? this.props.colors?.brand : Colors.white}
            title={route.title}
          />
        ))}
      </View>
    )
  }

  renderTabBar = (props: any) => {
    if (this.props.usePills) {
      return this.renderPill()
    }

    return (
      <TabBar
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        getLabelText={this.getLabelText}
        indicatorStyle={[styles.indicator, { backgroundColor: this.props.colors?.brand }]}
        renderLabel={this.renderLabel}
        style={[styles.tabBar, this.props.tabBarStyle]}
      />
    )
  }

  renderScene = () =>
    this.props.renderScene({
      route: this.props.routes[this.state.index]
    })

  renderTabView () {
    const navigationState = {
      index: this.state.index,
      routes: this.props.routes
    }

    return (
      <TabView
        navigationState={navigationState}
        onIndexChange={this.onIndexChange}
        renderScene={({ route }) => (this.props.scrollEnabled ? <></> : this.props.renderScene({ route }))}
        renderTabBar={this.renderTabBar}
        // @ts-expect-error
        style={Platform.OS === 'web' ? { flex: 'auto' } : {}}
        swipeEnabled={this.props.swipeEnabled}
      />
    )
  }

  render () {
    if (this.props.scrollEnabled) {
      return (
        <View>
          {this.renderTabView()}
          {this.renderScene()}
        </View>
      )
    }

    return this.renderTabView()
  }
}

const styles = StyleSheet.create({
  indicator: {
    height: Sizes.indicatorHeight
  },
  label: {
    paddingVertical: Sizes.spacingVerticalSmall
  },
  tabBar: {
    backgroundColor: Colors.fb,
    borderBottomColor: Colors.background,
    borderBottomWidth: 1,
    elevation: 0
  }
})

// Prevent the connected component from becoming a PureComponent,
// because of the 'renderScene' logic of the Tabs component it should always rerender.
export default connect(mapStateToProps.themeColors, null, null, { pure: false })(Tabs)
