import { format } from 'date-fns'
import { createSelector } from 'reselect'

import type { State as TState } from '@/redux/reducers'
import type { Payment } from '@/types/objects'
import dateUtil from '@/utils/date'

export const getPayments = (state: TState) => state.payments?.data

export const getPaymentByYear = createSelector(getPayments, (payments = []) =>
  payments?.reduce((acc: { title: string, data: Payment[] }[], payment) => {
    let added = false

    const title = format(new Date(dateUtil.dutchDateToJSDate(payment?.bookDate)), 'yyyy')

    const newAcc = acc.map((item) => {
      if (item?.title === title) {
        added = true

        return {
          ...item,
          data: (item?.data ?? []).concat(payment)
        }
      }

      return item
    })

    if (added) {
      return newAcc
    }

    return newAcc.concat({
      data: [payment],
      title
    })
  }, [])
)
