import type { File } from '@/components/inputs/InputFile'
import type { Claim, ClaimQuestion, FormClaimDamageResult } from '@/types/objects'

import api from '@/utils/api_wrapper'

export const getClaim = async (id: Claim['claimDamageId']) =>
  await api.get(`claims/${id}`)

export const getClaims = async () =>
  await api.get('claims')

export const getHistoricalClaims = async () =>
  await api.get('claims/historical')

export const getClaimDamageQuestions = async (claimTypeName: Claim['claimType']) =>
  await api.get(`claims/product-claim-attributes?product_claim_type_name=${claimTypeName}`)

export const updateClaim = async (id: Claim['id'], claim: Claim, updates: any) =>
  await api.put(`claims/${id}`, { data: { ...claim, ...updates } })

export const deleteClaim = async (claimDamageId: Claim['claimDamageId']) =>
  await api.delete<null>(`claims/${claimDamageId}`)

export const addClaim = async (data: Claim) =>
  await api.post('claims', data)

export const addClaimDynamic = async (formResult: FormClaimDamageResult, claimDamageId: Claim['claimDamageId']) =>
  await api.post('claims/dynamic', { claimDamageId, ...formResult })

export const updateClaimDynamic = async (formResult: FormClaimDamageResult, claimDamageId: Claim['claimDamageId']) =>
  await api.put(`claims/${claimDamageId}/dynamic`, { claimDamageId, ...formResult })

export const uploadClaimAttachment = async (
  productClaimAttribute: ClaimQuestion,
  claimDamageId: Claim['claimDamageId'],
  base64: string,
  filename: string,
  fileUuid: string,
  mimeType: string | null
) =>
  await api.post('claims/attachment', {
    productClaimAttribute,
    claimDamageId,
    base64,
    filename,
    fileUuid,
    mimeType
  })

interface IUploadAdditionalClaimAttachmentResponse {
  claimDamageId: Claim['claimDamageId']
  attachmentId: string
  fileName: string
}

export const uploadAdditionalClaimAttachment = async (claimDamageId: Claim['claimDamageId'], file: File, batchNumber: string) =>
  await api.post<IUploadAdditionalClaimAttachmentResponse>(`claims/${claimDamageId}/claim-attachments`,
    { base64: file?.base64, filename: file?.name, batchNumber: parseInt(batchNumber), fileUuid: file?.fileUuid, mimeType: file?.mimeType }
  )

export const removeAdditionalClaimAttachment = async (claimDamageId: Claim['claimDamageId'], attachmentId: string) =>
  await api.delete(`claims/${claimDamageId}/claim-attachments/${attachmentId}`)
