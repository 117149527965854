import React from 'react'
import type { RefreshControlProps, StyleProp, ViewStyle } from 'react-native'
import { Platform, ScrollView as ReactNativeScrollView } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

const ScrollView = Platform.OS === 'ios' ? KeyboardAwareScrollView : ReactNativeScrollView

export interface TScrollViewProps {
  children?: React.ReactNode
  contentContainerStyle?: StyleProp<ViewStyle>
  id?: string
  persistentScrollbar?: boolean
  refreshControl?: React.ReactElement<RefreshControlProps, string | React.JSXElementConstructor<any>>
  scrollEnabled?: boolean
  scrollViewRef?: React.RefObject<KeyboardAwareScrollView | ReactNativeScrollView>
  style?: StyleProp<ViewStyle>
  showsVerticalScrollIndicator?: boolean
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
export default function _ScrollView (props: TScrollViewProps) {
  return (
    <ScrollView
      accessibilityLabel={props.id?.toString()}
      contentContainerStyle={props.contentContainerStyle}
      key={props.id}
      keyboardDismissMode='interactive'
      keyboardShouldPersistTaps='handled'
      persistentScrollbar={props.persistentScrollbar}
      ref={props.scrollViewRef}
      refreshControl={props.refreshControl}
      scrollEnabled={props.scrollEnabled}
      style={props.style}
      showsVerticalScrollIndicator={props.showsVerticalScrollIndicator}
    >
      {props.children}
    </ScrollView>
  )
}
