import React from 'react'
import { StyleSheet, View } from 'react-native'

import type { IAttribute } from '@/components/Attributes'
import Attributes from '@/components/Attributes'
import Text from '@/components/wrappers/Text'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import type { Policy } from '@/types/objects'
import common from '@/utils/common'
import data from '@/utils/data'

import ClaimButton from './claim_button'
import { detailAttributes } from './helpers'

const DetailsPhone: React.FC<{ policy: Policy }> = ({ policy }) => {
  const vehicleDetail = data.getVehicleDetail(policy?.details)
  const attributes = common.convertDetailsToAttributes(detailAttributes(policy))

  return (
    <View style={styles.tab}>
      {vehicleDetail?.code
        ? (
          <Text
            style={Styling.marginBottomNormal}
            type='subsection'
          >
            {vehicleDetail.code}
          </Text>
          )
        : null}

      <Attributes
        attributes={attributes as IAttribute[]}
        id='attributes-details'
      />
      <ClaimButton policy={policy} />
    </View>
  )
}

const styles = StyleSheet.create({
  tab: {
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingVertical: Sizes.screenPaddingVertical
  }
})

export default DetailsPhone
