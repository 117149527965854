import type { State as TState } from '@/redux/reducers/index'
import { allowedToFetchData, getKeyName } from '@/utils/data'
import type { TApiCallDispatch } from '../actions/api'

export const isAllowedToFetch = (state: TState, action: TApiCallDispatch) => {
  const entity = getKeyName(action.payload)
  const caching = state.caching?.[entity]

  return allowedToFetchData(caching)
}
