import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { View } from 'react-native'

import Icon from '@/components/Icon'
import Touchable from '@/components/touchables/Touchable'
import Text from '@/components/wrappers/Text'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import type { IconProp } from '@/types/props'

interface TContactInfoProps {
  circleColor: string
  description?: string
  descriptionColor?: string
  icon?: IconProp
  iconColor: string
  onPress: (...args: any[]) => any
  style?: StyleProp<ViewStyle>
  title: string
}

export default function ContactInfo (props: TContactInfoProps) {
  return props.description
    ? (
      <Touchable
        onPress={props.onPress}
        style={props.style ? [Styling.rowCenter, props.style] : Styling.rowCenter}
      >
        <Icon
          circleColor={props.circleColor}
          color={props.iconColor}
          icon={props.icon}
          size={Sizes.iconSmall}
          style={Styling.marginRightSmall}
        />
        <View>
          <Text type='note'>{props.title}</Text>
          <Text
            color={props.descriptionColor}
            type='description'
          >
            {props.description}
          </Text>
        </View>
      </Touchable>
      )
    : null
}
