import haversine from 'haversine'
import { createSelector } from 'reselect'
import type { State as TState } from '@/redux/reducers'
import type { Company } from '@/types/objects'
import { CompanyTypes } from '@/types/objects'
import data from '@/utils/data'
import prototype from '@/utils/prototype'
import { COMPANY_TYPE_MAPPINGS } from '../constants/companies'
import type { FiltersState } from '../reducers/filters'

export const getRepairers = (state: TState) => state.companies?.repairers
export const getCarWindowRepairers = (state: TState) => state.companies?.carWindowRepairers
export const getCarWindowRepairerBrands = (state: TState) => state.companies?.carWindowRepairerBrands

export const getRepairCompanies = createSelector(
  [getRepairers, getCarWindowRepairers, (_state: TState, filters: FiltersState) => filters],
  (repairers, carWindowRepairers, filters) => {
    if (filters?.companyType === null) {
      return []
    }

    let filteredCompanies

    if (filters?.companyType === CompanyTypes['car-window']) {
      if (filters?.brand) {
        filteredCompanies = carWindowRepairers?.filter(
          (r) => {
            return r.brandId.includes(filters.brand!) || r.brandId.includes('Alle') || (r.allBrands && String(r.latitude) !== 'NULL' && String(r.longitude) !== 'NULL')
          }
        )
      } else {
        filteredCompanies = carWindowRepairers
      }
    } else {
      filteredCompanies = repairers?.filter((r) =>
        r.products.some((p) => COMPANY_TYPE_MAPPINGS?.[filters.companyType]?.includes(p))
      )
    }

    if (filters?.location) {
      filteredCompanies = filteredCompanies?.map((company: Company) => ({
        ...company,
        distance: haversine(
          filters.location!,
          { latitude: company.latitude, longitude: company.longitude },
          { unit: 'meter' }
        )
      }))

      filteredCompanies = prototype.sortNumericalAsc(filteredCompanies, 'distance')

      const radius = filters?.radius || data.getDefaultRadius()
      filteredCompanies = filteredCompanies?.filter((c: Company) => !c.distance || c.distance <= radius)
    }

    return filteredCompanies
  }
)

export const getReadableCarBrands = createSelector([getCarWindowRepairerBrands], (brands) =>
  brands?.map((b) => ({
    value: b,
    label: b
  }))
)
