import React from 'react'

import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import showValueMeter from '@/utils/showValueMeters'
import ValueMetersTablet from './valuemeters.tablet'

const Coverages = ({ policy }) => (
  <Bootstrap.Row>
    <Bootstrap.Column span={{ lg: 6, md: 8, sm: 12, xl: 4, xs: 12 }}>
      <Button
        title={i18n.t('Raadpleeg het polisblad voor\nuw gekozen dekkingen.')}
        onPress={navigation.openScreenPolicyStatement}
      />
      {showValueMeter(policy) && <ValueMetersTablet policy={policy} />}
    </Bootstrap.Column>
  </Bootstrap.Row>
)

export default Coverages
