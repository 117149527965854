import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import i18n from '@/utils/i18n'
import ValueMeters from '@/screens/case/value_meters'
import RebuildValueMeter from '@/screens/case/value_meters/rebuild_value_meter'
import HouseholdEffectsValueMeter from '@/screens/case/value_meters/household_effects_value_meter'

const ValueMetersStack = createStackNavigator()
const ValueMetersStackNavigator = () => (
  <ValueMetersStack.Navigator screenOptions={{ headerShown: false }}>
    <ValueMetersStack.Screen
      name='ValueMeters'
      component={ValueMeters}
      options={{ title: i18n.t('Waardemeters') }}
    />
    <ValueMetersStack.Screen
      name='RebuildValueMeter'
      component={RebuildValueMeter}
      options={{ title: i18n.t('Herbouwwaardemeter') }}
    />
    <ValueMetersStack.Screen
      name='HouseholdEffectsValueMeter'
      component={HouseholdEffectsValueMeter}
      options={{ title: i18n.t('Inboedelwaardemeter') }}
    />
  </ValueMetersStack.Navigator>
)

export default ValueMetersStackNavigator
