import i18n from '@/utils/i18n'
import { createStackNavigator } from '@react-navigation/stack'

import Purchase from '@/screens/purchases/Purchase'
import create_purchase from '@/screens/purchases/create_purchase'
import edit_purchase from '@/screens/purchases/edit_purchase'

const PurchasesStack = createStackNavigator()
const PurchasesStackNavigator = () => (
  <PurchasesStack.Navigator screenOptions={{ headerShown: false }}>
    {/* <PurchasesStack.Screen
      name='Purchases'
      component={Purchases}
      options={{ title: i18n.t('Mijn bezittingen') }}
    /> */}
    <PurchasesStack.Screen
      name='Purchase'
      component={Purchase}
      options={{ title: i18n.t('Bezitting') }}
    />
    <PurchasesStack.Screen
      name='CreatePurchase'
      component={create_purchase}
      options={{ title: i18n.t('Bezitting toevoegen') }}
    />
    <PurchasesStack.Screen
      name='EditPurchase'
      component={edit_purchase}
      options={{ title: i18n.t('Bezitting aanpassen') }}
    />
  </PurchasesStack.Navigator>
)

export default PurchasesStackNavigator
