import React from 'react'
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

import Colors from '@/constants/Colors'

interface TBackdropProps {
  children?: React.ReactNode
  onPress?: (...args: any[]) => any
}

export default function Backdrop (props: TBackdropProps) {
  return (
    <>
      <TouchableWithoutFeedback onPress={props.onPress}>
        <View style={styles.backdrop} />
      </TouchableWithoutFeedback>
      <View
        pointerEvents='box-none'
        style={StyleSheet.absoluteFill}
      >
        {props.children}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Colors.backdrop
  }
})
