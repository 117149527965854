import React, { useEffect } from 'react'
import { ActivityIndicator } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import Screen from '@/components/Screen'

import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'

import { logout, updateToken, updateTokenExpiresAt, updateTokenExtendedAt } from '@/redux/actions/user'
import selectors from '@/redux/selectors'
import { createToken } from '@/redux/api/sessions'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT } from '@/constants/date_time'

const Verify: React.FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectors.getUser)

  const createNewToken = async () => {
    const result = await createToken(user.email, user.password)
    if (result.success) {
      dispatch(updateToken(result.data))
      dispatch(updateTokenExpiresAt(result.tokenExpiresAt))
      dispatch(updateTokenExtendedAt(format(new Date(), DATE_TIME_FORMAT)))
    }
  }

  useEffect(() => {
    if (__DEV__) {
      createNewToken()
    } else {
      dispatch(logout())
    }
  })

  return (
    <Screen
      noBreadCrumbs
      white
    >
      <ActivityIndicator
        color={Colors.primary}
        size={Sizes.iconLarge}
      />
    </Screen>
  )
}

export default Verify
