import React from 'react'
import PDFViewer from '@/screens/pdf_viewer'
import i18n from '@/utils/i18n'
import type { GeneratedGreenCard } from '@/types/objects'

interface IProps {
  route: {
    params: {
      generatedGreenCard: GeneratedGreenCard
    }
  }
}

const GreenCard: React.FC<IProps> = (props: IProps) => {
  const generatedGreenCard = props.route.params.generatedGreenCard
  const fileName = `verzekeringsbewijs-polis-${generatedGreenCard.policyIdPullingVehicle}-${generatedGreenCard.towedVehicleLicensePlate}.pdf`

  return (
    <PDFViewer
      route={{
        params: {
          url: generatedGreenCard.url,
          key: `generated-green-card-${generatedGreenCard.policyIdPullingVehicle}-${generatedGreenCard.policyIdTowedVehicle}`,
          fileName,
          title: fileName,
          errorMessage: i18n.t('Het is niet gelukt om het Verzekeringsbewijs te downloaden')
        }
      }}
    />
  )
}

export default GreenCard
