import DateUtil from '@/utils/date'
import { getKeyName } from '@/utils/data'

import type { TApiCallDispatch } from '../actions/api'
import { API_CALL_SUCCESS } from '../constants/api'

export interface ICachingState {
  // index is created in pattern <entity>-<id>
  [index: string]: undefined | string // timestamp
}

export const initialState: ICachingState = {}

export default (state = initialState, action: TApiCallDispatch) => {
  if (action?.type === API_CALL_SUCCESS) {
    if (action.payload.skipCaching) {
      return state
    }

    // We do not want to block calls with other methods
    if (action.payload.method !== 'GET') {
      return state
    }

    return {
      ...state,
      [getKeyName(action.payload)]: DateUtil.now()
    }
  }

  if (['API_CALL_FAILED', 'INVALIDATE_CACHE_ENTRY'].includes(action?.type)) {
    // We do not log calls from other methods
    if (action.payload.method !== 'GET') {
      return state
    }

    const newState = { ...state }
    delete newState[getKeyName(action.payload)]

    return newState
  }

  if (action?.type === 'WIPE_CACHE') {
    return initialState
  }

  if (action?.type === 'SELECT_RELATION') {
    return initialState
  }

  return state
}
