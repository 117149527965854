import { addDays, format } from 'date-fns'

const DATE_FORMAT = 'dd-MM-yyyy'
const TIME_FORMAT = 'HH:mm'
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

const dutchDateToJSDate = (dutchDate?: string): Date => {
  if (!dutchDate) {
    return new Date()
  }

  const [day, month, year] = dutchDate?.split('-')?.map((item) => parseInt(item, 10))

  return new Date(year, month - 1, day)
}

const yesterday = (formating: string = DATE_FORMAT): string => {
  return format(addDays(new Date(), -1), formating)
}

const formatTimeStamp = (dateString: string, formatting = DATE_FORMAT): string => {
  if (!dateString) {
    return ''
  }

  const date = new Date(dateString)

  return format(date, formatting)
}

const now = (formatting = DATE_FORMAT) => {
  const date = new Date()

  return format(date, formatting)
}

export default {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
  dutchDateToJSDate,
  formatTimeStamp,
  now,
  yesterday
}
