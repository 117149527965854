import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Attributes from '@/components/Attributes'
import Block from '@/components/Block'
import Screen from '@/components/Screen'
import selectors from '@/redux/selectors'
import i18n from '@/utils/i18n'

import { ENTITIES } from '@/redux/constants'
import { apiCall } from '@/redux/actions/api'
import Styling from '../../constants/Styling'
import navigation from '../../utils/navigation'
import Actions from '../../components/Actions'

const CombiPolicy: React.FC = () => {
  const dispatch = useDispatch()

  const relation = useSelector(selectors.getCurrentRelation)
  const refreshing = !!useSelector(selectors.isLoading(ENTITIES.RELATION, relation?.id))

  const refresh = () => {
    dispatch(apiCall('GET', ENTITIES.RELATION, relation?.id))
  }

  const renderContent = () => (
    <>
      <Attributes
        attributes={[
          {
            description: relation?.combiTermOfPayment,
            title: i18n.t('Betaaltermijn')
          },
          {
            description: relation?.combiNumber,
            title: i18n.t('Combinummer')
          },
          {
            description: relation?.combiStartingDate,
            title: i18n.t('Ingangsdatum'),
            type: 'date'
          },
          {
            description: relation?.combiPackageDiscount,
            title: i18n.t('Combikorting'),
            type: 'percentage'
          },
          {
            description: relation?.combiStatus,
            title: i18n.t('Status')
          },
          {
            description: relation?.combiCollectionAmount,
            title: i18n.t('Totaal bedrag'),
            type: 'currency'
          }
        ]}
        id='combi-policy'
      />

      <Actions
        labelDown={i18n.t('Algemene voorwaarden')}
        onPressDown={navigation.openScreenGeneralConditions}
        style={Styling.marginTop}
      />
    </>
  )

  useEffect(() => {
    refresh()
  }, [])

  return (
    <Bootstrap.Switch
      renderPhone={() => (
        <Screen
          white
          useRefreshControl
          refreshing={refreshing}
          onRefresh={refresh}
        >
          {renderContent()}
        </Screen>
      )}
      renderTablet={() => (
        <Screen
          useRefreshControl
          refreshing={refreshing}
          onRefresh={refresh}
        >
          <Block noFlex>
            {renderContent()}
          </Block>
        </Screen>
      )}
    />
  )
}

export default CombiPolicy
