import { createSelector } from 'reselect'
import { addMonths, addYears, isAfter } from 'date-fns'

import data from '@/utils/data'
import date from '@/utils/date'

import {
  allHtmlEntities,
  generateGetByKeySelector,
  getFirstParameter,
  getSecondParameter
} from '@/redux/selectors/helpers'
import type { State as TState } from '@/redux/reducers'
import { initialState } from '@/redux/reducers/policies'

import type { GeneratedGreenCard, Policy } from '@/types/objects'
import { getProducts } from './products'
import type { IPickerItem } from '@/components/inputs/InputSelect'
import showValueMeter from '@/utils/showValueMeters'

export const getPolicies = (state: TState) => state.policies?.data

export const getPolicy = generateGetByKeySelector<Policy>(getPolicies, 'id')

export const getPolicyWithDistinctionLabel = createSelector(
  [getPolicies, (_state: TState, id: string) => id],
  (policies, id) => {
    const policy = policies?.find((policy) => policy?.id === id)
    if (policy) {
      const distinctionLabel = data.determinePolicyDistinctionLabel(policy)
      return policy?.productName + (distinctionLabel && ` (${distinctionLabel})`)
    } else {
      return 'undefined'
    }
  }
)

export const getPolicyByPolicyNumber = createSelector(
  [getPolicies, (_state: TState, policyNumber: Policy['policyNumber']) => policyNumber],
  (policies, policyNumber) => policies?.find((policy) => policy?.policyNumber?.toString() === policyNumber?.toString())
)

export const getPolicyByPolicyNumberWithDistinctionLabel = createSelector(
  [getPolicies, (_state: TState, policyNumber: Policy['policyNumber']) => policyNumber],
  (policies, policyNumber) => {
    const policy = policies?.find((policy) => policy?.policyNumber?.toString() === policyNumber?.toString())
    if (policy) {
      const distinctionLabel = data.determinePolicyDistinctionLabel(policy)
      return policy?.productName + (distinctionLabel ? ` (${distinctionLabel})` : '')
    } else {
      return null
    }
  }
)

export const getPoliciesUnexpelled = createSelector(getPolicies, (policies) =>
  (policies || []).filter(({ status }) => status !== 'Geroyeerd')
)

export const getPoliciesExpelled = createSelector(getPolicies, (policies) =>
  (policies || []).filter(({ status }) => status === 'Geroyeerd')
)

export const getPoliciesOverview = createSelector(getPolicies, (policies) =>
  (policies || []).filter(({ status }) => status === 'Lopend' || status === 'Opgeschort' || status === 'Toekomstig')
)

export const getPoliciesClaimDamage = createSelector(getPolicies, (policies) =>
  (policies || []).filter(({ status }) => status === 'Lopend' || status === 'Opgeschort' || status === 'Geroyeerd')
)

export const getPoliciesActivePullingVehicles = createSelector(getPolicies, (policies) =>
  (policies || []).filter(({ branche, status }) => ['02101', '02150', '02700', '02900'].includes(branche) && status === 'Lopend')
)

export const getPolicyItems = createSelector(getPoliciesClaimDamage, (policies) =>
  (policies || [])
    .map((policy) => {
      const distinctionLabel = data.determinePolicyDistinctionLabel(policy)

      return {
        label: policy?.productName + (distinctionLabel && ` (${distinctionLabel})`),
        value: policy.id,
        isAllowedToReportClaim: policy.isAllowedToReportClaim && policy.id !== '8000109001' // Not allowed to claim on rechtsbijstand
      }
    })
)

export const getPoliciesImportant = createSelector(getPoliciesOverview, (policies) => policies?.slice(0, 3))

export const selectPoliciesWithGreenCard = createSelector(getPoliciesOverview, (policies) =>
  (policies || []).filter((policy) => policy.greenCard)
)

export const getPolicyCondition = createSelector(
  [getPolicy, getFirstParameter, getSecondParameter],
  (policy, _, parameter) => {
    const conditionId = parameter && String(parameter)
    return (policy?.conditions ?? []).find((condition) => String(condition.id) === conditionId)
  }
)

export const getProductClaimType = createSelector(
  getPolicy,
  getProducts,
  (policy, products) => products.find(p => String(p.id) === String(policy?.productId))
)

export const getClaimMethod = createSelector(
  getPolicy,
  getProductClaimType,
  (policy, product) => {
    const claimMethod = product?.branches?.find(x => String(x.brancheCode) === String(policy?.branche))

    return {
      ...claimMethod,
      description: allHtmlEntities.decode(claimMethod?.description ?? '')
    }
  }
)

export const getProductClaimTypeItems = createSelector(
  getProductClaimType,
  (product): IPickerItem[] => product
    ?.productClaimTypes
    ?.map(p => ({
      key: String(p.id),
      name: p.name,
      value: p.name,
      label: p.displayName
    })) ?? []
)

const isWithinThreeMonthsOfExpiry = (underinsuranceGuaranteeDate: string): boolean => {
  // User should be able to submit value meter three months before expiry date
  // underinsurance_guarantee_date.code is the start date, add 5 years to get the expiry date

  try {
    const startDateTS = date.dutchDateToJSDate(underinsuranceGuaranteeDate)
    const expiryDateTS = addYears(startDateTS, 5)
    const canSubmitOnTS = addMonths(expiryDateTS, -3)

    return isAfter(new Date(), canSubmitOnTS)
  } catch (_) {
    return false
  }
}

const policyHasActiveValueMeter = (policy: Policy) =>
  __DEV__ ||
  (
    policy.details?.underinsurance_guarantee?.value !== undefined &&
    policy.details?.underinsurance_guarantee?.value !== false &&
    !policy.pendingMutations &&
    policy.details?.block_mutation_reason?.value !== true &&
    isWithinThreeMonthsOfExpiry(policy.details?.underinsurance_guarantee_date?.code)
  )

export const getValueMeterPolicies = createSelector(
  getPoliciesUnexpelled,
  (policies = []) => policies
    .filter((policy) => showValueMeter(policy))
    .map((item) => ({ ...item, valueMeterEnabled: policyHasActiveValueMeter(item) }))
    .filter((policy) => policy?.valueMeterEnabled)
)

export const getRebuildValue = (state: TState) =>
  state?.policies?.rebuildValue || initialState.rebuildValue

export const getIsPolicyPullable = (policy: Policy) => {
  return ['02200', '03200'].includes(policy.branche) && policy.status === 'Lopend' && !!policy.details?.license_plate?.value
}

export const selectPoliciesPullable = createSelector(
  getPoliciesUnexpelled,
  (policies) => {
    return policies.filter(p => getIsPolicyPullable(p))
  }
)

export enum GreenCardRowType {
  DOWNLOADABLE_POLICY,
  DOWNLOADABLE_PULLABLE_POLICY,
  GENERATE_PULLABLE_POLICY
}

export interface IGreenCardRow {
  policy: Policy
  type: GreenCardRowType
  generatedGreenCardOrUrl?: GeneratedGreenCard | string
}

const getIsPullablePolicyGeneratable = (policy: Policy, activePullingVehicles: Policy[]): boolean => {
  return (policy?.greenCards?.length < activePullingVehicles.length)
}

export const selectGreenCardRows = createSelector(
  selectPoliciesPullable,
  selectPoliciesWithGreenCard,
  getPoliciesActivePullingVehicles,
  (pullablePolicies, greenCardPolicies, activePullingVehicles) => {
    const output: IGreenCardRow[] = [
      ...greenCardPolicies.map(gcp => ({
        type: GreenCardRowType.DOWNLOADABLE_POLICY,
        generatedGreenCardOrUrl: gcp.greenCard,
        policy: gcp
      }))
    ]

    const extractedPullablePolicies: IGreenCardRow[] = pullablePolicies
      ?.reduce((acc: IGreenCardRow[], currentPolicy) => {
        if (!currentPolicy.greenCards?.length) {
          return acc
        }

        const rows: IGreenCardRow[] = currentPolicy.greenCards.map(gc => {
          return {
            type: GreenCardRowType.DOWNLOADABLE_PULLABLE_POLICY,
            policy: currentPolicy,
            generatedGreenCardOrUrl: gc
          }
        })

        return [
          ...acc,
          ...rows
        ]
      }, []) ?? []

    const generativePullablePolicies: IGreenCardRow[] = pullablePolicies
      ?.reduce((acc: IGreenCardRow[], currentPolicy: Policy) => {
        const isGenerative = getIsPullablePolicyGeneratable(currentPolicy, activePullingVehicles)

        if (!isGenerative) {
          return acc
        }

        return [
          ...acc,
          {
            type: GreenCardRowType.GENERATE_PULLABLE_POLICY,
            policy: currentPolicy
          }
        ]
      }, []) ?? []

    return [
      ...extractedPullablePolicies,
      ...output,
      ...generativePullablePolicies
    ].sort((a, b) => a.type - b.type)
  }
)

export const selectHasAccessToGreenCardsScreen = createSelector(
  selectGreenCardRows,
  (greenCardRows) => greenCardRows?.length
)
