import { createStackNavigator } from '@react-navigation/stack'
import i18n from '@/utils/i18n'
import CombiPolicy from '@/screens/policies/CombiPolicy'
import GeneralConditions from '@/screens/policies/details/GeneralConditions'
import PolicyCondition from '@/screens/policies/PolicyCondition'
import PolicyStatement from '@/screens/policies/PolicyStatement'
import Policies from '@/screens/policies/Policies'
import Policy from '@/screens/policies/details/Policy'
import AddPolicy from '@/screens/policies/AddPolicy'
import AddPolicySuccess from '@/screens/policies/AddPolicySuccess'

const PoliciesStack = createStackNavigator()
const PoliciesStackNavigator = () => (
  <PoliciesStack.Navigator screenOptions={{ headerShown: false }}>
    <PoliciesStack.Screen
      name='Policies'
      component={Policies}
      options={{ title: i18n.t('Verzekeringen') }}
    />

    <PoliciesStack.Screen
      name='Policy'
      component={Policy}
      options={{ title: i18n.t('Verzekering') }}
    />

    <PoliciesStack.Screen
      name='CombiPolicy'
      component={CombiPolicy}
      options={{ title: i18n.t('Combipolis') }}
    />

    <PoliciesStack.Screen
      name='PolicyCondition'
      component={PolicyCondition}
      options={{ title: i18n.t('Voorwaarden') }}
    />

    <PoliciesStack.Screen
      name='PolicyStatement'
      component={PolicyStatement}
      options={{ title: i18n.t('Polisblad') }}
    />

    <PoliciesStack.Screen
      name='GeneralConditions'
      component={GeneralConditions}
      options={{ title: i18n.t('Algemene voorwaarden') }}
    />
    <PoliciesStack.Screen
      name='AddPolicy'
      component={AddPolicy}
      options={{ title: i18n.t('Toevoegen') }}
    />
    <PoliciesStack.Screen
      name='AddPolicySuccess'
      component={AddPolicySuccess}
      options={{ title: i18n.t('Succes'), headerShown: false }}
    />
  </PoliciesStack.Navigator>
)

export default PoliciesStackNavigator
