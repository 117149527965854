import i18n from '@/utils/i18n'
import { createStackNavigator } from '@react-navigation/stack'
import Wrapper from '@/navigation/components/Wrapper'
import Overview from '@/screens/overview/Overview'
import ProfileStackNavigator from './profile/profile_stack'
import NotificationsStackNavigator from './notifications_stack'
import CaseStackNavigator from './case/case_stack'
import PoliciesStackNavigator from './policies_stack'
import AgencyStackNavigator from './agency_stack'
import MemberBenefitsStackNavigator from './memeber_benefits_stack'
import WhatToDo from '@/screens/overview/WhatToDo'
import MoreStackNavigator from './more/more_stack'
import ClaimDamageStackNavigator from './claim_damage_stack'
import PDFViewer from '@/screens/pdf_viewer'
import DamageRepairStackNavigator from './damage_repair_stack'
import GreenCardStackNavigator from './green_card_stack'

const MainStack = createStackNavigator()

const MainStackNavigator = (routeName: string) => {
  return (
    <Wrapper routeName={routeName}>
      <MainStack.Navigator screenOptions={{ headerShown: false }}>
        <MainStack.Screen
          name='Overview'
          component={Overview}
          options={{ title: i18n.t('Overzicht') }}
        />

        <MainStack.Screen
          name='NotificationsStack'
          component={NotificationsStackNavigator}
        />

        <MainStack.Screen
          name='ProfileStack'
          component={ProfileStackNavigator}
        />

        <MainStack.Screen
          name='CaseStack'
          component={CaseStackNavigator}
        />

        <MainStack.Screen
          name='PoliciesStack'
          component={PoliciesStackNavigator}
        />

        <MainStack.Screen
          name='AgencyStack'
          component={AgencyStackNavigator}
          options={{ title: i18n.t('Adviseur') }}
        />

        <MainStack.Screen
          name='MemberBenefitsStack'
          component={MemberBenefitsStackNavigator}
        />

        <MainStack.Screen
          name='GreenCardsStack'
          component={GreenCardStackNavigator}
        />

        <MainStack.Screen
          name='DamageRepairStack'
          component={DamageRepairStackNavigator}
        />

        <MainStack.Screen
          name='WhatToDo'
          component={WhatToDo}
        />

        <MainStack.Screen
          name='MoreStack'
          component={MoreStackNavigator}
        />

        <MainStack.Screen
          name='ClaimDamageStack'
          component={ClaimDamageStackNavigator}
          options={{ title: 'Schade melden' }}
        />

        <MainStack.Screen
          name='PDFViewer'
          component={PDFViewer}
        />

      </MainStack.Navigator>
    </Wrapper>
  )
}

export default MainStackNavigator
