import React from 'react'
import { useDispatch } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import Styling from '@/constants/Styling'
import { openPolicyInsuranceCard as openPolicyInsuranceCardAction } from '@/redux/actions/files'
import type { InsuranceCard, Policy } from '@/types/objects'

const InsuranceCards: React.FC<{ policy: Policy }> = (props) => {
  const dispatch = useDispatch()

  const openPolicyInsuranceCard = (insuranceCard: InsuranceCard): void => {
    dispatch(openPolicyInsuranceCardAction(props.policy, insuranceCard))
  }

  return (
    <Bootstrap.Row>
      <Bootstrap.Column span={{ lg: 6, md: 8, sm: 12, xl: 4, xs: 12 }}>
        {(props.policy?.insuranceCards || []).map((insuranceCard, index) => (
          <Button
            key={`insurance-card-button-${insuranceCard.id}`}
            onPress={() => openPolicyInsuranceCard(insuranceCard)}
            style={index !== 0 && Styling.marginTop}
            title={insuranceCard.description}
          />
        ))}
      </Bootstrap.Column>
    </Bootstrap.Row>
  )
}

export default InsuranceCards
