import type { IProduct } from '@/types/objects'
import { createSelector } from 'reselect'
import type { State } from '../reducers'
import { getFirstParameter } from './helpers'

export const getProducts = (state: State) => state?.products?.data ?? []

export const getProduct = createSelector(
  [getProducts, getFirstParameter],
  (products, id: IProduct['id']) => products.find(x => String(x.id) === String(id))
)
