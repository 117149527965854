import React from 'react'

import Line from '@/components/Line'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import type { Policy } from '@/types/objects'
import i18n from '@/utils/i18n'

import Coverages from './coverages'

const CoveragesTablet: React.FC<{ policy: Policy }> = ({ policy }) => (
  <>
    <Line
      horizontal
      color={Colors.background}
      height={1}
      style={Styling.marginVerticalMedium}
    />

    <Text
      style={Styling.marginBottomNormal}
      type='subtitle'
    >
      {i18n.t('Dekkingen')}
    </Text>

    <Coverages policy={policy} />
  </>
)

export default CoveragesTablet
