// eslint-disable-next-line n/handle-callback-err
export const recordAPIError = (error: string, params?: Record<string, any>) => {
  // @TODO log to sentry
}

const Crashlytics = {
  recordAPIError
}

export default Crashlytics
