import type { GeneratedGreenCard, Policy, PolicyDetail } from './../../types/objects'
import DateUtil from '@/utils/date'
import prototype from '@/utils/prototype'

import type { TApiCallDispatch } from '../actions/api'

export interface IPoliciesState {
  data: Policy[]

  rebuildValue: {
    loading: boolean
    error: boolean
    success: boolean
  }
}

export const initialState: IPoliciesState = {
  data: [],

  rebuildValue: {
    loading: false,
    error: false,
    success: false
  }
}

export default (state: IPoliciesState = initialState, action: TApiCallDispatch): IPoliciesState => {
  switch (action?.type) {
    case 'GET_POLICIES_SUCCESS': {
      const policies = action.payload.data as Policy[]

      return {
        ...state,
        data: prototype.mergeArrays(state.data, policies.map(convertPolicyDetails))
      }
    }

    case 'GET_POLICY_SUCCESS':
      return {
        ...state,
        data: prototype.mergeObjectInArray(state.data, convertPolicyDetails({
          ...action.payload.data as Policy,
          fetchedAt: DateUtil.now()
        }))
      }

    case 'POST_REBUILD_VALUE_METER':
      return {
        ...state,
        rebuildValue: {
          loading: true,
          error: initialState.rebuildValue.error,
          success: initialState.rebuildValue.success
        }
      }

    case 'GENERATED_GREEN_CARD':
      return {
        ...state,
        data: state.data.map(policy => {
          if (policy.id !== action.payload.id) {
            return policy
          }
          return {
            ...policy,
            greenCards: prototype.mergeObjectInArray(
              policy.greenCards,
              action.payload.data as GeneratedGreenCard,
              'policyIdPullingVehicle'
            )
          }
        })
      }

    case 'POST_REBUILD_VALUE_METER_SUCCESS':
      return {
        ...state,
        rebuildValue: {
          loading: initialState.rebuildValue.loading,
          error: initialState.rebuildValue.error,
          success: true
        }
      }

    case 'POST_REBUILD_VALUE_METER_FAILED':
      return {
        ...state,
        rebuildValue: {
          loading: initialState.rebuildValue.loading,
          error: true,
          success: initialState.rebuildValue.success
        }
      }

    case 'RESET_REBUILD_VALUE_METER':
      return {
        ...state,
        rebuildValue: {
          loading: initialState.rebuildValue.loading,
          error: initialState.rebuildValue.error,
          success: initialState.rebuildValue.success
        }
      }

    case 'SELECT_RELATION':
      return initialState

    default:
      return state
  }
}

// Details are received as array, but used in application as object
export const convertPolicyDetails = (policy: Policy): Policy => {
  const detailsArray = (policy?.details as PolicyDetail[])
    ?.filter((item) => item.showApp)
    ?.sort((a, b) => a.followNumber - b.followNumber)

  return {
    ...policy,
    details: prototype.arrayToDict<PolicyDetail>(detailsArray, 'name')
  }
}
