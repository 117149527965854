/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'

import * as Bootstrap from '@/bootstrap'

interface Config<P> {
  Phone: React.ComponentType<P>
  Tablet: React.ComponentType<P>
}

export default function withSwitch<P extends object> (config: Config<P>) {
  return class WithSwitch extends React.Component<P> {
    renderPhone = () => <config.Phone {...(this.props as P)} />

    renderTablet = () => <config.Tablet {...(this.props as P)} />

    render () {
      return (
        <Bootstrap.Switch
          renderPhone={this.renderPhone}
          renderTablet={this.renderTablet}
        />
      )
    }
  }
}
