import * as CONSTANTS from '@/redux/constants/idin'
import type { IdinActionTypes } from '@/redux/types/idin'

export interface IdinState {
  entranceCode: string
  timestamp: number
  transactionId: string
  email: string
}

export const initialState: IdinState = {
  entranceCode: '',
  timestamp: 0,
  transactionId: '',
  email: ''
}

export default (state: IdinState = initialState, action: IdinActionTypes): IdinState => {
  switch (action?.type) {
    case CONSTANTS.START_IDIN:
      return {
        ...initialState,
        entranceCode: action.entranceCode,
        timestamp: Date.now(),
        transactionId: action.transactionId,
        email: action.email
      }

    case CONSTANTS.RESET_IDIN:
      return initialState

    default:
      return state
  }
}
