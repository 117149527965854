import React, { useState } from 'react'
import { View } from 'react-native'

import { useSelector } from 'react-redux'
import Button from '@/components/buttons/Button'
import ButtonPrevious from '@/components/buttons/ButtonPrevious'
import InputCheckBox from '@/components/inputs/InputCheckBox'
import ItemQuestionResult from '@/components/ItemQuestionResult'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import i18n from '@/utils/i18n'
import selectors from '@/redux/selectors'
import type { ClaimQuestion, ClaimQuestionOption } from '@/types/objects'
import { staticClaimQuestionLabels } from '@/redux/constants/forms'
import dateUtils from '@/utils/date'

interface IProps {
  loadingSummary: boolean

  onPreviousStep: () => void
  finishStepSummary: () => void
}

const QuestionResult = (question: ClaimQuestion, index: number) => {
  const formClaimDamage = useSelector(selectors.getFormClaimDamage)
  const value = formClaimDamage?.answersDynamic[question.id]
  const kindName = question.productClaimTypeAttributeKind.name
  let description = ''

  switch (kindName) {
    case 'explanation':
    case 'header':
      return null
    case 'multi-checkbox': {
      if (Array.isArray(value)) {
        if (value.length) {
          description = `- ${(question.options || [])
            .filter((option) => value.includes(option.value))
            .map((option) => option.name)
            .join('\n- ')}`
        } else {
          description = i18n.t('Ja')
        }
      } else {
        description = i18n.t('Nee')
      }
      break
    }
    case 'checkbox':
      description = value ? i18n.t('Ja') : i18n.t('Nee')
      break
    case 'file':
      description = value?.name
      break
    default:
      break
  }

  if (description === '' && question.options?.length) {
    const selectedItem = question.options.find((item: ClaimQuestionOption) => item.value === value)
    if (selectedItem) {
      description = selectedItem.name || selectedItem.value
    }
  } else if (description === '' && typeof value === 'string') {
    description = value
  }

  description = description || question.defaultValue || '-'

  return (
    <ItemQuestionResult
      amount={kindName === 'amount'}
      currency={kindName === 'currency'}
      description={description}
      key={`question-value-${question.id || index}`}
      label={question.displayName}
      style={Styling.marginTop}
    />
  )
}

const StepSummary: React.FC<IProps> = (props) => {
  const formClaimDamage = useSelector(selectors.getFormClaimDamage)
  const policyLabel = useSelector((state) => selectors.getPolicyWithDistinctionLabel(state, formClaimDamage?.answersStatic?.policyId))
  const questions = useSelector(selectors.getFormClaimDamageQuestions)

  const [acceptedSummary, setAcceptedSummary] = useState<boolean>(false)

  return (
    <>
      <Text
        style={Styling.marginBottom}
        type='title'
      >
        {i18n.t('Overzicht')}
      </Text>
      <Text
        style={Styling.marginBottomMedium}
        type='description'
      >
        {i18n.t(
          'Nog even een laatste check, controleer of alle velden nauwkeurig zijn ingevuld. Dit helpt uw adviseur bij het goed kunnen afwikkelen van de schade.'
        )}
      </Text>
      <ItemQuestionResult
        description={policyLabel}
        label='Polis'
      />
      <ItemQuestionResult
        description={dateUtils.formatTimeStamp(formClaimDamage?.answersStatic?.dateOfDamage ?? '')}
        label='Datum waarop schade is ontstaan'
        style={Styling.marginTop}
      />
      <ItemQuestionResult
        description={formClaimDamage?.answersStatic?.description ?? ''}
        label={staticClaimQuestionLabels.description}
        style={Styling.marginTop}
      />
      <ItemQuestionResult
        description={formClaimDamage?.answersStatic?.phoneNumber ?? ''}
        label='Uw telefoonnummer'
        style={Styling.marginTop}
      />

      {(questions || []).map(QuestionResult)}

      <Text
        style={Styling.marginTopMedium}
        type='label'
      >
        Bijlage(n)
      </Text>
      {Object.keys(formClaimDamage.additionalFiles).map((name, index) => {
        const currentFile = formClaimDamage.additionalFiles[name]

        return (
          <Text
            key={index}
            type='description'
            style={{ color: Colors.primary }}
          >
            {`- ${currentFile.title}`}
          </Text>
        )
      })}

      <InputCheckBox
        label='Ik bevestig dat mijn ingevulde gegevens correct en naar waarheid zijn ingevuld. Bij doorzetting door mijn adviseur naar Nh1816 Verzekeringen wordt deze schademelding geregistreerd bij stichting CIS.'
        onChange={setAcceptedSummary}
        style={Styling.marginVerticalMedium}
        value={acceptedSummary}
      />

      <View style={Styling.row}>
        <ButtonPrevious onPress={props.onPreviousStep} />
        <Button
          color={Colors.primary}
          disabled={props.loadingSummary || !acceptedSummary}
          icon={['fal', 'paper-plane']}
          iconColor={Colors.white}
          loading={props.loadingSummary}
          onPress={props.finishStepSummary}
          style={Styling.autoLeft}
          title={i18n.t('Opsturen')}
        />
      </View>
    </>
  )
}

export default StepSummary
