import React from 'react'
import { useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import Screen from '@/components/Screen'
import Colors from '@/constants/Colors'
import selectors from '@/redux/selectors'
import navigation from '@/utils/navigation'
import type { IWtdItem } from '@/redux/reducers/support'
import { WtdCategory } from '@/redux/reducers/support'

export const getWtdItemIconName = (wtdItem: IWtdItem): string => {
  switch (wtdItem.category) {
    case WtdCategory.DAMAGE:
      return 'fragile'
    case WtdCategory.DAMAGE_REPAIR:
      return 'mask'
    case WtdCategory.DISEASED:
      return 'cross'
    case WtdCategory.LOSS:
      return 'ghost'
    case WtdCategory.LEGAL:
      return 'legal'
  }
}

const WhatToDos: React.FC = () => {
  const colors = useSelector(selectors.getThemeColors)
  const wtd = useSelector(selectors.getWtd)

  return (
    <Screen>
      <Bootstrap.Row
        gutterHorizontal={15}
        gutterVertical={15}
      >
        {wtd.map((w, i) => {
          const icon = getWtdItemIconName(w)

          return (
            <Bootstrap.Column
              key={`what-to-do-button-${w.category}-${i}`}
              span={{ lg: 6, md: 6, sm: 12, xl: 4, xs: 12 }}
            >
              <Button
                color={Colors.white}
                icon={icon}
                iconCircleColor={colors?.lightBrand}
                iconColor={colors?.brand}
                onPress={async () => await navigation.openScreenWhatToDo(w.category)}
                title={w.subject}
                titleColor={Colors.gray}
              />
            </Bootstrap.Column>
          )
        })}
      </Bootstrap.Row>
    </Screen>
  )
}

export default WhatToDos
