import React from 'react'
import { useSelector } from 'react-redux'

import PDFViewer from '../../pdf_viewer'

import i18n from '@/utils/i18n'

import selectors from '@/redux/selectors'

function GeneralConditions () {
  const currentRelation = useSelector(selectors.getCurrentRelation)
  if (!currentRelation) {
    return null
  }

  const fileParts = currentRelation.combiPolicyCondition.url.split('/')
  const filename = fileParts ? fileParts[fileParts.length - 1] : ''

  return (
    <PDFViewer
      route={{
        params: {
          url: currentRelation.combiPolicyCondition.url,
          errorMessage: i18n.t('Het is niet gelukt om de algemene voorwaarden te downloaden'),
          fileName: filename,
          title: 'Algemene voorwaarden',
          key: `general-conditions-${filename}`
        }
      }}
    />
  )
}

export default GeneralConditions
