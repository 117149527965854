import type { Policy } from '@/types/objects'
import api from '@/utils/api_wrapper'

export const getPolicies = async () =>
  await api.get('policies')

export const getPolicy = async (id: string) =>
  await api.get(`policies/${id}`)

interface IPostRebuildValueMeterProps {
  policy_id: Policy['id']
  renovationCost: string
  renovationCostAbove: 'Ja' | 'Nee'
}

export const postRebuildValueMeter = async (policyId: string, data: IPostRebuildValueMeterProps) =>
  await api.post(`policies/${policyId}/rebuilding-value`, data)

export const generateGreenCard = async (policyIdPullingVehicle: string, policyIdTowedVehicle: string) =>
  await api.post(`policies/${policyIdPullingVehicle}/${policyIdTowedVehicle}/insurance-card`, {})

export const requestPolicyAdvise = async (relationId: string, agencyId: string, message: string, products: string[]) => {
  const policyAdvise = {
    advise_explanation: message,
    product_names: products?.filter(item => item !== ''),
    agency_id: agencyId
  }
  return await api.post(`relations/advise-request/${relationId}`, policyAdvise)
}
