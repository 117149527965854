import * as CONSTANTS from '@/redux/constants/member_benefits'
import type { IMemberBenefit } from '@/types/objects'
import prototype from '@/utils/prototype'

import type { TApiCallDispatch } from '../actions/api'
import type { downloadVoucherSuccess } from '../actions/member_benefits'

export interface IMemberBenefitsState {
  detail: {
    data: IMemberBenefit | null

    downloadLoading: boolean
    downloadError: boolean
    downloadSuccess: boolean

    showToast: boolean
  }

  list: {
    data: IMemberBenefit[]
  }
}

export const initialState: IMemberBenefitsState = {
  detail: {
    data: null,

    downloadError: false,
    downloadLoading: false,
    downloadSuccess: false,

    showToast: false
  },

  list: {
    data: []
  }
}

type TDispatch = TApiCallDispatch & ReturnType<typeof downloadVoucherSuccess>

export default (state: IMemberBenefitsState = initialState, action: TDispatch): IMemberBenefitsState => {
  switch (action?.type) {
    case 'GET_MEMBER_BENEFITS_SUCCESS':
      // data is set to false when the user is not a member of the cooperation
      if (action.payload.data) {
        return {
          ...state,
          list: {
            ...state.list,
            data: prototype
              .mergeArrays(state.list.data, action.payload.data as IMemberBenefit[])
              ?.sort((a, b) => a?.sortNumber - b?.sortNumber)
          }
        }
      }
      return state

    case 'GET_MEMBER_BENEFIT': {
      const data = state.list.data?.find((benefit) => benefit.id.toString() === action?.payload?.id?.toString())

      return {
        ...state,
        detail: {
          ...state.detail,
          data: data ?? initialState.detail.data
        }
      }
    }

    case 'GET_MEMBER_BENEFIT_SUCCESS':
    case 'PUT_MEMBER_BENEFIT_SUCCESS': {
      const listData = prototype.mergeObjectInArray(
        state.list.data,
        action.payload.data as IMemberBenefit
      )

      return {
        ...state,
        detail: {
          ...state.detail,
          data: listData.find((item) => item.id.toString() === (action.payload.data as IMemberBenefit).id.toString())!,
          showToast: (action.type === 'PUT_MEMBER_BENEFIT_SUCCESS')
        },
        list: {
          ...state.list,
          data: listData
        }
      }
    }

    case CONSTANTS.MEMBER_BENEFIT_CLOSE_DETAIL:
      return {
        ...state,
        detail: initialState.detail
      }

    case CONSTANTS.MEMBER_BENEFIT_DOWNLOAD_VOUCHER:
      return {
        ...state,
        detail: {
          ...state.detail,
          downloadError: initialState.detail.downloadError,
          downloadLoading: true,
          downloadSuccess: initialState.detail.downloadSuccess
        }
      }

    case CONSTANTS.MEMBER_BENEFIT_DOWNLOAD_VOUCHER_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: prototype.mergeObjectInArray(state.list.data, action.payload.memberBenefit)
        },
        detail: {
          ...state.detail,
          data: action?.payload?.memberBenefit,
          downloadError: initialState.detail.downloadError,
          downloadLoading: initialState.detail.downloadLoading,
          downloadSuccess: true
        }
      }

    case CONSTANTS.MEMBER_BENEFIT_DOWNLOAD_VOUCHER_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          downloadError: action?.payload?.error,
          downloadLoading: initialState.detail.downloadLoading,
          downloadSuccess: initialState.detail.downloadSuccess
        }
      }

    default:
      return state
  }
}
