import React, { useState, useEffect } from 'react'
import type { ScaledSize } from 'react-native'
import { Dimensions } from 'react-native'

import ContextBreakpoint from '@/bootstrap/ContextBreakpoint'
import defaults from '@/bootstrap/defaults'
import getBreakpoint from '@/bootstrap/getBreakpoint'

const Root: React.FC<{ children: React.ReactNode }> = (props) => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint(defaults.breakpoints, Dimensions.get('window').width))

  useEffect(() => {
    const handleDimensionsChanged = (e: { window: ScaledSize }): void => {
      if (e?.window?.width) {
        const breakpoint = getBreakpoint(defaults.breakpoints, e.window.width)
        setBreakpoint(breakpoint)
      }
    }

    const listener = Dimensions.addEventListener('change', handleDimensionsChanged)

    return () => {
      listener?.remove?.()
    }
  }, [])

  return (
    <ContextBreakpoint.Provider value={breakpoint}>
      {props.children}
    </ContextBreakpoint.Provider>
  )
}

export default Root
