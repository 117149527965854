import React from 'react'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@/components/buttons/Button'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import { startClaimDamageForm } from '@/redux/actions/claims'
import type { Policy } from '@/types/objects'
import i18n from '@/utils/i18n'
import selectors from '@/redux/selectors'

const ClaimButton: React.FC<{ policy: Policy }> = ({ policy }) => {
  const dispatch = useDispatch()
  const onPress = () => dispatch(startClaimDamageForm(policy?.id))
  const colors = useSelector(selectors.getThemeColors)

  return (
    <Button
      color={colors?.brand || Colors.primary}
      icon='fragile'
      iconCircleColor={Colors.white}
      iconColor={colors?.brand || Colors.primary}
      onPress={onPress}
      style={styles.button}
      title={i18n.t('Schade melden')}
    />
  )
}

const styles = StyleSheet.create({
  button: {
    justifyContent: 'center',
    marginTop: Sizes.spacingVerticalMedium
  }
})

export default ClaimButton
