import React from 'react'
import { StyleSheet, View } from 'react-native'
import * as Bootstrap from '@/bootstrap'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import ButtonOutline from '@/components/buttons/ButtonOutline'
import i18n from '@/utils/i18n'
import selectors from '@/redux/selectors'
import Colors from '@/constants/Colors'
import { useSelector } from 'react-redux'
import navigation from '@/utils/navigation'

const AddPolicySuccessScreen: React.FC = () => {
  const colors = useSelector(selectors.getThemeColors)

  const renderPhone = () => {
    return (
      <View style={styles.viewWrapper}>
        <View style={styles.topView}>
          <Text
            style={[Styling.marginBottom, { color: colors.brand }]}
            type='label'
          >
            {i18n.t('Advies aangevraagd')}
          </Text>
          <Text
            type='description'
            style={Styling.marginBottom}
          >
            {i18n.t('Uw verzekeringsadviseur zal uw aanvraag in behandeling nemen en voor meer informatie contact opnemen. Heeft u tussentijds vragen? Neem contact op met uw adviseur.')}
          </Text>
        </View>

        <View
          style={styles.bottomView}
        >
          <ButtonOutline
            title={i18n.t('Terug naar overzicht')}
            onPress={navigation.openScreenPolicies}
          />
        </View>
      </View>
    )
  }
  const renderTablet = () => {
    return (
      <Screen>
        <Bootstrap.Row>
          <Bootstrap.Column
            offsetLeft={{ lg: 1, md: 0, sm: 0, xl: 2, xs: 0, xxl: 3 }}
            span={{ lg: 10, md: 12, sm: 12, xl: 8, xs: 12, xxl: 6 }}
            style={Styling.itemsCenter}
          >
            <Text
              center
              style={Styling.marginBottom}
              type='title'
            >
              {i18n.t('Advies aangevraagd')}
            </Text>
            <Text
              center
              type='description'
            >
              {i18n.t('Uw verzekeringsadviseur zal uw aanvraag in behandeling nemen en voor meer informatie contact opnemen. Heeft u tussentijds vragen? Neem contact op met uw adviseur.')}
            </Text>
          </Bootstrap.Column>
          <Bootstrap.Column
            span={{ lg: 12, md: 12, sm: 12, xl: 10, xs: 12, xxl: 9 }}
            style={Styling.autoHorizontal}
          >
            <ButtonOutline
              title={i18n.t('Terug naar overzicht')}
              onPress={navigation.openScreenPolicies}
              style={styles.marginTop}
            />
          </Bootstrap.Column>
        </Bootstrap.Row>
      </Screen>
    )
  }
  return (
    <Bootstrap.Switch
      renderPhone={renderPhone}
      renderTablet={renderTablet}
    />
  )
}

export const styles = StyleSheet.create({
  viewWrapper: {
    backgroundColor: Colors.white,
    flex: 1
  },
  topView: {
    padding: 32
  },
  bottomView: {
    padding: 32,
    backgroundColor: Colors.background,
    flexGrow: 1,
    justifyContent: 'flex-end'
  },
  marginTop: {
    marginTop: 32
  }
})

export default AddPolicySuccessScreen
