import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import type { TNavigationProp } from '@/types/props'
import common from '@/utils/common'
import fs from '@/utils/fs'
import i18n from '@/utils/i18n'
import PDFViewer from '../pdf_viewer'

interface TOwnProps {
  navigation: TNavigationProp
}

type ServiceGuideProps = TOwnProps & {
  filename: string
  title: string
  url: string
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
function _ServiceGuide (props: ServiceGuideProps) {
  useEffect(() => {
    props.navigation.setOptions({ title: props.title })
  }, [props.navigation, props.title])

  return (
    <PDFViewer
      route={{
        params: {
          url: props.url,
          fileName: props.filename,
          key: `service-guide-${props.filename}`,
          errorMessage: i18n.t('Het is niet gelukt om het dienstverleningsdocument te downloaden'),
          title: props.filename
        }
      }}
    />
  )
}

const mapStateToProps = (state: TState, ownProps: TOwnProps) => {
  const serviceGuideId = common.getNavigationParam(ownProps, 'serviceGuideId')
  const serviceGuide = selectors.getServiceGuide(state, serviceGuideId)
  const filename = fs.determineFilenameServiceGuide(serviceGuide)

  return {
    filename,
    title: serviceGuide?.title,
    url: serviceGuide?.url
  }
}

export default connect(mapStateToProps)(_ServiceGuide)
