import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import Block from '@/components/Block'
import Button from '@/components/buttons/Button'
import Icon from '@/components/Icon'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import type { IconProp } from '@/types/props'
import selectors from '@/redux/selectors'

interface TCardNavigatesProps {
  buttons: any[]
  icon?: IconProp
  title: string
}

const CardNavigates: React.FC<TCardNavigatesProps> = (props) => {
  const colors = useSelector(selectors.getThemeColors)

  return (
    <Block>
      <View style={styles.titleContainer}>
        <Icon
          large
          circleColor={colors?.lightBrand}
          color={colors?.brand}
          icon={props.icon}
          style={Styling.marginRight}
        />
        <Text type='subtitle'>{props.title}</Text>
      </View>

      {(props.buttons || []).map((button, index) => (
        <Button
          borderColor={Colors.background}
          color={Colors.white}
          icon={button.icon}
          iconCircleColor={colors?.lightBrand}
          iconColor={colors?.brand}
          // eslint-disable-next-line react/no-array-index-key
          key={`card-navigates-${button.title}-${index}`}
          onPress={button.onPress}
          style={index !== 0 && Styling.marginTop}
          title={button.title}
          titleColor={Colors.gray}
        />
      ))}
    </Block>
  )
}

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: Sizes.spacingVerticalMedium
  }
})

export default CardNavigates
