import React, { useEffect } from 'react'
import { decode } from 'html-entities'
import { useDispatch, useSelector } from 'react-redux'

import Screen from '@/components/Screen'
import Block from '@/components/Block'
import Text from '@/components/wrappers/Text'
import Html from '@/components/wrappers/HTML'
import EmptyState from '@/components/EmptyState'

import type { State } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import { apiCall } from '@/redux/actions/api'
import { ENTITIES } from '@/redux/constants'

import { IS_WEB } from '@/constants/platform'

import type { INotification } from '@/types/objects'
import type { TDetailScreenProps } from '@/types/props'

import i18n from '@/utils/i18n'

const Notification: TDetailScreenProps<INotification['messageId']> = (props) => {
  const dispatch = useDispatch()

  const id = props.route.params?.id?.toString()
  const notification = useSelector((state: State) => selectors.getNotification(state, id))
  const refreshing = !!useSelector(selectors.isLoading(ENTITIES.NOTIFICATION, notification?.id))

  const refresh = () => {
    if (!notification) {
      dispatch(apiCall('GET', ENTITIES.NOTIFICATION, id))
    }
  }

  const setRead = () => {
    if (notification?.read === false) {
      dispatch(apiCall('PUT', ENTITIES.NOTIFICATION, notification?.messageId, notification))
    }
  }

  const renderEmptyState = () => (
    <EmptyState
      message={refreshing
        ? i18n.t('De notificatie wordt geladen')
        : i18n.t('De notificatie kan niet geladen worden, probeer het later opnieuw.')}
    />
  )

  useEffect(() => {
    refresh()
    setRead()
  }, [notification])

  return (
    <Screen
      white={!IS_WEB}
      useRefreshControl
      onRefresh={refresh}
      refreshing={refreshing}
    >
      {notification
        ? (
          <Block>
            <Text type='descriptionBold'>{decode(notification?.title)}</Text>
            <Html
              html={notification?.message ?? ''}
              style={{ marginTop: 8 }}
            />

            <Text
              style={{ marginTop: 8 }}
              type='paragraphLight'
            >
              {notification.createdAt}
            </Text>
          </Block>
          )
        : renderEmptyState()}
    </Screen>
  )
}

export default Notification
