/* eslint-disable @typescript-eslint/no-var-requires, global-require, import/no-mutable-exports */

import { Platform } from 'react-native'

let MapView: any
let Marker: any

if (Platform.OS === 'web') {
  MapView = require('react-native-maps').default
  Marker = MapView.Marker
} else {
  MapView = require('react-native-map-clustering').default
  Marker = require('react-native-maps').Marker
}

export { Marker }
export default MapView
