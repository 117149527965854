import React from 'react'
import { StyleSheet } from 'react-native'

import type { TButtonProps } from '@/components/buttons/Button'
import Button from '@/components/buttons/Button'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import i18n from '@/utils/i18n'

type TButtonNextProps = TButtonProps & {
  small?: boolean
}

export default function ButtonNext ({ style, ...props }: TButtonNextProps) {
  return (
    <Button
      color={Colors.primary}
      enableHitSlop={props.small}
      iconColorRight={Colors.white}
      iconRight={['fal', 'chevron-right']}
      iconStyleRight={props.small && styles.iconSmall}
      small={props.small}
      style={[props.small && styles.buttonSmall, style]}
      title={!props.small && i18n.t('Volgende')}
      titleStyle={!props.small && Styling.marginLeft}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  buttonSmall: {
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: Sizes.buttonSmall
  },
  iconSmall: {
    marginLeft: 0
  }
})
