import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import i18n from '@/utils/i18n'

import Screen from '@/components/Screen'
import EmptyState from '@/components/EmptyState'
import ListItemNotification from './components/ListItemNotification'

import selectors from '@/redux/selectors'
import { apiCall } from '@/redux/actions/api'
import { ENTITIES } from '@/redux/constants'
import type { INotification } from '@/types/objects'
import { setShouldOpenAfterLogin } from '@/redux/actions/notifications'

const Notifications: React.FC = () => {
  const dispatch = useDispatch()

  const notifications = useSelector(selectors.getNotifications)
  const refreshing = !!useSelector(selectors.isLoading(ENTITIES.NOTIFICATIONS))

  const refresh = () => dispatch(apiCall('GET', ENTITIES.NOTIFICATIONS, undefined, undefined, true))

  const renderItem = (notification: INotification) => (
    <ListItemNotification
      key={`notification-list-item-${notification.messageId}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...notification}
    />
  )

  const renderEmptyState = () => (
    <EmptyState
      message={refreshing
        ? i18n.t('De notificaties worden opgehaald')
        : i18n.t('U heeft nog geen notificaties')}
    />
  )

  useEffect(() => {
    refresh()
    dispatch(setShouldOpenAfterLogin(false))
  }, [])

  return (
    <Screen
      useRefreshControl
      onRefresh={refresh}
      refreshing={refreshing}
    >
      {notifications?.length > 0
        ? notifications.map(renderItem)
        : renderEmptyState()}
    </Screen>
  )
}

export default Notifications
