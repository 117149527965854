import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, TouchableOpacity } from 'react-native'

import Icon from '@/components/Icon'
import Touchable from '@/components/touchables/Touchable'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'

interface TInputCheckBoxProps {
  label?: string
  onChange?: (value: boolean) => void
  onChangeValue?: (value: boolean) => void
  onValueChange?: (value: boolean) => void
  required?: boolean
  style?: StyleProp<ViewStyle>
  value?: boolean
}

export default class CheckBox extends React.Component<TInputCheckBoxProps> {
  toggleValue = () => {
    if (this.props.onChange) {
      this.props.onChange(!this.props.value)
    } else if (this.props.onChangeValue) {
      this.props.onChangeValue(!this.props.value)
    } else if (this.props.onValueChange) {
      this.props.onValueChange(!this.props.value)
    }
  }

  renderCheckBox (style?: StyleProp<ViewStyle>) {
    return (
      <Touchable
        onPress={this.toggleValue}
        style={style ? [styles.checkBox, style] : styles.checkBox}
      >
        {this.props.value === true
          ? <Icon
              color={Colors.success}
              icon='check'
            />
          : null}
      </Touchable>
    )
  }

  render () {
    if (this.props.label) {
      return (
        <TouchableOpacity
          accessible={false}
          onPress={this.toggleValue}
          style={this.props.style ? [Styling.rowCenter, this.props.style] : Styling.rowCenter}
        >
          {this.renderCheckBox()}
          <Text
            style={Styling.marginSmall}
            type='label'
          >
            {this.props.required
              ? <Icon
                  small
                  color={Colors.primary}
                  icon='asterisk'
                />
              : null}
            {this.props.required ? '  ' : null}
            {this.props.label}
          </Text>
        </TouchableOpacity>
      )
    }
    return this.renderCheckBox(this.props.style)
  }
}

const styles = StyleSheet.create({
  checkBox: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderColor: Colors.cc,
    borderRadius: Sizes.borderRadius,
    borderWidth: 1,
    height: Sizes.checkBox,
    justifyContent: 'center',
    overflow: 'hidden',
    width: Sizes.checkBox
  }
})
