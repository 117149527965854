import Notifications from '@/screens/notifications'
import Notification from '@/screens/notifications/notification'
import i18n from '@/utils/i18n'
import { createStackNavigator } from '@react-navigation/stack'

const NotificationsStack = createStackNavigator()
const NotificationsStackNavigator = () => (
  <NotificationsStack.Navigator screenOptions={{ headerShown: false }}>
    <NotificationsStack.Screen
      name='Notifications'
      component={Notifications}
      options={{ title: i18n.t('Notificaties') }}
    />
    <NotificationsStack.Screen
      name='Notification'
      component={Notification}
      options={{ title: i18n.t('Notificatie') }}
    />
  </NotificationsStack.Navigator>
)

export default NotificationsStackNavigator
