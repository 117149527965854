import api from '@/utils/api_wrapper'

import type { INotification } from '@/types/objects'

export const getNotifications = async () =>
  await api.get<INotification[]>('support/inbox-messages')

export const getNotification = async (id: string) =>
  await api.get<INotification>(`support/inbox-messages/${id}`)

export const putNotification = async (id: string) =>
  await api.put<INotification>(`support/inbox-messages/${id}`, {})

export const deleteNotification = async (id: string) =>
  await api.delete(`support/inbox-messages/${id}`)
