import React from 'react'
import { ScrollView } from 'react-native'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import BreadCrumbs from '@/components/BreadCrumbs'
import Line from '@/components/Line'
import Screen from '@/components/Screen'
import type { Route } from '@/components/Tabs'
import Tabs from '@/components/Tabs'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'

import EmailForm from './components/email_form'
import PasswordForm from './components/password_form'

const ManageLogin: React.FC = () => {
  const renderTab = ({ route }: { route: Route }) => {
    if (route.key === 'password') {
      return <PasswordForm />
    }

    return <EmailForm />
  }

  const renderContent = () => (
    <Tabs
      scrollEnabled
      renderScene={renderTab}
      routes={[
        { key: 'email', title: 'Email' },
        { key: 'password', title: 'Wachtwoord' }
      ]}
    />
  )

  const renderContentPhone = () => <ScrollView style={Styling.backgroundWhite}>{renderContent()}</ScrollView>

  const renderContentTablet = () => (
    <Bootstrap.Row>
      <Bootstrap.Column span={{ lg: 8, md: 10, sm: 12, xl: 6, xs: 12 }}>{renderContent()}</Bootstrap.Column>
    </Bootstrap.Row>
  )

  const renderBreadCrumbs = (props: any) => (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <BreadCrumbs {...props} />
      <Line
        horizontal
        color={Colors.background}
        height={1}
        style={Styling.marginTop}
      />
    </>
  )

  const renderPhone = () => (
    <Screen
      noPadding
      white
      renderBreadCrumbs={renderBreadCrumbs}
    >
      {renderContentPhone()}
    </Screen>
  )

  const renderTablet = () => (
    <Screen>
      <Block noPadding>{renderContentTablet()}</Block>
    </Screen>
  )

  return (
    <Bootstrap.Switch
      renderPhone={renderPhone}
      renderTablet={renderTablet}
    />
  )
}

export default ManageLogin
