import React, { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Screen from '@/components/Screen'
import Html from '@/components/wrappers/HTML'
import selectors from '@/redux/selectors'
import { useNavigation } from '@react-navigation/core'
import type { WtdCategory } from '@/redux/reducers/support'

interface INavigationProps {
  route: {
    params: {
      category: WtdCategory
    }
  }
}

function WhatToDo (props: INavigationProps) {
  const navigation = useNavigation()
  console.log({ navigation })
  const wtdItem = useSelector(selectors.getWtdItemByCategory(props.route.params.category))

  // TODO: redirect
  if (!wtdItem) {
    return null
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      title: wtdItem?.subject ?? 'Wat te doen bij'
    })
  }, [])

  return (
    <Bootstrap.Switch
      renderPhone={() => (
        <Screen white>
          <Html html={wtdItem.content} />
        </Screen>
      )}
      renderTablet={() => (
        <Screen>
          <Block noFlex>
            <Html html={wtdItem.content} />
          </Block>
        </Screen>
      )}
    />
  )
}

export default WhatToDo
