import { createSelector } from 'reselect'
import type { State } from '../reducers'

export const getMemberBenefits = (state: State) => state?.memberBenefits?.list
export const getMemberBenefit = (state: State) => state?.memberBenefits?.detail

export const getMemberBenefitById = (id: string | number) => createSelector(
  getMemberBenefits,
  (benefits) => benefits?.data?.find(x => String(x.id) === String(id))
)
