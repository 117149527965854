import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'

interface TDotProps {
  borderColor?: string
  children?: React.ReactNode
  color?: string
  huge?: boolean
  large?: boolean
  size?: number
  small?: boolean
  style?: StyleProp<ViewStyle>
  tiny?: boolean
}

export default function Dot (props: TDotProps) {
  let sizeStyle
  if (props.size) {
    sizeStyle = {
      borderRadius: props.size / 2,
      height: props.size,
      width: props.size
    }
  } else if (props.tiny) {
    sizeStyle = styles.tiny
  } else if (props.small) {
    sizeStyle = styles.small
  } else if (props.large) {
    sizeStyle = styles.large
  } else if (props.huge) {
    sizeStyle = styles.huge
  } else {
    sizeStyle = styles.default
  }
  return (
    <View
      style={[
        styles.view,
        sizeStyle,
        props.style,
        props.color && { backgroundColor: props.color },
        props.borderColor && { borderColor: props.borderColor, borderWidth: 1 }
      ]}
    >
      {props.children}
    </View>
  )
}

const generateSizeStyle = (size: any) => ({
  borderRadius: size / 2,
  height: size,
  width: size
})

const styles = StyleSheet.create({
  default: generateSizeStyle(Sizes.dot),
  huge: generateSizeStyle(Sizes.dotHuge),
  large: generateSizeStyle(Sizes.dotLarge),
  small: generateSizeStyle(Sizes.dotSmall),
  tiny: generateSizeStyle(Sizes.dotTiny),
  view: {
    backgroundColor: Colors.black,
    overflow: 'hidden'
  }
})
