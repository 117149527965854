import React, { Component, createRef } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import { PROVIDER_GOOGLE } from 'react-native-maps'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import ListItemCompany from '@/components/list_items/ListItemCompany'
import Screen from '@/components/Screen'
import Tabs from '@/components/Tabs'
import MapView from '@/components/wrappers/MapView'
import RefreshControl from '@/components/wrappers/RefreshControl'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Settings from '@/constants/Settings'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import ModalCompany from '@/modals/ModalCompany'
import ModalFilters from '@/screens/overview/components/ModalFilters'
import { apiCall } from '@/redux/actions/api'
import type { State as TState } from '@/redux/reducers'
import type { ThemeColors } from '@/redux/reducers/theme'
import selectors from '@/redux/selectors'
import MarkerCompany from '@/screens/overview/components/MarkerCompany'
import type { Company, ICarWindowRepairer, IRepairer } from '@/types/objects'
import { CompanyTypes } from '@/types/objects'
import i18n from '@/utils/i18n'
import { ENTITIES } from '@/redux/constants'
import type { FiltersState } from '@/redux/reducers/filters'
import OverlaySpinner from '@/components/overlay_spinner'

const animationDuration = 500 // Milliseconds
const coordinationDelta = 0.1
const mapOptions = { fullscreenControl: false, mapTypeControl: false }

interface TDamageRepairVehicleProps {
  colors: ThemeColors
  companies: Company[]
  filters: FiltersState
  refreshing: boolean
  apiCall: typeof apiCall
}

interface TDamageRepairVehicleState {
  modalCompanyVisible: boolean
  modalFiltersVisible: boolean
  query: string
  selectedCompany: Company | null
}

class DamageRepairVehicle extends Component<TDamageRepairVehicleProps, TDamageRepairVehicleState> {
  mapViewRef = createRef()

  routes = [
    {
      key: 'map',
      title: i18n.t('Kaart')
    },
    {
      key: 'list',
      title: i18n.t('Lijst')
    }
  ]

  constructor (props: TDamageRepairVehicleProps) {
    super(props)

    this.state = {
      modalCompanyVisible: false,
      modalFiltersVisible: true,
      query: '',
      selectedCompany: null
    }
  }

  componentDidMount () {
    this.refresh()
  }

  refresh = () => {
    this.props.apiCall('GET', ENTITIES.REPAIRERS)
    this.props.apiCall('GET', ENTITIES.CAR_WINDOW_REPAIRERS)
    this.props.apiCall('GET', ENTITIES.CAR_WINDOW_REPAIRER_BRANDS)
  }

  keyExtractor = (company: Company) => `list-item-damage-repair-vehicle-${company.id}`

  centerMap = () => {
    if (this.props.filters?.location) {
      this.mapViewRef?.current?.animateToRegion(
        {
          latitude: this.props.filters?.location?.latitude,
          latitudeDelta: coordinationDelta,
          longitude: this.props.filters?.location?.longitude,
          longitudeDelta: coordinationDelta
        },
        animationDuration
      )
    }
  }

  hideCompanyModal = () => this.setState({ modalCompanyVisible: false, selectedCompany: null })

  openCompanyModal = (company: Company) => this.setState({ modalCompanyVisible: true, selectedCompany: company })

  hideFiltersModal = () => this.setState({ modalFiltersVisible: false }, this.centerMap)

  openFiltersModal = () => this.setState({ modalFiltersVisible: true })

  renderRefreshControl = () => (
    <RefreshControl
      onRefresh={this.refresh}
      refreshing={this.props.refreshing}
      title={i18n.t('Schadebedrijven aan het ophalen')}
    />
  )

  renderItem = ({ item: company }: { item: IRepairer | ICarWindowRepairer }) => (
    <ListItemCompany
      company={company}
      query={this.state.query}
    />
  )

  renderMarker = (company: Company) => (
    <MarkerCompany
      company={company}
      key={`map-marker-company-${company.id}`}
      onPress={this.openCompanyModal}
    />
  )

  renderScene = ({ route }: { route: { key: string } }) => {
    const textResults = this.props.companies?.length === 1 ? i18n.t('resultaat') : i18n.t('resultaten')

    if (route.key === 'map') {
      return (
        <Screen
          noBreadCrumbs
          onlyChildren
        >
          {this.props.refreshing
            ? <OverlaySpinner visible />
            : null}

          <MapView
            clusterColor={this.props.colors?.brand}
            clusterTextColor={Colors.white}
            initialRegion={Settings.regionMiddleOfHolland}
            options={mapOptions}
            ref={this.mapViewRef}
            provider={PROVIDER_GOOGLE}
            region={
              Platform.OS === 'web'
                ? {
                    latitude: this.props.filters?.location?.latitude,
                    latitudeDelta: coordinationDelta,
                    longitude: this.props.filters?.location?.longitude,
                    longitudeDelta: coordinationDelta
                  }
                : null
            }
            style={Styling.fullSize}
          >
            {(this.props.companies || []).map(this.renderMarker)}
          </MapView>

          <View
            pointerEvents='box-none'
            style={styles.overlay}
          >
            <View
              pointerEvents='box-none'
              style={styles.topContainer}
            >
              <Button
                color={Colors.white}
                title={`${this.props.companies?.length} ${textResults}`}
                titleColor={Colors.gray}
              />
              <Button
                color={this.props.colors?.brand}
                icon='sliders-h'
                iconColor={Colors.white}
                onPress={this.openFiltersModal}
                title={i18n.t('Filteren')}
              />
            </View>
          </View>
        </Screen>
      )
    }

    if (route.key === 'list') {
      return (
        <Screen
          noBreadCrumbs
          noPadding
          refreshControl={this.renderRefreshControl()}
        >
          <View style={styles.screen}>
            <View style={styles.topContainer}>
              <View style={Styling.rowCenter}>
                <Text type='results'>
                  {this.props.companies?.length} {textResults}
                </Text>
              </View>

              <Button
                color={this.props.colors?.brand}
                icon='sliders-h'
                iconColor={Colors.white}
                onPress={this.openFiltersModal}
                title={i18n.t('Filteren')}
              />
            </View>
          </View>

          <Bootstrap.Collection
            data={this.props.companies}
            gutter={15}
            id='damage-repair-vehicle-collection'
            keyExtractor={this.keyExtractor}
            margin={30}
            renderItem={this.renderItem}
            span={{ lg: 6, md: 6, sm: 6, xl: 6, xs: 12 }}
          />
        </Screen>
      )
    }

    return null
  }

  render () {
    return (
      <>
        {this.state.modalCompanyVisible && this.state.selectedCompany !== null
          ? (
            <ModalCompany
              company={this.state.selectedCompany}
              onHide={this.hideCompanyModal}
              visible={this.state.modalCompanyVisible}
            />
            )
          : null}

        {this.state.modalFiltersVisible
          ? (
            <ModalFilters
              onHide={this.hideFiltersModal}
              visible={this.state.modalFiltersVisible}
            />
            )
          : null}

        <Tabs
          renderScene={this.renderScene}
          routes={this.routes}
          swipeEnabled={false}
        />
      </>
    )
  }
}

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: 'transparent',
    bottom: 0,
    left: 0,
    paddingHorizontal: Sizes.spacingHorizontal,
    paddingVertical: Sizes.spacingVertical,
    position: 'absolute',
    right: 0,
    top: 0
  },
  screen: {
    backgroundColor: Colors.background,
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingTop: Sizes.screenPaddingVertical
  },
  topContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Sizes.spacingVertical
  }
})

const mapStateToProps = (state: TState) => {
  const filters: FiltersState = selectors.getFilters(state)
  let companies = selectors.getRepairCompanies(state, filters)

  if (companies.length >= 50) {
    const nationwideCompany = companies.filter((company) => (company as ICarWindowRepairer).chain)
    companies = companies.slice(0, 50)
    const allFilteredCompanies = nationwideCompany.concat(companies)
    const allFilteredCompaniesWithoutDuplicates = allFilteredCompanies.filter((item, index) => allFilteredCompanies.indexOf(item) === index)

    companies = allFilteredCompaniesWithoutDuplicates
  }

  const repairersLoading = !!selectors.isLoading(ENTITIES.REPAIRERS)(state)
  const windowRepairersLoading = !!selectors.isLoading(ENTITIES.CAR_WINDOW_REPAIRERS)(state)

  const refreshing =
    (filters.companyType === CompanyTypes.car && repairersLoading) ||
    (filters.companyType === CompanyTypes['car-window'] && windowRepairersLoading)

  return {
    colors: selectors.getThemeColors(state),
    companies,
    filters,
    refreshing
  }
}

export default connect(mapStateToProps, { apiCall })(DamageRepairVehicle)
