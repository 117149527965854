import type { Payment } from '@/types/objects'
import type { TApiCallSuccess } from '../actions/api'

export interface IPaymentsState {
  data: Payment[]
}

export const initialState: IPaymentsState = {
  data: []
}

export default (state = initialState, action: TApiCallSuccess): IPaymentsState => {
  switch (action?.type) {
    case 'GET_PAYMENTS_SUCCESS':
      return {
        ...state,
        data: action.payload.data as Payment[]
      }

    case 'SELECT_RELATION':
      return initialState

    default:
      return state
  }
}
