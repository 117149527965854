import { View } from 'react-native'
import Colors from '@/constants/Colors'

interface OpeningHourDotProps {
  openingCondition: any
}

function getBackgroundColorFromOpeningCondition (openingCondition: any) {
  switch (openingCondition) {
    case -1:
      return Colors.orange
    case 0:
      return Colors.green
    case 1:
      return Colors.red
  }
}

const SIZE = 8

const OpeningHourDot = ({
  openingCondition
}: OpeningHourDotProps) => {
  const style = {
    width: SIZE,
    height: SIZE,
    borderRadius: SIZE / 2,
    backgroundColor: getBackgroundColorFromOpeningCondition(openingCondition)
  }

  return (
    <View style={style} />
  )
}

export default OpeningHourDot
