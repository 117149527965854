import lodash from 'lodash'

import type { ICarWindowRepairer, IRepairer } from '@/types/objects'

export interface ICompaniesState {
  repairers: IRepairer[]

  carWindowRepairers: ICarWindowRepairer[]

  carWindowRepairerBrands: string[]
}

export const initialState: ICompaniesState = {
  repairers: [],

  carWindowRepairers: [],

  carWindowRepairerBrands: []
}

export default (state = initialState, action: { type: string, payload: { data: unknown } }): ICompaniesState => {
  switch (action?.type) {
    case 'GET_REPAIRERS_SUCCESS':
      return {
        ...state,
        repairers: action.payload.data as IRepairer[]
      }

    case 'GET_CAR_WINDOW_REPAIRERS_SUCCESS':
      return {
        ...state,
        carWindowRepairers: action.payload.data as ICarWindowRepairer[]
      }

    case 'GET_CAR_WINDOW_REPAIRER_BRANDS_SUCCESS': {
      const carWindowRepairerBrands: string[] = lodash
        .uniq(action.payload.data as string[])
        .sort((a, b) => a.localeCompare(b))

      return {
        ...state,
        carWindowRepairerBrands
      }
    }

    default:
      return state
  }
}
