import Colors from '@/constants/Colors'
import type { Dict } from '@/redux/types'
import type { ClaimStatus } from '@/types/objects'
import i18n from './i18n'

interface IClaimConfig {
  circleColor: string
  color: string
  description: string
  icon: string
  type: string
}

export const defaultClaimConfig = {
  circleColor: Colors.lightBlue,
  color: Colors.blue,
  icon: 'horizontal-rule',
  type: 'paragraph'
}

const claimStatusConfigs: Dict<IClaimConfig> = {
  concept: {
    circleColor: Colors.lightBlue,
    color: Colors.blue,
    description: i18n.t(
      'Uw schademelding staat in concept. Ga verder waar u gebleven bent en maak uw schademelding definitief.'
    ),
    icon: 'pencil',
    type: 'paragraphLight'
  },
  pending: {
    circleColor: Colors.lightWaiting,
    color: Colors.waiting,
    description: i18n.t(
      'Uw schademelding is in behandeling. Aan de hand van het aangeleverde bewijs wordt uw schademelding beoordeeld. Heeft u nog vragen? Neem contact op met uw adviseur.'
    ),
    icon: 'clock',
    type: 'paragraphWaiting'
  },
  submitted: {
    circleColor: Colors.lightWaiting,
    color: Colors.waiting,
    description: i18n.t('Uw schademelding is succesvol ingediend. Uw adviseur neemt deze z.s.m. in behandeling.'),
    icon: 'clock',
    type: 'paragraphWaiting'
  },
  rejected: {
    circleColor: Colors.lightError,
    color: Colors.red,
    description: i18n.t(
      'Uw schademelding is afgekeurd door uw adviseur. Heeft u nog vragen? Neem contact op met uw adviseur.'
    ),
    icon: 'times',
    type: 'paragraphError'
  },
  finished: {
    circleColor: Colors.lightGreen,
    color: Colors.success,
    description: i18n.t(
      'Uw schademelding is afgerond. Aan de hand van het ingeleverde bewijs hebben we bepaald of uw melding is goedgekeurd of afgekeurd. U heeft hierover bericht ontvangen. Heeft u vragen over de melding? Neem contact op met uw adviseur.'
    ),
    icon: 'check',
    type: 'paragraphSuccess'
  }
}

export const getClaimConfig = (status?: ClaimStatus): IClaimConfig => {
  switch (status) {
    case 'Concept':
      return claimStatusConfigs.concept
    case 'Gemeld bij adviseur':
      return claimStatusConfigs.submitted
    case 'In behandeling':
      return claimStatusConfigs.pending
    case 'Afgehandeld':
      return claimStatusConfigs.finished
    default:
      return defaultClaimConfig as IClaimConfig
  }
}

export const getFileNameWithoutExtension = (fileName: string): string => {
  const lastDotIndex = fileName.lastIndexOf('.')
  if (lastDotIndex === -1 || lastDotIndex === 0) return fileName
  return fileName.substring(0, lastDotIndex)
}
