import { createSelector } from 'reselect'

import type { State as TState } from '@/redux/reducers'
import { getFirstParameter } from '@/redux/selectors/helpers'

export const getFiles = (state: TState) => state?.files

export const getFile = createSelector(
  [getFiles, getFirstParameter],
  (files, parameter) => (files?.[parameter]) || ''
)
