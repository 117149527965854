import { useNavigationState } from '@react-navigation/native'
import React, { useEffect, useMemo, useState } from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import Icon from '@/components/Icon'
import TouchableIcon from '@/components/touchables/TouchableIcon'
import TouchableText from '@/components/touchables/TouchableText'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import initialRouteNames from '@/navigation/mobile/helpers/initialRouteNames'
import paths from '@/navigation/web/helpers/paths'
import selectors from '@/redux/selectors'
import navigation from '@/utils/navigation'
import prototype from '@/utils/prototype'

const DEFAULT_LIMIT: number = 3
const DEFAULT_ROUTES: object[] = []

interface TBreadCrumbsProps {
  style?: StyleProp<ViewStyle>
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function BreadCrumbs (props: TBreadCrumbsProps) {
  const [limit, setLimit] = useState(DEFAULT_LIMIT)
  const [routes, setRoutes] = useState(DEFAULT_ROUTES)
  const colors = useSelector(selectors.getThemeColors)
  const navigationState = useNavigationState((state) => state)

  function determineRoutes (count = 0) {
    let routesRaw: any[] = navigation.getHierarchicalRoutes()

    routesRaw = (routesRaw || []).filter((route: any, index: number) =>
      !(index > 0 && initialRouteNames[routesRaw[index - 1].name] === route.name)
    )

    if ((!routesRaw || !routesRaw.length) && count < 10) {
      setTimeout(() => {
        determineRoutes(count + 1)
      }, 50)
    }

    routesRaw = routesRaw.slice(Math.max(routesRaw.length - (limit + 1), 0))
    setRoutes(routesRaw)
  }

  // With some navigation actions the routes are not fully available from the beginning which
  // causes the hierarchy to be incomplete. To solve that this ugly fix is implemented.
  useEffect(() => {
    setTimeout(determineRoutes, 100)
  }, [])

  useMemo(determineRoutes, [navigationState, limit])

  return routes && routes.length > 1
    ? (
      <View style={props.style ? [styles.view, props.style] : styles.view}>
        {routes.map((route, index) => {
          if (index === 0) {
            if (!route.name || route.name === 'RootStack') {
              return (
                <TouchableIcon
                  color={Colors.gray}
                  icon={['fal', 'home']}
                  key={route.key}
                  onPress={navigation.openScreenOverview}
                />
              )
            }
            return (
              <TouchableText
                noLineHeight
                color={Colors.gray}
                onPress={() => setLimit(limit + 1)}
                type='action'
              >
                ...
              </TouchableText>
            )
          }
          let text = route.name
          if (route.title) {
            text = route.title
          } else if (route.params?.title) {
            text = route.params.title
          } else if (route.name in paths) {
            const pathConfigMap = paths[route.name]
            text = prototype.capitalizeFirstLetter(
              (pathConfigMap?.path ?? pathConfigMap).replace(/[-]/g, ' ')
            )
          }
          return index < routes.length - 1
            ? (
              <React.Fragment key={route.key}>
                <Icon
                  small
                  color={Colors.gray}
                  icon={['fal', 'chevron-right']}
                  style={Styling.marginHorizontalTiny}
                />
                <TouchableText
                  color={Colors.gray}
                  onPress={async () => await navigation.push(route.name, { params: null, screen: null })}
                  type='action'
                >
                  {text}
                </TouchableText>
              </React.Fragment>
              )
            : (
              <React.Fragment key={route.key}>
                <Icon
                  small
                  color={Colors.gray}
                  icon={['fal', 'chevron-right']}
                  style={Styling.marginHorizontalTiny}
                />
                <Text
                  color={colors && colors.brand}
                  type='action'
                >
                  {text}
                </Text>
              </React.Fragment>
              )
        })}
      </View>
      )
    : null
}

const styles = StyleSheet.create({
  view: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
})
