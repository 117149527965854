import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'

import Icon from '@/components/Icon'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import navigation from '@/utils/navigation'

const hitSlopLeft = {
  ...Sizes.hitSlop,
  right: 0
}

const hitSlopRight = {
  ...Sizes.hitSlop,
  left: 0
}

interface THeaderRightProps {
  amountUnreadNotifications: number
}

function HeaderRight (props: THeaderRightProps) {
  const routes = navigation.getHierarchicalRoutes()
  const isNotifications = routes.find((route: any) => route && route.name === 'NotificationsStack')
  const isProfile = routes.find((route: any) => route && route.name === 'ProfileStack')
  return (
    <View style={styles.container}>
      <TouchableOpacity
        hitSlop={hitSlopLeft}
        onPress={navigation.openScreenNotifications}
        style={styles.touchable}
      >
        <Icon
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
          badgeNumber={props.amountUnreadNotifications > 0 ? props.amountUnreadNotifications : null}
          color={isNotifications ? Colors.gray : Colors.lightBrown}
          icon='bell'
          size={Sizes.headerIcon}
        />
      </TouchableOpacity>
      <TouchableOpacity
        hitSlop={hitSlopRight}
        onPress={navigation.openScreenProfile}
        style={styles.touchable}
      >
        <Icon
          color={isProfile ? Colors.gray : Colors.lightBrown}
          icon='user'
          size={Sizes.headerIcon}
        />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 8
  },
  touchable: {
    padding: 8
  }
})

const mapStateToProps = (state: TState) => ({
  amountUnreadNotifications: selectors.getAmountUnreadNotifications(state)
})

export default connect(mapStateToProps)(HeaderRight)
