import { StyleSheet } from 'react-native'

import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'

export default StyleSheet.create({
  absolute: {
    position: 'absolute'
  },
  autoBottom: {
    marginBottom: 'auto'
  },
  autoBottomLeft: {
    marginBottom: 'auto',
    marginLeft: 'auto'
  },
  autoHorizontal: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  autoLeft: {
    marginLeft: 'auto'
  },
  autoLeftTop: {
    marginLeft: 'auto',
    marginTop: 'auto'
  },
  autoRight: {
    marginRight: 'auto'
  },
  autoRightTop: {
    marginRight: 'auto',
    marginTop: 'auto'
  },
  autoTop: {
    marginTop: 'auto'
  },
  autoVertical: {
    marginBottom: 'auto',
    marginTop: 'auto'
  },
  background: {
    backgroundColor: Colors.background
  },
  backgroundWhite: {
    backgroundColor: Colors.white
  },
  centerCenter: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  columnCenter: {
    flexDirection: 'column'
  },
  columnStart: {
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  columnCenterCenter: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  columnStretchCenter: {
    alignItems: 'stretch',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  contentCenter: {
    justifyContent: 'center'
  },
  flex: {
    flex: 1
  },
  flexBackground: {
    backgroundColor: Colors.background,
    flex: 1
  },
  flexBetween: {
    flex: 1,
    justifyContent: 'space-between'
  },
  flexColumnCenter: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column'
  },
  flexEvenly: {
    flex: 1,
    justifyContent: 'space-evenly'
  },
  flexGrow: {
    flexGrow: 1
  },
  flexRow: {
    flex: 1,
    flexDirection: 'row'
  },
  flexRowBetween: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  flexRowCenter: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row'
  },
  flexRowEnd: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  flexWhite: {
    backgroundColor: Colors.white,
    flex: 1
  },
  flexWrap: {
    flex: 1,
    flexWrap: 'wrap'
  },
  fullHeight: {
    height: '100%'
  },
  fullSize: {
    height: '100%',
    width: '100%'
  },
  fullWidth: {
    width: '100%'
  },
  image: {
    backgroundColor: Colors.black,
    width: '100%'
  },
  itemsCenter: {
    alignItems: 'center'
  },
  itemsEnd: {
    alignItems: 'flex-end'
  },
  justifyCenter: {
    justifyContent: 'center'
  },
  margin: {
    marginHorizontal: Sizes.spacingHorizontal,
    marginVertical: Sizes.spacingVertical
  },
  marginBottom: {
    marginBottom: Sizes.spacingVertical
  },
  marginBottomBig: {
    marginBottom: Sizes.spacingVerticalBig
  },
  marginBottomMedium: {
    marginBottom: Sizes.spacingVerticalMedium
  },
  marginBottomMediumHalf: {
    marginBottom: Sizes.spacingVerticalMedium / 2
  },
  marginBottomNormal: {
    marginBottom: Sizes.spacingVerticalNormal
  },
  marginBottomSmall: {
    marginBottom: Sizes.spacingVerticalSmall
  },
  marginBottomTiny: {
    marginBottom: Sizes.spacingVerticalTiny
  },
  marginHorizontal: {
    marginHorizontal: Sizes.spacingHorizontal
  },
  marginHorizontalMedium: {
    marginHorizontal: Sizes.spacingHorizontalMedium
  },
  marginHorizontalSmall: {
    marginHorizontal: Sizes.spacingHorizontalSmall
  },
  marginHorizontalTiny: {
    marginHorizontal: Sizes.spacingHorizontalTiny
  },
  marginLeft: {
    marginLeft: Sizes.spacingHorizontal
  },
  marginLeftMedium: {
    marginLeft: Sizes.spacingHorizontalMedium
  },
  marginLeftSmall: {
    marginLeft: Sizes.spacingHorizontalSmall
  },
  marginLeftTiny: {
    marginLeft: Sizes.spacingHorizontalTiny
  },
  marginMedium: {
    marginHorizontal: Sizes.spacingHorizontalMedium,
    marginVertical: Sizes.spacingVerticalMedium
  },
  marginRight: {
    marginRight: Sizes.spacingHorizontal
  },
  marginRightMedium: {
    marginRight: Sizes.spacingHorizontalMedium
  },
  marginRightSmall: {
    marginRight: Sizes.spacingHorizontalSmall
  },
  marginRightTiny: {
    marginRight: Sizes.spacingHorizontalTiny
  },
  marginSmall: {
    marginHorizontal: Sizes.spacingHorizontalSmall,
    marginVertical: Sizes.spacingVerticalSmall
  },
  marginTop: {
    marginTop: Sizes.spacingVertical
  },
  marginTopMedium: {
    marginTop: Sizes.spacingVerticalMedium
  },
  marginTopMediumHalf: {
    marginTop: Sizes.spacingVerticalMedium / 2
  },
  marginTopSmall: {
    marginTop: Sizes.spacingVerticalSmall
  },
  marginTopTiny: {
    marginTop: Sizes.spacingVerticalTiny
  },
  marginVertical: {
    marginVertical: Sizes.spacingVertical
  },
  marginVerticalHuge: {
    marginVertical: Sizes.spacingVerticalHuge
  },
  marginVerticalMedium: {
    marginVertical: Sizes.spacingVerticalMedium
  },
  marginVerticalMediumHalf: {
    marginVertical: Sizes.spacingVerticalMedium / 2
  },
  marginVerticalNormal: {
    marginVertical: Sizes.spacingVerticalNormal
  },
  marginVerticalSmall: {
    marginVertical: Sizes.spacingVerticalSmall
  },
  marginVerticalTiny: {
    marginVertical: Sizes.spacingVerticalTiny
  },
  marginVerticalTinyHalf: {
    marginVertical: Sizes.spacingVerticalTiny / 2
  },
  noFlexGrow: {
    flexGrow: 0
  },
  noMargin: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0
  },
  padding: {
    paddingHorizontal: Sizes.spacingHorizontal,
    paddingVertical: Sizes.spacingVertical
  },
  paddingBottom: {
    paddingBottom: Sizes.spacingVertical
  },
  paddingBottomMedium: {
    paddingBottom: Sizes.spacingVerticalMedium
  },
  paddingHorizontalNormal: {
    paddingHorizontal: Sizes.spacingHorizontalNormal
  },
  paddingMedium: {
    paddingHorizontal: Sizes.spacingHorizontalMedium,
    paddingVertical: Sizes.spacingVerticalMedium
  },
  paddingNormal: {
    paddingHorizontal: Sizes.spacingHorizontalNormal,
    paddingVertical: Sizes.spacingVerticalNormal
  },
  relative: {
    position: 'relative'
  },
  row: {
    flexDirection: 'row'
  },
  rowBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rowCenter: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  rowCenterCenter: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  rowEnd: {
    alignItems: 'flex-end',
    flexDirection: 'row'
  },
  rowEndEnd: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  rowEndStart: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  rowStart: {
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
  rowWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  selfCenter: {
    alignSelf: 'center'
  },
  selfEnd: {
    alignSelf: 'flex-end'
  },
  selfStart: {
    alignSelf: 'flex-start'
  },
  textCenter: {
    textAlign: 'center'
  }
})
