import { useEffect, useRef, useState } from 'react'
import { Alert, AppState, Linking } from 'react-native'

import OneSignal from 'react-native-onesignal'
import { getBundleId } from 'react-native-device-info'
import { checkNotifications } from 'react-native-permissions'

import { requestForegroundPermissionsAsync, getForegroundPermissionsAsync } from 'expo-location'
import * as ExpoIntentLauncher from 'expo-intent-launcher'
import type { PermissionResponse } from 'expo-image-picker'
import { requestCameraPermissionsAsync, requestMediaLibraryPermissionsAsync, getMediaLibraryPermissionsAsync, getCameraPermissionsAsync } from 'expo-image-picker'

import i18n from '@/utils/i18n'
import { IS_ANDROID, IS_IOS } from '@/constants/platform'

export type TPermissions = 'camera' | 'mediaLibrary' | 'location' | 'notifications'

const usePermissions = () => {
  const [cameraPermissionStatus, setCameraPermissionStatus] = useState({ granted: false })
  const [mediaLibraryPermissionStatus, setMediaLibraryPermissionStatus] = useState({ granted: false })
  const [locationPermissionStatus, setLocationPermissionStatus] = useState({ granted: false })
  // @TODO retrieve notification permission status from OneSignal
  const [notificationPermissionStatus, setNotificationPermissionStatus] = useState({ granted: false })

  const appState = useRef(AppState.currentState)

  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        checkAllPermissions()
      }

      appState.current = nextAppState
    })

    return () => {
      subscription.remove()
    }
  }, [])

  const sendUserToSettings = () => {
    Alert.alert(
      i18n.t('Toestemming vereist'),
      i18n.t('U heeft de toestemming voor deze functionaliteit afgewezen. Ga naar de instellingen van uw toestel om deze toestemming alsnog te geven.'),
      [
        {
          text: i18n.t('Instellingen'),
          onPress: () => {
            if (IS_ANDROID) {
              ExpoIntentLauncher.startActivityAsync(ExpoIntentLauncher.ActivityAction.SETTINGS)
            } else if (IS_IOS) {
              Linking.openURL('app-settings://')
            }
          }
        },
        {
          text: i18n.t('Annuleren'),
          style: 'cancel',
        }
      ],
    )
  }

  const handleRequestResult = (result: PermissionResponse) => {
    if (!result.granted && !result.canAskAgain) {
      sendUserToSettings()
    }
  }

  const requestCameraPermissions = () => {
    requestCameraPermissionsAsync().then((result: PermissionResponse) => {
      setCameraPermissionStatus({ granted: result.status === 'granted' })
      handleRequestResult(result)
    })
  }

  const requestMediaLibraryPermissions = () => {
    requestMediaLibraryPermissionsAsync().then((result: PermissionResponse) => {
      setMediaLibraryPermissionStatus({ granted: result.status === 'granted' })
      handleRequestResult(result)
    })
  }

  const requestLocationPermissions = () => {
    requestForegroundPermissionsAsync().then((result: PermissionResponse) => {
      setLocationPermissionStatus({ granted: result.status === 'granted' })
      handleRequestResult(result)
    })
  }

  const requestNotifications = () => {
    OneSignal.promptForPushNotificationsWithUserResponse(true, (granted) => {
      setNotificationPermissionStatus({ granted })
    })
  }

  const checkCameraPermissions = () => {
    getCameraPermissionsAsync().then((result: PermissionResponse) => {
      setCameraPermissionStatus({ granted: result.status === 'granted' })
    })
  }

  const checkPhotoPermissions = () => {
    getMediaLibraryPermissionsAsync().then((result: PermissionResponse) => {
      setMediaLibraryPermissionStatus({ granted: result.status === 'granted' })
    })
  }

  const checkLocationPermissions = () => {
    getForegroundPermissionsAsync().then((result: PermissionResponse) => {
      setLocationPermissionStatus({ granted: result.status === 'granted' })
    })
  }

  const checkNotificationPermissions = () => {
    checkNotifications().then((result) => {
      setNotificationPermissionStatus({ granted: result.status === 'granted' })
    })
  }

  const checkAllPermissions = () => {
    checkCameraPermissions()
    checkPhotoPermissions()
    checkLocationPermissions()
    checkNotificationPermissions()
  }

  const requestPermission = (permission: TPermissions) => {
    switch (permission) {
      case 'camera':
        requestCameraPermissions()
        break
      case 'mediaLibrary':
        requestMediaLibraryPermissions()
        break
      case 'location':
        requestLocationPermissions()
        break
      case 'notifications':
        requestNotifications()
        break
      default:
        console.warn('[Permissions] Invalid permission type requested')
        break
    }
  }

  const revokePermission = () => {
    Alert.alert(
      i18n.t('Toestemming intrekken'),
      i18n.t('Om de toestemming in te trekken gaat u naar de instellingen van uw toestel. Wilt u doorgaan?'),
      [
        {
          text: i18n.t('Doorgaan'),
          onPress: () => {
            if (IS_ANDROID) {
              ExpoIntentLauncher.startActivityAsync('android.settings.APPLICATION_DETAILS_SETTINGS', { data: `package:${getBundleId()}` })
            } else if (IS_IOS) {
              Linking.openURL('app-settings://')
            }
          }
        },
        {
          text: i18n.t('Annuleren'),
          style: 'cancel',
        }
      ],
    )
  }

  useEffect(() => {
    checkAllPermissions()
  }, [])

  return {
    permissions: {
      camera: cameraPermissionStatus,
      mediaLibrary: mediaLibraryPermissionStatus,
      location: locationPermissionStatus,
      notifications: notificationPermissionStatus
    },

    requestPermission,
    revokePermission
  }
}

export default usePermissions
