import { Picker } from '@react-native-picker/picker'
import React, { useState } from 'react'
import { Platform, StyleSheet, TouchableOpacity, useColorScheme } from 'react-native'
import type { ColorSchemeName } from 'react-native'

import type { TInputProps } from '@/components/inputs/Input'
import Input from '@/components/inputs/Input'
import ModalPicker from '@/components/ModalPicker'
import Modal from '@/components/wrappers/Modal'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'

export interface IPickerItem<T = any> {
  key?: string
  name?: string
  label?: string
  value: T
}

interface IProps extends TInputProps {
  items: IPickerItem[]
  onChange: (value: IPickerItem['value']) => void
  value: IPickerItem['value']
  placeholder?: string
}

const InputSelect: React.FC<IProps> = (props) => {
  const colorScheme = useColorScheme()
  const currentItem = props.items?.find((item) => item.value === props.value)

  const [selectedValue, setSelectedValue] = useState<IPickerItem | null>(currentItem ?? null)
  const [modalVisible, setModalVisibility] = useState<boolean>(false)

  const keyExtractor = (item: IPickerItem): string => `picker-item-${item.key ?? item.label}-${item.value}`

  const showModal = (): void => {
    if (!props.disabled) {
      setModalVisibility(true)
      setSelectedValue(currentItem ?? null)
    }
  }

  const hideModal = (): void => {
    setModalVisibility(false)
    setSelectedValue(currentItem ?? null)
  }

  const onSubmit = (item = selectedValue ?? props.items[0]): void => {
    props.onChange(item?.value)
    setSelectedValue(item)
    hideModal()
  }

  const setItem = (value: number | string): void => {
    setSelectedValue(props.items.find((item) => item.value === value) ?? null)
  }

  const styles = style(colorScheme)

  return (
    <>
      <Input
        editable={false}
        iconRight={['fal', 'chevron-down']}
        onPress={showModal}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        placeholder={props.placeholder}
        value={currentItem?.label ?? currentItem?.name}
      />

      {Platform.OS === 'android' || Platform.OS === 'web'
        ? (
          <Modal
            noPadding
            showClose
            useScrollView
            onHide={hideModal}
            style={styles.modal}
            visible={modalVisible}
          >
            {props.items?.map((item) => (
              <TouchableOpacity
                key={keyExtractor(item)}
                onPress={() => onSubmit(item)}
                style={styles.touchableItem}
              >
                <Text type='picker'>{item.label || item.name}</Text>
              </TouchableOpacity>
            ))}
          </Modal>
          )
        : null}

      {Platform.OS === 'ios'
        ? (
          <ModalPicker
            onHide={hideModal}
            onSubmit={() => onSubmit()}
            visible={modalVisible}
          >
            <Picker
              itemStyle={styles.pickerItem}
              onValueChange={setItem}
              selectedValue={selectedValue?.value}
            >
              {props.items?.map((item) => (
                <Picker.Item
                  key={keyExtractor(item)}
                  label={item.label || item.name!}
                  value={item.value}
                />
              ))}
            </Picker>
          </ModalPicker>
          )
        : null}
    </>
  )
}

const style = (colorScheme: ColorSchemeName) => StyleSheet.create({
  modal: {
    paddingVertical: Sizes.spacingVerticalMedium / 2
  },
  pickerItem: {
    color: colorScheme === 'dark' ? Colors.white : Colors.black
  },
  touchableItem: {
    paddingHorizontal: Sizes.spacingHorizontal,
    paddingVertical: Sizes.spacingVerticalMedium / 2
  }
})

export default InputSelect
