import moment from 'moment'
import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Line from '@/components/Line'
import Modal from '@/components/wrappers/Modal'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import { closeModalLogs } from '@/redux/actions/modals'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import logger from '@/utils/logger'

const DATE_FORMAT = 'HH:mm:ss'

interface TModalLogsProps {
  closeModalLogs: typeof closeModalLogs
  modals: any
}

function renderItem ({ item, index: indexItem }: any) {
  return item ? (
    <>
      {indexItem !== 0
        ? <Line
            horizontal
            color={Colors.black}
            style={Styling.marginVertical}
          />
        : null}
      <View style={Styling.rowBetween}>
        <Text
          style={Styling.flex}
          type='span'
        >
          {item.type}
        </Text>
        <Text
          style={Styling.marginLeft}
          type='span'
        >
          {moment(item.timestamp).format(DATE_FORMAT)}
        </Text>
      </View>
      {(item.parameters || []).map((parameter: any, indexParameter: any) => (
        // eslint-disable-next-line react/no-array-index-key
        <Text
          key={`parameter-${indexParameter}`}
          type='paragraph'
        >
          {JSON.stringify(parameter)}
        </Text>
      ))}
    </>
  ) : null
}

function keyExtractor (item: any) {
  return item && item.id
}

function ModalLogs (props: TModalLogsProps) {
  const visible = !!(props.modals && props.modals.logs)
  const items = (logger.getHistory() || []).filter((item) => !!item)
  return (
    <Modal
      showClose
      useScrollView
      onHide={props.closeModalLogs}
      visible={visible}
    >
      <Bootstrap.Collection
        white
        data={items}
        keyExtractor={keyExtractor}
        numColumn={1}
        renderItem={renderItem}
      />
    </Modal>
  )
}

const mapStateToProps = (state: TState) => ({
  modals: selectors.getModals(state)
})

export default connect(mapStateToProps, { closeModalLogs })(ModalLogs)
