import * as CONSTANTS from '@/redux/constants/claims'

import type * as TYPES from '@/redux/types/claims'

import type { File } from '@/components/inputs/InputFile'
import type { Claim, Policy } from '@/types/objects'

export const startClaimDamageForm = (policyId?: Policy['id']): TYPES.StartClaimDamageFormAction => ({
  policyId,
  type: CONSTANTS.START_CLAIM_DAMAGE_FORM
})

export const continueClaimDamageConcept = (claim: Claim) => ({
  payload: { claim },
  type: CONSTANTS.CONTINUE_CLAIM_DAMAGE_CONCEPT
})

export const uploadAdditionalClaimAttachment = (id: string, file: File) => ({
  type: CONSTANTS.UPLOAD_ADDITIONAL_CLAIM_ATTACHMENT,
  payload: { id, file }
})

export const uploadedAdditionalClaimAttachment = (title: string, url: string) => ({
  type: CONSTANTS.UPLOADED_ADDITIONAL_CLAIM_ATTACHMENT,
  payload: { title, url }
})

export const deleteAdditionalClaimAttachment = (title: string, url: string) => ({
  type: CONSTANTS.DELETE_ADDITIONAL_CLAIM_ATTACHMENT,
  payload: { title, url }
})

export const deletedAdditionalClaimAttachment = (title: string) => ({
  type: CONSTANTS.DELETED_ADDITIONAL_CLAIM_ATTACHMENT,
  payload: { title }
})

export const serviceUnavailable = (errorMessage: string) => ({
  type: CONSTANTS.SERVICE_UNAVAILABLE,
  payload: { data: errorMessage }
})

export const deleteAdditionalClaimAttachmentFailed = (title: string) => ({
  type: CONSTANTS.DELETE_ADDITIONAL_CLAIM_ATTACHMENT_FAILED,
  payload: { title }
})

export const getClaimSuccess = (claimData: Claim) => ({
  type: CONSTANTS.GET_CLAIM_SUCCESS,
  payload: { data: claimData },
})

export const showUnprocessableContentError = () => ({
  type: CONSTANTS.SHOW_UNPROCESSABLE_CONTENT_ERROR,
  payload: { },
})

export const hideUnprocessableContentError = () => ({
  type: CONSTANTS.HIDE_UNPROCESSABLE_CONTENT_ERROR,
  payload: { },
})

export type ClaimDamageDispatch =
  ReturnType<typeof continueClaimDamageConcept> &
  ReturnType<typeof uploadAdditionalClaimAttachment> &
  ReturnType<typeof uploadedAdditionalClaimAttachment>
