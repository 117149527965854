import { createSelector } from 'reselect'

import type { State as TState } from '@/redux/reducers'
import type { User } from '@/types/objects'
import { isAfter } from 'date-fns'
import date from '@/utils/date'

export const getPincode = (state: TState) => state.user?.pincode
export const getUser = (state: TState): User => state.user
export const getEmail = (state: TState): User['email'] => state.user?.email
export const getUserId = (state: TState) => state.user?.id

export const isLoggedIn = createSelector(getUser, (user) => (
  user?.id !== '' &&
  user?.email !== '' &&
  user?.password !== ''
))

export const isVerified = createSelector([getUser, isLoggedIn], (user, loggedIn) => (
  loggedIn &&
  isAfter(new Date(), date.dutchDateToJSDate(user?.tokenExpiresAt))
))
