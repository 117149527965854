import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Action from '@/components/Action'
import Block from '@/components/Block'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import { openPolicyCondition, openPolicyInsuranceCard as openPolicyInsuranceCardAction } from '@/redux/actions/files'
import navigation from '@/utils/navigation'

import Actions from '../../../components/Actions'
import Button from '../../../components/buttons/Button'
import Line from '../../../components/Line'
import Colors from '../../../constants/Colors'
import type { GeneratedGreenCard } from '../../../types/objects'
import ClausesTablet from './components/clauses.tablet'
import ConditionsTablet from './components/conditions.tablet'
import CoveragesTablet from './components/coverages.tablet'
import DetailsTablet from './components/details.tablet'
import HeaderContent from './components/header_content'
import InsuranceCardsTablet from './components/insurance_cards.tablet'
import type { IChildComponentProps } from './Policy'
import { getIsPolicyPullable } from '@/redux/selectors/policies'

const PolicyTablet: React.FC<IChildComponentProps> = (props) => {
  const dispatch = useDispatch()

  const hasGreenCard = !!props.policy?.greenCard
  const singleInsuranceCard = props.policy?.insuranceCards?.length === 1
  const singleCondition = props.policy?.conditions?.length === 1
  const isPullable = getIsPolicyPullable(props.policy)

  const openScreenGreenCard = () => {
    navigation.openScreenGreenCard(props.policy)
  }

  const openPolicyInsuranceCard = () => {
    dispatch(openPolicyInsuranceCardAction(props.policy, props.policy.insuranceCards[0]))
  }

  const openScreenPolicyCondition = () => {
    dispatch(openPolicyCondition(props.policy, props.policy.conditions[0]))
  }

  const openScreenGenerateGreenCard = async () => await navigation.openScreenGenerateGreenCard(props.policy)
  const openScreenGeneratedGreenCard = async (generatedGreenCard: GeneratedGreenCard) => {
    await navigation.openScreenGeneratedGreenCard(generatedGreenCard)
  }

  return (
    <Screen
      useRefreshControl
      refreshing={props.refreshing}
      onRefresh={props.onRefresh}
    >
      <Block noFlex>
        <HeaderContent
          policy={props.policy}
          relation={props.relation}
        />

        <View style={styles.actionsContainer}>
          {singleInsuranceCard
            ? (
              <Action
                label='Open de verzekeringskaart'
                onPress={openPolicyInsuranceCard}
                style={Styling.marginRightMedium}
                type='down'
              />
              )
            : null}

          {singleCondition
            ? (
              <Action
                label='Bekijk de polisvoorwaarden'
                onPress={openScreenPolicyCondition}
                style={Styling.marginRightMedium}
                type='down'
              />
              )
            : null}
        </View>

        {hasGreenCard || isPullable || props.policy?.greenCards?.length > 0
          ? (
            <>
              <Line
                horizontal
                color={Colors.background}
                height={1}
                style={Styling.marginVerticalMedium}
              />

              <Text
                style={Styling.marginBottomNormal}
                type='subtitle'
              >
                Verzekeringsbewijs
              </Text>

              {hasGreenCard && (
                <Actions
                  labelDown='Open het Verzekeringsbewijs'
                  onPressDown={openScreenGreenCard}
                />)}

              {isPullable && (
                <Actions
                  labelRight='Verzekeringsbewijs genereren'
                  onPressRight={openScreenGenerateGreenCard}
                  style={Styling.marginBottom}
                />
              )}

              {props.policy.greenCards.map(greenCard => (
                <Bootstrap.Row>
                  <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 6, xs: 12, xxs: 12 }}>
                    <Button
                      onPress={async () => await openScreenGeneratedGreenCard(greenCard)}
                      title={`Verzekeringsbewijs - ${greenCard.pullingVehicleLicensePlate}`}
                    />
                  </Bootstrap.Column>
                </Bootstrap.Row>
              ))}
            </>
            )
          : null}

        <DetailsTablet policy={props.policy} />

        <CoveragesTablet policy={props.policy} />

        <ClausesTablet policy={props.policy} />

        <InsuranceCardsTablet policy={props.policy} />

        <ConditionsTablet policy={props.policy} />
      </Block>
    </Screen>
  )
}

const styles = StyleSheet.create({
  actionsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: Sizes.spacingVerticalMedium
  }
})

export default PolicyTablet
