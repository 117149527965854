import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import * as CLAIM_CONSTANTS from '../constants/claims'
import * as LOADING_CONSTANTS from '@/redux/constants/loading'

import { updateFormClaimDamageDynamic } from '@/redux/actions/forms'
import * as CLAIM_ACTIONS from '@/redux/actions/claims'
import * as LOADING_ACTIONS from '@/redux/actions/loading'

import * as api from '@/redux/api/claims'
import * as myAppClaimsApi from '@/redux/api/my_app_claims'

import toast from '@/utils/toast'
import type { IApiResponse } from '@/utils/api_wrapper'
import type { Claim, ClaimQuestion } from '@/types/objects'

const UNKNOWN_ERROR_MESSAGE = 'Bestand kon niet worden geupload. Probeer later opnieuw.'

function * uploadClaimAttachment (dispatch: { type: string, question: ClaimQuestion, file: File & { base64: string, fileUuid: string, mimeType: string | null }, claimDamageId: Claim['claimDamageId'] }) {
  try {
    if (dispatch?.question) {
      yield put(LOADING_ACTIONS.setLoadingClaimAttachment(dispatch.question.id, true))

      const result: IApiResponse<unknown> = yield call(
        api.uploadClaimAttachment,
        dispatch.question,
        dispatch.claimDamageId,
        dispatch.file.base64,
        dispatch.file.name,
        dispatch.file.fileUuid,
        dispatch.file.mimeType
      )

      if (!result?.success) {
        const message = result?.message ?? UNKNOWN_ERROR_MESSAGE
        toast(message)
        return
      }

      // Do not store base64 string in redux store, as this could be way to heavy
      yield put(updateFormClaimDamageDynamic(dispatch.question?.id, { ...dispatch.file, base64: true }))
      yield put(LOADING_ACTIONS.setLoadingClaimAttachment(dispatch.question?.id, false))
    }
  } catch (error) {
    toast(UNKNOWN_ERROR_MESSAGE)

    // eslint-disable-next-line no-console
    console.warn(error)
    yield put(LOADING_ACTIONS.setLoadingClaimAttachment(dispatch.question?.id, false))
  }
}

// This one should actually call Betty to remove attachment
export function * removeClaimAttachment (dispatch: { type: string, payload: { question: ClaimQuestion } }) {
  yield put(updateFormClaimDamageDynamic(dispatch.question?.id, null))
}

function * uploadAdditionalClaimAttachment ({ payload }: ReturnType<typeof CLAIM_ACTIONS.uploadAdditionalClaimAttachment>) {
  try {
    const result: IApiResponse<{
      extension: string
      title: string
      size: string
      url: string
    }> = yield call(myAppClaimsApi.uploadAdditionalClaimAttachment, payload.id, payload.file)

    toast(result.message!)

    if (result.statusCode === 422) {
      toast('Bestandstype niet ondersteund')
      yield put(CLAIM_ACTIONS.showUnprocessableContentError())
      yield put(CLAIM_ACTIONS.deletedAdditionalClaimAttachment(payload.file.name))
    }

    if (result.success) {
      yield put(CLAIM_ACTIONS.hideUnprocessableContentError())
      yield put(CLAIM_ACTIONS.uploadedAdditionalClaimAttachment(`${result.data[0].title}.${result.data[0].extension}`, result.data[0].url))
    }
  } catch (err) {
    console.warn(err)
  }
}

function * deleteAdditionalClaimAttachment ({ payload }: ReturnType<typeof CLAIM_ACTIONS.deleteAdditionalClaimAttachment>) {
  try {
    const result: IApiResponse<unknown> = yield call(
      myAppClaimsApi.removeAdditionalClaimAttachment,
      payload.url
    )

    toast(result.message!)

    if (result.success) {
      yield put(CLAIM_ACTIONS.deletedAdditionalClaimAttachment(payload.title))
    } else {
      throw new Error(result.message)
    }
  } catch (err) {
    console.warn(err)
    yield put(CLAIM_ACTIONS.deleteAdditionalClaimAttachmentFailed(payload.title))
  }
}

export function * claimsSaga () {
  yield takeLatest(LOADING_CONSTANTS.REMOVE_CLAIM_ATTACHMENT, removeClaimAttachment)
  yield takeLatest(LOADING_CONSTANTS.UPLOAD_CLAIM_ATTACHMENT, uploadClaimAttachment)

  yield takeEvery(CLAIM_CONSTANTS.UPLOAD_ADDITIONAL_CLAIM_ATTACHMENT, uploadAdditionalClaimAttachment)
  yield takeEvery(CLAIM_CONSTANTS.DELETE_ADDITIONAL_CLAIM_ATTACHMENT, deleteAdditionalClaimAttachment)
}
