import { createSelector } from 'reselect'

import type { State as TState } from '@/redux/reducers'
import type { ClaimQuestion, ClaimQuestionLinked, ClaimQuestionOption, FormClaimDamageResult } from '@/types/objects'
import { getClaims } from './claims'

export const getFormClaimDamage = (state: TState) => state.forms?.claimDamage
export const getFormClaimDamageId = (state: TState) => state.forms?.claimDamage?.id
export const getFormClaimId = (state: TState) => state.forms?.claimDamage.answersStatic.id
export const getShowUnprocessableContentError = (state: TState) => state.forms?.claimDamage.showUnproccessableContentError

export const getClaimFromDamageForm = createSelector([getClaims, getFormClaimDamageId], (claims, id) =>
  claims?.find((item) => item?.claimDamageId === id)
)

export const getFormClaimDamageResult = createSelector(getFormClaimDamage, (formClaimDamage): FormClaimDamageResult => {
  const productClaimAttributes: Record<string, any> = {}
  const claimAttributesOptions: Record<string, string[]> = {}

  // eslint-disable-next-line array-callback-return
  Object.entries(formClaimDamage?.answersDynamic || {}).forEach(([key, value]) => {
    const question = formClaimDamage?.questions?.find((x) => x?.id?.toString() === key?.toString())

    if (question?.productClaimTypeAttributeKind?.name === 'multi-checkbox') {
      if (Array.isArray(value)) {
        productClaimAttributes[key] = 'Ja'

        claimAttributesOptions[key] = value
          .map((x) => (question.options.find((option) => option.value === x) || {}).name)
          .filter(Boolean) as string[]
      } else {
        productClaimAttributes[key] = 'Nee'
      }
    } else if (value || !question?.required) {
      productClaimAttributes[key] = value ?? question?.defaultValue
    }
  })
  return {
    claimAttributesOptions,
    productClaimAttributes
  }
})

interface ClaimQuestionsDict { [key: number]: ClaimQuestion }

export const getFormClaimDamageQuestions = createSelector(getFormClaimDamage, (formClaimDamage) => {
  const questionsDict: ClaimQuestionsDict = (formClaimDamage?.questions || [])?.reduce(
    (previous: ClaimQuestionsDict, current: ClaimQuestion) => {
      // eslint-disable-next-line no-param-reassign
      previous[current.id] = current
      return previous
    },
    {}
  )
  function determineQuestions (question: ClaimQuestion, recursionLevel = 0) {
    let questions: ClaimQuestion[] = []
    if (!question || (question.parentQuestions && question.parentQuestions.length && !recursionLevel)) {
      return questions
    }
    questions.push(question);
    (question.options || []).forEach((option: ClaimQuestionOption) => {
      if (option.value === formClaimDamage?.answersDynamic[question.id]) {
        (option.linkedQuestions || []).forEach((linkedQuestion: ClaimQuestionLinked) => {
          if (questionsDict[linkedQuestion.id]) {
            const recursionQuestions = determineQuestions(questionsDict[linkedQuestion.id], recursionLevel + 1)
            questions = questions.concat(recursionQuestions)
          }
        })
      }
    })
    return questions
  }
  return (formClaimDamage?.questions || [])?.reduce(
    (previous: ClaimQuestion[], current: ClaimQuestion) => previous.concat(determineQuestions(current)),
    []
  )
})

export const getFormClaimDamageEmptyQuestions = createSelector(
  [getFormClaimDamage, getFormClaimDamageQuestions],
  (form, questions) =>
    questions.filter(
      (question) =>
        question.required &&
        (form?.answersDynamic?.[question.id] === undefined || form?.answersDynamic?.[question.id] === '')
    )
)

export const getFormClaimDamageAdditionalFiles = createSelector([getFormClaimDamage], (form) => form?.additionalFiles ?? {})
