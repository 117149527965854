import React from 'react'
import { connect } from 'react-redux'
import IBAN from 'iban'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import ButtonForm from '@/components/buttons/ButtonForm'
import Form from '@/components/Form'
import Input from '@/components/inputs/Input'
import InputDateTime from '@/components/inputs/InputDateTime'
import ListUnordered from '@/components/ListUnordered'
import Screen from '@/components/Screen'
import Toast from '@/components/Toast'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'

import withAnimFade from '@/hocs/withAnimFade'
import type { TApiCallProps } from '@/hocs/withApiCall'
import withApiCall from '@/hocs/withApiCall'

import { updateRelation } from '@/redux/actions/relations'
import * as relationApi from '@/redux/api/relations'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'

import type { Relation, User } from '@/types/objects'

import alert from '@/utils/alert'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import toast from '@/utils/toast'

type TManageRelationProps = TApiCallProps & {
  relation: Relation
  relationId: Relation['id']
  updateRelation: typeof updateRelation
  userId: User['id']
}

interface TManageRelationState {
  accountNumber?: string
  city?: string
  dateOfBirth?: string
  houseNumber?: string
  loading: boolean
  missingInputs: string[]
  phoneNumber?: string
  showError?: boolean
  showApiValidationError?: boolean
  street?: string
  zipCode?: string
  houseNumberAddition?: string
  ibanValid?: boolean | null
}

const ListUnorderedAnimFade = withAnimFade(ListUnordered)

const inputLabels = {
  accountNumber: i18n.t('IBAN'),
  city: i18n.t('Plaats'),
  dateOfBirth: i18n.t('Geboortedatum'),
  houseNumber: i18n.t('Huisnummer'),
  phoneNumber: i18n.t('Telefoonnummer'),
  street: i18n.t('Straat'),
  zipCode: i18n.t('Postcode'),
  houseNumberAddition: i18n.t('Huisnummer toevoeging')
}

class ManageRelation extends React.Component<TManageRelationProps, TManageRelationState> {
  constructor (props: TManageRelationProps) {
    super(props)

    const relation = props.relation || {}
    const mutation = relation.mutation || {}

    this.state = {
      accountNumber: mutation.accountNumber || relation.accountNumber,
      city: mutation.city || relation.city,
      dateOfBirth: mutation.dateOfBirth || relation.dateOfBirth,
      houseNumber: mutation.houseNumber || relation.houseNumber,
      loading: false,
      missingInputs: [],
      phoneNumber: mutation.phoneNumber || relation.phoneNumber,
      showError: false,
      street: mutation.street || relation.street,
      zipCode: mutation.zipCode || relation.zipCode,
      houseNumberAddition: mutation.houseNumberAddition || relation.houseNumberAddition,
      ibanValid: (mutation.accountNumber || relation.accountNumber) === ''
        ? true
        : IBAN.isValid(mutation.accountNumber || relation.accountNumber)
    }
  }

  onChange = (updates: Partial<TManageRelationState>) => {
    this.setState(updates, () => {
      const missingInputs = this.determineMissingInputs()
      this.setState({ missingInputs })
    })
  }

  determineMissingInputs = () => [
    !this.state.dateOfBirth ? inputLabels.dateOfBirth : '',
    !this.state.city ? inputLabels.city : '',
    !this.state.zipCode ? inputLabels.zipCode : '',
    !this.state.street ? inputLabels.street : '',
    !this.state.houseNumber ? inputLabels.houseNumber : '',
    !this.state.ibanValid ? inputLabels.accountNumber : ''
  ].filter(Boolean)

  formatZipCode = (zipCode: string): string => {
    if (zipCode.length === 6) {
      return `${zipCode.slice(0, 4)} ${zipCode.slice(4, 6)}`
    }
    return zipCode
  }

  formatPhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/\D/g, '')
  }

  validZipCode = (): boolean => {
    if (!this.state.zipCode) {
      return false
    }
    return /^\d{4}\s?[a-zA-Z]{2}$/.test(this.state.zipCode)
  }

  onPressSubmit = async () => {
    const missingInputs = this.determineMissingInputs()

    if (missingInputs.length > 0) {
      this.setState({
        missingInputs,
        showError: true
      })
    } else if (!this.state.loading) {
      this.setState({ loading: true, showError: false })

      const updates = {
        accountNumber: this.state.accountNumber,
        city: this.state.city,
        dateOfBirth: this.state.dateOfBirth,
        houseNumber: this.state.houseNumber,
        houseNumberAddition: this.state.houseNumberAddition,
        street: this.state.street,
        phoneNumber: this.formatPhoneNumber(this.state.phoneNumber ?? ''),
        zipCode: this.formatZipCode(this.state.zipCode!),
      }

      const result = await this.props.apiCallRaw(
        relationApi.updateRelation,
        this.props.relationId,
        this.props.relation,
        updates
      )

      if (result.data) {
        this.props.updateRelation(result.data)

        alert.showSingle(
          i18n.t('Uw wijziging is ingediend'),
          i18n.t(
            'De wijziging wordt zo spoedig mogelijk door uw adviseur in behandeling genomen. Zodra de wijziging is verwerkt worden de gewijzigde gegevens zichtbaar in de app.'
          ),
          {
            onPress: async () => await navigation.openScreenProfile(),
            text: i18n.t('Oke')
          }
        )
      } else {
        toast(result.message ?? i18n.t('De gegevens konden niet worden gewijzigd'))
        this.setState({ showApiValidationError: true })
      }

      this.setState({ loading: false })
    }
  }

  validateIban = () => {
    const ibanValid =
      (
        this.state.accountNumber === null ||
        this.state.accountNumber === undefined ||
        this.state.accountNumber === ''
      ) ||
      IBAN.isValid(this.state.accountNumber ?? '')

    this.setState({ ibanValid })
  }

  renderContent = () => {
    const hasPendingMutations = this.props.relation?.hasPendingMutations
    const disabled =
      this.state.loading ||
      hasPendingMutations ||
      !this.validZipCode() ||
      this.determineMissingInputs().length > 0

    return (
      <>
        <Form>
          {hasPendingMutations
            ? (
              <Text
                style={Styling.marginBottom}
                type='labelError'
              >
                {i18n.t('De vorige wijzigingen moeten nog worden geaccepteerd door uw adviseur')}
              </Text>
              )
            : null}

          <Input
            disabled={hasPendingMutations}
            keyboardType='phone-pad'
            label={`${inputLabels.phoneNumber}:`}
            onChange={(phoneNumber) => this.onChange({ phoneNumber })}
            maxLength={15}
            showMaxLength={false}
            value={this.state.phoneNumber}
          />

          <Input
            disabled={hasPendingMutations}
            label={`${inputLabels.accountNumber}:`}
            onChange={(accountNumber) => this.onChange({ accountNumber })}
            onBlur={this.validateIban}
            value={this.state.accountNumber}
            autoCapitalize='characters'
            error={!this.state.ibanValid}
            autoCompleteType='off'
          />

          <InputDateTime
            disabled={hasPendingMutations}
            label={`${inputLabels.dateOfBirth}:`}
            maxDate={new Date()}
            mode='date'
            onChange={(dateOfBirth) => this.onChange({ dateOfBirth })}
            value={this.state.dateOfBirth}
          />

          <Input
            disabled={hasPendingMutations}
            label={`${inputLabels.city}:`}
            onChange={(city) => this.onChange({ city })}
            value={this.state.city}
            autoCapitalize='characters'
          />

          <Input
            disabled={hasPendingMutations}
            label={`${inputLabels.zipCode}:`}
            onChange={(zipCode) => this.onChange({ zipCode })}
            value={this.state.zipCode}
            autoCapitalize='characters'
            placeholder='1234AB'
            error={!this.validZipCode()}
          />

          <Input
            disabled={hasPendingMutations}
            label={`${inputLabels.street}:`}
            onChange={(street) => this.onChange({ street })}
            value={this.state.street}
            autoCapitalize='words'
          />

          <Input
            disabled={hasPendingMutations}
            label={`${inputLabels.houseNumber}:`}
            onChange={(houseNumber) => this.onChange({ houseNumber })}
            value={this.state.houseNumber}
          />

          <Input
            disabled={hasPendingMutations}
            label={`${inputLabels.houseNumberAddition}:`}
            onChange={(houseNumberAddition) => this.onChange({ houseNumberAddition })}
            value={this.state.houseNumberAddition}
          />

          {this.state.showError && this.state.missingInputs.length > 0
            ? (
              <>
                <Toast
                  error
                  message='De volgende verplichte velden zijn nog niet ingevuld:'
                  style={Styling.marginBottomSmall}
                />

                <ListUnorderedAnimFade
                  items={this.state.missingInputs}
                  prefix='•'
                  style={Styling.marginBottom}
                  type='labelError'
                />
              </>
              )
            : null}

          {this.state.showApiValidationError && (
            <Toast
              error
              message={i18n.t('U kunt gegevens momenteel niet wijzigen, i.v.m. een openstaande wijziging. Neem contact op met uw adviseur.')}
              style={Styling.marginBottomSmall}
            />
          )}

          <Text
            style={Styling.marginBottomSmall}
            type='paragraph'
          >
            {i18n.t(
              'Opmerking: uw gegevens worden niet direct gewijzigd. Deze worden eerst gecontroleerd door uw adviseur.'
            )}
          </Text>

          <ButtonForm
            color={Colors.primary}
            disabled={disabled}
            loading={this.state.loading}
            onPress={this.onPressSubmit}
            title={i18n.t('Wijziging doorgeven')}
          />
        </Form>
      </>
    )
  }

  renderPhone = () => <Screen white>{this.renderContent()}</Screen>

  renderTablet = () => (
    <Screen>
      <Block>
        <Bootstrap.Row>
          <Bootstrap.Column span={{ lg: 6, md: 9, sm: 12, xl: 4, xs: 12 }}>{this.renderContent()}</Bootstrap.Column>
        </Bootstrap.Row>
      </Block>
    </Screen>
  )

  render () {
    return (
      <Bootstrap.Switch
        renderPhone={this.renderPhone}
        renderTablet={this.renderTablet}
      />
    )
  }
}

const mapStateToProps = (state: TState) => ({
  relation: selectors.getCurrentRelation(state)!,
  relationId: selectors.getCurrentRelationId(state)!,
  userId: selectors.getUserId(state)
})

export default connect(mapStateToProps, { updateRelation })(withApiCall(ManageRelation))
