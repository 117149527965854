import React from 'react'
import type { GestureResponderEvent, StyleProp, ViewStyle } from 'react-native'
import { ActivityIndicator, TouchableOpacity } from 'react-native'

import Sizes from '@/constants/Sizes'
import Colors from '@/constants/Colors'

interface TWithTouchableProps {
  onPress?: (event: GestureResponderEvent) => void
  touchableStyle?: StyleProp<ViewStyle>
  loading?: boolean
  disabled?: boolean
}

export default function withTouchable<P extends object> (Component: React.ComponentType<P>) {
  function WithTouchable ({ onPress, touchableStyle, ...props }: P & TWithTouchableProps) {
    return (
      <TouchableOpacity
        hitSlop={Sizes.hitSlop}
        onPress={onPress}
        style={touchableStyle}
        disabled={props.disabled}
      >
        {props.loading
          ? <ActivityIndicator color={Colors.primary} />
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          : <Component {...(props as P)} />}
      </TouchableOpacity>
    )
  }
  return WithTouchable
}
