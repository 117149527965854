import React from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'

import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import { IS_WEB } from '@/constants/platform'

import { lock, logout } from '@/redux/actions/user'
import type { State as TState } from '@/redux/reducers'
import type { ThemeColors } from '@/redux/reducers/theme'
import selectors from '@/redux/selectors'

import type { Relation, User } from '@/types/objects'

import alert from '@/utils/alert'
import common from '@/utils/common'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

interface TProfileProps {
  colors: ThemeColors
  lock: typeof lock
  logout: typeof logout
  relation: Relation
  user: User
}

class Profile extends React.Component<TProfileProps> {
  logout = () => {
    const title = i18n.t('Uitloggen')
    const message = i18n.t('Weet u zeker dat u wilt uitloggen?')

    alert.showDouble(
      title,
      message,
      {
        style: 'cancel',
        text: i18n.t('Nee')
      },
      {
        onPress: this.props.logout,
        text: i18n.t('Ja')
      }
    )
  }

  openScreenEditUser = async () => {
    return await navigation.openScreenEditUser(this.props.user)
  }

  renderLogoutButtons = () => {
    if (IS_WEB) {
      return (
        <Button
          color={Colors.white}
          icon='user-times'
          iconCircleColor={Colors.lightRed}
          iconColor={Colors.red}
          onPress={this.logout}
          title={i18n.t('Uitloggen')}
          titleColor={Colors.gray}
        />
      )
    }

    return (
      <>
        <Button
          color={Colors.white}
          icon='arrow-right'
          iconCircleColor={this.props.colors?.lightBrand}
          iconColor={this.props.colors?.brand}
          onPress={this.props.lock}
          style={Styling.marginBottom}
          title={i18n.t('Vergrendelen')}
          titleColor={Colors.gray}
        />

        <Button
          color={Colors.white}
          icon='user-times'
          iconCircleColor={Colors.lightRed}
          iconColor={Colors.red}
          onPress={this.logout}
          title={i18n.t('Uitloggen')}
          titleColor={Colors.gray}
        />
      </>
    )
  }

  render () {
    const nameUser = common.determineName(this.props.user)
    const mainUser = this.props.user?.mainUser

    return (
      <Screen>
        <Bootstrap.Column
          span={{ lg: 6, md: 8, sm: 12, xl: 4, xs: 12 }}
          style={Styling.autoHorizontal}
        >
          <View style={styles.textContainer}>
            {nameUser && (
              <Text
                color={this.props.colors?.brand}
                type='section'
              >
                {nameUser}
              </Text>
            )}

            {this.props.relation?.combiNumber && (
              <Text type='paragraph'>
                {' '}
                {i18n.t('Combinummer')}: {this.props.relation.combiNumber}
              </Text>
            )}

            <Text type='paragraph'>{mainUser ? i18n.t('Hoofdgebruiker') : i18n.t('Subgebruiker')}</Text>
          </View>

          <Button
            color={Colors.white}
            icon='user-lock'
            iconCircleColor={this.props.colors?.lightBrand}
            iconColor={this.props.colors?.brand}
            onPress={navigation.openScreenManageLogin}
            style={Styling.marginBottom}
            title={i18n.t('Inloggegevens wijzigen')}
            titleColor={Colors.gray}
          />

          <Button
            color={Colors.white}
            icon='address-card'
            iconCircleColor={this.props.colors?.lightBrand}
            iconColor={this.props.colors?.brand}
            onPress={mainUser ? navigation.openScreenManageRelation : this.openScreenEditUser}
            style={Styling.marginBottom}
            title={i18n.t('Gegevens wijzigen')}
            titleColor={Colors.gray}
          />

          {!IS_WEB && (
            <Button
              color={Colors.white}
              icon='fingerprint'
              iconCircleColor={this.props.colors?.lightBrand}
              iconColor={this.props.colors?.brand}
              onPress={navigation.openScreenManageVerify}
              style={Styling.marginBottom}
              title={i18n.t('Inlogmethode wijzigen')}
              titleColor={Colors.gray}
            />
          )}

          <Button
            color={Colors.white}
            icon='cog'
            iconCircleColor={this.props.colors?.lightBrand}
            iconColor={this.props.colors?.brand}
            onPress={navigation.openScreenSettings}
            style={Styling.marginBottom}
            title={i18n.t('Voorkeuren')}
            titleColor={Colors.gray}
          />

          {mainUser && (
            <Button
              color={Colors.white}
              icon='user-plus'
              iconCircleColor={this.props.colors?.lightBrand}
              iconColor={this.props.colors?.brand}
              onPress={navigation.openScreenManageUsers}
              style={Styling.marginBottom}
              title={i18n.t('Gebruikers beheren')}
              titleColor={Colors.gray}
            />
          )}

          <Button
            color={Colors.white}
            icon='file-alt'
            iconCircleColor={this.props.colors?.lightBrand}
            iconColor={this.props.colors?.brand}
            onPress={navigation.openScreenSwitchCombiPolicy}
            style={Styling.marginBottom}
            title={i18n.t('Selecteer combipolis')}
            titleColor={Colors.gray}
          />

          <Button
            color={Colors.white}
            icon='comment-alt-check'
            iconCircleColor={this.props.colors?.lightBrand}
            iconColor={this.props.colors?.brand}
            onPress={navigation.openScreenManagePermissions}
            style={Styling.marginBottom}
            title={i18n.t('Toestemmingen')}
            titleColor={Colors.gray}
          />

          {this.renderLogoutButtons()}
        </Bootstrap.Column>
      </Screen>
    )
  }
}

const styles = StyleSheet.create({
  textContainer: {
    alignItems: 'center',
    marginBottom: Sizes.spacingVerticalMedium
  }
})

const mapStateToProps = (state: TState) => ({
  colors: selectors.getThemeColors(state),
  relation: selectors.getCurrentRelation(state)!,
  user: selectors.getUser(state)
})

const mapDispatchToProps = {
  lock,
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
