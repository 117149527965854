import React from 'react'
import { StyleSheet, View } from 'react-native'

import { useSelector } from 'react-redux'
import Icon from '@/components/Icon'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import { Marker } from '@/components/wrappers/MapView'
import { getThemeColors } from '@/redux/selectors/theme'

interface IProps {
  coordinate: {
    latitude: number
    longitude: number
    latitudeDelta: number
    longitudeDelta: number
  }
}

const MarkerAgency: React.FC<IProps> = ({ coordinate }) => {
  const colors = useSelector(getThemeColors)

  return (
    <Marker coordinate={coordinate}>
      <View style={[styles.marker, { backgroundColor: colors?.brand }]}>
        <Icon
          color={Colors.white}
          icon='user-tie'
        />
      </View>
    </Marker>
  )
}

const styles = StyleSheet.create({
  marker: {
    alignItems: 'center',
    borderColor: Colors.white,
    borderRadius: Sizes.marker / 2,
    borderWidth: 2,
    height: Sizes.marker,
    justifyContent: 'center',
    overflow: 'hidden',
    width: Sizes.marker
  }
})

export default MarkerAgency
