/* eslint-disable react/jsx-closing-tag-location */
import React from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import ContactInfo from '@/components/ContactInfo'
import Icon from '@/components/Icon'
import InputSelect from '@/components/inputs/InputSelect'
import Html from '@/components/wrappers/HTML'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Settings from '@/constants/Settings'
import Styling from '@/constants/Styling'
import { openModalEmergencyNumber } from '@/redux/actions/modals'
import selectors from '@/redux/selectors'
import type { Policy } from '@/types/objects'
import common from '@/utils/common'
import i18n from '@/utils/i18n'
import Button from '@/components/buttons/Button'

interface IProps {
  layout: 'phone' | 'tablet'

  onChangePolicyId: (id: Policy['id']) => void
}

const StepExtern: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const openEmergencyModal = () => dispatch(openModalEmergencyNumber())

  const formClaimDamage = useSelector(selectors.getFormClaimDamage)
  const policy = useSelector((state) => selectors.getPolicy(state, formClaimDamage?.answersStatic?.policyId))
  const policyItems = useSelector(selectors.getPolicyItems)
  const agency = useSelector(selectors.getAgency)
  const claimMethod = useSelector((state) => selectors.getClaimMethod(state, formClaimDamage?.answersStatic?.policyId))
  const colors = useSelector(selectors.getThemeColors)

  const openExternLink = () => claimMethod?.externLink && common.openWebsite(claimMethod.externLink)
  const openWhatsapp = () => agency && common.openWhatsapp(agency.whatsapp)
  const openPhoneNumber = () => agency && common.openPhoneNumber(agency.phoneNumber)
  const openEmail = () => agency && common.openEmail(agency.email)
  const openWebsite = () => agency && common.openWebsite(agency.website)

  return (
    <Bootstrap.Row>
      <Bootstrap.Column span={{ lg: 12, md: 12, sm: 12, xl: 8, xs: 12, xxl: 9 }}>
        <View style={Styling.rowCenter}>
          <Icon
            circleColor={colors?.lightBrand}
            color={colors?.brand}
            huge={props.layout === 'tablet'}
            icon='fragile'
            large={props.layout !== 'tablet'}
            style={Styling.marginRight}
          />
          <Text type={props.layout === 'tablet' ? 'title' : 'subtitle'}>
            {policy?.productName ?? policy?.policyNumber}
          </Text>
        </View>
        <InputSelect
          noMargin
          items={policyItems.filter((policy) => policy.isAllowedToReportClaim)}
          label={i18n.t('Op welke polis wilt u schade melden?')}
          onChange={props.onChangePolicyId}
          style={Styling.marginTopMedium}
          value={formClaimDamage?.answersStatic.policyId}
        />

        {policy?.productName === 'Kostbaarheden'
          ? <Text
              style={props.layout === 'tablet' ? Styling.marginTopMedium : Styling.marginTop}
              type='description'
            >
            U kunt voor de kostbaarhedenverzekering geen schademelden via deze applicatie. U kunt hiervoor contact opnemen met uw adviseur.
          </Text>
          : null}

        {claimMethod?.description
          ? <Html
              html={claimMethod?.description}
              style={Styling.marginTopMedium}
            />
          : null}

        {claimMethod?.buttonTitle && claimMethod.externLink
          ? (
            <View style={Styling.marginTopMedium}>
              <Button
                onPress={openExternLink}
                style={props.layout === 'tablet' && Styling.selfStart}
                title={`${claimMethod.buttonTitle}*`}
              />
              <Text
                style={props.layout === 'tablet' ? Styling.marginTopMedium : Styling.marginTop}
                type='paragraph'
              >
                *{i18n.t('U wordt doorgestuurd naar een externe website om de schade te melden.')}
              </Text>
            </View>
            )
          : null}
      </Bootstrap.Column>
      <Bootstrap.Column
        span={{ lg: 12, md: 12, sm: 12, xl: 4, xs: 12, xxl: 3 }}
        style={{ xl: Styling.itemsEnd, xs: Styling.marginTopMedium }}
      >
        <View>
          <Text type='descriptionBold'>{i18n.t('Direct contact?')}</Text>
          {agency?.phoneNumber
            ? (
              <ContactInfo
                circleColor={colors?.brand}
                description={agency.phoneNumber}
                icon='phone'
                iconColor={Colors.white}
                onPress={openPhoneNumber}
                style={Styling.marginTopSmall}
                title={i18n.t('Bellen')}
              />
              )
            : null}
          {agency?.email
            ? (
              <ContactInfo
                circleColor={colors?.brand}
                description={agency.email}
                icon='envelope'
                iconColor={Colors.white}
                onPress={openEmail}
                style={Styling.marginTopSmall}
                title={i18n.t('Bericht')}
              />
              )
            : null}
          {agency?.whatsapp
            ? (
              <ContactInfo
                circleColor={Colors.whatsapp}
                description={agency.whatsapp}
                icon={['fab', 'whatsapp']}
                iconColor={Colors.white}
                onPress={openWhatsapp}
                style={Styling.marginTopSmall}
                title={i18n.t('WhatsApp')}
              />
              )
            : null}
          {agency?.website
            ? (
              <ContactInfo
                circleColor={colors?.brand}
                description={agency.website}
                icon='globe'
                iconColor={Colors.white}
                onPress={openWebsite}
                style={Styling.marginTopSmall}
                title={i18n.t('Website')}
              />
              )
            : null}
          <ContactInfo
            circleColor={Colors.darkRed}
            description={Settings.emergencyNumber}
            descriptionColor={Colors.darkRed}
            icon='phone-plus'
            iconColor={Colors.white}
            onPress={openEmergencyModal}
            style={Styling.marginTopSmall}
            title={i18n.t('Nood')}
          />
        </View>
      </Bootstrap.Column>
    </Bootstrap.Row>
  )
}

export default StepExtern
