import * as WebBrowser from 'expo-web-browser'
import React, { useEffect } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import ContextBreakpoint, { isSmallScreen } from '@/bootstrap/ContextBreakpoint'
import Button from '@/components/buttons/Button'
import Image from '@/components/images/image'
import Screen from '@/components/Screen'
import Html from '@/components/wrappers/HTML'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import { downloadVoucher } from '@/redux/actions/member_benefits'
import { getMemberBenefit, getMemberBenefitById } from '@/redux/selectors/member_benefits'
import type { IMemberBenefit } from '@/types/objects'
import dateUtil from '@/utils/date'
import i18n from '@/utils/i18n'
import selectors from '@/redux/selectors'

import VoucherCode from './components/voucher_code'
import type { TDetailScreenProps } from '@/types/props'
import { apiCall } from '../../../../redux/actions/api'
import { ENTITIES } from '@/redux/constants'
import toast from '@/utils/toast'

const MemberBenefitsDetail: TDetailScreenProps<IMemberBenefit['id']> = (props) => {
  const dispatch = useDispatch()
  const breakpoint = React.useContext(ContextBreakpoint)
  const styles = screenStyle(isSmallScreen(breakpoint))

  const memberBenefitId = props?.route?.params?.id
  const state = useSelector(getMemberBenefit)
  const memberBenefit = useSelector(getMemberBenefitById(memberBenefitId))
  const refreshing = !!useSelector(selectors.isLoading('MEMBER_BENEFITS'))
  const loadingMemberBenefit = !!useSelector(selectors.isLoading('MEMBER_BENEFIT', String(memberBenefitId)))

  useEffect(() => {
    fetchMemberBenefit()
  }, [])

  useEffect(() => {
    if (state.showToast) {
      toast('Bedankt voor jouw deelname!')
    }
  }, [state.showToast])

  const fetchMemberBenefit = () => {
    dispatch(apiCall('GET', ENTITIES.MEMBER_BENEFIT, `${memberBenefitId}`, undefined, true))
  }

  const joinCampaign = () => {
    dispatch(apiCall('PUT', ENTITIES.MEMBER_BENEFIT, `${memberBenefitId}`, undefined, true))
  }

  const download = (): void => {
    memberBenefit?.id && dispatch(downloadVoucher(memberBenefit?.id))
  }

  const openExternalLink = (): void => {
    memberBenefit?.externalLink && WebBrowser.openBrowserAsync(memberBenefit?.externalLink)
  }

  if (!memberBenefit) {
    return null
  }

  return (
    <Screen
      noPadding={isSmallScreen(breakpoint)}
      white={Platform.OS !== 'web'}
      useRefreshControl
      refreshing={refreshing}
      onRefresh={fetchMemberBenefit}
    >
      <Bootstrap.Row
        gutterHorizontal={15}
        gutterVertical={15}
      >
        <Bootstrap.Column span={{ lg: 8, md: 10, sm: 12, xl: 8, xs: 12, xxl: 8, xxxl: 6 }}>
          <Image
            style={styles.image}
            uri={memberBenefit?.image}
          />

          <View style={styles.container}>
            <Text
              style={styles.title}
              type='descriptionBold'
            >
              {memberBenefit?.title}
            </Text>

            <Text type='description'>{memberBenefit?.intro}</Text>

            {memberBenefit?.participant && (memberBenefit?.isPdfVoucher || memberBenefit?.isVoucherCode)
              ? (
                <>
                  <View style={styles.spacer} />
                  <VoucherCode memberBenefit={memberBenefit} />
                </>
                )
              : null}

            {memberBenefit?.isCampaignParticipant && !memberBenefit.isWinner
              ? (
                <>
                  <View style={styles.spacer} />
                  <Button
                    disabled={memberBenefit?.participant ?? memberBenefit?.isWinner ?? loadingMemberBenefit}
                    loading={loadingMemberBenefit}
                    onPress={joinCampaign}
                    title={memberBenefit?.participant ?? memberBenefit?.isWinner ? i18n.t('Mijn deelname is geregistreerd') : i18n.t('Ja, ik doe graag mee!')}
                  />
                </>
                )
              : null}

            {memberBenefit?.isWinner && memberBenefit.isWinnerDate != null
              ? (
                <>
                  <View style={styles.spacer} />
                  <View style={styles.winnerContainer}>
                    <Text
                      type='description'
                      style={styles.titleText}
                    >
                      {`Je hebt meegedaan aan de winactie voor '${memberBenefit.title}'. En je hebt gewonnen!`}

                    </Text>

                    <Text
                      type='description'
                      style={styles.contentText}
                    >
                      {`Om jouw tickets/stoelen te garanderen vragen wij je jouw aanwezigheid te bevestigen voor ${dateUtil.formatTimeStamp(memberBenefit?.isWinnerDate)}. Dat doe je via de winnaarsmail die vandaag naar je wordt gemaild.`}
                    </Text>

                    <Text
                      type='description'
                      style={styles.contentText}
                    >
                      {`Check hiervoor jouw mail/inbox en bevestig jouw aanwezigheid in de mail die je van ons ontvangt. Bij geen bevestiging voor ${dateUtil.formatTimeStamp(memberBenefit.isWinnerDate)} zullen we nieuwe winnaars blij maken met de tickets.`}
                    </Text>

                    <Text
                      type='description'
                      style={styles.contentText}
                    >
                      Alvast veel plezier toegewenst!
                    </Text>
                  </View>
                </>
                )
              : null}

            {(memberBenefit?.isPdfVoucher || memberBenefit?.isVoucherCode) && memberBenefit?.participant === false
              ? (
                <>
                  <View style={styles.spacer} />
                  <Button
                    disabled={!memberBenefit || state?.downloadLoading}
                    loading={state?.downloadLoading}
                    onPress={download}
                    title={memberBenefit?.isPdfVoucher ? i18n.t('Voucher ophalen') : i18n.t('Code genereren')}
                  />
                </>
                )
              : null}

            {memberBenefit?.externalLink
              ? (
                <>
                  <View style={styles.spacer} />
                  <Button
                    borderColor={Colors.gray}
                    color={Colors.white}
                    onPress={openExternalLink}
                    title={i18n.t('Ga naar de actiepagina')}
                    titleColor={Colors.gray}
                  />
                </>
                )
              : null}

            {memberBenefit?.campaignContent
              ? (
                <>
                  <View style={styles.spacer} />
                  <Html html={memberBenefit?.campaignContent} />
                </>
                )
              : null}

            {memberBenefit?.requirementsContent
              ? (
                <>
                  <View style={styles.spacer} />
                  <Html html={memberBenefit?.requirementsContent} />
                </>
                )
              : null}

            {memberBenefit?.validFrom && memberBenefit?.validTill
              ? (
                <>
                  <View style={styles.spacer} />
                  <Text type='note'>
                    {i18n.t(
                    `Geldig van ${dateUtil.formatTimeStamp(memberBenefit.validFrom)} tot ${dateUtil.formatTimeStamp(
                      memberBenefit.validTill
                    )}`
                    )}
                  </Text>
                </>
                )
              : null}
          </View>
        </Bootstrap.Column>
      </Bootstrap.Row>
    </Screen>
  )
}

const screenStyle = (smallScreen: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: smallScreen ? Sizes.screenPaddingHorizontal : undefined,
      paddingVertical: Sizes.screenPaddingVertical
    },

    image: {
      height: 300,
      width: '100%'
    },

    spacer: {
      marginTop: 25
    },

    title: {
      fontSize: 20,
      marginBottom: 10
    },
    winnerContainer: {
      borderRadius: Sizes.borderRadius,
      borderWidth: 1,
      borderColor: Colors.gray,
      backgroundColor: '#fff',
      paddingHorizontal: Sizes.buttonPaddingHorizontal,
      paddingVertical: Sizes.buttonPaddingVertical
    },
    contentText: {
      fontSize: 14,
      marginBottom: 8
    },
    titleText: {
      marginBottom: Sizes.buttonPaddingVertical
    }
  })

export default MemberBenefitsDetail
