import Agency from '@/screens/agency/Agency'
import ServiceGuide from '@/screens/agency/ServiceGuide'
import i18n from '@/utils/i18n'
import { createStackNavigator } from '@react-navigation/stack'

const AgencyStack = createStackNavigator()
const AgencyStackNavigator = () => (
  <AgencyStack.Navigator screenOptions={{ headerShown: false }}>
    <AgencyStack.Screen
      name='Agency'
      component={Agency}
      options={{ title: i18n.t('Adviseur') }}
    />

    <AgencyStack.Screen
      name='ServiceGuide'
      component={ServiceGuide}
      options={{ title: i18n.t('Dienstverleningsdocument') }}
    />
  </AgencyStack.Navigator>
)

export default AgencyStackNavigator
