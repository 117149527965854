import type { INotification } from '@/types/objects'
import prototype from '@/utils/prototype'
import * as CONSTANTS from '../constants/notifications'

export interface INotificationsState {
  data: INotification[]
  shouldOpenAfterLogin: boolean
}

export const initialState: INotificationsState = {
  data: [],
  shouldOpenAfterLogin: false
}

interface IDispatch {
  type: string
  payload: { data: INotification[] | INotification } & { id: INotification['messageId'] | string } & { shouldOpenAfterLogin: boolean }
}

export default (state = initialState, action: IDispatch) => {
  switch (action?.type) {
    case CONSTANTS.SET_SHOULD_OPEN_AFTER_LOGIN:
      return {
        ...state,
        shouldOpenAfterLogin: action.payload.shouldOpenAfterLogin
      }

    case CONSTANTS.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: prototype.mergeArrays(state.data, action.payload.data as INotification[], 'messageId')
      }

    case CONSTANTS.PUT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: prototype.mergeObjectInArray(state.data, action.payload.data as INotification, 'messageId')
      }

    case CONSTANTS.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: state.data.filter(n => n.messageId?.toString() !== action.payload.id?.toString())
      }

    default:
      return state
  }
}
