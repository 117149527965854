import { navigated } from '@/redux/actions'
import selectors from '@/redux/selectors'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Analytics from '@/utils/firebase/analytics'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import Home from '@/screens/auth/Home'
import Setup from '@/screens/setup/Setup'
import MainStackNavigator from './web/main/main_stack'
import { linking } from './web/helpers/linking'
import renderVerifyStack from './mobile/auth/verify/verify_stack'

const AuthStack = createStackNavigator()

function renderCorrectStack (routeName: string) {
  const loggedIn = useSelector(selectors.isLoggedIn)
  const setupCompleted = useSelector(selectors.isSetupCompleted)
  const verified = useSelector(selectors.isVerified)

  if (!loggedIn) {
    return (
      <AuthStack.Navigator screenOptions={{ headerShown: false }}>
        <AuthStack.Screen
          name='Login'
          component={Home}
        />
      </AuthStack.Navigator>
    )
  }

  if (!verified) {
    return renderVerifyStack()
  }

  if (!setupCompleted) {
    return (
      <AuthStack.Navigator screenOptions={{ headerShown: false }}>
        <AuthStack.Screen
          name='Setup'
          component={Setup}
          options={{ title: i18n.t('Mijn omgeving instellen') }}
        />
      </AuthStack.Navigator>
    )
  }

  return (
    <MainStackNavigator routeName={routeName} />
  )
}

const Navigator = () => {
  const [routeName, setRouteName] = useState(navigation.getCurrentRouteName())
  const dispatch = useDispatch()

  const setCurrentRouteName = () => {
    const route = navigation.getCurrentRoute()
    if (route?.name) {
      setRouteName(route.name)
      dispatch(navigated(route.name))

      Analytics.logEvent('on_navigation', {
        sender: i18n.t(routeName),
        target: i18n.t(route.name)
      })
      Analytics.setCurrentScreen(i18n.t(route.name))
    }
  }

  return (
    <NavigationContainer
      linking={linking}
      onReady={setCurrentRouteName}
      onStateChange={setCurrentRouteName}
      ref={navigation.init}
    >
      {renderCorrectStack(routeName)}
    </NavigationContainer>
  )
}

export default Navigator
