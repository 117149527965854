import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

import Block from '@/components/Block'
import Screen from '@/components/Screen'
import * as Bootstrap from '@/bootstrap'

import { apiCallSuccess } from '@/redux/actions/api'
import { postPurchaseInvoice, postPurchasePicture } from '@/redux/api/purchases'

import toast from '@/utils/toast'
import i18n from '@/utils/i18n'

import type { Purchase } from '@/types/objects'

import PurchaseForm, { inputLabels } from './components/purchase_form'
import { convertPurchaseToPurchaseInvoice, convertPurchaseToPurchasePicture } from '@/types/purchaseDto'
import selectors from '@/redux/selectors'

const CreatePurchase: React.FC = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const purchases = useSelector(selectors.getPurchases)

  const [purchase, setPurchase] = useState<Partial<Purchase>>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [invalidInputs, setInvalidInputs] = useState<string[]>([])

  const setItem = (item: Partial<Purchase>) => {
    setPurchase({
      ...purchase,
      ...item
    })

    setShowError(false)
  }

  const validate = () =>
    [
      !purchase.name || purchase.name.length < 1 ? inputLabels.name : null,
      !purchase.price || purchase.price.length < 1 ? inputLabels.price : null,
      !purchase.date || purchase.date.length < 1 ? inputLabels.date : null
    ].filter(Boolean) as string[]

  const submit = async () => {
    setLoading(true)

    if (validate().length > 0) {
      setInvalidInputs(validate())
      setShowError(true)
      setLoading(false)
      return
    }

    const invoicePurchase = convertPurchaseToPurchaseInvoice(purchase as Purchase)
    const invoiceResult = await postPurchaseInvoice(invoicePurchase)

    if (invoiceResult.success && invoiceResult.data) {
      const picturePurchaseWithId = {
        ...convertPurchaseToPurchasePicture(purchase as Purchase),
        purchaseId: invoiceResult.data.id
      }

      const pictureResult = await postPurchasePicture(picturePurchaseWithId)

      if (pictureResult.success) {
        const aggregatedPurchase = {
          ...invoiceResult.data,
          ...pictureResult.data
        }

        const allPurchases = [
          aggregatedPurchase,
          ...purchases
        ]
        dispatch(apiCallSuccess('GET', 'PURCHASES', undefined, allPurchases))
        navigation.goBack()
      } else {
        toast(i18n.t('Het item kon niet worden toegevoegd'))
      }
    } else {
      toast(i18n.t('Het item kon niet worden toegevoegd'))
    }

    setLoading(false)
  }

  return (
    <PurchaseForm
      purchase={purchase}
      loading={loading}
      showError={showError}
      invalidInputs={invalidInputs}
      onSubmit={submit}
      setPurchase={setItem}
    />
  )
}

const Phone: React.FC = () => (
  <Screen white>
    <CreatePurchase />
  </Screen>
)

const Tablet: React.FC = () => (
  <Screen>
    <Block>
      <Bootstrap.Row>
        <Bootstrap.Column span={{ lg: 6, md: 9, sm: 12, xl: 4, xs: 12 }}>
          <CreatePurchase />
        </Bootstrap.Column>
      </Bootstrap.Row>
    </Block>
  </Screen>
)

export default () => <Bootstrap.Switch
  renderPhone={Phone}
  renderTablet={Tablet}
                     />
