import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Marker } from '@/components/wrappers/MapView'
import Icon from '@/components/Icon'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import selectors from '@/redux/selectors'
import type { Company } from '@/types/objects'

interface IProps {
  company: Company
  onPress: (company: Company) => void
}

const MarkerCompany: React.FC<IProps> = ({ company, ...props }) => {
  const colors = useSelector(selectors.getThemeColors)
  const onMarkerPress = () => props.onPress(company)

  if (company?.latitude && company?.longitude) {
    return (
      <Marker
        coordinate={{
          latitude: parseFloat(company?.latitude?.toString()),
          longitude: parseFloat(company?.longitude?.toString())
        }}
        onPress={onMarkerPress}
      >
        <View style={[styles.marker, { backgroundColor: colors?.brand }]}>
          <Icon
            color={Colors.white}
            icon='tools'
          />
        </View>
      </Marker>
    )
  }

  return null
}

const styles = StyleSheet.create({
  marker: {
    alignItems: 'center',
    borderColor: Colors.white,
    borderRadius: Sizes.marker / 2,
    borderWidth: 2,
    height: Sizes.marker,
    justifyContent: 'center',
    overflow: 'hidden',
    width: Sizes.marker
  }
})

export default MarkerCompany
