import React from 'react'

import Cards from '@/components/Cards'
import Screen from '@/components/Screen'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

const cards = [
  {
    icon: 'car',
    id: 'vehicle',
    onPress: navigation.openScreenDamageRepairVehicle,
    title: i18n.t('Voertuigschade')
  }
  // @todo: NH1816-18486
  // {
  //   icon: 'home',
  //   id: 'home',
  //   title: i18n.t('Huis en eigendommen'),
  // },
]

export default function DamageRepair () {
  return (
    <Screen>
      <Cards
        cards={cards}
        id='damage-repair'
      />
    </Screen>
  )
}
