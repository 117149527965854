import { createSelector } from 'reselect'

import type { State as TState } from '@/redux/reducers'
import { getFirstParameter } from '@/redux/selectors/helpers'
import type { IAgencyLocation } from '@/types/objects'

import { getCurrentRelation } from './relations'

export const getAgency = (state: TState) => state.agency?.data
export const getAgents = (state: TState) => state.agency?.data?.agents
export const getAgencyId = createSelector(getCurrentRelation, (relation) => relation?.agencyId || null)

export const getServiceGuide = createSelector([getAgency, getFirstParameter], (agency, parameter: any) => {
  const serviceGuideId = String(parameter)

  return (agency?.serviceGuides ?? []).find((serviceGuide) => serviceGuide?.id === serviceGuideId)
})

export const getMainLocation = createSelector([getAgency], (agency): IAgencyLocation => {
  let location = agency?.locations?.find((l) => l.mainLocation)

  if (!location) {
    location = {
      ...agency
    } as IAgencyLocation
  }

  return location
})
