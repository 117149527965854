import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import { decode } from 'html-entities'

import * as Bootstrap from '@/bootstrap'
import Actions from '@/components/Actions'
import Amount from '@/components/Amount'
import Block from '@/components/Block'
import Button from '@/components/buttons/Button'
import Icon from '@/components/Icon'
import Screen from '@/components/Screen'
import TouchableText from '@/components/touchables/TouchableText'
import RefreshControl from '@/components/wrappers/RefreshControl'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Settings from '@/constants/Settings'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import { startClaimDamageForm } from '@/redux/actions/claims'
import { apiCall } from '@/redux/actions/api'
import { openModalEmergencyNumber } from '@/redux/actions/modals'
import { ENTITIES } from '@/redux/constants'
import type { State as TState } from '@/redux/reducers'
import type { ThemeColors } from '@/redux/reducers/theme'
import selectors from '@/redux/selectors'
import CardNavigates from '@/screens/overview/components/CardNavigates'
import IllustrationOverview from '@/svgs/IllustrationOverview'
import type { Policy, Relation, User } from '@/types/objects'
import common from '@/utils/common'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import type { TWtd } from '@/redux/reducers/support'
import { getWtdItemIconName } from '@/screens/overview/WhatToDos'
import apiWrapper from '@/utils/api_wrapper'
import FlashNews from './components/flash_news'

const intervalDuration = 60000

interface TOverviewProps {
  colors: ThemeColors
  openModalEmergencyNumber: typeof openModalEmergencyNumber
  policies: Policy[]
  policiesLoading: boolean
  relationLoading: boolean
  relation: Relation
  relationId: Relation['id']
  apiCall: typeof apiCall
  startClaimDamageForm: typeof startClaimDamageForm
  user: User
  valueMeterPolicies: Policy[]
  wtd: TWtd
  shouldOpenNotification: boolean
}

interface TOverviewState {
  hours: number
}

class Overview extends React.Component<TOverviewProps, TOverviewState> {
  caseButtons: any

  damageRepairButtons: any

  interval: any

  constructor (props: TOverviewProps) {
    super(props)

    this.caseButtons = [
      // {
      //   icon: 'file-alt',
      //   onPress: navigation.openScreenPurchases,
      //   title: i18n.t('Mijn bezittingen')
      // },
      {
        icon: 'fragile',
        onPress: navigation.openScreenClaims,
        title: i18n.t('Mijn schades')
      },
      {
        icon: 'euro-sign',
        onPress: navigation.openScreenPayments,
        title: i18n.t('Mijn betaaloverzicht')
      }
    ]

    if (this.props.valueMeterPolicies?.length > 0) {
      this.caseButtons.push({
        icon: 'tachometer',
        onPress: navigation.openScreenValueMeters,
        title: i18n.t('Waardemeters')
      })
    }

    this.damageRepairButtons = [
      {
        icon: 'car',
        onPress: navigation.openScreenDamageRepairVehicleWeb,
        title: i18n.t('Voertuigschade')
      }
    ]

    this.state = {
      hours: new Date().getHours()
    }
  }

  componentDidMount () {
    this.interval = setInterval(() => {
      this.setState({ hours: new Date().getHours() })
    }, intervalDuration)
    apiWrapper.setUserParams({ relationId: this.props.relationId, relationNumber: this.props.relation.relationNumber })
    this.refresh()
  }

  componentWillUnmount () {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  refresh = () => {
    this.props.apiCall('GET', ENTITIES.RELATION, this.props.relationId)
    this.props.apiCall('GET', ENTITIES.POLICIES)
    this.props.apiCall('GET', ENTITIES.WTD)
    this.props.apiCall('GET', ENTITIES.MEMBER_BENEFITS)
    this.props.apiCall('GET', 'FLASH_NEWS', undefined, undefined, true)
  }

  startClaimDamageForm = () => this.props.startClaimDamageForm()

  renderRefreshControl = () => (
    <RefreshControl
      onRefresh={this.refresh}
      refreshing={
        !!(this.props.policiesLoading || this.props.relationLoading)
      }
      title={i18n.t('De data aan het verversen')}
    />
  )

  render () {
    const greeting = common.determineGreeting(this.state.hours)
    const nameUser = common.determineName(this.props.user)
    const relation = this.props.relation || {}

    if (this.props.shouldOpenNotification) {
      void navigation.openScreenNotifications()
    }

    return (
      <Screen
        noBreadCrumbs
        noPadding
        refreshControl={this.renderRefreshControl()}
      >
        <Bootstrap.Grid>
          <Bootstrap.Row>
            <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 6, xs: 12, xxl: 8, xxxl: 8 }}>
              <Text type='greeting'>
                {greeting}
                {nameUser ? ',' : ''}
              </Text>
              {nameUser ? <Text type='title'>{decode(nameUser)}</Text> : null}
              <Text
                style={Styling.marginTopMedium}
                type='description'
              >
                {i18n.t('$screen.overview.description')}
              </Text>
            </Bootstrap.Column>
            <Bootstrap.Column span={{ lg: 6, md: 0, sm: 0, xl: 6, xs: 0, xxl: 4, xxxl: 4 }}>
              <IllustrationOverview
                backgroundColor='transparent'
                color={this.props.colors?.brand}
                style={Styling.autoVertical}
              />
            </Bootstrap.Column>
            <Bootstrap.Column span={{ lg: 12, md: 12, sm: 12, xl: 8, xs: 12 }}>
              <Block>
                <Bootstrap.Row spacing={0}>
                  <Bootstrap.Column
                    span={{ lg: 6, md: 12, sm: 12, xl: 6, xs: 12 }}
                    style={styles.contentContainer}
                  >
                    <Icon
                      large
                      circleColor={this.props.colors?.lightBrand}
                      color={this.props.colors?.brand}
                      icon='box-alt'
                      style={Styling.marginRight}
                    />
                    <View style={Styling.flexWrap}>
                      <Text type='subtitle'>{i18n.t('Mijn pakket')}</Text>
                      <Text type='descriptionLight'>
                        {/* eslint-disable-next-line prefer-template */}
                        {i18n.t('Combinummers') + ': ' + (relation.combiNumber || '')}
                      </Text>
                    </View>
                  </Bootstrap.Column>
                  <Bootstrap.Column span={{ lg: 6, md: 0, sm: 0, xl: 6, xs: 0 }}>
                    <View style={Styling.autoLeft}>
                      <View style={Styling.rowCenter}>
                        <Amount
                          currency
                          color={this.props.colors?.brand}
                          type='subtitle'
                        >
                          {relation.combiCollectionAmount}
                        </Amount>
                        <Text
                          style={Styling.marginLeftTiny}
                          type='description'
                        >
                          {common.determinePaymentPeriod(relation?.paymentPeriod)}
                        </Text>
                      </View>
                      {relation.combiPackageDiscount ? (
                        <Text type='descriptionLight'>
                          {/* eslint-disable-next-line prefer-template */}
                          {i18n.t('incl.') + ' ' + (relation.combiPackageDiscount || '') + '% ' + i18n.t('korting')}
                        </Text>
                      ) : null}
                    </View>
                  </Bootstrap.Column>
                </Bootstrap.Row>
                <View style={Styling.flexEvenly}>
                  <View style={styles.marginTopContainer}>
                    <Bootstrap.Row
                      gutterHorizontal={15}
                      gutterVertical={15}
                    >
                      <Bootstrap.Column span={{ lg: 4, md: 6, sm: 6, xl: 3, xs: 6 }}>
                        <Text type='paragraphLight'>{i18n.t('Ingangsdatum')}:</Text>
                        <Text type='span'>{relation.combiStartingDate}</Text>
                      </Bootstrap.Column>
                      <Bootstrap.Column span={{ lg: 4, md: 6, sm: 6, xl: 3, xs: 6 }}>
                        <Text type='paragraphLight'>{i18n.t('Vervaldatum')}:</Text>
                        <Text type='span'>{relation.combiContractExpirationDate}</Text>
                      </Bootstrap.Column>
                      <Bootstrap.Column span={{ lg: 4, md: 6, sm: 6, xl: 3, xs: 6 }}>
                        {(relation.paymentMethod === 'Automatisch' || relation.paymentMethod === 'Factuur') && (
                          <>
                            <Text type='paragraphLight'>{i18n.t('Betaalmethode')}:</Text>
                            <Text type='span'>{relation.paymentMethod}</Text>
                          </>
                        )}
                      </Bootstrap.Column>
                    </Bootstrap.Row>
                  </View>
                  <View style={styles.marginBottomContainer}>
                    {(!this.props.policies || !this.props.policies.length) && this.props.policiesLoading
                      ? (
                        <ActivityIndicator
                          color={Colors.primary}
                          size='large'
                        />
                        )
                      : (
                        <Bootstrap.Row
                          gutterHorizontal={15}
                          gutterVertical={15}
                        >
                          {(this.props.policies || []).map((policy) => (
                            <Bootstrap.Column
                              key={`overview-policy-item-${policy.id}`}
                              span={{ lg: 4, md: 6, sm: 12, xl: 6, xxl: 4, xs: 12 }}
                            >
                              <View style={Styling.flexRowCenter}>
                                <Icon
                                  circleColor={this.props.colors?.lightBrand}
                                  color={this.props.colors?.brand}
                                  productId={policy.productId}
                                />
                                <View style={Styling.marginLeftSmall}>
                                  <Text type='span'>{policy?.productName}</Text>
                                  <Amount
                                    currency
                                    paymentPeriod={relation?.paymentPeriod}
                                    type='spanLight'
                                  >
                                    {policy.premium}
                                  </Amount>
                                </View>
                              </View>
                            </Bootstrap.Column>
                          ))}
                        </Bootstrap.Row>
                        )}
                  </View>
                </View>
                <Actions
                  labelRight={i18n.t('Bekijk uw pakket')}
                  onPressRight={navigation.openScreenPolicies}
                  style={Styling.autoLeftTop}
                />
              </Block>
            </Bootstrap.Column>

            <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 4, xs: 12 }}>
              <Block>
                <View style={Styling.rowCenter}>
                  <Icon
                    large
                    circleColor={this.props.colors?.lightBrand}
                    color={this.props.colors?.brand}
                    icon='fragile'
                    style={Styling.marginRight}
                  />
                  <Text type='subtitle'>{i18n.t('Schade melden')}</Text>
                </View>
                <Text
                  style={Styling.marginTop}
                  type='description'
                >
                  {i18n.t(
                    'Heeft u schade? Vul het schadeformulier in via onderstaande button. Uw adviseur neemt de schade vervolgens z.s.m. in behandeling!'
                  )}
                </Text>
                <Button
                  icon='fragile'
                  iconCircleColor={Colors.white}
                  onPress={this.startClaimDamageForm}
                  style={Styling.marginVertical}
                  title={i18n.t('Direct schade melden')}
                />
                <Text type='description'>
                  {i18n.t('In geval van nood, neem contact op via het noodnummer')}:{' '}
                  <TouchableText
                    color={Colors.darkRed}
                    onPress={this.props.openModalEmergencyNumber}
                    type='description'
                  >
                    {Settings.emergencyNumber}
                  </TouchableText>
                </Text>
              </Block>
            </Bootstrap.Column>

            <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 4, xs: 12 }}>
              <CardNavigates
                buttons={this.damageRepairButtons}
                icon='tools'
                title={i18n.t('Schadehersteller')}
              />
            </Bootstrap.Column>

            <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 4, xs: 12 }}>
              <CardNavigates
                buttons={this.caseButtons}
                icon='folder'
                title={i18n.t('Mijn dossier')}
              />
            </Bootstrap.Column>

            <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 4, xs: 12 }}>
              <CardNavigates
                buttons={this.props.wtd.map(w => ({
                  icon: getWtdItemIconName(w),
                  onPress: async () => await navigation.openScreenWhatToDo(w.category),
                  title: w.subject
                }))}
                icon='question'
                title={i18n.t('Wat te doen bij')}
              />
            </Bootstrap.Column>
          </Bootstrap.Row>

          <FlashNews />
        </Bootstrap.Grid>
      </Screen>
    )
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  marginBottomContainer: {
    marginBottom: Sizes.spacingVerticalMedium,
    marginTop: Sizes.spacingVerticalMedium / 2
  },
  marginTopContainer: {
    marginBottom: Sizes.spacingVerticalMedium / 2,
    marginTop: Sizes.spacingVerticalMedium
  }
})

const mapStateToProps = (state: TState) => {
  return {
    colors: selectors.getThemeColors(state),
    policies: selectors.getPoliciesImportant(state),
    policiesLoading: selectors.isLoading(ENTITIES.POLICIES)(state),
    relation: selectors.getCurrentRelation(state),
    relationId: selectors.getCurrentRelationId(state),
    relationLoading: selectors.isLoading(ENTITIES.RELATIONS)(state),
    user: selectors.getUser(state),
    valueMeterPolicies: selectors.getValueMeterPolicies(state),
    wtd: selectors.getWtd(state),
    shouldOpenNotification: selectors.getShouldOpenNotifications(state)
  }
}

const mapDispatchToProps = {
  apiCall,
  openModalEmergencyNumber,
  startClaimDamageForm
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
