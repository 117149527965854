import defaults from '@/bootstrap/defaults'
import getBreakpoint from '@/bootstrap/getBreakpoint'
import prototype from '@/utils/prototype'

function getPreviousBreakpoint (breakpoint: string) {
  return getBreakpoint(defaults.breakpoints, defaults.breakpoints[breakpoint] - 1)
}

interface Config { [key: string]: any }

export default function getConfig (breakpoint: string, config: Config) {
  if (!prototype.isObject(config)) {
    return null
  }

  while (breakpoint && !(breakpoint in config)) {
    // eslint-disable-next-line no-param-reassign
    breakpoint = getPreviousBreakpoint(breakpoint)
  }

  return config[breakpoint]
}
