import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from '@/bootstrap'
import { apiCall } from '@/redux/actions/api'
import { ENTITIES } from '@/redux/constants'
import selectors from '@/redux/selectors'
import type { Policy, Relation } from '@/types/objects'
import type { TNavigationProp } from '@/types/props'
import common from '@/utils/common'

import PolicyPhone from './Policy.phone'
import PolicyTablet from './Policy.tablet'

export interface IChildComponentProps {
  policy: Policy
  relation: Relation
  refreshing: boolean
  onRefresh: () => void
}

interface IProps {
  navigation: TNavigationProp
}

const PolicyScreen: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()

  const policyId: string = common.getNavigationParam(props, 'policyId')
  const policy = useSelector((state) => selectors.getPolicy(state, policyId))
  const relation = useSelector(selectors.getCurrentRelation)
  const refreshing = !!useSelector(selectors.isLoading(ENTITIES.POLICY, policyId))

  const onRefresh = () => {
    dispatch(apiCall('GET', ENTITIES.POLICY, policyId))
  }

  useEffect(() => {
    onRefresh()

    props.navigation.setOptions({ title: policy?.productName })
  }, [])

  if (!policyId || !policy) {
    return null
  }

  const componentProps = {
    policy,
    refreshing,
    onRefresh,
    relation
  }

  return (
    <Switch
      /* eslint-disable react/jsx-props-no-spreading */
      renderPhone={() => <PolicyPhone {...componentProps} />}
      renderTablet={() => <PolicyTablet {...componentProps} />}
      /* eslint-enable react/jsx-props-no-spreading */
    />
  )
}

export default PolicyScreen
