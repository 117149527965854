import React from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import data from '@/utils/data'
import i18n from '@/utils/i18n'

import Icon from '@/components/Icon'
import ListItem from '@/components/list_items/ListItem'
import Text from '@/components/wrappers/Text'
import Sizes from '@/constants/Sizes'

import mapStateToProps from '@/redux/mapStateToProps'
import type { ThemeColors } from '@/redux/reducers/theme'

import type { IGreenCardRow } from '@/redux/selectors/policies'
import { GreenCardRowType } from '@/redux/selectors/policies'
import type { GeneratedGreenCard } from '@/types/objects'
import Actions from '@/components/Actions'
import Styling from '@/constants/Styling'
import navigation from '@/utils/navigation'

interface TListItemGreenCardProps {
  colors: ThemeColors
  row: IGreenCardRow
}

function ListItemGreenCard (props: TListItemGreenCardProps) {
  const { row } = props
  const policy = row.policy
  const licensePlate = data.getLicensePlate(policy)

  const handlePress = async () => {
    switch (row.type) {
      case GreenCardRowType.GENERATE_PULLABLE_POLICY:
        return await navigation.openScreenGenerateGreenCard(policy)
      case GreenCardRowType.DOWNLOADABLE_POLICY:
        return navigation.openScreenGreenCard(row.policy)
      case GreenCardRowType.DOWNLOADABLE_PULLABLE_POLICY:
        return await navigation.openScreenGeneratedGreenCard((row.generatedGreenCardOrUrl as GeneratedGreenCard))
    }
  }

  const isPullablePolicy = row.type === GreenCardRowType.DOWNLOADABLE_PULLABLE_POLICY
  const isPullableGenerativePolicy = row.type === GreenCardRowType.GENERATE_PULLABLE_POLICY

  return (
    <ListItem
      chevron
      item={policy}
      onPress={handlePress}
      style={{ marginBottom: 15 }}
    >
      <Icon
        circleColor={props.colors?.lightBrand}
        color={props.colors?.brand}
        productId={policy.productId}
      />
      <View style={styles.container}>
        <Text type='span'>
          {policy?.productName}
        </Text>
        {licensePlate && (
          <Text type='paragraphLight'>
            {i18n.t('Kenteken')}: {licensePlate}
          </Text>
        )}
        {isPullablePolicy && (
          <Text type='paragraphLight'>
            {i18n.t('Trekkend voertuig')}: {(row.generatedGreenCardOrUrl as GeneratedGreenCard).pullingVehicleLicensePlate}
          </Text>
        )}
        <Text type='paragraphLight'>
          {i18n.t('Polisnummer')}: {policy.policyNumber}
        </Text>
        {isPullableGenerativePolicy && (
          <Actions
            labelRight='Verzekeringsbewijs genereren'
            onPressRight={handlePress}
            style={Styling.marginTop}
          />
        )}
      </View>
    </ListItem>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Sizes.spacingVertical
  }
})

export default connect(mapStateToProps.themeColors)(ListItemGreenCard)
