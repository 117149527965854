import * as CONSTANTS from '@/redux/constants/forms'
import type * as TYPES from '@/redux/types/forms'

export const resetFormClaimDamanage = (): TYPES.ResetFormClaimDamanageAction => ({
  type: CONSTANTS.RESET_FORM_CLAIM_DAMAGE
})

export const setFormClaimDamageId = (claimDamageId: string): TYPES.SetFormClaimDamageIdAction => ({
  claimDamageId,
  type: CONSTANTS.SET_FORM_CLAIM_DAMAGE_ID
})

export const updateFormClaimDamageDynamic = (key: string, update: any): TYPES.UpdateFormClaimDamageDynamicAction => ({
  key,
  type: CONSTANTS.UPDATE_FORM_CLAIM_DAMAGE_DYNAMIC,
  update
})

export const updateFormClaimDamageStatic = (key: string, update: any): TYPES.UpdateFormClaimDamageStaticAction => ({
  key,
  type: CONSTANTS.UPDATE_FORM_CLAIM_DAMAGE_STATIC,
  update
})
