import { UPDATE_PRODUCT, UPDATE_MESSAGE, ADD_ROW, REMOVE_ROW, RESET_ADVISE } from '../constants/policy_advise'

export const updateAdviseProduct = (
  index: number,
  value: string
) => ({
  type: UPDATE_PRODUCT,
  payload: { index, value }
})

export const updateAdviseMessage = (
  value: string
) => ({
  type: UPDATE_MESSAGE,
  payload: { index: 0, value }
})

export const addProductRow = (
) => ({
  type: ADD_ROW,
  payload: { index: 0, value: '' }
})

export const removeProductRow = (
  index: number
) => ({
  type: REMOVE_ROW,
  payload: { index, value: '' }
})

export const resetAdvise = (
) => ({
  type: RESET_ADVISE,
  payload: { index: 0, value: '' }
})
