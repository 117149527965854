/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */

import React from 'react'

import ContextSpacing from '@/bootstrap/ContextSpacing'

export default function withSpacing (Component: React.ElementType) {
  return (props: any) => (
    <ContextSpacing.Consumer>
      {(spacing) => (
        <Component
          spacing={spacing}
          {...props}
        />
      )}
    </ContextSpacing.Consumer>
  )
}
