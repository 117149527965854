import * as CONSTANTS from '@/redux/constants/modals'
import type { ModalsActionTypes } from '@/redux/types/modals'

export interface ModalsState {
  emergencyNumber: boolean
  help: boolean
  logs: boolean
  session: boolean
}

export const initialState: ModalsState = {
  emergencyNumber: false,
  help: false,
  logs: false,
  session: false
}

export default (state: ModalsState = initialState, action: ModalsActionTypes): ModalsState => {
  switch (action?.type) {
    case CONSTANTS.CLOSE_MODAL:
      return action.modal in state ? { ...state, [action.modal]: false } : state

    case CONSTANTS.OPEN_MODAL:
      return action.modal in state ? { ...state, [action.modal]: true } : state

    default:
      return state
  }
}
