import React from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import ButtonCircle from '@/components/buttons/ButtonCircle'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import mapStateToProps from '@/redux/mapStateToProps'
import common from '@/utils/common'
import i18n from '@/utils/i18n'

interface TItemAgentProps {
  agent: any
  colors: any
}

class ItemAgent extends React.Component<TItemAgentProps> {
  openEmail = () => common.openEmail(this.props.agent.emailAddress)

  openPhoneNumber = () => common.openPhoneNumber(this.props.agent.phoneNumber)

  render () {
    const agent = this.props.agent || {}
    return (
      <View style={styles.container}>
        <View style={styles.upperContainer}>
          <Text type='subsection'>{agent.fullName}</Text>
          <Text type='paragraphLight'>{agent.function || ' '}</Text>
          <View style={styles.iconContainer}>
            {agent.emailAddress
              ? (
                <ButtonCircle
                  color={this.props.colors && this.props.colors.brand}
                  icon='envelope'
                  onPress={this.openEmail}
                />
                )
              : (
                <View />
                )}
            {agent.phoneNumber
              ? (
                <ButtonCircle
                  color={this.props.colors && this.props.colors.brand}
                  icon='phone'
                  onPress={this.openPhoneNumber}
                />
                )
              : (
                <View />
                )}
          </View>
        </View>
        <View style={styles.lowerContainer}>
          <Text type='paragraphLight'>{i18n.t('Expertises')}</Text>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    borderRadius: Sizes.borderRadius,
    flex: 1,
    overflow: 'hidden'
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Sizes.spacingVertical
  },
  lowerContainer: {
    backgroundColor: Colors.f9,
    padding: Sizes.listItemPadding
  },
  upperContainer: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.line,
    borderBottomWidth: 1,
    flex: 1,
    padding: Sizes.listItemPadding
  }
})

export default connect(mapStateToProps.themeColors)(ItemAgent)
