export default {
  AgencyStack: 'Agency',
  AuthentifyStack: 'Home',
  CaseStack: 'Case',
  ClaimDamageStack: 'ClaimDamage',
  ClaimsStack: 'Claims',
  ManageUsersStack: 'ManageUsers',
  MemberBenefitsStack: 'MemberBenefits',
  MoreStack: 'More',
  NotificationsStack: 'Notifications',
  OverviewStack: 'Overview',
  PoliciesStack: 'Policies',
  ProfileStack: 'Profile',
  PurchasesStack: 'Purchases',
  RootStack: 'Overview',
  RootTab: 'OverviewStack',
  SetupStack: 'Setup',
  TipsStack: 'TipCategories',
  VerifyStack: 'Verify',
  ValueMetersStack: 'ValueMeters'
} as Record<string, string>
