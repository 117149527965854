import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'

import logger from '@/utils/logger'

class Storage {
  logError = (error: any) => logger.warn('Storage:', String(error), error)

  getItem = async (key: string, defaultValue: any): Promise<any> => {
    try {
      let value
      if (Platform.OS === 'web') {
        value = JSON.parse(localStorage.getItem(key)!)
      } else {
        value = await AsyncStorage.getItem(key)
      }

      return value ?? defaultValue
    } catch (error) {
      this.logError(error)

      return defaultValue
    }
  }

  setItem = async (key: string, value: any): Promise<boolean> => {
    try {
      if (Platform.OS === 'web') {
        localStorage.setItem(key, JSON.stringify(value))
      } else {
        await AsyncStorage.setItem(key, JSON.stringify(value))
      }

      return true
    } catch (error) {
      this.logError(error)

      return false
    }
  }

  setAlreadyUsed = async (value: any) => await this.setItem('already-used', value)

  isAlreadyUsed = async () => await this.getItem('already-used', false)
}

export default new Storage()
