import type { Agency, Relation, User } from '@/types/objects'
import api from '@/utils/api_wrapper'

export const getRelation = async (id: string) => await api.get<Relation>(`relations/${id}`)

export const getRelations = async () => await api.get<Relation[]>('relations')

export const updateRelation = async (id: string, relation: Relation, updates: Partial<Relation>) =>
  await api.put(`relations/${id}`, { agencyId: relation?.agencyId, data: { ...relation, ...updates } })

export const updateRelationPreferences = async (relation: Relation, updates: Partial<Relation>) =>
  await api.put(`relations/preferences/${relation?.id}`, { ...updates, agencyId: relation?.agencyId })

export interface IUpdateEmailParams {
  agencyId: Agency['id']
  isRelationMutation: boolean
  newEmail: User['email']
  newEmailRepeat: User['email']
  password: User['password']
}

export const updateEmailAddress = async (id: string, params: IUpdateEmailParams) =>
  await api.put(`relations/${id}/change-email`, params)

export const postRelationInvite = async (_: unknown, params: { addCombiNumber: string, email: string }) =>
  await api.post('relations/add-relation-invite', params)

export const deleteRelation = async (id: string) => await api.delete(`relations/${id}`)
