import type { TApiCallSuccess } from '../actions/api'

export enum ApiContentWtdButtonType {
  AGENCY = 'agency',
  EMERGENCY = 'emergency',
  DAMAGE_REPAIR = 'damage-repair'
}

// TODO: pimp these when done
export enum WtdCategory {
  DAMAGE = 'damage',
  DISEASED = 'diseased',
  LEGAL = 'legal',
  LOSS = 'loss',
  DAMAGE_REPAIR = 'damage-repair'
}

export interface IWtdItem {
  category: WtdCategory
  sortNumber: number
  subject: string
  content: string
  publish: boolean
  button: ApiContentWtdButtonType
}

export type TWtd = IWtdItem[]

interface SupportState {
  aboutMembership: string
  aboutNh: string
  wtd: TWtd
}

export const initialState: SupportState = {
  aboutMembership: '',
  aboutNh: '',
  wtd: []
}

export default (state: SupportState = initialState, action: TApiCallSuccess): SupportState => {
  switch (action?.type) {
    case 'GET_ABOUT_MEMBERSHIP_SUCCESS':
      return {
        ...state,
        aboutMembership: action.payload.data as string
      }

    case 'GET_ABOUT_NH_SUCCESS':
      return {
        ...state,
        aboutNh: action.payload.data as string
      }

    case 'GET_WTD_SUCCESS':
      return {
        ...state,
        wtd: action.payload.data as TWtd
      }

    default:
      return state
  }
}
