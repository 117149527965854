import React, { useEffect } from 'react'
import type { ViewStyle } from 'react-native'
import { View } from 'react-native'

import { useDispatch, useSelector } from 'react-redux'
import { Switch } from '@/bootstrap'
import Button from '@/components/buttons/Button'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import Success from '@/svgs/Success'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import he from 'he'

import { resetFormClaimDamanage } from '@/redux/actions/forms'
import { apiCall } from '@/redux/actions/api'
import selectors from '@/redux/selectors'

interface IProps {
  viewClaimDamageReport: () => void
}

const Buttons = (style: ViewStyle, onPress: () => void): JSX.Element => {
  return (
    <View style={[style, Styling.marginTopMedium]}>
      <Button
        color={Colors.primary}
        onPress={() => {
          onPress()
        }}
        title={i18n.t('Bekijk schademelding')}
        titleColor={Colors.white}
      />
      <Button
        borderColor={Colors.gray}
        color={Colors.white}
        onPress={() => {
          navigation.replaceScreenClaims()
        }}
        title={i18n.t('Terug naar het overzicht')}
        titleColor={Colors.gray}
      />
    </View>
  )
}

const StepResult: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const agency = useSelector(selectors.getAgency)

  useEffect(() => {
    dispatch(resetFormClaimDamanage())
    dispatch(apiCall('GET', 'CLAIMS', undefined, undefined, true))
  }, [])

  return (
    <>
      <Success style={Styling.selfCenter} />

      <Text
        center
        style={[Styling.selfCenter, Styling.marginVertical]}
        type='label'
      >
        Schademelding ingediend bij
      </Text>

      <Text
        center
        style={[Styling.selfCenter, Styling.marginBottomBig]}
        type='title'
      >
        {he.decode(agency?.name)}
      </Text>

      <Text
        center
        style={Styling.selfCenter}
        type='description'
      >
        Uw adviseur neemt indien nodig contact met u op voor het compleet maken van dit dossier.
      </Text>

      <Switch
        renderPhone={() =>
          Buttons(
            {
              flex: 1,
              flexDirection: 'column',
              gap: 32
            },
            props.viewClaimDamageReport
          )}
        renderTablet={() =>
          Buttons(
            {
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              gap: 32
            },
            props.viewClaimDamageReport
          )}
      />
    </>
  )
}

export default StepResult
