import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'

import Card from '@/components/Card'
import selectors from '@/redux/selectors'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

interface IProps {
  id?: string
  style?: StyleProp<ViewStyle>
}

const CardGreenCard: React.FC<IProps> = (props) => {
  const hasAccessToGreenCardScreen = useSelector(selectors.selectHasAccessToGreenCardsScreen)

  if (!hasAccessToGreenCardScreen) {
    return null
  }

  const onPress = async () => {
    await navigation.openScreenGreenCards()
  }

  return (
    <Card
      icon='address-card'
      onPress={onPress}
      style={props.style}
      title={i18n.t('Verzekeringsbewijzen')}
    />
  )
}

export default CardGreenCard
