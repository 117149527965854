import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import Svg, { Path } from 'react-native-svg'

interface TLogoProps {
  color: string
  style?: StyleProp<ViewStyle>
  width?: number
}

const defaultWidth = 120
const defaultHeight = 98
export const ratio = defaultHeight / defaultWidth

export default function SvgComponent (props: TLogoProps) {
  const width = props.width || defaultWidth
  const height = props.width ? width * ratio : defaultHeight
  return (
    <Svg
      height={height}
      style={props.style}
      viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}
      width={width}
    >
      <Path
        d='M97.14 14.67c-9.41.54-15.54 5.21-19.83 10.34v-25H55v41.58L24.72 0H0v97.89l25.92-7.42V40.73l32.75 40.32 18.64-5.35V42.64c.47-5.77 4.47-9.3 9.76-10 5.64-.7 9.45 2 9.45 8V70.2l23.19-6.65V31.47c0-11.18-8.46-17.61-22.57-16.8z'
        fill={props.color}
        fillRule='nonzero'
      />
    </Svg>
  )
}
