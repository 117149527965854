import React from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import i18n from '@/utils/i18n'
import toast from '@/utils/toast'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import BreadCrumbs from '@/components/BreadCrumbs'
import Button from '@/components/buttons/Button'
import Line from '@/components/Line'
import Screen from '@/components/Screen'
import Html from '@/components/wrappers/HTML'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Switch from '@/screens/profile/components/Switch'
import Styling from '@/constants/Styling'
import Modal from '../../components/wrappers/Modal'

import type { TApiCallProps } from '@/hocs/withApiCall'
import withApiCall from '@/hocs/withApiCall'

import { updateUser } from '@/redux/actions/user'
import { updateRelationPreferences as updateRelationPreferencesApi } from '@/redux/api/relations'
import { updateUser as updateUserApi } from '@/redux/api/user'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import { ENTITIES } from '@/redux/constants'
import { apiCall as apiCallDispatch } from '@/redux/actions/api'
import { updateRelation } from '@/redux/actions/relations'

import type { Relation, User } from '@/types/objects'
import OverlaySpinner from '../../components/overlay_spinner'
import { IS_WEB } from '@/constants/platform'
import Action from '../../components/Action'
import ButtonOutline from '@/components/buttons/ButtonOutline'
import OnFocus from '@/components/OnFocus'
import * as Analytics from '@/utils/firebase/analytics'

type TSettingsProps = TApiCallProps & {
  aboutMembership: string
  relation: Relation
  relationId: Relation['id']
  user: User
  userId: User['id']

  apiCallDispatch: typeof apiCallDispatch
  updateRelation: typeof updateRelation
  updateUser: typeof updateUser
}

interface ModalProps {
  title: string
  question: string
  confirmButtonText: string
  cancelButtonText: string
}

interface TSettingsState {
  cooperationMembership: boolean
  financialMessages: boolean
  informativeMessages: boolean
  insuranceMessages: boolean
  loading: boolean
  showConfirmChangesModal: boolean
  memberBenefits: boolean
  zestMagazine: boolean
  revertChanges: () => void
  modalContent: ModalProps
  notificationsPermissionGranted: boolean
  showBenefitsWithoutMembershipModal: boolean
}

const modalContent: ModalProps[] = [
  {
    title: 'Wijziging bevestigen',
    question: '',
    confirmButtonText: 'Bevestigen',
    cancelButtonText: 'Annuleren',
  },
  {
    title: 'Lidmaatschap beëindigen',
    question:
      'Weet u het zeker? Zonder lidmaatschap ontvangt u geen Zest Magazine en kunt u niet gebruikmaken van leuke ledenvoordelen. Uw verzekeringspakket blijft ongewijzigd.',
    confirmButtonText: 'Ja, beëindig lidmaatschap',
    cancelButtonText: 'Nee, annuleren',
  }
]

class Settings extends React.Component<TSettingsProps, TSettingsState> {
  private notificationsLibrary?: typeof import('react-native-permissions')

  constructor (props: TSettingsProps) {
    super(props)

    this.state = {
      ...this.getRelationState(),
      ...this.getUserState(),
      showConfirmChangesModal: false,
      loading: false,
      revertChanges: () => this.setState({ showConfirmChangesModal: false }),
      modalContent: modalContent[1],
      // initialize to true to prevent warning from being shown incorrectly
      notificationsPermissionGranted: true,
      showBenefitsWithoutMembershipModal: false
    }
  }

  async componentDidMount () {
    if (!IS_WEB) {
      this.notificationsLibrary = await import('react-native-permissions')
      await this.checkPermissions()
    }

    await this.refresh()
  }

  checkPermissions = async () => {
    if (IS_WEB) {
      return
    }

    const notificationsResult = await this.notificationsLibrary!.checkNotifications()
    const disabled = ['blocked', 'denied', 'unavailable'].includes(notificationsResult.status)
    this.setState({ notificationsPermissionGranted: !disabled })
  }

  getUserState = () => ({
    financialMessages: this.props.user?.financialMessages || false,
    informativeMessages: this.props.user?.informativeMessages || false,
    insuranceMessages: this.props.user?.insuranceMessages || false,
    memberBenefits: this.props.user?.memberBenefits || false,
  })

  getRelationState = () => ({
    cooperationMembership: this.props.relation?.cooperationMembership || false,
    zestMagazine: this.props.relation?.zestMagazine || false,
  })

  refresh = async () => {
    this.props.apiCallDispatch('GET', ENTITIES.ABOUT_MEMBERSHIP)
    this.props.apiCallDispatch('GET', ENTITIES.USER, this.props.relationId, this.props.userId)
    this.props.apiCallDispatch('GET', ENTITIES.RELATION, this.props.relationId)

    await this.checkPermissions()
  }

  handleFocus = async () => {
    await this.refresh()
  }

  onPressSubmit = () => {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true, showConfirmChangesModal: false }, async () => {
      const userUpdates = {
        financialMessages: this.state.financialMessages,
        informativeMessages: this.state.informativeMessages,
        insuranceMessages: this.state.insuranceMessages,
        memberBenefits: this.state.memberBenefits,
      }

      let result = await this.props.apiCall(
        updateUserApi,
        this.props.relationId,
        this.props.userId,
        this.props.user,
        userUpdates,
      )

      if (result) {
        this.props.updateUser(userUpdates)
      } else {
        this.setState(this.getUserState())
      }

      if (
        this.state.cooperationMembership !== this.props.relation.cooperationMembership ||
        this.state.zestMagazine !== this.props.relation.zestMagazine
      ) {
        if (this.state.cooperationMembership !== this.props.relation.cooperationMembership) {
          if (this.state.cooperationMembership) {
            Analytics.logEvent('cooperation_membership_enabled')
          } else {
            Analytics.logEvent('cooperation_membership_disabled')
          }
        }

        if (this.state.zestMagazine !== this.props.relation.zestMagazine) {
          if (this.state.zestMagazine) {
            Analytics.logEvent('zest_magazine_enabled')
          } else {
            Analytics.logEvent('zest_magazine_disabled')
          }
        }

        const relationUpdates = {
          cooperationMembership: this.state.cooperationMembership,
          zestMagazine: this.state.zestMagazine,
        }

        result = await this.props.apiCall(updateRelationPreferencesApi, this.props.relation, relationUpdates)

        if (result) {
          this.props.updateRelation({
            ...this.props.relation,
            ...relationUpdates,
          })
        } else {
          this.setState(this.getRelationState())
        }
      }

      if (result) {
        toast(i18n.t('De instellingen zijn gewijzigd'))
      } else {
        toast(i18n.t('De instellingen konden niet worden gewijzigd'))
      }

      this.setState({ loading: false })
    })
  }

  /* eslint-disable @typescript-eslint/lines-between-class-members */
  onChangeFinancialMessages = (financialMessages: boolean) => {
    this.setState({
      revertChanges: this.getRevertChangeFunction('financialMessages', !financialMessages),
      financialMessages,
      showConfirmChangesModal: true,
      modalContent: modalContent[0],
    })
  }

  onChangeInformativeMessages = (informativeMessages: boolean) => {
    this.setState({
      revertChanges: this.getRevertChangeFunction('informativeMessages', !informativeMessages),
      informativeMessages,
      showConfirmChangesModal: true,
      modalContent: modalContent[0],
    })
  }

  onChangeInsuranceMessages = (insuranceMessages: boolean) => {
    this.setState({
      revertChanges: this.getRevertChangeFunction('insuranceMessages', !insuranceMessages),
      insuranceMessages,
      showConfirmChangesModal: true,
      modalContent: modalContent[0],
    })
  }

  onChangeMemberBenefits = (memberBenefits: boolean) => {
    const { cooperationMembership } = this.state

    if (memberBenefits && !cooperationMembership) {
      this.setState({
        showBenefitsWithoutMembershipModal: true
      })

      return
    }

    this.setState({
      revertChanges: this.getRevertChangeFunction('memberBenefits', !memberBenefits),
      memberBenefits,
      showConfirmChangesModal: true,
      modalContent: modalContent[0],
    })
  }

  onChangeCooperationMembership = (cooperationMembership: boolean) => {
    if (!cooperationMembership) {
      this.setState({
        revertChanges: () => {
          this.setState({
            showConfirmChangesModal: false,
            cooperationMembership: true,
            zestMagazine: true,
          })
        },
        showConfirmChangesModal: true,
        cooperationMembership: false,
        zestMagazine: false,
        memberBenefits: false,
        modalContent: modalContent[1],
      })
    } else {
      this.setState({
        revertChanges: () => {
          this.setState({
            showConfirmChangesModal: false,
            cooperationMembership: false,
            zestMagazine: false,
          })
        },
        cooperationMembership: true,
        showConfirmChangesModal: true,
        modalContent: modalContent[0],
      })
    }
  }

  onChangeZestMagazine = (zestMagazine: boolean) => {
    this.setState({
      revertChanges: this.getRevertChangeFunction('zestMagazine', !zestMagazine),
      zestMagazine,
      showConfirmChangesModal: true,
      modalContent: modalContent[0],
    })
  }

  onPressBenefitsWithoutMembershipOk = () => {
    this.setState({
      showBenefitsWithoutMembershipModal: false
    })
  }

  getRevertChangeFunction = (field: keyof TSettingsState, oldValue: boolean) => {
    return () => {
      this.setState({
        showConfirmChangesModal: false,
        [field]: oldValue,
      })
    }
  }

  renderHeaderPhone = (title: string) => {
    return (
      <>
        <View style={styles.headerContainerPhone}>
          <Text type='descriptionBold'>{title}</Text>
        </View>
        <Line
          horizontal
          color={Colors.background}
        />
      </>
    )
  }

  renderHeaderTablet = (title: string) => {
    return (
      <View style={styles.headerContainerTablet}>
        <Text type='descriptionBold'>{title}</Text>
      </View>
    )
  }

  renderItemPhone = (title: string, onValueChange: any, value: any, footnote = '', disabled = false) => {
    return (
      <>
        <View style={styles.itemContainerPhone}>
          <View style={Styling.flex}>
            <Text type='description'>{title}</Text>
            {footnote ? <Text type='footnote'>{footnote}</Text> : null}
          </View>
          <Switch
            onValueChange={onValueChange}
            style={Styling.marginLeft}
            value={value}
            disabled={disabled}
          />
        </View>
        <Line
          horizontal
          color={Colors.background}
        />
      </>
    )
  }

  renderItemTablet = (title: string, onValueChange: any, value: any, footnote = '', disabled = false) => {
    return (
      <View style={styles.itemContainerTablet}>
        <View style={Styling.flex}>
          <Text type='description'>{title}</Text>
          {footnote ? <Text type='footnote'>{footnote}</Text> : null}
        </View>
        <Switch
          onValueChange={onValueChange}
          style={Styling.marginLeft}
          value={value}
          disabled={disabled}
        />
      </View>
    )
  }

  renderPushNotificationWarning = (descriptionStyle: any) => {
    if (IS_WEB) {
      return null
    }

    const showPushNotificationsWarning = (
      this.state.financialMessages ||
      this.state.informativeMessages ||
      this.state.insuranceMessages ||
      this.state.memberBenefits
    ) && !this.state.notificationsPermissionGranted

    if (!showPushNotificationsWarning) {
      return null
    }

    return (
      <View>
        <Text
          style={descriptionStyle}
          type='inputWarning'
        >
          U heeft aangegeven pushberichten te willen ontvangen,
          maar u heeft notificaties voor de app uitgeschakeld.
        </Text>

        <Action
          onPress={this.notificationsLibrary!.openSettings}
          label='Instellingen openen'
          style={descriptionStyle}
          type='right'
        />

        <Line
          style={descriptionStyle}
          horizontal
          color={Colors.background}
        />
      </View>
    )
  }

  renderModalMembershipNotActive () {
    const { showBenefitsWithoutMembershipModal } = this.state

    return (
      <Modal visible={showBenefitsWithoutMembershipModal}>
        <Text
          style={styles.modalTitle}
          type='section'
        >
          Lidmaatschap niet actief
        </Text>

        <Text
          type='description'
          style={[Styling.textCenter, Styling.marginBottom]}
        >
          U heeft zich uitgeschreven als lid van de coöperatie Nh1816. U kunt daarom geen berichtgeving ontvangen over ledenvoordelen. Wilt u wel informatie over ledenvoordelen ontvangen? Schakel uw lidmaatschap dan weer in via de instellingen onderaan deze pagina.
        </Text>

        <ButtonOutline
          title='Sluiten'
          onPress={this.onPressBenefitsWithoutMembershipOk}
        />
      </Modal>
    )
  }

  renderModal = () => {
    const { showConfirmChangesModal, modalContent, revertChanges } = this.state

    return (
      <Modal visible={showConfirmChangesModal}>
        <Text
          style={styles.modalTitle}
          type='section'
        >
          {modalContent.title}
        </Text>

        <Text
          type='description'
          style={[Styling.textCenter, Styling.marginBottom]}
        >
          {modalContent.question}
        </Text>

        <Button
          title={modalContent.confirmButtonText}
          style={Styling.marginBottom}
          onPress={this.onPressSubmit}
        />

        <ButtonOutline
          title={modalContent.cancelButtonText}
          onPress={revertChanges}
        />
      </Modal>
    )
  }

  renderContent = (descriptionStyle: any, renderHeader: any, renderItem: any) => {
    const { user } = this.props
    const mainUser = user && user.mainUser

    return (
      <>
        {this.renderModal()}
        {this.renderModalMembershipNotActive()}
        <Bootstrap.Row
          gutterHorizontal={60}
          gutterVertical={0}
        >
          <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 6, xs: 12 }}>
            {renderHeader(i18n.t('Pushberichten'))}
            {this.renderPushNotificationWarning(descriptionStyle)}
            <Text
              style={descriptionStyle}
              type='description'
            >
              {i18n.t(
                'Geef hieronder per onderwerp aan of u hierover wél of geen pushberichten wil ontvangen. Berichten worden te allen tijde in uw inbox geplaatst.',
              )}
            </Text>
            {renderItem(
              i18n.t('Berichten over verzekeringen'),
              this.onChangeInsuranceMessages,
              this.state.insuranceMessages,
              i18n.t('(zoals polisvoorwaarden, prolongatie en schade)'),
            )}
            {renderItem(
              i18n.t('Financiële berichten'),
              this.onChangeFinancialMessages,
              this.state.financialMessages,
              i18n.t('(zoals betalingen & betalingsherinneringen)'),
            )}
            {renderItem(
              i18n.t('Informatieve berichten'),
              this.onChangeInformativeMessages,
              this.state.informativeMessages,
              i18n.t('(zoals preventietips & stormmeldingen)'),
            )}
            {renderItem(
              i18n.t('Ledenvoordelen'),
              this.onChangeMemberBenefits,
              this.state.memberBenefits,
              i18n.t('(zoals kortingsacties & evenementen)')
            )}
          </Bootstrap.Column>

          {mainUser && (
            <>
              <Bootstrap.Fragment
                hidden={{
                  lg: false,
                  md: true,
                  sm: true,
                  xl: false,
                  xs: true,
                  xxs: true,
                }}
              >
                <Line
                  vertical
                  color={Colors.background}
                  style={styles.line}
                  width={1}
                />
              </Bootstrap.Fragment>
              <Bootstrap.Column
                span={{ lg: 6, md: 12, sm: 12, xl: 6, xs: 12 }}
                style={{ lg: Styling.noMargin, sm: Styling.marginTopMedium }}
              >
                {renderHeader(i18n.t('Overig'))}
                <Html
                  html={this.props.aboutMembership}
                  style={descriptionStyle}
                />
                {renderItem(i18n.t('Lidmaatschap'), this.onChangeCooperationMembership, this.state.cooperationMembership)}
                {renderItem(
                  i18n.t('Zest Magazine ontvangen'),
                  this.onChangeZestMagazine,
                  this.state.zestMagazine,
                  '',
                  !this.state.cooperationMembership,
                )}
              </Bootstrap.Column>
            </>
          )}
        </Bootstrap.Row>
      </>
    )
  }

  renderContentPhone = () => this.renderContent(styles.description, this.renderHeaderPhone, this.renderItemPhone)

  renderContentTablet = () => this.renderContent(Styling.marginBottom, this.renderHeaderTablet, this.renderItemTablet)

  renderBreadCrumbs = (props: any) => (
    <>
      <BreadCrumbs {...props} />
      <Line
        horizontal
        color={Colors.background}
        height={1}
        style={Styling.marginTop}
      />
    </>
  )

  renderLoader () {
    return <OverlaySpinner visible />
  }

  renderPhone = () => (
    <>
      <OnFocus onFocus={this.handleFocus} />
      <Screen
        noPadding
        white
        renderBreadCrumbs={this.renderBreadCrumbs}
        useRefreshControl
        onRefresh={this.refresh}
      >
        {this.state.loading ? this.renderLoader() : this.renderContentPhone()}
      </Screen>
    </>
  )

  renderTablet = () => (
    <Screen>
      <Block noFlex>{this.state.loading ? this.renderLoader() : this.renderContentTablet()}</Block>
    </Screen>
  )

  render () {
    return (
      <Bootstrap.Switch
        renderPhone={this.renderPhone}
        renderTablet={this.renderTablet}
      />
    )
  }
}

const itemContainerStyle = {
  alignItems: 'center',
  backgroundColor: Colors.white,
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingVertical: Sizes.spacingVertical,
}

const styles = StyleSheet.create({
  description: {
    marginHorizontal: Sizes.spacingHorizontalMedium,
    marginTop: Sizes.spacingVertical,
  },
  headerContainerPhone: {
    backgroundColor: Colors.fb,
    paddingHorizontal: Sizes.spacingHorizontalMedium,
    paddingVertical: Sizes.spacingVertical,
  },

  headerContainerTablet: {
    marginBottom: Sizes.spacingVertical,
  },

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"center" ... Remove this comment to see the full error message
  itemContainerPhone: {
    ...itemContainerStyle,
    paddingHorizontal: Sizes.spacingHorizontalMedium,
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"center" ... Remove this comment to see the full error message
  itemContainerTablet: itemContainerStyle,
  line: {
    bottom: 0,
    left: '50%',
    position: 'absolute',
    top: 0,
  },
  modalTitle: {
    textAlign: 'center',
    marginBottom: Sizes.spacingVertical,
  },
})

const mapStateToProps = (state: TState) => ({
  aboutMembership: selectors.getAboutMembership(state),
  relation: selectors.getCurrentRelation(state)!,
  relationId: selectors.getCurrentRelationId(state)!,
  user: selectors.getUser(state),
  userId: selectors.getUserId(state),
})

const mapDispatchToProps = {
  updateRelation,
  updateUser,
  apiCallDispatch,
}

export default connect(mapStateToProps, mapDispatchToProps)(withApiCall(Settings))
