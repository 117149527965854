import React from 'react'
import type { GestureResponderEvent, StyleProp, TextStyle } from 'react-native'

import TouchableText from '@/components/touchables/TouchableText'
import { useLinkTo } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import { IS_WEB } from '@/constants/platform'

interface TComponentProps {
  onPress?: (event: GestureResponderEvent) => void
  style?: StyleProp<TextStyle>
  type: string
}

interface TAProps {
  Component?: React.ComponentType<TComponentProps>
  children?: React.ReactNode
  onPress?: (event: GestureResponderEvent, onPress: () => void) => void
  href: string
  style?: StyleProp<TextStyle>
  target?: string
  type?: string
}

export default function A (props: TAProps) {
  const linkTo = useLinkTo()
  const TextComponent = props.Component ?? TouchableText

  const openPath = () => {
    const { path, hostname } = Linking.parse(props.href)
    if (path !== null && hostname !== null && hostname?.endsWith('mijn-polissen.nl')) {
      linkTo(`/${path}`)
    } else {
      if (IS_WEB) {
        window.open(props.href)
      } else {
        Linking.openURL(props.href)
      }
    }
  }

  function onPress () {
    openPath()
  }

  return (
    <TextComponent
      onPress={
        typeof props.onPress === 'function' ? (event: GestureResponderEvent) => props.onPress!(event, onPress) : onPress
      }
      style={props.style}
      type={props.type ?? 'description'}
    >
      {props.children}
    </TextComponent>
  )
}
