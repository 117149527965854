import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ButtonForm from '@/components/buttons/ButtonForm'
import Form from '@/components/Form'
import Input from '@/components/inputs/Input'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import { updatePassword, updateToken } from '@/redux/actions/user'
import * as sessionsApi from '@/redux/api/sessions'
import selectors from '@/redux/selectors'
import apiWrapper from '@/utils/api_wrapper'
import common from '@/utils/common'
import i18n from '@/utils/i18n'
import toast from '@/utils/toast'

const PasswordForm: React.FC = () => {
  const dispatch = useDispatch()

  const email = useSelector(selectors.getEmail)
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const submit = async () => {
    setLoading(true)

    const result = await sessionsApi.updatePassword(email, currentPassword, newPassword, newPasswordRepeat)

    if (result?.data) {
      dispatch(updatePassword(newPassword))
      dispatch(updateToken(result.data.token))
      apiWrapper.setUserParams({ token: result.data.token })

      setCurrentPassword('')
      setNewPassword('')
      setNewPasswordRepeat('')
    }

    toast(result?.message || i18n.t('Het wachtwoord kon niet worden gewijzigd'))

    setLoading(false)
  }

  const result = common.checkPasswords(newPassword, newPasswordRepeat)
  const disabled = loading || !currentPassword || result.invalidPassword || result.invalidPasswordRepeat
  const invalidLabel = newPassword ? 'labelError' : 'label'

  return (
    <Form style={Styling.paddingMedium}>
      <Input
        secureTextEntry
        autoCapitalize='none'
        icon='key'
        label={i18n.t('Huidig wachtwoord')}
        onChange={setCurrentPassword}
        value={currentPassword}
      />

      <Text
        style={Styling.marginBottomTiny}
        type='label'
      >
        {i18n.t('Bedenk een nieuw veilig wachtwoord dat voldoet aan de volgende eisen')}
      </Text>

      <Text type={result.validLength ? 'labelSuccess' : invalidLabel}>• {i18n.t('Minimaal 8 karakters')}</Text>

      <Text type={result.validUppercase ? 'labelSuccess' : invalidLabel}>• {i18n.t('Minimaal 1 hoofdletter')}</Text>

      <Text type={result.validLowercase ? 'labelSuccess' : invalidLabel}>• {i18n.t('Minimaal 1 kleine letter')}</Text>

      <Text type={result.validDigit ? 'labelSuccess' : invalidLabel}>• {i18n.t('Minimaal 1 cijfer')}</Text>

      <Text
        style={Styling.marginBottom}
        type={result.validSpecialChar ? 'labelSuccess' : invalidLabel}
      >
        • {i18n.t('Minimaal 1 speciaal teken')}
      </Text>

      <Input
        secureTextEntry
        autoCapitalize='none'
        icon='key'
        label={i18n.t('Nieuw wachtwoord')}
        onChange={setNewPassword}
        success={!result.invalidPassword}
        value={newPassword}
      />

      <Input
        secureTextEntry
        autoCapitalize='none'
        error={result.errorPasswordRepeat}
        errorDescription={i18n.t('Beide wachtwoorden moeten gelijk zijn aan elkaar')}
        icon='key'
        label={i18n.t('Herhaal nieuw wachtwoord')}
        onChange={setNewPasswordRepeat}
        success={result.successPasswordRepeat}
        value={newPasswordRepeat}
      />

      <ButtonForm
        color={Colors.primary}
        disabled={disabled}
        loading={loading}
        onPress={submit}
        title={i18n.t('Opslaan')}
      />
    </Form>
  )
}

export default PasswordForm
