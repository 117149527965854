import React from 'react'
import { View, StyleSheet } from 'react-native'
import { useDispatch } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Actions from '@/components/Actions'
import Amount from '@/components/Amount'
import Attributes from '@/components/Attributes'
import EmptyState from '@/components/EmptyState'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import { openPolicyInsuranceCard } from '@/redux/actions/files'
import ArrowToggle from '@/screens/policies/components/ArrowToggle'
import ItemPolicy from '@/screens/policies/components/ItemPolicy'
import ButtonCircle from '@/components/buttons/ButtonCircle'
import AddPolicyTablet from '@/screens/policies/components/AddPolicyTablet'
import IllustrationPolicies from '@/svgs/IllustrationPolicies'
import type { InsuranceCard, Policy } from '@/types/objects'
import common from '@/utils/common'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import prototype from '@/utils/prototype'

import type { IChildComponentProps } from './Policies'

const PoliciesTablet: React.FC<IChildComponentProps> = (props) => {
  const dispatch = useDispatch()

  const openInsuranceCard = (policy: Policy, insuranceCard: InsuranceCard) => {
    dispatch(openPolicyInsuranceCard(policy, insuranceCard))
  }

  const renderItem = ({ item: policy }: { item: Policy }) => (
    <ItemPolicy
      onPress={navigation.openScreenPolicy}
      openPolicyInsuranceCard={openInsuranceCard}
      openScreenPolicyCondition={navigation.openScreenPolicyCondition}
      policy={policy}
      relation={props.relation}
    />
  )

  const renderEmptyState = () => (
    <EmptyState
      message={props.refreshing
        ? i18n.t('De polissen aan het ophalen')
        : i18n.t('U heeft nog geen polissen')}
    />
  )

  return (
    <Screen
      useRefreshControl
      refreshing={props.refreshing}
      onRefresh={props.onRefresh}
      scrollViewRef={props.scrollViewRef}
    >
      <Bootstrap.Row
        gutterHorizontal={30}
        gutterVertical={30}
      >
        <Bootstrap.Column
          offsetRight={{ lg: 0, md: 0, sm: 0, xl: 1, xs: 0, xxl: 2, xxxl: 2 }}
          span={{ lg: 8, md: 12, sm: 12, xl: 6, xs: 12, xxl: 6, xxxl: 6 }}
        >
          <View style={Styling.rowCenter}>
            <View style={Styling.autoRight}>
              <Text type='title'>{i18n.t('Mijn pakket')}</Text>
              <Text type='paragraphLight'>
                {i18n.t('Combinummer')}: {props.relation?.combiNumber}
              </Text>
            </View>
            <View style={Styling.itemsEnd}>
              <Amount
                currency
                color={props.colors?.brand}
                type='title'
              >
                {props.relation?.combiCollectionAmount}
              </Amount>
              <Text
                style={Styling.marginLeftTiny}
                type='description'
              >
                {common.determinePaymentPeriod(props.relation?.paymentPeriod)}
              </Text>
            </View>
          </View>
          <View style={Styling.marginVerticalMedium}>
            <Attributes
              attributes={[
                {
                  description: props.relation?.combiStartingDate,
                  title: i18n.t('Ingangsdatum')
                },
                {
                  description: props.relation?.combiContractExpirationDate,
                  title: i18n.t('Vervaldatum')
                }
              ]}
              id='policies-tablet-attributes-1'
              span={{ lg: 4, md: 4, sm: 6, xl: 3, xs: 6 }}
            />
          </View>
          <Attributes
            attributes={[
              {
                description: props.relation?.combiNumberOfPolicies || 0,
                title: i18n.t('Aantal actieve polissen')
              },
              {
                description:
                  prototype.isNumber(props.relation?.combiPackageDiscount) &&
                  `${props.relation?.combiPackageDiscount}%`,
                title: i18n.t('Combikorting')
              }
            ].concat(
              props.relation?.paymentMethod === 'Automatisch' || props.relation?.paymentMethod === 'Factuur'
                ? { description: props.relation.paymentMethod, title: i18n.t('Betaalmethode') }
                : []
            )}
            id='policies-tablet-attributes-2'
            span={{ lg: 4, md: 4, sm: 6, xl: 3, xs: 6 }}
          />
          <Actions
            labelRight={i18n.t('Bekijk de combipolis')}
            onPressRight={navigation.openScreenCombiPolicy}
            labelDown={i18n.t('Download het polisblad')}
            onPressDown={navigation.openScreenPolicyStatement}
            style={Styling.marginTop}
          />
        </Bootstrap.Column>
        <Bootstrap.Column span={{ lg: 4, md: 0, sm: 0, xl: 5, xs: 0, xxl: 4, xxxl: 4 }}>
          <IllustrationPolicies
            backgroundColor='transparent'
            color={props.colors?.brand}
            style={Styling.autoVertical}
          />
        </Bootstrap.Column>
        <Bootstrap.Column span={{ lg: 12, md: 12, sm: 12, xl: 12, xs: 12, xxl: 12, xxxl: 12 }}>
          <View style={styles.addButtonContainer}>
            <ButtonCircle
              icon='plus'
              color={props.colors?.brand}
              onPress={navigation.openScreenAddPolicy}
            />
          </View>
        </Bootstrap.Column>
        <Bootstrap.Column>
          {props.policiesOverview?.length
            ? (
              <Bootstrap.Collection
                data={props.policiesOverview}
                gutter={30}
                id='policies-overview-tablet-collection'
                keyExtractor={props.keyExtractor}
                margin={0}
                renderItem={renderItem}
                span={{ lg: 12, md: 12, sm: 12, xl: 12, xs: 12 }}
              />
              )
            : null}

          {props.policiesExpelled?.length
            ? (
              <ArrowToggle
                onToggle={props.showExpelledPolicies}
                style={props.policiesOverview?.length ? Styling.marginTopMedium : null}
                text={i18n.t('Toon de beëindigde polissen')}
              >
                <Bootstrap.Collection
                  data={props.policiesExpelled}
                  gutter={30}
                  id='policies-expelled-tablet-collection'
                  keyExtractor={props.keyExtractor}
                  margin={0}
                  renderItem={renderItem}
                  span={{ lg: 12, md: 12, sm: 12, xl: 12, xs: 12 }}
                  style={Styling.marginTopMedium}
                />
              </ArrowToggle>
              )
            : null}

          {(!props.policiesOverview?.length && !props.policiesExpelled?.length)
            ? renderEmptyState()
            : null}
          <AddPolicyTablet onPress={navigation.openScreenAddPolicy} />
        </Bootstrap.Column>
      </Bootstrap.Row>
    </Screen>
  )
}

const styles = StyleSheet.create({
  addButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center'
  }
})

export default PoliciesTablet
