import humps from 'humps'

type ArrayOfDicts = Record<string, any>[]

type DictOfDicts = Record<string, Record<string, any>>

class Prototype {
  sleep = async (time = 0) => await new Promise((resolve) => setTimeout(resolve, time))

  toCamelCase = (data: unknown) => humps.camelizeKeys(data)

  toSnakeCase = (data: unknown) => humps.decamelizeKeys(data)

  sortNumericalAsc = <T extends Record<string, any>>(list: T[], attribute: string) => {
    if (Array.isArray(list)) {
      return list.sort((a, b) => {
        const numberA = Number(a[attribute])
        const numberB = Number(b[attribute])

        if (Number.isNaN(numberA) || !Number.isFinite(numberA)) {
          return 1
        }

        if (Number.isNaN(numberB) || !Number.isFinite(numberB)) {
          return -1
        }

        return numberA - numberB
      })
    }

    return list
  }

  isObject = (e: any): boolean => (e === null ? false : typeof e === 'object')

  isBoolean = (e: any): boolean => typeof e === 'boolean'

  isNumber = (n: any): boolean => !Number.isNaN(Number(n))

  isInteger = Number.isInteger

  dictToArray = (dict: object): any[] => (dict && Object.values(dict)) || []

  arrayToDict = <T extends Record<string, any>>(array: T[] = [], key: string = ''): Record<string, T> =>
    array?.reduce<T>((dict, entity) => {
      // eslint-disable-next-line no-param-reassign
      dict[entity[key]] = entity
      return dict
    }, {})

  /*
    array1 = [{ 'id': 1, a: 'a', c: 'c' }, { 'id': 2, d: 'd' }]
    array2 = [{ 'id': 1, a: 'a2', b: 'b' }, { 'id': 3, e: 'e' }]
    result = [{ 'id': 1, a: 'a2', b: 'b', c: 'c' }, { 'id': 3, e: 'e' }]
  */
  mergeArraysOfDicts = (array1: ArrayOfDicts = [], array2: ArrayOfDicts = []): ArrayOfDicts => {
    const dict1 = (array1 || [])?.reduce<ArrayOfDicts>((dict, item) => {
      // eslint-disable-next-line no-param-reassign
      dict[Number(item.id)] = item
      return dict
    }, {})

    return (array2 || []).map((item) => ({
      ...(dict1[Number(item.id)] || {}),
      ...item
    }))
  }

  mergeDictsOfDicts = (dict1: DictOfDicts = {}, dict2: DictOfDicts = {}): DictOfDicts => {
    const newDict1 = { ...dict1 }
    Object.entries(dict2).forEach(([key, value]) => {
      if (key in newDict1) {
        newDict1[key] = {
          ...newDict1[key],
          ...value
        }
      } else {
        newDict1[key] = value
      }
    })
    return newDict1
  }

  capitalizeFirstLetter = (word: string): string => word?.charAt(0)?.toUpperCase() + word?.slice(1)

  toMoneyVisual = (money: string, includeSign = false): string => {
    if (!money) {
      return ''
    }

    const parsed = parseFloat(money.replace(',', '.')).toFixed(2).replace('.', ',')

    return `${includeSign ? '€' : ''} ${parsed}`
  }

  toMoneyPractical = (money: string): string => {
    if (!money) {
      return ''
    }

    return parseFloat(money.replace(',', '.')).toFixed(2).replace(',', '.')
  }

  mergeObjectInArray = <T extends Record<string, any>>(array: T[] = [], object = {} as T, comparer: keyof T = 'id') => {
    const itemMatches = (item: T) => item[comparer]?.toString() === object[comparer]?.toString()

    if (array?.find(itemMatches)) {
      return array?.map((item) => {
        if (itemMatches(item)) {
          return {
            ...item,
            ...object
          }
        }

        return item
      })
    }

    return [object].concat(array)
  }

  mergeArrays = <T extends Record<string, any>>(oldArray: T[] = [], newArray: T[] = [], comparer: keyof T = 'id') =>
    newArray?.map((item) => {
      const oldItem = oldArray.find(
        (x) => x[comparer]?.toString() === item[comparer]?.toString()
      )

      if (oldItem) {
        return {
          ...oldItem,
          ...item
        }
      }

      return item
    })
}

export default new Prototype()
