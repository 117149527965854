import React from 'react'
import { StyleSheet, View } from 'react-native'

import Button from '@/components/buttons/Button'
import Image from '@/components/images/image'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import type { IMemberBenefit } from '@/types/objects'
import navigation from '@/utils/navigation'

const MemberBenefitListItem: React.FC<IMemberBenefit> = (memberBenefit) => {
  const openBenefit = (): void => {
    navigation.openScreenMemberBenefitsDetail(memberBenefit?.id)
  }

  return (
    <View
      key={memberBenefit.id}
      style={styles.container}
    >
      <Image
        style={styles.image}
        uri={memberBenefit?.image}
      />

      <View style={styles.innerContainer}>
        <Text type='descriptionBold'>{memberBenefit?.title}</Text>

        <Text type='descriptionLight'>{memberBenefit?.intro}</Text>

        <Button
          onPress={openBenefit}
          style={styles.button}
          title='Bekijken'
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    marginTop: 15
  },

  container: {
    backgroundColor: Colors.white,

    borderRadius: 5,
    flexDirection: 'column',
    marginBottom: 25,
    overflow: 'hidden'
  },

  image: {
    height: 200,
    width: '100%'
  },

  innerContainer: {
    padding: 25,
    paddingTop: 10
  }
})

export default MemberBenefitListItem
