import type { Policy, PolicyDetail } from '@/types/objects'
import { PolicyDetailType } from '@/types/objects'

export const headerAttributes = (policy: Policy): PolicyDetail[] =>
  Object.values(policy?.details || []).filter((detail) => detail.important && detail.value)

export const detailAttributes = (policy: Policy): PolicyDetail[] =>
  Object.values(policy?.details || [])
    .filter((detail) => !detail.important && detail.value)
    .filter(
      (detail) => detail.type === PolicyDetailType.attribute || detail.type === PolicyDetailType.acceptanceQuestion
    )

export const coverageAttributes = (policy: Policy): PolicyDetail[] =>
  Object.values(policy?.details || [])
    .filter((detail) => !detail.important)
    .filter((detail) => detail.type === PolicyDetailType.coverage)
