import React from 'react'
import type { ImageStyle } from 'react-native'
import { Image as RNImage, StyleSheet } from 'react-native'

interface IProps {
  uri: string
  style?: ImageStyle
}

const Image: React.FC<IProps> = (props) => {
  return (
    <RNImage
      source={{ uri: props.uri }}
      style={props.style ?? styles.image}
    />
  )

  // may a loading state for a picture be wanted

  // const [loading, setLoading] = useState<boolean>(true)
  // return (
  //   <ImageBackground
  //     onLoadEnd={() => setLoading(false)}
  //     onLoadStart={() => setLoading(true)}
  //     source={{ uri: props.uri }}
  //     style={props.style || styles.image}
  //   >
  //     {loading ? (
  //       <View style={styles.loading}>
  //         <ActivityIndicator color={Colors.primary} size={Sizes.iconLarge} />
  //       </View>
  //     ) : null}
  //   </ImageBackground>
  // )
}

const styles = StyleSheet.create({
  image: {
    height: 100,
    width: 100
  },
  loading: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  }
})

export default Image
