import React from 'react'
import type { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'

import Icon from '@/components/Icon'
import Line from '@/components/Line'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import mapStateToProps from '@/redux/mapStateToProps'
import type { IconProp } from '@/types/props'

interface TItemSidebarProps {
  active?: boolean
  colors: any
  icon?: IconProp
  iconColor?: string
  iconSize?: number
  iconStyle?: StyleProp<ViewStyle>
  onPress: (...args: any[]) => any
  textStyle?: StyleProp<TextStyle>
  textType?: string
  title: string
}

function ItemSidebar (props: TItemSidebarProps) {
  let iconColor = Colors.lightBrown
  if (props.iconColor) {
    iconColor = props.iconColor
  } else if (props.active) {
    iconColor = props.colors?.brand
  }
  return (
    <>
      <TouchableOpacity
        onPress={props.onPress}
        style={props.active ? styles.containerActive : styles.container}
      >
        <Line
          vertical
          color={props.active ? props.colors?.brand : Colors.white}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
          height={null}
          style={styles.line}
          width={5}
        />
        <Icon
          color={iconColor}
          icon={props.icon}
          size={props.iconSize || Sizes.sidebarIcon}
          style={props.iconStyle}
        />
        <Text
          noLineHeight
          style={props.textStyle || Styling.marginLeft}
          type={props.textType || 'description'}
        >
          {props.title}
        </Text>
      </TouchableOpacity>
      <Line
        horizontal
        color={Colors.background}
        height={1}
      />
    </>
  )
}

const containerStyle = {
  alignItems: 'center',
  backgroundColor: Colors.white,
  flexDirection: 'row',
  paddingHorizontal: Sizes.spacingHorizontalMedium,
  paddingVertical: Sizes.spacingVerticalNormal,
  position: 'relative'
}

const styles = StyleSheet.create({
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"center" ... Remove this comment to see the full error message
  container: containerStyle,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"center" ... Remove this comment to see the full error message
  containerActive: {
    ...containerStyle,
    backgroundColor: Colors.sidebar
  },
  line: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    top: 0
  }
})

export default connect(mapStateToProps.themeColors)(ItemSidebar)
