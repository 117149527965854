import React, { useContext } from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

import ContextBreakpoint from '@/bootstrap/ContextBreakpoint'
import ButtonNext from '@/components/buttons/ButtonNext'
import ButtonPrevious from '@/components/buttons/ButtonPrevious'

interface TButtonsPreviousNextProps {
  nextDisabled?: boolean
  nextStyle?: StyleProp<ViewStyle>
  onNext?: (...args: any[]) => any
  onPrevious?: (...args: any[]) => any
  previousDisabled?: boolean
  previousStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
}

export default function ButtonsPreviousNext (props: TButtonsPreviousNextProps) {
  const breakpoint = useContext(ContextBreakpoint)
  return (
    <View style={props.style ? [styles.container, props.style] : styles.container}>
      <ButtonPrevious
        disabled={props.previousDisabled}
        onPress={props.onPrevious}
        small={breakpoint === 'xxs'}
        style={props.previousStyle}
      />
      <ButtonNext
        disabled={props.nextDisabled}
        onPress={props.onNext}
        small={breakpoint === 'xxs'}
        style={props.nextStyle}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  }
})
