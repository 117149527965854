import type { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Attribute from '@/components/Attribute'
import Attributes from '@/components/Attributes'
import Block from '@/components/Block'
import Pill from '@/components/Pill'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import type { State } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import i18n from '@/utils/i18n'
import { getClaimConfig } from '@/utils/claims'

interface IProps extends StackScreenProps<any> {}

const HistoricalClaim: React.FC<IProps> = (props) => {
  const claim = useSelector((state) => selectors.getHistoricalClaim(state as State, props?.route?.params?.claimNumber))
  const config = getClaimConfig(claim?.status)
  const licensePlate = claim?.licensePlate
  const title = licensePlate ?? claim?.productName
  const description = licensePlate ? claim?.productName : ''

  const renderContent = () => (
    <>
      <View style={Styling.marginBottom}>
        <Bootstrap.Row
          gutterHorizontal={15}
          gutterVertical={5}
        >
          <Bootstrap.Column
            span={{ lg: 6, md: 6, sm: 12, xl: 6, xs: 12 }}
            style={Styling.columnStretchCenter}
          >
            {title ? <Text type='subsection'>{title}</Text> : null}

            {description ? <Text type='paragraphLight'>{description}</Text> : null}
          </Bootstrap.Column>

          <Bootstrap.Column
            span={{ lg: 6, md: 6, sm: 0, xl: 6, xs: 0, xxs: 0 }}
            style={Styling.rowCenter}
          >
            <Pill
              color={config.color}
              style={Styling.autoLeft}
              title={claim?.status}
            />
          </Bootstrap.Column>

          <Bootstrap.Column
            span={{ lg: 0, md: 0, sm: 12, xl: 0, xs: 12 }}
            style={Styling.rowCenter}
          >
            <Pill
              color={config.color}
              title={claim?.status}
            />
          </Bootstrap.Column>
        </Bootstrap.Row>
      </View>

      <Attributes
        attributes={[
          {
            description: claim?.claimDate,
            title: i18n.t('Schadedatum')
          },
          {
            description: claim?.claimNumber?.toString(),
            title: i18n.t('Schadenummer')
          },
          {
            description: claim?.claimType,
            title: i18n.t('Soort schade')
          },
          {
            description: claim?.policyNumber,
            title: i18n.t('Polisnummer')
          },
          {
            description: claim?.reportDate,
            title: i18n.t('Melddatum')
          },
          {
            description: claim?.finishDate,
            title: i18n.t('Afgerond op')
          }
        ].concat(claim?.licensePlate ? { description: claim?.licensePlate, title: i18n.t('Kenteken') } : [])}
        id='claim'
      />

      <Attribute
        noMargin
        description={config.description}
        style={Styling.marginTop}
        title={`${i18n.t('Toelichting status')}:`}
      />
    </>
  )

  const renderPhone = () => (
    <Screen
      noPadding
      white
    >
      <View style={styles.container}>{renderContent()}</View>
    </Screen>
  )

  const renderTablet = () => (
    <Screen>
      <Block noFlex>{renderContent()}</Block>
    </Screen>
  )

  return (
    <Bootstrap.Switch
      renderPhone={renderPhone}
      renderTablet={renderTablet}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingVertical: Sizes.screenPaddingVertical
  }
})

export default HistoricalClaim
