import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import PDFViewer from '../pdf_viewer'

import common from '@/utils/common'
import fs from '@/utils/fs'
import i18n from '@/utils/i18n'

import selectors from '@/redux/selectors'

import type { State as TState } from '@/redux/reducers'
import type { TNavigationProp } from '@/types/props'

interface TOwnProps {
  navigation: TNavigationProp
}

type TPolicyConditionProps = TOwnProps & {
  filename: string
  title: string
  url: string
}

function PolicyCondition (props: TPolicyConditionProps) {
  useEffect(() => {
    props.navigation.setOptions({ title: props.title.length >= 20 ? `${props.title.substring(0, 20)}...` : props.title })
  }, [props.navigation, props.title])

  return (
    <PDFViewer
      route={{
        params: {
          url: props.url,
          errorMessage: i18n.t('Het is niet gelukt om de polisvoorwaarden te downloaden'),
          fileName: props.filename,
          title: props.filename,
          key: `policy-condition-${props.filename}`
        }
      }}
    />
  )
}

const mapStateToProps = (state: TState, ownProps: TOwnProps) => {
  const conditionId = common.getNavigationParam(ownProps, 'conditionId')
  const policyId = common.getNavigationParam(ownProps, 'policyId')
  const condition = selectors.getPolicyCondition(state, policyId, conditionId)
  const filename = fs.determineFilenameCondition(condition)

  return {
    filename,
    title: condition?.description,
    url: condition?.url
  }
}

export default connect(mapStateToProps)(PolicyCondition)
