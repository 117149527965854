import React from 'react'

import BlockToggle from '@/components/BlockToggle'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import type { Policy } from '@/types/objects'
import i18n from '@/utils/i18n'

const ClausesPhone: React.FC<{ policy: Policy }> = ({ policy }) => {
  if (!policy?.clauses?.length) {
    return null
  }

  return (
    <>
      <Text
        style={Styling.marginTop}
        type='subsection'
      >
        {i18n.t('Clausules')}
      </Text>

      {policy.clauses.map((clause) => (
        <BlockToggle
          backgroundColor
          key={`clause-block-toggle-${clause.id}`}
          lowerText={clause.description}
          style={Styling.marginTop}
          upperText={clause.name}
        />
      ))}
    </>
  )
}

export default ClausesPhone
