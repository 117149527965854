import { type Purchase, type PurchaseInvoice, type PurchasePicture } from './objects'

export const convertPurchaseToPurchaseInvoice = (purchase: Purchase) => {
  const purchaseInvoice: PurchaseInvoice = {
    date: purchase.date,
    filename: purchase.invoiceFilename,
    mimeType: purchase.invoiceFileMimeType,
    name: purchase.name,
    price: purchase.price,
    invoice: purchase.invoice,
    purchaseId: purchase.id,
  }
  return purchaseInvoice
}

export const convertPurchaseToPurchasePicture = (purchase: Purchase) => {
  const purchasePicture: PurchasePicture = {
    date: purchase.date,
    filename: purchase.pictureFilename,
    mimeType: purchase.pictureFileMimeType,
    name: purchase.name,
    price: purchase.price,
    picture: purchase.picture,
    purchaseId: purchase.id,
  }
  return purchasePicture
}

export const convertPurchaseToEditPurchaseInvoice = (purchase: Purchase) => {
  if (purchase.invoice.startsWith('http')) {
    const purchaseInvoice: PurchaseInvoice = {
      date: purchase.date,
      name: purchase.name,
      price: purchase.price,
      purchaseId: purchase.id,
      filename: null,
      mimeType: null,
      invoice: null
    }
    return purchaseInvoice
  } else {
    return convertPurchaseToPurchaseInvoice(purchase)
  }
}

export const convertPurchaseToEditPurchasePicture = (purchase: Purchase) => {
  if (purchase.picture.startsWith('http')) {
    const purchasePicture: PurchasePicture = {
      date: purchase.date,
      name: purchase.name,
      price: purchase.price,
      purchaseId: purchase.id,
      filename: null,
      mimeType: null,
      picture: null
    }
    return purchasePicture
  } else {
    return convertPurchaseToPurchasePicture(purchase)
  }
}
