/* eslint-disable react/jsx-closing-tag-location */
import React from 'react'
import { useSelector } from 'react-redux'
import type { ActionSheetOptions, ActionSheetProps } from '@expo/react-native-action-sheet'
import { connectActionSheet } from '@expo/react-native-action-sheet'
import type { StyleProp, ViewStyle } from 'react-native'
import { ActivityIndicator, StyleSheet, TouchableOpacity, View } from 'react-native'

import Icon from '@/components/Icon'
import Line from '@/components/Line'
import Text from '@/components/wrappers/Text'

import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'

import fs from '@/utils/fs'

import { getThemeColors } from '@/redux/selectors/theme'
import { IS_WEB } from '@/constants/platform'

interface IProps {
  cancelButtonIndex?: ActionSheetOptions['cancelButtonIndex']
  file: any // @TODO generalize File type
  loading?: boolean
  onEdit?: (index?: number) => void
  onRemove: () => void
  options: ActionSheetOptions['options']
  style?: StyleProp<ViewStyle>
  title?: string
}

const ButtonFile: React.FC<IProps & ActionSheetProps> = (props) => {
  const colors = useSelector(getThemeColors)

  const onEdit = () => {
    if (props.onEdit) {
      if (!IS_WEB) {
        props?.showActionSheetWithOptions?.(
          {
            cancelButtonIndex: props.cancelButtonIndex,
            options: props.options
          },
          props.onEdit
        )
      } else {
        props.onEdit(1)
      }
    }
  }

  return (
    <View style={[styles.container, { borderColor: colors?.brand }, props.style]}>
      {props.loading
        ? <ActivityIndicator
            color={Colors.brown}
            style={styles.activityIndicator}
          />
        : null}

      <View style={styles.textContainer}>
        <Text
          color={colors?.brand}
          numberOfLines={1}
          type='button'
        >
          {fs.fileToFilename(props.file) || props.title}
        </Text>
      </View>

      {props?.onEdit
        ? <>
          <Line
            vertical
            color={Colors.cc}
          />

          <TouchableOpacity
            onPress={onEdit}
            style={styles.iconContainer}
            disabled={props.loading}
          >
            <Icon
              color={props.loading ? Colors.ed : colors?.brand}
              icon='pencil'
            />
          </TouchableOpacity>

          <Line
            vertical
            color={Colors.cc}
          />
        </>
        : null}
      <TouchableOpacity
        onPress={props.onRemove}
        style={styles.iconContainer}
        disabled={props.loading}
      >
        <Icon
          color={props.loading ? Colors.ed : colors?.brand}
          icon='trash'
        />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    marginLeft: Sizes.buttonPaddingHorizontal
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderColor: Colors.cc,
    borderRadius: Sizes.borderRadius,
    borderWidth: 1,
    flex: 1,
    flexDirection: 'row',
    minHeight: Sizes.button,
    paddingVertical: Sizes.buttonPaddingVertical
  },
  iconContainer: {
    height: '100%',
    justifyContent: 'center',
    paddingHorizontal: Sizes.buttonPaddingHorizontal
  },
  textContainer: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    paddingHorizontal: Sizes.buttonPaddingHorizontal
  }
})

export default connectActionSheet(ButtonFile)
