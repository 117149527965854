import { differenceInMinutes } from 'date-fns'
import { call, delay, put, select, spawn, takeLatest } from 'redux-saga/effects'

import * as loadingActions from '@/redux/actions/loading'
import { openModalSession } from '@/redux/actions/modals'
import * as userActions from '@/redux/actions/user'

import { NAVIGATED } from '@/redux/constants'

import selectors from '@/redux/selectors'

import type { User } from '@/types/objects'
import { IS_WEB } from '@/constants/platform'

function * watchTokenExpiry () {
  if (!__DEV__) {
    const loggedIn: boolean = yield select(selectors.isLoggedIn)
    const verified: boolean = yield select(selectors.isVerified)

    if (loggedIn && verified) {
      const { tokenExpiresAt }: User = yield select(selectors.getUser)
      const minutesRemaining = differenceInMinutes(new Date(tokenExpiresAt), new Date())

      if (minutesRemaining <= 0) {
        if (IS_WEB) {
          yield put(userActions.logout())
        } else {
          yield put(userActions.lock())
        }
      } else if (minutesRemaining <= 5) {
        const sessionsModalVisible: boolean = yield select(selectors.isVisibleModalSession)
        if (IS_WEB && !sessionsModalVisible) {
          yield put(openModalSession())
        }
      }
    }

    // Recursively call function
    yield delay(1000 * 60) // 60 seconds
    yield call(watchTokenExpiry)
  }
}

function * extendTokenOnNavigation () {
  const loggedIn: boolean = yield select(selectors.isLoggedIn)
  const verified: boolean = yield select(selectors.isVerified)

  if (loggedIn && verified) {
    const { tokenExtendedAt }: User = yield select(selectors.getUser)
    const minutesPassed = differenceInMinutes(new Date(), new Date(tokenExtendedAt))

    if (minutesPassed >= 5) {
      yield put(loadingActions.extendToken())
    }
  }
}

export function * defaultSaga () {
  yield spawn(watchTokenExpiry)
  yield takeLatest(NAVIGATED, extendTokenOnNavigation)
}
