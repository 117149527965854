import React from 'react'
import { useSelector } from 'react-redux'
import type { StyleProp, ViewStyle } from 'react-native'

import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

import Button from '@/components/buttons/Button'

import Colors from '@/constants/Colors'

import selectors from '@/redux/selectors'

interface IProps {
  id?: string
  style?: StyleProp<ViewStyle>
}

const ButtonGreenCard: React.FC<IProps> = (props) => {
  const colors = useSelector(selectors.getThemeColors)
  const hasAccessToGreenCardScreen = useSelector(selectors.selectHasAccessToGreenCardsScreen)

  const onPress = async () => {
    await navigation.openScreenGreenCards()
  }

  if (!hasAccessToGreenCardScreen) {
    return null
  }

  return (
    <Button
      color={Colors.white}
      icon='address-card'
      iconCircleColor={colors?.lightBrand}
      iconColor={colors?.brand}
      id={props.id}
      onPress={onPress}
      style={props.style}
      title={i18n.t('Verzekeringsbewijzen')}
      titleColor={Colors.gray}
    />
  )
}

export default ButtonGreenCard
