import React from 'react'
import { View } from 'react-native'

import Amount from '@/components/Amount'
import Icon from '@/components/Icon'
import ListItem from '@/components/list_items/ListItem'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import type { Payment } from '@/types/objects'
import data from '@/utils/data'

interface IProps {
  payment: Payment
}

const ListItemPayment: React.FC<IProps> = (props) => {
  const payment = props.payment || {}
  const config = data.getConfigPayment(payment)

  return (
    <ListItem
      column
      item={props.payment}
    >
      <View style={[Styling.rowBetween, Styling.marginBottomSmall]}>
        <Text
          style={Styling.flex}
          type='span'
        >
          {payment.invoiceType === 'Prolongatie' ? 'Betaling' : payment.invoiceType}
        </Text>
        <View style={Styling.rowCenter}>
          <Text
            color={config.color}
            style={Styling.marginLeftSmall}
            type='span'
          >
            {payment.outstanding ? 'Open' : 'Betaald'}
          </Text>
          <Amount
            currency
            style={Styling.marginLeftSmall}
            type='span'
          >
            {payment.collectionAmount}
          </Amount>
        </View>
      </View>
      <View style={Styling.rowBetween}>
        <View style={Styling.rowCenter}>
          <Icon
            color={Colors.c1}
            icon={['fal', 'calendar']}
            style={Styling.marginRightTiny}
          />
          <Text
            color={Colors.c1}
            type='span'
          >
            {payment.bookDate}
          </Text>
        </View>
      </View>
    </ListItem>
  )
}

export default ListItemPayment
