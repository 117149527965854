import { format } from 'date-fns'
import { put, select, takeLatest } from 'redux-saga/effects'

import { DATE_TIME_FORMAT } from '@/constants/date_time'
import { resetState } from '@/redux/actions/index'
import { apiCall, apiCallSuccess } from '@/redux/actions/api'
import { closeModalSession } from '@/redux/actions/modals'
import * as ACTIONS from '@/redux/actions/user'
import callApi from '@/redux/api'
import * as sessionsApi from '@/redux/api/sessions'
import * as api from '@/redux/api/user'
import * as LOADING_CONSTANTS from '@/redux/constants/loading'
import * as CONSTANTS from '@/redux/constants/user'
import * as SELECTORS from '@/redux/selectors/user'
import type { User } from '@/types/objects'

import type { IApiResponse } from '@/utils/api_wrapper'
import apiWrapper from '@/utils/api_wrapper'

import selectors from '../selectors'
import { ENTITIES } from '../constants'
import { IS_WEB } from '@/constants/platform'

function * lock () {
  // Cancel all running api requests to prevent network requests from being completed while the application is locked.
  apiWrapper.cancelRequests()

  yield put(closeModalSession())
  yield put(ACTIONS.resetToken())
}

function * logout () {
  // Cancel all running api requests to prevent data from changing after resetting the state.
  apiWrapper.cancelRequests()

  // Calling the api function directly is intentionally.
  sessionsApi.logout()
  apiWrapper.reset()

  yield put(closeModalSession())
  yield put(resetState())
}

export function * requestUser (action) {
  const userRemote: IApiResponse<User> = yield callApi(api.getUser, action?.relationId, action?.userId)
  const userLocal: User = yield select(SELECTORS.getUser)

  if (userRemote.success) {
    // After completing the setup there is a chance the first name is not yet saved remotely.
    const user = {
      ...userRemote.data,
      firstName: userRemote?.data?.firstName || userLocal.firstName
    }

    yield put(ACTIONS.updateUser(user))
  }
}

export function * extendToken () {
  const user: User = yield select(SELECTORS.getUser)

  if (user?.token && user?.email) {
    const response: IApiResponse<{ result: boolean, tokenExpiresAt: string }> = yield callApi(
      sessionsApi.extendToken,
      user.token,
      user.email
    )

    if (response.success) {
      yield put(ACTIONS.updateTokenExpiresAt(response.data?.tokenExpiresAt))
      yield put(ACTIONS.updateTokenExtendedAt(format(new Date(), DATE_TIME_FORMAT)))
      yield put({ type: LOADING_CONSTANTS.EXTENDED_TOKEN })

      const sessionsModalVisible: boolean = yield select(selectors.isVisibleModalSession)
      if (sessionsModalVisible) {
        yield put(closeModalSession())
      }
    } else {
      if (IS_WEB) {
        yield put(ACTIONS.logout())
      } else {
        yield put(ACTIONS.lock())
      }
    }
  }
}

function updateToken (action: ReturnType<typeof ACTIONS.updateToken>) {
  apiWrapper.setUserParams({
    token: action.token
  })
}

function * signInSucces ({ payload }: ReturnType<typeof ACTIONS.signInSucces>) {
  apiWrapper.setUserParams({
    userId: payload.user?.id,
    token: payload.user?.token
  })

  if (payload.user) {
    yield put(
      ACTIONS.loggedIn({
        ...payload.user,
        tokenExtendedAt: format(new Date(), DATE_TIME_FORMAT)
      })
    )
  }

  if (payload.terms) {
    yield put(apiCallSuccess('GET', ENTITIES.TERMS_OF_USE, undefined, payload.terms?.termsOfUse))
  }

  yield put(apiCallSuccess('GET', ENTITIES.RELATIONS, undefined, payload.relations))
  yield put(apiCall('GET', ENTITIES.NOTIFICATIONS))
}

export function * userSaga () {
  yield takeLatest(CONSTANTS.LOCK, lock)
  yield takeLatest('REQUEST_USER', requestUser)
  yield takeLatest(CONSTANTS.LOGOUT, logout)
  yield takeLatest(LOADING_CONSTANTS.EXTEND_TOKEN, extendToken)
  yield takeLatest(CONSTANTS.UPDATE_TOKEN, updateToken)

  yield takeLatest(CONSTANTS.SIGN_IN_SUCCESS, signInSucces)
}
