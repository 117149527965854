import * as CONSTANTS from '@/redux/constants/relations'
import type { Relation } from '@/types/objects'
import type * as TYPES from '@/redux/types/relations'

export const updateRelation = (relation: Relation): TYPES.UpdateRelationAction => ({
  relation,
  type: CONSTANTS.UPDATE_RELATION
})

export const forceSwitchRelation = (): TYPES.ForceSwitchRelation => {
  return {
    type: CONSTANTS.FORCE_SWITCH_RELATION
  }
}

export const switchRelationSuccess = (): TYPES.ForceSwitchRelation => {
  return {
    type: CONSTANTS.SWITCH_RELATION_SUCCESS
  }
}
