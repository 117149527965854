const { hasOwnProperty } = Object.prototype

interface Breakpoints { [key: string]: number }

export default function getBreakpoint (breakpoints: Breakpoints, width: number): string {
  let breakpointName = ''
  let breakpointWidth = 0

  // eslint-disable-next-line no-restricted-syntax
  for (const name in breakpoints) {
    if (hasOwnProperty.call(breakpoints, name)) {
      const value = breakpoints[name]

      if (width >= value && value >= breakpointWidth) {
        breakpointName = name
        breakpointWidth = value
      }
    }
  }

  return breakpointName
}
