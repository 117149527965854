import React from 'react'

import type { TInputProps } from '@/components/inputs/Input'
import Input from '@/components/inputs/Input'
import prototype from '@/utils/prototype'

interface IProps extends TInputProps {
  currency?: boolean
}

const placeholder = parseFloat('0.001').toLocaleString('nl-NL').slice(0, -1)

const InputAmount: React.FC<IProps> = ({ currency, onChange, value, ...props }) => (
  <Input
    icon={currency ? 'euro-sign' : ''}
    keyboardType='numeric'
    onChange={(text) => onChange?.(text.replace(/[^0-9,.]/g, ''))}
    placeholder={placeholder}
    value={value?.replace('.', ',')}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    onBlur={() => {
      onChange?.(prototype.toMoneyPractical(value ?? ''))
    }}
  />
)

export default InputAmount
