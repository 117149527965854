import type { Agency } from '@/types/objects'
import api from '@/utils/api_wrapper'

export const getAgency = async (id: Agency['id']) => await api.get<Agency>(`agencies/${id}`)

export const getAgencyPrintout = async (id: Agency['id']) => await api.get<{
  coverImage: string
  id: string
  logo: string
  name: string
  primaryColor: string
  secondaryColor: string
}>(`agencies/${id}/printout`)
