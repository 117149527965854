import Text from '@/components/wrappers/Text'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import Button from '@/components/buttons/Button'
import Sizes from '@/constants/Sizes'
import type { GeneratedGreenCard, Policy } from '@/types/objects'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

import Styling from '@/constants/Styling'
import ClausesPhone from './clauses.phone'
import ConditionsPhone from './conditions.phone'
import InsuranceCardsPhone from './insurance_cards.phone'
import Actions from '@/components/Actions'
import { getIsPolicyPullable } from '@/redux/selectors/policies'

const MorePhone: React.FC<{ policy: Policy }> = ({ policy }) => {
  const hasGreenCard = !!policy.greenCard

  const openScreenGreenCard = () => navigation.openScreenGreenCard(policy)

  const openScreenGenerateGreenCard = async () => await navigation.openScreenGenerateGreenCard(policy)

  const openScreenGeneratedGreenCard = async (generatedGreenCard: GeneratedGreenCard) => {
    await navigation.openScreenGeneratedGreenCard(generatedGreenCard)
  }

  const isPullable = getIsPolicyPullable(policy)

  return (
    <View style={styles.tab}>
      {(hasGreenCard || isPullable || policy?.greenCards?.length > 0)
        ? (
          <Text
            style={Styling.marginBottom}
            type='subsection'
          >
            {i18n.t('Verzekeringsbewijzen')}
          </Text>
          )
        : null}

      {hasGreenCard
        ? (
          <Button
            onPress={openScreenGreenCard}
            title={i18n.t('Verzekeringsbewijzen')}
          />
          )
        : null}

      {isPullable
        ? (
          <Actions
            labelRight={i18n.t('Verzekeringsbewijs genereren')}
            onPressRight={openScreenGenerateGreenCard}
            style={Styling.marginBottom}
          />
          )
        : null}

      {policy.greenCards.map(generatedGreenCard => (
        <Button
          key={generatedGreenCard.policyIdPullingVehicle}
          onPress={async () => await openScreenGeneratedGreenCard(generatedGreenCard)}
          title={`Verzekeringsbewijs - ${generatedGreenCard.pullingVehicleLicensePlate}`}
          style={Styling.marginBottom}
        />
      ))}

      <InsuranceCardsPhone policy={policy} />

      <ConditionsPhone policy={policy} />

      <ClausesPhone policy={policy} />
    </View>
  )
}

const styles = StyleSheet.create({
  tab: {
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingVertical: Sizes.screenPaddingVertical
  }
})

export default MorePhone
