import * as CONSTANTS from '@/redux/constants/relationRepository'
import type { SelectRelationAction, UpdateRelationAction } from '@/redux/types/relations'
import type { Relation } from '@/types/objects'

export interface RelationRepositoryState {
  cache: any
  currentRelationId: string
  relations: Relation[]
  changeEmail: {
    error: boolean
    success: boolean
    loading: boolean
  }
}

export const initialState: RelationRepositoryState = {
  cache: {},
  changeEmail: {
    error: false,
    loading: false,
    success: false
  },
  currentRelationId: '',

  relations: []
}

export default (
  state = initialState,
  action: SelectRelationAction | UpdateRelationAction
): RelationRepositoryState => {
  switch (action?.type) {
    case CONSTANTS.RELATION_UPDATE_EMAIL:
      return {
        ...state,
        changeEmail: {
          error: initialState.changeEmail.error,
          loading: true,
          success: initialState.changeEmail.success
        }
      }

    case CONSTANTS.RELATION_UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        changeEmail: {
          error: initialState.changeEmail.error,
          loading: initialState.changeEmail.loading,
          success: true
        }
      }

    case CONSTANTS.RELATION_UPDATE_EMAIL_FAILED:
      return {
        ...state,
        changeEmail: {
          error: action.payload.error,
          loading: initialState.changeEmail.loading,
          success: false
        }
      }
    default:
      return state
  }
}
