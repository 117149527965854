import { getKeyName } from '@/utils/data'

import type { TEntity } from '../constants'
import type { State } from '../reducers'

export const getLoadingState = (state: State) => state?.loading

type TLoadingEntity =
  TEntity |
  'EXTEND_TOKEN' |
  'CLAIM_ATTACHMENTS'

export const isLoading = (entity: TLoadingEntity, id?: string) => (state: State) =>
  getLoadingState(state)?.[getKeyName({ entity: entity as TEntity, id })]
