import Colors, { transparentAddition } from '@/constants/Colors'
import { LOGGED_IN } from '@/redux/constants/user'
import type { LoggedInAction } from '@/redux/types/user'
import type { Agency, User } from '@/types/objects'
import prototype from '@/utils/prototype'

import type { TApiCallDispatch } from '../actions/api'

export interface ThemeColors {
  brand: string
  brandSecondary: string
  lightBrand: string
  lightBrandSecondary: string
}

export interface ThemeState {
  colors: ThemeColors
}

export const initialState: ThemeState = {
  colors: {
    brand: Colors.brand,
    brandSecondary: '',
    lightBrand: Colors.lightBrand,
    lightBrandSecondary: ''
  }
}

interface Updates {
  brand?: string
  brandSecondary?: string
  lightBrand?: string
  lightBrandSecondary?: string
}

function handleColorsChange (state: ThemeState, agency?: Agency, user?: User) {
  const updates: Updates = {};

  [agency, user].filter(prototype.isObject).forEach((object: any) => {
    if (object.primaryColor && object.primaryColor !== state.colors.brand) {
      updates.brand = object.primaryColor
      updates.lightBrand = object.primaryColor + transparentAddition
    }

    if (object.secondaryColor && object.secondaryColor !== state.colors.brandSecondary) {
      updates.brandSecondary = object.secondaryColor
      updates.lightBrandSecondary = object.secondaryColor + transparentAddition
    }
  })

  if (Object.keys(updates).length) {
    return {
      ...state,
      colors: {
        ...state.colors,
        ...updates
      }
    }
  }

  return state
}

export default (
  state: ThemeState = initialState,
  action: LoggedInAction & TApiCallDispatch
): ThemeState => {
  switch (action?.type) {
    case 'GET_AGENCY_SUCCESS':
      return handleColorsChange(state, action.payload.data as Agency)

    case LOGGED_IN:
      return handleColorsChange(state, undefined, action.user)

    case 'persist/REHYDRATE': {
      if (action.payload) {
        return handleColorsChange(state, action.payload.agency, action.payload.user)
      }

      return state
    }

    default:
      return state
  }
}
