/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */

import React from 'react'

import ContextBreakpoint from '@/bootstrap/ContextBreakpoint'

export default function withBreakpoint (Component: React.ElementType) {
  return (props: any) => (
    <ContextBreakpoint.Consumer>
      {(breakpoint) => <Component
        breakpoint={breakpoint}
        {...props}
                       />}
    </ContextBreakpoint.Consumer>
  )
}
