import React from 'react'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import Styling from '@/constants/Styling'
import type { Policy } from '@/types/objects'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

const Conditions: React.FC<{ policy: Policy }> = (props) => {
  if (!props.policy?.conditions?.length) {
    return null
  }

  return (
    <Bootstrap.Row>
      <Bootstrap.Column span={{ lg: 6, md: 8, sm: 12, xl: 4, xs: 12 }}>
        {props.policy?.conditions?.map((condition, index) => (
          <Button
            key={`condition-button-${condition.id}`}
            onPress={async () => await navigation.openScreenPolicyCondition(props.policy, condition)}
            style={index !== 0 && Styling.marginTop}
            title={condition.description || `${i18n.t('Voorwaarde')} ${index}`}
          />
        ))}
      </Bootstrap.Column>
    </Bootstrap.Row>
  )
}

export default Conditions
