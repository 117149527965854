import React from 'react'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Screen from '@/components/Screen'
import Html from '@/components/wrappers/HTML'
import RefreshControl from '@/components/wrappers/RefreshControl'
import Styling from '@/constants/Styling'
import { apiCall } from '@/redux/actions/api'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import type { TNavigationProp } from '@/types/props'
import common from '@/utils/common'
import i18n from '@/utils/i18n'
import { ENTITIES } from '@/redux/constants'

interface TOwnProps {
  navigation: TNavigationProp
}

type TTipCategoryProps = TOwnProps & {
  category: any
  refreshing: boolean
  apiCall: typeof apiCall
  tips: any[]
}

class TipCategory extends React.Component<TTipCategoryProps> {
  static navigationOptions: any

  componentDidMount () {
    this.props.navigation.setOptions({
      title: this.props.category?.title
    })

    this.refresh()
  }

  refresh = () => {
    this.props.apiCall('GET', ENTITIES.TIPS)
  }

  renderRefreshControl = () => (
    <RefreshControl
      onRefresh={this.refresh}
      refreshing={this.props.refreshing}
      title={i18n.t('Nieuwe preventietips aan het ophalen')}
    />
  )

  renderContent = () =>
    (this.props.tips || []).map((tip, index) => (
      <Html
        html={tip.content}
        key={`tips-button-${tip.id}`}
        style={index !== 0 && Styling.marginTop}
      />
    ))

  renderPhone = () => <Screen white>{this.renderContent()}</Screen>

  renderTablet = () => (
    <Screen>
      <Block noFlex>{this.renderContent()}</Block>
    </Screen>
  )

  render () {
    return (
      <Bootstrap.Switch
        renderPhone={this.renderPhone}
        renderTablet={this.renderTablet}
      />
    )
  }
}

TipCategory.navigationOptions = (props: any) => ({
  title: common.getNavigationParam(props, 'title') || i18n.t('Preventietip')
})

const mapStateToProps = (state: TState, ownProps: TOwnProps) => {
  const categoryId = common.getNavigationParam(ownProps, 'categoryId')

  return {
    category: selectors.getTipCategory(state, categoryId),
    refreshing: !!selectors.isLoading(ENTITIES.TIPS)(state),
    tips: selectors.getCategoryTips(state, categoryId)
  }
}

export default connect(mapStateToProps, { apiCall })(TipCategory)
