/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Pill from '@/components/Pill'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'
import dateUtils from '@/utils/date'

import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'

import type { State } from '@/redux/reducers'
import type { Claim as TClaim } from '@/types/objects'
import i18n from '@/utils/i18n'
import { getClaimConfig } from '@/utils/claims'
import { apiCall } from '@/redux/actions/api'

import selectors from '@/redux/selectors'

import Button from '@/components/buttons/Button'

import navigation from '@/utils/navigation'
import { continueClaimDamageConcept } from '@/redux/actions/claims'
import TouchableIcon from '../../components/touchables/TouchableIcon'
import alert from '@/utils/alert'
import common from '@/utils/common'
import data from '@/utils/data'

const Claim: React.FC<{ claim: TClaim }> = ({ claim }) => {
  const dispatch = useDispatch()

  const colors = useSelector(selectors.getThemeColors)
  const policy = useSelector((state: State) => selectors.getPolicy(state, claim?.policyId))
  const distinctionLabel = data.getDistinctionLabel(claim)

  const refreshing =
    useSelector(selectors.isLoading('CLAIM', claim?.claimDamageId))?.method === 'GET'
  const user = useSelector(selectors.getUser)
  const currentRelation = useSelector(selectors.getCurrentRelation)

  const config = getClaimConfig(claim?.status)
  const title = `${claim.productName} ${distinctionLabel ? `(${distinctionLabel})` : ''}`

  const onPressFinishForm = () => {
    dispatch(continueClaimDamageConcept(claim))
    navigation.openScreenClaimDamageForm()
  }

  useEffect(() => {
    if (policy) {
      dispatch(apiCall('GET', 'PRODUCT', String(policy.productId)))
    }
  }, [policy])

  const onPressRemove = () => {
    alert.showDouble(
      i18n.t('Verwijderen'),
      i18n.t('Weet u zeker dat u de concept schademelding wilt verwijderen?'),
      {
        onPress: () => false,
        style: 'cancel',
        text: i18n.t('Nee'),
      },
      {
        onPress: () => {
          dispatch(apiCall('DELETE', 'CLAIM', claim?.id))
          navigation.openScreenClaims()
        },
        text: i18n.t('Ja'),
      },
    )
  }

  return (
    <>
      <View style={Styling.marginBottom}>
        <Bootstrap.Row
          gutterHorizontal={15}
          gutterVertical={5}
        >
          {/* This column is only visible on the desktop sizes */}
          <Bootstrap.Column
            span={{ lg: 12, md: 12, sm: 0, xl: 12, xs: 0, xxs: 0 }}
            style={[Styling.rowBetween, Styling.itemsCenter]}
          >
            <View>
              {title ? <Text type='subsection'>{title}</Text> : null}
            </View>

            <View style={[Styling.row, Styling.itemsCenter]}>
              <Pill
                color={claim?.statusColor ?? config.color}
                title={claim?.status}
                style={[Styling.marginRight]}
              />

              {claim.status === 'Concept'
                ? (
                  <TouchableIcon
                    color={colors?.brand}
                    icon='trash'
                    onPress={onPressRemove}
                  />
                  )
                : null}
            </View>
          </Bootstrap.Column>

          {/* This column is only visible on the smaller screen sizes */}
          <Bootstrap.Column
            span={{ lg: 0, md: 0, sm: 12, xl: 0, xs: 12, xxs: 12 }}
            style={[Styling.rowBetween, Styling.itemsCenter]}
          >
            <View style={Styling.marginBottomMediumHalf}>
              <Text type='subsection'>Polis</Text>
              {title ? <Text type='description'>{title}</Text> : null}
            </View>

            {claim?.status === 'Concept'
              ? (
                <TouchableIcon
                  color={colors?.brand}
                  icon='trash'
                  onPress={onPressRemove}
                  style={{ width: 20 }}
                />
                )
              : null}
          </Bootstrap.Column>

          {/* This column is only visible on the smaller screen sizes */}
          <Bootstrap.Column
            span={{ lg: 0, md: 0, sm: 12, xl: 0, xs: 12, xxs: 12 }}
            style={Styling.rowBetween}
          >
            <Pill
              color={claim?.statusColor ?? config.color}
              title={claim?.status}
            />
          </Bootstrap.Column>
        </Bootstrap.Row>
      </View>

      <View>
        {dateUtils.formatTimeStamp(claim?.dateOfLoss)
          ? (
            <>
              <Text type='subsection'>{i18n.t('Datum waarop schade is ontstaan')}</Text>

              <Text
                style={[Styling.marginTopSmall, Styling.marginBottomNormal]}
                type='description'
              >
                {dateUtils.formatTimeStamp(claim?.dateOfLoss)}
              </Text>
            </>
            )
          : null}

        {claim?.text
          ? (
            <>
              <Text type='subsection'>{i18n.t('Uw omschrijving van de schade')}</Text>

              <Text
                style={[Styling.marginTopSmall, Styling.marginBottomNormal]}
                type='description'
              >
                {claim.text}
              </Text>
            </>
            )
          : null}

        {claim?.phoneNumber
          ? (
            <>
              <Text type='subsection'>{i18n.t('Uw telefoonnummer')}</Text>

              <Text
                style={[Styling.marginTopSmall, Styling.marginBottomNormal]}
                type='description'
              >
                {claim.phoneNumber}
              </Text>
            </>
            )
          : null}

      </View>

      <Text type='label'>
        Bijlage(n)
      </Text>
      {claim?.files?.map((file, index) => {
        return (
          <TouchableOpacity onPress={() => common.openURL(`${file.url}?token=${user.token}&user_id=${user.id}&relation_id=${currentRelation?.id}&relation_number=${currentRelation?.relationNumber}`, '_blank')}>
            <Text
              key={index}
              type='description'
              style={{ color: Colors.primary }}
            >
              {`- ${file.title}`}
            </Text>
          </TouchableOpacity>
        )
      })}

      {claim?.status === 'Concept'
        ? (
          <Button
            onPress={onPressFinishForm}
            title='Schademelding afronden'
            disabled={refreshing}
            style={Styling.marginTopMedium}
          />
          )
        : null}
    </>
  )
}

const Main: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()

  if (props?.route?.params?.claimId === undefined) {
    return null
  }

  const claim = useSelector((state) => selectors.getClaim(state, props?.route?.params?.claimId))

  const refreshing =
    useSelector(selectors.isLoading('CLAIM', claim?.id))?.method === 'GET'

  const refresh = () => {
    if (claim?.history === false) {
      dispatch(apiCall('GET', 'CLAIM', claim?.id, undefined, true))
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  if (!claim) {
    return null
  }

  return (
    <Bootstrap.Switch
      renderTablet={() => (
        <Screen
          useRefreshControl
          refreshing={refreshing}
          onRefresh={refresh}
        >
          <Block noFlex>
            <Claim claim={claim} />
          </Block>
        </Screen>
      )}
      renderPhone={() => (
        <Screen
          noPadding
          white
          useRefreshControl
          refreshing={refreshing}
          onRefresh={refresh}
        >
          <View style={styles.container}>
            <Claim claim={claim} />
          </View>
        </Screen>
      )}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingVertical: Sizes.screenPaddingVertical,
  }
})

export default Main
