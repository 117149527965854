import React from 'react'

import common from '@/utils/common'
import navigation from '@/utils/navigation'

interface INavigationProps {
  route: {
    params: {
      key: string
      fileName: string
      url: string
      errorMessage: string
    }
  }
}

const PDFViewer: React.FC<INavigationProps> = (props) => {
  const file = props.route.params

  if (file?.url) {
    common.openURL(file.url, '_blank')
    navigation.back()
  }

  return <></>
}

export default PDFViewer
