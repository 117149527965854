import React from 'react'

import Icon from '@/components/Icon'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'

export default function HeaderBackImage () {
  return (
    <Icon
      color={Colors.lightBrown}
      icon={['fal', 'chevron-left']}
      size={Sizes.backButton}
      style={Styling.marginSmall}
    />
  )
}
