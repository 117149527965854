import * as CONSTANTS from '@/redux/constants/files'
import type * as TYPES from '@/redux/types/files'
import type { Agency, Condition, InsuranceCard, Policy, Purchase, ServiceGuide } from '@/types/objects'

export const addFile = (url: string, filepath: string): TYPES.AddFileAction => ({
  filepath,
  type: CONSTANTS.ADD_FILE,
  url
})

export const updateFile = (url: string, filepath: string): TYPES.UpdateFileAction => ({
  filepath,
  type: CONSTANTS.UPDATE_FILE,
  url
})

export const refreshFile = (url: string, filename: string, updatedAt: string = ''): TYPES.RefreshFileAction => ({
  filename,
  type: CONSTANTS.REFRESH_FILE,
  updatedAt,
  url
})

export const openFile = (url: string, filename: string, errorMessage?: string): TYPES.OpenFileAction => ({
  errorMessage,
  filename,
  type: CONSTANTS.OPEN_FILE,
  url
})

export const openServiceGuide = (agency: Agency, serviceGuide: ServiceGuide): TYPES.OpenServiceGuideAction => ({
  agency,
  serviceGuide,
  type: CONSTANTS.OPEN_SERVICE_GUIDE
})

export const openPolicyCondition = (policy: Policy, condition: Condition): TYPES.OpenPolicyConditionAction => ({
  condition,
  policy,
  type: CONSTANTS.OPEN_POLICY_CONDITION
})

export const openPolicyInsuranceCard = (
  policy: Policy,
  insuranceCard: InsuranceCard
): TYPES.OpenPolicyInsuranceCardAction => ({
  insuranceCard,
  policy,
  type: CONSTANTS.OPEN_POLICY_INSURANCE_CARD
})

export const openPurchaseInvoice = (purchase: Purchase): TYPES.OpenPurchaseInvoiceAction => ({
  purchase,
  type: CONSTANTS.OPEN_PURCHASE_INVOICE
})
