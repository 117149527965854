import * as Analytics from '@/utils/firebase/analytics'
import type * as Linking from 'expo-linking'
import React, { createRef } from 'react'
import type {
  StyleProp,
  ViewStyle
} from 'react-native'
import {
  ActivityIndicator,
  ImageBackground,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import ButtonForm from '@/components/buttons/ButtonForm'
import Form from '@/components/Form'
import Icon from '@/components/Icon'
import ImageAutoWidth from '@/components/images/ImageAutoWidth'
import Input from '@/components/inputs/Input'
import Screen from '@/components/Screen'
import Toast from '@/components/Toast'
import TouchableText from '@/components/touchables/TouchableText'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Settings from '@/constants/Settings'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import withApiCall from '@/hocs/withApiCall'
import withHandleUrl from '@/hocs/withHandleUrl'
import withSafeAreaInsets from '@/hocs/withSafeAreaInsets'
import { resetIdin, startIdin } from '@/redux/actions/idin'
import { apiCall as apiCallDispatch } from '@/redux/actions/api'
import { openModalEmergencyNumber, openModalHelp } from '@/redux/actions/modals'
import { selectRelation } from '@/redux/actions/relationRepository'
import { signInSucces } from '@/redux/actions/user'
import * as idinApi from '@/redux/api/idin'
import * as sessionsApi from '@/redux/api/sessions'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import ListOrdered from '@/screens/auth/components/ListOrdered'
import AddUser from '@/svgs/AddUser'
import Email from '@/svgs/Email'
import Idin from '@/svgs/Idin'
import Search from '@/svgs/Search'
import type { User } from '@/types/objects'
import common from '@/utils/common'
import data from '@/utils/data'
import { parseEmailAddress } from '@/utils/email'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import storage from '@/utils/storage'
import toast from '@/utils/toast'
import ResetPassword from './components/reset_password'
import { ENTITIES } from '@/redux/constants'
import type { initialState } from '@/redux/reducers/agency'
import type {
  GetHomeEssentials,
  THomeOwnProps,
  THomeProps,
  THomeState
} from '@/screens/auth/Home.shared'
import {
  createOnLogin,
  createOnPressSubmitCheck,
  getInitialState
} from '@/screens/auth/Home.shared'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const background = require('../../assets/images/background.jpg')

type THomeWebProps = THomeProps & {
  apiCallDispatch: typeof apiCallDispatch
  agencyPrintout: typeof initialState['printout']
}

class Home extends React.Component<THomeWebProps, THomeState> {
  initialState = getInitialState()

  state: THomeState = {
    ...this.initialState,
    emailAddress: this.props.emailAddress || '',
    password: this.props.password || '',
    passwordRepeat: this.props.passwordRepeat || '',
    showCheckEmail: this.props.action === 'check-email',
    showForgotPassword: this.props.action === 'forgot-password',
    showRegister: this.props.action === 'register',
    showResetPassword: this.props.action === 'reset-password',
    handleIdinLogin: this.props.action === 'idin'
  }

  notFoundListItems: any

  static getDerivedStateFromProps (props: THomeWebProps, state: THomeState) {
    const newState: Partial<THomeState> = {}

    if (state.showRegister) {
      newState.showRegister = false
    } else if (state.showResetPassword) {
      newState.showResetPassword = false
    } else if (state.showForgotPassword) {
      newState.showForgotPassword = false
    } else if (state.showCheckEmail) {
      newState.showCheckEmail = false
    }

    switch (props.action) {
      case 'register':
        newState.showRegister = true
        newState.registerToken = props.registerToken
        newState.emailAddress = props.emailAddress
        break
      case 'reset-password':
        newState.showResetPassword = true
        break
      case 'forgot-password':
        newState.showForgotPassword = true
        break
      case 'check-email':
        newState.showCheckEmail = true
        break
      case 'idin':
        newState.handleIdinLogin = true
        break
      default:
        break
    }

    if (props?.agencyPrintout?.logo) {
      newState.logo = { uri: props.agencyPrintout.logo }
    }

    return Object.keys(newState).length ? newState : null
  }

  async componentDidMount () {
    this.notFoundListItems = [
      i18n.t('U bent te snel, de nieuwe polis is nog niet bevestigd.'),
      i18n.t('U heeft geen combipolis bij Nh1816'),
      i18n.t('U heeft een combipolis op de naam van iemand anders.')
    ]

    const alreadyUsed = await storage.isAlreadyUsed()
    this.setState({ alreadyUsed, loaded: true })

    this.fetchAgencyPrintout()

    if (this.state.handleIdinLogin) {
      this.handleEntranceCode(
        this.props.idin.entranceCode
      )
    }
  }

  fetchAgencyPrintout = async () => {
    const agencyId = window?.location?.hostname?.split?.('.')?.[0]

    if (agencyId && parseInt(agencyId)) {
      this.props.apiCallDispatch('GET', ENTITIES.AGENCY_PRINTOUT, agencyId)
    }
  }

  handleUrl = (url: Linking.ParsedURL): void => {
    if (url?.queryParams?.entranceCode) {
      this.handleEntranceCode(url.queryParams.entranceCode, url.queryParams.email)
    } else if (url?.queryParams?.action) {
      const { action, ...params } = url.queryParams
      navigation.openScreenHome(action, params)
    }
  }

  handleEntranceCode = async (entranceCode: any, email?: any): Promise<void> => {
    if (this.props.idin?.entranceCode && this.props.idin?.transactionId) {
      if (this.props.idin.entranceCode === entranceCode) {
        if (!this.state.idinLoading) {
          this.setState({ idinLoading: true })

          // Calling the api function directly is intentionally.
          const emailAddres = email || this.props.idin.email || ''
          const response = await idinApi.verifyIdin(
            this.props.idin.entranceCode,
            this.props.idin.transactionId,
            common.determineRedirectUrl('home', { action: 'register' }),
            emailAddres
          )

          if (response?.data?.registerToken) {
            toast(i18n.t('De identificatie is succesvol afgerond.'))
            this.setState({ idinResult: true, registerToken: response.data.registerToken })
            this.props.resetIdin()

            Analytics.logEvent('idin_success')
          } else {
            toast(i18n.t('De identificatie is helaas mislukt, er is geen data beschikbaar'))
            this.setState({ idinResult: false })

            Analytics.logEvent('idin_error')
          }

          this.setState({ idinLoading: false })
        }
      } else {
        toast(i18n.t('De identificatie is helaas mislukt, de toegangscode komt niet overeen.'))
      }
    } else {
      toast(i18n.t('De identificatie is helaas mislukt, er is geen toegangscode bekend.'))
    }

    this.props.navigation.setParams({ entranceCode: undefined })
  }

  onChangeEmailAddress = (emailAddress: User['email']): void => this.setState({ emailAddress })

  onChangePassword = (password: User['password']): void => this.setState({ password })

  onChangePasswordRepeat = (passwordRepeat: User['password']): void => this.setState({ passwordRepeat })

  onChangeForgotPasswordValue = (forgotPasswordValue: THomeState['forgotPasswordValue']): void =>
    this.setState({ forgotPasswordValue })

  getEssentials: GetHomeEssentials = () => ({
    props: this.props as any,
    state: this.state,
    setState: (newState: any) => this.setState(newState)
  })

  onPressSubmitCheck = createOnPressSubmitCheck(this.getEssentials)
  onLogin = createOnLogin(this.getEssentials)

  onPressSubmitForgotPassword = async (): Promise<void> => {
    this.setState({ loading: true })

    const resetPasswordUrl = common.determineRedirectUrl('home', { action: 'reset-password' })
    const response = await this.props.apiCallSkip(
      sessionsApi.forgotPassword,
      parseEmailAddress(this.state.forgotPasswordValue),
      resetPasswordUrl
    )

    if (response?.result) {
      this.setState({ forgotPasswordResult: response.result })
    } else {
      toast(i18n.t('Het is niet gelukt'))
    }

    this.setState({ loading: false })
  }

  onPressSubmitRegister = async (): Promise<void> => {
    this.setState({ loading: true })

    const result = await sessionsApi.register(
      parseEmailAddress(this.state.emailAddress),
      this.state.password,
      this.state.passwordRepeat,
      this.state.registerToken || this.props.registerToken || this.props.token!
    )

    if (result.success) {
      await storage.setAlreadyUsed(true)
      toast(i18n.t('Uw account is geregistreerd'))
      this.setState({ alreadyUsed: true })

      await this.onLogin()

      this.closeRegister()
    } else {
      this.closeRegister()

      if (result?.statusCode === 503) {
        this.setState({
          failedMessage: result.message!
        })

        return
      }

      this.setState({
        password: '',
        passwordRepeat: '',
        showInvalidToken: true,
        invalidTokenButtonText: 'Opnieuw registreren',
        invalidTokenText: 'De link om het wachtwoord in te stellen is niet meer geldig. Probeer opnieuw te registreren.',
        invalidTokenButtonHandler: () => {
          this.setState({ showInvalidToken: false })
          this.props.navigation.setParams({ action: 'check-email' })
        },
      })
    }

    this.setState({ loading: false })
  }

  onPressSubmitResetPassword = async (): Promise<void> => {
    this.setState({ loading: true })

    const result = await this.props.apiCallSkip(
      sessionsApi.resetPassword,
      this.state.password,
      this.state.passwordRepeat,
      this.props.resetPasswordToken ?? this.props.token
    )

    if (result) {
      toast(i18n.t('Uw wachtwoord is aangepast'))
      this.closeForgotPassword()
    } else {
      this.closeForgotPassword()
      this.setState({
        password: '',
        passwordRepeat: '',
        showInvalidToken: true,
        invalidTokenButtonText: 'Wachtwoord resetten',
        invalidTokenText: 'De link om het wachtwoord te resetten is niet meer geldig. Probeer opnieuw je wachtwoord te resetten.',
        invalidTokenButtonHandler: () => {
          this.setState({ showInvalidToken: false })
          this.openForgotPassword()
        },
      })
    }

    this.setState({ loading: false })
  }

  identifyIdin = async (): Promise<void> => {
    this.setState({ loading: true })

    const redirectUrl = common.determineRedirectUrl('home', { action: 'idin' })
    const email = parseEmailAddress(this.state.emailAddress)

    // Calling the api function directly is intentionally.
    const result = await idinApi.identifyIdin(redirectUrl, email)

    if (result?.entranceCode !== '') {
      this.props.startIdin(result.entranceCode, result.TransactionID, this.state.emailAddress)

      if (result.TransactionURL !== '') {
        common.openWebsite(result.TransactionURL, '_self')
      }
    } else {
      toast(i18n.t('De server heeft de aanvraag niet kunnen verwerken'))
    }

    this.setState({ loading: false })
  }

  openFirstUse = (): void => {
    this.resetState()
    this.props.navigation.setParams({ action: 'check-email' })
  }

  closeFirstUse = (): void => {
    this.props.navigation.setParams({ action: undefined })
    this.setState({ emailAddress: '' })
  }

  openRegister = (): void => this.props.navigation.setParams({ action: 'register' })

  closeRegister = (): void => {
    this.props.resetIdin()
    this.props.navigation.setParams({
      action: undefined,
      emailAddress: undefined,
      entranceCode: undefined,
      registerToken: undefined
    })
    this.setState({
      idinResult: null,
      password: '',
      passwordRepeat: '',
      registerToken: '',
      status: ''
    })
  }

  openForgotPassword = (): void => this.props.navigation.setParams({ action: 'forgot-password', resetPasswordToken: undefined })

  closeForgotPassword = (): void => {
    this.props.navigation.setParams({ action: undefined })
    this.setState({
      forgotPasswordResult: false,
      forgotPasswordValue: ''
    })
  }

  openResetPassword = (): void => this.props.navigation.setParams({ action: 'reset-password' })

  closeNoMatch = (): void => {
    this.props.resetIdin()
    this.props.navigation.setParams({
      action: undefined,
      entranceCode: undefined
    })
    this.resetState()
  }

  resetState = (): void =>
    this.setState((previousState: THomeState) => ({
      ...this.initialState,
      alreadyUsed: previousState.alreadyUsed,
      loaded: previousState.loaded
    }))

  getPaddingStyleFromInsets = (
    insets: any,
    horizontalAddition = 0,
    verticalAddition = 0
  ): { paddingBottom: number, paddingLeft: number, paddingRight: number, paddingTop: number } => ({
    paddingBottom: (insets?.bottom || 0) + verticalAddition,
    paddingLeft: (insets?.left || 0) + horizontalAddition,
    paddingRight: (insets?.right || 0) + horizontalAddition,
    paddingTop: (insets?.top || 0) + verticalAddition
  })

  renderContent = (type: string) => {
    if (!this.state.loaded) {
      return null
    }
    if (this.state.idinLoading) {
      return this.renderVerifyIdin()
    }
    if (this.state.showInvalidToken) {
      return this.renderInvalidToken()
    }
    if (this.state.idinResult === false) {
      return this.renderNoMatch(type)
    }
    if (this.state.idinResult === true) {
      return this.renderRegister()
    }
    if (this.state.showForgotPassword) {
      if (this.state.forgotPasswordResult) {
        return this.renderForgotPasswordResult(type)
      }
      return this.renderForgotPassword()
    }
    if (this.state.showRegister) {
      return this.renderRegister()
    }
    if (this.state.showCheckEmail) {
      return this.renderFirstUse(type)
    }
    if (this.state.showResetPassword) {
      return this.renderResetPassword()
    }
    if (this.state.status) {
      switch (this.state.status) {
        case 'activated':
          return this.renderLogin()
        // Show the same screen as with the not activated status.
        case 'multi_relations':
        case 'not_activated':
          return this.renderNotActivated(type)
        case 'not_activated_old':
          return this.renderNotActivatedOld(type)
        case 'not_found':
          return this.renderNotFound(type)
        default:
          return this.renderFirstUse(type)
      }
    }
    if (this.state.alreadyUsed) {
      return this.renderLogin()
    }
    return this.renderFirstUse(type)
  }

  renderButtonBack = (onPress: () => void, style?: any) => (
    <Button
      borderColor={Colors.gray}
      color={Colors.white}
      disabled={this.state.loading}
      id='button-back'
      onPress={onPress}
      style={[style, Styling.fullWidth]}
      title={i18n.t('Terug')}
      titleColor={Colors.gray}
    />
  )

  renderButtonReset = (style?: any) => this.renderButtonBack(this.openFirstUse, style)

  renderButtonIdin = (style: any) => (
    <Button
      color={Colors.primary}
      disabled={this.state.loading}
      iconCircleColor={Colors.white}
      iconComponent={Idin}
      iconSize={Sizes.idinIcon}
      id='button-register'
      loadingRight={this.state.loading}
      onPress={this.identifyIdin}
      style={style}
      title={i18n.t('Identificeren met iDIN')}
    />
  )

  renderButtonEmergency = (style?: StyleProp<ViewStyle>) => (
    <Button
      color={Colors.red}
      icon='phone'
      iconCircleColor={Colors.white}
      id='button-call-emergency'
      onPress={this.props.openModalEmergencyNumber}
      style={style}
      title={i18n.t('Noodnummer bellen')}
    />
  )

  renderNotActivated = (type: string) =>
    type === 'phone' ? this.renderNotActivatedPhone() : this.renderNotActivatedTablet()

  renderNotActivatedPhone = () => (
    <View style={Styling.itemsCenter}>
      <Email />
      <Text
        style={[Styling.marginVerticalMedium, Styling.textCenter]}
        type='title'
      >
        {i18n.t('Gevonden!')}
      </Text>
      <Text
        style={[Styling.marginBottomMedium, Styling.textCenter]}
        type='description'
      >
        {i18n.t('We hebben uw gegevens gevonden en een e-mail gestuurd naar:')}{' '}
        <Text type='descriptionBold'>{this.state.emailAddress}</Text>
      </Text>
      <Text
        style={[Styling.marginBottomMedium, Styling.textCenter]}
        type='description'
      >
        {i18n.t(
          'In deze e-mail vindt u alle informatie om in te loggen. Controleer ook uw spambox en ongewenste e-mail.'
        )}
      </Text>

      {this.renderButtonReset()}
    </View>
  )

  renderNotActivatedTablet = () => (
    <>
      <Text type='title'>{i18n.t('Gevonden!')}</Text>
      <Text
        style={Styling.marginVerticalMedium}
        type='description'
      >
        {i18n.t('We hebben uw gegevens gevonden en een e-mail gestuurd naar:')} {this.state.emailAddress}
      </Text>
      <Text
        style={Styling.marginBottomMedium}
        type='description'
      >
        {i18n.t('In deze e-mail vindt u alle informatie om in te loggen.')}
      </Text>
      <Text
        style={Styling.marginBottomMedium}
        type='description'
      >
        {i18n.t('Controleer ook uw spambox en ongewenste e-mail.')}
      </Text>

      {this.renderButtonReset()}
    </>
  )

  renderNotActivatedOld = (type: string) =>
    type === 'phone' ? this.renderNotActivatedOldPhone() : this.renderNotActivatedOldTablet()

  renderNotActivatedOldPhone = () => (
    <View style={Styling.itemsCenter}>
      <Email />
      <Text
        style={[Styling.marginVerticalMedium, Styling.textCenter]}
        type='title'
      >
        {i18n.t('Gevonden!')}
      </Text>
      <Text
        style={[Styling.marginBottomMedium, Styling.textCenter]}
        type='description'
      >
        {i18n.t('We zien dat u al een account heeft maar dat deze nog niet geactiveerd is voor de nieuwe omgeving.')}
      </Text>
      <Text
        style={[Styling.marginBottomMedium, Styling.textCenter]}
        type='description'
      >
        {i18n.t(
          'Vanwege verbeterde beveiligingsmaatregelen moet u uw account opnieuw activeren, we hebben hiervoor een e-mail gestuurd naar:'
        )}{' '}
        {this.state.emailAddress}
      </Text>

      {this.renderButtonReset()}
    </View>
  )

  renderNotActivatedOldTablet = () => (
    <>
      <Text type='title'>{i18n.t('Gevonden!')}</Text>
      <Text
        style={Styling.marginVerticalMedium}
        type='description'
      >
        {i18n.t('We zien dat u al een account heeft maar dat deze nog niet geactiveerd is voor de nieuwe omgeving.')}
      </Text>
      <Text
        style={Styling.marginBottomMedium}
        type='description'
      >
        {i18n.t(
          'Vanwege verbeterde beveiligingsmaatregelen moet u uw account opnieuw activeren, we hebben hiervoor een e-mail gestuurd naar:'
        )}{' '}
        {this.state.emailAddress}
      </Text>

      {this.renderButtonReset()}
    </>
  )

  renderNoMatch = (type: string) =>
    type === 'phone' ? this.renderNoMatchPhone() : this.renderNoMatchTablet()

  renderNoMatchPhone = () => (
    <>
      <Search style={[Styling.marginBottomMedium, Styling.selfCenter]} />
      <Text
        style={[Styling.marginBottom, Styling.textCenter]}
        type='title'
      >
        {i18n.t('Niets gevonden')}
      </Text>
      <Text
        style={[Styling.marginBottomMedium, Styling.textCenter]}
        type='description'
      >
        {i18n.t('Dat kan 3 dingen betekenen.')}
      </Text>
      <ListOrdered items={this.notFoundListItems} />
      <Text
        style={[Styling.marginVerticalMedium, Styling.textCenter]}
        type='description'
      >
        {i18n.t('Contacteer uw tussenpersoon. Hij of zij helpt u graag verder!')}
      </Text>
      {this.renderButtonBack(this.closeNoMatch, Styling.autoTop)}
    </>
  )

  renderNoMatchTablet = () => (
    <>
      <Text
        style={Styling.marginBottomMedium}
        type='title'
      >
        {i18n.t('Niets gevonden')}
      </Text>
      <Text
        style={Styling.marginBottom}
        type='description'
      >
        {i18n.t('Dat kan 3 dingen betekenen.')}
      </Text>
      <ListOrdered items={this.notFoundListItems} />
      <Text
        style={Styling.marginVerticalMedium}
        type='description'
      >
        {i18n.t('Contacteer uw tussenpersoon. Hij of zij helpt u graag verder!')}
      </Text>
      {this.renderButtonBack(this.closeNoMatch, Styling.autoTop)}
    </>
  )

  renderForgotPasswordResult = (type: string) =>
    type === 'phone' ? this.renderForgotPasswordResultPhone() : this.renderForgotPasswordResultTablet()

  renderForgotPasswordResultPhone = () => (
    <View style={Styling.itemsCenter}>
      <Email />
      <Text
        style={[Styling.marginVerticalMedium, Styling.textCenter]}
        type='title'
      >
        {i18n.t('E-mail verzonden!')}
      </Text>
      <Text
        style={[Styling.marginBottomMedium, Styling.textCenter]}
        type='description'
      >
        {i18n.t('We hebben een e-mail verzonden met daarin de instructies om uw wachtwoord te resetten.')}
      </Text>

      {this.renderButtonBack(this.closeForgotPassword)}
    </View>
  )

  renderForgotPasswordResultTablet = () => (
    <>
      <Text type='title'>{i18n.t('E-mail verzonden!')}</Text>
      <Text
        style={Styling.marginVerticalMedium}
        type='description'
      >
        {i18n.t('We hebben een e-mail verzonden met daarin de instructies om uw wachtwoord te resetten.')}
      </Text>

      {this.renderButtonBack(this.closeForgotPassword)}
    </>
  )

  renderForgotPassword = () => {
    const disabled = this.state.loading || !this.state.forgotPasswordValue

    return (
      <>
        <Text
          style={Styling.marginBottomMedium}
          type='title'
        >
          {i18n.t('Wachtwoord vergeten?')}
        </Text>
        <Text
          style={Styling.marginBottomMedium}
          type='description'
        >
          {i18n.t(
            'Om uw wachtwoord te resetten dient u uw email in te vullen. Vervolgens ontvangt u een e-mail met alle informatie om uw wachtwoord te resetten.'
          )}
        </Text>
        <Input
          autoFocus
          autoCapitalize='none'
          autoCompleteType='email'
          icon='envelope'
          id='input-email'
          label={i18n.t('E-mailadres')}
          onChange={this.onChangeForgotPasswordValue}
          placeholder={i18n.t('Uw e-mailadres')}
          value={this.state.forgotPasswordValue}
          onKeyPressEnter={this.onPressSubmitForgotPassword}
        />
        <Button
          color={Colors.primary}
          disabled={disabled}
          id='button-submit-forgot-password'
          loading={this.state.loading}
          onPress={this.onPressSubmitForgotPassword}
          style={Styling.marginBottom}
          title={i18n.t('Opsturen')}
        />

        {this.renderButtonBack(this.closeForgotPassword)}
      </>
    )
  }

  renderNotFound = (type: string) =>
    type === 'phone' ? this.renderNotFoundPhone() : this.renderNotFoundTablet()

  renderNotFoundPhone = () => (
    <View style={Styling.itemsCenter}>
      <AddUser />
      <Text
        style={[Styling.marginVerticalMedium, Styling.textCenter]}
        type='title'
      >
        {i18n.t('Registreren')}
      </Text>
      <Text
        style={[Styling.marginBottomSmall, Styling.textCenter]}
        type='description'
      >
        {i18n.t('We hebben geen accountgegevens gevonden op basis van uw e-mailadres: ')} {this.state.emailAddress}
      </Text>
      <Text
        style={[Styling.marginBottomSmall, Styling.textCenter]}
        type='description'
      >
        {i18n.t(
          'Neem contact op met uw verzekeringsadviseur om te verifiëren via welk e-mailadres u bij ons bekend bent.'
        )}
      </Text>
      <Text
        style={[Styling.marginBottomMedium, Styling.textCenter]}
        type='description'
      >
        {i18n.t('U kunt zich in dit geval ook eenvoudig identificeren via iDIN.')}
      </Text>
      {this.renderButtonIdin([Styling.marginBottom, Styling.fullWidth])}
      {this.renderButtonReset(Styling.marginBottom)}
      <TouchableText
        onPress={this.props.openModalHelp}
        type='light'
      >
        {i18n.t('Wat is iDIN?')}
      </TouchableText>
    </View>
  )

  renderNotFoundTablet = () => (
    <>
      <Text
        style={Styling.marginBottomMedium}
        type='title'
      >
        {i18n.t('Registreren')}
      </Text>
      <Text
        style={Styling.marginBottomMedium}
        type='description'
      >
        {i18n.t('We hebben geen account gegevens gevonden op basis van op uw e-mailadres: ')} {this.state.emailAddress}
      </Text>
      <Text
        style={Styling.marginBottomMedium}
        type='description'
      >
        {i18n.t(
          'Neem contact op met uw verzekeringsadviseur om te verifiëren via welk e-mailadres u bij ons bekend bent.'
        )}
      </Text>
      <Text
        style={Styling.marginBottomMedium}
        type='description'
      >
        {i18n.t('U kunt zich in dit geval ook eenvoudig identificeren via iDIN.')}
      </Text>
      {this.renderButtonIdin(Styling.marginBottom)}
      {this.renderButtonReset(Styling.marginBottom)}
      <TouchableText
        onPress={this.props.openModalHelp}
        type='light'
      >
        {i18n.t('Wat is iDIN?')}
      </TouchableText>
    </>
  )

  renderVerifyIdin = () => (
    <>
      <Text
        style={Styling.marginBottomMedium}
        type='title'
      >
        {i18n.t('Registreren')}
      </Text>
      <Text
        style={Styling.marginBottomMedium}
        type='description'
      >
        {i18n.t('We zijn bezig uw identificatie via iDIN te controleren...')}
      </Text>
      <ActivityIndicator
        color={Colors.primary}
        size='large'
      />
    </>
  )

  renderErrorToast = () => {
    const { failedMessage } = this.state

    if (!failedMessage) {
      return null
    }

    return (
      <Toast
        error
        message={failedMessage}
        style={Styling.marginBottomMediumHalf}
      />
    )
  }

  refLoginInputPassword = createRef<Input>()

  renderLogin = () => {
    const { loading, password, emailAddress, alreadyUsed } = this.state

    const disabled = loading || !password
    const hasEmail = emailAddress !== '' && emailAddress !== undefined

    return (
      <>
        <Text
          style={Styling.marginBottomMedium}
          type='title'
        >
          {alreadyUsed ? i18n.t('Welkom') : i18n.t('Inloggen')}
        </Text>
        <Text
          style={Styling.marginBottomMedium}
          type='description'
        >
          {alreadyUsed
            ? i18n.t(
              `In ${data.productName} kunt u snel schademelden en heeft u toegang tot uw dossier, verzekeringsbewijs, polissen en meer. Begin hier met inloggen.`
            )
            : i18n.t('We hebben uw account gevonden. Log in met uw eerder gekozen wachtwoord.')}
        </Text>
        <Form>
          {this.renderErrorToast()}
          <Input
            autoCapitalize='none'
            autoCompleteType='email'
            autoFocus={!hasEmail}
            icon='envelope'
            id='input-email'
            label={i18n.t('E-mailadres')}
            onChange={this.onChangeEmailAddress}
            placeholder={i18n.t('Uw e-mailadres')}
            style={Styling.marginBottom}
            value={emailAddress}
            nextInput={this.refLoginInputPassword}
          />
          <Input
            ref={this.refLoginInputPassword}
            secureTextEntry
            autoCapitalize='none'
            autoFocus={hasEmail}
            autoCompleteType='password'
            icon='key'
            id='input-password'
            label={i18n.t('Wachtwoord')}
            onChange={this.onChangePassword}
            onKeyPressEnter={this.onLogin}
            placeholder={i18n.t('Uw wachtwoord')}
            value={password}
          />
          <ButtonForm
            color={Colors.primary}
            disabled={disabled}
            id='button-submit-login'
            loading={loading}
            onPress={this.onLogin}
            style={Styling.marginBottom}
            title={i18n.t('Inloggen')}
          />
        </Form>
        {alreadyUsed ? null : this.renderButtonReset(Styling.marginBottom)}
        <TouchableText
          onPress={this.openFirstUse}
          touchableStyle={Styling.selfStart}
          type='light'
        >
          {i18n.t('Registreren?')}
        </TouchableText>
        <TouchableText
          onPress={this.openForgotPassword}
          touchableStyle={[Styling.selfStart, Styling.marginVerticalSmall]}
          type='light'
        >
          {i18n.t('Wachtwoord vergeten?')}
        </TouchableText>

        <Text type='description'>
          {i18n.t('In geval van nood, neem contact op via het noodnummer')}:{' '}
          <TouchableText
            color={Colors.darkRed}
            onPress={this.props.openModalEmergencyNumber}
            type='description'
          >
            {Settings.emergencyNumber}
          </TouchableText>
        </Text>
      </>
    )
  }

  renderRegister = () => {
    const result = common.checkPasswords(this.state.password, this.state.passwordRepeat)
    const invalidLabel = this.state.password ? 'labelError' : 'label'
    const disabled = this.state.loading || !this.state.password || !this.state.passwordRepeat || result.invalid

    return (
      <>
        <Text
          style={Styling.marginBottomMedium}
          type='title'
        >
          {i18n.t('Inlog aanmaken')}
        </Text>

        <Text
          style={Styling.marginBottomMedium}
          type='description'
        >
          {this.state.idinResult
            ? i18n.t('We hebben uw gegevens gevonden. Hieronder kunt u uw account aanmaken:')
            : i18n.t('Hieronder kunt u uw account aanmaken:')}
        </Text>

        <Form>
          <Input
            autoCapitalize='none'
            autoCompleteType='email'
            disabled={!!this.props.emailAddress}
            icon='envelope'
            id='input-email'
            label={i18n.t('E-mailadres')}
            onChange={this.onChangeEmailAddress}
            placeholder={i18n.t('Uw e-mailadres')}
            style={Styling.marginBottomMedium}
            value={this.state.emailAddress}
          />
          <Text
            style={Styling.marginBottomTiny}
            type='label'
          >
            {i18n.t('Bedenk een veilig wachtwoord dat voldoet aan de volgende eisen')}
          </Text>

          <Text type={result.validLength ? 'labelSuccess' : invalidLabel}>• {i18n.t('Minimaal 8 karakters')}</Text>

          <Text type={result.validUppercase ? 'labelSuccess' : invalidLabel}>• {i18n.t('Minimaal 1 hoofdletter')}</Text>

          <Text type={result.validLowercase ? 'labelSuccess' : invalidLabel}>
            • {i18n.t('Minimaal 1 kleine letter')}
          </Text>

          <Text type={result.validDigit ? 'labelSuccess' : invalidLabel}>• {i18n.t('Minimaal 1 cijfer')}</Text>

          <Text
            style={Styling.marginBottom}
            type={result.validSpecialChar ? 'labelSuccess' : invalidLabel}
          >
            • {i18n.t('Minimaal 1 speciaal teken')}
          </Text>
          <Input
            secureTextEntry
            autoCapitalize='none'
            autoCompleteType='off'
            icon='key'
            id='input-password'
            label={i18n.t('Wachtwoord')}
            onChange={this.onChangePassword}
            placeholder={i18n.t('Uw wachtwoord')}
            style={Styling.marginBottom}
            success={!result.invalidPassword}
            value={this.state.password}
          />
          <Input
            secureTextEntry
            autoCapitalize='none'
            autoCompleteType='off'
            error={result.errorPasswordRepeat}
            errorDescription={i18n.t('Beide wachtwoorden moeten gelijk zijn aan elkaar')}
            icon='key'
            id='input-password-repeat'
            label={i18n.t('Herhaal wachtwoord')}
            onChange={this.onChangePasswordRepeat}
            placeholder={i18n.t('Herhaal uw wachtwoord')}
            success={result.successPasswordRepeat}
            value={this.state.passwordRepeat}
          />
          <ButtonForm
            color={Colors.primary}
            disabled={disabled}
            id='button-submit-register'
            loading={this.state.loading}
            onPress={this.onPressSubmitRegister}
            style={Styling.marginBottom}
            title={i18n.t('Aanmaken')}
          />
        </Form>

        {this.renderButtonBack(this.closeRegister)}
      </>
    )
  }

  renderInvalidToken = () => {
    return (
      <View>
        <View style={styles.alertContainer}>
          <Text
            style={styles.alertText}
            type=''
          >
            {this.state.invalidTokenText}
          </Text>
        </View>
        <Button
          onPress={this.state.invalidTokenButtonHandler}
          title={this.state.invalidTokenButtonText}
        />
      </View>
    )
  }

  renderResetPassword = () => (
    <ResetPassword
      password={this.state.password}
      passwordRepeat={this.state.passwordRepeat}
      loading={this.state.loading}
      alreadyUsed={this.state.alreadyUsed}
      onChangePassword={this.onChangePassword}
      onChangePasswordRepeat={this.onChangePasswordRepeat}
      onPressSubmitResetPassword={this.onPressSubmitResetPassword}
      renderButtonBack={this.renderButtonBack}
      closeResetPassword={this.closeForgotPassword}
    />
  )

  renderFirstUse = (type: string) => {
    return type === 'phone' ? this.renderFirstUsePhone() : this.renderFirstUseTablet()
  }

  renderFirstUsePhone = () => (
    <>
      {this.renderFirstUseContent()}
      {this.renderButtonEmergency(Styling.autoTop)}
    </>
  )

  renderFirstUseTablet = () => (
    <>
      {this.renderFirstUseContent()}
      <Text type='description'>
        {i18n.t('In geval van nood, neem contact op via het noodnummer')}:{' '}
        <TouchableText
          color={Colors.darkRed}
          onPress={this.props.openModalEmergencyNumber}
          type='description'
        >
          {Settings.emergencyNumber}
        </TouchableText>
      </Text>
    </>
  )

  renderFirstUseContent = () => {
    const disabled = this.state.loading || !this.state.emailAddress || !common.validateEmail(this.state.emailAddress)

    return (
      <>
        <Text
          style={Styling.marginBottomMedium}
          type='title'
        >
          {i18n.t('Welkom')}
        </Text>
        <Text
          style={Styling.marginBottomMedium}
          type='description'
        >
          {i18n.t(
            `In ${data.productName} kunt u snel schademelden en heeft u toegang tot uw dossier, verzekeringsbewijs, polissen en meer. Begin hier met registreren.`
          )}
        </Text>
        {this.renderErrorToast()}
        <Input
          autoFocus
          autoCapitalize='none'
          autoCompleteType='email'
          icon='envelope'
          id='input-email'
          label={i18n.t('E-mailadres')}
          onChange={this.onChangeEmailAddress}
          placeholder={i18n.t('Uw e-mailadres')}
          value={this.state.emailAddress}
          onKeyPressEnter={this.onPressSubmitCheck}
        />
        <Button
          color={Colors.primary}
          disabled={disabled}
          id='button-continue'
          loading={this.state.loading}
          onPress={this.onPressSubmitCheck}
          style={this.state.alreadyUsed ? Styling.marginBottom : Styling.marginBottomMedium}
          title={i18n.t('Verder')}
        />

        {this.state.alreadyUsed ? this.renderButtonBack(this.closeFirstUse, Styling.marginBottomMedium) : null}
      </>
    )
  }

  renderPhone = () => (
    <Screen
      noBreadCrumbs
      white
      contentContainerStyle={this.getPaddingStyleFromInsets(
        this.props.insets,
        Sizes.screenPaddingHorizontal,
        Sizes.screenPaddingVertical
      )}
    >
      <TouchableOpacity
        onPress={this.props.openModalHelp}
        style={styles.touchableRelative}
      >
        <Text type='help'>{i18n.t('Help')}</Text>
        <Icon
          large
          color={Colors.lightBrown}
          icon='question-circle'
          style={Styling.marginLeftTiny}
        />
      </TouchableOpacity>

      {this.renderContent('phone')}
    </Screen>
  )

  renderTablet = () => (
    <Screen
      noBreadCrumbs
      onlyChildren
    >
      <ImageBackground
        imageStyle={styles.image}
        resizeMode='cover'
        source={background}
        style={Styling.fullSize}
      >
        <Bootstrap.Row style={Styling.flex}>
          <Bootstrap.Column
            offsetLeft={6}
            span={6}
            style={Styling.backgroundWhite}
          >
            <ScrollView
              contentContainerStyle={[styles.contentContainer, this.getPaddingStyleFromInsets(this.props.insets)]}
            >
              <Bootstrap.Row>
                <Bootstrap.Column span={{ lg: 2, md: 0, sm: 0, xl: 2, xs: 0 }} />
                <Bootstrap.Column
                  span={{ lg: 8, md: 12, sm: 12, xl: 8, xs: 12 }}
                  style={Styling.paddingMedium}
                >
                  <ImageAutoWidth
                    source={this.state.logo}
                    height={120}
                    style={Styling.marginBottomBig}
                  />
                  {this.renderContent('tablet')}
                </Bootstrap.Column>
                <Bootstrap.Column span={{ lg: 2, md: 0, sm: 0, xl: 2, xs: 0 }} />
              </Bootstrap.Row>
            </ScrollView>
          </Bootstrap.Column>
        </Bootstrap.Row>
      </ImageBackground>
      <TouchableOpacity
        onPress={this.props.openModalHelp}
        style={[styles.touchableAbsolute, { right: 30 + this.props.insets.right, top: 30 + this.props.insets.top }]}
      >
        <Text type='help'>{i18n.t('Help')}</Text>
        <Icon
          large
          color={Colors.lightBrown}
          icon='question-circle'
          style={Styling.marginLeftTiny}
        />
      </TouchableOpacity>
    </Screen>
  )

  render () {
    return (
      <Bootstrap.Switch
        renderPhone={this.renderPhone}
        renderTablet={this.renderTablet}
      />
    )
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  image: {
    height: '100%',
    width: '50%'
  },
  touchableAbsolute: {
    alignItems: 'center',
    flexDirection: 'row',
    position: 'absolute'
  },
  touchableRelative: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: Sizes.spacingVerticalMedium,
    marginLeft: 'auto'
  },
  alertContainer: {
    borderRadius: Sizes.borderRadius,
    backgroundColor: Colors.red,
    alignItems: 'center',
    marginBottom: Sizes.spacingVerticalMedium,
    padding: 20
  },
  alertText: {
    color: Colors.white,
    fontWeight: 'bold'
  }
})

const mapStateToProps = (state: TState, ownProps: THomeOwnProps) => {
  const action = common.getNavigationParam(ownProps, 'action')
  const email = common.getNavigationParam(ownProps, 'email')
  const password = common.getNavigationParam(ownProps, 'password')
  const passwordRepeat = common.getNavigationParam(ownProps, 'passwordRepeat')
  const registerToken = common.getNavigationParam(ownProps, 'registerToken')
  const resetPasswordToken = common.getNavigationParam(ownProps, 'resetPasswordToken')
  const token = common.getNavigationParam(ownProps, 'token')

  return {
    action,
    currentRelationId: selectors.getCurrentRelationId(state),
    emailAddress: email,
    idin: selectors.getIdin(state),
    password,
    passwordRepeat,
    registerToken,
    resetPasswordToken,
    token,
    agencyPrintout: state?.agency?.printout
  }
}

const mapDispatchToProps = {
  apiCallDispatch,
  openModalEmergencyNumber,
  openModalHelp,
  resetIdin,
  selectRelation,
  startIdin,
  signInSucces
}

export default connect(mapStateToProps, mapDispatchToProps)(withApiCall(withSafeAreaInsets(withHandleUrl(Home))))
