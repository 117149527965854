import type { StackNavigationOptions } from '@react-navigation/stack'
import React, { useContext } from 'react'
import type { ViewStyle } from 'react-native'
import { Platform, StyleSheet } from 'react-native'
import { styles as stylesText } from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import HeaderBackImage from '@/navigation/components/HeaderBackImage'
import HeaderRight from '@/navigation/components/HeaderRight'
import type { Routes } from '@/navigation/mobile/helpers/types'
import type { Dict } from '@/redux/types'
import ContextBreakpoint from '@/bootstrap/ContextBreakpoint'

export const isTabVisible = () => {
  const breakpoint = useContext(ContextBreakpoint)
  return breakpoint === 'xxs' || breakpoint === 'xs'
}

type Styles = Dict<ViewStyle>

const styles: Styles = StyleSheet.create({
  header: {
    borderBottomColor: Colors.ed,
    borderBottomWidth: 1,
    elevation: 0,
    shadowColor: 'transparent',
    shadowOffset: {
      height: 0,
      width: 0
    }
  },
  tabBar: {
    borderTopColor: Colors.ed,
    borderTopWidth: 1,
    elevation: 0,
    shadowColor: 'transparent',
    shadowOffset: {
      height: 0,
      width: 0
    }
  }
})

const headerOptions: StackNavigationOptions = {
  cardStyle: Styling.flex,
  gestureEnabled: Platform.OS !== 'web',
  headerBackImage: HeaderBackImage,
  headerBackTitle: '',
  headerBackTitleVisible: false,
  headerStyle: styles.header,
  headerTitleAlign: 'center',
  headerTitleStyle: stylesText.header,
  safeAreaInsets: {}
}

export function generateHeaderOptions (options = {}): StackNavigationOptions {
  return {
    ...headerOptions,
    headerShown: isTabVisible(),
    ...options
  }
}

export function generateHeaderOptionsNavigation (id: string, options = {}): StackNavigationOptions {
  return {
    headerRight: () => <HeaderRight key={`header-right-${id}`} />,
    ...generateHeaderOptions(options)
  }
}

export function renderStackScreens (StackComponent: any, stackName: string, screenRoutes: Routes) {
  return Object.entries(screenRoutes).map(([key, value]) => (
    <StackComponent.Screen
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...value}
      component={value.component}
      initialParams={value.initialParams}
      key={`${stackName}-${key}`}
      name={key}
      options={value.options}
    />
  ))
}
