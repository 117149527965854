import type { File } from '@/components/inputs/InputFile'
import * as CONSTANTS from '@/redux/constants/loading'
import type { ClaimQuestion } from '@/types/objects'

export const extendToken = () => ({ type: CONSTANTS.EXTEND_TOKEN })

export const setLoadingClaimAttachment = (id: string, loading: boolean) => ({
  id,
  loading,
  type: CONSTANTS.SET_LOADING_CLAIM_ATTACHMENT
})

export const removeClaimAttachment = (question: ClaimQuestion) => ({
  question,
  type: CONSTANTS.REMOVE_CLAIM_ATTACHMENT
})

export const uploadClaimAttachment = (claimDamageId: string, question: ClaimQuestion, file: File) => ({
  claimDamageId,
  file,
  question,
  type: CONSTANTS.UPLOAD_CLAIM_ATTACHMENT
})
