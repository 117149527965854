import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { Platform, StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import mapStateToProps from '@/redux/mapStateToProps'
import type { IconProp } from '@/types/props'
import prototype from '@/utils/prototype'

interface TComponentProps {
  color?: string
  width: number
  style?: StyleProp<ViewStyle>
}

export interface TIconProps {
  Component?: React.ComponentType<TComponentProps> | ((...args: any[]) => any)
  badgeNumber?: number
  circleColor?: string
  circleSize?: number
  color?: string
  colors?: any
  huge?: boolean
  icon?: IconProp
  large?: boolean
  normal?: boolean
  productId?: string
  size?: number
  small?: boolean
  style?: StyleProp<ViewStyle>
}

const productIcons: { [key: string]: string } = {
  12: 'motorcycle', // Bromfiets / Overig
  15: 'car', // Oldtimer
  18: 'couch', // Inboedel
  21: 'house', // Woonhuis
  27: 'address-card', // Aansprakelijkheid
  3: 'car', // Personenauto
  36: 'island-tropical', // Doorlopende reis
  39: 'caravan', // Caravan / Vouwwagen
  42: 'caravan', // Stacaravan
  51: 'exclamation-triangle', // Ongevallen
  58: 'gavel', // Rechtsbijstand
  64: 'rv', // Camper
  67: 'shuttle-van', // Bestelauto
  68: 'trailer', // Aanhangwagen
  71: 'tractor', // Hobbytractor
  74: 'motorcycle', // Quad / Trike
  75: 'house', // Recreatiewoning
  77: 'island-tropical', // Kortlopende reis
  78: 'cross', // Uitvaart
  81: 'gem', // Kostbaarheden
  84: 'gavel', // Rechtsbijstand - ProRechtpolis
  85: 'ship', // Pleziervaartuig
  90: 'ship', // Boot
  86: 'truck', // Vracht(Bestel)auto
  87: 'chair-office', // Inventaris
  9: 'motorcycle', // Motor
  92: 'road' // Verkeersdeelnemers
}

function Icon (props: TIconProps) {
  let icon = props.icon
  if (props.productId && props.productId in productIcons) {
    icon = productIcons[props.productId]
  } else if (typeof props.icon === 'string') {
    icon = ['fas', props.icon]
  }
  if (!icon && !props.Component) {
    return null
  }
  let size = Sizes.icon
  if (props.size) {
    size = props.size
  } else if (props.huge) {
    size = Sizes.iconHuge
  } else if (props.large) {
    size = Sizes.iconLarge
  } else if (props.small) {
    size = Sizes.iconSmall
  } else if (props.normal) {
    size = Sizes.iconNormal
  }
  if (props.circleColor) {
    let wrapperStyle: StyleProp<ViewStyle> = styles.wrapper
    if (props.circleSize) {
      wrapperStyle = generateWrapperStyle(props.circleSize)
    } else if (props.huge) {
      wrapperStyle = styles.wrapperHuge
    } else if (props.large) {
      wrapperStyle = styles.wrapperLarge
    } else if (props.small) {
      wrapperStyle = styles.wrapperSmall
    }
    return (
      <View style={[wrapperStyle, { backgroundColor: props.circleColor }, props.style]}>
        {props.Component
          ? (
            <props.Component
              color={props.color}
              width={size}
            />
            )
          : (
            <FontAwesomeIcon
              color={props.color}
              icon={icon}
              size={size}
              tabIndex={-1}
            />
            )}
      </View>
    )
  }
  if (props.Component) {
    return (
      <props.Component
        color={props.color}
        style={props.style}
        width={size}
      />
    )
  }
  // Flatten the style prop on web because the FontAwesomeIcon component only accepts an object as style prop.
  const style = Platform.OS === 'web' && props.style ? StyleSheet.flatten(props.style) : props.style
  if (prototype.isInteger(props.badgeNumber)) {
    return (
      <View style={Styling.relative}>
        <FontAwesomeIcon
          color={props.color}
          icon={icon}
          size={size}
          tabIndex={-1}
        />
        <View style={[styles.badgeWrapper, { backgroundColor: props.colors && props.colors.brand }]}>
          <Text
            style={Platform.OS === 'web' && styles.marginLeftOne}
            type='badge'
          >
            {props.badgeNumber}
          </Text>
        </View>
      </View>
    )
  }
  return (
    <FontAwesomeIcon
      color={props.color}
      icon={icon}
      size={size}
      style={style}
      tabIndex={-1}
    />
  )
}

const generateWrapperStyle = (size: number): ViewStyle => ({
  alignItems: 'center',
  borderRadius: size / 2,
  height: size,
  justifyContent: 'center',
  overflow: 'hidden',
  width: size
})

const styles = StyleSheet.create({
  badgeWrapper: {
    alignItems: 'center',
    borderColor: Colors.white,
    borderRadius: Sizes.badge / 2,
    borderWidth: 1,
    bottom: -1 * (Sizes.badge / 4),
    height: Sizes.badge,
    justifyContent: 'center',
    position: 'absolute',
    right: -1 * (Sizes.badge / 4),
    width: Sizes.badge
  },
  marginLeftOne: {
    marginLeft: 1
  },
  wrapper: generateWrapperStyle(Sizes.iconContainer),
  wrapperHuge: generateWrapperStyle(Sizes.iconContainerHuge),
  wrapperLarge: generateWrapperStyle(Sizes.iconContainerLarge),
  wrapperSmall: generateWrapperStyle(Sizes.iconContainerSmall)
})

export default connect(mapStateToProps.themeColors)(Icon)
