import ManageLogin from '@/screens/profile/ManageLogin'
import ManagePermissions from '@/screens/profile/ManagePermissions'
import ManageRelation from '@/screens/profile/ManageRelation'
import Profile from '@/screens/profile/Profile'
import Settings from '@/screens/profile/Settings'
import SwitchCombiPolicy from '@/screens/profile/SwitchCombiPolicy'
import AddRelationToCombi from '@/screens/profile/add_relation_to_combi'
import i18n from '@/utils/i18n'
import { createStackNavigator } from '@react-navigation/stack'
import ManageUsersStackNavigator from './manage_users_stack'
import ManageVerify from '@/screens/profile/ManageVerify'

const ProfileStack = createStackNavigator()
const ProfileStackNavigator = () => (
  <ProfileStack.Navigator screenOptions={{ headerShown: false }}>
    <ProfileStack.Screen
      name='Profile'
      component={Profile}
      options={{ title: i18n.t('Profiel') }}
    />
    <ProfileStack.Screen
      name='ManageLogin'
      component={ManageLogin}
      options={{ title: i18n.t('Inloggegevens wijzigen') }}
    />
    <ProfileStack.Screen
      name='ManagePermissions'
      component={ManagePermissions}
      options={{ title: i18n.t('Toestemmingen') }}
    />
    <ProfileStack.Screen
      name='ManageRelation'
      component={ManageRelation}
      options={{ title: i18n.t('Gegevens wijzigen') }}
    />
    <ProfileStack.Screen
      name='ManageUsersStack'
      component={ManageUsersStackNavigator}
      options={{ title: i18n.t('Gebruikers beheren') }}
    />
    <ProfileStack.Screen
      name='Settings'
      component={Settings}
      options={{ title: i18n.t('Voorkeuren') }}
    />
    <ProfileStack.Screen
      name='SwitchCombiPolicy'
      component={SwitchCombiPolicy}
      options={{ title: i18n.t('Selecteer combipolis') }}
    />
    <ProfileStack.Screen
      name='AddRelationToCombi'
      component={AddRelationToCombi}
      options={{ title: i18n.t('Combipolis toevoegen') }}
    />
    <ProfileStack.Screen
      name='ManageVerify'
      component={ManageVerify}
      options={{ title: i18n.t('Inlogmethode wijzigen') }}
    />
  </ProfileStack.Navigator>
)

export default ProfileStackNavigator
