import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

import ContextBreakpoint from '@/bootstrap/ContextBreakpoint'
import defaults from '@/bootstrap/defaults'
import getConfig from '@/bootstrap/getConfig'

interface TContainerProps {
  style?: StyleProp<ViewStyle>
}

export default class Container extends React.Component<TContainerProps> {
  static contextType = ContextBreakpoint

  getMaxWidthStyle (breakpoint: string): StyleProp<ViewStyle> {
    const maxContainerWidth = getConfig(breakpoint, defaults.maxContainerWidths)

    return maxContainerWidth
      ? {
          alignSelf: 'center',
          maxWidth: maxContainerWidth
        }
      : null
  }

  render () {
    return (
      <View style={[styles.view, this.getMaxWidthStyle(this.context), this.props.style]}>{this.props.children}</View>
    )
  }
}

const styles = StyleSheet.create({
  view: {
    width: '100%'
  }
})
