import * as CONSTANTS from '@/redux/constants/forms'
import * as CLAIM_CONSTANTS from '../constants/claims'

import type { File } from '@/components/inputs/InputFile'
import type { FormsActionTypes } from '@/redux/types/forms'
import type { ClaimQuestion } from '@/types/objects'
import type { TApiCallDispatch } from '../actions/api'
import type { ClaimDamageDispatch } from '../actions/claims'

export type ClaimDamageAdditionalFile =
  File &
  { loading?: boolean, attachmentId?: string }

export interface FormClaimDamage {
  answersDynamic: Record<string, any>
  answersStatic: {
    dateOfDamage?: string
    description?: string
    policyId?: string
    phoneNumber?: string
    damageResolved?: boolean
    invoiceRecieved?: boolean
    id?: string
  }
  id: string | null
  questions: ClaimQuestion[]
  syncTimestamp: number

  additionalFiles: Record<string, ClaimDamageAdditionalFile>

  showUnproccessableContentError: boolean
}

export interface FormsState {
  claimDamage: FormClaimDamage
}

export const initialStateClaimDamage: FormClaimDamage = {
  answersDynamic: {},
  answersStatic: {},
  id: null,
  questions: [],
  syncTimestamp: 0,
  additionalFiles: {},
  showUnproccessableContentError: false
}

export const initialState: FormsState = {
  claimDamage: initialStateClaimDamage
}

export default (
  state: FormsState = initialState,
  action: FormsActionTypes & TApiCallDispatch & ClaimDamageDispatch
): FormsState => {
  switch (action?.type) {
    case CLAIM_CONSTANTS.CONTINUE_CLAIM_DAMAGE_CONCEPT: {
      const { claim } = action.payload
      const answersDynamic: Record<string, string> = {}

      // eslint-disable-next-line array-callback-return
      claim?.claimChangeRequestAttributeValues?.map((item) => {
        if (item.value) {
          answersDynamic[item.id] = item.value
        }
      })

      return {
        ...state,
        claimDamage: {
          ...state.claimDamage,
          questions: initialState.claimDamage.questions,
          syncTimestamp: initialState.claimDamage.syncTimestamp,
          answersStatic: {
            ...state.claimDamage.answersStatic,
            id: claim?.id,
            dateOfDamage: claim?.dateOfLoss,
            description: claim?.text,
            policyId: claim?.policyNumber,
            phoneNumber: claim?.phoneNumber
          },
          answersDynamic,
          id: claim?.claimDamageId,
          additionalFiles: claim?.files?.reduce((previousValue, currentValue) => ({
            ...previousValue,
            [`${currentValue?.title}.${currentValue.extension}`]: currentValue
          }), {})
        }
      }
    }

    case CONSTANTS.RESET_FORM_CLAIM_DAMAGE:
      return {
        ...state,
        claimDamage: initialStateClaimDamage
      }

    case CONSTANTS.UPDATE_FORM_CLAIM_DAMAGE_DYNAMIC:
      return action.key
        ? {
            ...state,
            claimDamage: {
              ...state.claimDamage,
              answersDynamic: {
                ...state.claimDamage.answersDynamic,
                [String(action.key)]: action.update
              }
            }
          }
        : state

    case CONSTANTS.UPDATE_FORM_CLAIM_DAMAGE_STATIC: {
      let newState = state
      if (action.key) {
        newState = {
          ...state,
          claimDamage: {
            ...state.claimDamage,
            answersStatic: {
              ...state.claimDamage.answersStatic,
              [String(action.key)]: action.update
            }
          }
        }
        // When the policy changes the answers to the dynamic questions are not valid anymore.
        if (action.key === 'policyId' && action.update !== state.claimDamage.answersStatic.policyId) {
          newState.claimDamage.answersDynamic = {}
        }
      }
      return newState
    }

    case 'GET_CLAIM_DAMAGE_QUESTIONS':
      return {
        ...state,
        claimDamage: {
          ...state.claimDamage,
          questions: initialState.claimDamage.questions
        }
      }

    case 'GET_CLAIM_DAMAGE_QUESTIONS_SUCCESS': {
      const questions = action.payload.data as ClaimQuestion[]
      const answersDynamic: Record<string, string> = {}

      if (Object.keys(state.claimDamage.answersDynamic).length > 0) {
        // eslint-disable-next-line array-callback-return
        questions?.map((question) => {
          if (state.claimDamage.answersDynamic[question.id]) {
            answersDynamic[question.id] = state.claimDamage.answersDynamic[question.id]
          }
        })
      }

      return {
        ...state,
        claimDamage: {
          ...state.claimDamage,
          questions,
          syncTimestamp: Date.now(),
          answersDynamic
        }
      }
    }

    case CONSTANTS.SET_FORM_CLAIM_DAMAGE_ID:
      return {
        ...state,
        claimDamage: {
          ...state.claimDamage,
          id: action.claimDamageId
        }
      }

    case CLAIM_CONSTANTS.UPLOAD_ADDITIONAL_CLAIM_ATTACHMENT:
      return {
        ...state,
        claimDamage: {
          ...state.claimDamage,
          additionalFiles: {
            ...state.claimDamage.additionalFiles,
            [action.payload.file.name]: {
              ...action.payload.file,
              base64: '', // Do not bloat state
              loading: true
            }
          }
        }
      }

    case CLAIM_CONSTANTS.UPLOADED_ADDITIONAL_CLAIM_ATTACHMENT:
      return {
        ...state,
        claimDamage: {
          ...state.claimDamage,
          additionalFiles: {
            ...state.claimDamage.additionalFiles,
            [action.payload.title]: {
              ...state.claimDamage.additionalFiles[action.payload.title],
              ...action.payload,
              name: action.payload.title,
              loading: false
            }
          },
          showUnproccessableContentError: false
        }
      }

    case CLAIM_CONSTANTS.DELETE_ADDITIONAL_CLAIM_ATTACHMENT:
      return {
        ...state,
        claimDamage: {
          ...state.claimDamage,
          additionalFiles: {
            ...state.claimDamage.additionalFiles,
            [action.payload.title]: {
              ...state.claimDamage.additionalFiles[action.payload.title],
              loading: true
            }
          }
        }
      }

    case CLAIM_CONSTANTS.DELETE_ADDITIONAL_CLAIM_ATTACHMENT_FAILED:
      return {
        ...state,
        claimDamage: {
          ...state.claimDamage,
          additionalFiles: {
            ...state.claimDamage.additionalFiles,
            [action.payload.title]: {
              ...state.claimDamage.additionalFiles[action.payload.title],
              loading: false
            }
          }
        }
      }

    case CLAIM_CONSTANTS.DELETED_ADDITIONAL_CLAIM_ATTACHMENT: {
      const newAdditionalFiles = { ...state.claimDamage.additionalFiles }

      delete newAdditionalFiles[action.payload.title]

      return {
        ...state,
        claimDamage: {
          ...state.claimDamage,
          additionalFiles: newAdditionalFiles
        }
      }
    }

    case 'SELECT_RELATION':
      return initialState

    case CLAIM_CONSTANTS.SHOW_UNPROCESSABLE_CONTENT_ERROR: {
      return {
        ...state,
        claimDamage: {
          ...state.claimDamage,
          showUnproccessableContentError: true
        }
      }
    }

    case CLAIM_CONSTANTS.HIDE_UNPROCESSABLE_CONTENT_ERROR: {
      return {
        ...state,
        claimDamage: {
          ...state.claimDamage,
          showUnproccessableContentError: false
        }
      }
    }

    default:
      return state
  }
}
