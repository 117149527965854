import moment from 'moment'
import React, { useState } from 'react'
import { View } from 'react-native'
import CalendarPicker from 'react-native-calendar-picker'

import { styles as stylesText } from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import i18n from '@/utils/i18n'

const weekdays = [i18n.t('Ma'), i18n.t('Di'), i18n.t('Wo'), i18n.t('Do'), i18n.t('Vr'), i18n.t('Za'), i18n.t('Zo')]
const months = [
  i18n.t('Januari'),
  i18n.t('Februari'),
  i18n.t('Maart'),
  i18n.t('April'),
  i18n.t('Mei'),
  i18n.t('Juni'),
  i18n.t('Juli'),
  i18n.t('Augustus'),
  i18n.t('September'),
  i18n.t('Oktober'),
  i18n.t('November'),
  i18n.t('December')
]
const maxDate = new Date()

interface TCalendarPickerProps {
  date: string | number | Date
  minDate?: Date
  maxDate?: Date
  onDateChange: (date: Date) => void
}

export default function _CalendarPicker (props: TCalendarPickerProps) {
  const [width, setWidth] = useState(0)
  return (
    <>
      {width
        ? (
          <CalendarPicker
            scrollable
            startFromMonday
            initialDate={props.date ? moment(props.date) : undefined}
            minDate={props.minDate}
            maxDate={props.maxDate || maxDate}
            months={months}
            nextTitle={i18n.t('Volgende')}
            onDateChange={props.onDateChange}
            previousTitle={i18n.t('Vorige')}
            selectedDayColor={Colors.primary}
            selectedDayTextColor={Colors.white}
            selectedStartDate={props.date ? moment(props.date) : undefined}
            selectMonthTitle={`${i18n.t('Selecteer maand in')} `}
            selectYearTitle={i18n.t('Selecteer jaartal')}
            textStyle={stylesText.text}
            todayBackgroundColor={Colors.e8}
            todayTextStyle={stylesText.textBold}
            weekdays={weekdays}
            width={width}
          />
          )
        : null}
      <View
        onLayout={(event) => {
          if (
            event?.nativeEvent?.layout?.width &&
            event.nativeEvent.layout.width !== width
          ) {
            setWidth(event.nativeEvent.layout.width)
          }
        }}
        style={Styling.fullWidth}
      />
    </>
  )
}
