import { REHYDRATE } from 'redux-persist'

import * as CONSTANTS from '@/redux/constants/user'
import type * as TYPES from '@/redux/types/user'
import type { User } from '@/types/objects'
import prototype from '@/utils/prototype'

export type UserState = User

export const initialState: UserState = {
  dateOfBirth: '',
  email: '',
  facialRecognition: false,
  financialMessages: false,
  fingerprint: false,
  firstName: '',
  id: '',
  informativeMessages: false,
  initials: '',
  insuranceMessages: false,
  lastName: '',
  logo: '',
  mainUser: false,
  memberBenefits: false,
  password: '',
  pincode: '',
  relationId: '',
  termsRead: false,
  token: '',
  tokenExpiresAt: '',
  tokenExtendedAt: '',
  userIdSentToOneSignal: false,
}

export default (
  state: UserState = initialState,
  action: TYPES.UserActionTypes & TYPES.UpdatePasswordAction
): UserState => {
  switch (action?.type) {
    case CONSTANTS.USER_ID_SENT_TO_ONESIGNAL:
      return {
        ...state,
        userIdSentToOneSignal: true
      }

    case CONSTANTS.RESET_TOKEN:
      return {
        ...state,
        token: '',
        tokenExpiresAt: '',
        tokenExtendedAt: ''
      }

    case CONSTANTS.UPDATE_USER:
      return prototype.isObject(action.updates)
        ? {
            ...state,
            ...(action.updates || {})
          }
        : state

    case CONSTANTS.SET_PINCODE:
      return action.pincode
        ? {
            ...state,
            pincode: action.pincode
          }
        : state

    case CONSTANTS.UPDATE_PUSH_TOKEN:
      return action.pushToken
        ? {
            ...state,
            pushToken: action.pushToken
          }
        : state

    case CONSTANTS.UPDATE_TOKEN:
      return action.token
        ? {
            ...state,
            token: action.token
          }
        : state

    case CONSTANTS.UPDATE_TOKEN_EXPIRES_AT:
      return action.tokenExpiresAt
        ? {
            ...state,
            tokenExpiresAt: action.tokenExpiresAt
          }
        : state

    case CONSTANTS.UPDATE_TOKEN_EXTENDED_AT:
      return action.tokenExtendedAt
        ? {
            ...state,
            tokenExtendedAt: action.tokenExtendedAt
          }
        : state

    case CONSTANTS.UPDATE_PASSWORD:
      return action.password
        ? {
            ...state,
            password: action.password
          }
        : state

    case CONSTANTS.SET_FACIAL_RECOGNITION:
      return prototype.isBoolean(action.value)
        ? {
            ...state,
            facialRecognition: action.value
          }
        : state

    case CONSTANTS.SET_FINGERPRINT:
      return prototype.isBoolean(action.value)
        ? {
            ...state,
            fingerprint: action.value
          }
        : state

    case REHYDRATE:
      return {
        ...state,
        // @ts-expect-error
        ...action.payload?.user,
        token: __DEV__ ? action?.payload?.user?.token : initialState.token,
        tokenExpiresAt: __DEV__ ? action?.payload?.user?.tokenExpiresAt : initialState.tokenExpiresAt,
        tokenExtendedAt: __DEV__ ? action?.payload?.user?.tokenExtendedAt : initialState.tokenExtendedAt
      }

    default:
      return state
  }
}
