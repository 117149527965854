import React from 'react'
import Select from '@/components/inputs/InputSelect'
import type { IProduct } from '@/types/objects'
import i18n from '@/utils/i18n'
import { useDispatch, useSelector } from 'react-redux'
import { updateAdviseProduct, removeProductRow } from '@/redux/actions/policy_advise'
import Button from '@/components/buttons/Button'
import selectors from '@/redux/selectors'
import Colors from '@/constants/Colors'
import { View, Platform, StyleSheet } from 'react-native'

interface PorductSelectProps {
  products: IProduct[]
  value: string
  index: number
}

const ProductSelect: React.FC<PorductSelectProps> = ({ products, value, index }) => {
  const colors = useSelector(selectors.getThemeColors)
  const dispatch = useDispatch()

  const onChange = (event: string): void => {
    dispatch(updateAdviseProduct(index, event))
  }

  const onRemove = () => {
    dispatch(removeProductRow(index))
  }

  const items = products.map(product => { return { key: product.id, name: product.name, label: product.name, value: product.name } })
  return (
    <View style={styles.selectWrapper}>
      <Select
        items={items}
        onChange={onChange}
        value={value}
        placeholder={i18n.t('Selecteer product')}
        style={styles.select}
      />
      <Button
        icon='trash'
        iconColor={colors.brand}
        color={Colors.transparent}
        onPress={onRemove}
        iconStyle={[styles.iconStyle, { opacity: index === 0 ? 0 : 1 }]}
        disabled={index === 0}
        style={styles.button}
      />
    </View>

  )
}

const styles = StyleSheet.create({
  selectWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 80
  },
  select: {
    flexGrow: 1,
    marginVertical: 0,
    paddingVertical: 0
  },
  iconStyle: {
    marginRight: 0
  },
  button: {
    alignSelf: Platform.OS === 'web' ? 'flex-start' : undefined,
    paddingRight: 0
  }
})

export default ProductSelect
