import type { RefObject } from 'react'
import React from 'react'
import type {
  KeyboardTypeOptions, NativeSyntheticEvent,
  StyleProp, TextInputKeyPressEventData,
  TextInputProps,
  ViewStyle
} from 'react-native'
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  TextInput,
  TouchableWithoutFeedback,
  View
} from 'react-native'

import Icon from '@/components/Icon'
import Label from '@/components/Label'
import TouchableIcon from '@/components/touchables/TouchableIcon'
import Text, { styles as stylesText } from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import type { IconProp } from '@/types/props'
import { IS_WEB } from '@/constants/platform'

export interface TInputProps {
  autoCapitalize?: TextInputProps['autoCapitalize']
  autoFocus?: TextInputProps['autoFocus']
  description?: string
  disabled?: boolean
  editable?: boolean
  error?: boolean
  errorDescription?: string
  icon?: IconProp
  iconLocation?: IconProp
  iconRight?: IconProp
  addonRight?: string
  id?: string
  keyboardType?: KeyboardTypeOptions
  label?: string
  loading?: boolean
  minHeight?: number
  multiline?: boolean
  noMargin?: boolean
  numberOfLines?: number
  onChange?: (text: string) => void
  onPress?: (...args: any[]) => any
  onPressLocation?: (...args: any[]) => any
  onSubmitEditing?: (...args: any[]) => any
  placeholder?: string
  registerRef?: (...args: any[]) => any
  required?: boolean
  secureTextEntry?: boolean
  showLocation?: boolean
  style?: StyleProp<ViewStyle>
  success?: boolean
  successDescription?: string
  textAlignVertical?: string
  tooltip?: string
  value?: string
  warning?: boolean
  maxLength?: number
  showMaxLength?: boolean
  warningDescription?: string
  autoCompleteType?: TextInputProps['autoCompleteType']
  onBlur?: TextInputProps['onBlur']
  onEndEditing?: TextInputProps['onEndEditing']
  onKeyPressEnter?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  nextInput?: RefObject<Input>
}

export default class Input extends React.Component<TInputProps> {
  refTextInput: any

  focusTextInput = () => {
    if (this.refTextInput && typeof this.refTextInput.focus === 'function') {
      this.refTextInput.focus()
    }
  }

  setRefTextInput = (ref: any) => {
    this.refTextInput = ref
    if (typeof this.props.registerRef === 'function') {
      this.props.registerRef(ref)
    }
  }

  onSubmitEditing = () => {
    if (typeof this.props.onSubmitEditing === 'function') {
      this.props.onSubmitEditing()
    }
  }

  onPress = () => {
    if (typeof this.props.onPress === 'function') {
      this.props.onPress()
    }
    if (this.isEnabled()) {
      this.focusTextInput()
    }
  }

  isEnabled = () => !(this.props.editable === false || this.props.disabled === true)

  onChange = (value: string) => {
    const { autoCapitalize } = this.props

    // Manual implementation for web as react-native-web does not do this
    if (IS_WEB && autoCapitalize) {
      switch (autoCapitalize) {
        case 'characters':
          this.props?.onChange?.(
            value?.toUpperCase()
          )
          return
        case 'words':
          this.props?.onChange?.(
            value?.replace(/\b\w/g, l => l.toUpperCase())
          )
          return
        case 'none':
        case 'sentences':
          this.props?.onChange?.(value)
          return
      }
    }

    this.props?.onChange?.(value)
  }

  handleKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    const { onKeyPressEnter, nextInput } = this.props

    const key = e.nativeEvent.key

    switch (key) {
      case 'Enter':
        return onKeyPressEnter && onKeyPressEnter(e)
      case 'Tab':
        if (!nextInput?.current) {
          return
        }

        e.preventDefault()
        return nextInput?.current?.focusTextInput()
    }
  }

  renderContent = () => {
    const enabled = this.isEnabled()

    let container = styles.container
    let description = this.props.description
    let descriptionType = 'input'
    let labelType = 'label'
    if (this.props.disabled === true) {
      container = styles.containerDisabled
    } else if (this.props.error) {
      container = styles.containerError
      description = this.props.errorDescription
      descriptionType = 'inputError'
      labelType = 'labelError'
    } else if (this.props.warning) {
      container = styles.containerWarning
      description = this.props.warningDescription
      descriptionType = 'inputWarning'
      labelType = 'labelWarning'
    } else if (this.props.success) {
      container = styles.containerSuccess
      description = this.props.successDescription
      descriptionType = 'inputSuccess'
      labelType = 'labelSuccess'
    }

    // On web the below props for the TextInput are not supported.
    const extraPropsTextInput: any = {}
    if (Platform.OS !== 'web') {
      extraPropsTextInput.minHeight = this.props.minHeight
      extraPropsTextInput.textAlignVertical = this.props.textAlignVertical
    }

    // @note: This solution only works for a single component to the left.
    let extraPaddingLeft = null
    if (this.props.icon) {
      extraPaddingLeft = {
        paddingLeft: Sizes.spacingHorizontal + Sizes.spacingHorizontal + Sizes.icon
      }
    }

    return (
      <View style={this.props.noMargin ? this.props.style : [styles.marginBottom, this.props.style]}>
        <Label
          value={this.props.value}
          label={this.props.label}
          required={this.props.required}
          tooltip={this.props.tooltip}
          type={labelType}
          maxLength={this.props.maxLength}
          showMaxLength={this.props.showMaxLength}
        />

        <View
          style={container}
          pointerEvents={!enabled ? 'box-only' : undefined}
        >
          {this.props.addonRight !== undefined
            ? (
              <View style={styles.rightAddon}>
                <Text type='description'>{this.props.addonRight}</Text>
              </View>
              )
            : null}
          <View
            style={styles.containerStyle}
          >
            {this.props.icon !== undefined
              ? (
                <Icon
                  color={Colors.brown}
                  icon={this.props.icon}
                  size={Sizes.icon}
                  style={styles.iconLeft}
                />
                )
              : null}
            <TextInput
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...extraPropsTextInput}
              accessibilityLabel={this.props.id}
              autoCapitalize={this.props.autoCapitalize}
              autoCompleteType={this.props.autoCompleteType}
              editable={enabled}
              key={this.props.id}
              keyboardType={this.props.keyboardType}
              maxLength={this.props.maxLength}
              multiline={this.props.multiline}
              numberOfLines={this.props.numberOfLines}
              onBlur={this.props.onBlur}
              onChangeText={this.onChange}
              onSubmitEditing={this.onSubmitEditing}
              onKeyPress={this.handleKeyPress}
              autoFocus={this.props.autoFocus}
              placeholder={this.props.placeholder}
              onEndEditing={this.props.onEndEditing}
              placeholderTextColor={Colors.lightBrown}
              ref={this.setRefTextInput}
              secureTextEntry={this.props.secureTextEntry}
              style={[stylesText.input, styles.textInput, extraPaddingLeft]}
              value={typeof this.props.value === 'number' ? String(this.props.value) : this.props.value || ''}
            />
            <View style={Styling.rowCenter}>
              {this.props.loading
                ? <ActivityIndicator
                    color={Colors.brown}
                    style={Styling.marginRight}
                  />
                : null}

              {this.props.iconRight
                ? <Icon
                    color={Colors.brown}
                    icon={this.props.iconRight}
                    style={Styling.marginRight}
                  />
                : null}

              {this.props.error
                ? <Icon
                    color={Colors.red}
                    icon='times-circle'
                    style={Styling.marginRight}
                  />
                : null}

              {this.props.warning
                ? <Icon
                    color={Colors.orange}
                    icon='exclamation-circle'
                    style={Styling.marginRight}
                  />
                : null}

              {this.props.success
                ? <Icon
                    color={Colors.success}
                    icon='check-circle'
                    style={Styling.marginRight}
                  />
                : null}

              {this.props.showLocation
                ? (
                  <TouchableIcon
                    color={Colors.brown}
                    icon={this.props.iconLocation || 'location'}
                    onPress={this.props.onPressLocation}
                    touchableStyle={Styling.marginRight}
                  />
                  )
                : null}
            </View>
          </View>
        </View>

        {description
          ? (
            <Text
              style={styles.description}
              type={descriptionType}
            >
              {description}
            </Text>
            )
          : null}
      </View>
    )
  }

  render () {
    if (this.isEnabled() || typeof this.props.onPress === 'function') {
      return (
        <TouchableWithoutFeedback
          accessible={false}
          onPress={this.onPress}
        >
          {this.renderContent()}
        </TouchableWithoutFeedback>
      )
    }
    return this.renderContent()
  }
}

const borderStyle: ViewStyle = {
  borderRadius: Sizes.borderRadius,
  borderWidth: 1,
  flexDirection: 'row',
  overflow: 'hidden'
}

const styles = StyleSheet.create({
  containerStyle: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'space-between',
    backgroundColor: Colors.white,
    flexDirection: 'row',
    position: 'relative'
  },
  container: {
    ...borderStyle,
    borderColor: Colors.cc
  },
  containerDisabled: {
    ...borderStyle,
    backgroundColor: Colors.ed,
    borderColor: Colors.ed
  },
  containerError: {
    ...borderStyle,
    borderColor: Colors.red
  },
  containerSuccess: {
    ...borderStyle,
    borderColor: Colors.success
  },
  containerWarning: {
    ...borderStyle,
    borderColor: Colors.orange
  },
  description: {
    marginTop: Sizes.labelMarginVertical
  },
  iconLeft: {
    left: Sizes.spacingHorizontal,
    position: 'absolute'
  },
  label: {
    marginBottom: Sizes.labelMarginVertical
  },
  rightAddon: {
    justifyContent: 'center',
    borderEndWidth: 1,
    borderColor: Colors.cc,
    backgroundColor: Colors.lighterBrown,
    paddingHorizontal: Sizes.spacingHorizontal,
  },
  marginBottom: {
    marginBottom: Sizes.inputMarginVertical
  },
  textInput: {
    flex: 1,
    paddingBottom: Sizes.spacingVertical,
    paddingHorizontal: Sizes.spacingHorizontal,
    paddingTop: Sizes.spacingVertical
  }
})
