import React, { useEffect } from 'react'

import Cards from '@/components/Cards'
import Screen from '@/components/Screen'

import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

import { useDispatch, useSelector } from 'react-redux'
import selectors from '@/redux/selectors'
import { apiCall } from '@/redux/actions/api'
import { ENTITIES } from '@/redux/constants'
import RefreshControl from '@/components/wrappers/RefreshControl'
import type { Policy } from '@/types/objects'

const cardMapper = (policy: Policy & { valueMeterEnabled: boolean }) => {
  const isHousehold = policy?.productName === 'Inboedel'

  const navigate = async () => await navigation.navigate(
    isHousehold ? 'HouseholdEffectsValueMeter' : 'RebuildValueMeter',
    { policyId: policy?.id }
  )

  return ({
    icon: isHousehold ? 'couch' : 'house',
    id: `${policy?.productName}-value-meter-${policy.id}`,
    onPress: navigate,
    title: `${isHousehold ? i18n.t('Inboedelwaardemeter') : i18n.t('Herbouwwaardemeter')}\n(${policy?.details?.zip_code?.code}, ${policy.details?.house_number?.code})`,
    disabled: !policy.valueMeterEnabled
  })
}

const ValueMeters: React.FC = () => {
  const dispatch = useDispatch()
  const fetchPolicies = () => dispatch(apiCall('GET', ENTITIES.POLICIES))
  const policiesLoading = !!useSelector(selectors.isLoading(ENTITIES.POLICIES))
  const policies = useSelector(selectors.getValueMeterPolicies)

  useEffect(() => { fetchPolicies() }, [])

  return (
    <Screen refreshControl={<RefreshControl
      refreshing={policiesLoading}
      onRefresh={fetchPolicies}
                            />}
    >
      <Cards
        cards={policies.map(cardMapper)}
        id='value-meters'
      />
    </Screen>
  )
}

export default ValueMeters
