import Text from '@/components/wrappers/Text'
import React, { useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/buttons/Button'
import Screen from '../../../../components/Screen'
import Colors from '../../../../constants/Colors'
import Styling from '../../../../constants/Styling'
import { generatedGreenCard } from '@/redux/actions/policies'
import { generateGreenCard } from '@/redux/api/policies'
import selectors from '../../../../redux/selectors'
import type { GeneratedGreenCard, Policy } from '@/types/objects'
import toast from '../../../../utils/toast'
import * as Bootstrap from '@/bootstrap'
import navigation from '../../../../utils/navigation'
import Block from '../../../../components/Block'

interface Props {
  route: {
    params: {
      policyId: string
    }
  }
}

function GenerateGreenCard (props: Props) {
  const policy = useSelector(state => selectors.getPolicy(state, props.route.params.policyId))
  const pullingVehiclePolicies = useSelector(state => selectors.getPoliciesActivePullingVehicles(state))
  const [clickedPolicyId, setClickedPolicyId] = useState<string | undefined>(undefined)
  const dispatch = useDispatch()

  if (!policy) {
    return null
  }

  async function generateGreenCardApi (pullingVehiclePolicy: Policy) {
    setClickedPolicyId(pullingVehiclePolicy.id)

    try {
      const result = await generateGreenCard(pullingVehiclePolicy.id, policy!.id)
      if (result.success) {
        dispatch(generatedGreenCard(policy!.id, result.data.insuranceCard as GeneratedGreenCard))
        toast('Verzekeringsbewijs aangemaakt')
      } else {
        toast('Verzekeringsbewijs aanmaken mislukt. Probeer het later opnieuw.')
      }
    } catch (err) {
      console.warn(err)
      toast('Verzekeringsbewijs aanmaken mislukt. Probeer het later opnieuw.')
    } finally {
      await navigation.back()
    }
  }

  return (
    <Screen>
      <Bootstrap.Row>
        <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 6, xs: 12, xxs: 12 }}>
          <Block>
            <Text
              type='subtitle'
              bold
              style={Styling.marginBottomBig}
            >Selecteer trekkend voertuig
            </Text>

            {pullingVehiclePolicies.length === 0
              ? (
                <View>
                  <Text
                    type='description'
                    style={Styling.marginBottomBig}
                  >
                    Geen trekkend voertuig verzekerd bij Nh1816 Verzekeringen.
                    Vraag een verzekeringsbewijs aan bij de verzekeraar van het trekkende voertuig
                    of neem contact op met uw adviseur.
                  </Text>

                  <Button
                    color={Colors.primary}
                    title='Sluiten'
                    onPress={navigation.goBack}
                  />

                </View>
                )
              : pullingVehiclePolicies.map(p => (
                <Button
                  style={Styling.marginBottom}
                  key={p.id}
                  title={p.details.license_plate?.code}
                  onPress={async () => await generateGreenCardApi(p)}
                  disabled={!!clickedPolicyId}
                  loading={p.id === clickedPolicyId}
                />
              ))}
          </Block>
        </Bootstrap.Column>
      </Bootstrap.Row>
    </Screen>
  )
}

export default GenerateGreenCard
