import React, { useContext } from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

import ContextBreakpoint from '@/bootstrap/ContextBreakpoint'
import Dot from '@/components/Dot'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import withAnimBlink from '@/hocs/withAnimBlink'

import ChristmasTree from '@/svgs/ChristmasTree'
import date from '@/utils/date'
import { isAfter, isBefore } from 'date-fns'

const TextAnimBlink = withAnimBlink(Text)

interface IProps {
  disabled?: boolean
  done?: boolean
  error?: boolean
  focus?: boolean
  pincode?: string
  size?: number
  style?: StyleProp<ViewStyle>
  success?: boolean
}

const isChristmasTime = () => (
  isAfter(new Date(), date.dutchDateToJSDate('20-12-2021')) &&
  isBefore(new Date(), date.dutchDateToJSDate('01-01-2022'))
)

const Pincode: React.FC<IProps> = (props) => {
  const breakpoint = useContext(ContextBreakpoint)
  const itemStyle = breakpoint === 'xxs' ? styles.itemSmall : styles.item

  const renderEasterEgg = (index: number) => {
    const key = `pincode-item-${index}`

    if (index === props.pincode?.length) {
      return (
        <View
          key={key}
          style={[styles.current, itemStyle]}
        >
          {props.focus
            ? (
              <TextAnimBlink
                color={Colors.brown}
                type='pincode'
              >
                _
              </TextAnimBlink>
              )
            : null}
        </View>
      )
    }

    let color = Colors.white

    if (props.done) { color = Colors.gray }
    if (props.error) { color = Colors.red }
    if (index < (props.pincode ?? '').length) { color = Colors.primary }
    if (props.success) { color = Colors.success }

    return (
      <View
        key={key}
        style={[styles.dot, itemStyle]}
      >
        <ChristmasTree
          width={20}
          height={20}
          fill={color}
        />
      </View>
    )
  }

  function renderItem (index: number) {
    if (isChristmasTime()) {
      return renderEasterEgg(index)
    }

    const pincode = props.pincode || ''
    const key = `pincode-item-${index}`

    if (props.success) {
      return (
        <View
          key={key}
          style={[styles.dot, itemStyle]}
        >
          <Dot
            large
            color={Colors.success}
          />
        </View>
      )
    }

    if (props.done) {
      return (
        <View
          key={key}
          style={[styles.dot, itemStyle]}
        >
          <Dot
            large
            color={Colors.gray}
          />
        </View>
      )
    }

    if (props.error) {
      return (
        <View
          key={key}
          style={[styles.dot, itemStyle]}
        >
          <Dot
            large
            color={Colors.red}
          />
        </View>
      )
    }

    if (index < pincode.length) {
      return (
        <View
          key={key}
          style={[styles.dot, itemStyle]}
        >
          <Dot
            large
            color={Colors.primary}
          />
        </View>
      )
    }

    if (props.disabled) {
      return (
        <View
          key={key}
          style={[styles.empty, itemStyle]}
        />
      )
    }

    if (index === pincode.length) {
      return (
        <View
          key={key}
          style={[styles.current, itemStyle]}
        >
          {props.focus
            ? (
              <TextAnimBlink
                color={Colors.brown}
                type='pincode'
              >
                _
              </TextAnimBlink>
              )
            : null}
        </View>
      )
    }

    return (
      <View
        key={key}
        style={[styles.empty, itemStyle]}
      />
    )
  }

  return (
    <View style={props.style ? [Styling.rowBetween, props.style] : Styling.rowBetween}>
      {Array.from(Array(props.size).keys()).map(renderItem)}
    </View>
  )
}

const styles = StyleSheet.create({
  current: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderColor: Colors.brown,
    borderWidth: 1,
    justifyContent: 'center'
  },
  dot: {
    alignItems: 'center',
    backgroundColor: Colors.e8,
    justifyContent: 'center'
  },
  empty: {
    backgroundColor: Colors.e8
  },
  item: {
    borderRadius: Sizes.pincode / 2,
    height: Sizes.pincode,
    overflow: 'hidden',
    width: Sizes.pincode
  },
  itemSmall: {
    borderRadius: Sizes.pincodeSmall / 2,
    height: Sizes.pincodeSmall,
    overflow: 'hidden',
    width: Sizes.pincodeSmall
  }
})

export default Pincode
