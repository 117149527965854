import * as ExpoSharing from 'expo-sharing'

import logger from '@/utils/logger'

class Sharing {
  logError = (error: Error) => logger.warn('Sharing:', String(error), error)

  share = async (url: string, options?: object) => {
    try {
      return await ExpoSharing.shareAsync(url, options)
    } catch (error) {
      this.logError(error)
    }
    return null
  }
}

export default new Sharing()
