/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { Platform, Dimensions, StyleSheet, View } from 'react-native'
import HTMLView from 'react-native-htmlview'
import RenderHTML from 'react-native-render-html'
import { connect } from 'react-redux'

import A from '@/components/A'
import Button from '@/components/buttons/Button'
import Dot from '@/components/Dot'
import Text, { stylesObject as stylesObjectText } from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import { openModalEmergencyNumber } from '@/redux/actions/modals'
import mapStateToProps from '@/redux/mapStateToProps'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

import type { ThemeColors } from '@/redux/reducers/theme'

const imagesMaxWidth = Dimensions.get('window').width

interface THTMLProps {
  colors: ThemeColors
  enableRenderer?: boolean
  html: string
  openModalEmergencyNumber: typeof openModalEmergencyNumber
  style?: StyleProp<ViewStyle>
}

const styles = StyleSheet.create({
  a: {
    color: Colors.primary,
    textDecorationLine: 'underline'
  },
  componentWrapper: {
    alignItems: 'center',
    marginRight: Sizes.spacingHorizontalTiny,
    width: 20
  },
  marginTop: {
    marginTop:
      ((stylesObjectText.description.lineHeight as number) || (stylesObjectText.description.fontSize as number)) / 2
  },
  row: {
    flexDirection: 'row'
  },
  firstCell: {
    flex: 2
  },
  cell: {
    flex: 4
  }
})

const a = (children: any, index: number, href: string, target?: string) => (
  <A
    Component={Platform.OS === 'web' ? undefined : Text}
    href={href}
    key={`a-${index}`}
    style={styles.a}
    target={target}
    type='description'
  >
    {children}
  </A>
)

const div = (children: any) => children

const ul = (children: any) => children

const ol = (children: any) => children

const li = (children: any, index: number, parentTag: string) => {
  let Component
  if (parentTag === 'ol') {
    Component = <Text type='description'>{(index + 1) / 2}.</Text>
  } else {
    Component = (
      <Dot
        tiny
        style={styles.marginTop}
      />
    )
  }
  return (
    <View
      key={`li-${index}`}
      style={Styling.row}
    >
      <View style={styles.componentWrapper}>{Component}</View>
      <Text type='description'>{children}</Text>
    </View>
  )
}

const h1 = (children: any, index: number) => (
  <Text
    key={`h1-${index}`}
    type='title'
  >
    {children}
  </Text>
)

const h2 = (children: any, index: number) => (
  <Text
    key={`h2-${index}`}
    type='subtitle'
  >
    {children}
  </Text>
)

const h3 = (children: any, index: number) => (
  <Text
    key={`h3${index}`}
    type='section'
  >
    {children}
  </Text>
)

const p = (children: any, index: number) => (
  <Text
    key={`p-${index}`}
    type='description'
  >
    {children}
  </Text>
)

const strong = (children: any, index: number) => (
  <Text
    key={`strong-${index}`}
    type='descriptionBold'
  >
    {children}
  </Text>
)

const Table: React.FC<{ node: any, defaultRenderer: any, index: number }> = (props) => {
  const { node, defaultRenderer, index } = props
  return (
    <View key={`table-${index}`}>
      {node.children.filter(bodyItem => bodyItem.name === 'tbody' || bodyItem.name === 'thead' || bodyItem.name === 'tfoot').map((body: any, indexBody: number) =>
        <View key={`table-${index}-body-${indexBody}`}>
          {body.children.filter(rowItem => rowItem.name === 'tr').map((row: any, indexRow: number) =>
            <View
              style={styles.row}
              key={`table-${index}-row-${indexRow}`}
            >
              {row.children.filter(cellItem => cellItem.name === 'td' || cellItem.name === 'th').map((cell: any, indexCell: number) =>
                <View
                  style={indexCell === 0 ? styles.firstCell : styles.cell}
                  key={`table-${index}-row-${indexRow}-cell-${indexCell}`}
                >
                  <Text
                    type='description'
                  >
                    {defaultRenderer(cell.children, cell)}
                  </Text>
                </View>
              )}
            </View>
          )}
        </View>
      )}
    </View>
  )
}

class HTML extends React.Component<THTMLProps> {
  renderers = {
    div: (_htmlAttribs: any, children: any) => div(children),
    h1: (_htmlAttribs: any, children: any, _convertedCSSStyles: any, passProps: any) =>
      h1(children, passProps.nodeIndex),
    h2: (_htmlAttribs: any, children: any, _convertedCSSStyles: any, passProps: any) =>
      h2(children, passProps.nodeIndex),
    h3: (_htmlAttribs: any, children: any, _convertedCSSStyles: any, passProps: any) =>
      h3(children, passProps.nodeIndex),
    li: (_htmlAttribs: any, children: any, _convertedCSSStyles: any, passProps: any) =>
      li(children, passProps.nodeIndex, passProps.parentTag),
    ol: (_htmlAttribs: any, children: any) => ol(children),
    p: (_htmlAttribs: any, children: any, _convertedCSSStyles: any, passProps: any) => p(children, passProps.nodeIndex),
    strong: (_htmlAttribs: any, children: any, _convertedCSSStyles: any, passProps: any) =>
      strong(children, passProps.nodeIndex),
    ul: (_htmlAttribs: any, children: any) => ul(children)
  }

  renderNode = (node: any, index: number, _: any, parent: any, defaultRenderer: any) => {
    switch (node?.name) {
      case 'a':
        return a(defaultRenderer(node.children, parent), index, node.attribs?.href, node.attribs?.target)
      case 'div':
        return div(defaultRenderer(node.children, parent))
      case 'h1':
        return h1(defaultRenderer(node.children, parent), index)
      case 'h2':
        return h2(defaultRenderer(node.children, parent), index)
      case 'h3':
        return h3(defaultRenderer(node.children, parent), index)
      case 'li':
        return li(defaultRenderer(node.children, parent), index, node.parent?.name)
      case 'ol':
        return ol(defaultRenderer(node.children, parent))
      case 'p':
        return p(defaultRenderer(node.children, parent), index)
      case 'strong':
        return strong(defaultRenderer(node.children, parent), index)
      case 'ul':
        return ul(defaultRenderer(node.children, parent))
      case 'button-emergency':
        return (
          <React.Fragment key={`fragment-button-emergency-${index}`}>
            <Button
              color={Colors.red}
              icon='phone'
              iconCircleColor={Colors.white}
              id='button-call-emergency'
              key={`button-emergency-${index}`}
              onPress={this.props.openModalEmergencyNumber}
              style={Styling.selfStart}
              title={i18n.t('Noodnummer bellen')}
            />
            {defaultRenderer(node.children, parent)}
          </React.Fragment>
        )
      case 'button-link':
        return (
          <React.Fragment key={`fragment-button-link-${index}`}>
            <Button
              color={this.props.colors?.brand}
              key={`button-link${index}`}
              onPress={async () => await navigation.openScreen(node.attribs['data-screen'])}
              style={Styling.selfStart}
              title={node.attribs['data-title']}
            />
            {defaultRenderer(node.children, parent)}
          </React.Fragment>
        )
      case 'table':
        return (
          <Table
            node={node}
            defaultRenderer={defaultRenderer}
            index={index}
          />
        )
      default:
        if (node.name === undefined && (!node.data || !node.data.trim().length)) {
          return null
        }
        return undefined
    }
  }

  render () {
    if (!this.props.html) {
      return null
    }

    if (this.props.enableRenderer) {
      // The RenderHTML component is available as option because in the future there might be a need of a feature
      // which is not available in the HTMLView component.
      return (
        <RenderHTML
          decodeEntities
          html={this.props.html}
          imagesMaxWidth={imagesMaxWidth}
          renderers={this.renderers}
          style={this.props.style}
          tagsStyles={styles}
        />
      )
    }

    return (
      // @ts-expect-error ts-migrate(2769) FIXME: Type '{ a: { color: string; textDecorationLine: st... Remove this comment to see the full error message
      <HTMLView
        renderNode={this.renderNode}
        style={this.props.style}
        stylesheet={styles}
        value={this.props.html}
      />
    )
  }
}

export default connect(mapStateToProps.themeColors, { openModalEmergencyNumber })(HTML)
