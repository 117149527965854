/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
// We really need explicit checks

import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/core'
import humps from 'humps'
import { addYears, format } from 'date-fns'

import * as Bootstrap from '@/bootstrap'
import Screen from '@/components/Screen'
import Block from '@/components/Block'
import Text from '@/components/wrappers/Text'
import Form from '@/components/Form'
import InputCheckBoxYesNo from '@/components/inputs/InputCheckBoxYesNo'
import InputAmount from '@/components/inputs/InputAmount'
import InputRadioButton from '@/components/inputs/InputRadioButton'
import Button from '@/components/buttons/Button'

import date from '@/utils/date'
import i18n from '@/utils/i18n'
import toast from '@/utils/toast'

import { IS_WEB } from '@/constants/platform'
import Styling from '@/constants/Styling'

import { postHouseholdEffectsValueMeter } from '@/redux/api/value_meters'
import type { Policy } from '@/types/objects'
import type { StackScreenProps } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
import type { State } from '@/redux/reducers'
import selectors from '@/redux/selectors'

const getDate = (value: string) => value?.replace(/[a-zA-Z\s,./]/g, '')
const add5Years = (expiry: string) =>
  format(addYears(date.dutchDateToJSDate(expiry), 5), date.DATE_FORMAT)

type ExtensionAmount = '2000' | '4000' | '6000' | '8000' | '10000' | '12000' | '14000' | '16000'

interface IForm extends Record<string, unknown> {
  ageMainBreadwinner?: '0' | '36' | '51' | '71'

  familySituation?: 'single' | 'family'

  netMonthlyIncomeMainBreadwinner?: '0' | '1001' | '2001' | '3001' | '4851' | '5851'

  // humps.js will not convert this correctly when naming it houseSurface300
  houseSurface_300?: boolean
  houseSurface?: '300' | '351' | '401'
  numberRooms?: '1' | '3' | '6' | '9'

  ownershipSituation?: 'rent' | 'buy' | 'appt'
  extensionTenantsInterest?: boolean
  extensionTenantsInterestAmount?: string
  extensionOwnersInterest?: boolean
  extensionOwnersInterestAmount?: string

  extensionAudiovisualAndComputerEquipment?: boolean
  extensionAudiovisualAndComputerEquipmentAmount?: ExtensionAmount

  extensionJewelry?: boolean
  extensionJewelryAmount?: ExtensionAmount

  extensionSpecialPossessions?: boolean
  extensionSpecialPossessionsAmount?: ExtensionAmount
}

const HouseholdEffectsValueMeter: React.FC<StackScreenProps<{ policy: { policy: Policy } }, 'policy'>> = (props) => {
  const navigation = useNavigation()
  const policy = useSelector((state: State) => selectors.getPolicy(state, props.route?.params?.policyId))

  const [form, setForm] = useState<IForm>({})
  const [submittingForm, setSubmittingForm] = useState<boolean>(false)

  const updateForm = (update: Partial<IForm>) => setForm({ ...form, ...update })

  const valid = formIsValid(form)
  const expiry = getDate(policy?.details?.underinsurance_guarantee?.code)

  const onSubmit = async () => {
    setSubmittingForm(true)

    const result = await postHouseholdEffectsValueMeter(
      policy.id,
      Object
        .keys(form)
        .map((name) => ({ name: humps.decamelize(name), value: (form[name] as string | boolean)?.toString() }))
        .filter((item) => item.value !== undefined)
    )

    toast(result.message!, 5000)

    if (result.success) {
      navigation.goBack()
    }

    setSubmittingForm(false)
  }

  return (
    <Screen white={!IS_WEB}>
      <Block noFlex>
        <Bootstrap.Row
          gutterHorizontal={15}
          gutterVertical={15}
        >
          <Bootstrap.Column span={{ lg: 8, md: 10, sm: 12, xl: 8, xs: 12, xxl: 8, xxxl: 6 }}>
            <Text type='description'>
              <Text type='description'>
                {i18n.t(`Uw garantie tegen onderverzekering verloopt op ${add5Years(expiry)}.`)}
              </Text>
              &nbsp;
              <Text
                type='description'
                bold
              >
                {i18n.t('Verloopt uw garantie tegen onderverzekering over drie maanden of eerder?')}
              </Text>
              &nbsp;
              <Text type='description'>
                {i18n.t('Vul onderstaande inboedelwaardemeter dan naar waarheid in. Op basis van de ingevulde gegevens zal uw verzekeringsadviseur de garantie tegen onderverzekering verlengen of contact opnemen.')}
              </Text>
            </Text>

            <Form style={Styling.marginTop}>
              <InputRadioButton
                label={i18n.t('Wat is de leeftijd van de hoofdkostwinner?')}
                id='ageMainBreadwinner'
                value={form?.ageMainBreadwinner}
                onChange={(ageMainBreadwinner) => updateForm({ ageMainBreadwinner })}
                items={[
                  { id: '0', value: '0', name: i18n.t('35 jaar en jonger') },
                  { id: '36', value: '36', name: i18n.t('36 t/m 50 jaar') },
                  { id: '51', value: '51', name: i18n.t('51 t/m 70 jaar') },
                  { id: '71', value: '71', name: i18n.t('71 jaar en ouder') }
                ]}
              />

              <InputRadioButton
                label={i18n.t('Wat is uw gezinssamenstelling?')}
                id='familySituation'
                tooltip={i18n.t('- alleenstaande (met/zonder inwonende kinderen)\n- gezin (met/zonder inwonende kinderen)')}
                value={form?.familySituation}
                onChange={(familySituation) => updateForm({ familySituation })}
                items={[
                  { id: 'single', value: 'single', name: i18n.t('Alleenstaand') },
                  { id: 'family', value: 'family', name: i18n.t('Gezin') }
                ]}
              />

              <InputRadioButton
                label={i18n.t('Wat is het netto maandinkomen van de hoofdkostwinner?')}
                id='netMonthlyIncomeMainBreadwinner'
                tooltip={i18n.t('Het netto maandinkomen van de hoofdkostwinner in uw huishouding (loon, salaris, inkomen uit eigen bedrijf, pensioen, ontvangen alimentatie, AOW of andere sociale uitkeringen enz.) Rente en inkomsten uit beleggingen worden niet meegerekend.')}
                value={form?.netMonthlyIncomeMainBreadwinner}
                onChange={(netMonthlyIncomeMainBreadwinner) => updateForm({ netMonthlyIncomeMainBreadwinner })}
                items={[
                  { id: '0', value: '0', name: i18n.t('T/m € 1.000') },
                  { id: '1001', value: '1001', name: i18n.t('T/m € 2.000') },
                  { id: '2001', value: '2001', name: i18n.t('T/m € 3.000') },
                  { id: '3001', value: '3001', name: i18n.t('T/m € 4.850') },
                  { id: '4851', value: '4851', name: i18n.t('T/m € 5.850') },
                  { id: '5851', value: '5851', name: i18n.t('T/m € 7.000') }
                ]}
              />

              <InputCheckBoxYesNo
                label={i18n.t('Is de oppervlakte van uw woning, inclusief bijgebouw(en), meer dan 300m²?')}
                value={form?.houseSurface_300}
                // eslint-disable-next-line @typescript-eslint/naming-convention
                onChange={(houseSurface_300) => updateForm({ houseSurface_300, houseSurface: undefined, numberRooms: undefined })}
              />

              {form?.houseSurface_300 === true
                ? <InputRadioButton
                    label={i18n.t('Wat is de oppervlakte van de woning inclusief bijgebouw(en)?')}
                    id='houseSurface'
                    value={form?.houseSurface}
                    onChange={(houseSurface) => updateForm({ houseSurface })}
                    items={[
                      { id: '300', value: '300', name: i18n.t('300 m2 t/m 350 m2') },
                      { id: '351', value: '351', name: i18n.t('351 m2 t/m 400 m2') },
                      { id: '401', value: '401', name: i18n.t('401 m2 t/m 450 m2') }
                    ]}
                  />
                : null}

              {form?.houseSurface_300 === false
                ? <InputRadioButton
                    label={i18n.t('Aantal kamers in de woning en bijgebouw(en)?')}
                    tooltip={i18n.t('Als kamers worden beschouwd: woon-, eet-, slaap-, studeer-, en hobbykamers, serre, garage en schuur.\n\nEen zolder die uitsluitend bestemd wordt als bergruimte is geen kamer.')}
                    id='numberRooms'
                    value={form?.numberRooms}
                    onChange={(numberRooms) => updateForm({ numberRooms })}
                    items={[
                      { id: '1', value: '1', name: i18n.t('1 of 2 kamers') },
                      { id: '3', value: '3', name: i18n.t('3 t/m 5 kamers') },
                      { id: '6', value: '6', name: i18n.t('6 t/m 8 kamers') },
                      { id: '9', value: '9', name: i18n.t('9 of 10 kamers') }
                    ]}
                  />
                : null}

              <InputRadioButton
                label={i18n.t('Wat voor soort woning heeft u?')}
                id='ownershipSituation'
                value={form?.ownershipSituation}
                onChange={(ownershipSituation) => updateForm({
                  ownershipSituation,
                  extensionTenantsInterest: undefined,
                  extensionTenantsInterestAmount: undefined,
                  extensionOwnersInterest: undefined,
                  extensionOwnersInterestAmount: undefined
                })}
                items={[
                  { id: 'rent', value: 'rent', name: i18n.t('Huurwoning') },
                  { id: 'buy', value: 'buy', name: i18n.t('Koopwoning') },
                  { id: 'appt', value: 'appt', name: i18n.t('Koopappartement') }
                ]}
              />

              {form?.ownershipSituation === 'rent'
                ? <InputCheckBoxYesNo
                    label={i18n.t('Is het huurdersbelang meer dan € 6.000,-?')}
                    tooltip={i18n.t('Onder huurdersbelang worden alle veranderingen en verbeteringen aan de huurwoning verstaan die door u als bewoner voor eigen rekening zijn aangebracht. Hieronder vallen o.a. verbeteringen aan de C.V., keuken en sanitaire installaties, betimmeringen, parket- en tegelvloeren, schuurtjes, schuttingen enz.')}
                    value={form?.extensionTenantsInterest}
                    onChange={(extensionTenantsInterest) => updateForm({
                      extensionTenantsInterest,
                      extensionTenantsInterestAmount: undefined
                    })}
                  />
                : null}

              {form?.extensionTenantsInterest === true
                ? <InputAmount
                    label={i18n.t('Welk bedrag boven de € 6.000,-?')}
                    value={form?.extensionTenantsInterestAmount}
                    onChange={(extensionTenantsInterestAmount) => updateForm({ extensionTenantsInterestAmount })}
                    currency
                  />
                : null}

              {form?.ownershipSituation === 'appt'
                ? <InputCheckBoxYesNo
                    label={i18n.t('Is het eigenaarsbelang meer dan € 6.000,-?')}
                    tooltip={i18n.t('Onder eigenaarsbelang worden alle aard- en nagelvaste met de woning verbonden verbeteringen en/of voorzieningen verstaan die u als apparte - mentseigenaar voor eigen rekening heeft aangebracht en die niet gedekt zijn op een verzekering ten name van de Vereniging van Eigenaren. Hieronder vallen o.a. verbeteringen aan de C.V., keuken en sanitaire installaties, betimmeringen, parket- en tegelvloeren e.d.')}
                    value={form?.extensionOwnersInterest}
                    onChange={(extensionOwnersInterest) => updateForm({
                      extensionOwnersInterest,
                      extensionOwnersInterestAmount: undefined
                    })}
                  />
                : null}

              {form?.extensionOwnersInterest
                ? <InputAmount
                    label={i18n.t('Welk bedrag boven de € 6.000,-?')}
                    value={form?.extensionOwnersInterestAmount}
                    onChange={(extensionOwnersInterestAmount) => updateForm({ extensionOwnersInterestAmount })}
                    currency
                  />
                : null}

              <InputCheckBoxYesNo
                label={i18n.t('Heeft u meer dan € 12.000,- aan audiovisuele- en computerapparatuur?')}
                tooltip={i18n.t('Onder audiovisuele en computerapparatuur vallen:\n• alle beeld-, geluids-, ontvangst- en zendapparatuur;\n• alle soorten computerapparatuur (inclusief spelcomputers);\n• alle bij bovengenoemde apparatuur behorende randapparatuur;\n• alle standaardsoftware;\n• alle geluids-, beeld- en informatiedragers (bijv. CD’s, DVD’s, USB-sticks)')}
                value={form?.extensionAudiovisualAndComputerEquipment}
                onChange={(extensionAudiovisualAndComputerEquipment) => updateForm({
                  extensionAudiovisualAndComputerEquipment,
                  extensionAudiovisualAndComputerEquipmentAmount: undefined
                })}
              />

              {form?.extensionAudiovisualAndComputerEquipment
                ? <InputRadioButton
                    label={i18n.t('Welk bedrag bovenop € 12.000,-?')}
                    id='extensionAudiovisualAndComputerEquipmentAmount'
                    value={form?.extensionAudiovisualAndComputerEquipmentAmount}
                    onChange={(extensionAudiovisualAndComputerEquipmentAmount) => updateForm({ extensionAudiovisualAndComputerEquipmentAmount })}
                    items={[
                      { id: '2000', value: '2000', name: i18n.t('€ 2.000') },
                      { id: '4000', value: '4000', name: i18n.t('€ 4.000') },
                      { id: '6000', value: '6000', name: i18n.t('€ 6.000') },
                      { id: '8000', value: '8000', name: i18n.t('€ 8.000') },
                      { id: '10000', value: '10000', name: i18n.t('€ 10.000') },
                      { id: '12000', value: '12000', name: i18n.t('€ 12.000') },
                      { id: '14000', value: '14000', name: i18n.t('€ 14.000') },
                      { id: '16000', value: '16000', name: i18n.t('€ 16.000') },
                      { id: '20000', value: '20000', name: i18n.t('€ 20.000') },
                      { id: '25000', value: '25000', name: i18n.t('€ 25.000') },
                      { id: '30000', value: '30000', name: i18n.t('€ 30.000') },
                      { id: '40000', value: '40000', name: i18n.t('€ 40.000') }
                    ]}
                  />
                : null}

              <InputCheckBoxYesNo
                label={i18n.t('Heeft u meer dan € 6.000,- aan lijfsieraden?')}
                tooltip={i18n.t('Lijfsieraden zijn sieraden, inclusief horloges, vervaardigd om op of aan het lichaam te dragen en die geheel of ten dele bestaan uit edel- of ander metaal, gesteente, mineraal, (bloed)koraal of andere dergelijke stoffen, alsmede parels')}
                value={form?.extensionJewelry}
                onChange={(extensionJewelry) => updateForm({
                  extensionJewelry,
                  extensionJewelryAmount: undefined
                })}
              />

              {form?.extensionJewelry
                ? <InputRadioButton
                    label={i18n.t('Welk bedrag bovenop € 6.000,-?')}
                    id='extensionJewelryAmount'
                    value={form?.extensionJewelryAmount}
                    onChange={(extensionJewelryAmount) => updateForm({ extensionJewelryAmount })}
                    items={[
                      { id: '2000', value: '2000', name: i18n.t('€ 2.000') },
                      { id: '4000', value: '4000', name: i18n.t('€ 4.000') },
                      { id: '6000', value: '6000', name: i18n.t('€ 6.000') },
                      { id: '8000', value: '8000', name: i18n.t('€ 8.000') },
                      { id: '10000', value: '10000', name: i18n.t('€ 10.000') },
                      { id: '12000', value: '12000', name: i18n.t('€ 12.000') },
                      { id: '14000', value: '14000', name: i18n.t('€ 14.000') },
                      { id: '16000', value: '16000', name: i18n.t('€ 16.000') },
                      { id: '20000', value: '20000', name: i18n.t('€ 20.000') },
                      { id: '25000', value: '25000', name: i18n.t('€ 25.000') },
                      { id: '30000', value: '30000', name: i18n.t('€ 30.000') },
                      { id: '40000', value: '40000', name: i18n.t('€ 40.000') }
                    ]}
                  />
                : null}

              <Text
                type='description'
                style={{ marginBottom: 16 }}
              >
                Let op: het diefstalrisico (dus niet de overige risico’s) van lijfsieraden is op Inboedel Optimaal verzekeringen standaard beperkt tot € 6.000,-. Als u het diefstalrisico van lijfsieraden voor een hoger verzekerd bedrag wilt verzekeren, neemt u dan contact met uw verzekeringsadviseur op.
              </Text>

              <InputCheckBoxYesNo
                label={i18n.t('Heeft u meer dan € 15.000,- aan andere bijzondere bezittingen?')}
                tooltip={i18n.t('Onder bijzondere bezittingen worden waardevolle eigendommen verstaan, zoals o.a. verzamelingen, antiek, kunst, muziekinstrumenten, enz.')}
                value={form?.extensionSpecialPossessions}
                onChange={(extensionSpecialPossessions) => updateForm({
                  extensionSpecialPossessions,
                  extensionSpecialPossessionsAmount: undefined
                })}
              />

              {form?.extensionSpecialPossessions
                ? <InputRadioButton
                    label={i18n.t('Welk bedrag bovenop € 15.000,-?')}
                    id='extensionSpecialPossessionsAmount'
                    value={form?.extensionSpecialPossessionsAmount}
                    onChange={(extensionSpecialPossessionsAmount) => updateForm({ extensionSpecialPossessionsAmount })}
                    items={[
                      { id: '2000', value: '2000', name: i18n.t('€ 2.000') },
                      { id: '4000', value: '4000', name: i18n.t('€ 4.000') },
                      { id: '6000', value: '6000', name: i18n.t('€ 6.000') },
                      { id: '8000', value: '8000', name: i18n.t('€ 8.000') },
                      { id: '10000', value: '10000', name: i18n.t('€ 10.000') },
                      { id: '12000', value: '12000', name: i18n.t('€ 12.000') },
                      { id: '14000', value: '14000', name: i18n.t('€ 14.000') },
                      { id: '16000', value: '16000', name: i18n.t('€ 16.000') },
                      { id: '20000', value: '20000', name: i18n.t('€ 20.000') },
                      { id: '25000', value: '25000', name: i18n.t('€ 25.000') },
                      { id: '30000', value: '30000', name: i18n.t('€ 30.000') },
                      { id: '40000', value: '40000', name: i18n.t('€ 40.000') }
                    ]}
                  />
                : null}

              <Button
                title={i18n.t('Waardemeter indienen')}
                disabled={!valid || submittingForm}
                loading={submittingForm}
                onPress={onSubmit}
              />
            </Form>
          </Bootstrap.Column>
        </Bootstrap.Row>
      </Block>
    </Screen>
  )
}

// validation is always hard
// eslint-disable-next-line sonarjs/cognitive-complexity
const formIsValid = (form: IForm): boolean => {
  if (
    form.ageMainBreadwinner === undefined ||
    form.familySituation === undefined ||
    form.netMonthlyIncomeMainBreadwinner === undefined ||
    form.houseSurface_300 === undefined ||
    form.ownershipSituation === undefined ||
    form.extensionAudiovisualAndComputerEquipment === undefined ||
    form.extensionJewelry === undefined ||
    form.extensionSpecialPossessions === undefined
  ) {
    return false
  }

  if (form.houseSurface_300 && form.houseSurface === undefined) return false
  if (!form.houseSurface_300 && form.numberRooms === undefined) return false

  if (form.ownershipSituation === 'rent' && form.extensionTenantsInterest === undefined) return false
  if (form.extensionTenantsInterest === true && form.extensionTenantsInterestAmount === undefined) return false
  if (form.ownershipSituation === 'appt' && form.extensionOwnersInterest === undefined) return false
  if (form.extensionOwnersInterest === true && form.extensionOwnersInterestAmount === undefined) return false

  if (form.extensionAudiovisualAndComputerEquipment && form.extensionAudiovisualAndComputerEquipmentAmount === undefined) return false
  if (form.extensionJewelry && form.extensionJewelryAmount === undefined) return false
  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (form.extensionSpecialPossessions && form.extensionSpecialPossessionsAmount === undefined) return false

  return true
}

export default HouseholdEffectsValueMeter
