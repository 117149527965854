import React from 'react'
import type { GestureResponderEvent, StyleProp, TextStyle } from 'react-native'
import { Platform, StyleSheet, Text } from 'react-native'

import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import type { Dict } from '@/redux/types'

export interface TTextProps {
  bold?: boolean
  center?: boolean
  children?: React.ReactNode
  color?: string
  lineHeight?: number
  noLineHeight?: boolean
  numberOfLines?: number
  onLongPress?: (event: GestureResponderEvent) => void
  onPress?: (event: GestureResponderEvent) => void
  selectable?: boolean
  style?: StyleProp<TextStyle>
  type: string
  underline?: boolean
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
export default function _Text (props: TTextProps) {
  const textStyles: StyleProp<TextStyle> = [
    styles[props.type],
    props.style,
    props.bold ? { fontFamily: 'montserrat-medium' } : null,
    props.center ? Styling.textCenter : null,
    props.color ? { color: props.color } : null,
    props.lineHeight ? { lineHeight: props.lineHeight } : null,
    props.noLineHeight ? { lineHeight: null } : null,
    Platform.OS === 'web' ? { hyphens: 'auto' } : null,
    props.underline ? { textDecorationLine: 'underline' } : null
  ]

  return (
    <Text
      numberOfLines={props.numberOfLines}
      onLongPress={props.onLongPress}
      onPress={props.onPress}
      selectable={props.selectable}
      style={textStyles}
    >
      {props.children}
    </Text>
  )
}

type StylesObject = Dict<Dict<string | number>>

let stylesRaw: StylesObject = {
  action: {
    fontFamily: 'montserrat-regular',
    fontSize: 14,
    lineHeight: 23
  },
  badge: {
    color: Colors.white,
    fontFamily: 'montserrat-regular',
    fontSize: 10
    // By disabling the lineHeight the Text component centers pixel perfect.
    // lineHeight: 13,
  },
  button: {
    color: Colors.white,
    fontFamily: 'montserrat-regular',
    fontSize: 16
    // By disabling the lineHeight the Text component centers pixel perfect.
    // lineHeight: 24,
  },
  buttonSmall: {
    color: Colors.white,
    fontFamily: 'montserrat-regular',
    fontSize: 14
    // By disabling the lineHeight the Text component centers pixel perfect.
    // lineHeight: 21,
  },
  circle: {
    color: Colors.white,
    fontFamily: 'montserrat-medium',
    fontSize: 16
    // By disabling the lineHeight the Text component centers pixel perfect.
    // lineHeight: 19,
  },
  description: {
    color: Colors.gray,
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 27
  },
  descriptionBold: {
    color: Colors.gray,
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    lineHeight: 27
  },
  descriptionError: {
    color: Colors.red,
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 27
  },
  descriptionLight: {
    color: Colors['8d'],
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 27
  },
  descriptionSuccess: {
    color: Colors.success,
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 27
  },
  descriptionWarning: {
    color: Colors.orange,
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 27
  },
  disabled: {
    color: Colors.c1,
    fontFamily: 'montserrat-regular',
    fontSize: 14,
    lineHeight: 18
  },
  distance: {
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    lineHeight: 19
  },
  footnote: {
    color: Colors['8d'],
    fontFamily: 'montserrat-regular',
    fontSize: 13,
    lineHeight: 16
  },
  greeting: {
    color: Colors['8d'],
    fontFamily: 'montserrat-regular',
    fontSize: 26,
    lineHeight: 32
  },
  header: {
    color: Colors.gray,
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 24
  },
  help: {
    color: Colors.lightBrown,
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 19
  },
  input: {
    color: Colors.gray,
    fontFamily: 'montserrat-regular',
    fontSize: 16
    // By disabling the lineHeight the Text component centers pixel perfect.
    // lineHeight: 24,
  },
  inputError: {
    color: Colors.red,
    fontFamily: 'montserrat-regular',
    fontSize: 16
    // By disabling the lineHeight the Text component centers pixel perfect.
    // lineHeight: 24,
  },
  inputSuccess: {
    color: Colors.success,
    fontFamily: 'montserrat-regular',
    fontSize: 16
    // By disabling the lineHeight the Text component centers pixel perfect.
    // lineHeight: 24,
  },
  inputWarning: {
    color: Colors.orange,
    fontFamily: 'montserrat-regular',
    fontSize: 16
    // By disabling the lineHeight the Text component centers pixel perfect.
    // lineHeight: 24,
  },
  keyboard: {
    color: Colors.black,
    fontFamily: 'montserrat-regular',
    fontSize: 14,
    lineHeight: 21
  },
  keypad: {
    color: Colors.black,
    fontFamily: 'montserrat-regular',
    fontSize: 30,
    lineHeight: 37,
    // These styles prevent the text from getting selected on Web when clicking.
    ...(Platform.OS === 'web' ? { touchAction: 'auto', userSelect: 'none' } : {})
  },
  label: {
    color: Colors.gray,
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    lineHeight: 24
  },
  labelError: {
    color: Colors.red,
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    lineHeight: 24
  },
  labelRegular: {
    color: Colors.gray,
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 24
  },
  labelSuccess: {
    color: Colors.success,
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    lineHeight: 24
  },
  labelWarning: {
    color: Colors.orange,
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    lineHeight: 24
  },
  light: {
    color: Colors.lightBrown,
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 27
  },
  menu: {
    color: Colors.gray,
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    lineHeight: 19
  },
  note: {
    color: Colors.gray,
    fontFamily: 'montserrat-regular',
    fontSize: 12,
    lineHeight: 15
  },
  paragraph: {
    color: Colors.gray,
    fontFamily: 'montserrat-regular',
    fontSize: 14,
    lineHeight: 21
  },
  paragraphError: {
    color: Colors.red,
    fontFamily: 'montserrat-regular',
    fontSize: 14,
    lineHeight: 21
  },
  paragraphLight: {
    color: Colors['8d'],
    fontFamily: 'montserrat-regular',
    fontSize: 14,
    lineHeight: 21
  },
  paragraphSuccess: {
    color: Colors.success,
    fontFamily: 'montserrat-regular',
    fontSize: 14,
    lineHeight: 21
  },
  paragraphWaiting: {
    color: Colors.waiting,
    fontFamily: 'montserrat-regular',
    fontSize: 14,
    lineHeight: 21
  },
  picker: {
    color: Colors.black,
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    lineHeight: 19
  },
  pill: {
    color: Colors.white,
    fontFamily: 'montserrat-regular',
    fontSize: 12
    // By disabling the lineHeight the Text component centers pixel perfect.
    // lineHeight: 27,
  },
  pillLarge: {
    color: Colors.white,
    fontFamily: 'montserrat-regular',
    fontSize: 16
    // By disabling the lineHeight the Text component centers pixel perfect.
    // lineHeight: 27,
  },
  pincode: {
    color: Colors.white,
    fontFamily: 'montserrat-regular',
    fontSize: 20,
    lineHeight: 24
  },
  results: {
    color: Colors.gray,
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    lineHeight: 19
  },
  section: {
    fontFamily: 'montserrat-medium',
    fontSize: 20,
    lineHeight: 23
  },
  span: {
    color: Colors.gray,
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 24
  },
  spanLight: {
    color: Colors['8d'],
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 24
  },
  spanMedium: {
    color: Colors.gray,
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    lineHeight: 24
  },
  subsection: {
    color: Colors.gray,
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    lineHeight: 27
  },
  subtitle: {
    color: Colors.gray,
    fontFamily: 'montserrat-medium',
    fontSize: 22,
    lineHeight: 27
  },
  tabBar: {
    fontFamily: 'montserrat-regular',
    fontSize: 11,
    lineHeight: 14
  },
  text: {
    color: Colors.gray,
    fontFamily: 'montserrat-regular'
  },
  textBold: {
    color: Colors.gray,
    fontFamily: 'montserrat-medium'
  },
  title: {
    color: Colors.gray,
    fontFamily: 'montserrat-medium',
    fontSize: 34,
    lineHeight: 42
  },
  toast: {
    color: Colors.white,
    fontFamily: 'montserrat-regular',
    fontSize: 16,
    lineHeight: 25
  },
  unit: {
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    lineHeight: 15
  }
}

// Enable text selection in the browser.
if (Platform.OS === 'web') {
  stylesRaw = Object.keys(stylesRaw)?.reduce((map, key) => {
    if (!('userSelect' in map[key])) {
      // eslint-disable-next-line no-param-reassign
      map[key].userSelect = 'text'
      // eslint-disable-next-line no-param-reassign
      map[key].wordBreak = 'break-word'
    }
    return map
  }, stylesRaw)
}

export const stylesObject: StylesObject = stylesRaw

interface Styles {
  [key: string]: object
}

export const styles: Styles = StyleSheet.create(stylesRaw) as Styles
