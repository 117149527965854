import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

import Icon from '@/components/Icon'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'

interface TToggleProps {
  lowerText: string
  style?: StyleProp<ViewStyle>
  upperText: string
}

interface TToggleState {
  open: boolean
}

export default class Toggle extends React.Component<TToggleProps, TToggleState> {
  constructor (props: TToggleProps) {
    super(props)

    this.state = {
      open: false
    }
  }

  toggle = () =>
    this.setState((previousState: TToggleState) => ({
      open: !previousState.open
    }))

  render () {
    return (
      <View style={this.props.style}>
        <TouchableWithoutFeedback onPress={this.toggle}>
          <View style={styles.contentContainer}>
            <Text
              style={Styling.flex}
              type='descriptionBold'
            >
              {this.props.upperText}
            </Text>
            <Icon
              circleColor={Colors.lightPrimary}
              color={Colors.primary}
              icon={this.state.open ? ['far', 'minus'] : ['far', 'plus']}
              style={styles.icon}
            />
          </View>
        </TouchableWithoutFeedback>
        {this.state.open
          ? (
            <View style={styles.contentContainer}>
              <Text type='description'>{this.props.lowerText}</Text>
            </View>
            )
          : null}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  icon: {
    alignSelf: 'flex-start',
    marginLeft: Sizes.spacingHorizontal
  }
})
