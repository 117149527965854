/* eslint-disable react/jsx-props-no-spreading */

import React, { useContext } from 'react'

import type { StackScreenProps } from '@react-navigation/stack'
import ContextBreakpoint, { isSmallScreen } from '@/bootstrap/ContextBreakpoint'

interface IProps {
  renderPhone?: (...args: any[]) => JSX.Element
  renderTablet?: (...args: any[]) => JSX.Element
  route?: StackScreenProps<any>['route']

  Phone?: React.FC<{ type: 'phone' }>
  Tablet?: React.FC<{ type: 'tablet' }>
}

const Switch: React.FC<IProps> = ({ renderPhone, renderTablet, route, Phone, Tablet }) => {
  const breakpoint = useContext(ContextBreakpoint)

  if (Phone && isSmallScreen(breakpoint)) {
    return (
      <Phone
        {...route?.params}
        type='phone'
      />
    )
  } else if (Tablet && !isSmallScreen(breakpoint)) {
    return (
      <Tablet
        {...route?.params}
        type='tablet'
      />
    )
  }

  if (!renderPhone || !renderTablet) {
    return null
  }

  if (isSmallScreen(breakpoint)) {
    return renderPhone?.({ ...route?.params, type: 'phone' })
  }

  return renderTablet?.({ ...route?.params, type: 'tablet' })
}

export default Switch
