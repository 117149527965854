import React, { useEffect } from 'react'
import type { ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import { apiCall } from '@/redux/actions/api'
import type { ThemeColors } from '@/redux/reducers/theme'
import selectors from '@/redux/selectors'
import type { Policy, Relation } from '@/types/objects'

import PolicyPhone from './Policies.phone'
import PolicyTablet from './Policies.tablet'

export interface IChildComponentProps {
  showExpelledPolicies: (show: boolean) => void
  scrollViewRef: React.RefObject<ScrollView>
  keyExtractor: (policy: Policy) => string
  relation: Relation
  policiesOverview: Policy[]
  policiesExpelled: Policy[]
  colors: ThemeColors

  refreshing: boolean
  onRefresh: () => void
}

const Policies: React.FC = () => {
  const scrollViewRef = React.createRef<ScrollView>()
  const dispatch = useDispatch()

  const relation = useSelector(selectors.getCurrentRelation)
  const refreshing = !!useSelector(selectors.isLoading('POLICIES'))
  const colors = useSelector(selectors.getThemeColors)
  const policiesOverview = useSelector(selectors.getPoliciesOverview)
  const policiesExpelled = useSelector(selectors.getPoliciesExpelled)

  const onRefresh = () => {
    dispatch(apiCall('GET', 'POLICIES'))

    if (relation?.id) {
      dispatch(apiCall('GET', 'RELATION', relation.id))
    }
  }

  useEffect(() => {
    onRefresh()
  }, [relation?.id])

  const showExpelledPolicies = (show: boolean): void => {
    if (show && scrollViewRef?.current?.scrollToEnd) {
      setTimeout(() => {
        scrollViewRef?.current?.scrollToEnd()
      }, 100)
    }
  }

  const keyExtractor = (policy: Policy) => `policy-${policy.id}`

  const props = {
    colors,
    keyExtractor,
    policiesExpelled,
    policiesOverview,
    relation,
    scrollViewRef,
    showExpelledPolicies,
    refreshing,
    onRefresh
  }

  if (!relation?.id) {
    return null
  }

  return (
    <Bootstrap.Switch
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderPhone={() => <PolicyPhone {...props} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderTablet={() => <PolicyTablet {...props} />}
    />
  )
}

export default Policies
