import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { FlatList, SectionList, StyleSheet, View } from 'react-native'

import withBreakpoint from '@/bootstrap/withBreakpoint'
import Colors from '@/constants/Colors'
import type { ConfigProp, SectionsProp, SpacingProp } from '@/types/props'
import prototype from '@/utils/prototype'

interface TCollectionProps {
  breakpoint: string
  data?: any[]
  gutter?: number
  id?: string
  keyExtractor?: (item: any, index: number) => string
  margin?: number
  refreshControl?: React.ReactElement
  renderItem?: (...args: any[]) => any
  renderSectionHeader?: (...args: any[]) => any
  scrollEnabled?: boolean
  section?: boolean
  sections?: SectionsProp
  spacing?: SpacingProp
  span?: ConfigProp
  style?: StyleProp<ViewStyle>
  white?: boolean
  listFooterComponent?: React.ReactElement
}

class Collection extends React.Component<TCollectionProps> {
  renderItem = (props: any) => {
    if (typeof this.props.renderItem !== 'function') {
      return null
    }
    const gutterVertical = (this.props.gutter || this.props.spacing) as number
    let length

    if (props.section && Array.isArray(props.section.data)) {
      length = props.section.data.length
    } else if (Array.isArray(this.props.data)) {
      length = this.props.data.length
    } else {
      length = 0
    }

    let paddingStyle: StyleProp<ViewStyle> = null
    if (prototype.isNumber(gutterVertical)) {
      if (props.index === 0) {
        paddingStyle = { paddingBottom: gutterVertical / 2 }
      } else if (props.index + 1 === length) {
        paddingStyle = { paddingTop: gutterVertical / 2 }
      } else {
        paddingStyle = {
          paddingBottom: gutterVertical / 2,
          paddingTop: gutterVertical / 2
        }
      }
    }

    if (paddingStyle) {
      paddingStyle.flex = 1
      return <View style={paddingStyle}>{this.props.renderItem(props)}</View>
    }

    return this.props.renderItem(props)
  }

  render () {
    const contentContainerStyle = this.props.white ? styles.contentContainerWhite : styles.contentContainer
    let paddingStyle = null

    if (prototype.isNumber(this.props.spacing) || prototype.isNumber(this.props.margin)) {
      paddingStyle = {
        paddingHorizontal: (this.props.spacing ?? this.props.margin) as number,
        paddingVertical: (this.props.spacing ?? this.props.margin) as number
      }
    }

    if (this.props.section) {
      return (
        <SectionList
          contentContainerStyle={paddingStyle ? [paddingStyle, contentContainerStyle] : contentContainerStyle}
          key={this.props.id ? `${this.props.id}-section-list` : undefined}
          keyExtractor={this.props.keyExtractor}
          refreshControl={this.props.refreshControl}
          renderItem={this.renderItem}
          renderSectionHeader={this.props.renderSectionHeader}
          scrollEnabled={this.props.scrollEnabled}
          sections={this.props.sections!}
          style={this.props.style}
        />
      )
    }

    return (
      <FlatList
        contentContainerStyle={paddingStyle ? [paddingStyle, contentContainerStyle] : contentContainerStyle}
        data={this.props.data}
        key={`${this.props.id}-flat-list-${this.props.data?.length ?? 0}`}
        keyExtractor={this.props.keyExtractor}
        ListFooterComponent={this.props.listFooterComponent}
        refreshControl={this.props.refreshControl}
        renderItem={this.renderItem}
        scrollEnabled={this.props.scrollEnabled}
        style={this.props.style}
      />
    )
  }
}

export default withBreakpoint(Collection)

const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: Colors.background,
    flexGrow: 1
  },
  contentContainerWhite: {
    backgroundColor: Colors.white,
    flexGrow: 1
  },
  itemContainer: {
    flex: 1
  }
})
