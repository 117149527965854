/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect } from 'react'
import { isAfter } from 'date-fns'
import { useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import ButtonNext from '@/components/buttons/ButtonNext'
import Form from '@/components/Form'
import InputDateTime from '@/components/inputs/InputDateTime'
import InputSelect from '@/components/inputs/InputSelect'
import InputTextArea from '@/components/inputs/InputTextArea'
import Text from '@/components/wrappers/Text'

import Styling from '@/constants/Styling'

import i18n from '@/utils/i18n'
import date from '@/utils/date'

import selectors from '@/redux/selectors'
import { staticClaimQuestionLabels } from '@/redux/constants/forms'

import type { Policy } from '@/types/objects'
import Input from '@/components/inputs/Input'
import { View } from 'react-native'
import ButtonOutline from '@/components/buttons/ButtonOutline'
import AdditionalFiles from './additional_files'
import { IS_WEB } from '@/constants/platform'

interface IProps {
  loadingStatic: boolean
  staticFormValid: boolean

  onChangePolicyId: (value: Policy['id']) => void
  updateFormStatic: (key: string, value: string | number | boolean | undefined) => void
  finishStepStatic: () => void
  saveAsConcept: (showConfirmation: boolean) => Promise<string>
  conceptButtonText: string
  descriptionError: boolean
  phoneNumberError: boolean
  dateOfDamageError: boolean
  dateOfDamageErrorMessage: string
}

const maxDateAllowedToClaim = (cancellationDate: Policy['cancellationDate']): Date => {
  const timestamp = new Date(cancellationDate)

  return isAfter(timestamp, new Date()) ? new Date() : timestamp
}

const StepStatic: React.FC<IProps> = (props) => {
  const formClaimDamage = useSelector(selectors.getFormClaimDamage)
  const policyId = formClaimDamage?.answersStatic?.policyId
  const policyItems = useSelector(selectors.getPolicyItems)
  const policy = useSelector((state) => selectors.getPolicy(state, policyId)) ?? useSelector((state) => selectors.getPolicyByPolicyNumber(state, formClaimDamage.answersStatic.policyId ?? ''))
  const productClaimTypes = useSelector((state) => selectors.getProductClaimTypeItems(state, policyId))

  if (!policy) {
    return null
  }

  useEffect(() => {
    props.onChangePolicyId(policy.id)

    if (productClaimTypes.length === 1) {
      props.updateFormStatic('productClaimTypeName', productClaimTypes[0].value)
    }
  }, [productClaimTypes])
  return (
    <Form id='claim-damage-form-static-questions'>
      <Text
        style={Styling.marginBottom}
        type='title'
      >
        {i18n.t('Algemeen')}
      </Text>
      <Text
        style={Styling.marginBottomMedium}
        type='description'
      >
        Om te bepalen wat voor soort schade u heeft opgelopen, zijn er enkele vragen noodzakelijk.
      </Text>

      <Bootstrap.Row gutterHorizontal={30}>
        <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 6, xs: 12 }}>
          <InputSelect
            items={policyItems.filter((policy) => policy.isAllowedToReportClaim)}
            label={staticClaimQuestionLabels.policyId}
            onChange={props.onChangePolicyId}
            value={formClaimDamage?.answersStatic.policyId}
          />
        </Bootstrap.Column>

        <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 6, xs: 12 }}>
          <InputDateTime
            label={staticClaimQuestionLabels.dateOfDamage}
            minDate={new Date(policy.minimumDateOfLoss)}
            maxDate={policy.maximumDateOfLoss !== undefined && policy.maximumDateOfLoss !== null ? maxDateAllowedToClaim(policy.maximumDateOfLoss) : undefined}
            mode='date'
            onChange={(dateOfDamage) => props.updateFormStatic('dateOfDamage', dateOfDamage)}
            placeholder={date.yesterday()}
            value={formClaimDamage?.answersStatic?.dateOfDamage}
            error={props.dateOfDamageError}
            errorDescription={props.dateOfDamageErrorMessage}
          />
        </Bootstrap.Column>
      </Bootstrap.Row>

      <InputTextArea
        label={staticClaimQuestionLabels.description}
        maxLength={600}
        onChange={(description: string) => props.updateFormStatic('description', description)}
        placeholder='Hoe is de schade ontstaan, waar is de schade ontstaan, waaraan is schade, door wie is de schade ontstaan etc...'
        value={formClaimDamage?.answersStatic.description}
        error={props.descriptionError}
      />

      {!IS_WEB
        ? <Text
            type='note'
            style={{ marginTop: -22, marginBottom: 30 }}
          >
          Maximaal 600 tekens
        </Text>
        : null}

      <Input
        showMaxLength={false}
        label={staticClaimQuestionLabels.phoneNumber}
        maxLength={8}
        addonRight='06'
        inputMode='numeric'
        onChange={(phoneNumber: string) => props.updateFormStatic('phoneNumber', `06${phoneNumber.replace(/[^0-9]/g, '')}`)}
        placeholder={i18n.t('Vul hier uw telefoonnummer in.')}
        value={formClaimDamage?.answersStatic.phoneNumber?.substring(2)}
        keyboardType='number-pad'
        error={props.phoneNumberError}
      />

      <AdditionalFiles saveAsConcept={props.saveAsConcept} />

      <View style={Styling.row}>
        <ButtonOutline
          title={props.conceptButtonText}
          onPress={props.saveAsConcept}
        />

        <ButtonNext
          disabled={props.loadingStatic}
          loading={props.loadingStatic}
          onPress={props.finishStepStatic}
          style={Styling.autoLeft}
        />
      </View>
    </Form>
  )
}

export default StepStatic
