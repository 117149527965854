import React, { useContext } from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import ContextBreakpoint, { isSmallScreen } from '@/bootstrap/ContextBreakpoint'

interface TBlockProps {
  children?: React.ReactNode
  noPadding?: boolean
  style?: StyleProp<ViewStyle>
  noFlex?: boolean
}

export default function Block (props: TBlockProps) {
  const breakpoint = useContext(ContextBreakpoint)
  if (isSmallScreen(breakpoint)) {
    return <>{props.children}</>
  }

  return (
    <View
      style={[
        props.noPadding ? styles.containerNoPadding : styles.container,
        props.noFlex ? { flex: undefined } : {},
        props.style
      ]}
    >
      {props.children}
    </View>
  )
}

const containerStyle = {
  backgroundColor: Colors.white,
  borderRadius: Sizes.borderRadius,
  flex: 1
}

const styles = StyleSheet.create({
  container: {
    ...containerStyle,
    paddingHorizontal: Sizes.spacingHorizontalMedium,
    paddingVertical: Sizes.spacingVerticalMedium
  },
  containerNoPadding: containerStyle
})
