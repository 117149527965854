import common from '@/utils/common'
import * as sessionsApi from '@/redux/api/sessions'
import { parseEmailAddress } from '@/utils/email'
import storage from '@/utils/storage'
import type { TNavigationProp } from '@/types/props'
import type { TApiCallProps } from '@/hocs/withApiCall'
import type { Relation, User } from '@/types/objects'
import type { ISignInResult } from '@/redux/types/user'
import prototype from '@/utils/prototype'
import i18n from '@/utils/i18n'
import * as Analytics from '@/utils/firebase/analytics'
import type { signInSucces } from '@/redux/actions/user'
import type { TSafeAreaInsetsProps } from '@/hocs/withSafeAreaInsets'
import type { openModalEmergencyNumber, openModalHelp } from '@/redux/actions/modals'
import type { selectRelation } from '@/redux/actions/relationRepository'
import type { resetIdin, startIdin } from '@/redux/actions/idin'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('../../assets/logo.png')

export interface THomeOwnProps {
  navigation: TNavigationProp
}

export type THomeProps = THomeOwnProps & TApiCallProps & TSafeAreaInsetsProps & {
  action?: string
  emailAddress?: User['email']
  idin: any
  insets: any
  openModalEmergencyNumber: typeof openModalEmergencyNumber
  openModalHelp: typeof openModalHelp
  password?: User['password']
  passwordRepeat?: User['password']
  registerToken?: User['token']
  resetIdin: typeof resetIdin
  resetPasswordToken?: User['token']
  selectRelation: typeof selectRelation
  startIdin: typeof startIdin
  signInSucces: typeof signInSucces
  token?: User['token']
  currentRelationId?: Relation['id']
}

export interface THomeState {
  alreadyUsed: boolean
  emailAddress: User['email']
  failedMessage: string
  forgotPasswordResult: boolean
  forgotPasswordValue: string
  idinResult: boolean | null
  loaded: boolean
  loading: boolean
  password: User['password']
  passwordRepeat: User['password']
  registerToken: User['token']
  showCheckEmail: boolean
  showForgotPassword: boolean
  showRegister: boolean
  showResetPassword: boolean
  status: string
  idinLoading: boolean
  logo: {
    uri: string
  }
  handleIdinLogin: boolean
  showInvalidToken: boolean
  invalidTokenButtonText: string
  invalidTokenText: string
  invalidTokenButtonHandler?: () => void
}

export const getInitialState = (): THomeState => {
  return {
    alreadyUsed: false,
    emailAddress: '',
    failedMessage: '',
    forgotPasswordResult: false,
    forgotPasswordValue: '',
    idinLoading: false,
    idinResult: null,
    loaded: false,
    loading: false,
    password: '',
    passwordRepeat: '',
    registerToken: '',
    showCheckEmail: false,
    showForgotPassword: false,
    showRegister: false,
    showResetPassword: false,
    status: '',
    logo,
    handleIdinLogin: false,
    showInvalidToken: false,
    invalidTokenText: '',
    invalidTokenButtonText: '',
  }
}

interface HomeEssentials<T> {
  state: THomeState
  props: T
  setState: SetState
}

type SetState = (newState: any) => any
export type GetHomeEssentials = <T>() => HomeEssentials<T>

export const createOnPressSubmitCheck = (getEssentials: GetHomeEssentials) => async () => {
  const { state, props, setState } = getEssentials<TApiCallProps & {
    navigation: TNavigationProp
  }>()

  setState({ loading: true, failedMessage: '' })

  const registerUrl = common.determineRedirectUrl('home', { action: 'register' })

  const result = await props.apiCallRaw(
    sessionsApi.checkEmailAddress,
    parseEmailAddress(state.emailAddress),
    registerUrl
  )

  if (result.success) {
    // @ts-expect-error
    props.navigation.setParams({ action: undefined })

    if (result.data?.status === 'activated') {
      await storage.setAlreadyUsed(true)
    }

    setState({ status: result.data?.status })
  } else {
    const failedMessage = result?.message ?? 'Er is iets mis gegaan bij het controleren van uw e-mailadres, probeer het later nog eens.'

    setState({
      failedMessage
    })
  }

  setState({ loading: false })
}

export const createOnLogin = (getEssentials: GetHomeEssentials) => async () => {
  const { state, props, setState } = getEssentials<TApiCallProps & {
    navigation: TNavigationProp
    signInSucces: typeof signInSucces
  }>()

  setState({ loading: true, failedMessage: '' })
  await storage.setAlreadyUsed(true)

  const result = await props.apiCallRaw<ISignInResult>(
    sessionsApi.login,
    parseEmailAddress(state.emailAddress),
    state.password,
    common.determineRedirectUrl('home', { action: 'reset-password' })
  )

  if (result.success) {
    const user = {
      ...result.data?.user,
      password: state.password
    }

    props.signInSucces({ ...result.data, user })

    Analytics.setUserId(user?.id)
    Analytics.logEvent('login')
  } else {
    let failedMessage = result.message

    if (prototype.isNumber(result?.attemptsLeft)) {
      const attemptsString = result?.attemptsLeft === 1 ? i18n.t('inlogpoging') : i18n.t('inlogpogingen')
      failedMessage += `\n${i18n.t(`U heeft nog ${result?.attemptsLeft} ${attemptsString} over.`)}`
    }

    if (failedMessage) {
      setState({ failedMessage })
    }
  }

  setState({ loading: false })
}
