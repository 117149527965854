import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addYears, format } from 'date-fns'
import type { StackScreenProps } from '@react-navigation/stack'

import * as Bootstrap from '@/bootstrap'

import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'

import i18n from '@/utils/i18n'
import date from '@/utils/date'

import { IS_WEB } from '@/constants/platform'
import selectors from '@/redux/selectors'

import Form from '@/components/Form'
import InputCheckBoxYesNo from '@/components/inputs/InputCheckBoxYesNo'
import InputAmount from '@/components/inputs/InputAmount'

import Styling from '@/constants/Styling'
import Button from '@/components/buttons/Button'
import { apiCall } from '@/redux/actions/api'
import navigation from '@/utils/navigation'
import Block from '@/components/Block'
import type { State } from '@/redux/reducers'

type TProps = StackScreenProps<{ policy: { policyId: string } }, 'policy'>

const getDate = (value: string) => value?.replace(/[a-zA-Z\s,./]/g, '')
const add5Years = (expiry: string) =>
  format(addYears(date.dutchDateToJSDate(expiry), 5), date.DATE_FORMAT)

const Content: React.FC<TProps> = (props) => {
  const dispatch = useDispatch()
  const policy = useSelector((state: State) => selectors.getPolicy(state, props.route?.params?.policyId))
  const expiry = getDate(policy?.details?.underinsurance_guarantee?.code)
  const rebuildValue = policy?.details?.rebuilding_value?.code?.replace(/[^0-9,]/g, '')
  const loading = useSelector(selectors.isLoading('REBUILD_VALUE_METER', policy.id))
  const { success } = useSelector(selectors.getRebuildValue)

  const [renovationCostAbove, setRenovationCostAbove] = useState<boolean | undefined>(undefined)
  const [renovationCost, setRenovationCost] = useState<string>('')

  const newRebuildValue =
    (parseFloat(rebuildValue || '0') +
      parseFloat(renovationCost || '0')).toString()

  const valid =
    policy?.id !== undefined &&
    renovationCostAbove !== undefined &&
    (renovationCostAbove && parseFloat(renovationCost) >= 5000)

  const submitForm = () => {
    dispatch(apiCall('POST', 'REBUILD_VALUE_METER', policy.id, {
      policy_id: policy.id,
      renovationCost,
      renovationCostAbove: renovationCostAbove ? 'Ja' : 'Nee',
      rebuildingValueNew: newRebuildValue
    }))
  }

  useEffect(() => {
    if (valid && success) {
      navigation.pop()
    }
  }, [success])

  useEffect(() => {
    return () => {
      dispatch({ type: 'RESET_REBUILD_VALUE_METER' })
    }
  }, [])

  return (
    <>
      <Text type='description'>
        {i18n.t(`Uw garantie tegen onderverzekering verloopt op ${add5Years(expiry)}. Heeft u uw huis verbouwd? Vul onderstaand formulier in als de verbouwingskosten hoger waren dan € 5.000,- en de inhoud (m3) van uw woning groter is geworden door de verbouwing. Is dit niet het geval, dan hoeft u het formulier niet in te vullen.`)}
      </Text>

      <Form style={Styling.marginTop}>
        <InputAmount
          currency
          label={i18n.t('Huidige herbouwwaarde')}
          value={rebuildValue}
          disabled
        />

        <InputCheckBoxYesNo
          label={i18n.t('Had u verbouwingskosten boven € 5.000,- en heeft uw huis nu een grotere inhoud (m3)?')}
          value={renovationCostAbove}
          onChange={setRenovationCostAbove}
        />

        {renovationCostAbove === true
          ? (
            <InputAmount
              currency
              label={i18n.t('Verbouwingskosten')}
              value={renovationCost}
              onChange={setRenovationCost}
            />)
          : null}

        {renovationCostAbove === false
          ? (
            <Text
              type='description'
              style={Styling.marginBottom}
            >
              {i18n.t('U geeft aan geen verbouwingskosten boven de € 5000,- te hebben gemaakt, waarbij de inhoud van uw woning is vergroot. Het is daarom niet nodig om de Herbouwwaardemeter in te vullen.')}
            </Text>)
          : null}

        <InputAmount
          currency
          label={i18n.t('Nieuwe herbouwwaarde')}
          value={newRebuildValue}
          disabled
        />

        <Button
          disabled={!valid || !!loading}
          title={i18n.t('Versturen')}
          onPress={submitForm}
          loading={loading}
        />
      </Form>
    </>
  )
}

const RebuildValueMeter: React.FC<TProps> = (props) => (
  <Screen white={!IS_WEB}>
    <Block noFlex>
      <Bootstrap.Row
        gutterHorizontal={15}
        gutterVertical={15}
      >
        <Bootstrap.Column span={{ lg: 8, md: 10, sm: 12, xl: 8, xs: 12, xxl: 8, xxxl: 6 }}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Content {...props} />
        </Bootstrap.Column>
      </Bootstrap.Row>
    </Block>
  </Screen>
)

export default RebuildValueMeter
