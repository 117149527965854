import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Action from '@/components/Action'
import Amount from '@/components/Amount'
import Attribute from '@/components/Attribute'
import Block from '@/components/Block'
import Icon from '@/components/Icon'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import mapStateToProps from '@/redux/mapStateToProps'
import type { ThemeColors } from '@/redux/reducers/theme'
import type { Condition, InsuranceCard, Policy, Relation } from '@/types/objects'
import common from '@/utils/common'
import data from '@/utils/data'
import i18n from '@/utils/i18n'

interface TItemPolicyProps {
  colors: ThemeColors
  onPress: (policy: Policy) => void
  openScreenPolicyCondition: (policy: Policy, condition: Condition) => void
  openPolicyInsuranceCard: (policy: Policy, insuranceCard: InsuranceCard) => void
  policy: Policy
  relation: Relation
}

class ItemPolicy extends React.Component<TItemPolicyProps> {
  onPress = () => this.props.onPress(this.props.policy)

  hasSingleInsuranceCard = () => this.props.policy?.insuranceCards?.length === 1

  hasSingleCondition = () => this.props.policy?.conditions?.length === 1

  openScreenPolicyCondition = () =>
    this.hasSingleCondition() &&
    this.props.openScreenPolicyCondition(this.props.policy, this.props.policy?.conditions?.[0] as Condition)

  openPolicyInsuranceCard = () =>
    this.hasSingleInsuranceCard() &&
    this.props.openPolicyInsuranceCard(this.props.policy, this.props.policy?.insuranceCards?.[0] as InsuranceCard)

  renderActions = () => (
    <>
      {this.hasSingleCondition()
        ? (
          <Action
            label={i18n.t('Voorwaarden')}
            onPress={this.openScreenPolicyCondition}
            style={Styling.marginRightMedium}
            type='down'
          />
          )
        : null}

      {this.hasSingleInsuranceCard()
        ? (
          <Action
            label={i18n.t('Verzekeringskaart')}
            onPress={this.openPolicyInsuranceCard}
            style={Styling.marginRightMedium}
            type='down'
          />
          )
        : null}

      <Action
        label={i18n.t('Details')}
        onPress={this.onPress}
        type='right'
      />
    </>
  )

  render () {
    const policy = this.props.policy || {}
    const relation = this.props.relation || {}
    const importantDetails = data.getImportantDetails(policy)

    return (
      <Block>
        <Bootstrap.Row
          gutterHorizontal={15}
          gutterVertical={15}
          style={{ minHeight: 160 }}
        >
          <Bootstrap.Column
            span={{ lg: 8, md: 9, sm: 8, xl: 6, xs: 12 }}
            style={Styling.rowCenter}
          >
            <Icon
              large
              circleColor={this.props.colors && this.props.colors.lightBrand}
              color={this.props.colors && this.props.colors.brand}
              productId={policy.productId}
              style={Styling.marginRight}
            />
            <View style={Styling.flex}>
              <Text type='subtitle'>{policy?.productName}</Text>
              <Text type='descriptionLight'>
                {i18n.t('Polisnummer')}: {policy.policyNumber}
              </Text>
            </View>
          </Bootstrap.Column>

          <Bootstrap.Column
            span={{ lg: 4, md: 3, sm: 4, xl: 6, xs: 12 }}
            style={Styling.itemsEnd}
          >
            {policy.status === 'Lopend' && policy.premium
              ? (
                <>
                  <Amount
                    currency
                    color={this.props.colors && this.props.colors.brand}
                    type='subtitle'
                    hidePaymentPeriod={policy.productId === 77} // Hide payment period for 'kortlopende reis' product
                  >
                    {policy.premium}
                  </Amount>
                  {policy.productId !== 77 ? <Text type='descriptionLight'>{common.determinePaymentPeriod(relation?.paymentPeriod)}</Text> : null}
                </>
                )
              : null}

            {policy.status === 'Geroyeerd' ? <Text type='descriptionError'>{i18n.t('Beëindigd')}</Text> : null}

            {policy.status === 'Toekomstig'
              ? (
                <Text
                  color={Colors.primary}
                  type='descriptionLight'
                >
                  {i18n.t('Toekomstig')}
                </Text>
                )
              : null}

            {policy.status === 'Opgeschort' ? <Text type='descriptionLight'>{i18n.t('Opgeschort')} </Text> : null}
          </Bootstrap.Column>
          <Bootstrap.Column
            offsetRight={{ lg: 4, md: 3, sm: 0, xl: 0, xs: 0 }}
            span={{ lg: 8, md: 9, sm: 12, xl: 6, xs: 12 }}
            style={Styling.row}
          >
            {importantDetails
              ? importantDetails.map((detail) => (
                <Attribute
                  noMargin
                  description={detail?.code}
                  key={`item-policy-detail-${detail?.name}`}
                  style={Styling.flex}
                  title={detail?.label}
                />
              ))
              : null}
          </Bootstrap.Column>
          <Bootstrap.Column
            span={{ lg: 0, md: 0, sm: 0, xl: 6, xs: 0 }}
            style={Styling.rowEndEnd}
          >
            {this.renderActions()}
          </Bootstrap.Column>
          <Bootstrap.Column
            span={{ lg: 12, md: 12, sm: 12, xl: 0, xs: 12 }}
            style={Styling.rowEndStart}
          >
            {this.renderActions()}
          </Bootstrap.Column>
        </Bootstrap.Row>
      </Block>
    )
  }
}

export default connect(mapStateToProps.themeColors)(ItemPolicy)
