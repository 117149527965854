/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'
import { Image } from 'react-native'

type TImage = {
  height: number
  width: number
} & Image

export default ({ height, width, style, ...props }: TImage) =>
  <Image
    {...props}
    style={[
      style,
      {
        height: height ?? 100,
        width: width ?? 100
      }
    ]}
  />
