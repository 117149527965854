import React from 'react'
import { useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import Screen from '@/components/Screen'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'

import i18n from '@/utils/i18n'
import selectors from '@/redux/selectors'
import usePermissions from '@/hooks/usePermissions'
import { IS_WEB } from '@/constants/platform'

const ManagePermissions = () => {
  const colors = useSelector(selectors.getThemeColors)

  const {
    permissions,
    requestPermission,
    revokePermission
  } = usePermissions()

  return (
    <Screen>
      <Bootstrap.Row
        gutterHorizontal={15}
        gutterVertical={15}
      >
        {!IS_WEB
          ? (
            <Bootstrap.Column span={{ lg: 6, md: 8, sm: 12, xl: 4, xs: 12 }}>
              <Button
                color={Colors.white}
                icon='camera'
                iconCircleColor={colors?.lightBrand}
                iconColor={colors?.brand}
                iconColorRight={permissions.camera?.granted ? Colors.success : Colors.red}
                iconRight={permissions.camera?.granted ? 'check-circle' : 'times-circle'}
                onPress={permissions.camera?.granted
                  ? revokePermission
                  : () => requestPermission('camera')}
                title={i18n.t('Camera')}
                titleColor={Colors.gray}
                titleStyle={Styling.autoRight}
              />
            </Bootstrap.Column>
            )
          : null}

        {!IS_WEB
          ? (
            <Bootstrap.Column span={{ lg: 6, md: 8, sm: 12, xl: 4, xs: 12 }}>
              <Button
                color={Colors.white}
                icon='images'
                iconCircleColor={colors?.lightBrand}
                iconColor={colors?.brand}
                iconColorRight={permissions.mediaLibrary?.granted ? Colors.success : Colors.red}
                iconRight={permissions.mediaLibrary?.granted ? 'check-circle' : 'times-circle'}
                onPress={permissions.mediaLibrary?.granted
                  ? revokePermission
                  : () => requestPermission('mediaLibrary')}
                title={i18n.t('Fotogallerij')}
                titleColor={Colors.gray}
                titleStyle={Styling.autoRight}
              />
            </Bootstrap.Column>
            )
          : null}

        <Bootstrap.Column span={{ lg: 6, md: 8, sm: 12, xl: 4, xs: 12 }}>
          <Button
            color={Colors.white}
            icon='location'
            iconCircleColor={colors?.lightBrand}
            iconColor={colors?.brand}
            iconColorRight={permissions.location?.granted ? Colors.success : Colors.red}
            iconRight={permissions.location?.granted ? 'check-circle' : 'times-circle'}
            onPress={permissions.location?.granted
              ? revokePermission
              : () => requestPermission('location')}
            title={i18n.t('Locatie')}
            titleColor={Colors.gray}
            titleStyle={Styling.autoRight}
          />
        </Bootstrap.Column>

        {!IS_WEB
          ? (
            <Bootstrap.Column span={{ lg: 6, md: 8, sm: 12, xl: 4, xs: 12 }}>
              <Button
                color={Colors.white}
                icon='bell'
                iconCircleColor={colors?.lightBrand}
                iconColor={colors?.brand}
                iconColorRight={permissions.notifications?.granted ? Colors.success : Colors.red}
                iconRight={permissions.notifications?.granted ? 'check-circle' : 'times-circle'}
                onPress={permissions.notifications?.granted
                  ? revokePermission
                  : () => requestPermission('notifications')}
                title={i18n.t('Notificaties')}
                titleColor={Colors.gray}
                titleStyle={Styling.autoRight}
              />
            </Bootstrap.Column>
            )
          : null}
      </Bootstrap.Row>
    </Screen>
  )
}

export default ManagePermissions
