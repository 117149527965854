import { put, takeEvery, select, all } from 'redux-saga/effects'

import type { Agency, Relation } from '@/types/objects'

import type { IApiResponse } from '@/utils/api_wrapper'
import toast from '@/utils/toast'

import { getAgencyId } from '../selectors/agency'
import { isAllowedToFetch } from '../selectors/caching'
import { getCurrentRelationId } from '../selectors/relations'

import * as ACTIONS from '../actions/api'

import callApi, { apiFunctions } from '@/redux/api'
import { API_CALL, API_CALL_SUCCESS } from '../constants/api'

function * apiCall (action: ACTIONS.TApiCall) {
  const { method, entity, id } = action.payload

  try {
    const apiFunction = apiFunctions?.[entity]?.[method]

    if (apiFunction) {
      const { params, skipCaching } = action.payload

      const allowedToFetch: boolean = yield select(isAllowedToFetch, action)

      if (method !== 'GET' || allowedToFetch || skipCaching) {
        const result: IApiResponse<unknown> = yield callApi(apiFunction, id, params)

        if (method !== 'GET' && result.message) {
          toast(result.message)
        }

        if (result.success) {
          yield put(ACTIONS.apiCallSuccess(method, entity, id, result.data))
        } else {
          throw new Error(result.message)
        }
      } else {
        yield put(ACTIONS.apiCallCancelled(method, entity, id))
      }
    }
  } catch (err) {
    yield put(ACTIONS.apiCallFailed(method, entity, id, err))
  }
}

function * apiCallSuccess (action: ACTIONS.TApiCallSuccess) {
  const { method, entity, id, data } = action.payload

  yield put({
    type: `${method}_${entity}_SUCCESS`,
    payload: { data, method, entity, id }
  })
}

function * refetchAll () {
  const agencyId: Agency['id'] = yield select(getAgencyId)
  const relationId: Relation['id'] = yield select(getCurrentRelationId)

  yield all([
    put(ACTIONS.apiCall('GET', 'AGENCY', agencyId)),
    put(ACTIONS.apiCall('GET', 'RELATION', relationId)),
    put(ACTIONS.apiCall('GET', 'CLAIMS')),
    put(ACTIONS.apiCall('GET', 'POLICIES')),
    put(ACTIONS.apiCall('GET', 'RELATIONS'))
  ])
}

export function * api () {
  yield takeEvery(API_CALL, apiCall)
  yield takeEvery(API_CALL_SUCCESS, apiCallSuccess)
  yield takeEvery('WIPE_CACHE', refetchAll)
}
