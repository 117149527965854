/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

import Block from '@/components/Block'
import Screen from '@/components/Screen'
import * as Bootstrap from '@/bootstrap'

import { postPurchaseInvoice, postPurchasePicture } from '@/redux/api/purchases'
import { apiCallSuccess } from '@/redux/actions/api'
import { getPurchases } from '@/redux/selectors/purchases'

import toast from '@/utils/toast'
import i18n from '@/utils/i18n'

import type { Purchase } from '@/types/objects'

import PurchaseForm, { inputLabels } from './components/purchase_form'
import { convertPurchaseToEditPurchaseInvoice, convertPurchaseToEditPurchasePicture } from '@/types/purchaseDto'

interface IProps {
  purchaseId: Purchase['id']
}

const EditPurchase: React.FC<IProps> = (props) => {
  const purchases = useSelector(getPurchases)

  const dispatch = useDispatch()
  const navigation = useNavigation()

  const [purchase, setPurchase] = useState<Partial<Purchase>>(purchases?.find((p) => p.id === props.purchaseId) ?? {})
  const [loading, setLoading] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [invalidInputs, setInvalidInputs] = useState<string[]>([])

  const setItem = (item: Partial<Purchase>) => {
    setPurchase({
      ...purchase,
      ...item
    })

    setShowError(false)
  }

  const validate = () =>
    [
      !purchase.name || purchase.name.length < 1 ? inputLabels.name : null,
      !purchase.price || purchase.price.length < 1 ? inputLabels.price : null,
      !purchase.date || purchase.date.length < 1 ? inputLabels.date : null
    ].filter(Boolean) as string[]

  const submit = async () => {
    setLoading(true)

    if (validate().length > 0) {
      setInvalidInputs(validate())
      setShowError(true)
      setLoading(false)
      return
    }

    const invoicePurchase = convertPurchaseToEditPurchaseInvoice(purchase as Purchase)
    const invoiceResult = await postPurchaseInvoice(invoicePurchase)

    const picturePurchase = convertPurchaseToEditPurchasePicture(purchase as Purchase)
    const pictureResult = await postPurchasePicture(picturePurchase)

    if (invoiceResult.success && pictureResult.success) {
      const aggregatedPurchase = {
        ...purchase,
        ...invoiceResult.data,
        ...pictureResult.data
      }

      const updatedPurchases = purchases.map(purchase => purchase.id === aggregatedPurchase.id ? aggregatedPurchase : purchase)

      dispatch(apiCallSuccess('GET', 'PURCHASES', undefined, updatedPurchases))
      navigation.goBack()
    } else {
      toast(i18n.t('Het item kon niet worden gewijzigd'))
    }

    setLoading(false)
  }

  return (
    <PurchaseForm
      purchase={purchase}
      loading={loading}
      showError={showError}
      invalidInputs={invalidInputs}
      onSubmit={submit}
      setPurchase={setItem}
    />
  )
}

const Phone: React.FC<IProps> = (props) => (
  <Screen white>
    <EditPurchase {...props} />
  </Screen>
)

const Tablet: React.FC<IProps> = (props) => (
  <Screen>
    <Block>
      <Bootstrap.Row>
        <Bootstrap.Column span={{ lg: 6, md: 9, sm: 12, xl: 4, xs: 12 }}>
          <EditPurchase {...props} />
        </Bootstrap.Column>
      </Bootstrap.Row>
    </Block>
  </Screen>
)

export default (props: IProps) => <Bootstrap.Switch
  renderPhone={Phone}
  renderTablet={Tablet}
  {...props}
                                  />
