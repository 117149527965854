import type { PathConfigMap } from '@react-navigation/native'

export default {
  AboutApp: 'over-de-app',
  AboutNh1816: 'nh1816-verzekeringen',
  CreatePurchase: 'item-toevoegen',
  AddUser: 'gebruiker-uitnodigen',
  AgencyStack: 'adviseur',

  ClaimDamage: '', // Initial route of the stack.
  ClaimDamageForm: 'schadeformulier',
  ClaimDamageStack: 'schade-melden',

  CaseStack: {
    path: 'dossier',
    initialRouteName: 'Case',
    screens: {
      Case: {
        path: ''
      }
    }
  },
  ClaimsStack: {
    path: 'mijn-schades',
    initialRouteName: 'Claims',
    screens: {
      Claims: {
        path: ''
      },
      Claim: {
        path: ':claimId'
      }
    }
  },

  CombiPolicy: 'mijn-pakket',
  Company: 'schadeherstelbedrijf',
  DamageRepairStack: 'schadehersteller',
  DamageRepair: '',
  DamageRepairVehicle: 'voertuig',
  EditPurchase: 'item-wijzigen',
  EditUser: 'gebruiker-wijzigen',
  ForgotPassword: 'wachtwoord-vergeten',
  GreenCardsStack: 'verzekeringsbewijzen',
  GreenCard: 'verzekeringsbewijs',
  GreenCards: 'overzicht',
  GenerateGreenCard: 'verzekeringsbewijs-genereren',
  HistoricalClaim: 'historische-schade-details',
  Home: 'home',
  HouseholdEffectsValueMeter: 'Inboedelwaardemeter',
  Login: 'login',
  ManageFacialRecognition: 'gezichtsherkenning-instellen',
  ManageFingerprint: 'vingerafdruk-instellen',
  ManageLogin: 'inloggegevens-wijzigen',
  ManagePermissions: 'toestemmingen',
  ManagePincode: 'pincode-wijzigen',
  ManageRelation: 'gegevens-wijzigen',
  ManageUsers: '', // Initial route of the stack.
  ManageUsersStack: 'gebruikers-beheren',
  ManageVerify: 'inlogmethode-wijzigen',
  MemberBenefits: '', // Initial route of stack
  MemberBenefitsDetail: 'ledenvoordeel',
  MemberBenefitsStack: 'ledenvoordelen',
  More: '', // Initial route of the stack.
  MoreStack: 'meer',
  Notification: 'notificatie',
  Notifications: '', // Initial route of the stack.
  NotificationsStack: 'notificaties',
  Overview: 'overzicht',
  Payments: 'betaaloverzicht',
  Policies: '', // Initial route of the stack.
  PoliciesStack: 'verzekeringen',
  Policy: 'verzekering',
  AddPolicy: 'verzekering-toevoegen',
  AddPolicySuccess: 'verzekering-toevoegen-succes',
  PolicyCondition: 'polisvoorwaarden',
  PolicyStatement: 'polisblad',
  Profile: '', // Initial route of the stack.
  ProfileStack: 'mijn-profiel',
  Purchase: 'item',
  Purchases: '', // Initial route of the stack.
  PurchasesStack: 'mijn-bezittingen',
  Questions: 'vragen',
  Register: 'registreer',
  ResetPassword: 'wachtwoord-herstellen',
  ServiceGuide: 'dienstverleningsdocument',
  Settings: 'voorkeuren',
  Setup: 'setup',
  SwitchCombiPolicy: 'combipolis-selecteren',
  AddRelationToCombi: 'combipolis-toevoegen',
  TermsOfUse: 'gebruiksvoorwaarden',
  TipCategories: '', // Initial route of the stack.
  TipCategory: 'preventietip',
  TipsStack: 'preventietips',
  ValueMetersStack: 'waardemeters',
  ValueMeters: '',
  RebuildValueMeter: 'herbouwwaardemeter',
  Verify: 'verifieer',
  WhatToDo: 'wat-te-doen-bij',
  WhatToDos: 'wat-te-doen-bij-overzicht'
} as PathConfigMap
