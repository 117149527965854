import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import Svg, { Defs, Ellipse, G, Path } from 'react-native-svg'

interface TEmailProps {
  style?: StyleProp<ViewStyle>
  width?: number
}

const defaultWidth = 228
const defaultHeight = 147
export const ratio = defaultHeight / defaultWidth

export default function SvgComponent (props: TEmailProps) {
  const width = props.width || defaultWidth
  const height = props.width ? width * ratio : defaultHeight
  return (
    <Svg
      height={height}
      style={props.style}
      viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}
      width={width}
    >
      <Defs />
      <G
        fill='none'
        fillRule='evenodd'
        transform='translate(0 1)'
      >
        {/* @ts-expect-error ts-migrate(2322) FIXME: Property 'filter' does not exist on type 'Intrinsi... Remove this comment to see the full error message */}
        <Ellipse
          cx={114}
          cy={125}
          fill='#FBFAFA'
          filter='url(#prefix__a)'
          rx={114}
          ry={21}
        />
        <Path
          d='M164.25 24.875v42.3l-42.89 30.981c-1.817 1.317-5.113 2.386-7.356 2.386-2.244 0-5.54-1.069-7.356-2.386L63.75 67.174V24.875a8.378 8.378 0 018.375-8.375h83.75a8.378 8.378 0 018.375 8.375z'
          fill='#E7E4E2'
          fillRule='nonzero'
        />
        <Path
          d='M134.937 58.375H93.062a4.189 4.189 0 00-4.187 4.188v4.187a4.189 4.189 0 004.187 4.188h41.875a4.189 4.189 0 004.188-4.188v-4.187a4.189 4.189 0 00-4.188-4.188zm4.188-16.75a4.189 4.189 0 00-4.188-4.187H93.062a4.189 4.189 0 00-4.187 4.187v4.188A4.189 4.189 0 0093.062 50h41.875a4.189 4.189 0 004.188-4.187v-4.188zM86.78 16.5h54.438l-8.494-6.265C128.192 6.845 119.855-.338 114-.25c-5.855-.088-14.19 7.093-18.725 10.485L86.781 16.5zM63.75 67.172v-33.66l-11.347 8.443c-.278.212-.478.366-.59.455-2.653 2.08-4.809 6.502-4.813 9.872v2.788l16.75 12.102zM176.187 42.41l-1.348-1.018c-2.012-1.506-5.93-4.414-10.59-7.862v33.642L181 55.07v-2.788c-.003-3.37-2.16-7.792-4.812-9.872zM114 108.92c3.74-.001 9.234-1.782 12.264-3.975L181 65.405v55.782c0 6.935-5.628 12.563-12.563 12.563H59.562c-6.934 0-12.562-5.628-12.562-12.563V65.405l54.736 39.54c2.93 2.124 8.169 3.86 11.893 3.97l.37.006z'
          fill='#84726F'
          fillRule='nonzero'
        />
      </G>
    </Svg>
  )
}
