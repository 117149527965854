/* eslint-disable no-console */

import { v4 as uuid } from 'uuid'

interface IHistoryItem {
  id: string
  parameters: any[]
  timestamp: number
  type: string
}

const MAX_LENGTH = 256

class Logger {
  private history: IHistoryItem[] = []

  getHistory = () => this.history

  private readonly perform = (consoleFunction: (...params: unknown[]) => void, ...params: unknown[]) => {
    this.history.unshift({
      id: uuid(),
      parameters: params,
      timestamp: Date.now(),
      type: consoleFunction?.name
    })

    this.history.length = MAX_LENGTH

    if (__DEV__ && consoleFunction) {
      consoleFunction(...params)
    }
  }

  log = (...params: unknown[]) => this.perform(console.log, ...params)

  warn = (...params: unknown[]) => this.perform(console.warn, ...params)

  error = (...params: unknown[]) => this.perform(console.error, ...params)
}

export default new Logger()
