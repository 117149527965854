import React from 'react'

import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import type { Policy } from '@/types/objects'
import i18n from '@/utils/i18n'

import InsuranceCards from './insurance_cards'

const InsuranceCardsPhone: React.FC<{ policy: Policy }> = ({ policy }) => {
  if (!policy?.insuranceCards?.length) {
    return null
  }

  const multipleInsuranceCards = policy?.insuranceCards?.length > 1

  return (
    <>
      <Text
        style={Styling.marginVertical}
        type='subsection'
      >
        {multipleInsuranceCards ? i18n.t('Verzekeringskaarten') : i18n.t('Verzekeringskaart')}
      </Text>

      <InsuranceCards policy={policy} />
    </>
  )
}

export default InsuranceCardsPhone
