import * as CONSTANTS from '@/redux/constants/index'

export const navigated = (routeName: string) => ({
  routeName,
  type: CONSTANTS.NAVIGATED
})

export const wipeCache = () => ({
  type: 'WIPE_CACHE'
})

export const resetState = () => ({
  type: CONSTANTS.RESET_STATE
})
