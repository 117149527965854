import React from 'react'
import { Animated, Easing, StyleSheet, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import date from '@/utils/date'

import ContextBreakpoint from '@/bootstrap/ContextBreakpoint'
import Button from '@/components/buttons/Button'
import Icon from '@/components/Icon'
import ImageAutoWidth from '@/components/images/ImageAutoWidth'
import ItemSidebar from '@/components/ItemSidebar'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import type { TSafeAreaInsetsProps } from '@/hocs/withSafeAreaInsets'
import withSafeAreaInsets from '@/hocs/withSafeAreaInsets'
import Backdrop from '@/navigation/components/Backdrop'
import { startClaimDamageForm } from '@/redux/actions/claims'
import { apiCall, wipeCache } from '@/redux/actions/api'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import common from '@/utils/common'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import type { Agency, Relation, User } from '@/types/objects'
import type { ThemeColors } from '@/redux/reducers/theme'
import { IS_WEB } from '@/constants/platform'
import OfficeInfo from '@/components/OfficeInfo'

interface IButton {
  icon: string
  isCurrent: boolean | ((name: string) => boolean)
  onPress: () => void
  title: string
}

const getButtons = (showMemberBenefits: boolean): IButton[] => {
  const buttons = [
    {
      icon: 'home',
      isCurrent: navigation.isCurrentOverview,
      onPress: navigation.openScreenOverview,
      title: i18n.t('Overzicht')
    },
    {
      icon: 'folder',
      isCurrent: navigation.isCurrentCase,
      onPress: navigation.openScreenCase,
      title: i18n.t('Mijn dossier')
    },
    {
      icon: 'copy',
      isCurrent: navigation.isCurrentPolicies,
      onPress: navigation.openScreenPolicies,
      title: i18n.t('Mijn verzekeringen')
    },
    {
      icon: 'user-tie',
      isCurrent: navigation.isCurrentAgency,
      onPress: navigation.openScreenAgency,
      title: i18n.t('Adviseur')
    },
    {
      icon: 'ellipsis-v',
      isCurrent: navigation.isCurrentMore,
      onPress: navigation.openScreenMore,
      title: i18n.t('Meer')
    }
  ]
  if (showMemberBenefits) {
    const memberBenefitsButton = {
      icon: 'tag',
      isCurrent: navigation.isCurrentMemberBenefits,
      onPress: navigation.openScreenMemberBenefits,
      title: i18n.t('Ledenvoordelen')
    }
    buttons.splice(buttons.length - 2, 0, memberBenefitsButton)
  }
  return buttons
}

type TWrapperProps = TSafeAreaInsetsProps & {
  agency: Agency | null
  relation: Relation
  user: User

  amountUnreadNotifications: number
  routeName: string
  children: React.ReactNode

  colors: ThemeColors

  wipeCache: typeof wipeCache
  startClaimDamageForm: typeof startClaimDamageForm
  apiCall: typeof apiCall
}

interface TWrapperState {
  open: boolean
}

class Wrapper extends React.Component<TWrapperProps, TWrapperState> {
  static contextType = ContextBreakpoint

  position: any

  constructor (props: TWrapperProps) {
    super(props)

    this.state = {
      open: false
    }
  }

  componentDidMount () {
    this.position = new Animated.ValueXY({ x: -1 * Sizes.sidebarWidth, y: 0 })
    this.props.apiCall('GET', 'POLICIES')
  }

  startClaimDamageForm = () => this.props.startClaimDamageForm()

  toggleDrawer = () =>
    this.setState((previousState: TWrapperState) => ({
      open: !previousState.open
    }))

  openDrawer = () => {
    this.setState({ open: true })
    Animated.timing(this.position, {
      duration: 350,
      toValue: { x: 0, y: 0 },
      useNativeDriver: !IS_WEB,
      easing: Easing.ease
    }).start()
  }

  closeDrawer = () => {
    Animated.timing(this.position, {
      duration: 350,
      toValue: { x: -1 * Sizes.sidebarWidth, y: 0 },
      useNativeDriver: !IS_WEB,
      easing: Easing.ease
    }).start(() => this.setState({ open: false }))
  }

  wrapDrawerFunction = (func: () => void) => {
    func()
    this.closeDrawer()
  }

  render () {
    const isNotifications = navigation.isCurrentNotifications(this.props.routeName)
    const isProfile = navigation.isCurrentProfile(this.props.routeName)
    let buttons = getButtons(this.props.relation.cooperationMembership)

    if (__DEV__ || this.props.user.email === 'demo@nh1816.nl') {
      buttons = buttons.concat({
        icon: 'backspace',
        isCurrent: false,
        onPress: this.props.wipeCache,
        title: i18n.t('Cache legen')
      })
    }

    return (
      <>
        {this.context === 'xxs' || this.context === 'xs'
          ? (
            <View style={styles.menuContainer}>
              <TouchableOpacity
                onPress={this.openDrawer}
                style={Styling.flexRowCenter}
              >
                <Icon
                  large
                  color={this.props.colors?.brand}
                  icon={['fal', 'bars']}
                  style={Styling.marginRightTiny}
                />
                <Text type='menu'>{i18n.t('Menu')}</Text>
              </TouchableOpacity>

              <View style={Styling.flexRowCenter}>
                <TouchableOpacity
                  onPress={navigation.openScreenNotifications}
                  style={Styling.autoLeft}
                >
                  <Icon
                    badgeNumber={
                    this.props.amountUnreadNotifications > 0 ? this.props.amountUnreadNotifications : undefined
                  }
                    color={isNotifications ? Colors.gray : Colors.lightBrown}
                    icon='bell'
                    size={Sizes.headerIcon}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={navigation.openScreenProfile}
                  style={Styling.marginLeft}
                >
                  <Icon
                    color={isProfile ? Colors.gray : Colors.lightBrown}
                    icon='user'
                    size={Sizes.headerIcon}
                  />
                </TouchableOpacity>
              </View>
            </View>
            )
          : null}

        {this.context !== 'xxs' && this.context !== 'xs'
          ? (
            <View style={[styles.headerContainer, { paddingTop: Sizes.spacingVertical + this.props.insets.top }]}>
              <TouchableOpacity onPress={navigation.openScreenOverview}>
                {this.props.agency?.logo
                  ? (
                    <ImageAutoWidth
                      height={55}
                      source={{ uri: `${this.props.agency.logo}?${date.now()}` }}
                    />
                    )
                  : null}
              </TouchableOpacity>

              <View style={styles.profileContainer}>
                <TouchableOpacity
                  onPress={navigation.openScreenNotifications}
                  style={styles.touchable}
                >
                  <Icon
                    large
                    badgeNumber={
                    this.props.amountUnreadNotifications > 0 ? this.props.amountUnreadNotifications : undefined
                  }
                    color={isNotifications ? Colors.gray : Colors.lightBrown}
                    icon='bell'
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={navigation.openScreenProfile}
                  style={Styling.marginLeftSmall}
                >
                  <View style={Styling.rowCenter}>
                    <Icon
                      color={isProfile ? Colors.gray : Colors.lightBrown}
                      icon='user'
                      style={Styling.marginRightSmall}
                    />
                    <Text type='paragraph'>{common.determineFullName(this.props.user)}</Text>
                  </View>

                  {this.props.relation?.combiNumber
                    ? (
                      <Text type='paragraphLight'>
                        {i18n.t('Combinummer')}: {this.props.relation.combiNumber}
                      </Text>
                      )
                    : null}
                </TouchableOpacity>
              </View>
            </View>
            )
          : null}

        <View style={Styling.flexRow}>
          {this.context !== 'xxs' && this.context !== 'xs'
            ? (
              <View style={Styling.backgroundWhite}>
                {buttons.map((button, index) => (
                  <ItemSidebar
                    active={
                      typeof button.isCurrent === 'function' ? button.isCurrent(this.props.routeName) : button.isCurrent
                    }
                    icon={button.icon}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`sidebar-button-${button.title}-${index}`}
                    onPress={button.onPress}
                    title={button.title}
                  />
                ))}
                <Button
                  icon='fragile'
                  iconCircleColor={Colors.white}
                  onPress={this.startClaimDamageForm}
                  style={styles.button}
                  title={i18n.t('Schade melden')}
                />
                {this.renderOfficeInfo()}
              </View>
              )
            : null}

          <View style={styles.childrenContainer}>
            {this.props.children}
          </View>
        </View>

        {this.state.open ? <Backdrop onPress={this.closeDrawer} /> : null}

        <Animated.ScrollView style={[styles.scrollView, this.position?.getLayout()]}>
          <ItemSidebar
            icon={['fal', 'bars']}
            iconColor={this.props.colors?.brand}
            iconSize={Sizes.iconLarge}
            iconStyle={styles.itemIcon}
            onPress={this.closeDrawer}
            textStyle={styles.itemText}
            textType='menu'
            title={i18n.t('Menu')}
          />
          {buttons.map((button, index) => (
            <ItemSidebar
              active={
                typeof button.isCurrent === 'function' ? button.isCurrent(this.props.routeName) : button.isCurrent
              }
              icon={button.icon}
              // eslint-disable-next-line react/no-array-index-key
              key={`sidebar-button-${button.title}-${index}`}
              onPress={() => this.wrapDrawerFunction(button.onPress)}
              title={button.title}
            />
          ))}
          <Button
            color={Colors.primary}
            icon='fragile'
            iconCircleColor={Colors.white}
            iconColor={Colors.primary}
            onPress={() => this.wrapDrawerFunction(this.props.startClaimDamageForm)}
            style={styles.button}
            title={i18n.t('Schade melden')}
          />
        </Animated.ScrollView>
      </>
    )
  }

  renderOfficeInfo = () => {
    if (!this.props.agency?.name) {
      return null
    }

    return (
      <View style={styles.officeInfoContainer}>
        <OfficeInfo />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  officeInfoContainer: {
    paddingHorizontal: Sizes.spacingHorizontalMedium,
    paddingVertical: Sizes.spacingVertical,
    marginTop: Sizes.spacingVerticalMedium,
    borderTopColor: Colors.background,
    borderTopWidth: 1,
    borderBottomColor: Colors.background,
    borderBottomWidth: 1
  },
  button: {
    marginHorizontal: Sizes.spacingHorizontalMedium,
    marginVertical: Sizes.spacingVerticalNormal
  },
  childrenContainer: {
    backgroundColor: Colors.white,
    flex: 1,
    zIndex: -1
  },
  headerContainer: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.background,
    borderBottomWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: Sizes.spacingHorizontalMedium,
    paddingVertical: Sizes.spacingVertical
  },
  itemIcon: {
    marginLeft: -2
  },
  itemText: {
    marginLeft: Sizes.spacingHorizontal - 2
  },
  menuContainer: {
    borderBottomColor: Colors.ed,
    borderBottomWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: Sizes.spacingHorizontalNormal,
    paddingVertical: Sizes.spacingVerticalNormal,
    width: '100%'
  },
  profileContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 'auto'
  },
  scrollView: {
    backgroundColor: Colors.white,
    bottom: 0,
    left: -1 * Sizes.sidebarWidth,
    position: 'absolute',
    top: 0,
    width: Sizes.sidebarWidth
  },
  sidebarContainer: {
    borderBottomColor: Colors.background,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Sizes.spacingHorizontal,
    paddingVertical: Sizes.spacingVerticalSmall
  },
  touchable: {
    paddingHorizontal: Sizes.spacingHorizontalSmall,
    paddingVertical: Sizes.spacingVertical
  }
})

const mapStateToProps = (state: TState) => {
  return {
    agency: selectors.getAgency(state),
    amountUnreadNotifications: selectors.getAmountUnreadNotifications(state),

    colors: selectors.getThemeColors(state),
    relation: selectors.getCurrentRelation(state)!,
    user: selectors.getUser(state)
  }
}

export default connect(mapStateToProps, {
  startClaimDamageForm,
  wipeCache,
  apiCall
})(withSafeAreaInsets(Wrapper))
