import { createStackNavigator } from '@react-navigation/stack'
import i18n from '@/utils/i18n'
import GreenCard from '@/screens/green_card/GreenCard'
import GenerateGreenCard from '@/screens/policies/details/components/generate_green_card'
import GeneratedGreenCard from '@/screens/green_card/GeneratedGreenCard'
import GreenCards from '@/screens/green_card/GreenCards'

const GreenCardStack = createStackNavigator()
const GreenCardStackNavigator = () => (
  <GreenCardStack.Navigator screenOptions={{ headerShown: false }}>
    <GreenCardStack.Screen
      name='GreenCards'
      component={GreenCards}
      options={{ title: i18n.t('Verzekeringsbewijzen') }}
    />

    <GreenCardStack.Screen
      name='GreenCard'
      component={GreenCard}
      options={{ title: i18n.t('Verzekeringsbewijs') }}
    />

    <GreenCardStack.Screen
      name='GenerateGreenCard'
      component={GenerateGreenCard}
      options={{ title: i18n.t('Verzekeringsbewijzen genereren') }}
    />

    <GreenCardStack.Screen
      name='GeneratedGreenCard'
      component={GeneratedGreenCard}
      options={{ title: i18n.t('Verzekeringsbewijzen') }}
    />
  </GreenCardStack.Navigator>
)

export default GreenCardStackNavigator
