import type { ErrorInfo } from 'react'
import React from 'react'

import logger from '@/utils/logger'

export default class ErrorBoundary extends React.Component {
  static getDerivedStateFromError () {
    return {}
  }

  componentDidCatch (error: Error, errorInfo: ErrorInfo) {
    logger.warn(String(error), error, errorInfo)
  }

  render () {
    return this.props.children || null
  }
}
