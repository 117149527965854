import React from 'react'

import type { TInputProps } from '@/components/inputs/Input'
import Input from '@/components/inputs/Input'
import { IS_WEB } from '@/constants/platform'

interface IProps extends TInputProps {}

const InputTextArea: React.FC<IProps> = (props) => {
  const numberOfLines = props.numberOfLines || 4

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Input
      {...props}
      multiline
      minHeight={numberOfLines * 20}
      numberOfLines={numberOfLines}
      textAlignVertical='top'
      showMaxLength={IS_WEB}
    />
  )
}

export default InputTextArea
