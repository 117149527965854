import * as ExpoLocation from 'expo-location'

import logger from '@/utils/logger'
import axios from 'axios'
import { IS_IOS } from '@/constants/platform'
import Config from 'react-native-config'

class Location {
  logError = (error: any) => logger.warn('Location:', String(error), error)

  setApiKey = ExpoLocation.setGoogleApiKey

  wrapFunction =
    (func: any) =>
      async (...params: any) => {
        try {
        // eslint-disable-next-line @typescript-eslint/return-await
          return await func(...params)
        } catch (error) {
          this.logError(error)
        }
        return null
      }

  getCurrentPositionAsync = this.wrapFunction(ExpoLocation.getCurrentPositionAsync)

  // Geocode
  geocodeAsync = async (location: string) => {
    if (IS_IOS) {
      let positions = await ExpoLocation.geocodeAsync(`${location} Nederland`, { useGoogleMaps: false })

      if (positions?.length === 0) {
        positions = await ExpoLocation.geocodeAsync(`${location} Nederland`, { useGoogleMaps: false })
      }

      return positions
    } else {
      return await this.geocodeAsyncGoogleMaps(location)
    }
  }

  geocodeAsyncGoogleMaps = async (location: string) => {
    const baseUrl = 'https://maps.googleapis.com/maps/api/geocode/json'
    const apiKey = Config.GOOGLE_MAPS_API_KEY

    try {
      const response = await axios.get(`${baseUrl}?address=${location} Nederland&key=${apiKey}`)
      const data = response.data
      const firstResult = data.results[0]

      if (firstResult) {
        const latitude = firstResult.geometry.location.lat
        const longitude = firstResult.geometry.location.lng

        return [{ latitude, longitude }]
      } else {
        console.log('No results found.')
        return []
      }
    } catch (error) {
      console.error('Google maps api error')
      return []
    }
  }

  // Reverse geocode
  reverseGeocodeAsync = async (coordinates: { latitude: number, longitude: number }) => {
    if (IS_IOS) {
      return await this.reverseGeocodeAsynciOS(coordinates)
    } else {
      return await this.reverseGeocodeAsyncGoogleMaps(coordinates)
    }
  }

  reverseGeocodeAsynciOS = this.wrapFunction(ExpoLocation.reverseGeocodeAsync)

  reverseGeocodeAsyncGoogleMaps = async (coordinates: { latitude: number, longitude: number }) => {
    const baseUrl = 'https://maps.googleapis.com/maps/api/geocode/json'
    const apiKey = Config.GOOGLE_MAPS_API_KEY
    const response = await axios({
      method: 'get',
      url: `${baseUrl}?latlng=${coordinates?.latitude},${coordinates?.longitude}&key=${apiKey}`,
    })

    if (response?.status === 200) {
      const results = response.data.results

      if (results.length > 0) {
        const addressComponents = results[0].address_components
        let postalCode = ''
        let city = ''

        for (const component of addressComponents) {
          if (component.types.includes('postal_code')) {
            postalCode = component.short_name
          } else if (component.types.includes('locality')) {
            city = component.long_name
          }
        }

        if (postalCode !== '' && city !== '') {
          return [{ postalCode, city }]
        }
      }
    }

    return []
  }
}

export default new Location()
