import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import type { State } from '@/redux/reducers'
import Icon from '@/components/Icon'
import ListItem from '@/components/list_items/ListItem'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import common from '@/utils/common'
import i18n from '@/utils/i18n'
import type { Relation } from '@/types/objects'
import TouchableIcon from '../touchables/TouchableIcon'

interface IProps {
  current?: boolean
  relation: Relation
  onPressSelect?: (relation: Relation) => void
  onPressDelete?: (relation: Relation) => void
}

const ListItemCombiPolicy: React.FC<IProps> = (props) => {
  const { id, loading } = useSelector((state: State) => state?.relations?.deleteRelation || {})
  const deleteLoading = props?.relation?.id?.toString() === id?.toString() && loading

  const DeleteButton = () => {
    if (deleteLoading) {
      return (
        <ActivityIndicator
          color={Colors.primary}
          size='small'
        />
      )
    }

    if (props?.onPressDelete) {
      return (
        <TouchableIcon
          color={Colors.primary}
          icon='trash'
          onPress={() => props?.onPressDelete?.(props?.relation)}
        />
      )
    }

    return null
  }

  return (
    <ListItem
      backgroundColor={props?.current ? Colors.lightSuccess : Colors.lighterBrown}
      borderColor={props?.current ? Colors.success : undefined}
      item={props?.relation}
      onPress={props?.onPressSelect}
      disabled={props?.current || deleteLoading}
      style={styles.container}
    >
      <Icon
        circleColor={props?.current ? Colors.success : Colors.white}
        color={props?.current ? Colors.white : Colors.brown}
        icon='user'
        style={Styling.marginRight}
      />
      <View style={Styling.flex}>
        <Text
          color={Colors.brown}
          type='label'
        >
          {common.determineFullName(props?.relation)}
        </Text>
        <Text
          color={Colors.lightBrown}
          type='paragraph'
        >
          {i18n.t('Combipolisnummer')}: {props?.relation?.combiNumber}
        </Text>
      </View>

      {props?.current
        ? (
          <Icon
            color={Colors.success}
            icon='check-circle'
          />
          )
        : <DeleteButton />}
    </ListItem>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    marginTop: Sizes.spacingVertical,
    width: '100%'
  }
})

export default ListItemCombiPolicy
