import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import Icon from '@/components/Icon'
import ListItem from '@/components/list_items/ListItem'
import TouchableIcon from '@/components/touchables/TouchableIcon'
import Text from '@/components/wrappers/Text'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import type { Claim } from '@/types/objects'
import { getClaimConfig } from '@/utils/claims'
import selectors from '@/redux/selectors'
import data from '@/utils/data'

interface IListItemClaimProps {
  claim: Claim
  onPress: (claim: Claim) => void
  removeClaim: (claim: Claim) => void
}

const ListItemClaim: React.FC<IListItemClaimProps> = (props) => {
  const colors = useSelector(selectors.getThemeColors)

  const claim = props.claim || {}
  const config = getClaimConfig(claim?.status)
  const loadingDelete = useSelector(selectors.isLoading('CLAIM', claim.claimDamageId))?.method === 'DELETE'
  const distinctionLabel = data.getDistinctionLabel(claim)

  const onPressRemove = (): void => {
    props.removeClaim(claim)
  }

  return (
    <ListItem
      chevron
      item={props.claim}
      onPress={props.onPress}
    >
      <Icon
        circleColor={config.circleColor}
        color={config.color}
        icon={config.icon}
      />
      <View style={styles.container}>
        <Text type='description'>{`${claim.productName} ${distinctionLabel ? `(${distinctionLabel})` : ''}`}</Text>
        <Text type='descriptionLight'>{claim.policyNumber}</Text>
        <Text type={config.type}>{claim.status}</Text>
      </View>

      {claim.status === 'Concept (app)'
        ? (
          <TouchableIcon
            color={colors?.brand}
            icon='trash'
            onPress={onPressRemove}
            style={Styling.marginRightSmall}
            touchableStyle={Styling.rowCenter}
            loading={loadingDelete}
            disabled={loadingDelete}
          />
          )
        : null}
    </ListItem>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingHorizontal: Sizes.spacingVertical
  }
})

export default ListItemClaim
