import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as LocalAuthentication from 'expo-local-authentication'

import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

import * as userActions from '@/redux/actions/user'
import selectors from '@/redux/selectors'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import Screen from '@/components/Screen'

import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'

const ManageVerify: React.FC = () => {
  const dispatch = useDispatch()

  const colors = useSelector(selectors.getThemeColors)
  const user = useSelector(selectors.getUser)

  const [facialRecognitionAvailable, setFacialRecognitionAvailable] = useState<boolean>(false)
  const [fingerprintAvailable, setFingerprintAvailable] = useState<boolean>(false)

  const toggleFacialRecognition = () => {
    if (user?.facialRecognition) {
      dispatch(userActions.disableFacialRecognition())
    } else {
      navigation.openScreenManageFacialRecognition()
    }
  }

  const toggleFingerprint = () => {
    if (user?.fingerprint) {
      dispatch(userActions.disableFingerprint())
    } else {
      navigation.openScreenManageFingerprint()
    }
  }

  const checkBiometrics = async () => {
    const types = await LocalAuthentication.supportedAuthenticationTypesAsync()
    if (types?.length) {
      const faceAvailable = types.includes(LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION)
      const fingerAvailable = types.includes(LocalAuthentication.AuthenticationType.FINGERPRINT)

      setFacialRecognitionAvailable(faceAvailable)
      setFingerprintAvailable(fingerAvailable)
    }
  }

  useEffect(() => {
    checkBiometrics()
  }, [])

  return (
    <Screen>
      <Bootstrap.Column
        span={{ lg: 6, md: 6, sm: 8, xl: 6, xs: 12 }}
        style={Styling.autoHorizontal}
      >
        <Button
          color={Colors.white}
          icon='lock'
          iconCircleColor={colors?.lightBrand}
          iconColor={colors?.brand}
          onPress={navigation.openScreenManagePincode}
          style={Styling.marginBottom}
          title={i18n.t('Pincode wijzigen')}
          titleColor={Colors.gray}
        />

        {fingerprintAvailable
          ? <Button
              color={Colors.white}
              icon='fingerprint'
              iconCircleColor={user?.fingerprint
                ? colors?.lightBrand
                : Colors.transparentGray}
              iconColor={user.fingerprint
                ? colors?.brand
                : Colors.gray}
              onPress={toggleFingerprint}
              style={Styling.marginBottom}
              title={user.fingerprint
                ? i18n.t('Vingerafdruk uitzetten')
                : i18n.t('Vingerafdruk activeren')}
              titleColor={Colors.gray}
              titleStyle={Styling.flex}
            />
          : null}

        {facialRecognitionAvailable
          ? <Button
              color={Colors.white}
              icon='smile'
              iconCircleColor={user?.facialRecognition
                ? colors?.lightBrand
                : Colors.transparentGray}
              iconColor={user?.facialRecognition
                ? colors?.brand
                : Colors.gray}
              onPress={toggleFacialRecognition}
              style={Styling.marginBottom}
              title={user.facialRecognition
                ? i18n.t('Gezichtsherkenning uitzetten')
                : i18n.t('Gezichtsherkenning activeren')}
              titleColor={Colors.gray}
              titleStyle={Styling.flex}
            />
          : null}
      </Bootstrap.Column>
    </Screen>
  )
}

export default ManageVerify
