import { all } from 'redux-saga/effects'

import { api } from './api'
import { claimsSaga } from './claims'
import { defaultSaga } from './default'
import { filesSaga } from './files'
import { formsSaga } from './forms'
import { memberBenefitsSaga } from './member_benefits'
import { relationRepositorySaga } from './relationRepository'
import { relations } from './relations'
import { userSaga } from './user'

export default function * rootSaga () {
  yield all([
    api(),
    claimsSaga(),
    defaultSaga(),
    filesSaga(),
    formsSaga(),
    relationRepositorySaga(),
    relations(),
    userSaga(),
    memberBenefitsSaga()
  ])
}
