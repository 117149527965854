import AsyncStorage from '@react-native-async-storage/async-storage'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import rootReducer from '@/redux/reducers'
import rootSaga from '@/redux/sagas'
import logger from '@/utils/logger'

const persistConfig = {
  blacklist: ['loading', 'modals'],
  key: 'root',
  storage: AsyncStorage,
  stateReconciler: autoMergeLevel2
}

const crashReporter = () => (next: any) => (action: any) => {
  try {
    return next(action)
  } catch (error) {
    logger.warn('dispatch error', action)
    throw error
  }
}

const onError = (e: any) => {
  if (typeof e === 'object' && (e.message || e.stack)) {
    logger.warn('saga root error', e.message, e.stack)
  } else {
    logger.warn('saga root error', e)
  }
}

const sagaMiddleware = createSagaMiddleware({ onError })

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  undefined,
  composeWithDevTools(applyMiddleware(sagaMiddleware, crashReporter))
)

export const persistor = persistStore(store, undefined, () => sagaMiddleware.run(rootSaga))
