import React from 'react'
import { StyleSheet, View } from 'react-native'

import Sizes from '@/constants/Sizes'
import type { Policy } from '@/types/objects'

import Coverages from './coverages'

const CoveragesPhone: React.FC<{ policy: Policy }> = ({ policy }) => (
  <View style={styles.tab}>
    <Coverages policy={policy} />
  </View>
)

const styles = StyleSheet.create({
  tab: {
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingVertical: Sizes.screenPaddingVertical
  }
})

export default CoveragesPhone
