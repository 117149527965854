/* eslint-disable react/jsx-indent */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { File } from '@/components/inputs/InputFile'
import InputFile from '@/components/inputs/InputFile'

import { deleteAdditionalClaimAttachment, uploadAdditionalClaimAttachment } from '@/redux/actions/claims'
import type { ClaimDamageAdditionalFile } from '@/redux/reducers/forms'
import selectors from '@/redux/selectors'
import Text from '@/components/wrappers/Text'
import { ALLOWED_FILE_TYPES } from '@/constants/files'

interface IProps {
  saveAsConcept: (showConfirmation: boolean) => Promise<string>
}

const AdditionalFiles: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const id = useSelector(selectors.getFormClaimId)!
  const additionalFiles = useSelector(selectors.getFormClaimDamageAdditionalFiles)
  const showUnprocessableContentError = useSelector(selectors.getShowUnprocessableContentError)

  const hasExtension = (filename: string) => {
    const validExtensions = ALLOWED_FILE_TYPES
    const fileExtension = filename.slice(filename.lastIndexOf('.') + 1).toLowerCase()
    return validExtensions.includes(fileExtension)
  }

  const deleteAdditionalFile = (file: ClaimDamageAdditionalFile) => {
    const fileName = hasExtension(file.title) ? file.title : `${file.title}.${file.extension}`
    dispatch(deleteAdditionalClaimAttachment(fileName, file.url))
  }

  const uploadAdditionalFile = async (file: File, existingFile?: ClaimDamageAdditionalFile) => {
    let reference = id
    if (!id) {
      reference = await props.saveAsConcept(false)
    }
    // When overwriting existing selected file
    if (existingFile?.name) {
      deleteAdditionalFile(existingFile)
    } // if false, file was not uploaded (correctly)

    dispatch(uploadAdditionalClaimAttachment(reference, file))
  }

  const canAddAttachment = Object.keys(additionalFiles).length <= 9

  return (
    <>
      {Object.keys(additionalFiles).map((name, index) => {
        const currentFile = additionalFiles[name]

        return (
          <InputFile
            key={`uploaded-additional-file-upload-${name}`}
            onSelect={async (file) => await uploadAdditionalFile(file, currentFile)}
            onRemove={() => deleteAdditionalFile(currentFile)}
            file={currentFile}
            label={index === 0 ? 'Voeg bijlage(n) toe' : undefined}
            title='Selecteer bestand'
            description={index === 0 ? "Voeg relevante bijlage(n) toe. Denk aan kleurenfoto's van de schade, offertes, eventuele politierapporten en aankoopnota's." : undefined}
            loading={currentFile?.loading}
          />
        )
      })}

      {canAddAttachment
        ? <InputFile
            key='new-additional-file-upload'
            onSelect={async (file) => await uploadAdditionalFile(file)}
            onRemove={() => null}
            label={Object.values(additionalFiles).length === 0 ? 'Voeg bijlage(n) toe' : undefined}
            title='Selecteer bestand'
            description={Object.values(additionalFiles).length === 0 ? "Voeg relevante bijlage(n) toe. Denk aan kleurenfoto's van de schade, offertes, eventuele politierapporten en aankoopnota's." : undefined}
          />
        : null}

      {showUnprocessableContentError
        ? <Text
            type='descriptionError'
            style={{ marginTop: -22, marginBottom: 30 }}
          >
            Dit type bestand wordt niet ondersteund. Probeer een bestand van het type jpg, jpeg, png, txt, pdf, doc, docx, avi, mov, mpg, mpeg, mp4, msg, xls of xlsx te uploaden.
          </Text>
        : null}
    </>
  )
}

export default AdditionalFiles
