import React from 'react'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import Screen from '@/components/Screen'
import RefreshControl from '@/components/wrappers/RefreshControl'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import type { TApiCallProps } from '@/hocs/withApiCall'
import withApiCall from '@/hocs/withApiCall'
import { apiCall as apiCallDispatch } from '@/redux/actions/api'
import * as userApi from '@/redux/api/user'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import ListItemUser from '@/screens/profile/components/ListItemUser'
import type { Relation, User } from '@/types/objects'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import toast from '@/utils/toast'
import { ENTITIES } from '@/redux/constants'

type TManageUsersProps = TApiCallProps & {
  refreshing: boolean
  relationId: Relation['id']
  apiCallDispatch: typeof apiCallDispatch
  user: User
  users: User[]
}

interface IManageUsersState {
  loading: string[]
}

class ManageUsers extends React.Component<TManageUsersProps, IManageUsersState> {
  constructor (props: TManageUsersProps) {
    super(props)

    this.state = {
      loading: []
    }
  }

  componentDidMount () {
    this.refresh()
  }

  refresh = (skipCaching = false) => {
    this.props.apiCallDispatch('GET', ENTITIES.USERS, this.props?.relationId?.toString(), undefined, skipCaching)
  }

  onAddUser = async () => await navigation.openScreenAddUser()

  onEditUser = async (user: User) => await navigation.openScreenEditUser(user)

  onRemoveUser = async (user: User) => {
    if (user.mainUser) {
      toast(i18n.t('U kunt de hoofdgebruiker niet verwijderen'))
    } else if (user.id === this.props.user.id) {
      toast(i18n.t('U kunt uzelf niet verwijderen'))
    } else {
      this.setState({ loading: [...this.state.loading, user.id] })
      const result = await this.props.apiCall(userApi.deleteUser, this.props.relationId, user.id)
      if (result) {
        toast(i18n.t('De gebruiker is verwijderd'))
        this.refresh(true)
      } else {
        toast(i18n.t('De gebruiker kon niet worden verwijderd'))
      }
      this.setState((state, props) => this.userDoneLoading(state, props, user))
    }
  }

  userDoneLoading = (state: IManageUsersState, props: TManageUsersProps, user: User) => {
    return { loading: [...this.state.loading.filter((item: string) => item !== user.id)] }
  }

  onResendInvite = async (user: User) => {
    this.setState({ loading: [...this.state.loading, user.id] })
    const response = await this.props.apiCall(userApi.resendInvite, this.props.relationId, user.id)
    if (response.result === true) {
      toast(i18n.t('De gebruiker is opnieuw uitgenodigd. Hij of zij ontvangt een registratielink per e-mail.'))
      this.refresh(true)
    } else {
      toast(i18n.t('De gebruiker kon niet opnieuw worden uitgenodigd.'))
    }
    this.setState((state, props) => this.userDoneLoading(state, props, user))
  }

  keyExtractor = (user: User) => `list-item-user-${user.id}`

  renderRefreshControl = () => (
    <RefreshControl
      onRefresh={this.refresh}
      refreshing={this.props.refreshing}
      title={i18n.t('Gebruikers aan het ophalen')}
    />
  )

  renderItem = ({ item: user }: { item: User }) => (
    <ListItemUser
      loading={this.state.loading.includes(user.id)}
      currentUser={this.props.user}
      onEdit={this.onEditUser}
      onRemove={this.onRemoveUser}
      onResendInvite={this.onResendInvite}
      user={user}
    />
  )

  render () {
    return (
      <Screen refreshControl={this.renderRefreshControl()}>
        <Bootstrap.Row>
          <Bootstrap.Column span={{ lg: 6, md: 6, sm: 12, xl: 4, xs: 12 }}>
            <Button
              color={Colors.primary}
              onPress={this.onAddUser}
              style={Styling.marginBottomMedium}
              title={i18n.t('Gebruiker uitnodigen')}
            />
          </Bootstrap.Column>
        </Bootstrap.Row>

        <Bootstrap.Row>
          <Bootstrap.Column span={{ lg: 6, md: 6, sm: 12, xl: 4, xs: 12, xxs: 12 }}>
            <Bootstrap.Collection
              data={this.props.users}
              gutter={15}
              id='manage-users-collection'
              keyExtractor={this.keyExtractor}
              margin={0}
              renderItem={this.renderItem}
            />
          </Bootstrap.Column>
        </Bootstrap.Row>
      </Screen>
    )
  }
}

const mapStateToProps = (state: TState) => ({
  refreshing: !!selectors.isLoading(ENTITIES.USERS)(state),
  relationId: selectors.getCurrentRelationId(state)!,
  user: selectors.getUser(state),
  userId: selectors.getUserId(state),
  users: selectors.getUsers(state)
})

export default connect(mapStateToProps, { apiCallDispatch })(withApiCall(ManageUsers))
