import type { ReducersMapObject } from 'redux'
import { combineReducers } from 'redux'
import { REHYDRATE } from 'redux-persist'
import * as Analytics from '@/utils/firebase/analytics'

import selectors from '@/redux/selectors'
import apiWrapper from '@/utils/api_wrapper'
import i18n from '@/utils/i18n'

import { RESET_STATE } from '@/redux/constants'
import { LOGGED_IN } from '@/redux/constants/user'

import * as agencyReducer from './agency'
import * as cachingReducer from './caching'
import * as claimsReducer from './claims'
import * as companiesReducer from './companies'
import * as filesReducer from './files'
import * as filtersReducer from './filters'
import * as formsReducer from './forms'
import * as historicalClaimsReducer from './historical_claims'
import * as idinReducer from './idin'
import * as loadingReducer from './loading'
import * as modalsReducer from './modals'
import * as notifications from './notifications'
import * as paymentsReducer from './payments'
import * as policiesReducer from './policies'
import * as policyAdviseReducer from './policy_advise'
import * as purchasesReducer from './purchases'
import * as questionRepositoryReducer from './questionRepository'
import * as relationRepositoryReducer from './relationRepository'
import * as relationsReducer from './relations'
import * as supportReducer from './support'
import * as termsReducer from './terms'
import * as themeReducer from './theme'
import * as tipRepositoryReducer from './tipRepository'
import * as translationsReducer from './translations'
import * as userReducer from './user'
import * as usersReducer from './users'
import * as memberBenefitsReducer from './member_benefits'
import * as productsReducer from './products'
import * as flashNewsReducer from './flash_news'

export const initialState = {
  agency: agencyReducer.initialState,
  caching: cachingReducer.initialState,
  claims: claimsReducer.initialState,
  companies: companiesReducer.initialState,
  files: filesReducer.initialState,
  filters: filtersReducer.initialState,
  forms: formsReducer.initialState,
  historicalClaims: historicalClaimsReducer.initialState,
  idin: idinReducer.initialState,
  loading: loadingReducer.initialState,
  memberBenefits: memberBenefitsReducer.initialState,
  modals: modalsReducer.initialState,
  notifications: notifications.initialState,
  payments: paymentsReducer.initialState,
  policies: policiesReducer.initialState,
  policyAdvise: policyAdviseReducer.initialState,
  purchases: purchasesReducer.initialState,
  products: productsReducer.initialState,
  questionRepository: questionRepositoryReducer.initialState,
  relationRepository: relationRepositoryReducer.initialState,
  relations: relationsReducer.initialState,
  support: supportReducer.initialState,
  terms: termsReducer.initialState,
  theme: themeReducer.initialState,
  tipRepository: tipRepositoryReducer.initialState,
  translations: translationsReducer.initialState,
  user: userReducer.initialState,
  users: usersReducer.initialState,
  flashNews: flashNewsReducer.initialState
}

export type State = typeof initialState

export const reducers: Record<keyof State, unknown> = {
  agency: agencyReducer.default,
  caching: cachingReducer.default,
  claims: claimsReducer.default,
  companies: companiesReducer.default,
  files: filesReducer.default,
  filters: filtersReducer.default,
  forms: formsReducer.default,
  historicalClaims: historicalClaimsReducer.default,
  idin: idinReducer.default,
  loading: loadingReducer.default,
  memberBenefits: memberBenefitsReducer.default,
  modals: modalsReducer.default,
  notifications: notifications.default,
  payments: paymentsReducer.default,
  policies: policiesReducer.default,
  policyAdvise: policyAdviseReducer.default,
  purchases: purchasesReducer.default,
  products: productsReducer.default,
  questionRepository: questionRepositoryReducer.default,
  relationRepository: relationRepositoryReducer.default,
  relations: relationsReducer.default,
  support: supportReducer.default,
  terms: termsReducer.default,
  theme: themeReducer.default,
  tipRepository: tipRepositoryReducer.default,
  translations: translationsReducer.default,
  user: userReducer.default,
  users: usersReducer.default,
  flashNews: flashNewsReducer.default
}

const rootReducer = combineReducers(reducers as ReducersMapObject)

export default (state: State = initialState, action: any) => {
  switch (action?.type) {
    case REHYDRATE: {
      if (action.payload) {
        if (action.payload.translations) {
          i18n.setTranslations(action.payload.translations)
        }

        const user = selectors.getUser(action.payload)

        if (user) {
          apiWrapper.setUserParams({
            userId: user.id,
            token: user.token
          })

          Analytics.setUserId(user.id)
        }

        const currentRelation = selectors.getCurrentRelation(action.payload)

        if (currentRelation?.id && currentRelation?.relationNumber) {
          apiWrapper.setUserParams({ relationId: currentRelation.id, relationNumber: currentRelation.relationNumber })
        }
      }

      break
    }

    case RESET_STATE:
      return initialState

    case LOGGED_IN:
      return {
        ...initialState,
        theme: themeReducer.default(state.theme, action),
        user: action.user
      }

    default:
      break
  }

  return rootReducer(state, action)
}
