import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

import ButtonCircle from '@/components/buttons/ButtonCircle'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'

interface TStepperProps {
  availableIndex: number
  index: number
  labels: string[]
  onIndexChange: (index: number) => any
  style?: StyleProp<ViewStyle>
}

export default function Stepper (props: TStepperProps) {
  return (
    <View style={[styles.view, props.style]}>
      {(props.labels ?? []).map((label, index) => {
        const key = `button-circle-${index}-${label}`
        const isAvailable = index <= (props.availableIndex || props.index)

        if (index === props.index) {
          return (
            <ButtonCircle
              color={Colors.primary}
              key={key}
              textType='description'
              title={label}
            >
              <Text
                noLineHeight
                color={Colors.white}
                type='section'
              >
                {index + 1}
              </Text>
            </ButtonCircle>
          )
        }

        if (index > props.index) {
          return (
            <ButtonCircle
              color={isAvailable ? Colors.primary : Colors.white}
              key={key}
              onPress={() => (isAvailable ? props.onIndexChange(index) : null)}
              textColor={isAvailable ? undefined : Colors.b1}
              textType='description'
              title={label}
            >
              <Text
                noLineHeight
                color={isAvailable ? Colors.white : Colors.c1}
                type='section'
              >
                {index + 1}
              </Text>
            </ButtonCircle>
          )
        }

        return (
          <ButtonCircle
            color={Colors.success}
            icon={['far', 'check']}
            key={key}
            onPress={() => (isAvailable ? props.onIndexChange(index) : null)}
            textType='description'
            title={label}
          />
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  }
})
