import React from 'react'

import Line from '@/components/Line'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import type { Policy } from '@/types/objects'
import Card from '@/components/Card'
import i18n from '@/utils/i18n'

import * as Bootstrap from '@/bootstrap'
import navigation from '@/utils/navigation'

const ValueMetersTablet: React.FC<{ policy: Policy }> = ({ policy }) => {
  const handleOpenScreenValueMeter = async () => {
    await navigation.openScreenValueMeter(policy)
  }

  const isHousehold = policy?.productName === 'Inboedel'
  const title = `${isHousehold ? i18n.t('Inboedelwaardemeter') : i18n.t('Herbouwwaardemeter')}\n(${policy?.details?.zip_code?.code}, ${policy.details?.house_number?.code})`
  const icon = isHousehold ? 'couch' : 'house'

  return (
    <>
      <Line
        horizontal
        color={Colors.background}
        height={1}
        style={Styling.marginVerticalMedium}
      />

      <Bootstrap.Row>
        <Bootstrap.Column span={{ lg: 12, md: 12, sm: 12, xl: 12, xs: 12 }}>
          <Card
            title={title}
            icon={icon}
            onPress={handleOpenScreenValueMeter}
            style={{ borderColor: Colors.lightGray, borderWidth: 1 }}
          />
        </Bootstrap.Column>
      </Bootstrap.Row>
    </>
  )
}

export default ValueMetersTablet
