import React from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'

import Icon from '@/components/Icon'
import Text from '@/components/wrappers/Text'
import mapStateToProps from '@/redux/mapStateToProps'
import Sizes from '@/constants/Sizes'
import ListItem from '@/components/list_items/ListItem'
import i18n from '@/utils/i18n'
import type { ThemeColors } from '@/redux/reducers/theme'
import Styling from '@/constants/Styling'

export interface AddPolicyItemProps {
  colors: ThemeColors
  onPress: () => void
}

const AddPolicyPhone: React.FC<AddPolicyItemProps> = ({ onPress, colors }) => (
  <ListItem
    chevron
    item={{}}
    onPress={onPress}
    style={styles.listItemContainer}
  >
    <Icon
      circleColor={colors?.lightBrand}
      icon='plus'
      color={colors?.brand}
    />

    <View style={styles.container}>
      <View>
        <Text type='description'>
          {i18n.t('Verzekering(en) toevoegen')}
        </Text>
      </View>
    </View>
  </ListItem>
)

const styles = StyleSheet.create({
  listItemContainer: {
    ...Styling.marginBottomMediumHalf,
    ...Styling.marginTopMedium
  },
  container: {
    flex: 1,
    paddingLeft: Sizes.spacingHorizontal
  }
})

export default connect(mapStateToProps.themeColors)(AddPolicyPhone)
