import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import Icon from '@/components/Icon'
import TouchableText from '@/components/touchables/TouchableText'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'

interface TLabelProps {
  label?: string
  required?: boolean
  tooltip?: string
  type?: string
  maxLength?: number
  showMaxLength?: boolean
  value?: string
}

export default function Label (props: TLabelProps) {
  const [show, setShow] = useState(false)
  const TextComponent = props.tooltip ? TouchableText : Text

  return (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <TextComponent
          onPress={props.tooltip ? () => setShow(!show) : undefined}
          style={styles.label}
          type={props.type || 'label'}
        >
          {props.required
            ? <Icon
                small
                color={Colors.primary}
                icon='asterisk'
              />
            : null}
          {props.required ? '  ' : null}
          {props.label}
          {props.tooltip ? '  ' : null}
          {props.tooltip
            ? <Icon
                color={Colors.lightBrown}
                icon='info-circle'
              />
            : null}
        </TextComponent>

        {props.maxLength && props.showMaxLength !== false
          ? (
            <Text
              style={{ fontSize: 14 }}
              type={(props.value?.length || 0) >= props.maxLength ? 'labelError' : 'label'}
            >
              {props.maxLength - (props.value?.length || 0)}
            </Text>
            )
          : null}
      </View>

      {show
        ? (
          <TouchableOpacity
            onPress={() => setShow(false)}
            style={styles.box}
          >
            <Icon
              color={Colors.brown}
              icon={['fal', 'times']}
              onPress={() => setShow(false)}
              style={styles.icon}
            />
            <Text type='description'>{props.tooltip}</Text>
          </TouchableOpacity>
          )
        : null}
    </>
  )
}

const styles = StyleSheet.create({
  box: {
    backgroundColor: Colors.lighterBrown,
    borderRadius: Sizes.borderRadius,
    marginBottom: Sizes.spacingVertical,
    paddingLeft: Sizes.spacingHorizontal,
    paddingRight: Sizes.spacingHorizontalMedium,
    paddingVertical: Sizes.spacingVertical
  },
  icon: {
    position: 'absolute',
    right: 15,
    top: 15,
    zIndex: 1
  },
  label: {
    paddingBottom: Sizes.labelMarginVertical
  }
})
