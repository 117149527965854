import React from 'react'
import { useSelector } from 'react-redux'

import Form from '@/components/Form'
import Input from '@/components/inputs/Input'
import InputAmount from '@/components/inputs/InputAmount'
import InputDateTime from '@/components/inputs/InputDateTime'
import ButtonForm from '@/components/buttons/ButtonForm'
import ListUnordered from '@/components/ListUnordered'
import Toast from '@/components/Toast'

import Styling from '@/constants/Styling'

import withAnimFade from '@/hocs/withAnimFade'

import { getThemeColors } from '@/redux/selectors/theme'

import i18n from '@/utils/i18n'

import type { Purchase } from '@/types/objects'

const ListUnorderedAnimFade = withAnimFade(ListUnordered)

export const inputLabels = {
  date: i18n.t('Aankoopdatum'),
  fileInvoice: i18n.t('Factuur'),
  filePicture: i18n.t('Foto van het item in huidige staat'),
  name: i18n.t('Naam'),
  price: i18n.t('Aankoopprijs')
}

interface IProps {
  purchase: Partial<Purchase>
  loading: boolean
  showError: boolean
  invalidInputs: string[]
  onSubmit: () => void
  setPurchase: (property: { [key: string]: string | undefined }) => void
}

const PurchaseForm: React.FC<IProps> = ({ purchase, setPurchase, showError, invalidInputs, loading, onSubmit }) => {
  const colors = useSelector(getThemeColors)

  return (
    <Form>
      <Input
        required
        label={inputLabels.name}
        onChange={(name) => setPurchase({ name })}
        value={purchase?.name}
      />

      <InputAmount
        currency
        required
        label={inputLabels.price}
        onChange={(price) => setPurchase({ price })}
        value={purchase?.price}
      />

      <InputDateTime
        required
        label={inputLabels.date}
        maxDate={new Date()}
        mode='date'
        onChange={(date) => setPurchase({ date })}
        placeholder={i18n.t('Selecteer de aankoopdatum')}
        value={purchase?.date}
      />

      {/* <InputFile
        file={
          purchase?.invoice
            ? {
                base64: purchase.invoice,
                name: purchase.invoiceFilename!
              }
            : undefined
        }
        label={inputLabels.fileInvoice}
        onRemove={() => setPurchase({ invoice: undefined, invoiceFilename: undefined })}
        onSelect={(file) => setPurchase({ invoice: file.base64, invoiceFilename: file.name, invoiceFileMimeType: file.mimeType !== null ? file.mimeType : undefined })}
        title={i18n.t('Upload factuur')}
      />

      <InputFile
        file={
          purchase?.picture
            ? {
                base64: purchase.picture,
                name: purchase.pictureFilename!
              }
            : undefined
        }
        label={inputLabels.filePicture}
        title={i18n.t('Upload item foto')}
        onRemove={() => setPurchase({ picture: undefined, invoiceFilename: undefined })}
        onSelect={(file) => setPurchase({ picture: file.base64, pictureFilename: file.name, pictureFileMimeType: file.mimeType !== null ? file.mimeType : undefined })}
      /> */}

      {showError && invalidInputs.length > 0
        ? (
          <>
            <Toast
              error
              message={i18n.t('De volgende verplichte velden zijn nog niet ingevuld:')}
              style={Styling.marginBottomSmall}
            />
            <ListUnorderedAnimFade
              items={invalidInputs}
              prefix='•'
              style={Styling.marginBottom}
              type='labelError'
            />
          </>
          )
        : null}

      <ButtonForm
        color={colors?.brand}
        disabled={loading}
        loading={loading}
        onPress={onSubmit}
        title={i18n.t('Item opslaan')}
      />
    </Form>
  )
}

export default PurchaseForm
