import Config from 'react-native-config'
import OneSignal from 'react-native-onesignal'
import navigation from './navigation'
import type { Store } from 'redux'
import { setShouldOpenAfterLogin } from '@/redux/actions/notifications'
import { ENTITIES } from '@/redux/constants'
import { apiCall } from '@/redux/actions/api'
import { store } from '@/redux/configureStore'

const notificationsShouldOpenAfterLogin = () => {
  if (process.env.JEST_WORKER_ID === undefined) {
    void import('@/redux/configureStore').then(({ store }: { store: Store }) => {
      store.dispatch(setShouldOpenAfterLogin(true))
    })
  }
}

export function initializeOneSignal () {
  // Only initialize One Signal if you're running in release mode
  if (__DEV__) {
    return
  }

  console.log('initializing onesignal.....')

  // OneSignal Initialization
  OneSignal.setAppId(Config.ONESIGNAL_APP_ID)

  // promptForPushNotificationsWithUserResponse will show the native iOS or Android notification permission prompt.
  OneSignal.promptForPushNotificationsWithUserResponse(true)

  // Method for handling notifications received while app in foreground
  OneSignal.setNotificationWillShowInForegroundHandler(notificationReceivedEvent => {
    console.log('OneSignal: notification will show in foreground:', notificationReceivedEvent)
    const notification = notificationReceivedEvent.getNotification()
    console.log('notification: ', notification)
    const data = notification.additionalData
    console.log('additionalData: ', data)
    // Complete with null means don't show a notification.
    notificationReceivedEvent.complete(notification)

    // Refresh the notifications
    store.dispatch(apiCall('GET', ENTITIES.NOTIFICATIONS, undefined, undefined, true))
  })

  // Method for handling notifications opened
  OneSignal.setNotificationOpenedHandler(notification => {
    console.log('OneSignal: notification opened:', notification)

    notificationsShouldOpenAfterLogin()
    void navigation.openScreenNotifications()

    // Refresh the notifications
    store.dispatch(apiCall('GET', ENTITIES.NOTIFICATIONS, undefined, undefined, true))
  })
}
