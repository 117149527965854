import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import Amount from '@/components/Amount'
import Text from '@/components/wrappers/Text'
import Sizes from '@/constants/Sizes'
import selectors from '@/redux/selectors'
import common from '@/utils/common'

interface IProps {
  meters?: number
  style?: StyleProp<ViewStyle>
}

const CircleDistance: React.FC<IProps> = (props) => {
  const colors = useSelector(selectors.getThemeColors)

  if (!props.meters && props.meters !== 0) {
    return null
  }

  const { distance, unit } = common.determineDistance(props.meters)

  return (
    <View style={[styles.container, { backgroundColor: colors?.lightBrand }, props.style]}>
      <Amount
        color={colors?.brand}
        type='distance'
      >
        {distance}
      </Amount>
      <Text
        color={colors?.brand}
        type='unit'
      >
        {unit}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderRadius: Sizes.circle / 2,
    height: Sizes.circle,
    justifyContent: 'center',
    width: Sizes.circle
  }
})

export default CircleDistance
