import { IS_WEB } from './platform'

export const ALLOWED_FILE_TYPES = [
  'jpg',
  'jpeg',
  'png',
  'txt',
  'pdf',
  'doc',
  'docx',
  'avi',
  'mov',
  'mpg',
  'mpeg',
  'mp4',
  'msg',
  'xls',
  'xlsx',
  'heic'
]

export const MAXIMUM_FILE_SIZE = 10 * 1000 * 1000 // 10 MB;

export const ALLOWED_FILE_MIME_TYPES = IS_WEB
  ? [
      'image/jpeg',
      'image/png',
      'image/heic',
      'text/plain',
      'video/x-msvideo',
      'video/mpeg',
      'video/mp4',
      'application/pdf',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ]
  : undefined
