import { SET_LOADING_CLAIM_ATTACHMENT, EXTENDED_TOKEN, EXTEND_TOKEN } from './../constants/loading'
import { getKeyName } from '@/utils/data'
import type { TApiCallDispatch } from '../actions/api'
import { API_CALL, API_CALL_CANCELLED, API_CALL_FAILED, API_CALL_SUCCESS } from '../constants/api'
import { LOCK, LOGOUT } from '../constants/user'

export interface ILoadingState {
  [index: string]: undefined | TApiCallDispatch['payload']
}

interface IUploadAttachmentAction {
  id: number // question id
  type: string
  loading: boolean
}

export const initialState: ILoadingState = {}

export default (state: ILoadingState = initialState, action: TApiCallDispatch & IUploadAttachmentAction) => {
  switch (action?.type) {
    case API_CALL:
      return {
        ...state,
        [getKeyName(action.payload)]: action.payload
      }
    case API_CALL_SUCCESS:
    case API_CALL_CANCELLED:
    case API_CALL_FAILED: {
      const newState = { ...state }
      delete newState[getKeyName(action.payload)]

      return newState
    }

    // Catch unconnected api calls specifically for now
    case EXTEND_TOKEN:
      return {
        ...state,
        EXTEND_TOKEN: {
          entity: EXTEND_TOKEN,
          method: 'POST'
        }
      }

    case EXTENDED_TOKEN: {
      const newState = { ...state }
      delete newState.EXTEND_TOKEN

      return newState
    }

    case SET_LOADING_CLAIM_ATTACHMENT:
      if (action.loading) {
        return { ...state, CLAIM_ATTACHMENTS: { [action.id]: true } }
      } else {
        const newState = { ...state }
        delete newState.CLAIM_ATTACHMENTS
        return newState
      }

    case LOCK:
    case LOGOUT:
    case 'WIPE':
      return initialState
  }

  return state
}
