
import type { LinkingOptions } from '@react-navigation/native'

// @ts-expect-error
export const linking: LinkingOptions = {
  config: {
    screens: {
      Login: { path: 'home' },

      Setup: { path: 'instellen' },

      Overview: { path: 'overzicht' },

      AgencyStack: {
        path: 'adviseur',
        screens: {
          Agency: { path: '' },
          ServiceGuide: { path: 'dienstverleningsdocument' },
        }
      },

      DamageRepairStack: {
        path: 'schadehersteller',
        screens: {
          DamageRepairVehicle: { path: 'voertuigschade' },
          DamageRepair: { path: '' }
        }
      },

      GreenCardsStack: {
        path: '',
        screens: {
          GreenCard: { path: 'verzekeringsbewijzen/:policyId' },
          GreenCards: { path: 'verzekeringsbewijzen' },
          GenerateGreenCard: { path: 'verzekeringsbewijzen/genereren/:policyId' },
          GeneratedGreenCard: { path: 'verzekeringsbewijzen/gegenereerd/:generatedGreenCard' }
        }
      },

      WhatToDo: { path: 'wat-te-doen-bij/:category' },

      ClaimDamageStack: {
        path: 'schade-melden',
        screens: {
          ClaimDamage: { path: '' },
          ClaimDamageForm: { path: 'schadeformulier' }
        }
      },

      PoliciesStack: {
        path: 'verzekeringen',
        screens: {
          Policies: { path: '' },
          CombiPolicy: 'combipolis',
          GeneralConditions: 'algemene-voorwaarden',
          PolicyStatement: { path: 'polisblad' },
          Policy: { path: 'polis/:policyId' },
          PolicyCondition: { path: 'polis/:policyId/voorwaarden' },
        }
      },

      NotificationsStack: {
        path: 'notificaties',
        screens: {
          Notifications: { path: '' },
          Notification: { path: ':id' }
        }
      },

      CaseStack: {
        path: 'dossier',
        screens: {
          Case: { path: '' },
          ClaimsStack: {
            path: 'mijn-schades',
            screens: {
              Claims: { path: '' },
              Claim: { path: ':claimId' },
              HistoricalClaim: { path: '/historische-schades/:claimNumber' },
            }
          },
          PurchasesStack: {
            path: 'mijn-bezittingen',
            screens: {
              Purchases: { path: '' },
              CreatePurchase: { path: 'toevoegen' },
              Purchase: { path: ':purchaseId' },
              EditPurchase: { path: ':purchaseId/wijzig' }
            }
          },
          ValueMetersStack: {
            path: 'waardemeters',
            screens: {
              ValueMeters: { path: '' },
              RebuildValueMeter: { path: 'herbouwwaardemeter/:policyId' },
              HouseholdEffectsValueMeter: { path: 'inboedelwaardemeter/:policyId' }
            }
          },
          Payments: { path: 'betalingsoverzicht' },
        }
      },

      ProfileStack: {
        path: 'mijn-profiel',
        screens: {
          Profile: { path: '' },
          ManageLogin: { path: 'inloggegevens-wijzigen' },
          ManagePermissions: { path: 'toestemmingen' },
          ManageRelation: { path: 'gegevens-wijzigen' },
          ManageUsersStack: {
            path: 'gebruikers-beheren',
            screens: {
              ManageUsers: '',
              AddUser: 'uitnodigen',
              EditUser: ':userId/wijzigen'
            }
          },
          Settings: { path: 'voorkeuren' },
          SwitchCombiPolicy: { path: 'combipolis-selecteren' },
          AddRelationToCombi: { path: 'combipolis-toevoegen' }
        }
      },

      MemberBenefitsStack: {
        path: 'ledenvoordelen',
        screens: {
          MemberBenefits: { path: '' },
          MemberBenefitsDetail: { path: ':id' }
        }
      },

      MoreStack: {
        path: 'meer',
        screens: {
          More: { path: '' },
          AboutApp: { path: 'over-de-app' },
          AboutNh1816: { path: 'nh1816-verzekeringen' },
          Questions: { path: 'vragen' },
          TermsOfUse: { path: 'gebruiksvoorwaarden' },
          TipsStack: {
            path: 'preventietips',
            screens: {
              TipCategories: { path: '' },
              TipCategory: { path: ':categoryId' }
            }
          }
        }
      }
    }
  },
  prefixes: ['https://*.mijn-polissen.nl', 'https://mijn-polissen.nl']
}
