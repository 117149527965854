import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import Svg, { Circle, Defs, Ellipse, G, Path } from 'react-native-svg'

interface TSuccessProps {
  style?: StyleProp<ViewStyle>
  width?: number
}

const defaultWidth = 228
const defaultHeight = 182
export const ratio = defaultHeight / defaultWidth

export default function SvgComponent (props: TSuccessProps) {
  const width = props.width || defaultWidth
  const height = props.width ? width * ratio : defaultHeight
  return (
    <Svg
      height={height}
      style={props.style}
      viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}
      width={width}
    >
      <Defs />
      <G
        fill='none'
        fillRule='evenodd'
      >
        {/* @ts-expect-error ts-migrate(2322) FIXME: Property 'filter' does not exist on type 'Intrinsi... Remove this comment to see the full error message */}
        <Ellipse
          cx={114}
          cy={161}
          fill='#FAFAFA'
          filter='url(#prefix__a)'
          rx={114}
          ry={21}
        />
        <Circle
          cx={167}
          cy={79}
          fill='#87C104'
          opacity={0.071}
          r={21}
        />
        <Circle
          cx={123}
          cy={40}
          fill='#87C104'
          opacity={0.171}
          r={16}
        />
        <Circle
          cx={64}
          cy={35}
          fill='#87C104'
          opacity={0.371}
          r={6}
        />
        <Circle
          cx={55}
          cy={77}
          fill='#87C104'
          opacity={0.131}
          r={12}
        />
        <Circle
          cx={92}
          cy={8}
          fill='#87C104'
          opacity={0.071}
          r={7}
        />
        <Circle
          cx={175}
          cy={35}
          fill='#87C104'
          opacity={0.171}
          r={5}
        />
        <Circle
          cx={148}
          cy={3}
          fill='#87C104'
          opacity={0.331}
          r={3}
        />
        <Circle
          cx={114}
          cy={101}
          fill='#87C104'
          r={54.5}
          stroke='#FFF'
          strokeWidth={3}
        />
        <Path
          d='M141.39 78.54a1.734 1.734 0 012.452 0l1.635 1.635a1.735 1.735 0 010 2.453l-43.332 43.332a1.734 1.734 0 01-2.453 0l-17.17-17.17a1.735 1.735 0 010-2.452l1.636-1.635a1.735 1.735 0 012.453 0l14.307 14.308L141.39 78.54z'
          fill='#FFF'
          fillRule='nonzero'
        />
      </G>
    </Svg>
  )
}
