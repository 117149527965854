import React from 'react'

import Line from '@/components/Line'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import type { Policy } from '@/types/objects'
import i18n from '@/utils/i18n'

import Conditions from './conditions'

const ConditionsTablet: React.FC<{ policy: Policy }> = ({ policy }) => {
  const multipleConditions = policy?.conditions?.length > 1

  if (!multipleConditions) {
    return null
  }

  return (
    <>
      <Line
        horizontal
        color={Colors.background}
        height={1}
        style={Styling.marginVerticalMedium}
      />

      <Text
        style={Styling.marginBottomNormal}
        type='subtitle'
      >
        {i18n.t('Polisvoorwaarden')}
      </Text>

      <Conditions policy={policy} />
    </>
  )
}

export default ConditionsTablet
