import type { Relation } from '@/types/objects'
import prototype from '@/utils/prototype'
import * as CONSTANTS from '@/redux/constants/relations'

import type { TApiCallDispatch } from '../actions/api'
import { SELECT_RELATION } from '../constants/relationRepository'
import type { SelectRelationAction } from '../types/relations'

export interface IRelationsState {
  relations: Relation[]
  loadingRelations: boolean
  errorRelations: boolean
  forceSwitchRelation: boolean

  loadingRelation: boolean
  errorRelation: boolean

  currentRelation: Relation | null

  addRelationToCombi: {
    loading: boolean
    error: boolean
    success: boolean
  }

  deleteRelation: {
    id: string | null
    loading: boolean
    error: boolean
    success: boolean
  }
}

export const initialState: IRelationsState = {
  relations: [],
  loadingRelations: false,
  errorRelations: false,
  forceSwitchRelation: false,

  loadingRelation: false,
  errorRelation: false,

  currentRelation: null,

  addRelationToCombi: {
    loading: false,
    error: false,
    success: false
  },

  deleteRelation: {
    id: null,
    loading: false,
    error: false,
    success: false
  }
}

type TDispatch = TApiCallDispatch & SelectRelationAction

export default (state = initialState, action: TDispatch): IRelationsState => {
  switch (action?.type) {
    case 'GET_RELATION':
      return {
        ...state,
        loadingRelation: true,
        errorRelation: initialState.errorRelation
      }

    case 'GET_RELATION_SUCCESS': {
      let currentRelation = state.currentRelation
      const relations = prototype.mergeObjectInArray(state.relations, action.payload.data as Relation)

      if (currentRelation) {
        currentRelation = {
          ...state.currentRelation,
          ...relations.find(x => String(x.id) === String(state.currentRelation?.id))!
        }
      }

      return {
        ...state,
        loadingRelation: initialState.loadingRelation,
        errorRelation: initialState.errorRelation,
        relations,
        currentRelation
      }
    }

    case 'GET_RELATION_FAILED':
      return {
        ...state,
        loadingRelation: initialState.loadingRelation,
        errorRelation: true
      }

    case 'GET_RELATION_CANCELLED':
      return {
        ...state,
        loadingRelation: initialState.loadingRelation
      }

    case SELECT_RELATION:
      return {
        ...state,
        currentRelation: action?.relation
      }

    case 'FORCE_SWITCH_RELATION':
      return {
        ...state,
        forceSwitchRelation: true
      }

    case 'SWITCH_RELATION_SUCCESS':
      return {
        ...state,
        forceSwitchRelation: false
      }

    case 'GET_RELATIONS':
      return {
        ...state,
        loadingRelations: true,
        errorRelations: initialState.errorRelations
      }

    case 'GET_RELATIONS_SUCCESS': {
      let currentRelation = state.currentRelation
      const relations = prototype.mergeArrays(state.relations, action.payload.data as Relation[])

      if (currentRelation) {
        currentRelation = {
          ...state.currentRelation,
          ...relations.find(x => String(x.id) === String(state.currentRelation?.id))!
        }
      } else {
        if (relations.length === 1) {
          const [firstRelation] = relations

          currentRelation = {
            ...firstRelation
          }
        }
      }

      return {
        ...state,
        loadingRelations: initialState.loadingRelations,
        errorRelations: initialState.errorRelations,
        relations,
        currentRelation
      }
    }

    case 'GET_RELATIONS_FAILED':
      return {
        ...state,
        loadingRelations: initialState.loadingRelations,
        errorRelations: true
      }

    case 'GET_RELATIONS_CANCELLED':
      return {
        ...state,
        loadingRelations: initialState.loadingRelations
      }

    case 'POST_RELATION':
      return {
        ...state,
        addRelationToCombi: {
          loading: true,
          error: false,
          success: false
        }
      }

    case 'POST_RELATION_SUCCESS':
      return {
        ...state,
        addRelationToCombi: {
          loading: false,
          error: false,
          success: true
        }
      }

    case 'POST_RELATION_FAILED':
      return {
        ...state,
        addRelationToCombi: {
          loading: false,
          error: true,
          success: false
        }
      }

    case 'REMOVE_ADD_RELATION_ERROR':
      return {
        ...state,
        addRelationToCombi: {
          loading: false,
          error: false,
          success: false
        }
      }

    case 'DELETE_RELATION':
      return {
        ...state,
        deleteRelation: {
          id: action.payload.id as string,
          loading: true,
          error: false,
          success: false
        }
      }

    case 'DELETE_RELATION_SUCCESS':
      return {
        ...state,
        relations: prototype.mergeArrays(state.relations, action.payload.data as Relation[]),
        deleteRelation: {
          id: null,
          loading: false,
          error: false,
          success: true
        }
      }

    case 'DELETE_RELATION_FAILED':
      return {
        ...state,
        deleteRelation: {
          ...state.deleteRelation,
          loading: false,
          error: true,
          success: false
        }
      }

    case CONSTANTS.UPDATE_RELATION: {
      if (prototype.isObject(action.relation)) {
        const relationId = String(action.relation.id)
        const index = state.relations.findIndex((relation) => String(relation.id) === relationId)

        if (index > -1) {
          const newRelations = [...state.relations]
          const updatedRelation = {
            ...newRelations[index],
            ...action.relation
          }
          newRelations[index] = updatedRelation

          return {
            ...state,
            relations: newRelations,
            currentRelation: updatedRelation
          }
        }
      }

      return state
    }

    default:
      return state
  }
}
