import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

import Touchable from '@/components/touchables/Touchable'
import Text from '@/components/wrappers/Text'
import Sizes from '@/constants/Sizes'

interface TPillProps {
  borderColor?: string
  color: string
  large?: boolean
  onPress?: (...args: any[]) => any
  style?: StyleProp<ViewStyle>
  textColor?: string
  title: string
}

export default function Pill (props: TPillProps) {
  const style = [
    styles.container,
    { backgroundColor: props.color },
    props.borderColor && { borderColor: props.borderColor, borderWidth: 1 },
    props.style
  ]
  const type = props.large ? 'pillLarge' : 'pill'
  return props.onPress
    ? (
      <Touchable
        onPress={props.onPress}
        style={style}
      >
        <Text
          color={props.textColor}
          type={type}
        >
          {props.title}
        </Text>
      </Touchable>
      )
    : (
      <View style={style}>
        <Text
          color={props.textColor}
          type={type}
        >
          {props.title}
        </Text>
      </View>
      )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: Sizes.pillHeight / 2,
    justifyContent: 'center',
    minHeight: Sizes.pillHeight,
    paddingHorizontal: Sizes.pillHeight / 2,
    paddingVertical: Sizes.pillPaddingVertical
  }
})
