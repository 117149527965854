import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { View } from 'react-native'
import { decode } from 'html-entities'

import Amount from '@/components/Amount'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'

interface TAttributeProps {
  description?: string | number
  noMargin?: boolean
  style?: StyleProp<ViewStyle>
  title?: string
  type?: string
}

export default function Attribute (props: TAttributeProps) {
  let descriptionElement

  switch (props.type) {
    case 'currency':
      descriptionElement = (
        <Amount
          currency
          type='description'
        >{props.description}
        </Amount>
      )
      break
    case 'percentage':
      descriptionElement = <Text type='description'>{props.description}%</Text>
      break
    default:
      descriptionElement = <Text type='description'>{decode(props.description?.toString() ?? '')}</Text>
      break
  }

  return (
    <View style={props.noMargin ? props.style : [props.style, Styling.marginBottom]}>
      <Text type='paragraphLight'>{props.title}</Text>
      {descriptionElement}
    </View>
  )
}
