import type { ActionSheetProps } from '@expo/react-native-action-sheet'
import { connectActionSheet } from '@expo/react-native-action-sheet'
import React from 'react'

import type { TButtonProps } from '@/components/buttons/Button'
import Button from '@/components/buttons/Button'

type TButtonActionSheetProps = ActionSheetProps &
TButtonProps & {
  cancelButtonIndex?: number
  onPress: (...args: any[]) => any
  options: any[]
  showActionSheetWithOptions?: (...args: any[]) => any
}

class ButtonActionSheet extends React.Component<TButtonActionSheetProps> {
  onPress = () => {
    this.props.showActionSheetWithOptions(
      {
        cancelButtonIndex: this.props.cancelButtonIndex,
        options: this.props.options
      },
      this.props.onPress
    )
  }

  render () {
    return (
      // Keep the props destructuring at the beginning to prevent making this component unnecessary complex.
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Button
        {...this.props}
        onPress={this.onPress}
      />
    )
  }
}

export default connectActionSheet(ButtonActionSheet)
