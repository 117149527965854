export interface INews {
  id: number
  title: string
  tag: string
  content: string | null
  link: string
  linkText: string
  active: boolean
  startDate: string
  endDate: string
}

export interface IFlashNewsState {
  news: INews | null
}

export const initialState: IFlashNewsState = {
  news: null
}

export default (state = initialState, action: { type: string, payload: keyof IFlashNewsState }): IFlashNewsState => {
  switch (action?.type) {
    case 'GET_FLASH_NEWS_SUCCESS':
      if (action.payload.data?.flashMessages?.length > 0) {
        return {
          ...state,
          news: action.payload.data.flashMessages[0]
        }
      }
  }

  return state
}
