import * as AllHtmlEntities from 'html-entities'
import { createSelector } from 'reselect'
import type { State as TState } from '@/redux/reducers'

export const allHtmlEntities = AllHtmlEntities

export const getFirstParameter = (_: TState, parameter: any) => parameter
export const getSecondParameter = (_: TState, _2: any, parameter: any) => parameter

export const generateGetByKeySelector = <T>(selector: (...props: any[]) => T[], keyName: string) =>
  createSelector([selector, getFirstParameter], (list, parameter): T | null => {
    const key = parameter && String(parameter)
    return list?.find((item) => String(item[keyName]) === key) ?? null
  })
