import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { View } from 'react-native'

interface TFormProps {
  enableNesting?: boolean
  id?: string
  style?: StyleProp<ViewStyle>
}

const supportedComponents = ['ButtonForm', 'Input']

export default class Form extends React.Component<TFormProps> {
  registeredRefs: any[] = []

  // @todo: Reimplement this in a better way, after the upgrade of React Native this does not work anymore.
  renderChildren (children: any, recursiveIndex = 0): React.ReactNode {
    return React.Children.map(children, (child, index) => {
      if (child) {
        if (this.props.enableNesting && child.props && child.props.children) {
          return React.cloneElement(child, {
            ...child.props,
            children: this.renderChildren(child.props.children, index)
          })
        }
        if (child.type && supportedComponents.includes(child.type.name)) {
          const realIndex = index + recursiveIndex
          const props: { [key: string]: any } = {
            registerRef: (ref: any) => {
              this.registeredRefs[realIndex] = ref
            }
          }
          if (child.type.name === 'Input') {
            props.onSubmitEditing = () =>
              this.registeredRefs[realIndex + 1] && typeof this.registeredRefs[realIndex + 1].focus === 'function'
                ? this.registeredRefs[realIndex + 1].focus()
                : null
          }
          return React.cloneElement(child, props)
        }
      }
      return child
    })
  }

  render () {
    // This check is especially intended for the unit test.
    if (!this.props.children) {
      return null
    }
    if (this.props.style) {
      return <View style={this.props.style}>{this.props.children}</View>
    }
    return this.props.children
  }
}
