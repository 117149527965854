import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { Platform, Switch } from 'react-native'

import Colors from '@/constants/Colors'

const trackColor = Platform.OS === 'web' ? Colors.da : { false: Colors.da, true: Colors.success }

interface TSwitchProps {
  disabled?: boolean
  onValueChange: (value: boolean) => void
  style?: StyleProp<ViewStyle>
  value: boolean
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
export default function _Switch (props: TSwitchProps) {
  return (
    <Switch
      activeThumbColor={Colors.white}
      activeTrackColor={Colors.success}
      disabled={props.disabled}
      ios_backgroundColor={Colors.da}
      onValueChange={props.onValueChange}
      style={props.style}
      thumbColor={Colors.white}
      // @ts-expect-error ts-migrate(2769) FIXME: Type 'string' is not assignable to type '{ false: ... Remove this comment to see the full error message
      trackColor={trackColor}
      value={props.value}
    />
  )
}
