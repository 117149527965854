export const NAVIGATED = 'NAVIGATED'
export const RESET_STATE = 'RESET_STATE'

export const ENTITIES = {
  AGENCY: 'AGENCY',
  AGENCY_PRINTOUT: 'AGENCY_PRINTOUT',

  MEMBER_BENEFIT: 'MEMBER_BENEFIT',
  MEMBER_BENEFITS: 'MEMBER_BENEFITS',

  POLICIES: 'POLICIES',
  POLICY: 'POLICY',
  GENERATE_GREEN_CARD: 'GENERATE_GREEN_CARD',

  RELATION: 'RELATION',
  RELATIONS: 'RELATIONS',

  PAYMENTS: 'PAYMENTS',

  PURCHASE: 'PURCHASE',
  PURCHASES: 'PURCHASES',

  PRODUCT: 'PRODUCT',
  PRODUCTS: 'PRODUCTS',

  REPAIRERS: 'REPAIRERS',
  CAR_WINDOW_REPAIRERS: 'CAR_WINDOW_REPAIRERS',
  CAR_WINDOW_REPAIRER_BRANDS: 'CAR_WINDOW_REPAIRER_BRANDS',

  GETRELATIONUSERTOKEN: 'GETRELATIONUSERTOKEN',

  CLAIM: 'CLAIM',
  CLAIMS: 'CLAIMS',

  NOTIFICATION: 'NOTIFICATION',
  NOTIFICATIONS: 'NOTIFICATIONS',

  HISTORICAL_CLAIMS: 'HISTORICAL_CLAIMS',

  CLAIM_DAMAGE_QUESTIONS: 'CLAIM_DAMAGE_QUESTIONS',

  REBUILD_VALUE_METER: 'REBUILD_VALUE_METER',

  QUESTIONS: 'QUESTIONS',
  TERMS_OF_USE: 'TERMS_OF_USE',

  USER: 'USER',
  USERS: 'USERS',

  ABOUT_NH: 'ABOUT_NH',
  WTD: 'WTD',
  ABOUT_MEMBERSHIP: 'ABOUT_MEMBERSHIP',

  TIPS: 'TIPS',

  TRANSLATIONS: 'TRANSLATIONS',

  FLASH_NEWS: 'FLASH_NEWS'
} as const

export type TEntity = keyof typeof ENTITIES
