import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ButtonForm from '@/components/buttons/ButtonForm'
import Form from '@/components/Form'
import Input from '@/components/inputs/Input'
import CheckBox from '@/components/inputs/InputCheckBox'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import { updateEmail } from '@/redux/actions/relationRepository'
import selectors from '@/redux/selectors'
import common from '@/utils/common'
import i18n from '@/utils/i18n'

const EmailForm: React.FC = () => {
  const dispatch = useDispatch()

  const [newEmail, setNewEmail] = useState<string>('')
  const [newEmailRepeat, setNewEmailRepeat] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isRelationMutation, setIsRelationMutation] = useState<boolean>(false)
  const changeEmailState = useSelector(selectors.getChangeEmailState)

  const submit = () => {
    dispatch(updateEmail(newEmail, newEmailRepeat, password, isRelationMutation))
  }

  useEffect(() => {
    if (changeEmailState?.success) {
      setNewEmail('')
      setNewEmailRepeat('')
      setPassword('')
      setIsRelationMutation(false)
    }
  }, [changeEmailState?.success])

  const emailValid = newEmail === newEmailRepeat && common.validateEmail(newEmail)

  const disabled = changeEmailState?.loading || !emailValid || password === ''

  return (
    <Form style={Styling.paddingMedium}>
      <Input
        autoCapitalize='none'
        icon='envelope'
        label={i18n.t('Nieuw e-mailadres')}
        onChange={setNewEmail}
        value={newEmail}
      />

      <Input
        autoCapitalize='none'
        icon='envelope'
        label={i18n.t('Herhaal nieuw e-mailadres')}
        onChange={setNewEmailRepeat}
        value={newEmailRepeat}
      />

      <Input
        secureTextEntry
        autoCapitalize='none'
        icon='key'
        label={i18n.t('Wachtwoord ter bevestiging')}
        onChange={setPassword}
        value={password}
      />

      <CheckBox
        label={i18n.t('Wijzig mijn e-mailadres ook voor alle communicatie over mijn combipolis.')}
        onChange={setIsRelationMutation}
        style={Styling.marginBottomMedium}
        value={isRelationMutation}
      />

      <ButtonForm
        color={Colors.primary}
        disabled={disabled}
        loading={changeEmailState?.loading}
        onPress={submit}
        title={i18n.t('Opslaan')}
      />
    </Form>
  )
}

export default EmailForm
