import React, { useEffect, useState } from 'react'
import type { ImageProps } from 'react-native'
import { Image } from 'react-native'

type TImageAutoWidthProps = {
  height: number
  source: { uri: string }
} & ImageProps

interface TImageAutoWidthState {
  style: { width?: number, height: number }
}

const ImageAutoWidth: React.FC<TImageAutoWidthProps> = (props) => {
  const [style, setStyle] = useState<TImageAutoWidthState['style']>({ height: props.height })

  useEffect(() => {
    Image.getSize(
      props?.source?.uri ?? props.source,
      (width, height) => {
        setStyle({
          height: style.height,
          width: style.height * (width / height)
        })
      },
      (err) => console.warn(err)
    )
  }, [props.source])

  return (
    <Image
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      source={props.source}
      style={[props.style, style]}
    />
  )
}

export default ImageAutoWidth
