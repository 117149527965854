import React from 'react'
import type { ViewStyle } from 'react-native'
import { StyleSheet } from 'react-native'

import ScrollView from '@/components/wrappers/ScrollView'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'

import Html from './wrappers/HTML'

interface TEmptyStateProps {
  message: string
  noPadding?: boolean
  refreshControl?: React.ReactNode
  HTML?: boolean
}

export default function EmptyState (props: TEmptyStateProps) {
  return (
    <ScrollView
      contentContainerStyle={props.noPadding ? styles.container : styles.containerPadding}
      refreshControl={props.refreshControl}
    >
      {props.HTML
        ? (
          <Html html={props.message} />
          )
        : (
          <Text
            style={Styling.textCenter}
            type='paragraph'
          >
            {props.message}
          </Text>
          )}
    </ScrollView>
  )
}

const containerStyle: ViewStyle = {
  alignItems: 'center',
  backgroundColor: Colors.background,
  flex: 1,
  justifyContent: 'center'
}

const styles = StyleSheet.create({
  container: containerStyle,
  containerPadding: {
    ...containerStyle,
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingVertical: Sizes.screenPaddingVertical
  }
})
