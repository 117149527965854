import { createStackNavigator } from '@react-navigation/stack'
import ClaimDamage from '@/screens/claim_damage/ClaimDamage'
import ClaimDamageForm from '@/screens/claim_damage/ClaimDamageForm'

const ClaimDamageStack = createStackNavigator()
const ClaimDamageStackNavigator = () => (
  <ClaimDamageStack.Navigator screenOptions={{ headerShown: false }}>
    <ClaimDamageStack.Screen
      name='ClaimDamage'
      component={ClaimDamage}
      options={{ title: 'Schade melden' }}
    />
    <ClaimDamageStack.Screen
      name='ClaimDamageForm'
      component={ClaimDamageForm}
      options={{ title: 'Schadeformulier' }}
    />
  </ClaimDamageStack.Navigator>
)

export default ClaimDamageStackNavigator
