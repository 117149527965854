import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import Icon from '@/components/Icon'
import Line from '@/components/Line'
import Touchable from '@/components/touchables/Touchable'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import mapStateToProps from '@/redux/mapStateToProps'
import type { ThemeColors } from '@/redux/reducers/theme'

interface TBlockToggleProps {
  backgroundColor?: boolean
  colors: ThemeColors
  lowerText?: string
  style?: StyleProp<ViewStyle>
  upperText: string
  children?: JSX.Element
}

interface TBlockToggleState {
  open: boolean
}

class BlockToggle extends React.Component<TBlockToggleProps, TBlockToggleState> {
  constructor (props: TBlockToggleProps) {
    super(props)

    this.state = {
      open: false
    }
  }

  toggle = () =>
    this.setState((previousState: TBlockToggleState) => ({
      open: !previousState.open
    }))

  render () {
    const containerStyle = this.props.backgroundColor ? styles.containerBackground : styles.containerWhite
    return (
      <View style={this.props.style ? [containerStyle, this.props.style] : containerStyle}>
        <Touchable
          onPress={this.toggle}
          style={styles.upperContainer}
        >
          <Text
            style={Styling.flex}
            type='label'
          >
            {this.props.upperText}
          </Text>
          <Icon
            circleColor={this.props.backgroundColor ? Colors.white : this.props.colors && this.props.colors.lightBrand}
            color={this.props.backgroundColor ? Colors.gray : this.props.colors && this.props.colors.brand}
            icon={this.state.open ? ['far', 'minus'] : ['far', 'plus']}
            style={styles.icon}
          />
        </Touchable>
        {this.state.open
          ? (
            <Line
              horizontal
              color={this.props.backgroundColor ? Colors.white : Colors.background}
            />
            )
          : null}
        {this.state.open
          ? (
            <View style={styles.lowerContainer}>
              {this.props.children ? this.props.children : <Text type='description'>{this.props.lowerText}</Text>}
            </View>
            )
          : null}
      </View>
    )
  }
}

const contentContainerStyle: StyleProp<ViewStyle> = {
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingHorizontal: Sizes.spacingHorizontal,
  paddingVertical: Sizes.spacingVertical
}

const containerStyle: StyleProp<ViewStyle> = {
  borderRadius: Sizes.borderRadius,
  overflow: 'hidden'
}

const styles = StyleSheet.create({
  containerBackground: {
    ...containerStyle,
    backgroundColor: Colors.background
  },
  containerWhite: {
    ...containerStyle,
    backgroundColor: Colors.white
  },
  icon: {
    alignSelf: 'flex-start',
    marginLeft: Sizes.spacingHorizontal
  },
  lowerContainer: contentContainerStyle,
  upperContainer: {
    ...contentContainerStyle,
    flexDirection: 'row'
  }
})

export default connect(mapStateToProps.themeColors)(BlockToggle)
