import React from 'react'
import { connect } from 'react-redux'

import navigation from '@/utils/navigation'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import Card from '@/components/Card'
import Screen from '@/components/Screen'
import Colors from '@/constants/Colors'

import { apiCall } from '@/redux/actions/api'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import { ENTITIES } from '@/redux/constants'
import type { ThemeColors } from '@/redux/reducers/theme'

interface TTipCategoriesProps {
  categories: any[]
  colors: ThemeColors
  refreshing: boolean
  apiCall: typeof apiCall
}

class TipCategories extends React.Component<TTipCategoriesProps> {
  componentDidMount () {
    this.refresh()
  }

  refresh = () => {
    this.props.apiCall('GET', ENTITIES.TIPS)
  }

  renderContent = (Component: React.ElementType) =>
    (this.props.categories || []).map((category) => (
      <Bootstrap.Column
        key={`categories-button-${category.id}`}
        span={{ lg: 6, md: 6, sm: 12, xl: 4, xs: 12 }}
      >
        <Component
          color={Colors.white}
          icon={category.icon}
          iconCircleColor={this.props.colors?.lightBrand}
          iconColor={this.props.colors?.brand}
          onPress={async () => await navigation.openScreenTipCategory(category)}
          title={category.title}
          titleColor={Colors.gray}
        />
      </Bootstrap.Column>
    ))

  renderPhone = () => this.renderContent(Button)

  renderTablet = () => this.renderContent(Card)

  render () {
    return (
      <Screen
        useRefreshControl
        onRefresh={this.refresh}
        refreshing={this.props.refreshing}
      >
        <Bootstrap.Row
          gutterHorizontal={15}
          gutterVertical={15}
        >
          <Bootstrap.Switch
            renderPhone={this.renderPhone}
            renderTablet={this.renderTablet}
          />
        </Bootstrap.Row>
      </Screen>
    )
  }
}

const mapStateToProps = (state: TState) => ({
  categories: selectors.getTipCategories(state),
  colors: selectors.getThemeColors(state),
  refreshing: !!selectors.isLoading(ENTITIES.TIPS)(state)
})

export default connect(mapStateToProps, { apiCall })(TipCategories)
