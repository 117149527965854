import * as CONSTANTS from '@/redux/constants/files'
import type { FilesActionTypes } from '@/redux/types/files'

interface File {
  filepath: string
  timestamp: number
  url: string
}

export interface FilesState { [key: string]: File }

export const initialState: FilesState = {}

export default (state: FilesState = initialState, action: FilesActionTypes): FilesState => {
  switch (action?.type) {
    case CONSTANTS.ADD_FILE: {
      if (action.url && action.filepath) {
        return {
          ...state,
          [action.url]: {
            filepath: action.filepath,
            timestamp: Date.now(),
            url: action.url
          }
        }
      }

      return state
    }

    case CONSTANTS.UPDATE_FILE: {
      if (action.url && action.filepath && action.url in state) {
        return {
          ...state,
          [action.url]: {
            ...state[action.url],
            filepath: action.filepath,
            timestamp: Date.now()
          }
        }
      }

      return state
    }

    default:
      return state
  }
}
