import hoistNonReactStatics from 'hoist-non-react-statics'
import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { Animated } from 'react-native'

import prototype from '@/utils/prototype'
import { IS_WEB } from '@/constants/platform'

const fadeDuration = 250
const sleepDuration = 250

interface TWithAnimBlinkProps {
  style?: StyleProp<ViewStyle>
}

interface TWithAnimBlinkState {
  animBlink: Animated.Value
}

export default function withAnimBlink<P extends TWithAnimBlinkProps> (Component: React.ComponentType<P>) {
  class WithAnimBlink extends React.Component<P, TWithAnimBlinkState> {
    constructor (props: P) {
      super(props)

      this.state = {
        animBlink: new Animated.Value(0)
      }
    }

    componentDidMount () {
      this.fadeOut()
    }

    fadeOut = () =>
      Animated.timing(this.state.animBlink, {
        duration: fadeDuration,
        toValue: 1,
        useNativeDriver: !IS_WEB
      }).start(async () => {
        await prototype.sleep(sleepDuration)
        this.fadeIn()
      })

    fadeIn = () =>
      Animated.timing(this.state.animBlink, {
        duration: fadeDuration,
        toValue: 0,
        useNativeDriver: !IS_WEB
      }).start(async () => {
        await prototype.sleep(sleepDuration)
        this.fadeOut()
      })

    render () {
      const opacityStyle = { opacity: this.state.animBlink }
      return (
        <Animated.View style={this.props.style ? [opacityStyle, this.props.style] : opacityStyle}>
          <Component
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
          />
        </Animated.View>
      )
    }
  }
  hoistNonReactStatics(WithAnimBlink, Component)
  return WithAnimBlink
}
