import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

import Dot from '@/components/Dot'
import Icon from '@/components/Icon'
import Label from '@/components/Label'
import Touchable from '@/components/touchables/Touchable'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import { useSelector } from 'react-redux'
import selectors from '@/redux/selectors'
import type { ThemeColors } from '@/redux/reducers/theme'

interface TInputRadioTButtonProps {
  id?: string
  items: any[]
  label?: string
  onChange: (...args: any[]) => any
  required?: boolean
  style?: StyleProp<ViewStyle>
  tooltip?: string
  value?: boolean | number | string
}

export default function InputRadioButton (props: TInputRadioTButtonProps) {
  const colors = useSelector(selectors.getThemeColors)

  const styles = MakeStyle(colors)

  return (
    <View style={props.style}>
      <Label
        label={props.label}
        required={props.required}
        tooltip={props.tooltip}
      />
      <View style={Styling.rowWrap}>
        {(props.items || []).map((item, index) => (
          <Touchable
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.id || props.id}-input-radio-touchable-${index}`}
            onPress={() => props.onChange(item.value)}
            style={item.value === props.value ? styles.buttonSelected : styles.button}
          >
            {item.value === props.value
              ? (
                <Dot
                  huge
                  color={Colors.white}
                  style={Styling.centerCenter}
                >
                  <Icon
                    normal
                    color={colors?.brand || Colors.primary}
                    icon='check'
                  />
                </Dot>
                )
              : (
                <Dot
                  huge
                  borderColor={Colors.cc}
                  color={Colors.white}
                />
                )}
            <Text
              color={item.value === props.value ? Colors.white : Colors.gray}
              style={Styling.marginLeft}
              type='button'
            >
              {item.name}
            </Text>
          </Touchable>
        ))}
      </View>
    </View>
  )
}

const buttonStyle: ViewStyle = {
  alignItems: 'center',
  borderRadius: Sizes.borderRadius,
  flexDirection: 'row',
  marginBottom: Sizes.spacingVertical,
  marginRight: Sizes.spacingHorizontal,
  minHeight: Sizes.button,
  minWidth: 150,
  overflow: 'hidden',
  paddingHorizontal: Sizes.buttonPaddingHorizontal,
  paddingVertical: Sizes.buttonPaddingVertical
}

const MakeStyle = (colors: ThemeColors) => {
  return StyleSheet.create({
    button: {
      ...buttonStyle,
      backgroundColor: Colors.white,
      borderColor: Colors.cc,
      borderWidth: 1
    },
    buttonSelected: {
      ...buttonStyle,
      backgroundColor: colors?.brand || Colors.primary
    }
  })
}
