import React from 'react'
import { View, BackHandler, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import date from '@/utils/date'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Button from '@/components/buttons/Button'
import ButtonsPreviousNext from '@/components/buttons/ButtonsPreviousNext'
import ImageAutoHeight from '@/components/images/ImageAutoHeight'
import Input from '@/components/inputs/Input'
import InputCheckBox from '@/components/inputs/InputCheckBox'
import Keypad from '@/components/Keypad'
import ListItemCombiPolicy from '@/components/list_items/ListItemCombiPolicy'
import Pincode from '@/components/Pincode'
import Screen from '@/components/Screen'
import Html from '@/components/wrappers/HTML'
import RefreshControl from '@/components/wrappers/RefreshControl'
import ScrollView from '@/components/wrappers/ScrollView'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Settings from '@/constants/Settings'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import type { TApiCallProps } from '@/hocs/withApiCall'
import withApiCall from '@/hocs/withApiCall'
import type { TSafeAreaInsetsProps } from '@/hocs/withSafeAreaInsets'
import withSafeAreaInsets from '@/hocs/withSafeAreaInsets'
import type { TWindowDimensionsProps } from '@/hocs/withWindowDimensions'
import withWindowDimensions from '@/hocs/withWindowDimensions'
import { wipeCache, apiCall as apiCallDispatch } from '@/redux/actions/api'
import { selectRelation } from '@/redux/actions/relationRepository'
import { switchRelationSuccess } from '@/redux/actions/relations'
import { setPincode, updateUser } from '@/redux/actions/user'
import * as sessionsApi from '@/redux/api/sessions'
import type { State as StateType } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import ProgressBar from '@/screens/setup/components/ProgressBar'
import Cases from '@/svgs/Cases'
import Cogs from '@/svgs/Cogs'
import Handshake from '@/svgs/Handshake'
import Success from '@/svgs/Success'
import type { Relation, User } from '@/types/objects'
import type { TNavigationProp } from '@/types/props'
import data from '@/utils/data'
import i18n from '@/utils/i18n'
import { ENTITIES } from '@/redux/constants'
import { IS_WEB } from '@/constants/platform'

const hideKeyboard = false
const redirectPincode = true
const timeoutDurationSuccess = 300 // Milliseconds
const timeoutDurationError = 750

interface TOwnProps {
  navigation: TNavigationProp
}

type TSetupProps = TOwnProps &
TWindowDimensionsProps &
TApiCallProps &
TSafeAreaInsetsProps & {
  currentRelation?: Relation
  currentRelationId?: Relation['id']
  insets: any
  refreshing: boolean
  relations: Relation[]
  apiCallDispatch: typeof apiCallDispatch
  selectRelation: typeof selectRelation
  switchRelationSuccess: typeof switchRelationSuccess
  setPincode: typeof setPincode
  termsOfUse?: string
  updateUser: typeof updateUser
  wipeCache: typeof wipeCache
  user: User
  userId: User['id']
}

interface TSetupState {
  firstName: User['firstName']
  index: number
  pincode: User['pincode']
  pincodeRepeat: User['pincode']
  relation?: Relation
  termsRead: User['termsRead']
}

class Setup extends React.Component<TSetupProps, TSetupState> {
  backHandler: any

  currentRelationId: Relation['id']

  firstName: User['firstName']

  pincode: User['pincode']

  termsRead: User['termsRead']

  constructor (props: TSetupProps) {
    super(props)

    const user = props.user || {}

    this.firstName = user.firstName || ''
    this.termsRead = user.termsRead || false
    this.pincode = user.pincode || ''
    this.currentRelationId = props.currentRelationId ?? ''

    this.state = {
      firstName: this.firstName,
      index: 0,
      pincode: this.pincode,
      pincodeRepeat: this.pincode,
      relation: undefined,
      termsRead: this.termsRead
    }
  }

  componentDidMount () {
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress)

    this.refresh()

    const hasMissingInfo =
      !this.firstName ||
      !this.termsRead ||
      (!IS_WEB && !this.pincode)

    if (!hasMissingInfo && !this.currentRelationId) {
      this.onFinish(false)
    }
  }

  componentWillUnmount () {
    this.backHandler.remove()
  }

  determineSteps = () => {
    return [
      !IS_WEB && this.renderBegin,
      !this.firstName && this.renderName,
      !this.termsRead && this.renderTerms,
      !this.pincode && !IS_WEB && this.renderPincode,
      !this.pincode && !IS_WEB && this.renderPincodeRepeat,
      !this.currentRelationId && this.renderRelations,
      this.renderEnd
    ].filter(Boolean)
  }

  determineAmountSteps = (): number => this.determineSteps()?.length || 0

  refresh = (): void => {
    this.props.apiCallDispatch('GET', 'RELATIONS', undefined, undefined, true)
    this.props.apiCallDispatch('GET', 'TERMS_OF_USE', undefined, undefined, true)
  }

  handleBackPress = (): boolean => {
    if (this.state.index > 0) {
      this.onPreviousStep()
      return true
    }

    return false
  }

  onChangeFirstName = (firstName: User['firstName']): void => this.setState({ firstName })

  onChangeAgreed = (termsRead: User['termsRead']): void => this.setState({ termsRead })

  onChangePincode = (pincode: string): void => {
    if (pincode.length > Settings.pinCodeLength) {
      return
    }

    this.setState({ pincode })

    if (redirectPincode && pincode.length === Settings.pinCodeLength) {
      setTimeout(this.onNextStep, timeoutDurationSuccess)
    }
  }

  resetPincode = (): void => this.onChangePincode('')

  onChangePincodeRepeat = (pincodeRepeat: any): void => {
    if (pincodeRepeat.length > Settings.pinCodeLength) {
      return
    }

    this.setState({ pincodeRepeat })

    if (pincodeRepeat.length === Settings.pinCodeLength) {
      if (pincodeRepeat !== this.state.pincode) {
        setTimeout(() => {
          this.setState({ pincodeRepeat: '' })
        }, timeoutDurationError)
      } else if (redirectPincode) {
        setTimeout(this.onNextPincodeRepeat, timeoutDurationSuccess)
      }
    }
  }

  resetPincodeRepeat = (): void => this.onChangePincodeRepeat('')

  onChangeRelation = (relation: Relation): void => {
    this.setState({ relation })
    this.onNextStep()
  }

  onFinish = (shouldUpdate: boolean = true) => {
    this.props.switchRelationSuccess()
    if (shouldUpdate) {
      this.props.apiCall(sessionsApi.updateOnboarding, this.props.userId, this.state.firstName, this.state.termsRead)
      this.props.updateUser({ firstName: this.state.firstName, termsRead: this.state.termsRead })
    }

    const relation = this.props.currentRelation ?? this.state.relation ?? this.props.relations?.[0]

    if (relation) {
      this.props.selectRelation(relation)
    } else {
      this.props.apiCallDispatch('GET', 'RELATIONS', undefined, undefined, true)
    }
  }

  onPreviousStep = (): void =>
    this.setState((previousState) => ({
      index: previousState.index - 1
    }))

  onPreviousPincode = (): void => {
    this.onPreviousStep()
    this.setState({ pincode: '' })
  }

  onPreviousPincodeRepeat = (): void => {
    this.onPreviousStep()
    this.setState({ pincode: '', pincodeRepeat: '' })
  }

  onPreviousQuestions = (): void => {
    this.onPreviousStep()
    this.onPreviousStep()
    this.setState({ pincode: '', pincodeRepeat: '' })
  }

  onNextStep = (): void =>
    this.setState((previousState) => ({
      index: previousState.index + 1
    }))

  onNextPincodeRepeat = (): void => {
    this.props.setPincode(this.state.pincode)
    this.onNextStep()
  }

  getPaddingStyleFromInsets = (insets: any, horizontalAddition = 0, verticalAddition = 0) => ({
    paddingBottom: (insets?.bottom || 0) + verticalAddition,
    paddingLeft: (insets?.left || 0) + horizontalAddition,
    paddingRight: (insets?.right || 0) + horizontalAddition,
    paddingTop: (insets?.top || 0) + verticalAddition
  })

  renderBegin = () => {
    return (
      <ScrollView
        contentContainerStyle={styles.wrapper}
        style={Styling.flex}
      >
        <Bootstrap.Column
          span={{ lg: 6, md: 9, sm: 12, xl: 6, xs: 12 }}
          style={styles.column}
        >
          <Cogs style={styles.svg} />
          <Text
            style={styles.text}
            type='title'
          >
            {IS_WEB ? i18n.t('Mijn Omgeving instellen') : i18n.t('App instellen')}
          </Text>
          <Text
            style={styles.text}
            type='description'
          >
            {i18n.t(
              'Voordat de app in gebruik kan worden genomen, doorloopt u een paar eenvoudige stappen om de app voor u te optimaliseren.'
            )}
          </Text>
          <Button
            color={Colors.primary}
            id='button-next-step'
            onPress={this.onNextStep}
            title={i18n.t('Begin met instellen')}
          />
        </Bootstrap.Column>
      </ScrollView>
    )
  }

  renderName = () => {
    const disabled = !this.state.firstName

    return (
      <ScrollView
        contentContainerStyle={styles.wrapper}
        style={Styling.flex}
      >
        <Bootstrap.Column
          span={{ lg: 6, md: 9, sm: 12, xl: 6, xs: 12 }}
          style={styles.column}
        >
          <Handshake style={styles.svg} />
          <Text
            style={styles.text}
            type='title'
          >
            {i18n.t('Uw voornaam')}
          </Text>
          <Text
            style={styles.text}
            type='description'
          >
            {i18n.t('Laten we even kennismaken.')}
          </Text>
          <Input
            icon='user'
            onChange={this.onChangeFirstName}
            placeholder={i18n.t('Uw voornaam')}
            value={this.state.firstName}
          />
          <Button
            color={Colors.primary}
            disabled={disabled}
            id='button-next-step'
            onPress={this.onNextStep}
            title={i18n.t('Voornaam bevestigen')}
          />
        </Bootstrap.Column>
      </ScrollView>
    )
  }

  renderRefreshControl = () => (
    <RefreshControl
      onRefresh={this.refresh}
      refreshing={this.props.refreshing}
      title={i18n.t('De gebruiksvoorwaarden aan het ophalen')}
    />
  )

  renderTerms = () => (
    <Bootstrap.Column
      span={{ lg: 12, md: 12, sm: 12, xl: 9, xs: 12 }}
      style={styles.column}
    >
      <ScrollView
        persistentScrollbar
        contentContainerStyle={styles.wrapper}
        refreshControl={this.renderRefreshControl()}
        style={Styling.flex}
      >
        <Text
          style={Styling.marginBottomMedium}
          type='subtitle'
        >
          {i18n.t('Gebruiksvoorwaarden')}
        </Text>

        {this.props.termsOfUse ? <Html html={this.props.termsOfUse} /> : null}
      </ScrollView>

      <View style={styles.termsButtonContainer}>
        <InputCheckBox
          label={i18n.t('Ik ga akkoord met de gebruiksvoorwaarden')}
          onChange={this.onChangeAgreed}
          value={this.state.termsRead}
          style={Styling.marginTopSmall}
        />
        <ButtonsPreviousNext
          nextDisabled={!this.state.termsRead}
          nextStyle={Styling.marginTopMediumHalf}
          onNext={this.onNextStep}
          onPrevious={this.onPreviousStep}
          previousStyle={Styling.marginTopMediumHalf}
        />
      </View>
    </Bootstrap.Column>
  )

  renderPincode = () => {
    const done = this.state.pincode.length >= Settings.pinCodeLength
    return (
      <Bootstrap.Column
        span={{ lg: 6, md: 9, sm: 12, xl: 6, xs: 12 }}
        style={styles.column}
      >
        <ScrollView contentContainerStyle={styles.wrapper}>
          <Text
            style={styles.text}
            type='subtitle'
          >
            {i18n.t('Pincode instellen')}
          </Text>
          {this.props.windowHeight > 750
            ? (
              <Text
                style={hideKeyboard ? Styling.textCenter : styles.text}
                type='description'
              >
                {i18n.t('Voor snel en gemakkelijk inloggen')}
              </Text>
              )
            : null}
          <Pincode
            focus
            done={done}
            pincode={this.state.pincode}
            size={Settings.pinCodeLength}
            style={hideKeyboard ? Styling.autoVertical : null}
          />
          <ButtonsPreviousNext
            nextDisabled={!done}
            nextStyle={hideKeyboard ? null : Styling.marginTopMedium}
            onNext={this.onNextStep}
            onPrevious={this.onPreviousPincode}
            previousStyle={hideKeyboard ? null : Styling.marginTopMedium}
            style={hideKeyboard ? null : Styling.autoTop}
          />
        </ScrollView>
        <Keypad
          disabled={done}
          hideKeyboard={hideKeyboard}
          key='setup-keypad-1'
          onChange={this.onChangePincode}
          onCustomKey={this.resetPincode}
          style={hideKeyboard ? null : Styling.autoTop}
          value={this.state.pincode}
        />
      </Bootstrap.Column>
    )
  }

  renderPincodeRepeat = () => {
    const disabled = this.state.pincodeRepeat.length >= Settings.pinCodeLength
    const success = this.state.pincodeRepeat === this.state.pincode
    const error = disabled && !this.state.pincode.startsWith(this.state.pincodeRepeat)
    return (
      <Bootstrap.Column
        span={{ lg: 6, md: 9, sm: 12, xl: 6, xs: 12 }}
        style={styles.column}
      >
        <ScrollView contentContainerStyle={styles.wrapper}>
          <Text
            style={styles.text}
            type='subtitle'
          >
            {i18n.t('Pincode bevestigen')}
          </Text>
          {this.props.windowHeight > 750
            ? (
              <Text
                style={hideKeyboard ? Styling.textCenter : styles.text}
                type='description'
              >
                {success && i18n.t('De pincode is opgeslagen!')}
                {error && i18n.t('Dat is niet dezelfde pincode!')}
                {!success && !error && i18n.t('Nog één keer voor de zekerheid')}
              </Text>
              )
            : null}
          <Pincode
            focus
            error={error}
            pincode={this.state.pincodeRepeat}
            size={Settings.pinCodeLength}
            style={hideKeyboard ? Styling.autoVertical : null}
            success={success}
          />
          <ButtonsPreviousNext
            nextDisabled={!success}
            nextStyle={hideKeyboard ? null : Styling.marginTopMedium}
            onNext={this.onNextPincodeRepeat}
            onPrevious={this.onPreviousPincodeRepeat}
            previousStyle={hideKeyboard ? null : Styling.marginTopMedium}
            style={hideKeyboard ? null : Styling.autoTop}
          />
        </ScrollView>
        <Keypad
          disabled={disabled}
          hideKeyboard={hideKeyboard}
          key='setup-keypad-2'
          onChange={this.onChangePincodeRepeat}
          onCustomKey={this.resetPincodeRepeat}
          style={hideKeyboard ? null : Styling.autoTop}
          value={this.state.pincodeRepeat}
        />
      </Bootstrap.Column>
    )
  }

  renderRelations = () => (
    <ScrollView
      contentContainerStyle={styles.wrapper}
      style={Styling.flex}
    >
      <Bootstrap.Column
        span={{ lg: 9, md: 10, sm: 12, xl: 8, xs: 12 }}
        style={styles.column}
      >
        <Cases style={styles.svg} />
        <Text
          style={styles.text}
          type='title'
        >
          {i18n.t('Welke combipolis?')}
        </Text>
        <Text
          style={styles.text}
          type='description'
        >
          {i18n.t('$screen.setup.select-combi-policy.description')}
        </Text>
        {(this.props.relations || []).map((relation: Relation) => (
          <ListItemCombiPolicy
            key={`list-item-combi-policy-${relation.id}`}
            onPressSelect={this.onChangeRelation}
            relation={relation}
          />
        ))}
      </Bootstrap.Column>
    </ScrollView>
  )

  renderEnd = () => {
    return (
      <ScrollView
        contentContainerStyle={styles.wrapper}
        style={Styling.flex}
      >
        <Bootstrap.Column
          span={{ lg: 6, md: 9, sm: 12, xl: 6, xs: 12 }}
          style={styles.column}
        >
          <Success style={styles.svg} />
          <Text
            style={styles.text}
            type='title'
          >
            {i18n.t('Succesvol ingesteld!')}
          </Text>
          <Text
            style={styles.text}
            type='description'
          >
            Super! De belangrijkste stappen zijn nu doorlopen, u kunt nu gebruik maken van de {data.productName}.
          </Text>
          <Button
            color={Colors.primary}
            onPress={this.onFinish}
            title={i18n.t('Start de app')}
          />
        </Bootstrap.Column>
      </ScrollView>
    )
  }

  renderItem = () => {
    const steps = this.determineSteps()
    if (steps[this.state.index]) {
      // @ts-expect-error ts-migrate(2349) FIXME: Type 'false' has no call signatures.
      return steps[this.state.index]()
    }
    return null
  }

  renderContent = () => {
    const amountOfSteps = this.determineAmountSteps()
    const progress = this.state.index / (amountOfSteps - 1)
    return (
      <>
        <ProgressBar
          progress={progress}
          style={styles.progressBar}
        />
        {this.renderItem()}
      </>
    )
  }

  renderPhone = () => (
    <Screen
      noBreadCrumbs
      noPadding
      noScrollView
      white
      style={this.getPaddingStyleFromInsets(this.props.insets)}
    >
      {this.renderContent()}
    </Screen>
  )

  renderTablet = () => (
    <Screen
      noBreadCrumbs
      noPadding
      noScrollView
      style={this.getPaddingStyleFromInsets(this.props.insets)}
    >
      <Bootstrap.Container style={Styling.flex}>
        <Block
          noPadding
          style={styles.block}
        >
          {this.props.user?.logo
            ? (
              <ImageAutoHeight
                style={styles.image}
                uri={`${this.props.user.logo}?${date.now()}`}
                width={150}
              />
              )
            : null}

          {this.renderContent()}
        </Block>
      </Bootstrap.Container>
    </Screen>
  )

  render () {
    return (
      <Bootstrap.Switch
        renderPhone={this.renderPhone}
        renderTablet={this.renderTablet}
      />
    )
  }
}

const styles = StyleSheet.create({
  block: {
    marginVertical: Sizes.screenPaddingVertical
  },
  column: {
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  image: {
    alignSelf: 'center',
    marginHorizontal: Sizes.screenPaddingHorizontal,
    marginTop: Sizes.screenPaddingVertical
  },
  progressBar: {
    marginHorizontal: Sizes.screenPaddingHorizontal,
    marginVertical: Sizes.screenPaddingVertical
  },
  svg: {
    alignSelf: 'center',
    marginBottom: Sizes.spacingVerticalHuge,
    marginTop: Sizes.spacingVerticalMedium
  },
  text: {
    marginBottom: Sizes.spacingVerticalMedium,
    textAlign: 'center'
  },
  wrapper: {
    flexGrow: 1,
    paddingBottom: Sizes.screenPaddingVertical,
    paddingHorizontal: Sizes.screenPaddingHorizontal
  },
  termsButtonContainer: {
    backgroundColor: Colors.white,

    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: -1
    },
    shadowOpacity: 0.05,
    shadowRadius: 0.5,

    elevation: 3,

    paddingHorizontal: Sizes.screenPaddingHorizontal
  }
})

const mapStateToProps = (state: StateType, ownProps: TOwnProps) => {
  const loadingTerms = !!selectors.isLoading(ENTITIES.TERMS_OF_USE)(state)
  const loadingRelations = !!selectors.isLoading(ENTITIES.RELATIONS)(state)

  return {
    currentRelation: selectors.getCurrentRelation(state),
    currentRelationId: selectors.getCurrentRelationId(state),
    navigation: ownProps.navigation,
    refreshing: loadingTerms || loadingRelations,
    relations: selectors.getRelations(state),
    termsOfUse: selectors.getTermsOfUse(state),
    user: selectors.getUser(state),
    userId: selectors.getUserId(state)
  }
}

const mapDispatchToProps = {
  apiCallDispatch,
  selectRelation,
  setPincode,
  updateUser,
  wipeCache,
  switchRelationSuccess
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWindowDimensions(withApiCall(withSafeAreaInsets(Setup))))
