import React from 'react'
import { StyleSheet } from 'react-native'
import type { Moment } from 'moment'
import moment from 'moment'
import DateTimePicker from '@react-native-community/datetimepicker'

import { IS_ANDROID, IS_IOS, IS_WEB } from '@/constants/platform'

import CalendarPicker from '@/components/CalendarPicker'
import type { TInputProps } from '@/components/inputs/Input'
import Input from '@/components/inputs/Input'
import ModalPicker from '@/components/ModalPicker'
import Modal from '@/components/wrappers/Modal'
import Colors from '@/constants/Colors'

const DATE_FORMAT_PROCESS = 'YYYY-MM-DD'
const DATE_FORMAT_SHOW = 'DD-MM-YYYY'
const TIME_FORMAT = 'HH:mm'

type TInputDateTimeProps = TInputProps & {
  disabled?: boolean
  mode: 'date' | 'time'
  onChange: (value: string) => void
  value?: string
  minDate?: Date
  maxDate?: Date
}

interface TInputDateTimeState {
  modalVisible: boolean
}

export default class InputDateTime extends React.Component<TInputDateTimeProps, TInputDateTimeState> {
  value = ''

  state = {
    modalVisible: false
  }

  hideModal = () => {
    this.setState({ modalVisible: false }, () => { this.value = '' })
  }

  showModal = () => {
    if (!this.props.disabled) {
      this.setState({ modalVisible: true }, () => { this.value = '' })
    }
  }

  onChange = (value: Moment) =>
    this.props.onChange(value.format(this.props.mode === 'date' ? DATE_FORMAT_PROCESS : TIME_FORMAT))

  onChangePicker = (event: any) => {
    if (event) {
      if (event.nativeEvent?.timestamp) {
        this.value = event.nativeEvent.timestamp
      }

      if (IS_ANDROID) {
        this.hideModal()
        if (event?.type === 'set' && event?.nativeEvent?.timestamp) {
          this.onChange(moment(event.nativeEvent.timestamp))
        }
      }
    }
  }

  onChangeInput = (value: string) => {
    if (this.props.mode === 'date') {
      const newValue = (value || '').replace(/[^0-9|-]/g, '')
      const oldValue = (this.props.value || '').replace(/[^0-9|-]/g, '')
      if (newValue.length <= DATE_FORMAT_SHOW.length) {
        if (newValue.length > oldValue.length) {
          const convertedNewValue = newValue.replace(/[-]/g, '')
          let changedValue = ''
          if (convertedNewValue.length >= 4) {
            changedValue = `${convertedNewValue.substring(0, 2)}-${convertedNewValue.substring(
              2,
              4
            )}-${convertedNewValue.substring(4)}`
          } else if (convertedNewValue.length >= 2) {
            changedValue = `${convertedNewValue.substring(0, 2)}-${convertedNewValue.substring(2)}`
          } else {
            changedValue = convertedNewValue
          }
          this.props.onChange(changedValue)
        } else {
          this.props.onChange(newValue)
        }
      }
    } else {
      const newValue = (value || '').replace(/[^0-9|:]/g, '')
      this.props.onChange(newValue)
    }
  }

  onDateChange = (date: Date) => {
    this.onChange(moment(date))
    this.hideModal()
  }

  onSubmit = () => {
    this.onChange(moment(this.value || this.props.maxDate || Date.now()))
    this.hideModal()
  }

  renderPicker () {
    if (this.state.modalVisible && !this.props.disabled) {
      const date = this.props.value ? new Date(this.props.value) : new Date()

      return (
        <DateTimePicker
          display={IS_IOS ? 'spinner' : 'default'}
          locale='nl-NL'
          minimumDate={this.props.minDate}
          maximumDate={this.props.maxDate}
          mode={this.props.mode}
          onChange={this.onChangePicker}
          style={styles.dateTimePicker}
          value={date}
        />
      )
    }
    return null
  }

  render () {
    if (IS_ANDROID || IS_IOS || (IS_WEB && this.props.mode === 'date')) {
      let value = this.props.value
      if (this.props.value) {
        value =
          this.props.mode === 'date'
            ? moment(this.props.value).format(DATE_FORMAT_SHOW)
            : moment(this.props.value, TIME_FORMAT).format(TIME_FORMAT)
      }
      return (
        <>
          <Input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            editable={false}
            icon={this.props.mode === 'date' ? 'calendar' : 'clock'}
            onPress={this.showModal}
            placeholder={this.props.placeholder ?? (this.props.mode === 'date' ? DATE_FORMAT_SHOW : TIME_FORMAT)}
            value={value}
          />

          {IS_ANDROID
            ? this.renderPicker()
            : null}

          {IS_IOS
            ? (
              <ModalPicker
                onHide={this.hideModal}
                onSubmit={this.onSubmit}
                visible={this.state.modalVisible}
              >
                {this.renderPicker()}
              </ModalPicker>
              )
            : null}

          {IS_WEB
            ? (
              <Modal
                showClose
                onHide={this.hideModal}
                visible={this.state.modalVisible}
              >
                <CalendarPicker
                  date={this.props.value ?? this.props.maxDate}
                  onDateChange={this.onDateChange}
                  minDate={this.props.minDate}
                  maxDate={this.props.maxDate}
                />
              </Modal>
              )
            : null}
        </>
      )
    }

    let value = this.props.value || ''
    let error
    let success
    let warning

    if (this.props.mode === 'date') {
      const validProcess = moment(value, DATE_FORMAT_PROCESS, true).isValid()
      if (validProcess) {
        value = moment(value, DATE_FORMAT_PROCESS).format(DATE_FORMAT_SHOW)
      }
      const validShow = moment(value, DATE_FORMAT_SHOW, true).isValid()
      if (value.length >= DATE_FORMAT_SHOW.length) {
        error = !validShow
      } else {
        warning = !validShow
      }
      success = validShow
    } else {
      const valid = moment(value, TIME_FORMAT, true).isValid()
      if (value.length >= TIME_FORMAT.length) {
        error = !valid
      } else {
        warning = !valid
      }
      success = valid
    }

    return (
      <Input
        error={error}
        icon={this.props.mode === 'date' ? 'calendar' : 'clock'}
        success={success}
        warning={warning}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        onChange={this.onChangeInput}
        placeholder={this.props.placeholder ?? (this.props.mode === 'date' ? DATE_FORMAT_SHOW : TIME_FORMAT)}
        value={value}
      />
    )
  }
}

const styles = StyleSheet.create({
  // This style is needed to make it possible that the native picker seems white on dark mode.
  dateTimePicker: {
    shadowColor: Colors.white,
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 1,
    shadowRadius: 0
  }
})
