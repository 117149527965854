import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Screen from '@/components/Screen'
import Html from '@/components/wrappers/HTML'

import { apiCall } from '@/redux/actions/api'
import selectors from '@/redux/selectors'
import { ENTITIES } from '@/redux/constants'

const AboutNh1816: React.FC = () => {
  const dispatch = useDispatch()

  const aboutNh = useSelector(selectors.getAboutNh)
  const refreshing = !!useSelector(selectors.isLoading('ABOUT_NH'))

  const onRefresh = () => {
    dispatch(apiCall('GET', ENTITIES.ABOUT_NH))
  }

  useEffect(() => {
    onRefresh()
  }, [])

  return (
    <Bootstrap.Switch
      renderPhone={() => (
        <Screen
          white
          useRefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
        >
          <Html html={aboutNh} />
        </Screen>
      )}
      renderTablet={() => (
        <Screen
          useRefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
        >
          <Block noFlex>
            <Html html={aboutNh} />
          </Block>
        </Screen>
      )}
    />
  )
}

export default AboutNh1816
