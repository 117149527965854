import hoistNonReactStatics from 'hoist-non-react-statics'
import React from 'react'
import { Dimensions } from 'react-native'

export interface TWindowDimensionsProps {
  windowHeight: number
  windowWidth: number
}

interface TWithWindowDimensionsState {
  height: number
  width: number
}

export default function withWindowDimensions<P extends object> (
  Component: React.ComponentType<P & TWindowDimensionsProps>
) {
  class WithWindowDimensions extends React.Component<P, TWithWindowDimensionsState> {
    constructor (props: P) {
      super(props)

      const windowDimensions = Dimensions.get('window')

      this.state = {
        height: windowDimensions.height,
        width: windowDimensions.width,
        changeListener: null
      }
    }

    componentDidMount () {
      this.setState({
        changeListener: Dimensions.addEventListener('change', this.handleDimensionsChanged)
      })
    }

    componentWillUnmount () {
      this.state.changeListener?.remove?.()
    }

    handleDimensionsChanged = (e: any) => {
      if (e && e.window && e.window.height && e.window.width) {
        this.setState({
          height: e.window.height,
          width: e.window.width
        })
      }
    }

    render () {
      return (
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(this.props as P)}
          windowHeight={this.state.height}
          windowWidth={this.state.width}
        />
      )
    }
  }
  hoistNonReactStatics(WithWindowDimensions, Component)
  return WithWindowDimensions
}
