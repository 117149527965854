import type { Purchase } from '@/types/objects'
import prototype from '@/utils/prototype'
import type { TApiCallDispatch } from '../actions/api'

export interface IPurchaseState {
  data: Purchase[]
}

export const initialState: IPurchaseState = {
  data: []
}

export default (state = initialState, action: TApiCallDispatch): IPurchaseState => {
  switch (action?.type) {
    case 'GET_PURCHASE_SUCCESS':
      return {
        data: prototype.mergeObjectInArray(state.data, action.payload.data as Purchase)
      }

    case 'GET_PURCHASES_SUCCESS':
      return {
        data: prototype.mergeArrays(state.data, action.payload.data as Purchase[])
      }

    // API response does not include updated state
    // but it does include id that should be removed
    case 'DELETE_PURCHASE_SUCCESS':
      return {
        ...state,
        data: state.data.filter((purchase) => String(purchase.id) !== String(action.payload.id))
      }

    case 'SELECT_RELATION':
      return initialState

    default:
      return state
  }
}
