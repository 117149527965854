import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import type { OpeningHour } from '@/types/objects'
import common from '@/utils/common'
import i18n from '@/utils/i18n'
import prototype from '@/utils/prototype'

const INTERVAL_DURATION = 60000 // Milliseconds

const DEFAULT_OPENING_HOURS = [
  {
    day: i18n.t('Maandag'),
    dayNumber: 1
  },
  {
    day: i18n.t('Dinsdag'),
    dayNumber: 2
  },
  {
    day: i18n.t('Woensdag'),
    dayNumber: 3
  },
  {
    day: i18n.t('Donderdag'),
    dayNumber: 4
  },
  {
    day: i18n.t('Vrijdag'),
    dayNumber: 5
  },
  {
    day: i18n.t('Zaterdag'),
    dayNumber: 6
  },
  {
    day: i18n.t('Zondag'),
    dayNumber: 7
  }
]

interface TOpeningHoursProps {
  currentOpeningHour: any
  openingCondition?: number
  openingHours: OpeningHour[]
}

export default function OpeningHours (props: TOpeningHoursProps) {
  const [useInterval, setUseInterval] = useState(false)
  const [openingCondition, setOpeningCondition] = useState(null)

  const openingConditionUsed = useInterval ? openingCondition : props.openingCondition

  useEffect(() => {
    // If the openingCondition is determined by the parent it's not needed to keep track of it inside this component.
    if (prototype.isNumber(props.openingCondition)) {
      return undefined
    }

    setUseInterval(true)

    // @ts-expect-error ts-migrate(2345) FIXME: Type '1' is not assignable to type 'SetStateAction... Remove this comment to see the full error message
    setOpeningCondition(common.determineOpeningCondition(props.currentOpeningHour))

    const interval = setInterval(() => {
      // @ts-expect-error ts-migrate(2345) FIXME: Type '1' is not assignable to type 'SetStateAction... Remove this comment to see the full error message
      setOpeningCondition(common.determineOpeningCondition(props.currentOpeningHour))
    }, INTERVAL_DURATION)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      {DEFAULT_OPENING_HOURS.map((defaultOpeningHour, index) => {
        const openingHour = (props.openingHours || []).find(
          (oh) => String(oh.dayNumber) === String(defaultOpeningHour.dayNumber)
        )
        const day = (openingHour && openingHour.day) || (defaultOpeningHour && defaultOpeningHour.day)
        const dayNumber =
          (openingHour && openingHour.dayNumber) || (defaultOpeningHour && defaultOpeningHour.dayNumber)
        let color: string = ''
        if (props.currentOpeningHour === openingHour) {
          if (openingConditionUsed === 0) {
            color = Colors.green
          } else if (openingConditionUsed === -1) {
            color = Colors.waiting
          } else if (openingConditionUsed === 1) {
            color = Colors.red
          }
        }
        return (
          <View
            key={`opening-hour-${dayNumber}`}
            style={index === 0 ? Styling.rowCenter : styles.container}
          >
            <Text
              color={color}
              style={Styling.autoRight}
              type='description'
            >
              {day}
            </Text>
            {openingHour && (openingHour.opening || openingHour.closing)
              ? (
                <>
                  <Text
                    color={color}
                    type='description'
                  >
                    {openingHour.opening || '-'}
                  </Text>
                  <Text
                    color={color}
                    style={Styling.marginHorizontal}
                    type='description'
                  >
                    {i18n.t('tot')}
                  </Text>
                  <Text
                    color={color}
                    type='description'
                  >
                    {openingHour.closing || '-'}
                  </Text>
                </>
                )
              : (
                <Text
                  color={color}
                  type='description'
                >
                  {i18n.t('gesloten')}
                </Text>
                )}
          </View>
        )
      })}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: Sizes.spacingVerticalTiny
  }
})
