import type { ReactElement } from 'react'
import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Attributes from '@/components/Attributes'
import Block from '@/components/Block'
import Button from '@/components/buttons/Button'
import Screen from '@/components/Screen'
import TouchableIcon from '@/components/touchables/TouchableIcon'
import RefreshControl from '@/components/wrappers/RefreshControl'
import Text from '@/components/wrappers/Text'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import type { TApiCallProps } from '@/hocs/withApiCall'
import { openPurchaseInvoice } from '@/redux/actions/files'
import * as purchaseApi from '@/redux/api/purchases'
import type { State as TState } from '@/redux/reducers'
import type { ThemeColors } from '@/redux/reducers/theme'
import selectors from '@/redux/selectors'
import ImageBanner from '@/screens/purchases/components/ImageBanner'
import type { Purchase as TPurchase } from '@/types/objects'
import type { TNavigationProp } from '@/types/props'
import alert from '@/utils/alert'
import common from '@/utils/common'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import prototype from '@/utils/prototype'
import toast from '@/utils/toast'
import { apiCall, apiCallSuccess } from '@/redux/actions/api'
import { ENTITIES } from '@/redux/constants'

interface TOwnProps {
  navigation: TNavigationProp
}

type TPurchaseProps = TOwnProps &
TApiCallProps & {
  colors: ThemeColors
  openPurchaseInvoice: typeof openPurchaseInvoice
  purchase: TPurchase
  purchases: TPurchase[]
  purchaseId: TPurchase['id']
  refreshing: boolean

  apiCall: typeof apiCall
  apiCallSuccess: typeof apiCallSuccess
}

interface TPurchaseState {
  loadingRemove: boolean
  loadingShare: boolean
}

class Purchase extends React.Component<TPurchaseProps, TPurchaseState> {
  state: TPurchaseState = {
    loadingRemove: false,
    loadingShare: false
  }

  componentDidMount () {
    this.refresh()
  }

  refresh = (): void => {
    this.props.apiCall('GET', ENTITIES.PURCHASE, this.props.purchaseId)
  }

  removePurchase = async (): Promise<void> => {
    this.setState({ loadingRemove: true })

    const response = await this.props.apiCall(purchaseApi.deletePurchase, this.props.purchaseId)

    this.setState({ loadingRemove: false })

    if (response?.result) {
      this.props.apiCallSuccess(
        'GET',
        ENTITIES.PURCHASES,
        this.props.purchases.filter((p) => p.id !== this.props.purchaseId)
      )
      navigation.back()
    } else {
      toast(i18n.t('Het is helaas niet gelukt om het item te verwijderen'))
    }
  }

  onRemovePurchase = (): void => {
    alert.showDouble(
      i18n.t('Verwijderen'),
      i18n.t('Weet u zeker dat u dit item wilt verwijderen?'),
      {
        style: 'cancel',
        text: i18n.t('Nee')
      },
      {
        onPress: this.removePurchase,
        text: i18n.t('Ja')
      }
    )
  }

  openPurchaseInvoice = (): void => {
    this.props.openPurchaseInvoice(this.props.purchase)
  }

  openScreenEditPurchase = (): void => {
    navigation.openScreenEditPurchase(this.props.purchase)
  }

  renderRefreshControl = (): ReactElement => (
    <RefreshControl
      onRefresh={this.refresh}
      refreshing={this.props.refreshing}
      title={i18n.t('De aankoopgegevens aan het verversen')}
    />
  )

  renderContent = (): ReactElement => {
    const purchase = this.props.purchase || {}

    return (
      <>
        <View style={styles.contentContainer}>
          <Text
            style={{ flex: 1 }}
            type='subsection'
          >
            {purchase.name}
          </Text>
          <View style={Styling.rowStart}>
            <TouchableIcon
              color={this.props.colors?.brand}
              icon='pencil'
              onPress={this.openScreenEditPurchase}
              touchableStyle={Styling.marginLeftMedium}
            />

            {this.state.loadingRemove
              ? (
                <ActivityIndicator
                  color={this.props.colors?.brand}
                  size={Sizes.icon}
                  style={Styling.marginLeftMedium}
                />
                )
              : (
                <TouchableIcon
                  color={this.props.colors?.brand}
                  icon='trash'
                  onPress={this.onRemovePurchase}
                  touchableStyle={Styling.marginLeftMedium}
                />
                )}
          </View>
        </View>

        <Attributes
          attributes={[
            {
              description: purchase.date,
              title: i18n.t('Aankoopdatum')
            },
            {
              description: prototype.toMoneyVisual(purchase.price, true),
              title: i18n.t('Aankoopprijs')
            }
          ]}
          id='purchase'
          style={Styling.autoBottom}
        />

        {purchase.invoice
          ? (
            <Button
              color={this.props.colors?.brand}
              disabled={this.state.loadingShare}
              loading={this.state.loadingShare}
              onPress={this.openPurchaseInvoice}
              style={Styling.marginTop}
              title={i18n.t('Download factuur')}
            />
            )
          : null}
      </>
    )
  }

  renderPhone = (): ReactElement => (
    <>
      <ImageBanner
        height={200}
        uri={this.props.purchase?.picture}
      />
      <Screen
        white
        refreshControl={this.renderRefreshControl()}
      >
        {this.renderContent()}
      </Screen>
    </>
  )

  renderTablet = (): ReactElement => (
    <>
      <ImageBanner
        height={300}
        uri={this.props.purchase?.picture}
      />
      <Screen refreshControl={this.renderRefreshControl()}>
        <Block noFlex>{this.renderContent()}</Block>
      </Screen>
    </>
  )

  render () {
    return (
      <Bootstrap.Switch
        renderPhone={this.renderPhone}
        renderTablet={this.renderTablet}
      />
    )
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Sizes.spacingVerticalMedium
  }
})

const mapStateToProps = (state: TState, ownProps: TOwnProps) => {
  const purchaseId: TPurchase['id'] = common.getNavigationParam(ownProps, 'purchaseId')

  return {
    colors: selectors.getThemeColors(state),
    purchase: selectors.getPurchase(state, purchaseId),
    purchases: selectors.getPurchases(state),
    purchaseId: common.getNavigationParam(ownProps, 'purchaseId'),
    refreshing: state?.purchases?.loadingDetail
  }
}

const mapDispatchToProps = {
  openPurchaseInvoice,
  apiCall,
  apiCallSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(Purchase)
