import React, { useMemo } from 'react'
import type { StyleProp, ViewStyle } from 'react-native'

import * as Bootstrap from '@/bootstrap'
import Attribute from '@/components/Attribute'
import type { ConfigProp } from '@/types/props'

export interface IAttribute {
  description?: string
  title?: string
  type?: string
  id?: string
  followNumber?: string | number
}

interface TAttributesProps {
  attributes: IAttribute[]
  id: string
  span?: ConfigProp
  style?: StyleProp<ViewStyle>
}

const Attributes: React.FC<TAttributesProps> = (props) => {
  const enhancedAttributes = useMemo(
    () =>
      (props.attributes || [])
        .filter((attribute) => attribute.description && attribute.title)
        .sort((attributeA, attributeB) => {
          const numberA = Number(attributeA.followNumber)
          const numberB = Number(attributeB.followNumber)
          if (Number.isNaN(numberA) || !Number.isFinite(numberA)) {
            return 1
          }
          if (Number.isNaN(numberB) || !Number.isFinite(numberB)) {
            return -1
          }
          return numberA - numberB
        }),
    [props.attributes]
  )

  return (
    <Bootstrap.Row
      gutterHorizontal={15}
      gutterVertical={15}
      style={props.style}
    >
      {enhancedAttributes.map((attribute, index) => (
        <Bootstrap.Column
          key={`column-${props.id || 'attributes'}-${index}`}
          span={props.span ?? { lg: 3, md: 4, sm: 6, xl: 3, xs: 6 }}
        >
          <Attribute
            noMargin
            description={attribute.description}
            title={`${attribute.title}:`}
            type={attribute.type}
          />
        </Bootstrap.Column>
      ))}
    </Bootstrap.Row>
  )
}

export default Attributes
