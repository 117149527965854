import { Alert, Platform } from 'react-native'

type Function = (...args: any[]) => any

interface Button {
  result?: boolean
  style?: 'default' | 'cancel' | 'destructive'
  text: string
  onPress?: Function
}

interface Options {
  cancelable?: boolean
  onDismiss?: () => void
}

function interceptCallback (resolve: Function, reject: Function, callback?: Function) {
  if (!callback) {
    resolve()
  } else {
    try {
      const maybePromise = callback()
      if (maybePromise instanceof Promise) {
        maybePromise.then(resolve, reject)
      } else {
        resolve(maybePromise)
      }
    } catch (error) {
      reject(error)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
class _Alert {
  _showAsync = async (title: string, message: string, buttons: Button[], options: Options = {}): Promise<any> => {
    if (!title && !message) {
      return await new Promise((resolve) => resolve())
    }
    return await new Promise((resolve, reject) => {
      const interceptedButtons: Button[] = buttons.map((button: Button) => ({
        ...button,
        onPress: () => interceptCallback(resolve, reject, button.onPress)
      }))

      const interceptedOptions: Options = {
        ...options,
        onDismiss: () => interceptCallback(resolve, reject, options.onDismiss)
      }

      Alert.alert(title, message, interceptedButtons, interceptedOptions)
    })
  }

  showSingle = async (title: string, message: string, button: Button, options?: Options): Promise<any> => {
    if (Platform.OS === 'web') {
      // eslint-disable-next-line no-alert
      window.alert(`${title}\n${message}`)
      if (button && typeof button.onPress === 'function') {
        button.onPress()
      }
      return true
    }
    // eslint-disable-next-line no-underscore-dangle
    return await this._showAsync(title, message, [button], options)
  }

  showDouble = async (
    title: string,
    message: string,
    buttonNegative: Button,
    buttonPositive: Button,
    options?: Options
  ): Promise<any> => {
    if (Platform.OS === 'web') {
      // eslint-disable-next-line no-alert
      const result: boolean = window.confirm(`${title}\n${message}`)
      if (result) {
        // This if statement should not be merged with the above if statement.
        if (buttonPositive && typeof buttonPositive.onPress === 'function') {
          buttonPositive.onPress()
        }
      } else if (buttonNegative && typeof buttonNegative.onPress === 'function') {
        buttonNegative.onPress()
      }
      return result
    }
    // eslint-disable-next-line no-underscore-dangle
    return await this._showAsync(title, message, [buttonNegative, buttonPositive], options)
  }
}

export default new _Alert()
