import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

import Icon from '@/components/Icon'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import withAnimFade from '@/hocs/withAnimFade'
import type { IconProp } from '@/types/props'

interface TToastProps {
  color?: string
  error?: boolean
  icon?: IconProp
  message: string
  style?: StyleProp<ViewStyle>
  success?: boolean
  warning?: boolean
}

function Toast (props: TToastProps) {
  let icon
  let color
  if (props.error) {
    icon = 'times-circle'
    color = Colors.red
  } else if (props.warning) {
    icon = 'exclamation-circle'
    color = Colors.orange
  } else if (props.success) {
    icon = 'check-circle'
    color = Colors.success
  }
  if (props.icon) {
    icon = props.icon
  }
  if (props.color) {
    color = props.color
  }
  return (
    <View style={[styles.view, color && { backgroundColor: color }, props.style]}>
      {icon
        ? <Icon
            large
            color={Colors.white}
            icon={icon}
            style={styles.icon}
          />
        : null}
      <Text
        style={Styling.flex}
        type='toast'
      >
        {props.message}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  icon: {
    marginRight: Sizes.spacingHorizontal
  },
  view: {
    alignItems: 'center',
    backgroundColor: Colors.gray,
    borderRadius: Sizes.borderRadius,
    flexDirection: 'row',
    paddingHorizontal: Sizes.spacingHorizontal,
    paddingVertical: Sizes.spacingVertical
  }
})

export default withAnimFade(Toast)
