import { call, put, select, takeLatest } from 'redux-saga/effects'

import * as ACTIONS from '@/redux/actions/forms'
import * as CONSTANTS from '@/redux/constants/claims'
import type { State as TState } from '@/redux/reducers'
import * as SELECTORS from '@/redux/selectors/forms'
import type * as TYPES from '@/redux/types/claims'
import alert from '@/utils/alert'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

export function * startClaimDamageForm (action: TYPES.StartClaimDamageFormAction) {
  const formClaimDamage: TState['forms']['claimDamage'] = yield select(SELECTORS.getFormClaimDamage)

  if (formClaimDamage?.answersStatic?.policyId) {
    const result: boolean = yield call(
      alert.showDouble,
      i18n.t('Verder gaan'),
      i18n.t('De vorige schademelding is niet afgerond, wilt u hiermee verdergaan?'),
      {
        onPress: () => false,
        style: 'cancel',
        text: i18n.t('Nee')
      },
      {
        onPress: () => true,
        text: i18n.t('Ja')
      }
    )

    if (result) {
      return navigation.openScreenClaimDamageForm()
    }

    yield put(ACTIONS.resetFormClaimDamanage())
  }

  if (action.policyId) {
    yield put(ACTIONS.updateFormClaimDamageStatic('policyId', action.policyId))

    return navigation.openScreenClaimDamageForm()
  }

  return navigation.openScreenClaimDamage()
}

export function * formsSaga () {
  yield takeLatest(CONSTANTS.START_CLAIM_DAMAGE_FORM, startClaimDamageForm)
}
