export const START_CLAIM_DAMAGE_FORM = 'START_CLAIM_DAMAGE_FORM'
export const CONTINUE_CLAIM_DAMAGE_CONCEPT = 'CONTINUE_CLAIM_DAMAGE_CONCEPT'

export const UPLOAD_ADDITIONAL_CLAIM_ATTACHMENT = 'UPLOAD_ADDITIONAL_CLAIM_ATTACHMENT'
export const UPLOADED_ADDITIONAL_CLAIM_ATTACHMENT = 'UPLOADED_ADDITIONAL_CLAIM_ATTACHMENT'
export const DELETE_ADDITIONAL_CLAIM_ATTACHMENT = 'DELETE_ADDITIONAL_CLAIM_ATTACHMENT'
export const DELETE_ADDITIONAL_CLAIM_ATTACHMENT_FAILED = 'DELETE_ADDITIONAL_CLAIM_ATTACHMENT_FAILED'
export const DELETED_ADDITIONAL_CLAIM_ATTACHMENT = 'DELETED_ADDITIONAL_CLAIM_ATTACHMENT'
export const GET_CLAIM_SUCCESS = 'GET_CLAIM_SUCCESS'
export const SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE'

export const SHOW_UNPROCESSABLE_CONTENT_ERROR = 'SHOW_UNPROCESSABLE_CONTENT_ERROR'
export const HIDE_UNPROCESSABLE_CONTENT_ERROR = 'HIDE_UNPROCESSABLE_CONTENT_ERROR'
