import { createSelector } from 'reselect'

import type { State as TState } from '@/redux/reducers'
import type { Claim, ClaimId, ClaimStatus, IHistoricalClaim, Policy } from '@/types/objects'

import { getFirstParameter } from './helpers'

export const getClaims = (state: TState) => state.claims?.data
export const getClaimServiceAvailability = (state: TState) => state.claims?.isAvailable
export const getClaimErrorMessage = (state: TState) => state.claims?.errorMessage
export const getHistoricalClaims = (state: TState) => state.historicalClaims?.data

export const getAllClaims = createSelector([getClaims, getHistoricalClaims], (claims, historicalClaims) =>
  claims?.concat(historicalClaims as unknown as Claim)
)

export const getHistoricalClaim = createSelector(
  [getHistoricalClaims, getFirstParameter],
  (historicalClaims, claimNumber: IHistoricalClaim['claimNumber']) =>
    historicalClaims?.find((claim) => claim?.claimNumber?.toString() === claimNumber?.toString())
)

export const getClaim = createSelector([getClaims, getFirstParameter], (claims, claimId: ClaimId) =>
  claims?.find((claim) => {
    if (claimId === undefined) {
      return null
    }

    if (claim.id) {
      return claim.id?.toString() === claimId?.toString()
    } else {
      return null
    }
  })
)

const pendingClaimStatuses: ClaimStatus[] = ['Gemeld via app', 'Concept (app)', 'In behandeling']
const concludedClaimStatuses: ClaimStatus[] = ['Afgehandeld', 'Afgewezen door adviseur']

export const getPendingClaims = createSelector([getAllClaims], (claims) =>
  claims?.filter((claim) => pendingClaimStatuses.includes(claim?.status))?.sort(sortByClaimDate)
)

export const getConcludedClaims = createSelector([getAllClaims], (claims) =>
  claims?.filter((claim) => concludedClaimStatuses.includes(claim?.status))?.sort(sortByClaimDate)
)

const sortByClaimDate = (a: Claim, b: Claim) => new Date(b?.reportDate)?.getTime() - new Date(a?.reportDate)?.getTime()

export const getProductClaimTypeValueByName = (claim: Claim, policy: Policy) =>
  policy?.productClaimTypes?.find((item) => item?.name === claim?.claimType)

export const getAnsweredQuestionsForClaimDamage = (claim: Claim) =>
  claim?.claimChangeRequestAttributeValues?.filter((item) => item.value) ?? []
