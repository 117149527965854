import React from 'react'
import { StyleSheet, View } from 'react-native'

import Screen from '@/components/Screen'
import Tabs from '@/components/Tabs'
import Sizes from '@/constants/Sizes'
import i18n from '@/utils/i18n'

import CoveragesPhone from './components/coverages.phone'
import DetailsPhone from './components/details.phone'
import HeaderContent from './components/header_content'
import MorePhone from './components/more.phone'
import type { IChildComponentProps } from './Policy'

const PolicyPhone: React.FC<IChildComponentProps> = (props) => {
  const routes = [
    {
      key: 'details',
      title: i18n.t('Details')
    },
    {
      key: 'coverages',
      title: i18n.t('Dekkingen')
    },
    {
      key: 'more',
      title: i18n.t('Meer')
    }
  ]

  const renderScene = ({ route }: { route: { key: string } }) => {
    if (route.key === 'details') {
      return <DetailsPhone policy={props.policy} />
    }

    if (route.key === 'coverages') {
      return <CoveragesPhone policy={props.policy} />
    }

    if (route.key === 'more') {
      return <MorePhone policy={props.policy} />
    }

    return null
  }

  return (
    <Screen
      noPadding
      white
      useRefreshControl
      refreshing={props.refreshing}
      onRefresh={props.onRefresh}
    >
      <View style={styles.tab}>
        <HeaderContent
          policy={props.policy}
          relation={props.relation}
        />
      </View>

      <Tabs
        scrollEnabled
        renderScene={renderScene}
        routes={routes}
      />
    </Screen>
  )
}

const styles = StyleSheet.create({
  tab: {
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingVertical: Sizes.screenPaddingVertical
  }
})

export default PolicyPhone
