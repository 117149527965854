import type { User } from '@/types/objects'
import api from '@/utils/api_wrapper'
import { parseEmailAddress } from '@/utils/email'

export const logout = async () => await api.get('sessions/logout')

export const login = async (email: User['email'], password: User['password'], resetPasswordUrl: string) =>
  await api.post('sessions/login', { email: parseEmailAddress(email), password, resetPasswordUrl })

export const forgotPassword = async (email: User['email'], resetPasswordUrl: string) =>
  await api.post('sessions/forgot-password', { email: parseEmailAddress(email), resetPasswordUrl })

export const updatePassword = async (
  email: User['email'],
  currentPassword: User['password'],
  newPassword: User['password'],
  newPasswordRepeat: User['password']
) =>
  await api.post<{ token: string }>('sessions/update-password', {
    currentPassword,
    email: parseEmailAddress(email),
    newPassword,
    newPasswordRepeat
  })

export const checkEmailAddress = async (email: User['email'], registerUrl: string) =>
  await api.post('sessions/check-email-address', { email: parseEmailAddress(email), registerUrl })

export const updateOnboarding = async (userId: User['id'], firstName: User['firstName'], termsRead: User['termsRead']) =>
  await api.post('sessions/onboarding', { firstName, termsRead, userId })

export const register = async (
  email: User['email'],
  password: User['password'],
  passwordRepeat: User['password'],
  registerToken: User['token']
) =>
  await api.post('sessions/register', {
    email: parseEmailAddress(email),
    password,
    passwordRepeat,
    registerToken,
    token: registerToken
  })

export const resetPassword = async (
  password: User['password'],
  passwordRepeat: User['password'],
  resetPasswordToken: User['token']
) =>
  await api.post('sessions/reset-password', {
    password,
    passwordRepeat,
    resetPasswordToken,
    token: resetPasswordToken
  })

export const createToken = async (email: User['email'], password: User['password']) =>
  await api.post<string>('sessions/create-token', { email: parseEmailAddress(email), password })

export const checkToken = async (token: User['token']) => await api.post('sessions/check-token', { token })

export const extendToken = async (token: User['token'], email: User['email']) =>
  await api.post('sessions/extend-token', { email: parseEmailAddress(email), token })
