import React from 'react'
import { StyleSheet } from 'react-native'

import type { TButtonProps } from '@/components/buttons/Button'
import Button from '@/components/buttons/Button'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import i18n from '@/utils/i18n'

type TButtonPreviousProps = TButtonProps & {
  small?: boolean
}

export default function ButtonPrevious ({ style, ...props }: TButtonPreviousProps) {
  return (
    <Button
      borderColor={Colors.gray}
      color={Colors.white}
      enableHitSlop={props.small}
      icon={['fal', 'chevron-left']}
      iconColor={Colors.gray}
      iconStyle={props.small && styles.iconSmall}
      small={props.small}
      style={[props.small && styles.buttonSmall, style]}
      title={!props.small && i18n.t('Vorige')}
      titleColor={Colors.gray}
      titleStyle={!props.small && Styling.marginRight}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  buttonSmall: {
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: Sizes.buttonSmall
  },
  iconSmall: {
    marginRight: 0
  }
})
