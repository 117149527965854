import React from 'react'
import { useSelector } from 'react-redux'

import PDFViewer from '@/screens/pdf_viewer'

import i18n from '@/utils/i18n'
import fs from '@/utils/fs'

import selectors from '@/redux/selectors'

import type { Policy } from '@/types/objects'

interface IGreenCardProps {
  route: {
    params: {
      policyId: string
    }
  }
}

interface IUseGreenCardOutput {
  fileName: string
  url: string
}

const getCleanGreenCardUrl = (greenCardUrl: string): string => {
  return greenCardUrl.replace(/ /g, '%20')
}

const useGreenCard = (policyId: string): IUseGreenCardOutput => {
  const policy = useSelector(state => selectors.getPolicy(state, policyId)) as Policy
  const fileName = fs.determineFilenameGreenCard(policy)
  const url = getCleanGreenCardUrl(policy?.greenCard || '')

  return {
    fileName,
    url
  }
}

const GreenCard: React.FC<IGreenCardProps> = ({ route }) => {
  const policyId = route.params.policyId
  const { fileName, url } = useGreenCard(policyId)

  return (
    <PDFViewer
      route={{
        params: {
          url,
          key: `green-card-${fileName}`,
          fileName,
          title: fileName,
          errorMessage: i18n.t('Het is niet gelukt om het Verzekeringsbewijs te downloaden')
        }
      }}
    />
  )
}

export default GreenCard
