import { createSelector } from 'reselect'

import type { State as TState } from '@/redux/reducers'
import { allHtmlEntities, generateGetByKeySelector, getFirstParameter } from '@/redux/selectors/helpers'
import type { Tip } from '@/types/objects'

export const getTipCategories = (state: TState) => state.tipRepository?.categories

export const getTipRepository = (state: TState) => state.tipRepository

export const getTips = createSelector(
  getTipRepository,
  (tipRepository) =>
    tipRepository?.tips?.map((tip) => ({
      ...tip,
      content: allHtmlEntities.decode(tip.content || '')
    }))
)

export const getTip = generateGetByKeySelector<Tip>(getTips, 'id')

export const getCategoryTips = createSelector([getTips, getFirstParameter], (tips, parameter) => {
  const categoryId = parameter && String(parameter)
  return tips?.filter((tip) => String(tip.categoryId) === categoryId)
})

export const getTipCategory = createSelector([getTipCategories, getFirstParameter], (categories, parameter) => {
  const categoryId = parameter && String(parameter)
  return categories?.find((category) => String(category.id) === categoryId)
})
