import ManageUser from '@/screens/profile/ManageUser'
import ManageUsers from '@/screens/profile/ManageUsers'
import i18n from '@/utils/i18n'
import { createStackNavigator } from '@react-navigation/stack'

const ManageUsersStack = createStackNavigator()
const ManageUsersStackNavigator = () => (
  <ManageUsersStack.Navigator screenOptions={{ headerShown: false }}>
    <ManageUsersStack.Screen
      name='ManageUsers'
      component={ManageUsers}
      options={{ title: i18n.t('Gebruikers beheren') }}
    />
    <ManageUsersStack.Screen
      name='AddUser'
      component={ManageUser}
      options={{ title: i18n.t('Gebruiker uitnodigen') }}
    />
    <ManageUsersStack.Screen
      name='EditUser'
      component={ManageUser}
      options={{ title: i18n.t('Gebruiker aanpassen') }}
    />
  </ManageUsersStack.Navigator>
)

export default ManageUsersStackNavigator
