import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import type { ICard } from '@/components/Cards'
import Cards from '@/components/Cards'
import Screen from '@/components/Screen'

import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

import selectors from '@/redux/selectors'

const useCards = (): ICard[] => {
  const valueMeterPolicies = useSelector(selectors.getValueMeterPolicies)
  const hasAccessToGreenCardScreen = useSelector(selectors.selectHasAccessToGreenCardsScreen)

  return useMemo(() => {
    const cards: ICard[] = [
      // {
      //   icon: 'file-alt',
      //   id: 'purchases',
      //   onPress: navigation.openScreenPurchases,
      //   title: i18n.t('Mijn bezittingen')
      // },
      {
        icon: 'fragile',
        id: 'claims',
        onPress: navigation.openScreenClaims,
        title: i18n.t('Mijn schades')
      },
      {
        icon: 'euro-sign',
        id: 'payments',
        onPress: navigation.openScreenPayments,
        title: i18n.t('Mijn betalingsoverzicht')
      }
    ]

    if (hasAccessToGreenCardScreen) {
      cards.push({
        icon: 'address-card',
        id: 'greencards',
        onPress: navigation.openScreenGreenCards,
        title: 'Verzekeringsbewijzen'
      })
    }

    const hasValueMeterPolicies = valueMeterPolicies.length > 0

    if (hasValueMeterPolicies) {
      cards.push({
        icon: 'tachometer',
        id: 'value-calculators',
        onPress: navigation.openScreenValueMeters,
        title: i18n.t('Waardemeters')
      })
    }

    return cards
  }, [valueMeterPolicies, hasAccessToGreenCardScreen])
}

const Case: React.FC = () => {
  const cards = useCards()

  return (
    <Screen>
      <Cards
        cards={cards}
        id='case'
      />
    </Screen>
  )
}

export default Case
