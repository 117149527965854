import React from 'react'

import type { IAttribute } from '@/components/Attributes'
import Attributes from '@/components/Attributes'
import Line from '@/components/Line'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import type { Policy } from '@/types/objects'
import common from '@/utils/common'
import i18n from '@/utils/i18n'

import { detailAttributes } from './helpers'

const DetailsTablet: React.FC<{ policy: Policy }> = ({ policy }) => {
  const attributes = common.convertDetailsToAttributes(detailAttributes(policy))

  return (
    <>
      <Line
        horizontal
        color={Colors.background}
        height={1}
        style={Styling.marginVerticalMedium}
      />

      <Text
        style={Styling.marginBottomNormal}
        type='subtitle'
      >
        {i18n.t('Details')}
      </Text>

      <Attributes
        attributes={attributes as IAttribute[]}
        id='attributes-details'
      />
    </>
  )
}

export default DetailsTablet
