import * as Device from 'expo-device'
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'
import Settings from '@/constants/Settings'
import Styling from '@/constants/Styling'
import { IS_WEB } from '@/constants/platform'

import { openModalLogs } from '@/redux/actions/modals'
import i18n from '@/utils/i18n'

import packageJson from '../../../package.json'

let git = {}

try {
  git = require('../../../git.json')
} catch (_) {}

interface TAboutAppProps {
  openModalLogs: typeof openModalLogs
}

interface TAboutAppState {
  count: number
  longCount: number
}

class AboutApp extends React.Component<TAboutAppProps, TAboutAppState> {
  state = {
    count: 0,
    longCount: 0
  }

  onPress = () =>
    this.setState((previousState: TAboutAppState) => ({
      count: previousState.count + 1
    }))

  onLongPress = () =>
    this.setState((previousState: TAboutAppState) => {
      let longCount = previousState.longCount + 1
      if (longCount === 3) {
        longCount = 0
        this.props.openModalLogs()
      }
      return { longCount }
    })

  renderContent = () => (
    <TouchableOpacity
      activeOpacity={1}
      onLongPress={this.onLongPress}
      onPress={this.onPress}
      style={styles.touchable}
    >
      <View style={Styling.flex}>
        <Text
          style={Styling.marginBottom}
          type='description'
          selectable={false}
        >
          {i18n.t('Applicatieversie')}: {packageJson.version}{' '}
        </Text>
        <Text
          style={Styling.marginBottom}
          type='description'
          selectable={false}
        >
          {i18n.t('Besturingssysteem')}: {Device.osName} {Device.osVersion}
        </Text>
        <Text
          type='description'
          selectable={false}
        >
          {i18n.t('$screen.about-app.description')}
        </Text>

        {this.state.count % 10 === 0 && this.state.count > 0
          ? (
            <View style={Styling.marginTop}>
              <Text type='description'>branch: {git?.branch}</Text>
              <Text type='description'>environment: {Settings.environment}</Text>
              <Text type='description'>sha: {git?.sha}</Text>
            </View>
            )
          : null}
      </View>
    </TouchableOpacity>
  )

  renderPhone = () => <Screen white>{this.renderContent()}</Screen>

  renderTablet = () => (
    <Screen>
      <Block noFlex>{this.renderContent()}</Block>
    </Screen>
  )

  render () {
    return (
      <Bootstrap.Switch
        renderPhone={this.renderPhone}
        renderTablet={this.renderTablet}
      />
    )
  }
}

const styles = StyleSheet.create({
  // @ts-expect-error
  touchable: IS_WEB ? { cursor: 'default' } : {}
})

export default connect(null, { openModalLogs })(AboutApp)
