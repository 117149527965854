import React, { useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import * as Bootstrap from '@/bootstrap'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import TextArea from '@/components/inputs/InputTextArea'
import Button from '@/components/buttons/Button'
import ButtonCircle from '@/components/buttons/ButtonCircle'
import navigation from '@/utils/navigation'
import i18n from '@/utils/i18n'
import { useDispatch, useSelector } from 'react-redux'
import { ENTITIES } from '@/redux/constants'
import { apiCall } from '@/redux/actions/api'
import selectors from '@/redux/selectors'
import ProductSelectors from './components/ProductSelectors'
import { updateAdviseMessage, addProductRow, resetAdvise } from '@/redux/actions/policy_advise'
import { requestPolicyAdvise } from '@/redux/api/policies'
import Colors from '@/constants/Colors'
import Toast from '@/components/Toast'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

const AddPolicyScreen: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const colors = useSelector(selectors.getThemeColors)

  const products = useSelector(selectors.getProducts)
  const adviseProducts = useSelector(selectors.getAdviseProducts)
  const message = useSelector(selectors.getAdviseMessage)
  const relationId = useSelector(selectors.getCurrentRelationId) ?? ''
  const agencyId = useSelector(selectors.getAgencyId) ?? ''

  const onMessageChange = (event: string): void => {
    dispatch(updateAdviseMessage(event))
  }

  const addRow = (): void => {
    dispatch(addProductRow())
  }

  useEffect(() => {
    dispatch(apiCall('GET', ENTITIES.PRODUCTS))
  }, [])

  const needExtraRow = adviseProducts.filter(item => item !== '').length === adviseProducts.length
  const showAddButton = needExtraRow && adviseProducts.length < 10
  const disabled = loading || adviseProducts.filter(item => item !== '').length === 0

  const submitAdviseRequest = (): void => {
    setLoading(true)
    requestPolicyAdvise(relationId, agencyId, message, adviseProducts).then(data => {
      dispatch(resetAdvise())
      navigation.openScreenAddPolicySuccess()
      setError('')
    }).catch(() => {
      setError(i18n.t('Er ging iets mis. Probeer het opnieuw'))
    }).finally(() => {
      setLoading(false)
    })
  }

  const renderForm = () => {
    return (
      <View>
        <ProductSelectors
          products={products}
          values={adviseProducts}
        />

        {showAddButton
          ? (
            <TouchableOpacity
              style={styles.addButton}
              onPress={addRow}
            >
              <ButtonCircle
                icon='add'
                color={colors.brand}
                style={{ marginRight: 10 }}
                small
              />
              <Text type='text'>{i18n.t('Verzekering toevoegen')}</Text>
            </TouchableOpacity>
            )
          : null}

        <Text
          type='label'
          style={{ marginTop: 30 }}
        >
          {i18n.t('Mijn adviesvraag:')}
        </Text>

        <TextArea
          placeholder={i18n.t('Vul hier zo veel mogelijk relevante informatie in...')}
          value={message}
          onChange={onMessageChange}
          maxLength={2000}
        />

        <Button
          title={i18n.t('Geef mij advies')}
          onPress={submitAdviseRequest}
          loading={loading}
          disabled={disabled}
        />
      </View>
    )
  }

  const renderPhone = () => {
    return (
      <KeyboardAwareScrollView style={styles.scrollView}>
        <View style={styles.topView}>
          <Text
            style={[Styling.marginBottom, { color: colors.brand }]}
            type='label'
          >
            {i18n.t('Ik wil graag verzekeringsadvies')}
          </Text>

          <Text
            type='description'
            style={Styling.marginBottom}
          >
            {i18n.t('Graag ontvang ik advies over het afsluiten van aanvullende verzekeringen en/of dekkingen.')}
          </Text>
        </View>

        <View style={styles.bottomView}>
          {renderForm()}
        </View>

        {error !== ''
          ? (
            <Toast
              error
              message={error}
              style={Styling.marginTopMediumHalf}
            />)
          : null}
      </KeyboardAwareScrollView>
    )
  }
  const renderTablet = () => {
    return (
      <Screen>
        <Bootstrap.Row>
          <Bootstrap.Column
            offsetLeft={{ lg: 1, md: 0, sm: 0, xl: 2, xs: 0, xxl: 3 }}
            span={{ lg: 10, md: 12, sm: 12, xl: 8, xs: 12, xxl: 6 }}
            style={Styling.itemsCenter}
          >
            <Text
              center
              style={Styling.marginBottom}
              type='title'
            >
              {i18n.t('Ik wil graag verzekeringsadvies')}
            </Text>

            <Text
              center
              type='description'
              style={Styling.marginBottomBig}
            >
              {i18n.t('Graag ontvang ik advies over het afsluiten van aanvullende verzekeringen en/of dekkingen.')}
            </Text>
          </Bootstrap.Column>
          <Bootstrap.Column
            span={{ lg: 12, md: 12, sm: 12, xl: 10, xs: 12, xxl: 9 }}
            style={Styling.autoHorizontal}
          >
            {renderForm()}
          </Bootstrap.Column>
        </Bootstrap.Row>
        {error !== ''
          ? (
            <Toast
              error
              message={error}
              style={Styling.marginTopMediumHalf}
            />)
          : null}
      </Screen>
    )
  }
  return (
    <Bootstrap.Switch
      renderPhone={renderPhone}
      renderTablet={renderTablet}
    />
  )
}

export const styles = StyleSheet.create({
  scrollView: {
    backgroundColor: Colors.white
  },
  topView: {
    padding: 32
  },
  subHead: {
    marginBottom: 30
  },
  bottomView: {
    padding: 32,
    backgroundColor: Colors.background
  },
  addButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20
  },
  view: {
    flex: 1,
    width: '100%'
  }
})

export default AddPolicyScreen
