import React from 'react'
import { StyleSheet } from 'react-native'
import { connect } from 'react-redux'

import Button from '@/components/buttons/Button'
import Icon from '@/components/Icon'
import Modal from '@/components/wrappers/Modal'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import { closeModalEmergencyNumber } from '@/redux/actions/modals'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import common from '@/utils/common'
import i18n from '@/utils/i18n'

interface TModalEmergencyNumberProps {
  closeModalEmergencyNumber: typeof closeModalEmergencyNumber
  modals: any
}

function ModalEmergencyNumber (props: TModalEmergencyNumberProps) {
  return (
    <Modal
      onHide={props.closeModalEmergencyNumber}
      style={Styling.itemsCenter}
      visible={!!(props.modals && props.modals.emergencyNumber)}
    >
      <Icon
        huge
        circleColor={Colors.darkRed}
        color={Colors.white}
        icon='phone-plus'
        style={styles.icon}
      />
      <Text
        style={styles.title}
        type='section'
      >
        {i18n.t('Noodnummer bellen')}
      </Text>
      <Text
        style={Styling.textCenter}
        type='description'
      >
        {i18n.t(
          'Weet u zeker dat u het noodnummer wilt bellen? U wordt dan direct in contact gebracht met de alarmcentrale van Nh1816.'
        )}
      </Text>
      <Button
        color={Colors.darkRed}
        onPress={common.callEmergencyNumber}
        style={Styling.marginVertical}
        title={i18n.t('Ja, bel het noodnummer')}
      />
      <Text type='description'>{i18n.t('of')}</Text>
      <Button
        borderColor={Colors.darkRed}
        color={Colors.white}
        onPress={props.closeModalEmergencyNumber}
        style={Styling.marginTop}
        title={i18n.t('Nee, annuleer')}
        titleColor={Colors.darkRed}
      />
    </Modal>
  )
}

const styles = StyleSheet.create({
  icon: {
    borderColor: Colors.white,
    borderWidth: 4,
    marginTop: -Sizes.iconContainerHuge * 0.5,
    position: 'absolute',
    top: 0
  },
  title: {
    marginBottom: Sizes.spacingVertical,
    marginTop: Sizes.iconContainerHuge * 0.5
  }
})

const mapStateToProps = (state: TState) => ({
  modals: selectors.getModals(state)
})

export default connect(mapStateToProps, { closeModalEmergencyNumber })(ModalEmergencyNumber)
