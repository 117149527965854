import hoistNonReactStatics from 'hoist-non-react-statics'
import React from 'react'
import { View } from 'react-native'

export interface TWidthProps {
  width: number
}

interface TWithWidthState {
  width: number
}

export default function withWidth<P extends object> (Component: React.ComponentType<P & TWidthProps>) {
  class WithWidth extends React.Component<P, TWithWidthState> {
    constructor (props: P) {
      super(props)

      this.state = {
        width: 0
      }
    }

    onLayout = (event: any) => {
      if (event && event.nativeEvent && event.nativeEvent.layout && event.nativeEvent.layout.width) {
        this.setState({ width: event.nativeEvent.layout.width })
      }
    }

    render () {
      return (
        <>
          <View onLayout={this.onLayout} />
          <Component
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(this.props as P)}
            width={this.state.width}
          />
        </>
      )
    }
  }
  hoistNonReactStatics(WithWidth, Component)
  return WithWidth
}
