import React from 'react'

import ContextBreakpoint from '@/bootstrap/ContextBreakpoint'
import getConfig from '@/bootstrap/getConfig'
import type { ConfigProp } from '@/types/props'

interface TFragmentProps {
  hidden?: ConfigProp
}

export default class Fragment extends React.Component<TFragmentProps> {
  static contextType = ContextBreakpoint

  render () {
    return this.context && this.props.hidden && getConfig(this.context, this.props.hidden as object)
      ? null
      : this.props.children ?? null
  }
}
