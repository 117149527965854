import * as Localization from 'expo-localization'

import nl from '@/assets/locales/nl/translation.json'
import Settings from '@/constants/Settings'

class I18n {
  locale: any

  translation: any

  translations: any

  constructor () {
    this.translations = {}
    this.translations.nl = nl
    this.setLocale(Localization.locale)
  }

  cleanLocale = (locale: any) => (locale || '').substring(0, 2).toLowerCase()

  setTranslations = (translations: any) => {
    Object.entries(translations || {}).forEach(([key, value]) => {
      this.setTranslation(key, value)
    })
  }

  setTranslation = (rawLocale: any, translation: any) => {
    if (translation && rawLocale) {
      const locale = this.cleanLocale(rawLocale)
      if (locale in this.translations) {
        this.translations[locale] = {
          ...this.translations[locale],
          ...translation
        }
      } else {
        this.translations[locale] = translation
      }
    }
  }

  setLocale = (rawLocale: any) => {
    let locale = this.cleanLocale(rawLocale)
    if (!(locale in this.translations)) {
      locale = Settings.defaultLocale
    }
    this.translation = this.translations[locale] || {}
    this.locale = locale
  }

  t = (text: any): string => (text in this.translation && this.translation[text] ? this.translation[text] : text)
}

export default new I18n()
