import type { IHistoricalClaim } from '@/types/objects'
import type { TApiCallDispatch } from '../actions/api'

export interface IHistoricalClaimsState {
  data: IHistoricalClaim[]
}

export const initialState: IHistoricalClaimsState = {
  data: []
}

export default (state: IHistoricalClaimsState = initialState, action: TApiCallDispatch) => {
  switch (action?.type) {
    case 'GET_HISTORICAL_CLAIMS_SUCCESS':
      return {
        ...state,
        data: (action?.payload?.data as IHistoricalClaim[] ?? []).map(item => ({
          ...item,
          historical: true
        }))
      }

    case 'SELECT_RELATION':
      return initialState

    default:
      return state
  }
}
