import React from 'react'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import EmptyState from '@/components/EmptyState'
import Screen from '@/components/Screen'
import RefreshControl from '@/components/wrappers/RefreshControl'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import { startClaimDamageForm } from '@/redux/actions/claims'
import { apiCall } from '@/redux/actions/api'
import type { State as TState } from '@/redux/reducers'
import type { ThemeColors } from '@/redux/reducers/theme'
import selectors from '@/redux/selectors'
import ItemClaim from '@/screens/claims/components/ItemClaim'
import IllustrationClaims from '@/svgs/IllustrationClaims'
import type { Claim, IHistoricalClaim } from '@/types/objects'
import alert from '@/utils/alert'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import { ENTITIES } from '@/redux/constants'

interface TClaimsProps {
  claims: Claim[]
  pendingClaims: Claim[]
  concludedClaims: Claim[]
  colors: ThemeColors
  refreshing: boolean
  isAvailable: boolean
  claimServiceErrorMessage: string
  apiCall: typeof apiCall
  startClaimDamageForm: typeof startClaimDamageForm
}

class Claims extends React.Component<TClaimsProps> {
  componentDidMount () {
    this.refresh()
  }

  refresh = () => {
    this.props.apiCall('GET', 'CLAIMS', undefined, undefined, true)
  }

  keyExtractor = (claim: Claim) => `list-item-claim-${claim.id}`

  renderRefreshControl = () => (
    <RefreshControl
      onRefresh={this.refresh}
      refreshing={this.props.refreshing}
      title={i18n.t('Gegevens van de schademeldingen aan het ophalen')}
    />
  )

  deleteClaimOnPress = (claim: Claim): void => {
    alert.showDouble(
      i18n.t('Verwijderen'),
      i18n.t('Weet u zeker dat u de concept schademelding wilt verwijderen?'),
      {
        onPress: () => false,
        style: 'cancel',
        text: i18n.t('Nee')
      },
      {
        onPress: () => {
          this.props.apiCall('DELETE', ENTITIES.CLAIM, claim.id)
        },
        text: i18n.t('Ja')
      }
    )
  }

  onPressClaim = (claim: Claim | IHistoricalClaim): void => {
    navigation.openScreenClaim(claim as Claim)
  }

  renderItem = ({ item }: { item: Claim | IHistoricalClaim }) => (
    <ItemClaim
      claim={item}
      onPress={this.onPressClaim}
      removeClaim={this.deleteClaimOnPress}
    />
  )

  renderClaims = () => {
    const claims = this.props.isAvailable ? this.props.claims : []
    return claims?.length
      ? (
        <Bootstrap.Collection
          data={claims}
          gutter={30}
          id='claims-tablet-collection'
          keyExtractor={this.keyExtractor}
          margin={0}
          renderItem={this.renderItem}
          span={{ lg: 12, md: 12, sm: 12, xl: 6, xs: 12 }}
          style={Styling.marginTopMedium}
        />
        )
      : (
        <EmptyState
          message={this.props.refreshing
            ? i18n.t('Uw schades worden opgehaald')
            : this.props.isAvailable ? i18n.t('U heeft geen lopende of historische schades.') : this.props.claimServiceErrorMessage}
        />
        )
  }

  render () {
    return (
      <Screen refreshControl={this.renderRefreshControl()}>
        <Bootstrap.Row
          gutterHorizontal={30}
          gutterVertical={30}
        >
          <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 6, xs: 12, xxl: 8, xxxl: 8 }}>
            <Text type='descriptionLight'>{i18n.t('Overzicht')}</Text>
            <Text type='title'>{i18n.t('Mijn schades')}</Text>
            <Text
              style={Styling.marginVerticalMedium}
              type='description'
            >
              {i18n.t('$screen.claims.description')}
            </Text>
            <Button
              icon='fragile'
              iconCircleColor={Colors.white}
              onPress={() => this.props.startClaimDamageForm()}
              style={Styling.selfStart}
              title={i18n.t('Schade melden')}
            />
          </Bootstrap.Column>
          <Bootstrap.Column span={{ lg: 6, md: 0, sm: 0, xl: 6, xs: 0, xxl: 4, xxxl: 4 }}>
            <IllustrationClaims
              backgroundColor='transparent'
              color={this.props.colors?.brand}
              style={Styling.autoVertical}
            />
          </Bootstrap.Column>
        </Bootstrap.Row>

        {this.renderClaims()}
      </Screen>
    )
  }
}

const mapStateToProps = (state: TState) => ({
  claims: selectors.getClaims(state),
  colors: selectors.getThemeColors(state),
  concludedClaims: selectors.getConcludedClaims(state),
  pendingClaims: selectors.getPendingClaims(state),
  refreshing: !!selectors.isLoading('CLAIMS')(state),
  isAvailable: selectors.getClaimServiceAvailability(state),
  claimServiceErrorMessage: selectors.getClaimErrorMessage(state)
})

const mapDispatchToProps = {
  apiCall,
  startClaimDamageForm
}

export default connect(mapStateToProps, mapDispatchToProps)(Claims)
