import type { Tip, TipCategory } from '@/types/objects'
import type { TApiCallSuccess } from '../actions/api'

interface TipRepositoryState {
  categories: TipCategory[]
  tips: Tip[]
}

export const initialState: TipRepositoryState = {
  categories: [],
  tips: []
}

export default (state: TipRepositoryState = initialState, action: TApiCallSuccess): TipRepositoryState => {
  switch (action?.type) {
    case 'GET_TIPS_SUCCESS':
      return {
        ...state,
        categories: action.payload.data.categories,
        tips: action.payload.data.tips
      }

    default:
      return state
  }
}
