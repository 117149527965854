import { createStackNavigator } from '@react-navigation/stack'
import i18n from '@/utils/i18n'
import TipCategory from '@/screens/tips/TipCategory'
import TipCategories from '@/screens/tips/TipCategories'

const TipsStack = createStackNavigator()
const TipsStackNavigator = () => (
  <TipsStack.Navigator screenOptions={{ headerShown: false }}>
    <TipsStack.Screen
      name='TipCategories'
      component={TipCategories}
      options={{ title: i18n.t('Preventietips') }}
    />

    <TipsStack.Screen
      name='TipCategory'
      component={TipCategory}
      options={{ title: i18n.t('Preventietip') }}
    />
  </TipsStack.Navigator>
)

export default TipsStackNavigator
