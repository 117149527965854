import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import ListItemCombiPolicy from '@/components/list_items/ListItemCombiPolicy'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import { apiCall } from '@/redux/actions/api'
import { forceSwitchRelation } from '@/redux/actions/relations'
import selectors from '@/redux/selectors'
import Cases from '@/svgs/Cases'
import i18n from '@/utils/i18n'
import { ENTITIES } from '@/redux/constants'
import ContextBreakpoint, { isSmallScreen } from '@/bootstrap/ContextBreakpoint'
import Button from '@/components/buttons/Button'
import type { Relation } from '@/types/objects'
import navigation from '@/utils/navigation'
import { IS_WEB } from '@/constants/platform'
import Sizes from '@/constants/Sizes'
import alert from '@/utils/alert'
import { selectRelation } from '../../redux/actions/relationRepository'

const SwitchCombiPolicy: React.FC = () => {
  const dispatch = useDispatch()

  const refresh = () => dispatch(apiCall('GET', ENTITIES.RELATIONS))
  const switchRelation = (relation: Relation) => {
    dispatch(selectRelation(relation))
    dispatch(forceSwitchRelation())
  }
  const addCombiPolicy = async () => await navigation.openScreenAddRelationToCombi()
  const deleteRelationCombi = (relation: Relation) => {
    alert.showDouble(
      i18n.t('Verwijderen'),
      i18n.t('Weet u zeker dat u de combipolis wilt verwijderen?'),
      { text: 'Nee' },
      { text: 'Ja', style: 'destructive', onPress: () => dispatch(apiCall('DELETE', ENTITIES.RELATION, relation.id)) }
    )
  }

  useEffect(() => {
    refresh()
  }, [])

  const relations = useSelector(selectors.getRelations)
  const currentRelationId = useSelector(selectors.getCurrentRelationId)
  const refreshing = !!useSelector(selectors.isLoading('RELATIONS'))

  const breakpoint = useContext(ContextBreakpoint)
  const isPhone = isSmallScreen(breakpoint)

  const Wrapper = ({ children }: { children: React.ReactNode }) => {
    if (isPhone) {
      return <>{children}</>
    }
    return <Block noFlex>{children}</Block>
  }

  return (
    <Screen
      white={isPhone}
      useRefreshControl
      refreshing={refreshing}
      onRefresh={refresh}
    >
      <Wrapper>
        <Bootstrap.Column
          offsetLeft={{ lg: 2, md: 0, sm: 0, xl: 3, xs: 0 }}
          span={{ lg: 9, md: 12, sm: 12, xl: 6, xs: 12 }}
          style={Styling.itemsCenter}
        >
          <Cases style={Styling.marginBottomMedium} />
          <Text
            style={Styling.marginBottom}
            type='title'
          >
            {i18n.t('Welke combipolis?')}
          </Text>
          <Text
            style={Styling.textCenter}
            type='description'
          >
            {i18n.t('$screen.switch-combi-policy.description')}
          </Text>
        </Bootstrap.Column>

        <Bootstrap.Row gutterHorizontal={15}>
          {(relations || []).map((relation) => (
            <Bootstrap.Column
              key={`switch-combi-policy-column-${relation.id}`}
              span={{ lg: 6, md: 12, sm: 12, xl: 4, xs: 12 }}
            >
              <ListItemCombiPolicy
                current={currentRelationId === relation.id}
                onPressSelect={switchRelation}
                onPressDelete={deleteRelationCombi}
                relation={relation}
              />
            </Bootstrap.Column>
          ))}

          <Bootstrap.Column
            key='add-combi-user'
            span={{ lg: 6, md: 12, sm: 12, xl: 4, xs: 12 }}
          >
            <Button
              title={i18n.t('Combipolis toevoegen')}
              onPress={addCombiPolicy}
              icon='user'
              iconCircleColor='#fff'
              style={
                IS_WEB ? { marginTop: Sizes.spacingVertical, height: 77 } : { marginTop: Sizes.spacingVerticalMedium }
              }
            />
          </Bootstrap.Column>
        </Bootstrap.Row>
      </Wrapper>
    </Screen>
  )
}

export default SwitchCombiPolicy
