import type { TApiCallSuccess } from '../actions/api'

interface TermsState {
  termsOfUse: string
}

export const initialState: TermsState = {
  termsOfUse: ''
}

export default (state: TermsState = initialState, action: TApiCallSuccess): TermsState => {
  switch (action?.type) {
    case 'GET_TERMS_OF_USE_SUCCESS':
      return {
        ...state,
        termsOfUse: action?.payload?.data?.termsOfUse as string
      }

    default:
      return state
  }
}
