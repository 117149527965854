import React from 'react'
import type { ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import ListItem from '@/components/list_items/ListItem'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import mapStateToProps from '@/redux/mapStateToProps'
import type { Policy } from '@/types/objects'
import data from '@/utils/data'
import Icon from '@/components/Icon'
import type { ThemeColors } from '@/redux/reducers/theme'
import Sizes from '@/constants/Sizes'

interface TListItemPolicyTButtonProps {
  colors: ThemeColors
  onPress?: (policy: Policy) => void
  policy: Policy
  policyStatus?: boolean
  style?: ViewStyle
}

function ListItemPolicyButton (props: TListItemPolicyTButtonProps) {
  const policy = props.policy || {}
  const distinctionLabel = data.determinePolicyDistinctionLabel(policy)
  let policyStatusText = null
  if (policy.status === 'Geroyeerd') {
    policyStatusText = ' (Beëindigd)'
  } else if (policy.status === 'Opgeschort') {
    policyStatusText = ` (${policy.status})`
  }

  const policyStatus = props.policyStatus
    ? (
      <Text type={policy.status === 'Geroyeerd' ? 'descriptionError' : 'descriptionLight'}>{policyStatusText}</Text>
      )
    : null

  return (
    <ListItem
      chevron
      borderColor={Colors.cc}
      chevronColor={Colors.cc}
      item={policy}
      onPress={props.onPress}
      style={props.style}
    >
      <Icon
        circleColor={props.colors?.lightBrand}
        color={props.colors?.brand}
        productId={policy.productId}
      />
      <View style={styles.container}>
        <Text type='description'>
          {policy?.productName}
          {policyStatus}
        </Text>

        {distinctionLabel
          ? <Text type='paragraphLight'>{distinctionLabel}</Text>
          : null}
      </View>
    </ListItem>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: Sizes.spacingHorizontal
  }
})

export default connect(mapStateToProps.themeColors)(ListItemPolicyButton)
