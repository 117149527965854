import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Screen from '@/components/Screen'
import Html from '@/components/wrappers/HTML'
import { apiCall } from '@/redux/actions/api'
import { ENTITIES } from '@/redux/constants'
import { isLoading } from '@/redux/selectors/loading'
import { getTermsOfUse } from '@/redux/selectors/terms'

const TermsOfUse = () => {
  const dispatch = useDispatch()

  const termsOfUse = useSelector(getTermsOfUse)
  const refreshing = !!useSelector(isLoading(ENTITIES.TERMS_OF_USE))

  const refresh = () => {
    dispatch(apiCall('GET', ENTITIES.TERMS_OF_USE))
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <Bootstrap.Switch
      renderPhone={() => (
        <Screen
          white
          useRefreshControl
          onRefresh={refresh}
          refreshing={refreshing}
        >
          <Html html={termsOfUse} />
        </Screen>
      )}
      renderTablet={() => (
        <Screen
          useRefreshControl
          onRefresh={refresh}
          refreshing={refreshing}
        >
          <Block noFlex>
            <Html html={termsOfUse} />
          </Block>
        </Screen>
      )}
    />
  )
}

export default TermsOfUse
