import { createSelector } from 'reselect'

import { generateGetByKeySelector } from '@/redux/selectors/helpers'
import type { WhatToDo } from '@/types/objects'

export const getWhatToDos = () => data

export const getWhatToDo = generateGetByKeySelector<WhatToDo>(getWhatToDos, 'id')

export const getWhatToDosImportant = createSelector(getWhatToDos, (whatToDos) =>
  whatToDos.filter((whatToDo) => whatToDo.important)
)

const data: WhatToDo[] = [
  {
    content: `
      <div>
        <h2>Wat te doen bij schade?</h2>
        <br/>
        <p>Heeft u schade? Meld de schade bij uw adviseur. Hij of zij begeleidt u bij de schademelding en ontzorgt u. De adviseur houdt u op de hoogte van de schadeafwikkeling. Daarnaast heeft de adviseur contact met Nh1816 Verzekeringen en beantwoordt uw vragen.</p>
        <br/>
        <br/>
        <h2>Ruitbreuk in of aan uw woning?</h2>
        <br/>
        <p>Is er sprake van ruitbreuk in of aan uw woning en wordt deze schade gedekt conform onze polisvoorwaarden? Neem contact op met <b>Uniglas</b> via onderstaand nummer.</p>
        <br/>
        <p><b>Bel: </b><a href="tel:088-8640016">088 - 8640016</a></p>
        <br/>
        <p>Uniglas zorgt voor het plaatsen van een noodvoorziening en zorgt voor de vervanging van de gebroken ruit. Wij ontvangen rechtstreeks de nota van Uniglas en zorgen voor de verdere financiële afwikkeling.</p>
        <br/>
        <br/>
        <h2>Noodsituatie</h2>
        <br/>
        <p>Is er sprake van een noodsituatie? Ons noodnummer is dag en nacht bereikbaar.</p>
        <br/>
        <button-emergency />
        <br/>
        <br/>
        <h2>Zoekt u een schadehersteller?</h2>
        <br/>
        <button-link data-screen="DamageRepair" data-title="Ga naar de schadeherstellers" />
      </div>
    `,
    icon: 'fragile',
    id: 'schade',
    important: true,
    title: 'Schade',
  },
  {
    content: `
      <div>
        <h2>Wat te doen bij overlijden?</h2>
        <br/>
        <p>Is een dierbare overleden? Ook in deze lastige tijd staat de adviseur voor u klaar. Emoties en praktische zaken lopen bij een overlijden door elkaar heen. De adviseur helpt u en begeleidt u bij het afwikkelen van de verzekeringen. Meld daarom het overlijden van uw dierbare bij uw adviseur.</p>
        <br/>
        <button-link data-screen="AgencyStack" data-title="Ga naar de adviseur" />
      </div>
    `,
    icon: 'cross',
    id: 'overlijden',
    important: true,
    title: 'Overlijden',
  },
  {
    content: `
      <div>
        <h2>Wat de doen bij verlies of diefstal?</h2>
        <br/>
        <p>Bent u beroofd op straat, in de trein of is er iets gestolen uit uw woning? Volg dan altijd onderstaande stappen:</p>
        <br/>
        <ol>
          <li>Doe direct aangifte bij de politie</li>
          <li>Meld de schade vervolgens ook bij uw verzekeringsadviseur. Hij staat voor u klaar en helpt u bij de schadeafhandeling en afwikkeling</li>
        </ol>
        <br/>
        <button-link data-screen="AgencyStack" data-title="Ga naar de adviseur" />
        <br/>
        <br/>
        <p>Bent u tijdens uw reis beroofd?</p>
        <br/>
        <ol>
          <li>Doe direct aangifte bij de lokale politie. De aangifte dient als bewijs en is belangrijk bij de schade-uitkering</li>
          <li>Stel tijdens uw vakantie ook alvast uw adviseur op de hoogte</li>
          <li>Geef bij thuiskomst de schademelding door via de VerzekeringApp of Mijn Polissen en voeg de aangifte van de buitenlandse politie als bijlage toe</li>
        </ol>
        <br/>
        <br/>
        <h2>Is uw auto gestolen?</h2>
        <br/>
        <ol>
          <li>Meld de diefstal van uw voertuig direct bij de politie. Houd uw kentekenbewijs, rijbewijs en Verzekeringsbewijs bij de hand</li>
          <li>Informeer het Verzekeringsbureau Voertuigcriminaliteit (VBV). De helpdesk is 24/7 bereikbaar via tel. <a href="tel:055-7410001">055-7410001</a>. Na de melding wordt uw voertuig opgenomen in de opsporingsregisters van de politie</li>
          <li>Geef de diefstal vervolgens direct door aan uw verzekeringsadviseur</li>
        </ol>
        <br/>
        <button-link data-screen="AgencyStack" data-title="Ga naar de adviseur" />
        <br/>
        <br/>
        <h2>Is uw auto gestolen in het buitenland?</h2>
        <br/>
        <ol>
          <li>Doe direct aangifte bij de politie in de plaats waar uw auto is gestolen. De diefstal wordt internationaal bekend gemaakt. Bewaar de aangifte goed</li>
          <li>Meld bij thuiskomst de schade zo snel mogelijk bij het Aangifteloket Gestolen Voertuigen en Transportcriminaliteit via <a href="tel:088-0087444">088-0087444</a></li>
          <li>Mail de ingescande buitenlandse aangifte naar <a href="mailto:agv@liv.nl" target="_blank">agv@liv.nl</a></li>
          <li>Geef de diefstal ook zo snel mogelijk door aan uw verzekeringsadviseur</li>
        </ol>
        <br/>
        <button-link data-screen="AgencyStack" data-title="Ga naar de adviseur" />
        <br/>
        <br/>
        <h2>Verloren en gevonden</h2>
        <br/>
        <p>Ga naar <a href="https://www.verlorenofgevonden.nl/">https://www.verlorenofgevonden.nl/</a> als u in Nederland iets bent verloren of hebt gevonden.</p>
        <br/>
        <p>Lever gevonden voorwerpen in bij de gemeente of het politiebureau. Daar kunt u ook melding maken van hetgeen u bent verloren.</p>
      </div>
    `,
    icon: 'ghost',
    id: 'verlies-of-diefstal',
    important: true,
    title: 'Verlies of diefstal',
  }
]
