import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import Svg, { Defs, Ellipse, G, Path } from 'react-native-svg'

interface TCogsProps {
  style?: StyleProp<ViewStyle>
  width?: number
}

const defaultWidth = 228
const defaultHeight = 144
export const ratio = defaultHeight / defaultWidth

export default function SvgComponent (props: TCogsProps) {
  const width = props.width || defaultWidth
  const height = props.width ? width * ratio : defaultHeight
  return (
    <Svg
      height={height}
      style={props.style}
      viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}
      width={width}
    >
      <Defs />
      <G
        fill='none'
        fillRule='evenodd'
      >
        {/* @ts-expect-error ts-migrate(2322) FIXME: Property 'filter' does not exist on type 'Intrinsi... Remove this comment to see the full error message */}
        <Ellipse
          cx={114}
          cy={123}
          fill='#FBFAFA'
          filter='url(#prefix__a)'
          rx={114}
          ry={21}
        />
        <G fillRule='nonzero'>
          <Path
            d='M170.187 1.498c.727-1.283 2.277-1.816 3.657-1.307a26.755 26.755 0 017.774 4.504 3.008 3.008 0 01.679 3.803l-1.986 3.463a20.907 20.907 0 013.85 6.636h3.996a2.99 2.99 0 012.955 2.495 27.287 27.287 0 010 8.985 3.011 3.011 0 01-2.955 2.518h-3.996a20.908 20.908 0 01-3.85 6.636l1.986 3.464c.726 1.259.46 2.882-.679 3.802a27.083 27.083 0 01-7.774 4.505c-1.38.508-2.93-.025-3.657-1.308l-1.986-3.464c-2.518.46-5.134.46-7.677 0l-1.986 3.464c-.726 1.283-2.277 1.816-3.657 1.308a26.763 26.763 0 01-7.774-4.505 3.007 3.007 0 01-.678-3.802l1.985-3.464a20.908 20.908 0 01-3.85-6.636h-3.996a2.99 2.99 0 01-2.955-2.494 27.288 27.288 0 010-8.985 3.011 3.011 0 012.955-2.519h3.996a20.906 20.906 0 013.85-6.636l-1.985-3.463c-.727-1.26-.46-2.882.678-3.803 2.3-1.913 4.94-3.439 7.774-4.504 1.38-.509 2.93.024 3.657 1.307l1.986 3.464c2.543-.46 5.134-.46 7.677 0zm.582 17.705c-9.325-7.193-19.957 3.463-12.788 12.787 9.324 7.169 19.956-3.463 12.788-12.787z'
            fill='#B6A399'
          />
          <Path
            d='M79.512 8.11a55.238 55.238 0 0118.407-.024c2.785.484 4.843 2.882 4.843 5.716v8.137a42.057 42.057 0 0113.296 7.678l7.048-4.07c2.422-1.404 5.546-.871 7.338 1.284 3.924 4.699 8.04 10.075 10.196 15.936.97 2.64.025 5.619-2.421 7.048l-8.162 4.068a42.083 42.083 0 010 15.38l8.162 4.068c2.446 1.405 3.511 4.384 2.542 7.048-2.001 5.442-5.799 10.467-9.472 14.919l-.845 1.017c-1.792 2.155-4.892 2.688-7.338 1.283l-7.048-4.068a41.6 41.6 0 01-13.296 7.677v8.137c0 2.81-2.01 5.232-4.77 5.716a54.467 54.467 0 01-18.383 0c-2.785-.484-4.843-2.882-4.843-5.716v-8.137a42.06 42.06 0 01-13.297-7.677l-7.047 4.044c-2.422 1.405-5.546.872-7.338-1.283-3.924-4.699-8.065-10.076-10.22-15.912a5.844 5.844 0 012.542-7.048l8.065-4.069a41.961 41.961 0 010-15.354l-8.065-4.093c-2.446-1.405-3.536-4.384-2.543-7.024 2.002-5.442 5.716-10.466 9.377-14.918l.844-1.017c1.792-2.156 4.892-2.689 7.338-1.284l7.047 4.069a41.606 41.606 0 0113.297-7.678v-8.161c0-2.786 1.986-5.207 4.746-5.692zm22.064 66.263c14.337-18.649-6.951-39.937-25.6-25.6-14.337 18.649 6.951 39.937 25.6 25.6z'
            fill='#84726F'
          />
          <Path
            d='M170.187 73.888c.727-1.283 2.277-1.816 3.657-1.308a26.76 26.76 0 017.774 4.505 3.008 3.008 0 01.679 3.802l-1.986 3.464a20.908 20.908 0 013.85 6.636h3.996a2.99 2.99 0 012.955 2.494 27.288 27.288 0 010 8.985 3.011 3.011 0 01-2.955 2.519h-3.996a20.907 20.907 0 01-3.85 6.636l1.986 3.463c.726 1.26.46 2.882-.679 3.803a27.077 27.077 0 01-7.774 4.504c-1.38.509-2.93-.024-3.657-1.308l-1.986-3.463c-2.518.46-5.134.46-7.677 0l-1.986 3.463c-.726 1.284-2.277 1.817-3.657 1.308a26.757 26.757 0 01-7.774-4.504 3.008 3.008 0 01-.678-3.803l1.985-3.463a20.906 20.906 0 01-3.85-6.636h-3.996a2.99 2.99 0 01-2.955-2.495 27.287 27.287 0 010-8.985 3.011 3.011 0 012.955-2.518h3.996a20.908 20.908 0 013.85-6.636l-1.985-3.464c-.727-1.259-.46-2.882.678-3.802 2.3-1.913 4.94-3.439 7.774-4.505 1.38-.508 2.93.025 3.657 1.308l1.986 3.463c2.543-.46 5.134-.46 7.677 0zm.582 17.68c-9.325-7.169-19.957 3.463-12.788 12.787 9.324 7.169 19.956-3.463 12.788-12.787z'
            fill='#EFEBE9'
          />
        </G>
      </G>
    </Svg>
  )
}
