import React from 'react'
import { Keyboard } from 'react-native'

import type { TButtonProps } from '@/components/buttons/Button'
import Button from '@/components/buttons/Button'

type TButtonFormProps = TButtonProps & {
  registerRef?: (...args: any[]) => any
}

export default class ButtonForm extends React.Component<TButtonFormProps> {
  refButton: any

  focus = () => {
    if (this.refButton && typeof this.refButton.focus === 'function' && !this.props.disabled) {
      this.refButton.focus()
    }
  }

  setRefButton = (ref: any) => {
    this.refButton = ref
    if (typeof this.props.registerRef === 'function') {
      // Do not register the button ref but the instance of this component.
      this.props.registerRef(this)
    }
  }

  onPress = () => {
    Keyboard.dismiss()

    if (typeof this.props.onPress === 'function') {
      this.props.onPress()
    }
  }

  render () {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (
      <Button
        {...this.props}
        onPress={this.onPress}
        ref={this.setRefButton}
      />
    )
  }
}
