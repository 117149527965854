import * as CONSTANTS from '@/redux/constants/filters'
import type * as TYPES from '@/redux/types/filters'
import type { CompanyTypes, Location } from '@/types/objects'
import type { FiltersState } from '../reducers/filters'

export const removeFilterLocation = (): TYPES.RemoveFilterLocationAction => ({
  type: CONSTANTS.REMOVE_FILTER_LOCATION
})

export const resetFilters = (): TYPES.ResetFiltersAction => ({
  type: CONSTANTS.RESET_FILTERS
})

export const updateFilterCompanyType = (companyType: CompanyTypes): TYPES.UpdateFilterCompanyTypeAction => ({
  companyType,
  type: CONSTANTS.UPDATE_FILTER_COMPANY_TYPE
})

export const updateFilterLocation = (location: Location): TYPES.UpdateFilterLocationAction => ({
  location,
  type: CONSTANTS.UPDATE_FILTER_LOCATION
})

export const updateFilterRadius = (radius: number): TYPES.UpdateFilterRadiusAction => ({
  radius,
  type: CONSTANTS.UPDATE_FILTER_RADIUS
})

export const updateFilter = (data: Partial<FiltersState>) => ({
  type: CONSTANTS.UPDATE_FILTERS,
  payload: { data }
})
