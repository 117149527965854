import React from 'react'
import { Platform, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import i18n from '@/utils/i18n'

import Button from '@/components/buttons/Button'
import Modal from '@/components/wrappers/Modal'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'

import { extendToken } from '@/redux/actions/loading'
import { lock, logout } from '@/redux/actions/user'
import selectors from '@/redux/selectors'
import { EXTEND_TOKEN } from '@/redux/constants/loading'

const ModalSession: React.FC = () => {
  const dispatch = useDispatch()

  const modals = useSelector(selectors.getModals)
  const loadingExtendToken = !!useSelector(selectors.isLoading(EXTEND_TOKEN))

  const onExtendToken = () => {
    dispatch(extendToken())
  }

  const onLock = () => {
    dispatch(lock())
  }

  const onLogout = () => {
    dispatch(logout())
  }

  const deny = () => {
    if (Platform.OS === 'web') {
      onLogout()
    } else {
      onLock()
    }
  }

  return (
    <Modal
      useScrollView
      showClose={false}
      visible={!!modals?.session}
    >
      <Text
        style={Styling.selfCenter}
        type='subtitle'
      >
        {i18n.t('Sessie verlengen')}
      </Text>
      <Text
        style={Styling.marginVerticalMedium}
        type='description'
      >
        {i18n.t('Uw sessie verloopt binnenkort, wilt u deze verlengen?')}
      </Text>
      <View style={Styling.row}>
        <Button
          borderColor={Colors.gray}
          color={Colors.white}
          onPress={deny}
          style={Styling.flex}
          title={i18n.t('Nee')}
          titleColor={Colors.gray}
          titleStyle={Styling.marginRight}
        />
        <View style={Styling.flex} />
        <Button
          color={Colors.primary}
          disabled={loadingExtendToken}
          loading={loadingExtendToken}
          onPress={onExtendToken}
          style={Styling.flex}
          title={i18n.t('Ja')}
          titleStyle={Styling.marginLeft}
        />
      </View>
    </Modal>
  )
}

export default ModalSession
