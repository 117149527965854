import ModalWeb from 'modal-enhanced-react-native-web'
import React from 'react'
import { Modal, Platform, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View, useColorScheme } from 'react-native'
import type { ColorSchemeName } from 'react-native'

import TouchableText from '@/components/touchables/TouchableText'
import Colors from '@/constants/Colors'
import Settings from '@/constants/Settings'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import i18n from '@/utils/i18n'

interface TModalPickerProps {
  children?: React.ReactNode
  onHide?: (...args: any[]) => any
  onSubmit?: (...args: any[]) => any
  visible: boolean
}

export default function ModalPicker (props: TModalPickerProps) {
  const colorScheme = useColorScheme()
  const textColor = colorScheme === 'light' ? Colors.gray : undefined
  const styles = style(colorScheme)

  const Content = (
    <View style={Styling.flex}>
      <TouchableOpacity
        onPress={props.onHide}
        style={StyleSheet.absoluteFill}
      />
      <View
        pointerEvents='box-none'
        style={styles.container}
      >
        <View style={styles.contentContainer}>
          <TouchableWithoutFeedback>
            <View style={styles.buttonContainer}>
              <TouchableText
                color={textColor}
                onPress={props.onHide}
                type='button'
              >
                {i18n.t('Annuleren')}
              </TouchableText>
              <TouchableText
                color={textColor}
                onPress={props.onSubmit}
                type='button'
              >
                {i18n.t('Klaar')}
              </TouchableText>
            </View>
          </TouchableWithoutFeedback>
          {props.children}
        </View>
      </View>
    </View>
  )
  if (Platform.OS === 'web') {
    return (
      <ModalWeb
        transparent
        animationType='fade'
        onBackButtonPress={props.onHide}
        onBackdropPress={props.onHide}
        onRequestClose={props.onHide}
        presentationStyle='overFullScreen'
        supportedOrientations={Settings.supportedOrientations}
        visible={props.visible}
      >
        {Content}
      </ModalWeb>
    )
  }
  return (
    <Modal
      transparent
      animationType='fade'
      onRequestClose={props.onHide}
      presentationStyle='overFullScreen'
      supportedOrientations={Settings.supportedOrientations}
      visible={props.visible}
    >
      {Content}
    </Modal>
  )
}

const style = (colorScheme: ColorSchemeName) => StyleSheet.create({
  buttonContainer: {
    backgroundColor: colorScheme === 'dark' ? Colors.backgroundDark : Colors.background,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Sizes.spacingHorizontal,
    paddingVertical: Sizes.spacingVertical
  },
  container: {
    backgroundColor: Colors.backdrop,
    flex: 1,
    justifyContent: 'flex-end'
  },
  contentContainer: {
    backgroundColor: colorScheme === 'dark' ? Colors.whiteDark : Colors.white
  }
})
