import type { User } from '@/types/objects'
import type { TApiCallSuccess } from '../actions/api'

export type UsersState = User[]

export const initialState: UsersState = []

export default (state: UsersState = initialState, action: TApiCallSuccess): UsersState => {
  switch (action?.type) {
    case 'GET_USERS_SUCCESS':
      return action.payload.data as User[]

    default:
      return state
  }
}
