import { IS_WEB } from '@/constants/platform'
import hoistNonReactStatics from 'hoist-non-react-statics'
import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { Animated } from 'react-native'

interface TWithAnimFadeProps {
  style?: StyleProp<ViewStyle>
}

interface TWithAnimFadeState {
  animFade: Animated.Value
}

export default function withAnimFade<P extends TWithAnimFadeProps> (Component: React.ComponentType<P>) {
  class WithAnimFade extends React.Component<P, TWithAnimFadeState> {
    constructor (props: P) {
      super(props)

      this.state = {
        animFade: new Animated.Value(0)
      }
    }

    componentDidMount () {
      Animated.timing(this.state.animFade, {
        duration: 1000,
        toValue: 1,
        useNativeDriver: !IS_WEB
      }).start()
    }

    componentWillUnmount () {
      Animated.timing(this.state.animFade, {
        duration: 1000,
        toValue: 0,
        useNativeDriver: !IS_WEB
      }).start()
    }

    render () {
      const opacityStyle = { opacity: this.state.animFade }
      return (
        <Animated.View style={this.props.style ? [opacityStyle, this.props.style] : opacityStyle}>
          <Component
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(this.props as P)}
          />
        </Animated.View>
      )
    }
  }
  hoistNonReactStatics(WithAnimFade, Component)
  return WithAnimFade
}
