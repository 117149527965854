import { UPDATE_PRODUCT, UPDATE_MESSAGE, ADD_ROW, REMOVE_ROW, RESET_ADVISE } from '../constants/policy_advise'
interface IPolicyAdviseState {
  products: string[]
  message: string
}

export const initialState: IPolicyAdviseState = {
  products: ['', '', ''],
  message: ''
}

export default (
  state = initialState,
  action: {
    type: string
    payload: { index: number, value: string }
  }
): IPolicyAdviseState => {
  switch (action?.type) {
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map((item, index) => {
          if (index === action.payload.index) return action.payload.value
          return item
        })
      }
    case ADD_ROW:
      return {
        ...state,
        products: [...state.products, '']
      }
    case REMOVE_ROW:
      return {
        ...state,
        products: state.products.filter((_, index) => index !== action.payload.index)
      }
    case UPDATE_MESSAGE:
      return {
        ...state,
        message: action.payload.value
      }
    case RESET_ADVISE:
      return {
        message: '',
        products: ['', '', '']
      }
    default:
      return state
  }
}
