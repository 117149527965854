import * as CONSTANTS from '@/redux/constants/user'
import type * as TYPES from '@/redux/types/user'
import type { User } from '@/types/objects'

export const disableFacialRecognition = (): TYPES.DisableAction => ({
  type: CONSTANTS.SET_FACIAL_RECOGNITION,
  value: false
})

export const disableFingerprint = (): TYPES.DisableAction => ({
  type: CONSTANTS.SET_FINGERPRINT,
  value: false
})

export const enableFacialRecognition = (): TYPES.EnableAction => ({
  type: CONSTANTS.SET_FACIAL_RECOGNITION,
  value: true
})

export const enableFingerprint = (): TYPES.EnableAction => ({
  type: CONSTANTS.SET_FINGERPRINT,
  value: true
})

export const resetToken = (): TYPES.ResetTokenAction => ({
  type: CONSTANTS.RESET_TOKEN
})

export const userIdSentToOneSignal = (): TYPES.OneSignalInitializedAction => ({
  type: CONSTANTS.USER_ID_SENT_TO_ONESIGNAL
})

export const setPincode = (pincode: string): TYPES.SetPincodeAction => ({
  pincode,
  type: CONSTANTS.SET_PINCODE
})

export const updatePushToken = (pushToken: string): TYPES.UpdatePushTokenAction => ({
  pushToken,
  type: CONSTANTS.UPDATE_PUSH_TOKEN
})

export const updateToken = (token: string): TYPES.UpdateTokenAction => ({
  token,
  type: CONSTANTS.UPDATE_TOKEN
})

export const updateTokenExpiresAt = (tokenExpiresAt: string): TYPES.UpdateTokenExpiresAtAction => ({
  tokenExpiresAt,
  type: CONSTANTS.UPDATE_TOKEN_EXPIRES_AT
})

export const updateTokenExtendedAt = (tokenExtendedAt: string): TYPES.UpdateTokenExtendedAtAction => ({
  tokenExtendedAt,
  type: CONSTANTS.UPDATE_TOKEN_EXTENDED_AT
})

export const updateUser = (updates: object): TYPES.UpdateUserAction => ({
  type: CONSTANTS.UPDATE_USER,
  updates
})

export const loggedIn = (user: User): TYPES.LoggedInAction => ({
  type: CONSTANTS.LOGGED_IN,
  user
})

export const signInSucces = (entities: TYPES.ISignInResult) => ({
  type: CONSTANTS.SIGN_IN_SUCCESS,
  payload: entities
})

export const logout = () => ({
  type: CONSTANTS.LOGOUT
})

export const updatePassword = (password: string): TYPES.UpdatePasswordAction => ({
  password,
  type: CONSTANTS.UPDATE_PASSWORD
})

export const lock = () => ({
  type: CONSTANTS.LOCK
})
