import { ALLOWED_FILE_TYPES } from '@/constants/files'

export const convertUriToMimeType = (uri: string): string | null => {
  if (!uri) {
    return null
  }

  const extension = uri.split('.').pop() ?? ''
  if (!ALLOWED_FILE_TYPES.includes(extension)) {
    return null
  }

  if (extension === 'jpg') {
    return 'image/jpeg'
  }

  return `image/${extension}`
}
