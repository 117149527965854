/* eslint-disable no-underscore-dangle */

// IMPORTANT
// This is a deprecated implementation for API calls
// Please use @/utils/api_wrapper

import type { CancelToken, CancelTokenSource } from 'axios'
import axios from 'axios'
import { v4 as uuid } from 'uuid'

import Settings from '@/constants/Settings'
import type { Relation, User } from '@/types/objects'
import logger from '@/utils/logger'
import prototype from '@/utils/prototype'

let Platform: any
try {
  // eslint-disable-next-line global-require
  Platform = require('react-native').Platform
} catch (error) {
  // Do nothing with the error because this will only happen when the api util is used outside the React Native environment.
}

interface Params {
  environment?: string
  relationId?: Relation['id']
  token?: User['token']
  userId?: User['id']
}

interface Config {
  cancelToken?: CancelToken
  headers?: { [key: string]: string }
  params?: Params
  withCredentials?: boolean
}

interface ICancelSources {
  [key: string]: CancelTokenSource
}

class Api {
  cancelSources: ICancelSources = {}

  cookies: any = ''

  relationId: Relation['id'] = ''

  token: User['token'] = ''

  userId: User['id'] = ''

  updateConfig = (updates: Partial<Params> = {}): void => {
    this.token = updates?.token || this.token
    this.userId = updates?.userId || this.userId
    this.relationId = updates?.relationId || this.relationId
  }

  reset = (): void => {
    this.token = ''
    this.userId = ''
    this.relationId = ''
  }

  log = (...params: any[]): void => logger.log('Api:', ...params)

  logError = (error: any, paramsPath = ''): void => logger.warn('Api:', paramsPath, String(error), error)

  cancelAll = (): void => {
    this.logError('Canceling all network requests!')

    Object.values(this.cancelSources).forEach((cancelSource: CancelTokenSource) => cancelSource?.cancel())
  }

  setCookie = (response: any): void => {
    // This does not work on Web because the reponse headers are nearly empty because of the CORS policy.
    if (response?.headers['set-cookie']) {
      if (Array.isArray(response.headers['set-cookie'])) {
        this.cookies = response.headers['set-cookie'].join('; ')
      } else if (typeof response.headers['set-cookie'] === 'string') {
        this.cookies = response.headers['set-cookie']
      }
    }
  }

  getConfig = (params: Params = {}, cancelToken: CancelToken | null = null, skipCaseConversion = false): Config => {
    const config: Config = { withCredentials: true }

    if (cancelToken) {
      config.cancelToken = cancelToken
    }

    if (this.cookies) {
      config.headers = {
        Cookie: this.cookies
      }
    }

    config.params = params

    if (this.token && !config.params.token) {
      config.params.token = this.token
    }

    if (this.userId && !config.params.userId) {
      config.params.userId = this.userId
    }

    if (this.relationId && !config.params.relationId) {
      config.params.relationId = this.relationId
    }

    if (Platform?.OS) {
      config.params.environment = Platform.OS
    }

    if (!skipCaseConversion) {
      config.params = prototype.toSnakeCase(config.params)
    }

    return config
  }

  getBody = (object = {}, skipCaseConversion = false) => {
    const body = skipCaseConversion ? object : prototype.toSnakeCase(object)

    if (this.token && !body.token) {
      body.token = this.token
    }

    if (this.userId && !body.userId) {
      body.userId = this.userId
    }

    if (this.relationId && !body.relationId) {
      body.relationId = this.relationId
    }

    return body
  }

  getApiUrl = (path = ''): string => (path ? `${Settings.apiUrl}/${path}` : Settings.apiUrl)

  processResponse = (response: any, defaultValue: any = null) => {
    if (response?.data) {
      // There is a bug in Betty that an html page is returned when something goes wrong on the server.
      if (typeof response.data === 'string') {
        return {
          data: defaultValue,
          statusCode: response.status
        }
      }

      if (prototype.isObject(response.data)) {
        return prototype.toCamelCase(response.data)
      }
    }

    return null
  }

  processError = (error: any, defaultValue: any = null) => this.processResponse(error?.response, defaultValue)

  setCancelSourceForRequest = (cancelSource: CancelTokenSource | null): string => {
    const source: CancelTokenSource = cancelSource || axios?.CancelToken?.source()

    const id = uuid()
    this.cancelSources[id] = source

    return id
  }

  deleteRequest = async (path: any, params = {}, cancelSource: CancelTokenSource | null = null) => {
    let result

    const cancelSourceId = this.setCancelSourceForRequest(cancelSource)

    try {
      const url = this.getApiUrl(path)
      const config = this.getConfig(params, cancelSource?.token)
      this.log('delete', url, config)
      const response = await axios.delete(url, config)
      this.log('delete response', url, response)
      this.setCookie(response)
      result = this.processResponse(response)
    } catch (error) {
      this.logError(error, path)
      result = this.processError(error, false)
    }

    delete this.cancelSources[cancelSourceId]
    return result
  }

  getRequest = async (path: any, params = {}, cancelSource: CancelTokenSource | null = null) => {
    let result

    const cancelSourceId = this.setCancelSourceForRequest(cancelSource)

    try {
      const url = this.getApiUrl(path)
      const config = this.getConfig(params, cancelSource?.token)
      this.log('get', url, config)
      const response = await axios.get(url, config)
      this.log('get response', url, response)
      this.setCookie(response)
      result = this.processResponse(response)
    } catch (error) {
      this.logError(error, path)
      result = this.processError(error, null)
    }

    delete this.cancelSources[cancelSourceId]
    return result
  }

  putRequest = async (path: any, object: any, params = {}, cancelSource: CancelTokenSource | null = null) => {
    let result

    const cancelSourceId = this.setCancelSourceForRequest(cancelSource)

    try {
      const body = this.getBody(object)
      const url = this.getApiUrl(path)
      const config = this.getConfig(params, cancelSource?.token)
      this.log('put', url, body, config)
      const response = await axios.put(url, body, config)
      this.log('put response', url, response)
      this.setCookie(response)
      result = this.processResponse(response)
    } catch (error) {
      this.logError(error, path)
      result = this.processError(error, false)
    }

    delete this.cancelSources[cancelSourceId]
    return result
  }

  postRequest = async (
    path: any,
    object: any,
    params = {},
    cancelSource: CancelTokenSource | null = null,
    skipCaseConversion = false
  ) => {
    let result

    const cancelSourceId = this.setCancelSourceForRequest(cancelSource)

    try {
      const body = this.getBody(object, skipCaseConversion)
      const url = this.getApiUrl(path)
      const config = this.getConfig(params, cancelSource?.token, skipCaseConversion)
      this.log('post', url, body, config)
      const response = await axios.post(url, body, config)
      this.log('post response', url, response)
      this.setCookie(response)
      result = this.processResponse(response)
    } catch (error) {
      this.logError(error, path)
      result = this.processError(error, false)
    }

    delete this.cancelSources[cancelSourceId]
    return result
  }
}

export default new Api()
