import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { View } from 'react-native'
import Bar from 'react-native-progress/Bar'

import Dot from '@/components/Dot'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'

interface TProgressBarProps {
  progress?: number
  style?: StyleProp<ViewStyle>
}

export default function ProgressBar (props: TProgressBarProps) {
  return (
    <View style={props.style}>
      <Bar
        borderRadius={5}
        borderWidth={0}
        color={Colors.success}
        height={Sizes.progressBarHeight}
        progress={props.progress}
        unfilledColor={Colors.e8}
        width={null}
      />
      {props.progress === 0
        ? (
          <Dot
            color={Colors.success}
            size={Sizes.progressBarHeight}
            style={Styling.absolute}
          />
          )
        : null}
    </View>
  )
}
