import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import { decode } from 'html-entities'

import Button from '@/components/buttons/Button'
import ButtonGreenCard from '@/components/buttons/ButtonGreenCard'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import { startClaimDamageForm } from '@/redux/actions/claims'
import { openModalEmergencyNumber } from '@/redux/actions/modals'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import common from '@/utils/common'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import type { ThemeColors } from '@/redux/reducers/theme'
import type { Relation, User } from '@/types/objects'
import Image from '@/components/images/image'
import date from '@/utils/date'
import OneSignal from 'react-native-onesignal'
import { userIdSentToOneSignal } from '../../redux/actions/user'
import { apiCall } from '../../redux/actions/api'
import FlashNews from './components/flash_news'

const intervalDuration = 60000

interface TOverviewProps {
  colors: ThemeColors
  openModalEmergencyNumber: typeof openModalEmergencyNumber
  startClaimDamageForm: typeof startClaimDamageForm
  userIdSentToOneSignal: typeof userIdSentToOneSignal
  apiCall: typeof apiCall
  user: User
  relation: Relation
  showMemberBenefitsLink: boolean
  shouldOpenNotification: boolean
}

interface TOverviewState {
  hours: number
}

class Overview extends React.Component<TOverviewProps, TOverviewState> {
  interval: NodeJS.Timer | undefined

  constructor (props: TOverviewProps) {
    super(props)

    this.state = {
      hours: new Date().getHours()
    }
  }

  componentDidMount () {
    const { user } = this.props
    if (user && !user.userIdSentToOneSignal) {
      OneSignal.setExternalUserId(user.id)
      this.props.userIdSentToOneSignal()
    }

    this.props.apiCall('GET', 'POLICIES')
    this.props.apiCall('GET', 'WTD')
    this.props.apiCall('GET', 'MEMBER_BENEFITS')
    this.props.apiCall('GET', 'FLASH_NEWS', undefined, undefined, true)

    this.interval = setInterval(() => {
      this.setState({ hours: new Date().getHours() })
    }, intervalDuration)
  }

  componentWillUnmount () {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = undefined
    }
  }

  startClaimDamageForm = () => this.props.startClaimDamageForm()

  render () {
    if (this.props.shouldOpenNotification) {
      void navigation.openScreenNotifications()
    }

    return (
      <View style={styles.container}>
        <View style={styles.greetingContainer}>
          <View style={styles.greeting}>
            <Text
              type='greeting'
              style={{ fontSize: 22 }}
            >
              {common.determineGreeting(this.state.hours)},
            </Text>

            <Text
              type='title'
              style={{ fontSize: 26 }}
            >
              {decode(common.determineName(this.props.user))}
            </Text>
          </View>

          <View style={styles.logoContainer}>
            <Image
              uri={`${this.props.relation?.logo}?${date.now()}`}
              style={styles.logo}
            />
          </View>
        </View>
        <View style={Styling.flex}>
          <View style={styles.block} />
          <Screen
            noBreadCrumbs
            backgroundColor='transparent'
          >
            <Button
              icon='fragile'
              iconCircleColor={Colors.white}
              onPress={this.startClaimDamageForm}
              style={Styling.marginBottom}
              title={i18n.t('Schade melden')}
            />

            <Button
              color={Colors.white}
              icon='copy'
              iconCircleColor={this.props.colors?.lightBrand}
              iconColor={this.props.colors?.brand}
              onPress={navigation.openScreenPolicies}
              style={Styling.marginBottom}
              title={i18n.t('Verzekeringen')}
              titleColor={Colors.gray}
            />

            {this.props.relation.cooperationMembership && (
              <Button
                color={Colors.white}
                icon='tag'
                iconCircleColor={this.props.colors?.lightBrand}
                iconColor={this.props.colors?.brand}
                onPress={navigation.openScreenMemberBenefits}
                style={Styling.marginBottom}
                title={i18n.t('Ledenvoordelen')}
                titleColor={Colors.gray}
              />
            )}
            {Platform.OS === 'web'
              ? (
                <Button
                  color={Colors.white}
                  icon='copy'
                  iconCircleColor={this.props.colors?.lightBrand}
                  iconColor={this.props.colors?.brand}
                  onPress={navigation.openScreenPolicies}
                  style={Styling.marginBottom}
                  title={i18n.t('Verzekeringen')}
                  titleColor={Colors.gray}
                />
                )
              : null}

            <Button
              color={Colors.white}
              icon='tools'
              iconCircleColor={this.props.colors?.lightBrand}
              iconColor={this.props.colors?.brand}
              // @todo: NH1816-18486
              // onPress={navigation.openScreenDamageRepair}
              onPress={navigation.openScreenDamageRepairVehicle}
              style={Styling.marginBottom}
              title={i18n.t('Zoek schadehersteller')}
              titleColor={Colors.gray}
            />

            <ButtonGreenCard style={Styling.marginBottom} />

            <Button
              color={Colors.white}
              icon='phone-plus'
              iconCircleColor={Colors.darkRed}
              iconColor={Colors.white}
              onPress={this.props.openModalEmergencyNumber}
              title={i18n.t('Bel het noodnummer')}
              titleColor={Colors.gray}
            />

            <FlashNews />
          </Screen>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  block: {
    backgroundColor: Colors.white,
    height: Sizes.screenPaddingVertical + Sizes.button / 2,
    left: 0,
    position: 'absolute',
    right: 0
  },
  container: {
    backgroundColor: Colors.background,
    flex: 1
  },
  greetingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.white,
    paddingHorizontal: Sizes.spacingHorizontalMedium,
    paddingTop: Sizes.spacingVerticalMedium
  },
  greeting: {
    flex: 1
  },
  logoContainer: {
    width: 110,
    height: 110,
    borderRadius: 55,
    borderWidth: 1,
    borderColor: Colors.cc
  },
  logo: {
    flex: 1,
    resizeMode: 'contain',
    margin: 10
  }
})

const mapStateToProps = (state: TState) => {
  return {
    colors: selectors.getThemeColors(state),
    user: selectors.getUser(state),
    relation: selectors.getCurrentRelation(state),
    shouldOpenNotification: selectors.getShouldOpenNotifications(state)
  }
}

const mapDispatchToProps = {
  openModalEmergencyNumber,
  startClaimDamageForm,
  userIdSentToOneSignal,
  apiCall
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
