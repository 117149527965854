import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { Keyboard } from 'react-native'
import { apiCall } from '@/redux/actions/api'
import ContextBreakpoint, { isSmallScreen } from '@/bootstrap/ContextBreakpoint'
import Screen from '@/components/Screen'
import { Column } from '@/bootstrap'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import i18n from '@/utils/i18n'
import Button from '@/components/buttons/Button'
import Input from '@/components/inputs/Input'
import common from '@/utils/common'
import Toast from '@/components/Toast'
import type { State } from '@/redux/reducers'
import Block from '@/components/Block'
import alert from '@/utils/alert'

const AddRelationToCombi: React.FC = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const breakpoint = useContext(ContextBreakpoint)
  const isPhone = isSmallScreen(breakpoint)

  const [email, setEmail] = useState<string>('')
  const [combiNumber, setCombiNumber] = useState<string>('')
  const inputValid = common.validateEmail(email) && combiNumber !== '' && combiNumber?.length > 4

  const { loading, success, error } = useSelector((state: State) => state?.relations?.addRelationToCombi) || {}

  const addRelation = () => {
    Keyboard.dismiss()
    setEmail(email.toLowerCase())
    dispatch(apiCall('POST', 'RELATION', undefined, { addCombiNumber: combiNumber, email: email.toLowerCase() }))
  }

  useEffect(() => {
    if (success && email !== '' && combiNumber !== '') {
      alert.showSingle(
        i18n.t('Gevonden!'),
        i18n.t(
          'Er wordt een bevestigingsmail verstuurd naar het opgegeven e-mailadres (check ook spam). Na “akkoord” gegeven in deze e-mail, zal de combipolis na één dag zichtbaar zijn in uw account.'
        ),
        { text: 'Ok', onPress: () => navigation.goBack() }
      )
    }
  }, [success])

  useEffect(() => {
    if (error) {
      dispatch({ type: 'REMOVE_ADD_RELATION_ERROR' })
    }
  }, [email, combiNumber])

  const Wrapper = isPhone ? React.Fragment : Block

  return (
    <Screen white={isPhone}>
      <Wrapper noFlex>
        <Column span={{ lg: 9, md: 12, sm: 12, xl: 6, xs: 12 }}>
          <Text
            style={Styling.marginBottom}
            type='subtitle'
          >
            {i18n.t('Combipolis toevoegen')}
          </Text>

          <Input
            label={i18n.t('Combipolisnummer')}
            tooltip={i18n.t(
              'Het combipolisnummer vindt u bovenaan uw polisblad of kunt u opvragen bij uw verzekeringsadvisuer.'
            )}
            onChange={setCombiNumber}
            value={combiNumber}
            keyboardType='numeric'
          />

          <Input
            label={i18n.t('E-mailadres')}
            tooltip={i18n.t(
              'Het e-mailadres dat is gekoppeld aan het combipolisnummer. Op dit e-mailadres ontvangt u een verificatiemail.'
            )}
            onChange={setEmail}
            value={email}
            keyboardType='email-address'
            autoCapitalize='none'
          />

          {error
            ? (
              <Toast
                error
                message={i18n.t('Combipolisnummer en/of E-mailadres combinatie komt niet overeen')}
                style={Styling.marginBottom}
              />
              )
            : null}

          <Button
            title={i18n.t('Verifiëren')}
            onPress={addRelation}
            disabled={!inputValid || loading || error}
            loading={loading}
          />
        </Column>
      </Wrapper>
    </Screen>
  )
}

export default AddRelationToCombi
