import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import Icon from '@/components/Icon'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import type { IconProp } from '@/types/props'

interface TButtonCircleProps {
  children?: React.ReactNode
  color?: string
  disabled?: boolean
  icon?: IconProp
  onPress?: () => void
  small?: boolean
  style?: StyleProp<ViewStyle>
  textColor?: string
  textType?: string
  title?: string
}

export default function ButtonCircle (props: TButtonCircleProps) {
  let backgroundStyle
  if (props.disabled) {
    backgroundStyle = styles.disabled
  } else if (props.color) {
    backgroundStyle = { backgroundColor: props.color }
  }
  const iconContainerStyle = props.small ? styles.iconContainerSmall : styles.iconContainer
  let textType
  if (props.textType) {
    textType = props.textType
  } else {
    textType = props.disabled ? 'disabled' : 'paragraph'
  }
  return (
    <TouchableOpacity
      disabled={props.disabled || !props.onPress}
      onPress={props.onPress}
      style={props.style ? [Styling.itemsCenter, props.style] : Styling.itemsCenter}
    >
      <View style={backgroundStyle ? [iconContainerStyle, backgroundStyle] : iconContainerStyle}>
        {props.children || (
          <Icon
            color={Colors.white}
            icon={props.icon}
            size={props.small ? Sizes.iconNormal : Sizes.icon}
          />
        )}
      </View>
      {props.title
        ? (
          <Text
            color={props.textColor}
            style={Styling.marginTopTiny}
            type={textType}
          >
            {props.title}
          </Text>
          )
        : null}
    </TouchableOpacity>
  )
}

const iconContainerStyle = {
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden'
}

const styles = StyleSheet.create({
  disabled: {
    backgroundColor: Colors.c1
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"hidden" ... Remove this comment to see the full error message
  iconContainer: {
    ...iconContainerStyle,
    borderRadius: Sizes.circleNormal / 2,
    height: Sizes.circleNormal,
    width: Sizes.circleNormal
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"hidden" ... Remove this comment to see the full error message
  iconContainerSmall: {
    ...iconContainerStyle,
    borderRadius: Sizes.circleSmall / 2,
    height: Sizes.circleSmall,
    width: Sizes.circleSmall
  }
})
