import React from 'react'

import * as Bootstrap from '@/bootstrap'
import Card from '@/components/Card'

export interface ICard {
  icon: string
  id: string
  onPress: () => void
  title: string
  disabled?: boolean
}

interface IProps {
  cards: ICard[]
  id?: string
}

const Cards: React.FC<IProps> = (props) => (
  <Bootstrap.Row
    gutterHorizontal={15}
    gutterVertical={15}
  >
    {(props.cards || []).map((card) => (
      <Bootstrap.Column
        key={`${props.id}-column-${card.id || card.title}`}
        span={{ lg: 6, md: 6, sm: 12, xl: 4, xs: 12 }}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Card {...card} />
      </Bootstrap.Column>
    ))}
  </Bootstrap.Row>
)

export default Cards
