import React, { useEffect } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import Text from '@/components/wrappers/Text'

import EmptyState from '@/components/EmptyState'
import Screen from '@/components/Screen'
import { getMemberBenefits } from '@/redux/selectors/member_benefits'

import MemberBenefitListItemPhone from './components/list_item.phone'
import MemberBenefitListItemTablet from './components/list_item.tablet'

import { getCurrentRelation } from '@/redux/selectors/relations'
import Colors from '@/constants/Colors'
import i18n from '@/utils/i18n'
import Button from '@/components/buttons/Button'
import navigation from '@/utils/navigation'
import selectors from '@/redux/selectors'
import { ENTITIES } from '@/redux/constants'

import * as Bootstrap from '@/bootstrap'

import { apiCall } from '@/redux/actions/api'

const MemberBenefits: React.FC = () => {
  const dispatch = useDispatch()

  const refreshing = !!useSelector(selectors.isLoading(ENTITIES.MEMBER_BENEFITS))

  const currentRelation = useSelector(getCurrentRelation)
  const memberBenefits = useSelector(getMemberBenefits)

  const refresh = () => {
    dispatch(apiCall('GET', ENTITIES.MEMBER_BENEFITS, undefined, undefined, true))
  }

  const renderEmptyState = () => (
    <EmptyState
      HTML={!refreshing}
      message={refreshing
        ? i18n.t('De ledenvoordelen worden opgehaald')
        : `<p>Er zijn momenteel geen ledenvoordelen beschikbaar in de ${Platform.OS === 'web' ? 'Mijn Omgeving' : 'app'}. Klik op <a href="https://www.nh1816.nl/ledenvoordeel">nh1816.nl/ledenvoordeel</a> voor actuele ledenvoordelen.</p>`}
    />
  )

  useEffect(() => {
    refresh()
  }, [])

  if (!currentRelation?.cooperationMembership) {
    return (
      <Screen>
        <View style={styles.container}>
          <Text
            style={styles.membershipMessage}
            type='description'
          >
            {i18n.t('U heeft zich afgemeld als lid van de Coöperatie Nh1816. U kunt hierdoor geen gebruikmaken van ledenvoordelen. Wilt u wel gebruikmaken van ledenvoordelen? Meld u dan aan als lid van de coöperatie bij uw voorkeuren.')}
          </Text>

          <Button
            title={i18n.t('Wijzig voorkeuren')}
            onPress={async () => await navigation.openScreenSettings()}
          />
        </View>
      </Screen>
    )
  }

  const renderPhone = () => (
    <>
      {memberBenefits.data.length
        ? memberBenefits?.data?.map(MemberBenefitListItemPhone)
        : renderEmptyState()}
    </>
  )

  const renderTablet = () => (
    <Bootstrap.Row
      gutterHorizontal={15}
      gutterVertical={15}
    >
      {memberBenefits?.data?.length
        ? memberBenefits.data.map((benefit) => (
          <Bootstrap.Column
            key={benefit.id}
            span={{ lg: 6, md: 6, sm: 6, xl: 4, xs: 12 }}
          >
            <MemberBenefitListItemTablet memberBenefit={benefit} />
          </Bootstrap.Column>
        ))
        : renderEmptyState()}
    </Bootstrap.Row>
  )

  return (
    <Screen
      useRefreshControl
      onRefresh={refresh}
      refreshing={refreshing}
    >
      <Bootstrap.Switch
        renderPhone={renderPhone}
        renderTablet={renderTablet}
      />
    </Screen>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    padding: 25
  },
  membershipMessage: {
    marginBottom: 20
  }
})

export default MemberBenefits
