import React from 'react'
import { Image } from 'react-native'

import Styling from '@/constants/Styling'

interface TImageBannerProps {
  height: number
  uri?: string
}

export default function ImageBanner ({ height, uri, ...props }: TImageBannerProps) {
  return (
    <Image
      resizeMode='contain'
      // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'ImageS... Remove this comment to see the full error message
      source={uri && { uri }}
      style={[Styling.image, { height }]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}
