import React from 'react'
import { Platform } from 'react-native'

import * as Linking from 'expo-linking'
import hoistNonReactStatics from 'hoist-non-react-statics'

export default function withHandleUrl<P extends object> (Component: React.ComponentType<P>) {
  class WithHandleUrl extends React.Component<P> {
    ref = React.createRef<any>()

    state = {
      linkingListener: null
    }

    componentDidMount () {
      if (Platform.OS !== 'web') {
        this.getInitialUrl()
        this.listenToUrl()
      }
    }

    componentWillUnmount () {
      if (Platform.OS !== 'web') {
        this.state.linkingListener?.remove?.()
      }
    }

    public getInitialUrl = () => {
      Linking.getInitialURL().then(this.handleUrl)
    }

    public listenToUrl = () => {
      this.setState({
        linkingListener: Linking.addEventListener('url', ({ url }) => this.handleUrl(url))
      })
    }

    private readonly handleUrl = (url: string | null) => {
      if (url) {
        const parsed = Linking.parse(url)
        this?.ref?.current?.handleUrl(parsed)
      }
    }

    render () {
      return (
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(this.props as P)}
          ref={this.ref}
        />
      )
    }
  }

  hoistNonReactStatics(WithHandleUrl, Component)

  return WithHandleUrl
}
