import React, { useContext } from 'react'
import type { RefreshControlProps, StyleProp, ViewStyle } from 'react-native'
import { Platform, StyleSheet, View } from 'react-native'

import ContextBreakpoint from '@/bootstrap/ContextBreakpoint'
import BreadCrumbs from '@/components/BreadCrumbs'
import ErrorBoundary from '@/components/ErrorBoundary'
import type { TScrollViewProps } from '@/components/wrappers/ScrollView'
import ScrollView from '@/components/wrappers/ScrollView'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import RefreshControl from './wrappers/RefreshControl'

interface TScreenProps {
  backgroundColor?: string
  children?: React.ReactNode
  contentContainerStyle?: StyleProp<ViewStyle>
  id?: string
  noBreadCrumbs?: boolean
  noPadding?: boolean
  noScrollView?: boolean
  onlyChildren?: boolean
  persistentScrollbar?: boolean
  refreshControl?: React.ReactElement<RefreshControlProps, string | React.JSXElementConstructor<any>>
  renderBreadCrumbs?: (...args: any[]) => any
  scrollEnabled?: boolean
  scrollViewRef?: TScrollViewProps['scrollViewRef']
  style?: StyleProp<ViewStyle>
  white?: boolean

  useRefreshControl?: boolean
  refreshing?: boolean
  onRefresh?: () => void
}

export default function Screen (props: TScreenProps) {
  const breakpoint = useContext(ContextBreakpoint)
  if (props.onlyChildren) {
    return <ErrorBoundary>{props.children}</ErrorBoundary>
  }
  let backgroundStyle
  if (props.backgroundColor) {
    backgroundStyle = { backgroundColor: props.backgroundColor }
  } else if (props.white) {
    backgroundStyle = Styling.backgroundWhite
  } else {
    backgroundStyle = Styling.background
  }
  function renderBreadCrumbs () {
    if (!props.noBreadCrumbs && (Platform.OS === 'web' || (breakpoint !== 'xxs' && breakpoint !== 'xs'))) {
      let style
      if (breakpoint === 'xxs' || breakpoint === 'xs') {
        style = props.noPadding ? styles.breadCrumbsPhoneExcluded : styles.breadCrumbsPhoneIncluded
      } else {
        style = props.noPadding ? styles.breadCrumbsTablet : Styling.marginBottomMedium
      }
      if (typeof props.renderBreadCrumbs === 'function') {
        return props.renderBreadCrumbs({ style })
      }
      return <BreadCrumbs style={style} />
    }
    return null
  }
  if (props.noScrollView) {
    const containerStyle = props.noPadding ? Styling.flex : styles.container
    return (
      <ErrorBoundary>
        <View
          id={props.id}
          style={[backgroundStyle, containerStyle, props.style]}
        >
          {renderBreadCrumbs()}
          {props.children}
        </View>
      </ErrorBoundary>
    )
  }
  const contentContainerStyle = props.noPadding ? Styling.flexGrow : styles.contentContainer

  let refreshControl = props.refreshControl

  if (props.useRefreshControl && props.onRefresh) {
    refreshControl = (
      <RefreshControl
        onRefresh={props.onRefresh}
        refreshing={props.refreshing ?? false}
      />
    )
  }

  return (
    <ErrorBoundary>
      <ScrollView
        contentContainerStyle={
          props.contentContainerStyle ? [contentContainerStyle, props.contentContainerStyle] : contentContainerStyle
        }
        id={props.id}
        persistentScrollbar={props.persistentScrollbar}
        refreshControl={refreshControl}
        scrollEnabled={props.scrollEnabled}
        scrollViewRef={props.scrollViewRef}
        style={props.style ? [backgroundStyle, props.style] : backgroundStyle}
      >
        {renderBreadCrumbs()}
        {props.children}
      </ScrollView>
    </ErrorBoundary>
  )
}

const styles = StyleSheet.create({
  breadCrumbsPhoneExcluded: {
    marginHorizontal: Sizes.spacingHorizontal,
    marginTop: Sizes.spacingVertical
  },
  breadCrumbsPhoneIncluded: {
    marginBottom: Sizes.screenPaddingVertical,
    marginLeft: Sizes.screenPaddingHorizontal / -2,
    marginTop: Sizes.screenPaddingVertical / -2
  },
  breadCrumbsTablet: {
    marginHorizontal: Sizes.screenPaddingHorizontal,
    marginTop: Sizes.screenPaddingVertical
  },
  container: {
    flex: 1,
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingVertical: Sizes.screenPaddingVertical
  },
  contentContainer: {
    flexGrow: 1,
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingVertical: Sizes.screenPaddingVertical
  }
})
