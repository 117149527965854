import type { TButtonProps } from '@/components/buttons/Button'
import Button from '@/components/buttons/Button'
import { useSelector } from 'react-redux'
import { getThemeColors } from '@/redux/selectors/theme'
import Colors from '@/constants/Colors'

const ButtonOutline = (props: TButtonProps) => {
  const themeColors = useSelector(getThemeColors)

  return (
    <Button
      {...props}
      borderColor={themeColors?.brand}
      color={Colors.transparent}
      titleColor={themeColors?.brand}
    />
  )
}

export default ButtonOutline
