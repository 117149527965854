import type { Claim } from '@/types/objects'
import prototype from '@/utils/prototype'
import type { TApiCallDispatch } from '../actions/api'

export interface IClaimsState {
  data: Claim[]
  isAvailable: boolean
  errorMessage: string
}

export const initialState: IClaimsState = {
  data: [],
  isAvailable: true,
  errorMessage: ''
}

export default (state: IClaimsState = initialState, action: TApiCallDispatch): IClaimsState => {
  switch (action?.type) {
    case 'GET_CLAIMS_SUCCESS':
      return {
        ...state,
        data: action.payload.data as Claim[],
        isAvailable: true,
        errorMessage: ''
      }
    case 'DELETE_CLAIM_SUCCESS':
      return {
        ...state,
        data: state.data.filter((claim) => claim.id !== action.payload.id),
        isAvailable: true,
        errorMessage: ''
      }

    case 'GET_CLAIM_SUCCESS':
      return {
        ...state,
        data: prototype.mergeObjectInArray(state.data, action.payload.data as Claim, 'id'),
        isAvailable: true,
        errorMessage: ''
      }

    case 'SERVICE_UNAVAILABLE':
      return {
        ...state,
        isAvailable: false,
        errorMessage: action.payload.data as string
      }

    case 'SELECT_RELATION':
      return initialState

    default:
      return state
  }
}
