import Config from 'react-native-config'

const environment = Config.APP_ENV ?? 'default'
const apiUrl = Config.API_URL ?? 'default'

const claimUrl = (): string => {
  switch (Config.APP_ENV) {
    case 'acceptance':
      return 'https://claim-acc.portal.nh1816.nl'
    case 'development':
      return 'https://claim-dev.portal.nh1816.nl'
    case 'production':
    default:
      return 'https://claim.portal.nh1816.nl'
  }
}

const supportedOrientations: (
  | 'portrait'
  | 'landscape'
  | 'landscape-left'
  | 'landscape-right'
  | 'portrait-upside-down'
)[] = ['portrait', 'landscape', 'landscape-left', 'landscape-right']

export default {
  apiUrl,
  claimUrl: claimUrl(),
  claimDamageQuestionsLifespan: 60 * 60 * 1000, // Milliseconds
  defaultLocale: 'nl',
  emergencyNumber: '+31 88 112 1816',
  environment,
  maximumFileSize: 10 * 1000 * 1000, // 10 MB
  pinCodeLength: 5,
  pinCodeTries: 3,
  regionMiddleOfHolland: {
    latitude: 52.1326,
    latitudeDelta: 3,
    longitude: 5.2913,
    longitudeDelta: 3
  },
  regionNh1816: {
    latitude: 52.7123209,
    latitudeDelta: 0.5,
    longitude: 4.8068634,
    longitudeDelta: 0.5
  },
  supportedOrientations,
  // This interval ensures that the necessary data is synced at least every week.
  syncInterval: 7 * 24 * 60 * 60 * 1000, // Milliseconds
  webUrl: 'https://mijn.nh1816.nl'
}
