import React from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import EmptyState from '@/components/EmptyState'
import Screen from '@/components/Screen'
import RefreshControl from '@/components/wrappers/RefreshControl'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import { startClaimDamageForm } from '@/redux/actions/claims'
import { apiCall } from '@/redux/actions/api'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import ListItemClaim from '@/screens/claims/components/ListItemClaim'
import type { Claim, IHistoricalClaim } from '@/types/objects'
import alert from '@/utils/alert'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import { ENTITIES } from '@/redux/constants'

interface TClaimsProps {
  claims: Claim[]
  pendingClaims: Claim[]
  concludedClaims: Claim[]
  refreshing: boolean
  isAvailable: boolean
  claimServiceErrorMessage: string
  apiCall: typeof apiCall
  startClaimDamageForm: typeof startClaimDamageForm
}

class Claims extends React.Component<TClaimsProps> {
  componentDidMount () {
    this.refresh()
  }

  refresh = () => {
    this.props.apiCall('GET', 'CLAIMS', undefined, undefined, true)
  }

  keyExtractor = (claim: Claim) => `list-item-claim-${claim.id}`

  deleteClaimOnPress = (claim: Claim): void => {
    alert.showDouble(
      i18n.t('Verwijderen'),
      i18n.t('Weet u zeker dat u de concept schademelding wilt verwijderen?'),
      {
        onPress: () => false,
        style: 'cancel',
        text: i18n.t('Nee')
      },
      {
        onPress: () => {
          this.props.apiCall('DELETE', ENTITIES.CLAIM, claim.id)
        },
        text: i18n.t('Ja')
      }
    )
  }

  onPressClaim = (claim: Claim | IHistoricalClaim): void => {
    navigation.openScreenClaim(claim as Claim)
  }

  renderItem = ({ item }: { item: Claim }) => (
    <ListItemClaim
      claim={item}
      onPress={this.onPressClaim}
      removeClaim={this.deleteClaimOnPress}
    />
  )

  renderRefreshControl = () => (
    <RefreshControl
      onRefresh={this.refresh}
      refreshing={this.props.refreshing}
      title={i18n.t('Gegevens van de schademeldingen aan het ophalen')}
    />
  )

  renderScene = (): JSX.Element => {
    const claims = this.props.isAvailable ? this.props.claims : []
    return claims?.length
      ? (
        <Bootstrap.Collection
          data={claims}
          gutter={15}
          id='claims-phone-collection'
          keyExtractor={this.keyExtractor}
          margin={0}
          renderItem={this.renderItem}
          span={{ lg: 6, md: 6, sm: 6, xl: 6, xs: 12 }}
        />
        )
      : (
        <EmptyState
          message={this.props.refreshing
            ? i18n.t('Uw schades worden opgehaald')
            : this.props.isAvailable ? i18n.t('U heeft geen lopende of historische schades.') : this.props.claimServiceErrorMessage}
        />
        )
  }

  render () {
    return (
      <Screen
        noBreadCrumbs
        refreshControl={this.renderRefreshControl()}
      >
        <View style={styles.container}>
          <Button
            icon='fragile'
            iconCircleColor={Colors.white}
            onPress={() => this.props.startClaimDamageForm()}
            title={i18n.t('Schade melden')}
          />
        </View>

        {this.renderScene()}
      </Screen>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.background,
    paddingBottom: Sizes.screenPaddingVertical
  }
})

const mapStateToProps = (state: TState) => ({
  claims: selectors.getClaims(state),
  concludedClaims: selectors.getConcludedClaims(state),
  pendingClaims: selectors.getPendingClaims(state),
  refreshing: !!selectors.isLoading('CLAIMS')(state),
  isAvailable: selectors.getClaimServiceAvailability(state),
  claimServiceErrorMessage: selectors.getClaimErrorMessage(state)
})

const mapDispatchToProps = {
  apiCall,
  startClaimDamageForm
}

export default connect(mapStateToProps, mapDispatchToProps)(Claims)
