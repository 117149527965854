import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

interface TLineProps {
  color: string
  height?: number
  horizontal?: boolean
  style?: StyleProp<ViewStyle>
  vertical?: boolean
  width?: number
}

export default function Line (props: TLineProps) {
  if (props.horizontal) {
    return (
      <View
        style={[
          styles.containerHorizontal,
          props.width !== undefined && { width: props.width },
          props.height !== undefined && { height: props.height },
          props.color && { backgroundColor: props.color },
          props.style
        ]}
      />
    )
  }
  if (props.vertical) {
    return (
      <View
        style={[
          styles.containerVertical,
          props.width !== undefined && { width: props.width },
          props.height !== undefined && { height: props.height },
          props.color && { backgroundColor: props.color },
          props.style
        ]}
      />
    )
  }
  return null
}

const styles = StyleSheet.create({
  containerHorizontal: {
    height: 1,
    width: '100%'
  },
  containerVertical: {
    height: '100%',
    width: 1
  }
})
