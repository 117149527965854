import React from 'react'
import { connect } from 'react-redux'

import Text from '@/components/wrappers/Text'
import mapStateToProps from '@/redux/mapStateToProps'

interface TTextHighlightProps {
  children?: React.ReactNode
  colors: any
  query: string
  type: string
}

function TextHighlight (props: TTextHighlightProps) {
  if (typeof props.children === 'string') {
    // Use null as a negative default value because using an empty string gives 0 as result instead of -1.
    // @ts-expect-error ts-migrate(2345) FIXME: Type 'null' is not assignable to type 'string'.
    const index = props.children?.toLowerCase()?.indexOf(props.query || null)
    if (index > -1) {
      return (
        <Text type={props.type}>
          {props.children.slice(0, index)}
          <Text
            color={props.colors && props.colors.brand}
            type={props.type}
          >
            {props.children.substr(index, props.query.length)}
          </Text>
          {props.children.slice(index + props.query.length, props.children.length)}
        </Text>
      )
    }
  }
  return <Text type={props.type}>{props.children}</Text>
}

export default connect(mapStateToProps.themeColors)(TextHighlight)
