import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet } from 'react-native'
import { connect } from 'react-redux'

import Icon from '@/components/Icon'
import Touchable from '@/components/touchables/Touchable'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import mapStateToProps from '@/redux/mapStateToProps'
import type { ThemeColors } from '@/redux/reducers/theme'
import type { IconProp } from '@/types/props'

interface TCardProps {
  colors: ThemeColors
  icon?: IconProp
  iconComponent?: React.ReactElement | ((...args: any[]) => any)
  onPress?: (...args: any[]) => any
  style?: StyleProp<ViewStyle>
  title: string
  disabled?: boolean
}

function Card (props: TCardProps) {
  return (
    <Touchable
      onPress={props.onPress}
      style={[
        styles.container,
        props.style,
        props.disabled && { backgroundColor: Colors.ed }
      ]}
      disabled={props.disabled}
    >
      <Icon
        large
        circleColor={props.colors?.lightBrand}
        color={props.colors?.brand}
        Component={props.iconComponent}
        icon={props.icon}
        style={Styling.marginBottom}
      />
      <Text
        style={Styling.textCenter}
        type='description'
      >
        {props.title}
      </Text>
    </Touchable>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: Sizes.borderRadius,
    flex: 1,
    overflow: 'hidden',
    paddingHorizontal: Sizes.spacingHorizontalMedium,
    paddingVertical: Sizes.spacingVerticalMedium
  }
})

export default connect(mapStateToProps.themeColors)(Card)
