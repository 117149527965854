import type { Relation, User } from '@/types/objects'
import api from '@/utils/api_wrapper'
import common from '@/utils/common'
import { parseEmailAddress } from '@/utils/email'

import type { IAddUserParams } from '../types/user'

export const getUser = async (relationId: Relation['id'], userId: User['id']) =>
  await api.get(`relations/${relationId}/users/${userId}`)

export const updateUser = async (relationId: Relation['id'], userId: User['id'], user: User, updates: any) =>
  await api.put(`relations/${relationId}/users/${userId}`, { data: { ...user, ...updates } })

export const updatePushToken = async (
  relationId: Relation['id'],
  userId: User['id'],
  user: User,
  pushToken: User['pushToken']
) => await updateUser(relationId, userId, user, { pushToken })

export const addUser = async (relationId: Relation['id'], data: IAddUserParams) =>
  await api.post(`relations/${relationId}/users`, {
    data,
    registerUrl: common.determineRedirectUrl('home', {
      action: 'register',
      email: parseEmailAddress(data?.email)
    })
  })

export const resendInvite = async (relationId: Relation['id'], selectedUserId: User['id']) =>
  await api.post(`/relations/${relationId}/users/${selectedUserId}/resend-invite`, {
    selected_user_id: `${selectedUserId}`
  })

export const deleteUser = async (relationId: Relation['id'], deleteUserId: User['id']) =>
  await api.delete(`relations/${relationId}/users/${deleteUserId}`)
