import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { View } from 'react-native'

import Amount from '@/components/Amount'
import Text from '@/components/wrappers/Text'

interface TItemQuestionResultProps {
  amount?: boolean
  currency?: boolean
  description: string
  label: string
  style?: StyleProp<ViewStyle>
}

export default function ItemQuestionResult (props: TItemQuestionResultProps) {
  return (
    <View style={props.style}>
      <Text type='label'>{props.label}</Text>
      {props.amount || props.currency
        ? (
          <Amount
            currency={props.currency}
            type='description'
          >
            {props.description}
          </Amount>
          )
        : (
          <Text type='description'>{props.description}</Text>
          )}
    </View>
  )
}
