import { initializeApp } from 'firebase/app'
import * as Analytics from 'firebase/analytics'

export const initialize = () => initializeApp({
  apiKey: 'AIzaSyBlH4JTF73BDRHKER5NSggcp0_t3c_HDSI',
  appId: '1:508437389849:web:fb80e1320573eb82538088',
  measurementId: 'G-9KCLPST01Y',
  projectId: 'verzekeringapp'
})

export const logEvent = (name: string, params?: Record<string, any>) =>
  Analytics.logEvent(Analytics.getAnalytics(), name, params)

export const setCurrentScreen = (screenName: string) =>
  Analytics.setCurrentScreen(Analytics.getAnalytics(), screenName)

export const setUserId = (userId: string) =>
  Analytics.setUserId(Analytics.getAnalytics(), userId)
