import { format } from 'date-fns'
import { validate } from 'email-validator'
// Use the Linking from expo instead of the react-native version.
import * as Linking from 'expo-linking'
import { Platform } from 'react-native'

import { TIME_FORMAT } from '@/constants/date_time'
import Settings from '@/constants/Settings'
import type { OpeningHour, PolicyDetail } from '@/types/objects'
import i18n from '@/utils/i18n'

import { parseEmailAddress } from './email'
import { IS_IOS, IS_WEB } from '@/constants/platform'
import Config from 'react-native-config'
import * as Analytics from '@/utils/firebase/analytics'

enum DistanceUnits {
  M = 'M',
  MM = 'MM',
  KM = 'KM',
}

class Common {
  validateEmail = (email: string): boolean => validate(parseEmailAddress(email))

  determineRedirectUrl = (path: string, queryParams = {}) => {
    const url = Linking.makeUrl(path, queryParams)

    if (IS_WEB) {
      return url
    }

    // These urls can also be picked up by the app
    switch (Config.APP_ENV) {
      case 'acceptance':
        return url?.replace('nh1816:///', 'https://nh1816-acc.mijn-polissen.nl/')
      case 'development':
        return url?.replace('nh1816:///', 'https://nh1816-dev.mijn-polissen.nl/')
      case 'production':
      default:
        return url?.replace('nh1816:///', 'https://mijn-polissen.nl/')
    }
  }

  openURL = (url: string, target?: string, noopener?: string): void => {
    if (url) {
      if (IS_WEB) {
        window.open(url, target, noopener)
      } else {
        Linking.openURL(url)
      }
    }
  }

  openEmail = (email: string): void => this.openURL(`mailto:${email}`)

  openPhoneNumber = (phoneNumber: string | number): void => {
    if (IS_IOS) {
      this.openURL(`tel://${phoneNumber}`)
    } else {
      this.openURL(`tel:${phoneNumber}`)
    }
  }

  openWhatsapp = (phoneNumber: string): void => {
    const link = `https://wa.me/${phoneNumber}`

    if (Platform.OS === 'web') {
      window.open(link, '_blank')
    } else {
      Linking.openURL(link)
    }
  }

  callEmergencyNumber = (): void => {
    Analytics.logEvent('emergency_number')

    return this.openPhoneNumber(Settings.emergencyNumber)
  }

  openWebsite = (website: string = '', target = '_blank'): void => {
    let url = website

    if (url.includes('http://')) {
      url = url.replace('http://', 'https://')
    } else if (!url.includes('https://')) {
      url = `https://${url}`
    }

    this.openURL(
      url.startsWith('https') || url.startsWith('//') ? url : `//${url}`,
      target
    )
  }

  determineFullName = (object: any): string => {
    if (object) {
      return [
        object.firstName || object.initials,
        object.insertion,
        object.lastName
      ]
        .map((p) => (p || '').trim())
        .filter((p) => !!p)
        .join(' ')
    }
    return ''
  }

  determineName = (object: any): string => {
    if (object) {
      if (object.firstName) {
        return object.firstName
      }
      return this.determineFullName(object)
    }

    return ''
  }

  determinePaymentPeriod (paymentPeriod: string) {
    switch (paymentPeriod) {
      case 'Maand':
        return ' per maand'
      case 'Kwartaal':
        return ' per kwartaal'
      case 'Halfjaar':
        return ' per halfjaar'
      case 'Jaar':
        return ' per jaar'
      default:
        return null
    }
  }

  getNavigationParam = (props: any, index: number | string): any =>
    props?.route?.params?.[index]

  determineGreeting = (hours: number): string => {
    if (hours >= 0 && hours < 6) {
      return i18n.t('Goedenacht')
    }
    if (hours >= 6 && hours < 12) {
      return i18n.t('Goedemorgen')
    }
    if (hours >= 12 && hours < 18) {
      return i18n.t('Goedemiddag')
    }
    if (hours >= 18 && hours < 24) {
      return i18n.t('Goedenavond')
    }
    return i18n.t('Goedendag')
  }

  determineDistance = (
    meters: number
  ): { distance: number, unit: DistanceUnits } => {
    let distance = Math.round(meters)
    let unit: DistanceUnits = DistanceUnits.M

    if (distance > 1000000) {
      distance = Math.round(distance / 1000000)
      unit = DistanceUnits.MM
    } else if (distance > 1000) {
      distance = Math.round((distance / 1000) * 10) / 10
      unit = DistanceUnits.KM
    }

    return { distance, unit }
  }

  convertDetailToAttribute = (detail: PolicyDetail) => {
    if (detail) {
      return {
        description: detail?.code,
        followNumber: detail?.followNumber,
        id: detail?.id,
        title: detail?.label
      }
    }

    return null
  }

  convertDetailsToAttributes = (details: PolicyDetail[]) =>
    (details || []).map(this.convertDetailToAttribute).filter(Boolean)

  determineOpeningCondition = (openingHour: OpeningHour): -1 | 0 | 1 => {
    if (openingHour?.opening && openingHour.closing) {
      const opening = openingHour.opening.padStart(5, '0')
      const closing = openingHour.closing.padStart(5, '0')
      const currentTime = format(new Date(), TIME_FORMAT)

      if (currentTime >= opening && currentTime <= closing) {
        return 0
      }
      if (currentTime < opening) {
        return -1
      }
      if (currentTime > closing) {
        return 1
      }
    }

    return 1
  }

  checkPasswords = (newPassword = '', newPasswordRepeat = '') => {
    const validDigit = /\d/g.test(newPassword)
    const validLength = newPassword.length >= 8
    const validLowercase = newPassword.toUpperCase() !== newPassword
    const validSpecialChar = !!newPassword.replace(/[a-zA-Z\d\s]/g, '').length
    const validUppercase = newPassword.toLowerCase() !== newPassword
    const invalidPassword =
      !validDigit ||
      !validLength ||
      !validLowercase ||
      !validSpecialChar ||
      !validUppercase
    const invalidPasswordRepeat =
      !newPasswordRepeat ||
      !newPasswordRepeat.length ||
      newPasswordRepeat !== newPassword
    let errorPasswordRepeat = false
    let successPasswordRepeat = false

    if (newPasswordRepeat?.length) {
      if (!newPassword.startsWith(newPasswordRepeat)) {
        errorPasswordRepeat = true
      } else if (newPasswordRepeat === newPassword) {
        successPasswordRepeat = true
      }
    }

    return {
      errorPasswordRepeat,
      invalid: invalidPassword || invalidPasswordRepeat,
      invalidPassword,
      invalidPasswordRepeat,
      successPasswordRepeat,
      validDigit,
      validLength,
      validLowercase,
      validSpecialChar,
      validUppercase
    }
  }
}

export default new Common()
