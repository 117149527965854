import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import i18n from '@/utils/i18n'
import Payments from '@/screens/case/Payments'
import Case from '@/screens/case/Case'
import ClaimsStackNavigator from './claim_stack'
import PurchasesStackNavigator from './purchase_stack'
import ValueMetersStackNavigator from './value_meter_stack'

const CaseStack = createStackNavigator()
const CaseStackNavigator = () => (
  <CaseStack.Navigator screenOptions={{ headerShown: false }}>
    <CaseStack.Screen
      name='Case'
      component={Case}
      options={{ title: i18n.t('Dossier') }}
    />

    <CaseStack.Screen
      name='ClaimsStack'
      component={ClaimsStackNavigator}
    />

    <CaseStack.Screen
      name='PurchasesStack'
      component={PurchasesStackNavigator}
    />

    <CaseStack.Screen
      name='ValueMetersStack'
      component={ValueMetersStackNavigator}
    />

    <CaseStack.Screen
      name='Payments'
      component={Payments}
      options={{ title: i18n.t('Betalingsoverzicht') }}
    />
  </CaseStack.Navigator>
)

export default CaseStackNavigator
