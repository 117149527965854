import React from 'react'
import type { IProduct } from '@/types/objects'
import ProductSelect from './ProductSelect'
import { View } from 'react-native'

interface ProductSelectorsProps {
  products: IProduct[]
  values: string[]
}

const ProductSelectors: React.FC<ProductSelectorsProps> = ({ products, values }) => {
  return (
    <View>
      {values.map((value, index) => (
        <ProductSelect
          key={index}
          products={products}
          value={value}
          index={index}
        />)
      )}
    </View>
  )
}

export default ProductSelectors
