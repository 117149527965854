import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'

const themeColors = (state: TState) => ({
  colors: selectors.getThemeColors(state)
})

export default {
  themeColors
}
