import type { TEntity } from '../constants'
import { API_CALL, API_CALL_SUCCESS, API_CALL_FAILED, API_CALL_CANCELLED } from '../constants/api'

export type TMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

export const apiCall = <T>(method: TMethod, entity: TEntity, id?: string, params?: T, skipCaching?: boolean) => ({
  type: API_CALL,
  payload: { method, entity, id, params, skipCaching }
})

export const apiCallSuccess = <T>(method: TMethod, entity: TEntity, id?: string, data?: T, skipCaching?: boolean) => ({
  type: API_CALL_SUCCESS,
  payload: { method, entity, id, data, skipCaching }
})

export const apiCallFailed = <T>(method: TMethod, entity: TEntity, id?: string, error?: T) => ({
  type: API_CALL_FAILED,
  payload: { method, entity, id, error }
})

export const apiCallCancelled = (method: TMethod, entity: TEntity, id?: string) => ({
  type: API_CALL_CANCELLED,
  payload: { method, entity, id }
})

export type TApiCall = ReturnType<typeof apiCall>
export type TApiCallSuccess = ReturnType<typeof apiCallSuccess>
export type TApiCallFailed = ReturnType<typeof apiCallFailed>
export type TApiCallCancelled = ReturnType<typeof apiCallCancelled>

export type TApiCallDispatch =
  TApiCall &
  TApiCallSuccess &
  TApiCallFailed &
  TApiCallCancelled

export const wipeCache = () => ({ type: 'WIPE_CACHE' })
