export const parseEmailAddress = (emailAddress: string): string => {
  if (!emailAddress) {
    return ''
  }

  let email = emailAddress.toLowerCase()

  // remove all whitespaces
  email = email.replace(/\s+/g, '')

  return email
}
