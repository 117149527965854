import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import * as ImagePicker from 'expo-image-picker'
import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'

import i18n from '@/utils/i18n'
import { getThemeColors } from '@/redux/selectors/theme'
import Sizes from '@/constants/Sizes'
import { IS_ANDROID, IS_WEB } from '@/constants/platform'
import Colors from '@/constants/Colors'
import { ALLOWED_FILE_MIME_TYPES, ALLOWED_FILE_TYPES } from '@/constants/files'
import Button from '../buttons/Button'
import ButtonActionSheet from '../buttons/ButtonActionSheet'
import ButtonFile from '../buttons/ButtonFile'
import Label from '../Label'
import toast from '@/utils/toast'
import Alert from '@/utils/alert'
import { v4 as uuidv4 } from 'uuid'
import { convertUriToMimeType } from '@/utils/convertUriToMimeType'
import { convertBase64String } from '@/utils/data'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'

export interface File {
  name: string
  base64: string
  fileUuid: string
  mimeType: string | null
}

export type ActionType = 'camera' | 'image' | 'document' | 'cancel'

interface IProps {
  title: string
  onSelect: (file: File) => void
  onRemove: () => void

  required?: boolean
  label?: string
  file?: File
  loading?: boolean
  description?: string
}

const pickerOptions: ImagePicker.ImagePickerOptions = {
  allowsEditing: false,
  mediaTypes: ImagePicker?.MediaTypeOptions?.Images,
  base64: true,
  quality: 0.5
}

const buttons: { title: string, type: ActionType }[] = [
  {
    title: i18n.t('Open camera'),
    type: 'camera'
  },
  {
    title: i18n.t('Selecteer afbeelding'),
    type: 'image'
  },
  {
    title: i18n.t('Selecteer bestand'),
    type: 'document'
  },
  {
    title: i18n.t('Annuleer'),
    type: 'cancel'
  }
]

const options = buttons.map((button) => button.title)

const InputFile: React.FC<IProps> = (props) => {
  const colors = useSelector(getThemeColors)
  const [loading, setLoading] = useState<boolean>(false)

  const [cameraPermissionStatus, requestCameraPermission] = ImagePicker.useCameraPermissions()
  const [mediaLibraryPermissionStatus, requestMediaLibraryPermission] = ImagePicker.useMediaLibraryPermissions()

  const requestPermissions = async () => {
    if (cameraPermissionStatus?.status !== 'granted') {
      if (cameraPermissionStatus?.canAskAgain) {
        const result = await requestCameraPermission()
        if (!result.granted) {
          return false
        }
      } else {
        toast('U heeft permissies tot uw camera geweigerd, ga naar uw instellingen om deze toe te staan')
        return false
      }
    }

    if (mediaLibraryPermissionStatus?.status !== 'granted') {
      if (mediaLibraryPermissionStatus?.canAskAgain) {
        const result = await requestMediaLibraryPermission()
        if (!result.granted) {
          return false
        }
      } else {
        toast('U heeft permissies tot uw camera roll geweigerd, ga naar uw instellingen om deze toe te staan')
        return false
      }
    }

    return true
  }

  const launchCamera = async () => {
    const hasPermissions = await requestPermissions()

    if (!hasPermissions) {
      return
    }

    const result = await ImagePicker.launchCameraAsync(pickerOptions)

    if (!result.canceled && result.assets?.[0]?.base64) {
      props.onSelect({
        name: 'Afbeelding',
        base64: result.assets?.[0].base64,
        fileUuid: uuidv4(),
        mimeType: convertUriToMimeType(result.assets?.[0].uri)
      })
    }
  }

  const launchImagePicker = async () => {
    const hasPermissions = await requestPermissions()

    if (!hasPermissions) {
      return
    }

    const result = await ImagePicker.launchImageLibraryAsync(pickerOptions)

    if (!result.canceled && result.assets?.[0]) {
      const asset = result.assets?.[0]
      const fileInfo = await FileSystem.getInfoAsync(asset.uri)
      if (fileTooBig(fileInfo.size)) {
        await showFileTooBigAlert()
        return
      }

      props.onSelect({
        name: asset?.fileName || 'Afbeelding',
        base64: asset.base64 as string,
        fileUuid: uuidv4(),
        mimeType: convertUriToMimeType(asset.uri)
      })
    }
  }

  const launchDocumentPicker = async () => {
    const hasPermissions = await requestPermissions()

    if (!hasPermissions) {
      return
    }

    const result = await DocumentPicker.getDocumentAsync({
      type: ALLOWED_FILE_MIME_TYPES?.join(','),
      copyToCacheDirectory: false
    })

    if (!result.canceled && result.assets?.[0]) {
      const asset = result.assets?.[0]

      if (fileTooBig(asset?.size)) {
        await showFileTooBigAlert()
        return
      }

      if (!IS_WEB) {
        const fileInfo = await FileSystem.getInfoAsync(asset.uri)

        if (IS_ANDROID) {
          fileInfo.uri = FileSystem.documentDirectory + asset.name
          await FileSystem.copyAsync({
            from: asset.uri,
            to: fileInfo.uri
          })
        }

        props.onSelect({
          name: asset.name,
          base64: await convertBase64String(fileInfo.uri),
          fileUuid: uuidv4(),
          mimeType: (asset.mimeType !== undefined) ? asset.mimeType : null
        })
      } else {
        props.onSelect({
          name: asset.name,
          base64: await convertBase64String(asset.uri),
          fileUuid: uuidv4(),
          mimeType: (asset.mimeType !== undefined) ? asset.mimeType : null
        })
      }
    }
  }

  const onStartPicker = async (type: ActionType) => {
    if (!IS_WEB && props.loading === undefined) {
      setLoading(true)
    }

    if (IS_WEB || type === 'document') {
      await launchDocumentPicker()
    } else if (type === 'camera') {
      await launchCamera()
    } else if (type === 'image') {
      await launchImagePicker()
    }

    if (!IS_WEB && props.loading === undefined) {
      setLoading(false)
    }
  }

  const fileTooBig = (sizeInBytes: number | undefined): boolean => {
    if (!sizeInBytes) {
      return true
    }
    return sizeInBytes / 1024 / 1024 > 18
  }

  const showFileTooBigAlert = async () => {
    await Alert.showSingle(
      i18n.t('Een bijlage mag niet groter zijn dan 18 MB.'),
      i18n.t(''),
      { text: 'Ok' }
    )
  }

  let component

  if (props.file) {
    component = (
      <ButtonFile
        cancelButtonIndex={2}
        file={props.file}
        loading={props.loading ?? loading}
        onRemove={props.onRemove}
        options={buttons.map((button) => button.title)}
        style={{ marginBottom: Sizes.inputMarginVertical }}
        title={props.file?.name ?? props.file?.title ?? 'Foto'}
      />
    )
  } else if (IS_WEB) {
    component = (
      <Button
        borderColor={colors?.brand}
        color={Colors.white}
        icon='plus'
        iconColor={colors?.brand}
        loading={props.loading ?? loading}
        onPress={async () => await onStartPicker('image')}
        style={{ marginBottom: Sizes.inputMarginVertical }}
        title={props.title}
        titleColor={colors?.brand}
      />
    )
  } else {
    component = (
      <ButtonActionSheet
        borderColor={colors?.brand}
        cancelButtonIndex={options.length - 1}
        color={Colors.white}
        icon='plus'
        iconColor={colors?.brand}
        loading={props.loading ?? loading}
        onPress={async (index) => await onStartPicker(buttons[index].type)}
        options={options}
        style={{ marginBottom: Sizes.inputMarginVertical }}
        title={props.title}
        titleColor={colors?.brand}
      />
    )
  }

  return (
    <>
      {props.label &&
        <Label
          label={props.label}
          required={props.required}
          tooltip={i18n.t(`Bestandstypen die worden ondersteund: ${ALLOWED_FILE_TYPES.join(', ')}`)}
        />}

      {props.description &&
        <Text
          type='description'
          style={Styling.marginBottomSmall}
        >
          {props.description}
        </Text>}

      {component}
    </>
  )
}

export default InputFile
