import * as CONSTANTS from '@/redux/constants/idin'
import type * as TYPES from '@/redux/types/idin'

export const resetIdin = (): TYPES.ResetIdinAction => ({
  type: CONSTANTS.RESET_IDIN
})

export const startIdin = (entranceCode: string, transactionId: string, email: string): TYPES.StartIdinAction => ({
  entranceCode,
  transactionId,
  type: CONSTANTS.START_IDIN,
  email
})
