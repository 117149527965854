import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import { PROVIDER_GOOGLE } from 'react-native-maps'
import { decode } from 'html-entities'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Button from '@/components/buttons/Button'
import ContactInfo from '@/components/ContactInfo'
import Screen from '@/components/Screen'
import type { Route } from '@/components/Tabs'
import Tabs from '@/components/Tabs'
import TouchableText from '@/components/touchables/TouchableText'
import MapView from '@/components/wrappers/MapView'
import RefreshControl from '@/components/wrappers/RefreshControl'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Settings from '@/constants/Settings'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import { apiCall } from '@/redux/actions/api'
import { openModalEmergencyNumber } from '@/redux/actions/modals'
import { ENTITIES } from '@/redux/constants'
import type { State as TState } from '@/redux/reducers'
import type { ThemeColors } from '@/redux/reducers/theme'
import selectors from '@/redux/selectors'
import ItemAgent from '@/screens/agency/components/ItemAgent'
import OpeningHours from '@/screens/agency/components/OpeningHours'
import type { Agency as IAgency, Agent, IAgencyLocation, Location } from '@/types/objects'
import common from '@/utils/common'
import data from '@/utils/data'
import i18n from '@/utils/i18n'
import locationUtil from '@/utils/location'
import navigation from '@/utils/navigation'
import MarkerAgency from './components/MarkerAgency'

const mapOptions = { fullscreenControl: false }

interface IAgencyProps {
  agency: IAgency
  agencyId: IAgency['id']
  agents?: IAgency['agents'][]
  colors: ThemeColors
  currentOpeningHour?: IAgency['openingHours']
  openModalEmergencyNumber: typeof openModalEmergencyNumber
  openingHours?: IAgency['openingHours']
  showOpeningHours: boolean
  refreshing: boolean
  mainLocation: IAgencyLocation
  apiCall: typeof apiCall
}

interface IAgencyState {
  position: typeof Settings.regionNh1816
}

class Agency extends React.Component<IAgencyProps, IAgencyState> {
  state: IAgencyState = {
    position: Settings.regionNh1816
  }

  routes: Route[] = [
    {
      key: 'general',
      title: i18n.t('Algemeen')
    },
    {
      key: 'agents',
      title: i18n.t('Adviseurs')
    }
  ]

  componentDidMount () {
    this.refresh()
  }

  refresh = async () => {
    this.props.apiCall('GET', ENTITIES.AGENCY, this.props.agencyId)

    const agency = this.props.agency || {}
    const addressParts = [agency.city, agency.zipCode, agency.street, agency.houseNumber].filter(Boolean)

    if (addressParts.length) {
      const positions: Location[] = await locationUtil.geocodeAsync([addressParts].join(' '))

      if (positions?.[0]?.latitude && positions?.[0]?.longitude) {
        this.setState({
          position: {
            ...positions[0],
            latitudeDelta: 0.5,
            longitudeDelta: 0.5
          }
        })
      }
    }
  }

  openWhatsapp = () => common.openWhatsapp(this.props.mainLocation.whatsapp)

  openPhoneNumber = () => common.openPhoneNumber(this.props.mainLocation.phoneNumber)

  openEmail = () => common.openEmail(this.props.mainLocation.email)

  openWebsite = () => common.openWebsite(this.props.agency.website)

  keyExtractor = (agent: Agent) => `list-item-agent-${agent.id}`

  renderItem = ({ item: agent }: { item: Agent }) => <ItemAgent agent={agent} />

  renderRefreshControl = () => (
    <RefreshControl
      onRefresh={this.refresh}
      refreshing={this.props.refreshing}
      title={i18n.t('Gegevens van de adviseur aan het ophalen')}
    />
  )

  renderGeneral = () => {
    const agency = this.props.agency || {}
    const mainLocation = this.props.mainLocation || {}
    const address = data.getAgencyAddress(agency)

    return (
      <Bootstrap.Grid>
        <Bootstrap.Row>
          <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 4, xs: 12 }}>
            {address
              ? (
                <>
                  <Text
                    style={Styling.marginBottomSmall}
                    type='subsection'
                  >
                    {i18n.t('Adres')}
                  </Text>
                  <Text
                    style={Styling.marginBottomMedium}
                    type='description'
                  >
                    {address}
                  </Text>
                </>
                )
              : null}
            <Text type='subsection'>{i18n.t('Contact')}</Text>

            <ContactInfo
              circleColor={this.props.colors?.brand}
              description={mainLocation.phoneNumber}
              icon='phone'
              iconColor={Colors.white}
              onPress={this.openPhoneNumber}
              style={Styling.marginTopSmall}
              title={i18n.t('Bellen')}
            />

            <ContactInfo
              circleColor={this.props.colors?.brand}
              description={mainLocation.email}
              icon='envelope'
              iconColor={Colors.white}
              onPress={this.openEmail}
              style={Styling.marginTopSmall}
              title={i18n.t('Bericht')}
            />

            <ContactInfo
              circleColor={Colors.whatsapp}
              description={mainLocation.whatsapp}
              icon={['fab', 'whatsapp']}
              iconColor={Colors.white}
              onPress={this.openWhatsapp}
              style={Styling.marginTopSmall}
              title={i18n.t('WhatsApp')}
            />

            <ContactInfo
              circleColor={Colors.darkRed}
              description={Settings.emergencyNumber}
              descriptionColor={Colors.darkRed}
              icon='phone-plus'
              iconColor={Colors.white}
              onPress={this.props.openModalEmergencyNumber}
              style={Styling.marginTopSmall}
              title={i18n.t('Nood')}
            />
          </Bootstrap.Column>

          <Bootstrap.Column
            offsetRight={{ lg: 0, md: 4, sm: 0, xl: 0, xs: 0 }}
            span={{ lg: 6, md: 8, sm: 12, xl: 4, xs: 12 }}
          >
            {this.props.showOpeningHours && this.props.openingHours?.length
              ? (
                <View>
                  <Text
                    style={Styling.marginBottomSmall}
                    type='subsection'
                  >
                    {i18n.t('Openingstijden')}
                  </Text>

                  <OpeningHours
                    currentOpeningHour={this.props.currentOpeningHour}
                    openingHours={this.props.openingHours || []}
                  />
                </View>
                )
              : null}

            <Text
              style={Styling.marginTop}
              type='paragraphLight'
            >
              {i18n.t('Buiten kantooruren kunt u in gevallen van nood bellen met de alarmcentrale van Nh1816 op')}{' '}
              <TouchableText
                onPress={this.props.openModalEmergencyNumber}
                type='paragraphError'
              >
                {Settings.emergencyNumber}
              </TouchableText>
            </Text>
          </Bootstrap.Column>

          <Bootstrap.Column
            offsetRight={{ lg: 0, md: 4, sm: 0, xl: 0, xs: 0 }}
            span={{ lg: 6, md: 8, sm: 12, xl: 4, xs: 12 }}
          >
            {agency.serviceGuides && agency.serviceGuides.length
              ? (
                <View style={Styling.marginBottomMedium}>
                  <Text
                    style={Styling.marginBottomSmall}
                    type='subsection'
                  >
                    {i18n.t('Onze diensten')}
                  </Text>

                  {agency.serviceGuides.map((serviceGuide, index) => (
                    <Button
                      color={this.props.colors?.brand}
                      key={`service-guide-${serviceGuide.id}`}
                      onPress={async () => await navigation.openScreenServiceGuide(serviceGuide)}
                      style={index !== 0 && Styling.marginTopSmall}
                      title={serviceGuide.title}
                    />
                  ))}
                </View>
                )
              : null}

            <Text type='subsection'>{i18n.t('Meer informatie?')}</Text>

            <Button
              color={this.props.colors?.brand}
              onPress={this.openWebsite}
              style={Styling.marginTopSmall}
              title={i18n.t('Bekijk de website')}
            />
          </Bootstrap.Column>
        </Bootstrap.Row>

        <Bootstrap.Row>
          <Bootstrap.Column>
            <MapView
              initialRegion={this.state.position}
              options={mapOptions}
              region={Platform.OS === 'web' ? this.state.position : null}
              style={styles.map}
              provider={PROVIDER_GOOGLE}
            >
              <MarkerAgency coordinate={this.state.position} />
            </MapView>
          </Bootstrap.Column>
        </Bootstrap.Row>
      </Bootstrap.Grid>
    )
  }

  renderScene = ({ route }: { route: Route }) => {
    if (route.key === 'general') {
      return this.renderGeneral()
    }

    if (route.key === 'agents') {
      return (
        <Bootstrap.Collection
          white
          data={this.props.agents}
          gutter={15}
          id='agency-phone-collection'
          keyExtractor={this.keyExtractor}
          margin={30}
          renderItem={this.renderItem}
          span={{ lg: 4, md: 6, sm: 12, xl: 4, xs: 12 }}
        />
      )
    }

    return null
  }

  render () {
    return (
      <Screen refreshControl={this.renderRefreshControl()}>
        <Block noPadding>
          <View style={styles.headerWrapper}>
            <Text type='subtitle'>{i18n.t('Contactgegevens')}</Text>

            {this.props.agency?.name
              ? (
                <Text
                  style={Styling.marginTopSmall}
                  type='descriptionLight'
                >
                  {decode(this.props.agency.name)}
                </Text>
                )
              : null}
          </View>

          {this.props.agents?.length
            ? (
              <Tabs
                scrollEnabled
                usePills
                renderScene={this.renderScene}
                routes={this.routes}
                tabBarStyle={styles.tabBar}
              />
              )
            : (
                this.renderGeneral()
              )}
        </Block>
      </Screen>
    )
  }
}

const styles = StyleSheet.create({
  headerWrapper: {
    paddingHorizontal: Sizes.spacingHorizontalMedium,
    paddingTop: Sizes.spacingVerticalMedium
  },
  map: {
    borderRadius: Sizes.borderRadius,
    height: 360,
    overflow: 'hidden',
    width: '100%'
  },
  tabBar: {
    marginHorizontal: Sizes.spacingHorizontalMedium,
    marginTop: Sizes.spacingVerticalMedium
  }
})

const mapStateToProps = (state: TState) => {
  const currentDay = new Date().getDay()
  const openingHours = state.agency?.data?.openingHours
  const showOpeningHours = state.agency?.data?.showOpeningHours
  const currentOpeningHour = openingHours?.find((o: any) => o.dayNumber === currentDay)
  const nextOpeningHour =
    openingHours &&
    (openingHours.find((o: any) => o.dayNumber > currentDay) ||
      openingHours?.reduce(
        (lowest: any, current: any) => (lowest.dayNumber < current.dayNumber ? lowest : current),
        openingHours[0]
      ))

  return {
    agency: selectors.getAgency(state),
    agencyId: selectors.getAgencyId(state),
    agents: selectors.getAgents(state),
    colors: selectors.getThemeColors(state),
    currentOpeningHour,
    mainLocation: selectors.getMainLocation(state),
    nextOpeningHour,
    openingHours,
    showOpeningHours,
    refreshing: selectors.isLoading('AGENCY')(state)
  }
}

const mapDispatchToProps = {
  apiCall,
  openModalEmergencyNumber
}

export default connect(mapStateToProps, mapDispatchToProps)(Agency)
