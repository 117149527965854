import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Actions from '@/components/Actions'
import Block from '@/components/Block'
import Icon from '@/components/Icon'
import Pill from '@/components/Pill'
import TouchableIcon from '@/components/touchables/TouchableIcon'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import selectors from '@/redux/selectors'
import type { Claim, IHistoricalClaim } from '@/types/objects'
import i18n from '@/utils/i18n'
import { getClaimConfig } from '@/utils/claims'
import DateUtil from '@/utils/date'
import data from '@/utils/data'

interface IProps {
  claim: Claim
  onPress: (claim: Claim | IHistoricalClaim) => void
  removeClaim: (claim: Claim) => void
}

const ItemClaim: React.FC<IProps> = (props) => {
  const colors = useSelector(selectors.getThemeColors)
  const claim = props.claim || {}
  const config = getClaimConfig(claim?.status)
  const loadingDelete = useSelector(selectors.isLoading('CLAIM', (claim).claimDamageId))?.method === 'DELETE'
  const distinctionLabel = data.getDistinctionLabel(claim)

  const onPressClaim = () => {
    props.onPress(claim)
  }

  const onPressRemove = () => {
    props.removeClaim(claim)
  }

  return (
    <Block>
      <View style={Styling.marginBottomMedium}>
        <Bootstrap.Row
          gutterHorizontal={15}
          gutterVertical={15}
        >
          <Bootstrap.Column
            span={{ lg: 8, md: 8, sm: 12, xl: 8, xs: 12 }}
            style={Styling.rowCenter}
          >
            <Icon
              large
              circleColor={colors?.lightBrand}
              color={colors?.brand}
              productId={claim?.productId}
              style={Styling.marginRight}
            />
            <View style={Styling.flex}>
              <Text type='subtitle'>{`${claim.productName} ${distinctionLabel ? `(${distinctionLabel})` : ''}`}</Text>
            </View>
          </Bootstrap.Column>
          <Bootstrap.Column
            span={{ lg: 4, md: 4, sm: 0, xl: 4, xs: 0 }}
            style={Styling.rowCenter}
          >
            <View style={Styling.flexWrap}>
              <Pill
                color={claim?.statusColor ?? config.color}
                style={Styling.autoLeft}
                title={claim.status}
              />
            </View>
          </Bootstrap.Column>
          <Bootstrap.Column
            span={{ lg: 0, md: 0, sm: 12, xl: 0, xs: 12 }}
            style={Styling.rowCenter}
          >
            <Pill
              color={claim?.statusColor ?? config.color}
              title={claim.status}
            />
          </Bootstrap.Column>
        </Bootstrap.Row>
      </View>
      <Bootstrap.Row
        gutterHorizontal={15}
        gutterVertical={15}
      >
        <Bootstrap.Column span={{ lg: 3, md: 4, sm: 6, xl: 3, xs: 6 }}>
          <Text type='paragraphLight'>{i18n.t('Schadedatum')}</Text>
          <Text type='span'>{DateUtil.formatTimeStamp(claim?.dateOfLoss)}</Text>
        </Bootstrap.Column>
        <Bootstrap.Column span={{ lg: 3, md: 4, sm: 6, xl: 3, xs: 6 }}>
          <Text type='paragraphLight'>{i18n.t('Polisnummer')}</Text>
          <Text type='span'>{claim.policyNumber}</Text>
        </Bootstrap.Column>
        <Bootstrap.Column span={{ lg: 3, md: 4, sm: 6, xl: 3, xs: 6 }}>
          <Text type='paragraphLight'>{i18n.t('Verzekering')}</Text>
          <Text type='span'>{claim.productName}</Text>

        </Bootstrap.Column>
      </Bootstrap.Row>
      <View style={Styling.marginVerticalMedium}>
        <Text type='paragraphLight'>{i18n.t('Toelichting status')}</Text>
        <Text type='span'>{claim.statusDescription}</Text>
      </View>
      <View style={Styling.flexRowBetween}>
        {loadingDelete
          ? <Actions />
          : (
            <Actions
              labelRight={i18n.t('Bekijk schademelding')}
              onPressRight={onPressClaim}
              style={Styling.rowCenter}
            />
            )}

        {claim.status === 'Concept'
          ? (
            <TouchableIcon
              color={colors?.brand}
              icon='trash'
              onPress={onPressRemove}
              touchableStyle={Styling.rowCenter}
              loading={loadingDelete}
              disabled={loadingDelete}
            />
            )
          : null}
      </View>
    </Block>
  )
}

export default ItemClaim
