import React from 'react'
import { StyleSheet, View, ActivityIndicator } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { decode } from 'html-entities'

import navigation from '@/utils/navigation'
import alert from '@/utils/alert'
import i18n from '@/utils/i18n'

import selectors from '@/redux/selectors'
import { apiCall } from '@/redux/actions/api'
import { ENTITIES } from '@/redux/constants'

import ListItem from '@/components/list_items/ListItem'
import Text from '@/components/wrappers/Text'
import TouchableIcon from '@/components/touchables/TouchableIcon'

import Styling from '@/constants/Styling'

import type { INotification } from '@/types/objects'

const ListItemNotification: React.FC<INotification> = (notification) => {
  const dispatch = useDispatch()
  const colors = useSelector(selectors.getThemeColors)

  const deleteLoading = useSelector(selectors.isLoading(ENTITIES.NOTIFICATION, notification.messageId))?.method === 'DELETE'

  const onPress = async () => {
    await navigation.openScreenNotification(notification?.messageId)
  }

  const deleteNotification = () => {
    dispatch(apiCall('DELETE', ENTITIES.NOTIFICATION, notification.messageId?.toString()))
  }

  const onPressDelete = () => {
    alert.showDouble(
      i18n.t('Weet u zeker dat u het bericht wil verwijderen?'),
      '',
      { text: 'Nee' },
      {
        text: 'Ja',
        style: 'destructive',
        onPress: deleteNotification
      }
    )
  }

  return (
    <ListItem
      chevron
      item={notification}
      onPress={onPress}
      style={styles.listItem}
      disabled={deleteLoading}
    >
      <View style={styles.wrapper}>
        <View style={Styling.rowCenter}>
          <Text
            style={[Styling.flex, notification?.read ? {} : { color: colors?.brand }]}
            type={notification?.read ? 'span' : 'spanMedium'}
          >
            {decode(notification?.title)}
          </Text>
        </View>

        <Text
          style={Styling.marginTopTiny}
          type='paragraphLight'
        >
          {notification.createdAt}
        </Text>
      </View>

      {deleteLoading
        ? <ActivityIndicator color={colors.brand} />
        : <TouchableIcon
            color={colors?.brand}
            icon='trash'
            onPress={onPressDelete}
          />}
    </ListItem>
  )
}

const styles = StyleSheet.create({
  listItem: {
    marginVertical: 4
  },
  wrapper: {
    flex: 1
  }
})

export default ListItemNotification
