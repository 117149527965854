export const transparentAddition = '08'

const gray = '#48423E'
const primary = '#E95E40'
const secondary = '#48422E'
const success = '#87C104'

export default {
  '8d': '#8D8D8D',
  b1: '#B1B1B1',
  backdrop: 'rgba(0,0,0,0.75)',
  background: '#F0F3F6',
  backgroundDark: '#171820',
  black: '#000000',
  blue: '#02327D',
  brand: primary,
  brandSecondary: secondary,
  brown: '#84726F',
  c1: '#C1C1C1',
  cc: '#CCCCCC',
  da: '#DADADA',
  darkRed: '#AF0000',
  e2: '#E2E2E2',
  e8: '#E8E8E8',
  ed: '#EDEDED',
  f9: '#F9F9F9',
  fb: '#FBFBFB',
  gray,
  green: '#408716',
  greenCard: '#D4E4C4',
  lightBlue: '#F5F7FA',
  lightBrand: primary + transparentAddition,
  lightBrandSecondary: secondary + transparentAddition,
  lightBrown: '#B3A49A',
  lightError: '#FFEEEE',
  lightGray: '#D9DFE8',
  lightGreen: '#EDF9E5',
  lightPrimary: primary + transparentAddition,
  lightRed: '#FAF5F5',
  lightSuccess: success + transparentAddition,
  lightWaiting: '#FFFAE9',
  lighterBrown: '#EDEBE9',
  line: 'rgba(0,0,0,0.04)',
  orange: '#DE8710',
  primary,
  red: '#CD0404',
  sidebar: '#F9FAFC',
  success,
  transparent: 'transparent',
  transparentGray: gray + transparentAddition,
  waiting: '#FF9C00',
  whatsapp: '#00A100',
  white: '#FFFFFF',
  whiteDark: '#0D0E18'
}
