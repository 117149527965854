import * as CONSTANTS from '@/redux/constants/relationRepository'
import type * as TYPES from '@/redux/types/relations'
import type { Relation, User } from '@/types/objects'

export const selectRelation = (relation: Relation): TYPES.SelectRelationAction => ({
  relation,
  type: CONSTANTS.SELECT_RELATION
})

export const updateEmail = (
  newEmail: User['email'],
  newEmailRepeat: User['email'],
  password: User['password'],
  isRelationMutation: boolean
) => ({
  payload: { isRelationMutation, newEmail, newEmailRepeat, password },
  type: CONSTANTS.RELATION_UPDATE_EMAIL
})

export const updateEmailSuccess = () => ({
  type: CONSTANTS.RELATION_UPDATE_EMAIL_SUCCESS
})

export const updateEmailFailed = (error: string) => ({
  payload: { error },
  type: CONSTANTS.RELATION_UPDATE_EMAIL_FAILED
})
