import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet } from 'react-native'

import Icon from '@/components/Icon'
import Touchable from '@/components/touchables/Touchable'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'

interface TListItemProps {
  backgroundColor?: string
  borderColor?: string
  chevron?: boolean
  chevronColor?: string
  column?: boolean
  flex?: boolean
  item: any
  onPress?: (item: any) => void
  style?: StyleProp<ViewStyle>
  disabled?: boolean
  children: React.ReactNode[]
}

const ListItem: React.FC<TListItemProps> = (props) => {
  const onPress = (): void => {
    props?.onPress?.(props.item)
  }

  return (
    <Touchable
      disabled={!props.onPress || props.disabled}
      onPress={onPress}
      style={[
        props.column ? styles.containerColumn : styles.container,
        props.backgroundColor ? { backgroundColor: props.backgroundColor } : null,
        props.borderColor ? { borderColor: props.borderColor, borderWidth: 1 } : null,
        props.flex ? Styling.flex : null,
        props.style
      ]}
    >
      {props.children}
      {props.chevron
        ? (
          <Icon
            color={props.chevronColor || Colors.lightGray}
            icon={['fal', 'chevron-right']}
            style={Styling.marginLeft}
          />
          )
        : null}
    </Touchable>
  )
}

const containerStyle = {
  backgroundColor: Colors.white,
  borderRadius: Sizes.borderRadius,
  padding: Sizes.listItemPadding
}

const styles = StyleSheet.create({
  container: {
    ...containerStyle,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden'
  },
  containerColumn: {
    ...containerStyle,
    flexDirection: 'column',
    overflow: 'hidden'
  }
})

export default ListItem
