import React from 'react'

import i18n from '@/utils/i18n'

import Text from '@/components/wrappers/Text'
import Form from '@/components/Form'
import Input from '@/components/inputs/Input'
import ButtonForm from '@/components/buttons/ButtonForm'

import Styling from '@/constants/Styling'
import Colors from '@/constants/Colors'
import common from '@/utils/common'

interface IProps {
  password: string
  passwordRepeat: string

  loading: boolean
  alreadyUsed: boolean

  onChangePassword: (password: string) => void
  onChangePasswordRepeat: (password: string) => void
  onPressSubmitResetPassword: () => void

  renderButtonBack: (closeResetPassword: () => void) => void
  closeResetPassword: () => void
}

const ResetPassword: React.FC<IProps> = (props) => {
  const result = common.checkPasswords(props.password, props.passwordRepeat)
  const invalidLabel = props.password ? 'labelError' : 'label'
  const disabled = props.loading || !props.password || !props.passwordRepeat || result.invalid

  return (
    <>
      <Text
        style={Styling.marginBottomMedium}
        type='title'
      >
        {i18n.t('Wachtwoord herstellen')}
      </Text>
      <Text
        style={Styling.marginBottomMedium}
        type='description'
      >
        {i18n.t('Hieronder kunt u uw nieuwe wachtwoord invullen:')}
      </Text>

      <Text
        style={Styling.marginBottomTiny}
        type='label'
      >
        {i18n.t('Bedenk een veilig wachtwoord dat voldoet aan de volgende eisen')}
      </Text>

      <Text type={result.validLength ? 'labelSuccess' : invalidLabel}>• {i18n.t('Minimaal 8 karakters')}</Text>

      <Text type={result.validUppercase ? 'labelSuccess' : invalidLabel}>• {i18n.t('Minimaal 1 hoofdletter')}</Text>

      <Text type={result.validLowercase ? 'labelSuccess' : invalidLabel}>• {i18n.t('Minimaal 1 kleine letter')}</Text>

      <Text type={result.validDigit ? 'labelSuccess' : invalidLabel}>• {i18n.t('Minimaal 1 cijfer')}</Text>

      <Text
        style={Styling.marginBottom}
        type={result.validSpecialChar ? 'labelSuccess' : invalidLabel}
      >
        • {i18n.t('Minimaal 1 speciaal teken')}
      </Text>

      <Form>
        <Input
          secureTextEntry
          autoCapitalize='none'
          autoCompleteType='off'
          icon='key'
          id='input-password'
          label={i18n.t('Wachtwoord')}
          onChange={props.onChangePassword}
          placeholder={i18n.t('Nieuw wachtwoord')}
          style={Styling.marginBottom}
          value={props.password}
          success={!result.invalidPassword}
        />
        <Input
          secureTextEntry
          autoCapitalize='none'
          autoCompleteType='off'
          icon='key'
          id='input-password-repeat'
          label={i18n.t('Herhaal wachtwoord')}
          onChange={props.onChangePasswordRepeat}
          placeholder={i18n.t('Herhaal nieuw wachtwoord')}
          value={props.passwordRepeat}
          success={!result.invalidPasswordRepeat}
        />
        <ButtonForm
          color={Colors.primary}
          disabled={disabled}
          id='button-submit-reset-password'
          loading={props.loading}
          onPress={props.onPressSubmitResetPassword}
          style={Styling.marginBottom}
          title={i18n.t('Aanpassen')}
        />
      </Form>

      {props.alreadyUsed ? null : props?.renderButtonBack?.(props.closeResetPassword)}
    </>
  )
}

export default ResetPassword
