import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'

import defaults from '@/bootstrap/defaults'
import getConfig from '@/bootstrap/getConfig'
import withBreakpoint from '@/bootstrap/withBreakpoint'
import withSpacing from '@/bootstrap/withSpacing'
import Styling from '@/constants/Styling'
import type { ConfigProp, PointerEventsProp, SpacingProp } from '@/types/props'
import prototype from '@/utils/prototype'

interface TColumnProps {
  breakpoint: string
  offsetLeft?: ConfigProp
  offsetRight?: ConfigProp
  pointerEvents?: PointerEventsProp
  spacing?: SpacingProp
  span: ConfigProp
  style?: StyleProp<ViewStyle>
}

class Column extends React.Component<TColumnProps> {
  getOffsetLeftStyle (breakpoint: string, offsetLeft: ConfigProp): StyleProp<ViewStyle> {
    if (offsetLeft === undefined) {
      return undefined
    }

    if (prototype.isObject(offsetLeft) && Object.keys(offsetLeft).length) {
      return this.getOffsetLeftStyle(breakpoint, getConfig(breakpoint, offsetLeft as object))
    }

    if (typeof offsetLeft === 'number') {
      return { marginLeft: `${(offsetLeft / defaults.columns) * 100}%` }
    }

    if (typeof offsetLeft === 'string') {
      return { marginLeft: offsetLeft }
    }

    return undefined
  }

  getOffsetRightStyle (breakpoint: string, offsetRight: ConfigProp): StyleProp<ViewStyle> {
    if (offsetRight === undefined) {
      return undefined
    }

    if (prototype.isObject(offsetRight) && Object.keys(offsetRight).length) {
      return this.getOffsetRightStyle(breakpoint, getConfig(breakpoint, offsetRight as object))
    }

    if (typeof offsetRight === 'number') {
      return { marginRight: `${(offsetRight / defaults.columns) * 100}%` }
    }

    if (typeof offsetRight === 'string') {
      return { marginRight: offsetRight }
    }

    return undefined
  }

  getSpanStyle (breakpoint: string, span: ConfigProp): StyleProp<ViewStyle> {
    if (span === undefined) {
      return Styling.fullWidth
    }

    if (prototype.isObject(span) && Object.keys(span).length) {
      return this.getSpanStyle(breakpoint, getConfig(breakpoint, span as object))
    }

    if (typeof span === 'number') {
      return {
        width: `${(100 * span) / defaults.columns}%`
      }
    }

    if (typeof span === 'string') {
      return null
    }

    return undefined
  }

  getSpacingStyle (spacing: SpacingProp): StyleProp<ViewStyle> {
    if (typeof spacing === 'number') {
      return {
        paddingHorizontal: spacing / 2,
        paddingVertical: spacing / 2
      }
    }
    if (prototype.isObject(spacing)) {
      const style: StyleProp<ViewStyle> = {}
      if (prototype.isNumber(spacing.horizontal)) {
        style.paddingHorizontal = spacing.horizontal / 2
      }
      if (prototype.isNumber(spacing.vertical)) {
        style.paddingVertical = spacing.vertical / 2
      }
      return style
    }
    return null
  }

  getStyle (breakpoint: string, style: any): StyleProp<ViewStyle> {
    if (prototype.isObject(style) && Object.keys(style).length) {
      const config = getConfig(breakpoint, style)
      if (config) {
        return config
      }
    }
    return style
  }

  render () {
    if (getConfig(this.props.breakpoint, this.props.span as object) === 0) {
      return null
    }
    return (
      <View
        pointerEvents={this.props.pointerEvents}
        style={[
          styles.view,
          this.getSpacingStyle(this.props.spacing as SpacingProp),
          this.getSpanStyle(this.props.breakpoint, this.props.span),
          this.getOffsetLeftStyle(this.props.breakpoint, this.props.offsetLeft as ConfigProp),
          this.getOffsetRightStyle(this.props.breakpoint, this.props.offsetRight as ConfigProp),
          this.getStyle(this.props.breakpoint, this.props.style)
        ]}
      >
        {this.props.children}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  view: {
    flexDirection: 'column',
    minHeight: 1,
    position: 'relative'
  }
})

export default withSpacing(withBreakpoint(Column))
