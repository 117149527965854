export interface Agent {
  emailAddress: string
  fullName: string
  function: string
  id: string
  phoneNumber: string
}

export interface OpeningHour {
  closing: string
  day: string
  dayNumber: number
  opening: string
  closed: 'true' | 'false'
}

export interface ServiceGuide {
  id: string
  title: string
  url: string
}

export interface ITXResponse {
  entranceCode: string
  DebtorReference: string
  RequestType: string
  TransactionID: string
  TransactionURL: string
  ShortTransactionURL: string
}

export interface IAgencyLocation {
  name: string
  street: string
  houseNumber: string
  houseNumberAddition: string
  zipCode: string
  city: string
  email: string
  phoneNumber: string
  whatsapp: string
  mainLocation: boolean
}

export interface Agency {
  agents: Agent[]
  city: string
  email: string
  houseNumber: string
  houseNumberAddition: string
  id: string
  logo: string
  locations: IAgencyLocation[]
  name: string
  openingHours: OpeningHour[]
  phoneNumber: string
  primaryColor: string
  secondaryColor: string
  serviceGuides: ServiceGuide[]
  showOpeningHours: boolean
  street: string
  website: string
  whatsapp: string
  zipCode: string
}

export interface WhatToDo {
  content: string
  icon: string
  id: string
  important: boolean
  title: string
}

export interface User {
  active: boolean
  dateOfBirth: string
  email: string
  facialRecognition: boolean
  financialMessages: boolean
  fingerprint: boolean
  firstName: string
  id: string
  informativeMessages: boolean
  initials: string
  insuranceMessages: boolean
  lastName: string
  logo: string
  mainUser: boolean
  memberBenefits: boolean
  userIdSentToOneSignal: boolean
  password: string
  passwordResetTokens: PasswordresetToken[]
  pincode: string
  pushToken?: string
  relationId: string
  termsRead: boolean
  token: string
  tokenExpiresAt: string
  tokenExtendedAt: string
}

export interface PasswordresetToken {
  createdAt: string
  valid: boolean
}
export interface Tip {
  categoryId: string
  content: string
  id: string
  title: string
}

export interface TipCategory {
  icon: string
  id: string
  title: string
}

export interface Clause {
  description: string
  id: string
  name: string
}

export interface Condition {
  description: string
  id: string
  url: string
}

export enum PolicyDetailType {
  attribute = 'attribute',
  acceptanceQuestion = 'acceptanceQuestion',
  coverage = 'coverage',
}

export interface PolicyDetail {
  code: string
  followNumber: number
  id: string
  important: boolean
  isCoverage: boolean
  label: string
  name: string
  type: PolicyDetailType
  value: string | boolean
  showApp: boolean

  [key: string]: string | number | boolean | PolicyDetailType
}

export interface InsuranceCard {
  description: string
  id: string
  url: string
}

export interface GeneratedGreenCard {
  relationNumber: string
  policyNumberPullingVehicle: string
  policyIdPullingVehicle: string
  pullingVehicleLicensePlate: string
  policyNumberTowedVehicle: string
  policyIdTowedVehicle: string
  towedVehicleLicensePlate: string
  url: string
}

export interface ProductClaimType {
  id: number
  displayName: string
  name: string
}

export interface Policy {
  id: string
  policyNumber: string
  productId: number
  productName: IProduct['name']
  status: string
  pendingMutations: boolean
  greenCard: string
  greenCards: GeneratedGreenCard[]
  updatedAt: string
  premium: string
  cancellationDate: string | null
  details: Record<PolicyDetail['name'], PolicyDetail>
  fetchedAt?: string
  isAllowedToReportClaim: boolean
  minimumDateOfLoss: string
  maximumDateOfLoss: string | null

  // nieuw tov oude situatie
  branche: string
  commenceDate: string

  // detail call tov oude situatie
  clauses?: Clause[]
  conditions?: Condition[]
  insuranceCards?: InsuranceCard[]
}

interface CombiPolicyCondition {
  convValue: string
  description: string
  url: string
}

export interface Relation {
  accountNumber: string
  agencyId: string
  city: string
  combiCollectionAmount: string
  combiContractExpirationDate: string
  combiNumber: string
  combiNumberOfPolicies: number
  combiPackageDiscount: string
  combiPolicyCondition: CombiPolicyCondition
  combiStartingDate: string
  combiStatus: string
  combiTermOfPayment: string
  cooperationMembership: boolean
  dateOfBirth: string
  hasPendingMutations: boolean
  houseNumber: string
  houseNumberAddition: string
  id: string
  logo: string
  mutation: Relation
  paymentMethod: string
  paymentPeriod: string
  phoneNumber: string
  policyStatement: string
  street: string
  zipCode: string
  zestMagazine: boolean
  relationNumber: string
  agentNumber: string | number
}

export interface SupportQuestion {
  answer: string
  id: string
  text: string
}

export interface SupportQuestionCategory {
  icon: string
  id: string
  title: string
}

export interface Purchase {
  date: string
  id: string
  invoice: string
  invoiceFilename: string
  name: string
  picture: string
  pictureFilename: string
  price: string
  invoiceFileMimeType: string | null
  pictureFileMimeType: string | null
}

export interface PurchaseInvoice {
  date: string
  filename: string | null
  mimeType: string | null
  name: string
  price: string
  invoice: string | null
  purchaseId: string | null
}

export interface PurchasePicture {
  date: string
  filename: string | null
  mimeType: string | null
  name: string
  price: string
  picture: string | null
  purchaseId: string | null
}

export interface Payment {
  invoiceNumber: string
  invoiceType: string
  collectionAmount: string
  outstanding: string
  collection_code: string
  bookDate: string
  payoutDate: string
}

export interface INotification {
  messageId: number
  title: string
  message: string
  read: boolean
  createdAt: string
  updatedAt: string
}

export interface ClaimQuestionKind {
  id: number
  name: string
}

export interface ClaimQuestionLinked {
  id: number
  displayName: string
}

export interface ClaimQuestionOption {
  checked: boolean
  id: string
  linkedQuestions: ClaimQuestionLinked[]
  name: string
  value: string
}

export interface ClaimQuestion {
  defaultValue: string
  displayName: string
  id: string
  name: string
  options: ClaimQuestionOption[]
  parentQuestions: number[]
  placeholder: string
  productClaimType: ProductClaimType
  productClaimTypeAttributeKind: ClaimQuestionKind
  required: boolean
  sortNumber: string
  tooltip: string
  value: string
}

export interface File {
  filename?: string
  filepath?: string
  height?: number
  id: string
  name: string
  size?: number
  type?: string
  uri: string
  width?: number
}

export interface Location {
  latitude: number
  longitude: number
  name: string
}

export interface Company {
  id: string
  street: string
  houseNumber: string
  houseNumberAddition: string
  city: string
  latitude: number
  longitude: number
  name: string
  emailAddress: string
  phoneNumber: string
  website: string
  zipCode: string

  distance?: number
}

export interface IRepairer extends Company {
  businessChain: string
  products: boolean
}

export interface ICarWindowRepairer extends Company {
  brandId: string[]
  products: string[]
  allBrands: boolean
  chain: boolean
}

export enum CompanyTypes {
  'car' = 'car',
  'car-window' = 'car-window',
  'recreational-vehicle' = 'recreational-vehicle',
}

export type ClaimStatus = 'Concept' | 'Gemeld bij adviseur' | 'In behandeling' | 'Afgehandeld'

export interface IClaimAttachment {
  attachment: string
  attachmentId: string
  claimDamageId: Claim['claimDamageId']
  fileName: string
  batchNumber: string | number
  fileExtension: string
}

export interface Claim {
  id: string
  claimNumber: number
  statusDescription: string
  status: string
  statusColor: string
  agent_number: number
  relation_number: number
  dateOfLoss: string
  lossNotificationDate: string
  text: string
  phoneNumber: string
  branche: number
  productName: string
  policyNumber: string
  history: boolean
  files: [{
    url: string
    title: string
    extension: string
    size: number
  }]
  building: {
    street: string
    houseNumber: string
    houseNumberAddition: string
    postalCode: string
  }
  vehicle: {
    licensePlate: string
    make: string
    model: string
    modelVersion: string
  }
}

export interface ClaimConceptCreateResponse {
  temporaryClaimReference: string
}

export interface IHistoricalClaim {
  claimNumber: number // Overlapt
  claimType: string // Overlapt
  status: ClaimStatus // Overlapt
  reportDate: string // Overlapt
  finishDate: string
  productName: string // Overlapt
  policyNumber: string // Overlapt
  licensePlate: string
  policyExpirationDate: string
  historical: boolean
  claimDate: string
}

export interface IClaimMethod {
  brancheCode: string
  description: string
  externLink: string
  buttonTitle: string
  extern: boolean
}

export interface IProduct {
  id: string
  name: string
  productClaimTypes: ProductClaimType[]
  branches: IClaimMethod[]
  followNumber: number
}

export type ClaimId = Claim['id'] | Claim['claimDamageId']

export interface FormClaimDamageResult {
  claimAttributesOptions: {
    [key: string]: string[]
  }
  productClaimAttributes: {
    [key: string]: any
  }
  claimAttachmentsUuids: string[]
}

export interface IMemberBenefit {
  id: number
  title: string
  topic: string
  intro: string
  validFrom: string
  validTill: string
  closed: boolean
  image: string
  isCampaignParticipant: boolean
  isVoucherCode: boolean
  isPdfVoucher: boolean

  sortNumber: number

  // Only on detail call
  available?: boolean
  campaignContent?: string
  externalLink?: string
  requirementsContent?: string

  voucherCode?: string
  voucherPdf?: string

  participant?: boolean
  isWinner?: boolean
  isWinnerDate?: string
}
export interface ITermsAndCondition {
  id: string
  termsOfUse: string // content
  title: string
  version: string
  typeOfSetting: string
}
