import React from 'react'
import { View, StyleSheet, TouchableOpacity, Linking } from 'react-native'
import { useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Text from '@/components/wrappers/Text'
import Sizes from '@/constants/Sizes'
import Colors from '@/constants/Colors'
import type { INews } from '@/redux/reducers/flash_news'

const Content = () => {
  const news = useSelector((state) => state.flashNews).news as INews

  const onPressReadMore = () => {
    if (news?.link) {
      if (news.link.includes('https://')) {
        Linking.openURL(news.link)
      } else {
        // TODO: Open page in app
      }
    }
  }

  return (
    <View style={styles.container}>
      <Text
        type='description'
        style={styles.topContainerText}
      >
        {news.tag}
      </Text>

      <Text
        type='descriptionBold'
        numberOfLines={2}
        style={styles.titleText}
      >
        {news.title}
      </Text>

      <Text
        type='description'
        numberOfLines={5}
        style={styles.contentText}
      >
        {news.content}
      </Text>

      {(news.linkText && news.link)
        ? (
          <TouchableOpacity
            onPress={onPressReadMore}
            style={styles.linkButton}
          >
            <Text
              type='description'
              style={[styles.linkText, styles.contentText]}
            >
              {news.linkText}
            </Text>
          </TouchableOpacity>
          )
        : null}
    </View>
  )
}

const Phone = () => (
  <>
    <View style={styles.divider} />
    <Content />
  </>
)

const Tablet = () => (
  <Bootstrap.Row>
    <Bootstrap.Column span={{ lg: 6, md: 12, sm: 12, xl: 4, xs: 12 }}>
      <Block style={styles.blockStyle}>
        <Content />
      </Block>
    </Bootstrap.Column>
  </Bootstrap.Row>
)

const FlashNews = () => {
  const news = useSelector((state) => state.flashNews).news as INews

  if (!news) {
    return null
  }

  return (
    <Bootstrap.Switch
      Phone={Phone}
      Tablet={Tablet}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: Sizes.borderRadius,
    backgroundColor: '#fff',
    paddingHorizontal: Sizes.buttonPaddingHorizontal,
    paddingVertical: Sizes.buttonPaddingVertical
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  topContainerText: {
    color: Colors.primary,
    paddingVertical: Sizes.buttonPaddingVertical
  },
  blockStyle: {
    paddingHorizontal: Sizes.spacingHorizontalMedium / 2,
    paddingVertical: Sizes.spacingVerticalMedium / 2
  },
  contentText: {
    fontSize: 12
  },
  divider: {
    height: 32
  },
  linkText: {
    color: Colors.primary
  },
  linkButton: {
    marginVertical: Sizes.buttonPaddingVertical
  },
  titleText: {
    marginBottom: Sizes.buttonPaddingVertical
  }
})

export default FlashNews
