import React from 'react'
import { Platform } from 'react-native'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Button from '@/components/buttons/Button'
import ButtonGreenCard from '@/components/buttons/ButtonGreenCard'
import Card from '@/components/Card'
import Screen from '@/components/Screen'
import Colors from '@/constants/Colors'
import CardGreenCard from '@/screens/more/components/CardGreenCard'
import Logo from '@/svgs/Logo'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import selectors from '@/redux/selectors'
import type { State } from '@/redux/reducers'
import type { ThemeColors } from '@/redux/reducers/theme'
import type { User } from '@/types/objects'
import { wipeCache } from '@/redux/actions/api'
import common from '@/utils/common'

interface TMoreProps {
  colors: ThemeColors
  email: User['email']
  wipeCache: typeof wipeCache
  hasAccessToGreenCardScreen: boolean
}

interface IItem {
  id?: string
  onPress?: (...props: any[]) => any
  title?: string
  icon?: string
  iconComponent?: any
  Component?: any
}

const itemsBasic: IItem[] = [
  {
    iconComponent: Logo,
    id: 'nh1816',
    onPress: navigation.openScreenAboutNh1816,
    title: i18n.t('Nh1816 Verzekeringen')
  },
  {
    icon: 'info',
    id: 'about-app',
    onPress: navigation.openScreenAboutApp,
    title: Platform.OS === 'web' ? i18n.t('Over de applicatie') : i18n.t('Over de app')
  },
  {
    icon: 'comments',
    id: 'questions',
    onPress: navigation.openScreenQuestions,
    title: i18n.t('Veelgestelde vragen')
  },
  {
    icon: 'lightbulb',
    id: 'tip-categories',
    onPress: navigation.openScreenTipCategories,
    title: i18n.t('Preventietips')
  },
  {
    icon: 'file-check',
    id: 'terms-of-use',
    onPress: navigation.openScreenTermsOfUse,
    title: i18n.t('Gebruiksvoorwaarden')
  },
  {
    icon: 'tag',
    id: 'benefits',
    onPress: navigation.openScreenMemberBenefits,
    title: i18n.t('Ledenvoordelen')
  },
  {
    icon: 'seedling',
    id: 'sustainability',
    onPress: () => common.openURL('https://www.nh1816.nl/duurzaam', '_blank'),
    title: i18n.t('Duurzaamheid')
  }
]

const itemsPhone = [
  ...itemsBasic,
  {
    Component: ButtonGreenCard
  }
]

const itemsTablet = [
  ...itemsBasic,
  {
    Component: CardGreenCard
  }
]

class More extends React.Component<TMoreProps> {
  renderButton = (item: IItem) => (
    <Button
      color={Colors.white}
      icon={item.icon}
      iconCircleColor={this.props.colors?.lightBrand}
      iconColor={this.props.colors?.brand}
      iconComponent={item.iconComponent}
      id={item.id}
      onPress={item.onPress}
      title={item.title}
      titleColor={Colors.gray}
    />
  )

  renderCard = (item: IItem) => (
    <Card
      icon={item.icon}
      iconComponent={item.iconComponent}
      onPress={item.onPress}
      title={item.title!}
    />
  )

  renderContent = (items: IItem[], renderItem: (item: IItem) => JSX.Element) => {
    let allItems = [...items]

    if (this.props.email === 'demo@nh1816.nl' || __DEV__) {
      allItems = allItems.concat({
        icon: 'backspace',
        id: 'wipe-cache',
        onPress: this.props.wipeCache,
        title: i18n.t('Cache legen')
      })
    }

    return (
      <Screen>
        <Bootstrap.Row
          gutterHorizontal={15}
          gutterVertical={15}
        >
          {allItems?.map((item) => (
            <Bootstrap.Column
              key={`more-column-${item?.id}`}
              span={{ lg: 6, md: 6, sm: 6, xl: 4, xs: 12 }}
            >
              {(item?.Component && <item.Component />) || renderItem(item)}
            </Bootstrap.Column>
          ))}
        </Bootstrap.Row>
      </Screen>
    )
  }

  renderPhone = () => {
    return this.renderContent(
      this.props.hasAccessToGreenCardScreen ? itemsPhone : itemsBasic,
      this.renderButton
    )
  }

  renderTablet = () => {
    return this.renderContent(
      this.props.hasAccessToGreenCardScreen ? itemsTablet : itemsBasic,
      this.renderCard
    )
  }

  render () {
    return (
      <Bootstrap.Switch
        renderPhone={this.renderPhone}
        renderTablet={this.renderTablet}
      />
    )
  }
}

const mapStateToProps = (state: State) => ({
  colors: selectors.getThemeColors(state),
  email: selectors.getEmail(state),
  hasAccessToGreenCardScreen: selectors.selectHasAccessToGreenCardsScreen(state)
})

export default connect(mapStateToProps, { wipeCache })(More)
