import React from 'react'

import Line from '@/components/Line'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import type { Policy } from '@/types/objects'
import i18n from '@/utils/i18n'

import InsuranceCards from './insurance_cards'

const InsuranceCardsTablet: React.FC<{ policy: Policy }> = ({ policy }) => {
  const multipleInsuranceCards = policy?.insuranceCards?.length > 1

  if (!multipleInsuranceCards) {
    return null
  }

  return (
    <>
      <Line
        horizontal
        color={Colors.background}
        height={1}
        style={Styling.marginVerticalMedium}
      />
      <Text
        style={Styling.marginBottomNormal}
        type='subtitle'
      >
        {multipleInsuranceCards ? i18n.t('Verzekeringskaarten') : i18n.t('Verzekeringskaart')}
      </Text>

      <InsuranceCards policy={policy} />
    </>
  )
}

export default InsuranceCardsTablet
