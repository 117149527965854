import { createStackNavigator } from '@react-navigation/stack'
import i18n from '@/utils/i18n'
import Claims from '@/screens/claims/Claims'
import Claim from '@/screens/claims/Claim'
import HistoricalClaim from '@/screens/claims/HistoricalClaim'

const ClaimsStack = createStackNavigator()
const ClaimsStackNavigator = () => (
  <ClaimsStack.Navigator screenOptions={{ headerShown: false }}>
    <ClaimsStack.Screen
      name='Claims'
      component={Claims}
      options={{ title: i18n.t('Mijn schades') }}
    />
    <ClaimsStack.Screen
      name='Claim'
      component={Claim}
      options={{ title: i18n.t('Schade') }}
    />
    <ClaimsStack.Screen
      name='HistoricalClaim'
      component={HistoricalClaim}
      options={{ title: i18n.t('Historische schade') }}
    />
  </ClaimsStack.Navigator>
)

export default ClaimsStackNavigator
