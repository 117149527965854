import { createSelector } from 'reselect'

import type { State as TState } from '@/redux/reducers'
import { allHtmlEntities } from '@/redux/selectors/helpers'

export const getQuestionRepository = (state: TState) => state.questionRepository

export const getQuestions = createSelector(getQuestionRepository, (questionRepository) =>
  questionRepository?.questions?.map((question) => ({
    ...question,
    answer: allHtmlEntities.decode(question.answer || ''),
    text: allHtmlEntities.decode(question.text || '')
  }))
)
