import React from 'react'
import type { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { connect } from 'react-redux'

import Icon from '@/components/Icon'
import Touchable from '@/components/touchables/Touchable'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import mapStateToProps from '@/redux/mapStateToProps'
import type { IconProp } from '@/types/props'

export interface TButtonProps {
  borderColor?: string
  color?: string
  colorRight?: string
  colors?: any
  disabled?: boolean
  enableHitSlop?: boolean
  icon?: IconProp
  iconCircleColor?: string
  iconCircleColorRight?: string
  iconColor?: string
  iconColorRight?: string
  iconComponent?: React.ReactElement | ((...args: any[]) => any)
  iconComponentRight?: React.ReactElement | ((...args: any[]) => any)
  iconRight?: IconProp
  iconSize?: number
  iconStyle?: StyleProp<ViewStyle>
  iconStyleRight?: StyleProp<ViewStyle>
  id?: string
  loading?: boolean
  loadingRight?: boolean
  onPress?: (...args: any[]) => any
  small?: boolean
  style?: StyleProp<ViewStyle>
  title?: string
  titleColor?: string
  titleStyle?: StyleProp<TextStyle>
  noOpacity?: boolean
}

class Button extends React.Component<TButtonProps> {
  refTouchable: any

  setRefTouchable = (ref: any) => {
    this.refTouchable = ref
  }

  focus = () => {
    if (this.refTouchable && typeof this.refTouchable.focus === 'function') {
      this.refTouchable.focus()
    }
  }

  hasIconRight = () => !!(this.props.iconRight || this.props.iconComponentRight)

  hasIcon = () => !!(this.props.icon || this.props.iconComponent)

  isDisabled = () => this.props.disabled || !this.props.onPress

  isTextCenter = () => !this.hasIcon() && !this.hasIconRight()

  renderIconRight () {
    return this.hasIconRight()
      ? (
        <Icon
          circleColor={this.props.iconCircleColorRight}
          color={this.props.iconColorRight || this.props.colorRight || (this.props.colors && this.props.colors.brand)}
          Component={this.props.iconComponentRight}
          icon={this.props.iconRight}
          style={[Styling.marginLeft, this.props.iconStyleRight]}
        />
        )
      : null
  }

  renderIcon () {
    return this.hasIcon()
      ? (
        <Icon
          circleColor={this.props.iconCircleColor}
          color={this.props.iconColor || this.props.color || (this.props.colors && this.props.colors.brand)}
          Component={this.props.iconComponent}
          icon={this.props.icon}
          size={this.props.iconSize}
          style={[Styling.marginRight, this.props.iconStyle]}
        />
        )
      : null
  }

  renderTitle () {
    return (
      <Text
        color={this.props.titleColor}
        style={[this.props.titleStyle, this.isTextCenter() ? styles.textCenter : styles.text]}
        type={this.props.small ? 'buttonSmall' : 'button'}
      >
        {this.props.title}
      </Text>
    )
  }

  renderActivityIndicator () {
    // Position absolute the activity indicator to prevent the text in the Button from moving.
    return this.props.loading
      ? (
        <ActivityIndicator
          color={this.props.iconColor || Colors.white}
          size={Sizes.iconLarge}
          style={this.isTextCenter() ? styles.activityIndicatorAbsolute : styles.activityIndicator}
        />
        )
      : null
  }

  renderActivityIndicatorRight () {
    // Position absolute the activity indicator to prevent the text in the Button from moving.
    return this.props.loadingRight
      ? (
        <ActivityIndicator
          color={this.props.iconColorRight || this.props.iconColor || Colors.white}
          size={Sizes.iconLarge}
          style={this.isTextCenter() ? styles.activityIndicatorRightAbsolute : styles.activityIndicatorRight}
        />
        )
      : null
  }

  render () {
    const containerStyle = this.props.small ? styles.containerSmall : styles.container
    const backgroundStyle = this.props.color
      ? { backgroundColor: this.props.color }
      : { backgroundColor: this.props.colors?.brand }
    const viewStyles = [
      containerStyle,
      backgroundStyle,
      this.props.disabled && { opacity: 0.5 },
      this.props.disabled && this.props.noOpacity && { opacity: 1 },
      this.props.borderColor && { borderColor: this.props.borderColor, borderWidth: 1 },
      this.props.style
    ]
    return (
      <Touchable
        accessibilityLabel={this.props.id}
        disabled={this.isDisabled()}
        hitSlop={this.props.enableHitSlop ? Sizes.hitSlop : undefined}
        key={this.props.id}
        onPress={this.props.onPress}
        ref={this.setRefTouchable}
        style={viewStyles}
      >
        {this.renderIcon()}
        {this.renderActivityIndicator()}
        {this.renderTitle()}
        {this.renderIconRight()}
        {this.renderActivityIndicatorRight()}
      </Touchable>
    )
  }
}

const containerStyle = {
  alignItems: 'center',
  borderRadius: Sizes.borderRadius,
  flexDirection: 'row',
  overflow: 'hidden',
  paddingHorizontal: Sizes.buttonPaddingHorizontal
}

const styles = StyleSheet.create({
  activityIndicator: {
    marginRight: Sizes.buttonPaddingHorizontal
  },
  activityIndicatorAbsolute: {
    left: Sizes.buttonPaddingHorizontal,
    position: 'absolute'
  },
  activityIndicatorRight: {
    marginLeft: Sizes.buttonPaddingHorizontal
  },
  activityIndicatorRightAbsolute: {
    position: 'absolute',
    right: Sizes.buttonPaddingHorizontal
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"hidden" ... Remove this comment to see the full error message
  container: {
    ...containerStyle,
    minHeight: Sizes.button,
    paddingVertical: Sizes.buttonPaddingVertical
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"hidden" ... Remove this comment to see the full error message
  containerSmall: {
    ...containerStyle,
    minHeight: Sizes.buttonSmall
  },
  text: {
    // This should not be 'flex'.
    // flexGrow: 1,
    // flexShrink: 1,
    flexWrap: 'wrap'
  },
  textCenter: {
    // This should not be 'flex'.
    // flexGrow: 1,
    // flexShrink: 1,
    flexWrap: 'wrap',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  }
})

export default connect(mapStateToProps.themeColors, null, null, { forwardRef: true })(Button)
