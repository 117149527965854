import { generateHeaderOptions, renderStackScreens } from '@/navigation/mobile/helpers/navigation_helpers'
import initialRouteNames from '@/navigation/mobile/helpers/initialRouteNames'
import type { Routes } from '@/navigation/mobile/helpers/types'
import Verify from '@/screens/verify/Verify'
import { createStackNavigator } from '@react-navigation/stack'

const VerifyStack = createStackNavigator()

const renderVerifyStack = () => {
  return (
    <VerifyStack.Navigator
      initialRouteName={initialRouteNames.VerifyStack}
      key='stack-navigator-verify'
      screenOptions={generateHeaderOptions()}
    >
      {renderStackScreens(VerifyStack, 'VerifyStack', routes)}
    </VerifyStack.Navigator>
  )
}

export default renderVerifyStack

export const routes: Routes = {
  Verify: {
    component: Verify,
    options: {
      headerShown: false
    }
  }
}
