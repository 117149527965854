import { useFocusEffect } from '@react-navigation/core'
import { useCallback } from 'react'

interface OnFocusProps {
  onFocus: () => void
}

const OnFocus = ({
  onFocus
}: OnFocusProps) => {
  useFocusEffect(useCallback(() => {
    onFocus()
  }, [onFocus]))

  return null
}

export default OnFocus
