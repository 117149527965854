import React from 'react'

import i18n from '@/utils/i18n'

import InputRadioButton from './InputRadioButton'

interface IProps {
  label?: string
  onChange: (value: boolean) => void
  required?: boolean
  tooltip?: string
  value: boolean | undefined
}

const radioItems = [
  {
    name: i18n.t('Ja'),
    value: true
  },
  {
    name: i18n.t('Nee'),
    value: false
  }
]

const InputCheckBoxYesNo: React.FC<IProps> = (props) => (
  <InputRadioButton
    label={props.label}
    required={props.required}
    tooltip={props.tooltip}
    items={radioItems}
    onChange={props.onChange}
    value={props.value}
  />
)

export default InputCheckBoxYesNo
