import { createStackNavigator } from '@react-navigation/stack'
import i18n from '@/utils/i18n'
import More from '@/screens/more/More'
import AboutApp from '@/screens/more/AboutApp'
import AboutNh1816 from '@/screens/more/AboutNh1816'
import Questions from '@/screens/more/Questions'
import TermsOfUse from '@/screens/more/TermsOfUse'
import TipsStackNavigator from './tips_stack'

const MoreStack = createStackNavigator()
const MoreStackNavigator = () => (
  <MoreStack.Navigator screenOptions={{ headerShown: false }}>
    <MoreStack.Screen
      name='More'
      component={More}
      options={{ title: i18n.t('Meer') }}
    />

    <MoreStack.Screen
      name='AboutApp'
      component={AboutApp}
      options={{ title: i18n.t('Over de app') }}
    />
    <MoreStack.Screen
      name='AboutNh1816'
      component={AboutNh1816}
      options={{ title: i18n.t('Over Nh1816 Verzekeringen') }}
    />
    <MoreStack.Screen
      name='Questions'
      component={Questions}
      options={{ title: i18n.t('Veelgestelde vragen') }}
    />
    <MoreStack.Screen
      name='TermsOfUse'
      component={TermsOfUse}
      options={{ title: i18n.t('Gebruiksvoorwaarden') }}
    />

    <MoreStack.Screen
      name='TipsStack'
      component={TipsStackNavigator}
    />

    {/* <MoreStack.Screen
      name='MemberBenefitsStack'
      component={MemberBenefitsStackNavigator}
    /> */}
  </MoreStack.Navigator>
)

export default MoreStackNavigator
