import React from 'react'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import ButtonForm from '@/components/buttons/ButtonForm'
import Form from '@/components/Form'
import Input from '@/components/inputs/Input'
import InputDateTime from '@/components/inputs/InputDateTime'
import Screen from '@/components/Screen'
import Colors from '@/constants/Colors'
import type { TApiCallProps } from '@/hocs/withApiCall'
import withApiCall from '@/hocs/withApiCall'
import * as userApi from '@/redux/api/user'
import { apiCall as apiCallDispatch } from '@/redux/actions/api'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import type { IAddUserParams } from '@/redux/types/user'
import type { Relation, User } from '@/types/objects'
import type { TNavigationProp } from '@/types/props'
import common from '@/utils/common'
import { parseEmailAddress } from '@/utils/email'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'
import toast from '@/utils/toast'
import { updateUser } from '../../redux/actions/user'
import { IS_WEB } from '../../constants/platform'

interface TOwnProps {
  navigation: TNavigationProp
  apiCallDispatch: typeof apiCallDispatch
}

type TManageUserProps = TOwnProps &
TApiCallProps & {
  relationId: Relation['id']
  user?: User
  userId?: User['id']
  updateUser: typeof updateUser
}

type TManageUserState = IAddUserParams & {
  loading: boolean
  showError: boolean
}

class ManageUser extends React.Component<TManageUserProps, TManageUserState> {
  static navigationOptions: any

  constructor (props: TManageUserProps) {
    super(props)

    this.state = {
      dateOfBirth: props?.user?.dateOfBirth ?? '',
      email: props?.user?.email ?? '',
      firstName: props?.user?.firstName ?? '',
      initials: props?.user?.initials ?? '',
      lastName: props?.user?.lastName ?? '',
      loading: false,
      showError: false
    }
  }

  componentDidMount () {
    if (!IS_WEB) {
      this.props?.navigation?.setParams({
        title: this.props.userId ? i18n.t('Wijzigen') : i18n.t('Toevoegen')
      })
    }
  }

  isNewUser = () => !this.props.user || !Object.keys(this.props.user).length

  onChangeDateOfBirth = (dateOfBirth: User['dateOfBirth']) => this.setState({ dateOfBirth })

  onChangeEmail = (email: User['email']) => this.setState({ email })

  onChangeFirstName = (firstName: User['firstName']) => this.setState({ firstName })

  onChangeInitials = (initials: User['initials']) => this.setState({ initials })

  onChangeLastName = (lastName: User['lastName']) => this.setState({ lastName })

  onPressSubmit = async () => {
    if (this.isFormValid() && !this.state.loading) {
      this.setState({ loading: true })

      const userData: IAddUserParams = {
        dateOfBirth: this.state.dateOfBirth,
        email: parseEmailAddress(this.state.email),
        firstName: this.state.firstName,
        initials: this.state.initials,
        lastName: this.state.lastName
      }

      let result

      if (this.isNewUser()) {
        result = await this.props.apiCall(userApi.addUser, this.props.relationId, userData)

        if (result) {
          toast(i18n.t('De gebruiker is uitgenodigd. Hij of zij ontvangt een registratielink per e-mail.'))
        } else {
          this.setState({ showError: true })
          toast(i18n.t('De gebruiker kon niet worden uitgenodigd.'))
        }
      } else {
        // @ts-expect-error The operand of a 'delete' operator must be optional.
        delete userData.email

        result = await this.props.apiCall(
          userApi.updateUser,
          this.props.relationId,
          this.props.userId,
          this.props.user,
          userData
        )

        if (result) {
          toast(i18n.t('De gebruiker is gewijzigd'))
        } else {
          toast(i18n.t('De gebruiker kon niet worden gewijzigd'))
        }
      }

      if (result) {
        this.props.apiCallDispatch('GET', 'USERS', this.props.relationId, undefined, true)
        this.props.updateUser(userData)
        navigation.openScreenManageUsers()
      }

      this.setState({ loading: false })
    }
  }

  // The first name input is not mandatory.
  isFormValid = () => {
    const newUser = this.isNewUser()

    return (
      this.state.dateOfBirth &&
      (this.state.initials || this.state.firstName) &&
      this.state.lastName &&
      (newUser || this.state.email) &&
      (newUser || common.validateEmail(this.state.email))
    )
  }

  renderContent = () => {
    const disabled = this.state.loading || !this.isFormValid()
    const newUser = this.isNewUser()

    return (
      <Form>
        {newUser
          ? (
            <Input
              autoCapitalize='none'
              icon='envelope'
              label={i18n.t('E-mailadres')}
              onChange={this.onChangeEmail}
              placeholder={i18n.t('E-mailadres gebruiker')}
              value={this.state.email}
              error={this.state.showError}
              errorDescription='Er bestaat mogelijk al een gebruiker met dit e-mailadres.'
            />
            )
          : null}

        <Input
          label={i18n.t('Voornaam')}
          onChange={this.onChangeFirstName}
          value={this.state.firstName}
        />

        <Input
          label={i18n.t('Voorletter(s)')}
          onChange={this.onChangeInitials}
          value={this.state.initials}
        />

        <Input
          label={i18n.t('Achternaam')}
          onChange={this.onChangeLastName}
          value={this.state.lastName}
        />

        <InputDateTime
          label={i18n.t('Geboortedatum')}
          minDate={new Date('1900-01-01')}
          mode='date'
          onChange={this.onChangeDateOfBirth}
          value={this.state.dateOfBirth}
        />

        <ButtonForm
          color={Colors.primary}
          disabled={disabled}
          loading={this.state.loading}
          onPress={this.onPressSubmit}
          title={newUser ? i18n.t('Gebruiker uitnodigen') : i18n.t('Wijzigingen opslaan')}
        />
      </Form>
    )
  }

  renderPhone = () => <Screen white>{this.renderContent()}</Screen>

  renderTablet = () => (
    <Screen>
      <Block noFlex>
        <Bootstrap.Row>
          <Bootstrap.Column span={{ lg: 6, md: 9, sm: 12, xl: 4, xs: 12 }}>{this.renderContent()}</Bootstrap.Column>
        </Bootstrap.Row>
      </Block>
    </Screen>
  )

  render () {
    return (
      <Bootstrap.Switch
        renderPhone={this.renderPhone}
        renderTablet={this.renderTablet}
      />
    )
  }
}

ManageUser.navigationOptions = (props: any) => ({
  title: common.getNavigationParam(props, 'title') || i18n.t('Gebruiker beheren')
})

const mapStateToProps = (state: TState, ownProps: TOwnProps) => {
  const userId = common.getNavigationParam(ownProps, 'userId')
  const user = state.user?.id === userId ? state.user : (state.users || []).find((u: User) => u.id === userId)

  return {
    relation: selectors.getCurrentRelation(state),
    relationId: selectors.getCurrentRelationId(state)!,
    user,
    userId
  }
}

export default connect(mapStateToProps, { apiCallDispatch, updateUser })(withApiCall(ManageUser))
