import type { File } from '@/components/inputs/InputFile'
import api from '@/utils/api_wrapper'

export const getRelationUserToken = async () =>
  await api.get('my-app/claims/token')

export const createClaimConcept = async (policyNumber: number, agentNumber: number, relationNumber: number, branche: number) =>
  await api.post('my-app/claims/create', { policyNumber, agentNumber, relationNumber, branche })

export const updateClaimConcept = async (id: string, dateOfLoss: string, text: string, telephoneNumber: string, policyNumber: number) =>
  await api.put(`my-app/claims/${id}`, { dateOfLoss, text, telephoneNumber, policyNumber })

export const storeClaimConcept = async (id: string, dateOfLoss: string, text: string, telephoneNumber: string, policyNumber: number) =>
  await api.post(`my-app/claims/${id}`, { dateOfLoss, text, telephoneNumber, policyNumber })

export const deleteClaimConcept = async (id: string) =>
  await api.delete(`my-app/claims/${id}`)

export const getClaims = async (relationNumber: number) =>
  await api.get('my-app/claims')

export const getClaim = async (id: string) =>
  await api.get(`my-app/claims/${id}`)

export const uploadAdditionalClaimAttachment = async (id: string, file: File) =>
  await api.post(`my-app/claims/${id}/upload`, { file: file.base64, name: file.name })

export const removeAdditionalClaimAttachment = async (fileUrl: string) =>
  await api.delete(`${fileUrl}`, false, true)
