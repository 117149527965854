import React from 'react'
import { StyleSheet, View } from 'react-native'

import ContextSpacing from '@/bootstrap/ContextSpacing'
import defaults from '@/bootstrap/defaults'

interface TGridProps {
  children?: React.ReactNode
}

export default function Grid (props: TGridProps) {
  return (
    <ContextSpacing.Provider value={defaults.spacing}>
      <View style={styles.view}>{props.children}</View>
    </ContextSpacing.Provider>
  )
}

const styles = StyleSheet.create({
  view: {
    paddingHorizontal: defaults.spacing.horizontal / 2,
    paddingVertical: defaults.spacing.vertical / 2
  }
})
