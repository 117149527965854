import { createStackNavigator } from '@react-navigation/stack'
import i18n from '@/utils/i18n'
import MemberBenefits from '@/screens/more/member_benefits/list'
import MemberBenefitsDetail from '@/screens/more/member_benefits/detail'

const MemberBenefitsStack = createStackNavigator()
const MemberBenefitsStackNavigator = () => (
  <MemberBenefitsStack.Navigator screenOptions={{ headerShown: false }}>
    <MemberBenefitsStack.Screen
      name='MemberBenefits'
      component={MemberBenefits}
      options={{ title: i18n.t('Ledenvoordelen') }}
    />

    <MemberBenefitsStack.Screen
      name='MemberBenefitsDetail'
      component={MemberBenefitsDetail}
      options={{ title: i18n.t('Ledenvoordeel') }}
    />
  </MemberBenefitsStack.Navigator>
)

export default MemberBenefitsStackNavigator
