import React from 'react'
import type { GestureResponderEvent, StyleProp, ViewStyle } from 'react-native'
import { TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'

import Icon from '@/components/Icon'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import mapStateToProps from '@/redux/mapStateToProps'

const supportedTypes = ['left', 'right', 'up', 'down']

interface TActionProps {
  colors: any
  label: string
  onPress?: (event: GestureResponderEvent) => void
  style?: StyleProp<ViewStyle>
  type: string
}

function Action (props: TActionProps) {
  return supportedTypes.includes(props.type)
    ? (
      <TouchableOpacity
        onPress={props.onPress}
        style={props.style ? [Styling.rowCenter, props.style] : Styling.rowCenter}
      >
        <Icon
          small
          color={props.colors && props.colors.brand}
          icon={['fal', `arrow-${props.type}`]}
          style={Styling.marginRightTiny}
        />
        <Text
          color={props.colors && props.colors.brand}
          type='action'
        >
          {' '}
          {props.label}
        </Text>
      </TouchableOpacity>
      )
    : null
}

export default connect(mapStateToProps.themeColors)(Action)
