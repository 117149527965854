import React from 'react'
import { connect } from 'react-redux'

import i18n from '@/utils/i18n'

import * as Bootstrap from '@/bootstrap'
import EmptyState from '@/components/EmptyState'
import Screen from '@/components/Screen'
import RefreshControl from '@/components/wrappers/RefreshControl'
import Text from '@/components/wrappers/Text'
import ListItemPayment from '@/screens/case/components/ListItemPayment'
import Styling from '@/constants/Styling'

import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import { apiCall } from '@/redux/actions/api'

import type { Payment } from '@/types/objects'

interface TPaymentsProps {
  paymentSections: any[]
  refreshing: boolean
  apiCall: typeof apiCall
}

class Payments extends React.Component<TPaymentsProps> {
  componentDidMount () {
    this.refresh()
  }

  refresh = () => this.props.apiCall('GET', 'PAYMENTS', undefined, undefined, true)

  keyExtractor = (payment: Payment) => `list-item-payment-${payment.invoiceNumber}`

  renderItem = ({ item: payment }: { item: Payment }) => <ListItemPayment payment={payment} />

  renderSectionHeader = ({ section }: any) => (
    <Text
      style={[Styling.marginBottomTiny, section.index !== 0 && Styling.marginTopMedium]}
      type='subsection'
    >
      {section.title}
    </Text>
  )

  renderRefreshControl = () => (
    <RefreshControl
      onRefresh={this.refresh}
      refreshing={this.props.refreshing}
      title={i18n.t('Betalingen aan het ophalen')}
    />
  )

  renderEmptyState = () => (
    <EmptyState
      message={this.props.refreshing
        ? i18n.t('De betalingen worden opgehaald')
        : i18n.t('U heeft nog geen betalingen')}
    />
  )

  render () {
    return (
      <Screen refreshControl={this.renderRefreshControl()}>
        {this.props.paymentSections?.length
          ? (
            <Bootstrap.Collection
              section
              gutter={15}
              id='payments-collection'
              keyExtractor={this.keyExtractor}
              numColumns={1}
              renderItem={this.renderItem}
              renderSectionHeader={this.renderSectionHeader}
              sections={this.props.paymentSections}
            />
            )
          : this.renderEmptyState()}
      </Screen>
    )
  }
}

const mapStateToProps = (state: TState) => ({
  paymentSections: selectors.getPaymentByYear(state),
  refreshing: !!selectors.isLoading('PAYMENTS')(state)
})

export default connect(mapStateToProps, { apiCall })(Payments)
