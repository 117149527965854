import React, { Component } from 'react'
import { Keyboard } from 'react-native'
import { connect } from 'react-redux'
import type { EmitterSubscription } from 'react-native'

import Button from '@/components/buttons/Button'
import Input from '@/components/inputs/Input'
import type { IPickerItem } from '@/components/inputs/InputSelect'
import InputSelect from '@/components/inputs/InputSelect'
import Modal from '@/components/wrappers/Modal'
import Text from '@/components/wrappers/Text'
import Styling from '@/constants/Styling'
import {
  removeFilterLocation,
  updateFilterCompanyType,
  updateFilterLocation,
  updateFilterRadius,
  updateFilter
} from '@/redux/actions/filters'
import type { State as TState } from '@/redux/reducers'
import type { FiltersState } from '@/redux/reducers/filters'
import type { ThemeColors } from '@/redux/reducers/theme'
import selectors from '@/redux/selectors'
import type { Location, Relation } from '@/types/objects'
import { CompanyTypes } from '@/types/objects'
import data from '@/utils/data'
import i18n from '@/utils/i18n'
import locationUtil from '@/utils/location'
import logger from '@/utils/logger'
import { ENTITIES } from '@/redux/constants'
import { getForegroundPermissionsAsync, requestForegroundPermissionsAsync } from 'expo-location'

interface TModalFiltersProps {
  colors: ThemeColors
  filters: FiltersState
  onHide?: () => void
  visible: boolean
  relation: Relation
  loadingBrands: boolean
  carWindowRepairerBrands: IPickerItem<string | null>[]
  removeFilterLocation: typeof removeFilterLocation
  updateFilterCompanyType: typeof updateFilterCompanyType
  updateFilterLocation: typeof updateFilterLocation
  updateFilterRadius: typeof updateFilterRadius
  updateFilter: typeof updateFilter
}

interface TModalFiltersState {
  companyType: CompanyTypes
  latitude: number | null
  loading: boolean
  locationError: string
  locationName: string
  longitude: number | null
  permissionDenied: boolean
  radius: number
  showPermissionDenied: boolean
  verifying: boolean
  brand: IPickerItem<string | null>['value']
}

class ModalFilters extends Component<TModalFiltersProps, TModalFiltersState> {
  companyTypeItems: ReturnType<typeof data.getItemsCompanyType>

  locationName: Location['name']

  radiusItems: ReturnType<typeof data.getItemsRadius>

  keyboardOpen: boolean

  keyboardDidShowListener?: EmitterSubscription
  keyboardDidHideListener?: EmitterSubscription

  constructor (props: TModalFiltersProps) {
    super(props)

    const filters = props?.filters
    const location = filters?.location

    this.radiusItems = data.getItemsRadius()
    this.companyTypeItems = data.getItemsCompanyType()
    this.locationName = location?.name ?? ''

    this.keyboardOpen = false

    this.state = {
      companyType: filters?.companyType ?? data.getDefaultCompanyType(),
      latitude: location?.latitude ?? null,
      loading: false,
      locationError: '',
      locationName: this.locationName,
      longitude: location?.longitude ?? null,
      permissionDenied: false,
      radius: filters?.radius ?? data.getDefaultRadius(),
      showPermissionDenied: false,
      verifying: false,
      brand: filters?.brand
    }
  }

  componentDidMount () {
    this.init()

    this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      this.keyboardOpen = true
    })
    this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      this.keyboardOpen = false
    })
  }

  componentWillUnmount () {
    this?.keyboardDidShowListener?.remove()
    this?.keyboardDidHideListener?.remove()
  }

  logError = (error: any) => logger.warn('ModalFilters:', String(error), error)

  init = async () => {
    const { status } = await getForegroundPermissionsAsync()
    this.setState({ permissionDenied: status !== 'granted' })

    if (!this.state.locationName || !this.state.permissionDenied) {
      return
    }

    const locationName = this.props.relation?.zipCode || this.props.relation?.city

    if (locationName) {
      const positions = await locationUtil.geocodeAsync(locationName)

      if (positions?.[0]?.latitude && positions?.[0]?.longitude) {
        this.setState({
          latitude: positions[0].latitude,
          locationName,
          longitude: positions[0].longitude
        })
      }
    }
  }

  determineLocation = async () => {
    const { status } = await requestForegroundPermissionsAsync()
    this.setState({ permissionDenied: status !== 'granted' })

    if (this.state.loading || this.state.permissionDenied) {
      return
    }

    this.setState({ loading: true })

    try {
      const position = await locationUtil.getCurrentPositionAsync()

      if (position?.coords?.latitude && position?.coords?.longitude) {
        const locations = await locationUtil.reverseGeocodeAsync(position.coords)

        if (locations?.[0]) {
          const locationName = locations[0].postalCode || locations[0].city

          if (locationName) {
            this.setState({
              latitude: position.coords.latitude,
              locationName,
              longitude: position.coords.longitude
            })
          }
        }
      }
    } catch (error) {
      this.logError(error)
    }

    this.setState({ loading: false })
  }

  onChangeCompanyType = (companyType: CompanyTypes) => this.setState({ companyType })

  onChangeRadius = (radius: number) => this.setState({ radius })

  onChangeLocationName = async (locationName: string) => {
    if (!locationName || !locationName.length) {
      this.setState({ locationError: '' })
    }

    this.setState({
      latitude: null,
      locationName,
      longitude: null
    })
  }

  onSubmit = async () => {
    // Some devices postfix the input with a space because of autocorrection.
    const locationName = (this.state.locationName || '').trim()
    let locationError = ''

    // Only geocode the 'new' location name when it has actually changed.
    if (locationName !== this.locationName) {
      let location = null

      if (this.state.latitude && this.state.longitude) {
        location = {
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          name: locationName
        }
      } else {
        this.setState({ loading: true })

        const positions = await locationUtil.geocodeAsync(locationName)

        if (positions?.[0]?.latitude && positions?.[0]?.longitude) {
          this.setState({ locationError: '' })

          location = {
            latitude: positions[0].latitude,
            longitude: positions[0].longitude,
            name: locationName
          }
        } else {
          locationError = i18n.t('De ingevoerde locatie kon niet worden gevonden')
        }

        this.setState({ loading: false })
      }

      if (location) {
        this.props.updateFilterLocation(location)
      }
    } else if (!locationName && this.locationName) {
      this.props.removeFilterLocation()
    }

    this.props.updateFilter({
      companyType: this.state.companyType,
      radius: this.state.radius,
      brand: this.state.brand
    })

    if (locationError) {
      this.setState({ locationError })
    } else if (typeof this.props.onHide === 'function') {
      this.props.onHide()
    }
  }

  onHide = () => {
    if (this.keyboardOpen) {
      Keyboard.dismiss()
    } else if (typeof this.props.onHide === 'function') {
      this.props.onHide()
    }
  }

  render () {
    return (
      <Modal
        showClose
        onHide={this.onHide}
        visible={this.props.visible}
      >
        {this.state.permissionDenied && this.state.showPermissionDenied
          ? (
            <Text
              style={Styling.marginBottom}
              type='inputError'
            >
              {i18n.t('Voor het bepalen van de huidige locatie is geen toestemming gegeven')}
            </Text>
            )
          : null}

        <Input
          editable={!this.state.loading}
          error={!!this.state.locationError}
          errorDescription={this.state.locationError}
          iconLocation={this.state.permissionDenied ? 'location-slash' : 'location'}
          label={i18n.t('Locatie')}
          loading={this.state.loading}
          onChange={this.onChangeLocationName}
          onPressLocation={this.determineLocation}
          placeholder={i18n.t('Bijv. Oudkarspel of 1724 NT')}
          showLocation={!this.state.loading}
          value={this.state.locationName}
        />

        <InputSelect
          items={this.radiusItems}
          label={i18n.t('Radius')}
          onChange={this.onChangeRadius}
          value={this.state.radius}
        />

        <InputSelect
          items={this.companyTypeItems}
          label={i18n.t('Type hersteller')}
          onChange={this.onChangeCompanyType}
          value={this.state.companyType}
        />

        {this.state.companyType === CompanyTypes['car-window']
          ? (
            <InputSelect
              disabled={this.props.loadingBrands}
              items={this.props.carWindowRepairerBrands}
              label='Merk'
              loading={this.props.loadingBrands}
              onChange={(brand) => this.setState({ brand })}
              placeholder={i18n.t('Selecteer merk...')}
              value={this.state.brand}
            />
            )
          : null}

        <Button
          color={this.props.colors?.brand}
          disabled={this.state.locationName === ''}
          loading={this.state.verifying}
          onPress={this.onSubmit}
          title={i18n.t('Pas filters toe')}
        />
      </Modal>
    )
  }
}

const mapStateToProps = (state: TState) => ({
  colors: selectors.getThemeColors(state),
  filters: selectors.getFilters(state),
  relation: selectors.getCurrentRelation(state)!,
  carWindowRepairerBrands: selectors.getReadableCarBrands(state),
  loadingBrands: !!selectors.isLoading(ENTITIES.CAR_WINDOW_REPAIRER_BRANDS)(state)
})

const mapDispatchToProps = {
  removeFilterLocation,
  updateFilterCompanyType,
  updateFilterLocation,
  updateFilterRadius,
  updateFilter
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilters)
