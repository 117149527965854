// This is a deprecated implementation for API calls
// Please use @/utils/api_wrapper

import { format } from 'date-fns'
import hoistNonReactStatics from 'hoist-non-react-statics'
import React from 'react'
import { connect } from 'react-redux'

import { DATE_TIME_FORMAT } from '@/constants/date_time'
import { lock, updateTokenExpiresAt, updateTokenExtendedAt } from '@/redux/actions/user'
import i18n from '@/utils/i18n'
import toast from '@/utils/toast'
import type { IApiResponse } from '@/utils/api_wrapper'

interface TWithApiCallProps {
  lock: typeof lock
  updateTokenExpiresAt: typeof updateTokenExpiresAt
  updateTokenExtendedAt: typeof updateTokenExtendedAt
}

export interface TApiCallProps {
  apiCall: <T = any>(...args: any[]) => Promise<IApiResponse<T>['data']>
  apiCallRaw: <T = any>(...args: any[]) => Promise<IApiResponse<T>>
  apiCallSkip: <T = any>(...args: any[]) => Promise<IApiResponse<T>['data']>
}

export default function withApiCall<P extends object> (Component: React.ComponentType<P & TApiCallProps>) {
  class WithApiCall extends React.Component<P & TWithApiCallProps> {
    // @note: Keep this implementation in sync with the other api wrappers.
    apiCallRaw = async (apiFunction: any, ...params: any[]) => {
      const result = await apiFunction(...params)
      if (result) {
        if (String(result.statusCode) === '401') {
          toast(i18n.t('U dient zich opnieuw te verifiëren omdat uw sessie is verlopen'))
          this.props.lock()
        } else if (result.tokenExpiresAt) {
          this.props.updateTokenExpiresAt(result.tokenExpiresAt)
          this.props.updateTokenExtendedAt(format(new Date(), DATE_TIME_FORMAT))
        }
      }
      return result
    }

    apiCall = async (apiFunction: any, ...params: any[]) => {
      const result = await this.apiCallRaw(apiFunction, ...params)
      // The newer versions of the api endpoints in Betty use a different json format.
      if (result && 'data' in result) {
        return result.data
      }
      return result
    }

    apiCallSkip = async (apiFunction: any, ...params: any[]) => {
      const result = await apiFunction(...params)
      // The newer versions of the api endpoints in Betty use a different json format.
      if (result && 'data' in result) {
        return result.data
      }
      return result
    }

    render () {
      return (
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(this.props as P)}
          apiCall={this.apiCall}
          apiCallRaw={this.apiCallRaw}
          apiCallSkip={this.apiCallSkip}
        />
      )
    }
  }
  hoistNonReactStatics(WithApiCall, Component)
  const mapDispatchToProps = {
    lock,
    updateTokenExpiresAt,
    updateTokenExtendedAt
  }
  return connect(null, mapDispatchToProps)(WithApiCall)
}
