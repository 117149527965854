import React, { useEffect } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Icon from '@/components/Icon'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'
import ListItemPolicyButton from '@/screens/claim_damage/components/ListItemPolicyButton'

import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'

import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

import { apiCall } from '@/redux/actions/api'
import { updateFormClaimDamageStatic } from '@/redux/actions/forms'
import { ENTITIES } from '@/redux/constants'
import selectors from '@/redux/selectors'

import type { Policy } from '@/types/objects'

const ClaimDamage: React.FC = () => {
  const dispatch = useDispatch()

  const policies = useSelector(selectors.getPoliciesClaimDamage)
  const policiesLoading = !!useSelector(selectors.isLoading('POLICIES'))

  useEffect(() => {
    dispatch(apiCall('GET', ENTITIES.POLICIES))
    dispatch(apiCall('GET', ENTITIES.GETRELATIONUSERTOKEN))
    dispatch(apiCall('GET', ENTITIES.RELATIONS))
  }, [])

  const onPressItem = (policy: Policy) => {
    if (policy?.id) {
      dispatch(updateFormClaimDamageStatic('policyId', policy.id))
      dispatch(updateFormClaimDamageStatic('productClaimTypeName', undefined))
      dispatch(apiCall('GET', ENTITIES.PRODUCT, String(policy.productId)))
      navigation.openScreenClaimDamageForm()
    }
  }

  const renderItem = ({ item }: { item: Policy }) => (
    <ListItemPolicyButton
      policyStatus
      onPress={onPressItem}
      policy={item}
      style={{ marginVertical: 8 }}
    />
  )

  const renderContent = () => {
    return (
      <View style={Styling.itemsCenter}>
        <Icon
          color={Colors.brown}
          icon='fragile'
          size={90}
          style={Styling.marginBottomMedium}
        />

        <Text
          center
          style={Styling.marginBottom}
          type='title'
        >
          {i18n.t('Schade melden')}
        </Text>

        <Text
          center
          style={Styling.marginBottomMedium}
          type='description'
        >
          {i18n.t(
            'Oei, wat vervelend! U heeft schade opgelopen. We willen u hier graag bij helpen. Om u te helpen moeten we wel eerst even weten op welke polis u de schade wilt melden.'
          )}
        </Text>

        {policiesLoading
          ? (
            <ActivityIndicator
              color={Colors.primary}
              size='small'
              style={Styling.marginBottomMedium}
            />
            )
          : null}

        <Bootstrap.Collection
          white
          data={policies.filter((policy) => policy.isAllowedToReportClaim)}
          gutter={0}
          keyExtractor={(policy: Policy) => String(policy.id)}
          margin={0}
          renderItem={renderItem}
          span={{ lg: 6, md: 6, sm: 12, xl: 4, xs: 12 }}
          style={Styling.fullWidth}
        />
      </View>
    )
  }

  return (
    <Bootstrap.Switch
      renderPhone={() => (
        <Screen white>
          {renderContent()}
        </Screen>
      )}
      renderTablet={() => (
        <Screen>
          <Bootstrap.Row>
            <Bootstrap.Column
              span={{ lg: 12, md: 12, sm: 12, xl: 10, xs: 12, xxl: 9 }}
              style={Styling.autoHorizontal}
            >
              <Block noFlex>
                {renderContent()}
              </Block>
            </Bootstrap.Column>
          </Bootstrap.Row>
        </Screen>
      )}
    />
  )
}

export default ClaimDamage
