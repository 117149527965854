import { createStackNavigator } from '@react-navigation/stack'
import i18n from '@/utils/i18n'
import DamageRepairVehicle from '@/screens/overview/DamageRepairVehicle'
import DamageRepair from '@/screens/overview/DamageRepair'

const DamageRepairStack = createStackNavigator()
const DamageRepairStackNavigator = () => (
  <DamageRepairStack.Navigator screenOptions={{ headerShown: false }}>
    <DamageRepairStack.Screen
      name='DamageRepairVehicle'
      component={DamageRepairVehicle}
      options={{ title: i18n.t('Voertuigschade') }}
    />

    <DamageRepairStack.Screen
      name='DamageRepair'
      component={DamageRepair}
      options={{ title: i18n.t('Schadeherstellers') }}
    />
  </DamageRepairStack.Navigator>
)

export default DamageRepairStackNavigator
