import React from 'react'
import { View } from 'react-native'

import Line from '@/components/Line'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import type { Policy } from '@/types/objects'
import i18n from '@/utils/i18n'

const ClausesTablet: React.FC<{ policy: Policy }> = ({ policy }) => {
  if (!policy?.clauses?.length) {
    return null
  }

  return (
    <>
      <Line
        horizontal
        color={Colors.background}
        height={1}
        style={Styling.marginVerticalMedium}
      />

      <Text
        style={Styling.marginBottomNormal}
        type='subtitle'
      >
        {i18n.t('Clausules')}
      </Text>

      {policy.clauses.map((clause, index) => (
        <View
          key={`clause-block-toggle-${clause.id}`}
          style={index !== 0 && Styling.marginTopMedium}
        >
          <Text type='subsection'>{clause.name}</Text>
          <Text type='description'>{clause.description}</Text>
        </View>
      ))}
    </>
  )
}

export default ClausesTablet
