import type { GeneratedGreenCard } from './../../types/objects'

interface GeneratedGreenCardAction {
  type: 'GENERATED_GREEN_CARD'
  payload: {
    id: string
    data: GeneratedGreenCard
  }
}

export const generatedGreenCard = (policyId: string, data: GeneratedGreenCard): GeneratedGreenCardAction => ({
  type: 'GENERATED_GREEN_CARD',
  payload: {
    id: policyId,
    data
  }
})
