interface NumberObject { [key: string]: number }

const breakpoints: NumberObject = {
  xxs: 0,
  xs: 364,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1600
}

const gutters: NumberObject = {
  xs: 0,
  xxs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0
}

const maxContainerWidths: NumberObject = {
  xs: 340,
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
  xxl: 1320,
  xxxl: 1500
}

const spacing: NumberObject = {
  horizontal: 30,
  vertical: 30
}

export default {
  breakpoints,
  columns: 12,
  gutters,
  maxContainerWidths,
  spacing
}
