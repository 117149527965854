import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import Svg, { Defs, G, Mask, Path, Use } from 'react-native-svg'

interface TIdinProps {
  style?: StyleProp<ViewStyle>
  width?: number
}

const defaultWidth = 20
const defaultHeight = 18
export const ratio = defaultHeight / defaultWidth

export default function SvgComponent (props: TIdinProps) {
  const width = props.width || defaultWidth
  const height = props.width ? width * ratio : defaultHeight
  return (
    <Svg
      height={height}
      style={props.style}
      viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}
      width={width}
    >
      <Defs>
        <Path
          d='M0 .004h19.95v17.587H0z'
          id='prefix__a'
        />
        <Path
          d='M0 17.587h19.95V0H0z'
          id='prefix__c'
        />
      </Defs>
      <G
        fill='none'
        fillRule='evenodd'
        transform='translate(-1)'
      >
        <G transform='translate(1 .069)'>
          <Mask
            fill='#fff'
            id='prefix__b'
          >
            <Use xlinkHref='#prefix__a' />
          </Mask>
          <Path
            d='M0 .004v17.587h10.254c6.764 0 9.696-3.788 9.696-8.81 0-5.004-2.932-8.777-9.696-8.777H0z'
            fill='#FFF'
            mask='url(#prefix__b)'
          />
        </G>
        <Path
          d='M11.256 1.363c7.314 0 8.406 4.69 8.406 7.485 0 4.85-2.985 7.52-8.406 7.52H2.292V1.364h8.964zm-8.259.706v13.594h8.26c4.987 0 7.7-2.323 7.7-6.815 0-4.615-2.941-6.78-7.7-6.78h-8.26z'
          fill='#000'
        />
        <Mask
          fill='#fff'
          id='prefix__d'
        >
          <Use xlinkHref='#prefix__c' />
        </Mask>
        <Path
          d='M4.105 14.555H6.75V9.848H4.105zM7.07 7.477a1.643 1.643 0 11-3.286 0 1.643 1.643 0 013.286 0'
          fill='#000'
          mask='url(#prefix__d)'
        />
        <Path
          d='M8.08 6.712v1.534h.47c.327 0 .628-.094.628-.779 0-.669-.335-.755-.627-.755h-.47z'
          fill='#00B0F0'
          mask='url(#prefix__d)'
        />
        <Path
          d='M11.4 3.178H8.08v2.773h.471c.857 0 1.39.581 1.39 1.516 0 .965-.52 1.54-1.39 1.54h-.47v5.549h3.32c4.174 0 6.445-1.944 6.445-5.703 0-3.863-2.462-5.675-6.445-5.675zm-.704 5.83h.76V5.95h-.76v3.057zm4.426 0h-.749l-1.32-2.032v2.032h-.7V5.95h.741l1.326 2.045V5.951h.702v3.057z'
          fill='#00B0F0'
          mask='url(#prefix__d)'
        />
      </G>
    </Svg>
  )
}
