import type { IMemberBenefit } from '@/types/objects'

import * as CONSTANTS from '../constants/member_benefits'

export const closeDetail = () => ({
  type: CONSTANTS.MEMBER_BENEFIT_CLOSE_DETAIL
})

export const downloadVoucher = (id: IMemberBenefit['id']) => ({
  payload: { id },
  type: CONSTANTS.MEMBER_BENEFIT_DOWNLOAD_VOUCHER
})

export const downloadVoucherSuccess = (memberBenefit: IMemberBenefit) => ({
  payload: { memberBenefit },
  type: CONSTANTS.MEMBER_BENEFIT_DOWNLOAD_VOUCHER_SUCCESS
})

export const downloadVoucherFailed = (error: string) => ({
  payload: { error },
  type: CONSTANTS.MEMBER_BENEFIT_DOWNLOAD_VOUCHER_FAILED
})
