import { IS_WEB } from '@/constants/platform'

import type { State as TState } from '@/redux/reducers'
import * as agencySelectors from '@/redux/selectors/agency'
import * as cachingSelectors from '@/redux/selectors/caching'
import * as claimsSelectors from '@/redux/selectors/claims'
import * as companiesSelectors from '@/redux/selectors/companies'
import * as filesSelectors from '@/redux/selectors/files'
import * as filtersSelectors from '@/redux/selectors/filters'
import * as formsSelectors from '@/redux/selectors/forms'
import * as idinSelectors from '@/redux/selectors/idin'
import * as memberBenefitsSelectors from '@/redux/selectors/member_benefits'
import * as modalsSelectors from '@/redux/selectors/modals'
import * as paymentsSelectors from '@/redux/selectors/payments'
import * as policiesSelectors from '@/redux/selectors/policies'
import * as policyAdviseSelector from '@/redux/selectors/policy_advise'
import * as purchasesSelectors from '@/redux/selectors/purchases'
import * as notificationsSelectors from './notifications'
import * as questionRepositorySelectors from '@/redux/selectors/questionRepository'
import * as relationRepositorySelectors from '@/redux/selectors/relationRepository'
import * as supportSelectors from '@/redux/selectors/support'
import * as termsSelectors from '@/redux/selectors/terms'
import * as themeSelectors from '@/redux/selectors/theme'
import * as tipRepositorySelectors from '@/redux/selectors/tipRepository'
import * as userSelectors from '@/redux/selectors/user'
import * as usersSelectors from '@/redux/selectors/users'
import * as whatToDosSelectors from '@/redux/selectors/whatToDos'
import * as relationSelectors from '@/redux/selectors/relations'
import * as productSelectors from '@/redux/selectors/products'
import * as loadingSelectors from '@/redux/selectors/loading'

const isSetupCompleted = (state: TState): boolean => {
  // When the user switches the combi policy, we want to show the setup screen again.
  const forceSwitchRelation = relationSelectors.getForceSwitchRelation(state)
  if (forceSwitchRelation) {
    return false
  }

  const termsRead = state.user?.termsRead
  const relationId = relationSelectors.getCurrentRelationId(state)
  const pincode = IS_WEB ? true : state.user?.pincode
  return !!(termsRead && relationId && pincode)
}

export default {
  ...agencySelectors,
  ...claimsSelectors,
  ...cachingSelectors,
  ...companiesSelectors,
  ...filesSelectors,
  ...filtersSelectors,
  ...formsSelectors,
  ...idinSelectors,
  ...modalsSelectors,
  ...memberBenefitsSelectors,
  ...paymentsSelectors,
  ...policiesSelectors,
  ...policyAdviseSelector,
  ...purchasesSelectors,
  ...questionRepositorySelectors,
  ...relationRepositorySelectors,
  ...notificationsSelectors,
  ...supportSelectors,
  ...termsSelectors,
  ...themeSelectors,
  ...tipRepositorySelectors,
  ...userSelectors,
  ...usersSelectors,
  ...whatToDosSelectors,
  ...relationSelectors,
  ...productSelectors,
  ...loadingSelectors,
  isSetupCompleted
}
