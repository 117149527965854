import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import Amount from '@/components/Amount'
import Attribute from '@/components/Attribute'
import type { IAttribute } from '@/components/Attributes'
import Attributes from '@/components/Attributes'
import Icon from '@/components/Icon'
import Text from '@/components/wrappers/Text'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import selectors from '@/redux/selectors'
import type { Policy, Relation } from '@/types/objects'
import common from '@/utils/common'
import i18n from '@/utils/i18n'

import { headerAttributes } from './helpers'

interface IProps {
  policy: Policy
  relation: Relation
}

const HeaderContent: React.FC<IProps> = ({ policy, relation }) => {
  const colors = useSelector(selectors.getThemeColors)
  const attributes = common.convertDetailsToAttributes(headerAttributes(policy))

  return (
    <>
      <View style={styles.headerContainer}>
        <Icon
          large
          circleColor={colors?.lightBrand}
          color={colors?.brand}
          productId={policy?.productId}
          style={Styling.selfCenter}
        />

        <View style={styles.centerContainer}>
          <Text
            color={colors?.brand}
            type='section'
          >
            {policy?.productName}
          </Text>
          <Text
            style={Styling.marginVerticalTiny}
            type='paragraph'
          >
            {i18n.t('Polisnummer')}: {policy?.policyNumber}
          </Text>
        </View>

        <View style={Styling.itemsEnd}>
          <Amount
            currency
            color={colors?.brand}
            type='subsection'
          >
            {policy?.premium}
          </Amount>

          {Platform.OS === 'web'
            ? (
              <Text type='paragraphLight'>{common.determinePaymentPeriod(relation?.paymentPeriod)}</Text>
              )
            : null}
        </View>
      </View>

      {Platform.OS !== 'web'
        ? (
          <Attribute
            noMargin
            description={relation?.paymentPeriod}
            style={Styling.marginBottomMediumHalf}
            title={`${i18n.t('Betalingstermijn')}:`}
          />
          )
        : null}

      <Attributes
        attributes={attributes as IAttribute[]}
        id='attributes-header'
      />
    </>
  )
}

const styles = StyleSheet.create({
  centerContainer: {
    flex: 1,
    paddingHorizontal: Sizes.spacingHorizontal
  },
  headerContainer: {
    flexDirection: 'row',
    marginBottom: Sizes.spacingVerticalMedium
  }
})

export default HeaderContent
