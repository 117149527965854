import type { IProduct } from '@/types/objects'
import type { TApiCallDispatch } from '../actions/api'
import prototype from '@/utils/prototype'

interface IProductsState {
  data: IProduct[]
}

export const initialState: IProductsState = {
  data: []
}

export default (
  state = initialState,
  action: TApiCallDispatch & { payload: { data: IProduct | IProduct[] } }
): IProductsState => {
  switch (action?.type) {
    case 'GET_PRODUCT_SUCCESS':
      return {
        ...state,
        data: prototype.mergeObjectInArray(state.data, action.payload.data as IProduct)
      }
    case 'GET_PRODUCTS_SUCCESS':
      return {
        ...state,
        data: prototype.mergeArrays(state.data, action.payload.data as IProduct[])
          .sort((a, b) => a.followNumber - b.followNumber)
      }

    case 'SELECT_RELATION':
      return initialState

    default:
      return state
  }
}
