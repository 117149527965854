import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import Block from '@/components/Block'
import Icon from '@/components/Icon'
import Text from '@/components/wrappers/Text'
import Touchable from '@/components/touchables/Touchable'
import Styling from '@/constants/Styling'
import mapStateToProps from '@/redux/mapStateToProps'
import i18n from '@/utils/i18n'
import type { ThemeColors } from '@/redux/reducers/theme'

export interface AddPolicyItemProps {
  colors: ThemeColors
  onPress: () => void
}

const AddPolicyTablet: React.FC<AddPolicyItemProps> = ({
  onPress,
  colors
}) => {
  return (
    <Touchable onPress={onPress}>
      <View style={Styling.marginTopMedium}>
        <Block>
          <Bootstrap.Row
            gutterHorizontal={15}
            gutterVertical={15}
          >
            <Bootstrap.Column
              style={Styling.rowCenter}
            >
              <Icon
                large
                icon='plus'
                circleColor={colors?.lightBrand}
                color={colors?.brand}
                style={Styling.marginRight}
              />
              <View style={Styling.flex}>
                <Text type='subtitle'>{i18n.t('Verzekering(en) toevoegen')}</Text>
              </View>
            </Bootstrap.Column>
          </Bootstrap.Row>
        </Block>
      </View>

    </Touchable>
  )
}

export default connect(mapStateToProps.themeColors)(AddPolicyTablet)
