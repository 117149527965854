import React, { useState } from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet } from 'react-native'
import { connect } from 'react-redux'

import Icon from '@/components/Icon'
import Touchable from '@/components/touchables/Touchable'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import mapStateToProps from '@/redux/mapStateToProps'

interface TArrowToggleProps {
  children?: React.ReactNode
  colors: any
  onToggle?: (open: boolean) => void
  style?: StyleProp<ViewStyle>
  text?: string
}

function ArrowToggle (props: TArrowToggleProps) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Touchable
        onPress={() => {
          setOpen(!open)
          if (typeof props.onToggle === 'function') {
            props.onToggle(!open)
          }
        }}
        style={props.style ? [styles.container, props.style] : styles.container}
      >
        <Text
          style={Styling.flex}
          type='label'
        >
          {props.text}
        </Text>
        <Icon
          circleColor={props.colors && props.colors.lightBrand}
          color={props.colors && props.colors.brand}
          icon={open ? ['fal', 'arrow-up'] : ['fal', 'arrow-down']}
          style={styles.icon}
        />
      </Touchable>
      {open && props.children ? props.children : null}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderRadius: Sizes.borderRadius,
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
    paddingHorizontal: Sizes.spacingHorizontal,
    paddingVertical: Sizes.spacingVertical
  },
  icon: {
    alignSelf: 'center',
    marginLeft: Sizes.spacingHorizontal
  }
})

export default connect(mapStateToProps.themeColors)(ArrowToggle)
