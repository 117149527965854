import moment from 'moment'
import { Platform } from 'react-native'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import * as ACTIONS from '@/redux/actions/files'
import * as CONSTANTS from '@/redux/constants/files'
import * as SELECTORS from '@/redux/selectors/files'
import type * as TYPES from '@/redux/types/files'
import type { File } from '@/types/objects'
import common from '@/utils/common'
import fs from '@/utils/fs'
import i18n from '@/utils/i18n'
import sharing from '@/utils/sharing'
import toast from '@/utils/toast'

import type { FilesState } from '../reducers/files'

export function * openFile (action: TYPES.OpenFileAction) {
  let result = false

  if (action.url) {
    if (Platform.OS === 'web') {
      common.openURL(action.url, '_blank')
      result = true
    } else {
      let localFilepath = ''

      // First check if for the given url there is a local file available
      // If no local file avilable try to download the remote file.

      const file: File = yield select(SELECTORS.getFile, action.url)

      if (file?.filepath) {
        const exists: boolean = yield call(fs.fileExists, file.filepath)

        if (exists) {
          localFilepath = file.filepath
        }
      }

      if (!localFilepath) {
        const filepath: string = yield call(fs.downloadDocument, action.url, action.filename)

        if (filepath) {
          yield put(ACTIONS.addFile(action.url, filepath))

          localFilepath = filepath
        }
      }

      if (localFilepath) {
        result = yield call(sharing.share, localFilepath)
      }
    }
  }

  if (!result && action.errorMessage) {
    toast(action.errorMessage)
  }
}

export function * refreshFile (action: TYPES.RefreshFileAction) {
  const files: FilesState = yield select(SELECTORS.getFiles)

  if (action.url in files) {
    const outOfDate =
      action.updatedAt &&
      (!files[action.url].timestamp || moment(files[action.url].timestamp) < moment(action.updatedAt))

    if (outOfDate) {
      const filepath: string = yield call(fs.downloadDocument, action.url, action.filename)

      yield put(ACTIONS.updateFile(action.url, filepath))
    }
  } else {
    const filepath: string = yield call(fs.downloadDocument, action.url, action.filename)

    if (filepath) {
      yield put(ACTIONS.addFile(action.url, filepath))
    }
  }
}

// eslint-disable-next-line require-yield
export function * openPolicyInsuranceCard (action: TYPES.OpenPolicyInsuranceCardAction) {
  if (action.insuranceCard?.url) {
    common.openURL(action.insuranceCard.url)
  }
}

export function * openPurchaseInvoice (action: TYPES.OpenPurchaseInvoiceAction) {
  const filename = fs.determineFilenameInvoice(action.purchase)
  const errorMessage = i18n.t('De aankoopbon kon niet worden geopend')

  yield put(ACTIONS.openFile(action.purchase?.invoice, filename, errorMessage))
}

export function * openPolicyCondition (action: TYPES.OpenPolicyConditionAction) {
  const filename = fs.determineFilenameCondition(action.condition)
  const errorMessage = i18n.t('De polisvoorwaarden kon niet worden geopend')

  yield put(ACTIONS.openFile(action.condition?.url, filename, errorMessage))
}

export function * openServiceGuide (action: TYPES.OpenServiceGuideAction) {
  const filename = fs.determineFilenameServiceGuide(action.serviceGuide)
  const errorMessage = i18n.t('Het dienstverlenend document kon niet worden geopend')

  yield put(ACTIONS.openFile(action.serviceGuide?.url, filename, errorMessage))
}

export function * filesSaga () {
  yield takeLatest(CONSTANTS.OPEN_FILE, openFile)
  yield takeLatest(CONSTANTS.REFRESH_FILE, refreshFile)
  yield takeLatest(CONSTANTS.OPEN_POLICY_CONDITION, openPolicyCondition)
  yield takeLatest(CONSTANTS.OPEN_POLICY_INSURANCE_CARD, openPolicyInsuranceCard)
  yield takeLatest(CONSTANTS.OPEN_PURCHASE_INVOICE, openPurchaseInvoice)
  yield takeLatest(CONSTANTS.OPEN_SERVICE_GUIDE, openServiceGuide)
}
