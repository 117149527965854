import { put, select, takeLatest } from 'redux-saga/effects'

import type { Relation } from '@/types/objects'

import apiWrapper from '@/utils/api_wrapper'
import * as ACTIONS from '../actions/api'
import selectors from '../selectors'
import type { SelectRelationAction } from '../types/relations'
import { SELECT_RELATION } from '../constants/relationRepository'
import { GET_RELATIONS_SUCCESS } from '../constants/relations'
import { logout } from '../actions/user'

function * selectRelation ({ relation }: SelectRelationAction) {
  apiWrapper.setUserParams({ relationId: relation.id, relationNumber: relation.relationNumber })
  yield put(ACTIONS.apiCallSuccess('GET', 'RELATION', relation?.id, relation))
  yield put(ACTIONS.apiCall('GET', 'AGENCY', relation?.agencyId))
}

function * checkCurrentRelation ({ payload }: { payload: { data: Relation[] } }) {
  const currentRelation: Relation = yield select(selectors.getCurrentRelation)
  const allRelationIds = payload.data?.map((relation) => relation.id)

  if (currentRelation && !allRelationIds.includes(currentRelation?.id)) {
    yield put(logout())
  } else if (currentRelation) {
    yield put(ACTIONS.apiCall('GET', 'AGENCY', currentRelation.agencyId))
    apiWrapper.setUserParams({ relationId: currentRelation.id, relationNumber: currentRelation.relationNumber })
  }
}

export function * relations () {
  yield takeLatest(SELECT_RELATION, selectRelation)
  yield takeLatest(GET_RELATIONS_SUCCESS, checkCurrentRelation)
}
