import { Dimensions } from 'react-native'

const screenSize = Dimensions.get('window')

export default {
  backButton: 20,
  badge: 18,
  borderRadius: 6,
  button: 60,
  buttonPaddingHorizontal: 15,
  buttonPaddingVertical: 12.5,
  buttonSmall: 45,
  checkBox: 30,
  circle: 40,
  circleLarge: 60,
  circleNormal: 50,
  circleSmall: 30,
  dot: 12,
  dotHuge: 24,
  dotLarge: 18,
  dotSmall: 8,
  dotTiny: 4,
  headerIcon: 22,
  hitSlop: {
    bottom: 15,
    left: 15,
    right: 15,
    top: 15
  },
  icon: 18,
  iconContainer: 35,
  iconContainerHuge: 80,
  iconContainerLarge: 50,
  iconContainerSmall: 20,
  iconHuge: 30,
  iconLarge: 24,
  iconNormal: 15,
  iconSmall: 12,
  idinIcon: 20,
  indicatorHeight: 3,
  inputMarginVertical: 30,
  labelMarginVertical: 10,
  listItemMargin: 15,
  listItemPadding: 15,
  marker: 40,
  modalPaddingHorizontal: 30,
  modalPaddingVertical: 30,
  pillHeight: 35,
  pillPaddingVertical: 10,
  pincode: 50,
  pincodeSmall: 40,
  progressBarHeight: 10,
  screenHeight: screenSize.height,
  screenPaddingHorizontal: 30,
  screenPaddingVertical: 30,
  screenWidth: screenSize.width,
  sidebarIcon: 20,
  sidebarWidth: 252,
  spacingHorizontal: 15,
  spacingHorizontalBig: 45,
  spacingHorizontalHuge: 60,
  spacingHorizontalMedium: 30,
  spacingHorizontalNormal: 20,
  spacingHorizontalSmall: 10,
  spacingHorizontalTiny: 5,
  spacingVertical: 15,
  spacingVerticalBig: 45,
  spacingVerticalHuge: 60,
  spacingVerticalMedium: 30,
  spacingVerticalNormal: 20,
  spacingVerticalSmall: 10,
  spacingVerticalTiny: 5,
  tabBarIcon: 20,
  tabBarPaddingVertical: 8,
  thumbnail: 56
}
