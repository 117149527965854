import hoistNonReactStatics from 'hoist-non-react-statics'
import React from 'react'
import type { EdgeInsets } from 'react-native-safe-area-context'
import { SafeAreaInsetsContext } from 'react-native-safe-area-context'

export interface TSafeAreaInsetsProps {
  insets: EdgeInsets | null
}

export default function withSafeAreaInsets<P extends object> (Component: React.ComponentType<P & TSafeAreaInsetsProps>) {
  function WithSafeAreaInsets (props: P) {
    return (
      <SafeAreaInsetsContext.Consumer>
        {(insets) => (
          <Component
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(props)}
            insets={insets}
          />
        )}
      </SafeAreaInsetsContext.Consumer>
    )
  }
  hoistNonReactStatics(WithSafeAreaInsets, Component)
  return WithSafeAreaInsets
}
