import React from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'

import Amount from '@/components/Amount'
import Icon from '@/components/Icon'
import ListItem from '@/components/list_items/ListItem'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import mapStateToProps from '@/redux/mapStateToProps'
import type { Policy, Relation } from '@/types/objects'
import data from '@/utils/data'
import i18n from '@/utils/i18n'
import type { ThemeColors } from '@/redux/reducers/theme'

interface TListItemPolicyProps {
  colors: ThemeColors
  onPress?: (policy: Policy) => void
  policy: Policy
  relation: Relation
}

function ListItemPolicy (props: TListItemPolicyProps) {
  const relation = props.relation || {}
  const policy = props.policy || {}
  const distinctionLabel = data.determinePolicyDistinctionLabel(policy)

  return (
    <ListItem
      chevron
      item={props.policy}
      onPress={props.onPress}
      style={{ marginBottom: 15 }}
    >
      <Icon
        circleColor={props.colors?.lightBrand}
        color={props.colors?.brand}
        productId={policy.productId}
      />

      <View style={styles.container}>
        <View>
          <Text type='description'>
            {policy?.productName}
          </Text>

          {distinctionLabel
            ? <Text type='description'>{distinctionLabel}</Text>
            : null}
        </View>

        {policy.status === 'Lopend' && policy.premium
          ? (
            <View style={Styling.rowWrap}>
              <Text type='paragraphLight'>
                {i18n.t('Premie')}:
              </Text>

              <Amount
                currency
                paymentPeriod={relation?.paymentPeriod}
                type='paragraphLight'
                hidePaymentPeriod={policy.productId === 77} // Hide payment period for 'kortlopende reis' product
              >
                {policy.premium}
              </Amount>
            </View>
            )
          : null}

        {policy.status === 'Geroyeerd'
          ? <Text type='descriptionError'>{i18n.t('Beëindigd')}</Text>
          : null}

        {policy.status === 'Toekomstig'
          ? (
            <Text
              color={Colors.primary}
              type='descriptionLight'
            >
              {i18n.t('Toekomstig')}
            </Text>
            )
          : null}

        {policy.status === 'Opgeschort'
          ? <Text type='descriptionLight'>{i18n.t('Opgeschort')}</Text>
          : null}
      </View>
    </ListItem>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: Sizes.spacingHorizontal
  }
})

export default connect(mapStateToProps.themeColors)(ListItemPolicy)
