import { isAfter } from 'date-fns'
import { Platform } from 'react-native'
import * as FileSystem from 'expo-file-system'

import Colors from '@/constants/Colors'
import type { Dict } from '@/redux/types'
import type { Agency, Claim, Payment, Policy, PolicyDetail } from '@/types/objects'
import { CompanyTypes } from '@/types/objects'
import DateUtil from '@/utils/date'
import i18n from '@/utils/i18n'
import { defaultClaimConfig } from './claims'
import type { TApiCallDispatch } from '@/redux/actions/api'

const paymentStatuses: Dict<string> = {
  betaald: '1',
  open: '0'
}

const paymentConfigs: Dict<{ color: string }> = {
  0: {
    color: Colors.waiting
  },
  1: {
    color: Colors.success
  }
}

const radiusItems = [
  {
    label: `10 ${i18n.t('KM')}`,
    value: 10000
  },
  {
    label: `15 ${i18n.t('KM')}`,
    value: 15000
  },
  {
    label: `25 ${i18n.t('KM')}`,
    value: 25000
  },
  {
    label: `35 ${i18n.t('KM')}`,
    value: 35000
  },
  {
    label: `50 ${i18n.t('KM')}`,
    value: 50000
  }
]

const companyTypeItems = [
  {
    label: i18n.t('Auto'),
    value: CompanyTypes.car
  },
  {
    label: i18n.t('Autoruit'),
    value: CompanyTypes['car-window']
  },
  {
    label: i18n.t('Caravan / Camper'),
    value: CompanyTypes['recreational-vehicle']
  }
]

const vehicleProductIds = [
  '12', // Bromfiets / Overig
  '15', // Oldtimer
  '3', // Personenauto
  '39', // Caravan / Vouwwagen
  '42', // Stacaravan
  '64', // Camper
  '67', // Bestelauto
  '68', // Aanhangwagen
  '71', // Hobbytractor
  '74', // Quad / Trike
  '86', // Vracht(bestel)auto
  '9' // Motor
]

export const allowedToFetchData = (fetchedAt: string | null | undefined): boolean => {
  if (fetchedAt === null || fetchedAt === undefined) {
    return true
  }

  return isAfter(DateUtil.dutchDateToJSDate(DateUtil.now()), DateUtil.dutchDateToJSDate(fetchedAt))
}

export const getKeyName = ({ entity, id }: Pick<TApiCallDispatch['payload'], 'entity' | 'id'>) => {
  if (id) {
    return `${entity}-${id}`
  }

  return entity
}

// This method exists because of inconsistent Expo APIs
// And the requirement of BettyBlocks to remove mime-type
export const convertBase64String = async (base64: string): Promise<string> => {
  switch (Platform.OS) {
    // expect the path of the resource instead of the actual base64 string
    // native will return base64 without mime-type
    case 'android':
    case 'ios':
      return await FileSystem.readAsStringAsync(base64, {
        encoding: FileSystem.EncodingType.Base64
      })

    case 'web':
    default:
      // Expect actual base64 on web
      // remove mime-type through splitting
      return await new Promise((resolve) => resolve(base64.split(',')[1]))
  }
}

class Data {
  productName = Platform.OS === 'web' ? 'Mijn Polissen' : 'VerzekeringApp'

  getConfigPayment = (payment: Payment) => {
    if (payment) {
      const getStatus = payment.outstanding ? 'Open' : 'Betaald'
      const cleanStatus = (getStatus || '').toLowerCase().replace(/[\W_]+/g, '')
      if (cleanStatus in paymentStatuses && paymentStatuses[cleanStatus] in paymentConfigs) {
        return paymentConfigs[paymentStatuses[cleanStatus]]
      }
    }
    return defaultClaimConfig
  }

  getItemsRadius = () => radiusItems

  getDefaultRadius = () => 10000

  getItemsCompanyType = () => companyTypeItems

  getDefaultCompanyType = () => companyTypeItems[0].value

  getVehicleDetail = (details: any) => {
    if (details?.brand?.code && details?.model?.code) {
      return {
        code: `${details.brand.code} ${details.model.code}`.trim(),
        id: `${details.brand.id} ${details.model.id}`,
        label: 'Voertuig',
        name: 'vehicle',
        type: 'string'
      }
    }
    return null
  }

  determinePolicyDistinctionLabel = (policy: Policy) => {
    let label = ''
    if (policy) {
      label = policy.policyNumber
      if (policy.details && policy.productId) {
        if (this.isPolicyVehicle(policy)) {
          if (policy.details?.license_plate?.code) {
            label = policy.details.license_plate.code
          }
        } else if (policy.details?.zip_code?.code) {
          label = policy.details.zip_code.code
        }
      }
    }
    return label
  }

  getImportantDetails = (policy: Policy): PolicyDetail[] =>
    Object.values(policy?.details ?? {}).filter((detail) => detail?.important && detail?.code !== '')

  getLicensePlate = (policy: Policy) => policy?.details?.license_plate?.code || ''

  getAgencyAddress = (agency: Agency): string => {
    let mainLocation = agency?.locations?.find((location) => location.mainLocation)

    if (!mainLocation) {
      mainLocation = {
        city: agency?.city,
        email: agency?.email,
        houseNumber: agency?.houseNumber,
        houseNumberAddition: agency?.houseNumberAddition,
        mainLocation: true,
        name: agency?.name,
        phoneNumber: agency?.phoneNumber,
        street: agency?.street,
        whatsapp: agency?.whatsapp,
        zipCode: agency?.zipCode
      }
    }

    const firstPart = [
      mainLocation.street,
      mainLocation.houseNumber +
        (typeof mainLocation.houseNumberAddition === 'string' ? mainLocation.houseNumberAddition : '')
    ]
      .filter((s) => !!s)
      .join(' ')
      .trim()
    const secondPart = [mainLocation.zipCode, mainLocation.city]
      .filter((s) => !!s)
      .join(' ')
      .trim()
    return [firstPart, secondPart].filter((s) => !!s).join('\n')
  }

  getPolicyName = (policy: Policy) => {
    if (policy?.details?.brand?.code && policy?.details?.model?.code) {
      return `${policy.details.brand.code} ${policy.details.model.code}`.trim()
    }
    return ''
  }

  isPolicyVehicle = (policy: Policy) => !!(policy?.productId && vehicleProductIds.includes(String(policy.productId)))

  getDistinctionLabel = (claim?: Claim) => {
    let distinctionLabel

    if (claim?.vehicle?.licensePlate) {
      distinctionLabel = claim.vehicle.licensePlate
    } else if (claim?.building?.postalCode && claim?.building?.houseNumber) {
      distinctionLabel = `${claim.building.postalCode} ${claim.building.houseNumber}`
    } else {
      distinctionLabel = null
    }

    return distinctionLabel
  }
}

export default new Data()
