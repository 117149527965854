import React from 'react'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'

import Toggle from '@/components/Toggle'
import Modal from '@/components/wrappers/Modal'
import Text from '@/components/wrappers/Text'
import Settings from '@/constants/Settings'
import Styling from '@/constants/Styling'
import * as modalActions from '@/redux/actions/modals'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'
import i18n from '@/utils/i18n'

import packageJson from '../../package.json'

let git = {}

try {
  git = require('../../git.json')
} catch (_) {}

interface TModalHelpProps {
  closeModalHelp: typeof modalActions.closeModalHelp
  openModalLogs: typeof modalActions.openModalLogs
  modals: any
}

interface TModalHelpState {
  count: number
  longCount: number
}

class ModalHelp extends React.Component<TModalHelpProps, TModalHelpState> {
  constructor (props: TModalHelpProps) {
    super(props)

    this.state = {
      count: 0,
      longCount: 0
    }
  }

  onPress = () =>
    this.setState((previousState: TModalHelpState) => ({
      count: previousState.count + 1
    }))

  onLongPress = () =>
    this.setState((previousState: TModalHelpState) => {
      let longCount = previousState.longCount + 1
      if (longCount === 3) {
        longCount = 0
        this.props.closeModalHelp()
        setTimeout(() => {
          this.props.openModalLogs()
        }, 500)
      }
      return { longCount }
    })

  getQuestions = () => [
    {
      answer:
        'Neem contact op met uw verzekeringsadviseur. Hij kan achterhalen via welk e-mailadres u geregistreerd bent.',
      id: '1',
      text: 'Ik ben het e-mailadres vergeten waarop ik inlog. Wat moet ik doen?'
    },
    {
      answer:
        'iDIN is dé veilige online identificatie- en inlogmethode ontwikkeld door banken. Deze methode wordt ook gebruikt door allerlei overheidsinstanties en webwinkels. Met iDIN gebruikt u de veilige en vertrouwde inlogmethode van uw bank. Zo hoeft u veel minder gebruikersnamen en wachtwoorden te onthouden.',
      id: '2',
      text: 'Wat is iDIN?'
    },
    {
      answer: 'Nee. U kunt alleen een Mijn Polissen account aanvragen als er Nh1816 Verzekeringen op uw naam staan.',
      id: '3',
      text: 'Kan iedereen een account aanmaken?'
    },
    {
      answer:
        'De hoofdgebruiker kan binnen de VerzekeringApp en Mijn Polissen omgeving een sub-gebruiker aanmaken. Dit doet hij/zij bij de instellingen. Sub-gebruikers kunnen middels een eigen inlog verzekeringen inzien en bijvoorbeeld het Verzekeringsbewijs raadplegen.',
      id: '4',
      text: 'Mijn verzekeringen lopen via een ander gezinslid. Hoe kan ik deze verzekeringen zelf inzien?'
    }
  ]

  buildInfo = () => {
    // @TODO retrieve from native
    return `Version ${packageJson.version}`
  }

  render () {
    return (
      <Modal
        showClose
        useScrollView
        onHide={this.props.closeModalHelp}
        visible={!!(this.props.modals?.help)}
      >
        <TouchableOpacity
          activeOpacity={1}
          onLongPress={this.onLongPress}
          onPress={this.onPress}
          style={styles.touchable}
        >
          <Text
            type='title'
            selectable={false}
          >{i18n.t('Help')}
          </Text>
        </TouchableOpacity>

        {this.state.count % 10 === 0 && this.state.count > 0
          ? (
            <View style={Styling.marginTopMedium}>
              <Text type='description'>{this.buildInfo()}</Text>
              <Text type='description'>branch: {git?.branch}</Text>
              <Text type='description'>environment: {Settings.environment}</Text>
              <Text type='description'>sha: {git?.sha}</Text>
            </View>
            )
          : null}

        {this.getQuestions().map((question) => (
          <Toggle
            key={`question-block-toggle-${question.id}`}
            lowerText={question.answer}
            style={Styling.marginTopMedium}
            upperText={question.text}
          />
        ))}
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  touchable: {
    alignSelf: 'center',
    ...(Platform.OS === 'web' ? { cursor: 'default' } : {})
  }
})

const mapStateToProps = (state: TState) => ({
  modals: selectors.getModals(state)
})

const mapDispatchToProps = {
  closeModalHelp: modalActions.closeModalHelp,
  openModalLogs: modalActions.openModalLogs
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalHelp)
