import React from 'react'
import { StyleSheet, View } from 'react-native'

import common from '@/utils/common'
import i18n from '@/utils/i18n'
import navigation from '@/utils/navigation'

import Actions from '@/components/Actions'
import Amount from '@/components/Amount'
import EmptyState from '@/components/EmptyState'
import Icon from '@/components/Icon'
import Screen from '@/components/Screen'
import Text from '@/components/wrappers/Text'
import ButtonCircle from '@/components/buttons/ButtonCircle'
import ArrowToggle from '@/screens/policies/components/ArrowToggle'
import ListItemPolicy from '@/screens/policies/components/ListItemPolicy'
import AddPolicyPhone from '@/screens/policies/components/AddPolicyPhone'

import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'

import type { Policy } from '@/types/objects'
import type { IChildComponentProps } from './Policies'

const PoliciesPhone: React.FC<IChildComponentProps> = (props) => {
  const renderItem = (policy: Policy) => (
    <ListItemPolicy
      key={`list-item-policy-${policy.id}`}
      onPress={navigation.openScreenPolicy}
      policy={policy}
      relation={props.relation}
    />
  )

  const renderEmptyState = () => (
    <EmptyState
      message={props.refreshing
        ? i18n.t('De polissen aan het ophalen')
        : i18n.t('U heeft nog geen polissen')}
    />
  )

  return (
    <Screen
      noPadding
      white
      useRefreshControl
      refreshing={props.refreshing}
      onRefresh={props.onRefresh}
      scrollViewRef={props.scrollViewRef}
    >
      <View style={styles.container}>
        <Icon
          large
          circleColor={props.colors?.lightBrand}
          color={props.colors?.brand}
          icon='box-alt'
          style={Styling.selfCenter}
        />

        <View style={styles.centerContainer}>
          <Text
            color={props.colors?.brand}
            type='section'
          >
            {i18n.t('Mijn pakket')}
          </Text>

          <Text
            style={Styling.marginVerticalTiny}
            type='paragraph'
          >
            {i18n.t('Combinummer')}: {props.relation?.combiNumber}
          </Text>

          <Actions
            labelDown={i18n.t('Polisblad')}
            labelRight={i18n.t('Details')}
            onPressDown={navigation.openScreenPolicyStatement}
            onPressRight={navigation.openScreenCombiPolicy}
          />
        </View>

        <View style={Styling.itemsEnd}>
          <Amount
            currency
            color={props.colors?.brand}
            type='subsection'
          >
            {props.relation?.combiCollectionAmount}
          </Amount>

          <Text type='paragraphLight'>
            {common.determinePaymentPeriod(props.relation?.paymentPeriod)}
          </Text>
        </View>
      </View>
      <View style={styles.spacer} />
      <View style={[Styling.background, Styling.paddingMedium]}>
        <View style={styles.addButtonContainer}>
          <ButtonCircle
            icon='plus'
            color={props.colors?.brand}
            style={styles.addButton}
            onPress={navigation.openScreenAddPolicy}
          />
        </View>

        {props.policiesOverview?.map(renderItem)}

        {props.policiesExpelled?.length
          ? (
            <ArrowToggle
              onToggle={props.showExpelledPolicies}
              style={props.policiesOverview?.length ? Styling.marginTopMedium : null}
              text={i18n.t('Toon de beëindigde polissen')}
            >
              {props.policiesExpelled?.map(renderItem)}
            </ArrowToggle>
            )
          : null}

        {(!props.policiesOverview?.length && !props.policiesExpelled?.length)
          ? renderEmptyState()
          : null}

        <AddPolicyPhone onPress={navigation.openScreenAddPolicy} />
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  centerContainer: {
    flex: 1,
    paddingHorizontal: Sizes.spacingHorizontal
  },
  container: {
    backgroundColor: Colors.white,
    flexDirection: 'row',
    paddingHorizontal: Sizes.screenPaddingHorizontal,
    paddingVertical: Sizes.screenPaddingVertical
  },
  generalConditionsContainer: {
    minWidth: 250
  },
  addButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  addButton: {
    position: 'relative',
    top: -55,
    left: -10,
    zIndex: 999
  },
  spacer: {
    height: 20
  }
})

export default PoliciesPhone
