import * as CONSTANTS from '@/redux/constants/filters'
import type { FiltersActionTypes } from '@/redux/types/filters'
import type { CompanyTypes, Location } from '@/types/objects'
import prototype from '@/utils/prototype'

export interface FiltersState {
  companyType: CompanyTypes | null
  brand: string | null
  location: Location | null
  radius: number | null
}

export const initialState: FiltersState = {
  companyType: null,
  brand: null,
  location: null,
  radius: null
}

export default (state: FiltersState = initialState, action: FiltersActionTypes): FiltersState => {
  switch (action?.type) {
    case CONSTANTS.RESET_FILTERS:
      return initialState

    case CONSTANTS.REMOVE_FILTER_LOCATION:
      return {
        ...state,
        location: null
      }

    case CONSTANTS.UPDATE_FILTER_LOCATION:
      return prototype.isObject(action.location) && action.location !== state.location
        ? {
            ...state,
            location: action.location
          }
        : state

    case CONSTANTS.UPDATE_FILTER_RADIUS:
      return action.radius !== state.radius
        ? {
            ...state,
            radius: action.radius
          }
        : state

    case CONSTANTS.UPDATE_FILTER_COMPANY_TYPE:
      return action.companyType !== state.companyType
        ? {
            ...state,
            companyType: action.companyType
          }
        : state

    case CONSTANTS.UPDATE_FILTERS:
      return {
        ...state,
        ...action.payload.data
      }

    default:
      return state
  }
}
