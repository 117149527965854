import type { ReactElement } from 'react'
import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { Platform, TextInput, View } from 'react-native'
import { VirtualKeyboard } from 'react-native-screen-keyboard'

import Icon from '@/components/Icon'
import { styles as stylesText } from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'

interface TKeypadProps {
  customKeyElement?: JSX.Element
  disabled?: boolean
  hideKeyboard?: boolean
  onChange: (pincode: string) => void
  onCustomKey?: (text: string) => void
  style?: StyleProp<ViewStyle>
  value: string
}

const keyboard = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  [
    <Icon
      color={Colors.black}
      icon={['far', 'trash']}
    />,
    0,
    <Icon
      color={Colors.black}
      icon={['far', 'backspace']}
    />
  ]
]

export default class Keypad extends React.Component<TKeypadProps, { keyDownListener: unknown }> {
  textInputRef: any

  virtualKeyboardRef: any

  constructor (props: TKeypadProps) {
    super(props)
    if (Platform.OS !== 'web') {
      this.textInputRef = React.createRef()
    }

    this.state = {
      keyDownListener: null
    }
  }

  componentDidMount () {
    if (Platform.OS === 'web') {
      this.setState({
        keyDownListener: document.addEventListener('keydown', this.onKeyDownEvent, false)
      })
    }
  }

  componentWillUnmount () {
    if (Platform.OS === 'web') {
      this.state.keyDownListener?.remove?.()
    }
  }

  isRemoveKey = (key: string): boolean => ['back', 'backspace', 'delete'].includes(key)

  onKeyPress = (event: any): void => {
    if (event?.nativeEvent) {
      this.onKeyDownEvent(event.nativeEvent)
    }
  }

  onKeyDownEvent = (event: any): void => {
    const key = (event?.key || '').toLowerCase()
    const number = Number(key)

    if (!Number.isNaN(number) || this.isRemoveKey(key)) {
      this.onKeyDown(key)
    }
  }

  onKeyDown = (key: string): void => {
    if (key === 'custom') {
      return
    }

    let value: string = this.props.value || ''

    if (this.isRemoveKey(key)) {
      value = value.slice(0, -1)
    } else {
      if (this.props.disabled) {
        return
      }

      value += key
    }

    this.props.onChange(value)
  }

  onBlur = (): void => {
    if (this.textInputRef?.current) {
      this.textInputRef.current.focus()
    }
  }

  renderKeyboard = (): ReactElement | null =>
    this.props.hideKeyboard
      ? null
      : (
        <VirtualKeyboard
          keyboard={
          this.props.customKeyElement
            ? [keyboard[0], keyboard[1], keyboard[2], [this.props.customKeyElement, keyboard[3][1], keyboard[3][2]]]
            : keyboard
        }
          keyboardStyle={styles.keyboard}
          keyStyle={styles.key}
          keyTextStyle={stylesText.keypad}
          onCustomKey={this.props.onCustomKey}
          onKeyDown={this.onKeyDown}
          onRef={(ref: React.Component) => {
            this.virtualKeyboardRef = ref
          }}
          vibration={false}
        />
        )

  renderContent = (): ReactElement => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isPad' does not exist on type 'PlatformA... Remove this comment to see the full error message
    return Platform.isPad
      ? (
        <>
          <TextInput
            autoFocus
            keyboardType='numeric'
            onBlur={this.onBlur}
            onKeyPress={this.onKeyPress}
            ref={this.textInputRef}
            style={styles.textInput}
          />
          {this.renderKeyboard()}
        </>
        )
      : (
          this.renderKeyboard()
        )
  }

  render () {
    if (this.props.style) {
      return <View style={this.props.style}>{this.renderContent()}</View>
    }

    return this.renderContent()
  }
}

// Do not use StyleSheet.create because the VirtualKeyboard component only accepts objects as style props.
const styles = {
  key: {
    borderBottomColor: Colors.e2,
    borderRightColor: Colors.e2
  },
  keyboard: {
    borderLeftColor: Colors.e2,
    borderLeftWidth: 1,
    borderTopColor: Colors.e2,
    borderTopWidth: 1
  },
  textInput: {
    height: 0,
    width: 0
  }
}
