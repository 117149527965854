import React from 'react'
import * as Linking from 'expo-linking'
import semverGt from 'semver/functions/gt'
import semverValid from 'semver/functions/valid'
import checkVersion from 'react-native-store-version'

import Button from '@/components/buttons/Button'
import Modal from '@/components/wrappers/Modal'
import Text from '@/components/wrappers/Text'

import Colors from '@/constants/Colors'
import Styling from '@/constants/Styling'
import { IS_ANDROID, IS_IOS } from '@/constants/platform'

import i18n from '@/utils/i18n'
import * as RemoteConfig from '@/utils/firebase/remote_config'

import packageJson from '../../package.json'
import { logEvent } from '@/utils/firebase/analytics'

const UpdateAppModal = () => {
  const [latestAppVersion, setLatestAppVersion] = React.useState<string | null>(null)
  const [minimumAppVersion, setMinimumAppVersion] = React.useState<string | null>(null)

  const currentAppVersion = packageJson.version

  const updateAvailable: boolean = latestAppVersion !== null && semverGt(latestAppVersion, currentAppVersion)
  const updateRequired: boolean = minimumAppVersion !== null && semverGt(minimumAppVersion, currentAppVersion)

  const hideModal = () => {
    setLatestAppVersion(null)
    setMinimumAppVersion(null)
  }

  const openStore = () => {
    if (IS_IOS) {
      Linking.openURL('https://apps.apple.com/us/app/verzekeringapp/id1512743190')
    } else if (IS_ANDROID) {
      Linking.openURL('https://play.google.com/store/apps/details?id=com.nh1816.verzekeringapp')
    }

    logEvent(updateRequired ? 'update_button_required' : 'update_button')

    if (!updateRequired) {
      hideModal()
    }
  }

  const fetchLatestAppVersion = async () => {
    try {
      let latestVersion = ''

      const check = await checkVersion({
        version: packageJson.version, // app local version
        iosStoreURL: 'https://apps.apple.com/nl/app/verzekeringapp/id1512743190',
        androidStoreURL: 'https://play.google.com/store/apps/details?id=com.nh1816.verzekeringapp&hl=en',
        country: 'nl', // default value is 'jp'
      })

      latestVersion = check.remote

      if (semverValid(latestVersion)) {
        setLatestAppVersion(latestVersion as unknown as string)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  }

  const fetchMinimumAppVersion = async () => {
    try {
      const remoteMinimumAppVersion = RemoteConfig.getValue('minimum_app_version').asString()

      if (semverValid(remoteMinimumAppVersion)) {
        setMinimumAppVersion(remoteMinimumAppVersion)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      fetchLatestAppVersion()
      fetchMinimumAppVersion()
    }, 1000)
  }, [])

  return (
    <Modal
      showClose={false}
      visible={updateAvailable || updateRequired}
    >
      <Text
        bold
        center
        style={Styling.marginTop}
        type='header'
      >
        {updateRequired
          ? i18n.t('Nieuwe app-versie vereist')
          : i18n.t('Nieuwe app-versie beschikbaar')}
      </Text>
      <Text
        center
        style={Styling.marginVertical}
        type='description'
      >
        {updateRequired
          ? i18n.t(
            'Er staat een nieuwe versie van de VerzekeringApp voor u klaar. Download de nieuwste versie om de app te kunnen blijven gebruiken.'
          )
          : i18n.t(
            'Er staat een nieuwe versie van de VerzekeringApp voor u klaar. Voor optimaal gebruiksgemak raden wij aan de app te updaten.'
          )}
      </Text>

      <Button
        onPress={openStore}
        style={Styling.marginVertical}
        title={i18n.t('Updaten')}
      />

      {!updateRequired && (
        <Button
          borderColor={Colors.gray}
          color={Colors.white}
          onPress={hideModal}
          style={Styling.marginVertical}
          title={i18n.t('Nee, bedankt')}
          titleColor={Colors.gray}
        />
      )}
    </Modal>
  )
}

export default UpdateAppModal
