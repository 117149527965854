import { put, takeLatest } from 'redux-saga/effects'

import type { IMemberBenefit } from '@/types/objects'
import i18n from '@/utils/i18n'

import type { IApiResponse } from '@/utils/api_wrapper'
import * as ACTIONS from '../actions/member_benefits'
import * as API from '../api/member_benefits'
import * as CONSTANTS from '../constants/member_benefits'
import callApi from '../api'

function * downloadVoucher ({ payload }: ReturnType<typeof ACTIONS.downloadVoucher>) {
  try {
    const response: IApiResponse<IMemberBenefit> = yield callApi(API.downloadVoucher, payload.id.toString())

    if (response.success) {
      yield put(ACTIONS.downloadVoucherSuccess(response?.data))
    } else {
      throw new Error(
        response.message ?? i18n.t('Er is iets fout gegaan met het ophalen van de ledenvoordelen, probeer het later opnieuw')
      )
    }
  } catch (error) {
    yield put(ACTIONS.downloadVoucherFailed(error))
  }
}

export function * memberBenefitsSaga () {
  yield takeLatest(CONSTANTS.MEMBER_BENEFIT_DOWNLOAD_VOUCHER, downloadVoucher)
}
