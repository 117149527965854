import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as Bootstrap from '@/bootstrap'
import BlockToggle from '@/components/BlockToggle'
import Screen from '@/components/Screen'
import Html from '@/components/wrappers/HTML'
import Styling from '@/constants/Styling'
import selectors from '@/redux/selectors'
import { apiCall } from '@/redux/actions/api'

const Questions = () => {
  const dispatch = useDispatch()

  const questions = useSelector(selectors.getQuestions)
  const refreshing = !!useSelector(selectors.isLoading('QUESTIONS'))

  const refresh = () => {
    dispatch(apiCall('GET', 'QUESTIONS'))
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <Screen
      useRefreshControl
      onRefresh={refresh}
      refreshing={refreshing}
    >
      <Bootstrap.Row
        gutterHorizontal={15}
        gutterVertical={15}
        style={Styling.justifyCenter}
      >
        {(questions || []).map((question) => (
          <Bootstrap.Column
            key={`question-block-toggle-${question.id}`}
            span={{ lg: 8, md: 10, sm: 12, xl: 8, xs: 12 }}
          >
            <BlockToggle upperText={question.text}>
              <Html html={question.answer} />
            </BlockToggle>
          </Bootstrap.Column>
        ))}
      </Bootstrap.Row>
    </Screen>
  )
}

export default Questions
